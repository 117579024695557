import styled, { css } from 'styled-components'
import { Modal, ModalBody } from 'reactstrap'

import { InputNumberModalSize } from './components/InputNumberModal'
import CreatableSelect2 from 'components/SelectOptions/CreatableSelect2'
import Checkbox from 'components/Checkbox'
import theme from 'vars/theme'
import BackSpace from 'components/BackSpace'

export const Wrapper = styled.div`
  --height-header: 60px;
  --height-footer: 70px;
  background-color: ${theme.graySoft6};
  height: calc(var(--vh, 1vh) * 100);
  font-size: 18px;
  overflow: auto;
`

export const WrapperLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1056;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.5);
`

export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ModalFrame = styled(ModalBody)`
  position: relative;
  z-index: 100;
  width: ${({ isSmallModal }) => (isSmallModal ? '696px' : '896px')};
  margin: -40px auto 0 auto;
  padding: 16px;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #dddddd;
`

export const CustomModal = styled(Modal)`
  max-width: ${({ size }) => {
    switch (size) {
      case InputNumberModalSize.SMALL:
        return '696px'
      case InputNumberModalSize.MEDIUM:
        return '896px'
      default:
        return '896px'
    }
  }};

  & > .modal-content {
    border-radius: 12px;
    border: 1px solid #dddddd;
    padding: 16px;
  }
`

export const CustomInput = styled.input`
  width: 200px;
  text-align: center;
  font-size: 40px;
  color: #333;
  border-radius: 12px;
  margin: 0 auto 16px auto;
`

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px 16px;
`

export const HeaderBox = styled.div`
  width: 33%;
`

export const HeaderCenterBox = styled.div`
  width: 34%;
  text-align: center;
  font-size: 24px;
`

export const WrapperInput = styled.div`
  display: flex;
  justify-content: space-around;
  width: 496px;
  margin: 0 auto 18px auto;
  border: 2px solid #4f4f4f;
  border-radius: 12px;
`

export const Input = styled.input`
  border: none;
  width: 140px;
  text-align: center;
  font-size: 40px;
  color: #333;
  background: none;
`

export const NumberButton = styled.button`
  display: inline-block;
  width: 160px;
  height: 80px;
  border: 1px solid #ccc;
  color: #247cdb;
  background-color: white;
  font-size: 48px;
  border-radius: 12px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 1px 6px;
  white-space: nowrap;
`

export const CustomNumberButton = styled(NumberButton)`
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1.0)};
`

export const BackSpaceButton = styled(NumberButton)`
  font-size: 22px;
  font-weight: normal;
`

export const EnterButton = styled(NumberButton)`
  color: white;
  background-color: #247cdb;
  border: none;
  height: 256px;
  font-weight: normal;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1.0)};
`

export const NumericKeypadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ isShowPhoneNumberPrefix }) => (isShowPhoneNumberPrefix ? '832px' : '664px')};
  margin: 0 auto;
`

export const NumericKeypadBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const NumericKeypadRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

//Login page

export const Content = styled.div`
  margin: 32px;
`

export const WrapperTitle = styled.div`
  border-radius: 12px;
  background-color: white;
`

export const InputPassword = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 52px;
  padding-left: 10px;
  padding-right: 10px;
`

// PasCode Page

export const IconBox = styled(HeaderCenterBox)`
  width: 45%;
`

export const InputPassCode = styled.input`
  flex: 1;
  max-width: 111px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 40px;
  color: #333;
  border: 2px solid ${({ isValid }) => (isValid ? '#333333' : '#DB2664')};
  border-radius: 12px;
`

export const WrapperInputPassCode = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
`

export const ModalFramePassCode = styled(ModalFrame)`
  width: 931px;
`

export const BackSpaceCustom = styled(BackSpace)`
  margin-left: auto;
  margin-top: 9px;
  margin-bottom: 30px;
`

export const PassCodeText = styled.p`
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
`

export const Title = styled(PassCodeText)`
  font-weight: 700;

  span {
    text-decoration: underline;
  }
`

export const Notification = styled(Title)`
  margin-top: 15px;
`

export const PhoneNumberLabel = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 36px;
  font-weight: 600;
  margin: 18px 0px;
`

export const SendCodeButton = styled.button`
  padding: 35px 23px;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  color: ${theme.blueMedium5};
  background-color: ${theme.whitePrimary};
  border: 1px solid ${theme.blueMedium5};
  border-radius: 30px;
  font-size: 24px;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

export const LeftBox = styled.div`
  margin-top: -80px;
  padding-right: 35px;
  flex: 45%;
`

export const RightBox = styled.div`
  flex: 55%;
`

// entry page

export const LabelTitleBottom = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 1.5rem;
`

export const TextBold = styled.span`
  font-weight: bold;
  font-size: 70px;
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 940px;
  padding-top: 35px;
`

export const EntryBookingPage = styled.div`
  height: calc((var(--vh, 1vh) * 100) - var(--height-header) - 138px);
  overflow-y: auto;
`

export const EntryLoadPage = styled.div`
  height: calc((var(--vh, 1vh) * 100) - var(--height-header) - var(--height-footer));
  overflow-y: auto;
`

export const EntryLoadPageContent = styled.div<{ isImportExportChecked: boolean }>`
  --title-height: 120px;
  height: calc(
    (var(--vh, 1vh) * 100) - var(--height-header) - var(--height-footer) - var(--title-height)
  );
  display: flex;
  overflow-y: ${({ isImportExportChecked }) => (isImportExportChecked ? 'auto' : 'hidden')};

  @media (min-width: 970px) {
    justify-content: center;
  }
`

export const EntryLoadPageArea = styled.div<{ hasBorderRight: boolean }>`
  border-right: ${({ hasBorderRight }) => (hasBorderRight ? '1px solid #BDBDBD' : 'none')};
`

export const EntryLoadPageAreaContent = styled(EntryLoadPageArea)`
  margin-right: -1px;
  width: 460px;
`

export const ImportExportContent = styled.div<{ isShow: boolean }>`
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  font-size: 24px;
`

export const InputImportExport = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 1.1;
`

export const SelectInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  .label {
    line-height: 1.2;
    word-break: break-word;
  }
`

// entry finish page

export const ContentTitle = styled.div`
  min-height: 350px;
  border-radius: 12px;
`

// Driver info page

export const InputInfo = styled.input`
  width: 100%;
  height: 52px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
  ${({ disabled }) => disabled && `background-color: rgb(237, 237, 237)`}
`

export const WrapperOptions = styled.div`
  display: flex;
  margin: 0 auto 16px auto;
  width: 550px;
`

export const OptionsBox = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperFiledName = styled.div`
  width: 260px;
`

export const CustomSelect = styled.select`
  width: 100%;
  height: 52px;
  padding: 0px 8px;
  border-radius: 4;
  font-size: 24px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #000;
`

export const CustomCreatableSelect = styled(CreatableSelect2)`
  .mono-creatable__control {
    margin-left: 0;
  }
`

export const ButtonOutline = styled.button`
  color: #247cdb;
  background-color: white;
  border: 1px solid #247cdb;
  border-radius: 68px;
  min-width: 440px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 32px;

  ${({ color }) =>
    color &&
    css`
      text-align: center;
      color: white;
      background-color: ${color};
      border: 1px solid ${color};
    `};
`
export const ImageLogo = styled.img`
  width: 70px;
  height: 80px;
`

export const WrapperTitleCustom = styled(WrapperTitle)`
  margin-bottom: 1.5rem;
  padding-bottom: 100px;
  padding-top: 100px;
  min-height: 500px;
`

export const NotificationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Text = styled.p`
  font-size: 28px;

  ${(props) =>
    props.sms &&
    `
    line-height: 1.2;
    font-size: 62px;
    font-weight: bold
  `}
`

export const TextNoteMessage = styled.p`
  font-size: 28px;
  padding: 14px;
  margin-top: 4px;
  background: ${theme.redPrimary1};
  color: ${theme.whitePrimary};
  font-weight: 500;
`

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
`

export const IconWrapper = styled.div`
  ${(props) =>
    props.sms
      ? ` width: 120px;
          height: 120px;
          margin: 24px 0 0;
        `
      : `
          width: 180px;
          height: 180px;
          margin: 24px 0;
  `}
`

export const PortalIconWrapper = styled.div`
  width: 180px;
  height: 180px;
  margin: 24px 0;
  padding: 30px;
  border-radius: 32px;
  box-shadow: 0px 0px 9px 4px ${theme.graySoft17};
`

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const MessageDefault = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 48px;
`

export const CheckboxCustom = styled(Checkbox)`
  display: flex;
  align-items: center;
  height: 38px;

  & input {
    width: 200px;
    height: 38px;
  }

  & .check-icon {
    width: 38px;
    height: 38px;

    img {
      width: 24px !important;
      height: 24px !important;
      margin-top: 0 !important;
    }
  }

  span.text-primary {
    font-size: 32px;
    margin-left: 50px !important;
  }
`

export const HeaderTitle = styled.h2`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
`

export const RequiredText = styled.span`
  color: ${theme.redPrimary2};
  font-size: 24px;
  width: 60px;
  margin: 0 5px 0 -12px;
`

export const ConfirmationWrapper = styled(Wrapper)`
  min-width: 1024px;
`
