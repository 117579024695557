import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import BookingDetail from './BookingDetail'
import CarRow, { Colgroup, Thead } from 'containers/IntegratedManagementPage/components/CarRow'
import Button from './Button'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(17, 32, 64, 0.4);
  z-index: 4;
`

const FIX_COLUMN_LENGTH = 5
let theadRef = {
  offsetHeight: 0,
}

const setRef = (ref) => {
  if (!ref) return
  theadRef = ref
}

export default ({
  top,
  height,
  booking,
  tenants,
  onClickCancel,
  onClickDecide,
  onClickToggleWarehouse,
  checkedWarehouseIds,
  vehicleCategoryMap,
  packageTypeMap,
  onClickResetDraftTenant,
  bookingMaster,
}) => {
  const [heightContent, setHeightContent] = useState(0)
  const divRef = useRef()

  useEffect(() => {
    setHeightContent(divRef.current.offsetHeight > height ? divRef.current.offsetHeight : height)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking])

  return (
    // thead の高さを取得するために visibility: hidden で最初に描画しておく必要がある
    <div style={{ display: top ? 'block' : 'none' }}>
      <Backdrop />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: heightContent + 350,
          zIndex: 9,
        }}
      >
        <div
          style={{
            top: top - theadRef.offsetHeight,
            left: 20,
            position: 'absolute',
            height: 'auto',
            width: 'calc(100% - 40px)',
            zIndex: 9,
            backgroundColor: 'white',
            borderRadius: 12,
            overflowX: 'auto',
          }}
          ref={divRef}
        >
          <table
            style={{
              margin: 0,
              zIndex: 10,
              backgroundColor: 'white',
            }}
          >
            <Colgroup tenants={tenants} operationType="decide" />
            <Thead tenants={tenants} setRef={setRef} operationType="decide" isSticky />

            <tbody style={{ border: 'none' }}>
              {/* 割り当て順番 */}
              <CarRow
                warehouses={tenants}
                booking={booking}
                onClickToggleWarehouse={onClickToggleWarehouse}
                onClickCancel={onClickCancel}
                onClickDecide={onClickDecide}
                operationType="decide"
                checkedWarehouseIds={checkedWarehouseIds}
                multiBookingOrders={[]}
                colWidth={80}
                vehicleCategoryMap={vehicleCategoryMap}
                onClickResetDraftTenant={onClickResetDraftTenant}
                isCarBookingEditor
              />

              {/* 車両詳細 */}
              <tr style={{ borderBottom: '1px solid #CCC' }}>
                <td colSpan={FIX_COLUMN_LENGTH + tenants.length}>
                  <BookingDetail
                    booking={booking}
                    packageTypeMap={packageTypeMap}
                    bookingMaster={bookingMaster}
                  />
                </td>
              </tr>

              {/* 操作ボタン */}
              <tr>
                <td colSpan={FIX_COLUMN_LENGTH + tenants.length}>
                  <div
                    className="m12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      label="キャンセル"
                      type="blue-outline"
                      onClick={onClickCancel}
                      style={{ marginRight: 10, height: 32 }}
                    />
                    <Button
                      label="決定"
                      type="blue-fill"
                      onClick={checkedWarehouseIds.length ? onClickDecide : _.noop}
                      style={{ opacity: checkedWarehouseIds.length ? 1.0 : 0.4, height: 32 }}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
