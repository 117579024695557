import React, { MouseEvent, useRef, useState } from 'react'
import styled from 'styled-components'
import OutsideClickComponent from 'react-outside-click-handler'

import { CustomButton } from './CarRow'

const Option = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 24px;
  left: 8px;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 100px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 4px 24px rgba(37, 39, 44, 0.25);
  z-index: 2;
`

const OptionItem = styled.div`
  padding: 6px 15px;
  font-size: 14px;
  text-align: start;

  &:hover {
    background-color: #247cdb;
    color: #fff;
  }
`

const Wrapper = styled.div`
  position: relative;
`

type Props = {
  disabled: boolean
  onClickAssign: (e: Object) => void
  onClickCloseDetail: () => void
  onHandleAcceptDraftOrder: () => void
}

const PreAssignButton: React.FC<Props> = ({
  disabled,
  onClickAssign,
  onHandleAcceptDraftOrder,
  onClickCloseDetail,
}) => {
  const assignWrapperRef = useRef<any>(null)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  return (
    <OutsideClickComponent ref={assignWrapperRef} onOutsideClick={() => setIsOpenModal(false)}>
      <Wrapper
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          onClickCloseDetail()
          setIsOpenModal(!isOpenModal)
        }}
      >
        <CustomButton label="本割当する" disabled={disabled} type="red-fill" />
        <Option isOpen={!disabled && isOpenModal}>
          <OptionItem
            onClick={() => {
              setIsOpenModal(false)
              onHandleAcceptDraftOrder()
            }}
          >
            仮割当を利用して本割当
          </OptionItem>
          <OptionItem
            onClick={(e: MouseEvent) => {
              e.stopPropagation()
              setIsOpenModal(false)
              const newEvent = {
                currentTarget: assignWrapperRef.current.childNode,
                stopPropagation: () => {},
              }
              onClickAssign(newEvent)
            }}
          >
            新しい順序で本割当
          </OptionItem>
        </Option>
      </Wrapper>
    </OutsideClickComponent>
  )
}

export default PreAssignButton
