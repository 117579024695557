import RestClient from './RestClient'
import { getAuthHeader } from './RestPhoneBookingClient'

export default class ConfirmBookingService {
  constructor(restService = RestClient) {
    this.restService = restService
  }

  getBookingInfo(linkCode) {
    return this.restService.get(
      `berths/bookings/detail_by_link_code?link_code=${linkCode}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
  getCountVehicleWaiting(booking_id) {
    return this.restService.get(
      `tablets/count_vehicle_waiting?booking_id=${booking_id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  createCommentSMS(data) {
    return this.restService.post({
      url: 'berths/booking/create_comment_sms',
      data,
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })
  }

  driverUpdateStatus(data) {
    return this.restService.patch({
      url: 'berths/booking/driver_update_status ',
      data,
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })
  }

  getPackageTypeList(tenant_id) {
    return this.restService.get(
      'tablets/master_data',
      { tenant_id },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getCommentList(data) {
    return this.restService.get(
      `berths/booking/list_comments?booking_id=${data}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  sendNotification(data) {
    return this.restService.post({
      url: 'berths/booking/send_notification',
      data,
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })
  }

  verifyAccessToken({ token }) {
    return this.restService.get(
      `tablets/verify_access_token?access_token=${token}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  verifyToken({ phoneNumber, token }) {
    return this.restService.get(
      `tablets/verify_phone_and_token?phone_number=${phoneNumber}&access_token=${token}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  verifyCode({ phoneNumber, passcode, token }) {
    return this.restService.get(
      `tablets/verify_old_passcode?phone_number=${phoneNumber}&access_token=${token}&old_code=${passcode}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  updateCode({ phoneNumber, token, passcode }) {
    return this.restService.patch({
      url: 'tablets/update_code',
      data: { phone_number: phoneNumber, access_token: token, new_code: passcode },
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    })
  }
}
