export const processType = {
  booking: {
    update: 'update_booking',
    delete: 'delete_booking',
  },
  delegation: {
    update: 'update_delegation',
    delete: 'delete_delegation',
  },
}

export const convertAttachFile = (attach) => (attach ? attach.split('/')[1] : '')
