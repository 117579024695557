import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

import NumericKeypad from '../components/NumericKeypad'
import Header from '../components/Header'
import { Backdrop, ModalFrame } from '../components/utils'
import { updateDriver } from '../reducers'
import PrevButton from 'components/PrevButton'
import { fetchAnything } from 'containers/App/reducers'
import { filterNumber } from '../constants'

const Input = styled.input`
  border: none;
  width: 140px;
  text-align: center;
  font-size: 40px;
  color: #333;
  background: none;
`
class EntryTelNumberPage extends Component {
  state = {
    left: '',
    center: '',
    right: '',
  }

  componentDidMount() {
    const { match, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
      return
    }

    this.props.fetchAnything({
      url: '/tablets/master_data',
      storePath: 'master',
      data: { tenantId },
    })

    this.props.fetchAnything({
      url: '/tablets/driver_confirmation',
      storePath: 'driverConfirmations',
      data: { tenantId },
    })
  }

  onClick = (value) => {
    const { left, center, right } = this.state

    let num

    if (left.length < 3) {
      num = { left: `${left}${value}` }
    } else if (center.length < 4) {
      num = { center: `${center}${value}` }
    } else if (right.length < 4) {
      num = { right: `${right}${value}` }
    }

    if (num) {
      this.setState(num)
    }
  }

  onClickBackSpace = () => {
    const { left, center, right } = this.state

    let num

    if (right.length > 0) {
      num = { right: right.substring(0, right.length - 1) }
    } else if (center.length > 0) {
      num = { center: center.substring(0, center.length - 1) }
    } else {
      num = { left: left.substring(0, left.length - 1) }
    }

    this.setState(num)
  }

  onChangeLeft = (e) => {
    const { value } = e.target
    this.setState({ left: filterNumber(value) })
  }

  onChangeCenter = (e) => {
    const { value } = e.target
    this.setState({ center: filterNumber(value) })
  }

  onChangeRight = (e) => {
    const { value } = e.target
    this.setState({ right: filterNumber(value) })
  }

  onClickEnter = () => {
    const {
      history,
      match: { params },
    } = this.props
    const { warehouseId, tenantId } = params
    const { left, center, right } = this.state

    this.props.updateDriver({ driverPhone: `${left}${center}${right}` })
    history.push(`/receipt-booking/entry-passcode/${warehouseId}/${tenantId}`)
  }

  render() {
    const {
      match: { params },
      driver,
      history,
    } = this.props
    const { warehouseId, tenantId } = params
    const { left, center, right } = this.state
    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header driver={driver} />

        <ModalFrame className="mx-auto p-3" style={{ width: 832 + 32 + 32 }}>
          <div className="mb-3 mx-3 d-flex justify-content-between align-items-center">
            <div style={{ width: '33%' }}>
              <PrevButton
                onClick={() => history.push(`/receipt-booking/${warehouseId}/${tenantId}`)}
              />
            </div>
            <div className="text-center text-size24" style={{ width: '34%' }}>
              <span>電話番号を入力</span>
            </div>
            <div style={{ width: '33%' }} />
          </div>

          <div
            className="mx-auto mb-4"
            style={{
              width: 496,
              display: 'flex',
              justifyContent: 'space-around',
              border: '2px solid #4F4F4F',
              borderRadius: 12,
            }}
          >
            {/* TODO set focus */}
            <Input type="text" value={left} maxLength={3} onChange={this.onChangeLeft} />
            <span style={{ fontSize: 40 }}>-</span>
            <Input type="text" value={center} maxLength={4} onChange={this.onChangeCenter} />
            <span style={{ fontSize: 40 }}>-</span>
            <Input type="text" value={right} maxLength={4} onChange={this.onChangeRight} />
          </div>

          <NumericKeypad
            isShowPhoneNumberPrefix
            onClick={this.onClick}
            onClickBackSpace={this.onClickBackSpace}
            onClickEnter={this.onClickEnter}
            value={`${left}${center}${right}`}
            canClickEnter={`${left}${center}${right}`.length === 11}
          />
        </ModalFrame>

        <Backdrop />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
    returnEntryPage: reception.returnEntryPage,
  }
}

const mapDispatchToProps = {
  updateDriver,
  fetchAnything,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryTelNumberPage)
