export const buildBooking = (booking, warehouseId, tenantId, driverPhone) => {
  // import/export のチェックがついてない場合は、いっさい送らない
  const isImport = booking.importFlag === 1
  const isExport = booking.exportFlag === 1

  const filteredBooking = Object.keys(booking).reduce((ac, key) => {
    if (
      ['importFlag', 'exportFlag'].includes(key) ||
      !key.match(/^(import|export)/) ||
      (isImport && key.match(/^import/)) ||
      (isExport && key.match(/^export/))
    ) {
      return ac
    }

    return {
      ...ac,
      [key]: '',
    }
  }, booking)

  return {
    ...filteredBooking,
    warehouseId,
    tenantId,
    driverPhone,
    attachments: [],
  }
}
