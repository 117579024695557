import React from 'react'
import styled from 'styled-components'

import Icon from 'components/Icon'
import { formatBookingNo } from 'utils/formatters'

const Outer = styled.button`
  display: block;
  width: 100%;
  background-color: white;
  color: #247cdb;
  border: 1px solid #247cdb;
  border-radius: 68px;
  height: 72px;
  font-size: 24px;
`

export default ({ className, style, onClick, booking, children }) => (
  <Outer
    className={`d-flex justify-content-between align-items-center ${className}`}
    style={style}
    onClick={() => onClick(booking)}
  >
    {/* left */}
    <div className="ml-4 font-weight-bold">{children}</div>
    {/* right */}
    <div className="mr-3">
      <span className="mr-4 font-weight-bold">
        {formatBookingNo(booking.bookingNo || booking?.generalBookingNo)}
      </span>
      <Icon
        src="/assets/svgs/arrow-right-blue.svg"
        width="12px"
        height="12px"
        style={{ marginTop: -6 }}
      />
    </div>
  </Outer>
)
