import React, { FC, useState } from 'react'

import Button from 'components/Button'
import Select from 'components/SelectOptions'
import { optionEnCodingsDownloadCsv } from 'constants/misc'
import * as S from './style'

type Props = {
  isOpen: boolean
  uploadCsv: (file: File | null, encodingType: string) => Promise<void>
  onClose: () => void
}

const CsvUploadModal: FC<Props> = ({ isOpen, uploadCsv, onClose }) => {
  const [encodingType, setEncodingType] = useState<string>('Shift-JIS')
  const [file, setFile] = useState<File | null>(null)

  const onChangeModalUploadCsv = async (e: any) => {
    const file = e.target.files[0]
    if (file) {
      setFile(file)
    }
  }

  const handleUploadCsv = () => {
    uploadCsv(file, encodingType)
    onHandleClickClose()
  }

  const onHandleClickClose = () => {
    onClose()
    setEncodingType('Shift-JIS')
    setFile(null)
  }

  return (
    <S.CustomModal width={480} isOpen={isOpen}>
      <S.Title>CSVアップロード</S.Title>
      <S.Wrapper>
        <span>ファイル</span>
        <S.NameFile>{file?.name}</S.NameFile>
        <label htmlFor="modal-upload-csv-booking">
          <Button color="blue" onClick={(e: any) => e.target.parentNode.click()}>
            選択
            <S.InputHidden
              id="modal-upload-csv-booking"
              type="file"
              accept=".csv"
              onChange={onChangeModalUploadCsv}
              onClick={(e: any) => (e.target.value = '')}
            />
          </Button>
        </label>
      </S.Wrapper>
      <S.Wrapper>
        <Select
          name="encoding-type"
          label="文字コード"
          options={optionEnCodingsDownloadCsv}
          selectValue={encodingType}
          handleUpdateData={(value: string) => setEncodingType(value)}
          width={'140px'}
        />
      </S.Wrapper>
      <S.Footer>
        <S.CustomButton color="lightBlue" onClick={onHandleClickClose}>
          キャンセル
        </S.CustomButton>
        <S.CustomButton
          color="blue"
          disabled={!Boolean(file) || !file?.name.endsWith('.csv')}
          onClick={handleUploadCsv}
        >
          アップロード
        </S.CustomButton>
      </S.Footer>
    </S.CustomModal>
  )
}

export default CsvUploadModal
