import { fromJS } from 'immutable'
import { handleActions, createActions } from 'redux-actions'
import _ from 'lodash'

import { context } from './constants'

const initialState = fromJS({
  loading: false,
  generalReceptions: [],
  generalReceptionSelected: {},
  error: null,
  selectedParameter: {
    id: 0,
    displayName: '',
    type: 0,
  },
  parameters: [],
  initialParameters: [],
  generalReceptionId: 0,
  generalReception: [],
  initialGeneralConfirmation: {
    confirmations: [],
  },
  settingGeneralConfirmation: {
    confirmations: [],
  },
  role: {},
})

export const {
  startLoading,
  endLoading,
  fetchSuccess,
  fetchFailed,
  fetchGeneralReceptions,
  updateGeneralReceptionSelected,
  updateParameters,
  selectParameter,
  updateParameter,
  fetchReceptionParameters,
  updateInitialGeneralConfirmation,
  updateSettingGeneralConfirmation,
  fetchRoleGeneralReception,
  resetData,
  clearConfirmations,
  updateRole,
} = createActions(
  {
    START_LOADING: _.noop,
    END_LOADING: _.noop,
    FETCH_SUCCESS: (data) => data,
    FETCH_FAILURE: (data) => data,
    FETCH_GENERAL_RECEPTIONS: _.noop,
    UPDATE_GENERAL_RECEPTION_SELECTED: (data) => data,
    UPDATE_PARAMETERS: (data) => data,
    SELECT_PARAMETER: (data) => data,
    UPDATE_PARAMETER: (data) => data,
    FETCH_RECEPTION_PARAMETERS: (data) => data,
    UPDATE_INITIAL_GENERAL_CONFIRMATION: (data) => data,
    UPDATE_SETTING_GENERAL_CONFIRMATION: (data) => data,
    FETCH_ROLE_GENERAL_RECEPTION: _.noop,
    RESET_DATA: _.noop,
    CLEAR_CONFIRMATIONS: _.noop,
    UPDATE_ROLE: (data) => data,
  },
  { prefix: context }
)

export const reducer = handleActions(
  {
    START_LOADING: (state) => state.set('loading', true),
    END_LOADING: (state) => state.set('loading', false),
    FETCH_SUCCESS: (state, { payload: { key, data } }) =>
      state.set('loading', false).set(key, fromJS(data)),
    FETCH_FAILURE: (state, { payload }) =>
      state.set('loading', false).set('error', fromJS(payload)),
    UPDATE_GENERAL_RECEPTION_SELECTED: (state, { payload }) =>
      state.set('generalReceptionSelected', fromJS(payload)),
    SELECT_PARAMETER: (state, { payload }) => {
      return state.set('selectedParameter', fromJS(payload))
    },
    UPDATE_PARAMETER: (state, { payload }) => {
      return state.set('selectedParameter', fromJS(payload))
    },
    UPDATE_PARAMETERS: (state, { payload }) => {
      return state.set('parameters', fromJS(payload))
    },
    UPDATE_INITIAL_GENERAL_CONFIRMATION: (state, { payload }) =>
      state.set('initialGeneralConfirmation', fromJS(payload)),
    UPDATE_SETTING_GENERAL_CONFIRMATION: (state, { payload }) =>
      state.set('settingGeneralConfirmation', fromJS(payload)),
    RESET_DATA: (state) => state.merge(initialState),
    CLEAR_CONFIRMATIONS: (state) => {
      const initialConfirmation = fromJS({
        confirmations: [],
      })
      return state
        .set('initialGeneralConfirmation', initialConfirmation)
        .set('settingGeneralConfirmation', initialConfirmation)
    },
    UPDATE_ROLE: (state, { payload }) => state.set('role', fromJS(payload)),
  },
  initialState,
  { prefix: context }
)
