import './env'

// Needed for redux-saga es6 generator support
// import 'babel-polyfill'

// Import all the third party stuff
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

// Import CSS reset and Global Styles
import GlobalStyle from './global-styles'
import './fonts.css'

// Import root app
import App from 'containers/App'

/* eslint-enable import/no-unresolved, import/extensions */

import { store } from './reduxStoreHelper'

import '@fortawesome/fontawesome-free/css/all.css'

import moment from 'moment'
import 'moment/locale/ja'

moment.locale('ja')

const MOUNT_NODE = document.getElementById('root')

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <GlobalStyle />
        <App />
      </BrowserRouter>
    </Provider>,
    MOUNT_NODE
  )
}

render()

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['./i18n', 'containers/App'], () => {
//     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     render(translationMessages)
//   })
// }

// // Chunked polyfill for browsers without Intl support
// if (!window.Intl) {
//   new Promise(resolve => {
//     resolve(import('intl'))
//   })
//     .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
//     .then(() => render(translationMessages))
//     .catch(err => {
//       throw err;
//     })
// } else {
//   render(translationMessages)
// }
