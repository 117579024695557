import React, { memo, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'

import { useDispatch, useSelector } from 'reduxStoreHelper'
import { openModal, openError } from 'containers/App/reducers'
import errorMessages from 'utils/errorMessages'
import { phoneNumberEnteredSelectors } from '../selectors'
import { startLoading, endLoading, fetchSuccess, setIsFromBookingNo } from '../reducer'
import {
  INTEGRATED_RECEPTION_URL_BASE,
  MAX_LENGTH_BOOKING_NUMBER_LEFT_INPUT,
  MAX_LENGTH_BOOKING_NUMBER_CENTER_INPUT,
  MAX_LENGTH_BOOKING_NUMBER_RIGHT_INPUT,
  checkBookingCanCheckin,
} from '../constants'
import { getBookingAndDriverInfo } from '../api'
import Header from '../components/Header'
import InputNumberModal, { InputNumberModalSize } from '../components/InputNumberModal'
import * as S from '../style'

type Props = {
  generalReceptionId?: string
}

const EntryBookingNumberPage: React.FC<Props> = ({ generalReceptionId }) => {
  const history = useHistory()
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()
  const dispatch = useDispatch()
  const phoneNumberEntered = useSelector(phoneNumberEnteredSelectors())

  const onClickEnter = useCallback(
    async (bookingNo: string) => {
      try {
        dispatch(startLoading())
        const resp = await getBookingAndDriverInfo(
          generalReceptionId,
          bookingNo,
          phoneNumberEntered
        )
        const statusOfBooking = _.get(resp, 'data.status', '')

        if (!checkBookingCanCheckin(statusOfBooking)) {
          dispatch(
            openModal({
              content: <div>{errorMessages.Receipt.bookingNoReceived()}</div>,
              buttons: [
                {
                  label: 'OK',
                  color: 'primary',
                },
              ],
            })
          )
          return
        }

        dispatch(
          fetchSuccess({
            key: 'bookingFromBookingNo',
            data: resp.data,
          })
        )
        dispatch(setIsFromBookingNo(true))
        history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/${generalReceptionNumber}`)
      } catch (error) {
        //@ts-ignore
        dispatch(openError(error.message))
      } finally {
        dispatch(endLoading())
      }
    },
    [dispatch, generalReceptionId, generalReceptionNumber, history, phoneNumberEntered]
  )

  const onClickClose = useCallback(() => {
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-bookings/${generalReceptionNumber}`)
  }, [generalReceptionNumber, history])

  return (
    <S.Wrapper>
      <Header />
      <InputNumberModal
        isOpen
        title="予約番号を入力"
        maxLengthLeft={MAX_LENGTH_BOOKING_NUMBER_LEFT_INPUT}
        maxLengthCenter={MAX_LENGTH_BOOKING_NUMBER_CENTER_INPUT}
        maxLengthRight={MAX_LENGTH_BOOKING_NUMBER_RIGHT_INPUT}
        onClickEnter={onClickEnter}
        onClickClose={onClickClose}
        size={InputNumberModalSize.SMALL}
      />
    </S.Wrapper>
  )
}

export default memo(EntryBookingNumberPage)
