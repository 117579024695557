export const context = 'containers/home-page'

// working type, not for create actions
export const CREATE_BOOKING = 'create_booking'
export const UPDATE_BOOKING = 'update_booking'
export const BOOKING_DETAIL = 'booking_detail'
export const DELEGATION_DETAIL = 'delegation_detail'
export const RESET_ALL_TENANT_DATA = `${context}/RESET_ALL_TENANT_DATA`
export const DELETE_REPEAT_BOOKING = `${context}/DELETE_REPEAT_BOOKING`

// current day
export const UPDATE_CURRENT_DAY = `${context}/UPDATE_CURRENT_DAY`
// warhouse
export const CHANGE_WAREHOUSE_ID = `${context}/CHANGE_WAREHOUSE_ID`
export const CHANGE_TENANT_ID = `${context}/CHANGE_TENANT_ID`

// slide modal
export const OPEN_SLIDE_MODAL = `${context}/OPEN_SLIDE_MODAL`
export const CLOSE_SLIDE_MODAL = `${context}/CLOSE_SLIDE_MODAL`

// init home, warehouse list
export const GET_WAREHOUSE_LIST = `${context}/GET_WAREHOUSE_LIST`
export const GET_WAREHOUSE_LIST_SUCCESS = `${context}/GET_WAREHOUSE_LIST_SUCCESS`
export const GET_WAREHOUSE_LIST_FAILURE = `${context}/GET_WAREHOUSE_LIST_FAILURE`
export const GET_BERTH_LIST = `${context}/GET_BERTH_LIST`
export const GET_BERTH_LIST_SUCCESS = `${context}/GET_BERTH_LIST_SUCCESS`
export const GET_BERTH_LIST_FAILURE = `${context}/GET_BERTH_LIST_FAILURE`

export const CHANGE_META_BERTH_LIST = `${context}/CHANGE_META_BERTH_LIST`

export const GET_ALL_BERTH_LIST = `${context}/GET_ALL_BERTH_LIST`
export const GET_ALL_BERTH_LIST_SUCCESS = `${context}/GET_ALL_BERTH_LIST_SUCCESS`
export const GET_ALL_BERTH_LIST_FAILURE = `${context}/GET_ALL_BERTH_LIST_FAILURE`

export const GET_ROLE_TENANT = `${context}/GET_ROLE_TENANT`
export const GET_ROLE_TENANT_SUCCESS = `${context}/GET_ROLE_TENANT_SUCCESS`
export const GET_ROLE_TENANT_FAILURE = `${context}/GET_ROLE_TENANT_FAILURE`
export const SET_IS_LOADING_ROLE_TENANT = `${context}/SET_IS_LOADING_ROLE_TENANT`

// actions type
export const ADD_BOOKING_ITEM = `${context}/ADD_BOOKING_ITEM`
export const UPDATE_BOOKING_ITEM = `${context}/UPDATE_BOOKING_ITEM`
export const DELETE_BOOKING_ITEM = `${context}/DELETE_BOOKING_ITEM`
export const DELETE_BOOKING_ITEMS = `${context}/DELETE_BOOKING_ITEMS`
export const ADD_PENDING_ITEM = `${context}/ADD_PENDING_ITEM`
export const DELETE_PENDING_ITEM = `${context}/DELETE_PENDING_ITEM`
export const DELETE_DELEGATION_ITEM = `${context}/DELETE_DELEGATION_ITEM`
export const CHANGE_SEARCH_BOOKING = `${context}/CHANGE_SEARCH_BOOKING`

// company list
export const GET_COMPANY_LIST = `${context}/GET_COMPANY_LIST`
export const GET_COMPANY_LIST_SUCCESS = `${context}/GET_COMPANY_LIST_SUCCESS`
export const GET_COMPANY_LIST_FAILURE = `${context}/GET_COMPANY_LIST_FAILURE`
export const SET_COMPANY_LIST = `${context}/SET_COMPANY_LIST`

// create new booking
export const CREATE_NEW_BOOKING = `${context}/CREATE_NEW_BOOKING`
export const CREATE_NEW_BOOKING_SUCCESS = `${context}/CREATE_NEW_BOOKING_SUCCESS`
export const CREATE_NEW_BOOKING_FAILURE = `${context}/CREATE_NEW_BOOKING_FAILURE`

// create unassign booking
export const CREATE_UNASSIGN_BOOKING = `${context}/CREATE_UNASSIGN_BOOKING`
export const CREATE_UNASSIGN_BOOKING_SUCCESS = `${context}/CREATE_UNASSIGN_BOOKING_SUCCESS`
export const CREATE_UNASSIGN_BOOKING_FAILURE = `${context}/CREATE_UNASSIGN_BOOKING_FAILURE`

// create booking repeat
export const CREATE_BOOKING_REPEAT = `${context}/CREATE_BOOKING_REPEAT`
export const CREATE_BOOKING_REPEAT_SUCCESS = `${context}/CREATE_BOOKING_REPEAT_SUCCESS`
export const CREATE_BOOKING_REPEAT_FAILURE = `${context}/CREATE_BOOKING_REPEAT_FAILURE`

// update booking
export const UPDATE_BOOKING_DATA = `${context}/UPDATE_BOOKING_DATA`
export const UPDATE_BOOKING_SUCCESS = `${context}/UPDATE_BOOKING_SUCCESS`
export const UPDATE_BOOKING_FAILURE = `${context}/UPDATE_BOOKING_FAILURE`

// update booking unassigned
export const UPDATE_BOOKING_UNASSIGNED = `${context}/UPDATE_BOOKING_UNASSIGNED`
export const UPDATE_BOOKING_UNASSIGNED_SUCCESS = `${context}/UPDATE_BOOKING_UNASSIGNED_SUCCESS`

// update booking status
export const UPDATE_BOOKING_STATUS = `${context}/UPDATE_BOOKING_STATUS`
export const UPDATE_BOOKING_STATUS_SUCCESS = `${context}/UPDATE_BOOKING_STATUS_SUCCESS`
export const UPDATE_BOOKING_STATUS_FAILURE = `${context}/UPDATE_BOOKING_STATUS_FAILURE`

// update confirmed driver change time
export const CONFIRMED_DRIVER_CHANGE_TIME = `${context}/CONFIRMED_DRIVER_CHANGE_TIME`
export const CONFIRMED_DRIVER_CHANGE_TIME_SUCCESS = `${context}/CONFIRMED_DRIVER_CHANGE_TIME_SUCCESS`
export const CONFIRMED_DRIVER_CHANGE_TIME_FAILURE = `${context}/CONFIRMED_DRIVER_CHANGE_TIME_FAILURE`

// create new Delegation
export const CREATE_NEW_DELEGATION = `${context}/CREATE_NEW_DELEGATION`
export const CREATE_NEW_DELEGATION_SUCCESS = `${context}/CREATE_NEW_DELEGATION_SUCCESS`
export const CREATE_NEW_DELEGATION_FAILURE = `${context}/CREATE_NEW_DELEGATION_FAILURE`

// update new Delegation
export const UPDATE_DELEGATION_DATA = `${context}/UPDATE_DELEGATION_DATA`
export const UPDATE_DELEGATION_SUCCESS = `${context}/UPDATE_DELEGATION_SUCCESS`
export const UPDATE_DELEGATION_FAILURE = `${context}/UPDATE_DELEGATION_FAILURE`

// error object
export const UPDATE_ERROR_MESSAGES = `${context}/UPDATE_ERROR_MESSAGES`
export const CLEAR_ERROR_MESSAGES = `${context}/CLEAR_ERROR_MESSAGES`

// neutral booking list
export const GET_NEUTRAL_BOOKING = `${context}/GET_NEUTRAL_BOOKING`
export const GET_NEUTRAL_BOOKING_SUCCESS = `${context}/GET_NEUTRAL_BOOKING_SUCCESS`
export const GET_NEUTRAL_BOOKING_FAILURE = `${context}/GET_NEUTRAL_BOOKING_FAILURE`
export const UPDATE_NEUTRAL_META = `${context}/UPDATE_NEUTRAL_META`

// actions csv
export const UPLOAD_CSV_REQUEST = `${context}/UPLOAD_CSV_REQUEST`
export const UPLOAD_CSV_SUCCESS = `${context}/UPLOAD_CSV_SUCCESS`
export const UPLOAD_CSV_FAILURE = `${context}/UPLOAD_CSV_FAILURE`

export const DOWNLOAD_CSV_REQUEST = `${context}/DOWNLOAD_CSV_REQUEST`
export const DOWNLOAD_CSV_SUCCESS = `${context}/DOWNLOAD_CSV_SUCCESS`
export const DOWNLOAD_CSV_FAILURE = `${context}/DOWNLOAD_CSV_FAILURE`

export const UPLOAD_CSV_BERTH = `${context}/UPLOAD_CSV_BERTH`

// get package type master
export const GET_PACKAGE_TYPE_LIST = `${context}/GET_PACKAGE_TYPE_LIST`
export const GET_PACKAGE_TYPE_SUCCESS = `${context}/GET_PACKAGE_TYPE_SUCCESS`

// get booking summary
export const GET_BOOKING_SUMMARY = `${context}/GET_BOOKING_SUMMARY`
export const GET_BOOKING_SUMMARY_SUCCESS = `${context}/GET_BOOKING_SUMMARY_SUCCESS`
export const GET_BOOKING_SUMMARY_FAILURE = `${context}/GET_BOOKING_SUMMARY_FAILURE`

export const UPDATE_TASK_LIST_PROCESS = `${context}/UPDATE_TASK_LIST_PROCESS`
export const CLEAR_TASK_LIST_PROCESS = `${context}/CLEAR_TASK_LIST_PROCESS`

// force finish
export const REQUEST_FORCE_FINISH = `${context}/REQUEST_FORCE_FINISH`
export const CANCEL_FORCE_FINISH = `${context}/CANCEL_FORCE_FINISH`
export const CONFIRM_FORCE_FINISH = `${context}/CONFIRM_FORCE_FINISH`
export const FORCE_FINISH_SUCCESS = `${context}/FORCE_FINISH_SUCCESS`
export const FORCE_FINISH_FAILURE = `${context}/FORCE_FINISH_FAILURE`

// save loading
export const TOGGLE_SAVE_LOADING = `${context}/TOGGLE_SAVE_LOADING`
export const TOGGLE_DISPLAY_MODE = `${context}/TOGGLE_DISPLAY_MODE`

// update repeat status
export const DISPATCH_UPDATE_REPEAT_STATUS = `${context}/DISPATCH_UPDATE_REPEAT_STATUS`

// update action processing
export const UPDATE_ACTION_PROCESSING = `${context}/UPDATE_ACTION_PROCESSING`
export const SET_IS_LOADING_CHANGE_TIME = `${context}/SET_IS_LOADING_CHANGE_TIME`

export const SET_IS_ACTION_PROCESSING_SUBMIT_FORM = `${context}/SET_IS_ACTION_PROCESSING_SUBMIT_FORM`
export const SET_COUNT_DOWN_SUBMIT_FAIL = `${context}/SET_COUNT_DOWN_SUBMIT_FAIL`

export const FETCH_SETTINGS = `${context}/FETCH_SETTINGS`
export const UPDATE_SIZE_COLUMN_SETTINGS = `${context}/UPDATE_SIZE_COLUMN_SETTINGS`

// handle notification from upload csv of general booking
export const FETCH_BOOKING_DATA_AFTER_UPLOAD_CSV = `${context}/FETCH_BOOKING_DATA_AFTER_UPLOAD_CSV`

// update setting tenant (realtime)
export const UPDATE_TENANT_SETTING = `${context}/UPDATE_TENANT_SETTING`
export const UPDATE_TENANT_SETTING_SUCCESS = `${context}/UPDATE_TENANT_SETTING_SUCCESS`

export const SEND_MESSAGE_CUSTOMIZE = `${context}/SEND_MESSAGE_CUSTOMIZE`

export const FETCH_MESSAGE_LIST = `${context}/FETCH_MESSAGE_LIST`
export const FETCH_MESSAGE_LIST_SUCCESS = `${context}/FETCH_MESSAGE_LIST_SUCCESS`
export const FETCH_MESSAGE_LIST_FAIL = `${context}/FETCH_MESSAGE_LIST_FAIL`
export const CLEAR_MESSAGE_LIST = `${context}/CLEAR_MESSAGE_LIST`

export const TIME_ALLOW_SEND_MESSAGE = 31
export const SET_TIME_ALLOW_SEND_MESSAGES = `${context}/SET_TIME_ALLOW_SEND_MESSAGES`

export const UPDATE_FILTER_SEARCH_BOOKING = `${context}/UPDATE_FILTER_SEARCH_BOOKING`
export const CLEAR_FILTER_SEARCH_BOOKING = `${context}/CLEAR_FILTER_SEARCH_BOOKING`
export const SET_STATUS_TAP_BOOKING = `${context}/SET_STATUS_TAP_BOOKING`

export const FETCH_GROUP_BERTH_LIST = `${context}/FETCH_GROUP_BERTH_LIST`
export const FETCH_GROUP_BERTH_LIST_SUCCESS = `${context}/FETCH_GROUP_BERTH_LIST_SUCCESS`
export const FETCH_GROUP_BERTH_LIST_FAILURE = `${context}/FETCH_GROUP_BERTH_LIST_FAILURE`
export const UPDATE_GROUP_BERTH_LIST_META = `${context}/UPDATE_GROUP_BERTH_LIST_META`
export const CLEAR_GROUP_BERTH_LIST = `${context}/CLEAR_GROUP_BERTH_LIST`

export const SET_MESSAGE_COPY_PASTE_BOOKING = `${context}/SET_MESSAGE_COPY_PASTE_BOOKING`

export const [
  FETCH_YESTERDAY_BOOKING_LIST,
  FETCH_YESTERDAY_BOOKING_LIST_SUCCESS,
  FETCH_YESTERDAY_BOOKING_LIST_FAIL,
] = [
  `${context}/FETCH_YESTERDAY_BOOKING_LIST`,
  `${context}/FETCH_YESTERDAY_BOOKING_LIST_SUCCESS`,
  `${context}/FETCH_YESTERDAY_BOOKING_LIST_FAIL`,
]

export const DELETE_YESTERDAY_BOOKING = `${context}/DELETE_YESTERDAY_BOOKING`
export const DELETE_YESTERDAY_BOOKING_ITEMS = `${context}/DELETE_YESTERDAY_BOOKING_ITEMS`
export const DELETE_REPEAT_YESTERDAY_BOOKING = `${context}/DELETE_REPEAT_YESTERDAY_BOOKING`
export const UPDATE_YESTERDAY_BOOKING_SUCCESS = `${context}/UPDATE_YESTERDAY_BOOKING_SUCCESS`

export const FORCE_ANIMATION_BOOKING = `${context}/FORCE_ANIMATION_BOOKING`
export const SET_IS_DRAGGING_UNASSIGN_BOOKING = `${context}/SET_IS_DRAGGING_UNASSIGN_BOOKING`

export const TEN_SECONDS_ANIMATION_BOOKING = 10000
export const SETTING_TASK_LIST_LOAD_INFO = `${context}/SETTING_TASK_LIST_LOAD_INFO`

// Check time of user inactive in the berth
export const SECOND_CHECK_INTERVAL_INACTIVE = 1
export const TIME_RELOAD_INACTIVE = 300 // 5 minutes
export const EVENT_ACTIVES = [
  'mousedown',
  'mouseup',
  'click',
  'mousemove',
  'mouseover',
  'mousewheel',
  'keydown',
  'keyup',
  'keypress',
  'textInput',
  'touchstart',
  'touchmove',
  'touchend',
  'touchcancel',
  'resize',
  'scroll',
  'zoom',
  'focus',
]
