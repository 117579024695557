import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'

import DoubleArrowLeft from 'assets/svgs/double-arrow-left.svg'

export default ({ to }) => {
  const history = useHistory()
  return (
    <Button
      className="d-flex align-items-center rounded-pill bg-white text-primary"
      color="primary"
      outline
      onClick={() => history.push(to)}
    >
      <img src={DoubleArrowLeft} alt="double arrow left" className="mr-2" />
      はじめにもどる
    </Button>
  )
}
