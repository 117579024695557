import React, { useEffect } from 'react'
import { Route, Redirect, RouteProps, RouteComponentProps, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { userDataSelectors, receptionDataSelectors } from 'containers/App/selectors'
import errorMessages from 'utils/errorMessages'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { compareValue } from 'utils/commonFunctions'
import { Routes } from '../constants'
import { GeneralReceptionType } from '../type'
import { generalReceptionsSelectors, selectedGeneralReceptionSelectors } from '../selectors'
import { updateSelectedGeneralReception } from '../reducer'

type Props = RouteProps & {
  userData: any
  receptionData: any
  render: (
    param: RouteComponentProps & {
      hasAdminCompanyPermission: boolean
      hasManagerGeneralPermission: boolean
    }
  ) => React.ReactNode
  selectedGeneralReception: GeneralReceptionType
  generalReceptions: GeneralReceptionType[]
  updateSelectedGeneralReception: (data: GeneralReceptionType) => void
  showFlashMessage: (data: Object) => void
}

const PrivateRoute = ({
  userData,
  receptionData,
  selectedGeneralReception,
  generalReceptions,
  updateSelectedGeneralReception,
  showFlashMessage,
  render,
  ...rest
}: Props) => {
  const history = useHistory()

  const showWarning = () => {
    showFlashMessage({
      message: errorMessages.Unauthorized(),
      isError: true,
    })
  }

  // Update general reception when init (fresh) page if the selected is none
  useEffect(() => {
    const { generalReceptionId } = _.get(rest, 'computedMatch.params', {})
    const { path } = rest

    if (
      !_.isEmpty(generalReceptions) &&
      generalReceptions.length > 0 &&
      _.isEmpty(selectedGeneralReception)
    ) {
      // If the master page or create general, default choose the first page
      if (path === Routes.master || path === Routes.createGeneralReception) {
        updateSelectedGeneralReception(generalReceptions[0])

        return
      }

      // If the other page, choose by id
      if (path !== Routes.master && !!generalReceptionId) {
        const generalReceptionFind = _.find(generalReceptions, (generalReception) =>
          compareValue(generalReception.id, generalReceptionId)
        )

        if (!_.isEmpty(generalReceptionFind)) {
          updateSelectedGeneralReception(generalReceptionFind!)
        } else {
          showFlashMessage({
            message: errorMessages.GeneralReception.generalReceptionInvalid(),
            isError: true,
          })
          history.push(Routes.master)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalReceptions, updateSelectedGeneralReception, history, rest])

  return (
    <Route
      {...rest}
      render={({ match, ...restRouteProps }) => {
        const { is_warehouse_admin, is_general_manager } = _.get(userData, 'attributes', {})

        switch (match.path) {
          case Routes.master:
            {
              const isExistsReception = _.get(receptionData, 'is_exists_reception', false)
              if (
                !_.isEmpty(userData) &&
                !_.isEmpty(receptionData) &&
                !(is_warehouse_admin || (is_general_manager && isExistsReception))
              ) {
                showWarning()
                return <Redirect to="/" />
              }
            }
            break
          case Routes.createGeneralReception:
          case Routes.editGeneralReception:
          case Routes.addTenant:
          case Routes.addAdmin:
            if (!_.isEmpty(userData) && !is_warehouse_admin) {
              showWarning()
              return <Redirect to={Routes.master} />
            }
            break
          case Routes.addController:
            const isExistsReception = _.get(receptionData, 'is_exists_reception', false)
            if (
              !isExistsReception ||
              (!_.isEmpty(selectedGeneralReception) &&
                (!selectedGeneralReception.features?.canGeneralBooking ||
                  !(is_warehouse_admin || is_general_manager)))
            ) {
              showWarning()
              return <Redirect to={Routes.master} />
            }
            break
        }
        return (
          render &&
          render({
            hasAdminCompanyPermission: is_warehouse_admin,
            hasManagerGeneralPermission: is_general_manager,
            match,
            ...restRouteProps,
          })
        )
      }}
    />
  )
}

const mapStateToProps = createStructuredSelector({
  userData: userDataSelectors(),
  receptionData: receptionDataSelectors(),
  generalReceptions: generalReceptionsSelectors(),
  selectedGeneralReception: selectedGeneralReceptionSelectors(),
})

const mapDispatchToProps = {
  showFlashMessage,
  updateSelectedGeneralReception,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(PrivateRoute)
