import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import theme from 'vars/theme'

const StyledSelect = styled(Select)`
  .mono-select__indicator > svg {
    fill: ${theme.blueMedium5};
  }
`

const buildStyles = (size) => ({
  control: (provided) => ({
    ...provided,
    height: size === 'M' ? 40 : 52,
    border: '1px solid #CCC',
    fontSize: size === 'M' ? 18 : 24,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: size === 'M' ? 18 : 24,
  }),
  indicatorSeparator: () => ({}),
})

export default ({ options, value, onChange, size = 'M', isDisabled = false }) => (
  <StyledSelect
    options={options}
    value={value}
    placeholder="選択してください"
    styles={buildStyles(size)}
    classNamePrefix="mono-select"
    onChange={onChange}
    isSearchable={false} // for iPad
    isDisabled={isDisabled}
  />
)
