import RestClient, { getAuthHeader } from './RestClient'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})

export default class TenantServices {
  restService: RestClient

  constructor(restService = new RestClient()) {
    this.restService = restService
  }

  getCustomBerthLabel = (tenant_id: string | number) =>
    this.restService.get(
      '/berths/tenant',
      { tenant_id },
      {
        headers: headers(),
      }
    )
}
