import { compareValue } from 'utils/commonFunctions'
import { createSelector } from 'reselect'
import { context } from './constants'
import { WarehouseType, TenantType } from './type'

const cardListSelector = (state: any) => state.get(context)

export const loadingSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('loading'))

export const loadingRoleTenantSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('isLoadingRoleTenant'))

export const warehouseListSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('warehouseList')?.toJS())

export const warehouseIdSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('warehouseId'))

export const tenantIdSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('tenantId'))

export const selectedDaySelectors = () =>
  createSelector(cardListSelector, (state) => state.get('selectedDay')?.toJS())

export const listCardReceptionSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('listCardReception')?.toJS())

export const bookingMasterDataSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('bookingMaster')?.toJS())

export const listCardBookingsSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('listCards')?.toJS())

export const orderSelectors = () => createSelector(cardListSelector, (state) => state.get('order'))

export const roleTenantSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('roleTenant')?.toJS())

export const isLoadingRoleTenantSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('isLoadingRoleTenant'))

export const orderBySelectors = () =>
  createSelector(cardListSelector, (state) => state.get('orderBy'))

export const activeTenantSelectors = () =>
  createSelector(
    warehouseListSelectors(),
    tenantIdSelectors(),
    warehouseIdSelectors(),
    (warehouses, tenantId, warehouseId) => {
      const warehouse = warehouses?.find(({ id }: WarehouseType) => compareValue(id, warehouseId))

      if (warehouse) {
        const tenantItem = warehouse?.tenants?.find(({ id }: TenantType) =>
          compareValue(id, tenantId)
        )
        return tenantItem || {}
      }
    }
  )

export const modalOptionsConfirmSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('modalOptions')?.toJS())

export const paginationSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('pagination')?.toJS())

export const lastUpdateTimeSelectors = () =>
  createSelector(cardListSelector, (state) => state.get('lastUpdateTime'))
