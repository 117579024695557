import { createSingleAction } from 'utils/reduxActions'
import * as types from './constants'

export const getAuthenticationData = createSingleAction(types.GET_AUTHEN_DATA)

export const getUserData = createSingleAction(types.GET_USER_DATA)
export const getUserDataSuccess = createSingleAction(types.GET_USER_DATA_SUCCESS)
export const getUserDataFailure = createSingleAction(types.GET_USER_DATA_FAILURE)

export const [signOutUser, signOutUserSuccess, signOutUserFailure] = [
  createSingleAction(types.SIGN_OUT_USER),
  createSingleAction(types.SIGN_OUT_USER_SUCCESS),
  createSingleAction(types.SIGN_OUT_USER_FAILURE),
]
