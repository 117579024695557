import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import ModalPopup from 'components/ModalPopup'
import CircleNumber from 'components/CircleNumber'
import Button from './Button'

const determineJustifyContent = (index, length) => {
  if (length === 1) return 'center'

  const map = {
    0: 'flex-end',
    [length - 1]: 'flex-start',
  }
  return map[index] || 'center'
}

const Wrapper = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 74px;
  padding: 0 6px;
  color: #25272c;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  word-break: break-word;
`

export default ({ isModalOpen, hideModal, executeAssign, tenants, checkedWarehouseIds }) => (
  <ModalPopup
    isOpen={isModalOpen}
    title="この順番で割り当てますか？"
    centeredHeader
    isFullWidth
    content={
      <div>
        <div style={{ display: 'flex', margin: '0 auto', width: 'fit-content' }}>
          {checkedWarehouseIds.map((checkedWarehouseId, index) => (
            <div
              key={checkedWarehouseId}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: determineJustifyContent(index, checkedWarehouseIds.length),
                }}
              >
                {index !== 0 && (
                  <span style={{ marginRight: 4, marginTop: -4, whiteSpace: 'nowrap' }}>- - -</span>
                )}
                <CircleNumber num={index + 1} type="black-outline" />
                {index !== checkedWarehouseIds.length - 1 && (
                  <span style={{ marginLeft: 4, marginTop: -4, whiteSpace: 'nowrap' }}>- - -</span>
                )}
              </div>
              <Wrapper>
                {_.find(tenants, { generalReceptionTenantId: checkedWarehouseId }).tenantName}
              </Wrapper>
            </div>
          ))}
        </div>
      </div>
    }
  >
    <div className="w-100 d-flex flex-column">
      <div className="d-flex w-100 justify-content-end">
        <Button
          label="もどる"
          type="blue-outline"
          className="mr10"
          onClick={hideModal}
          style={{ height: 32 }}
        />
        <Button
          label="割り当て決定"
          type="blue-fill"
          onClick={executeAssign}
          style={{ height: 32 }}
        />
      </div>
    </div>
  </ModalPopup>
)
