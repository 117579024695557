import React, { FC } from 'react'
import _ from 'lodash'

import { compareValue } from 'utils/commonFunctions'
import * as S from './style'
import SingleForm from 'components/SingleForm'
import CreatableSelect from 'components/SelectOptions/CreatableSelect'
import {
  GeneralBooking,
  Company,
  CompanyDetail,
  InputSelect,
  GeneralMasterData,
  DriverDetail,
  BookingError,
} from 'containers/IntegratedManagementPage/type'
import { convertPhoneNumber } from 'utils/commonFunctions'

type Props = {
  defaultCompanyList: InputSelect[]
  initialBooking: GeneralBooking
  draftBooking: GeneralBooking
  masterData: GeneralMasterData
  remoteCompany: CompanyDetail
  remoteDriver: DriverDetail
  error: BookingError
  handleChangeDraftBooking: (data: object) => void
  resetRemoteCompany: () => void
}

const DriverInfoForm: FC<Props> = ({
  defaultCompanyList,
  initialBooking,
  draftBooking,
  masterData,
  remoteCompany,
  remoteDriver,
  error,
  handleChangeDraftBooking,
  resetRemoteCompany,
}) => {
  const convertToVehicleOption = (): InputSelect[] => {
    const { vehicleNumber } = initialBooking
    const data = (remoteCompany.vehicles || []).map((vehicle) => ({
      value: vehicle.simpleCarNumber,
      label: vehicle.simpleCarNumber,
    }))
    const initialOption =
      vehicleNumber && data.every((item) => !compareValue(item.value, vehicleNumber))
        ? [{ value: vehicleNumber, label: vehicleNumber }]
        : []
    return [{ value: '', label: '未定' }].concat(data).concat(initialOption)
  }

  const convertToVehicleCategoryOptions = (): JSX.Element => {
    const vehicleCategory: string = draftBooking?.vehicleCategory || ''

    return (
      <React.Fragment>
        <option value=""></option>
        {_.get(masterData, 'vehicleCategories', [])
          .filter(
            (vehicle) =>
              (vehicleCategory && compareValue(vehicle.parameterId, vehicleCategory)) ||
              vehicle.displayFlag
          )
          .map((vehicle) => (
            <option key={vehicle.parameterId} value={vehicle.parameterId}>
              {vehicle.parameterName}
            </option>
          ))}
      </React.Fragment>
    )
  }

  const getDriverName = (
    driver: any,
    driverId: string | number,
    driverName: string,
    remoteDriverName: string,
    remoteDriverId: string
  ) => {
    if (compareValue(driver?.id, remoteDriverId)) {
      return remoteDriverName
    }

    if (compareValue(driver?.id, driverId)) {
      return driverName
    }
    return _.get(driver, 'attributes.name')
  }

  const getDriversOption = (): InputSelect[] => {
    const { driverId, driverName } = initialBooking
    const { driverName: remoteDriverName, driverId: remoteDriverId } = remoteDriver
    const masterCompany = _.get(remoteCompany, 'drivers.data', [])
    const masterCompanyMap = masterCompany.map((driver: any) => ({
      value: driver.id,
      // to show correct old/new driver name of booking when checkin and change driver name, we get it from booking detail
      // Otherwise to show from list driver name
      // Booking driver name is kept the old value before being changed with confirm driver phone modal
      label: getDriverName(driver, driverId, driverName, remoteDriverName, remoteDriverId),
    }))
    const initialOption: any[] =
      driverId && masterCompanyMap.every((item: any) => !compareValue(item.value, driverId))
        ? [{ value: driverId, label: driverName }]
        : []
    const remoteDriverOption: any[] =
      !_.isEmpty(remoteDriver) &&
      !compareValue(driverId, remoteDriver.driverId) &&
      masterCompanyMap.every((item: any) => !compareValue(item.value, remoteDriver.driverId))
        ? [{ value: remoteDriver.driverId, label: remoteDriver.driverName }]
        : []

    return [{ value: '', label: '未定' }]
      .concat(masterCompanyMap)
      .concat(initialOption)
      .concat(remoteDriverOption)
  }

  const handleCreateSelectChange = (val: InputSelect, name: string) => {
    handleChangeDraftBooking({ [name]: val.value })
  }

  const handleInputChange = (val: number | string, name: string) => {
    handleChangeDraftBooking({ [name]: val })
  }

  const handleChangePhoneNumber = (val: string) => {
    if (typeof convertPhoneNumber(val) !== 'boolean') {
      handleChangeDraftBooking({
        driverPhone: convertPhoneNumber(val),
      })
    }
  }

  const onDriverCompanyIdChange = (val: InputSelect) => {
    const driverCompanyName = val.value && val.label ? val.label : ''
    const isChangeIdCompany =
      !val.value || Number(val.value) !== Number(draftBooking.driverCompanyId)
    const resetValue = isChangeIdCompany
      ? {
          vehicleNumber: '',
          vehicleCategory: '',
          driverId: '',
          driverName: '',
          driverPhone: '',
          driverEmail: '',
        }
      : {}
    handleChangeDraftBooking({
      driverCompanyId: val.value,
      driverCompanyName,
      ...resetValue,
    })
    if (isChangeIdCompany) resetRemoteCompany()
  }

  const onDriverIdChange = (val: InputSelect, name: string) => {
    const driverMaster = _.get(remoteCompany, 'drivers.data', [])
    const driver = driverMaster.find((it: any) => compareValue(it.id, val.value))
    const isSameInitial = compareValue(initialBooking.driverId, val.value)
    if (isSameInitial) {
      handleChangeDraftBooking({
        driverId: val.value || '',
        driverName: initialBooking.driverName || '',
        driverPhone: initialBooking.driverPhone || '',
        driverEmail: initialBooking.driverEmail || '',
      })
      return
    }
    if (compareValue(remoteDriver.driverId, val.value)) {
      handleChangeDraftBooking({
        driverId: val.value || '',
        driverName: remoteDriver.driverName || '',
        driverPhone: remoteDriver.driverPhone || '',
        driverEmail: remoteDriver.driverEmail || '',
      })
      return
    }
    if (driver) {
      handleChangeDraftBooking({
        driverId: val.value || '',
        driverName: _.get(driver, 'attributes.name') || '',
        driverPhone: _.get(driver, 'attributes.cellPhone') || '',
        driverEmail: _.get(driver, 'attributes.email') || '',
      })
      return
    }
    handleChangeDraftBooking({
      driverId: val.value || '',
      driverName: val.label || '',
      driverPhone: '',
      driverEmail: '',
    })
  }

  const disableDriver = () => {
    const { driverId } = draftBooking
    const driverMaster = _.get(remoteCompany, 'drivers.data', [])
    const hasDriver = driverMaster.some((it: any) => compareValue(it.id, driverId))
    const isSameInitial = compareValue(initialBooking.driverId, driverId)
    const sameDriver = compareValue(driverId, remoteDriver.driverId)
    return !driverId || hasDriver || isSameInitial || sameDriver
  }

  return (
    <S.FormBlockWrapper>
      <h3 className="font-weight-bold mb-3">運送会社情報</h3>
      <CreatableSelect
        label="運送会社名"
        placeholder=""
        options={defaultCompanyList}
        onChange={onDriverCompanyIdChange}
        value={draftBooking.driverCompanyId}
        isUnique
        notShowNewOption
        errorMessage={error.driverCompanyId || error.driverCompanyName}
      />
      <CreatableSelect
        label="車両番号"
        placeholder=""
        options={convertToVehicleOption()}
        onChange={(val: InputSelect) => handleCreateSelectChange(val, 'vehicleNumber')}
        value={draftBooking.vehicleNumber}
        isUnique
        notShowNewOption
        type="number"
        maxLength={4}
        isDisabled={!draftBooking.driverCompanyId}
        errorMessage={error.vehicleNumber}
      />
      <SingleForm
        id="vehicle_category"
        name="vehicle_category"
        type="select"
        label="車両形態"
        value={draftBooking.vehicleCategory}
        onChange={(value: string | number) => handleInputChange(value, 'vehicleCategory')}
        isDisabled={!draftBooking.driverCompanyId}
        errorMessage={error.vehicleCategory}
      >
        {convertToVehicleCategoryOptions()}
      </SingleForm>
      <CreatableSelect
        label="ドライバー名"
        placeholder=""
        options={getDriversOption()}
        value={draftBooking.driverId}
        onChange={onDriverIdChange}
        maxLength={50}
        isHightLight
        isUnique
        notShowNewOption
        isDisabled={!draftBooking.driverCompanyId}
        errorMessage={error.driverId || error.driverName}
      />
      <SingleForm
        id="driver_phone"
        name="driver_phone"
        label="電話番号"
        value={draftBooking.driverPhone}
        onChange={handleChangePhoneNumber}
        required
        isDisabled={disableDriver()}
        errorMessage={error.driverPhone}
      />
      <SingleForm
        id="driver_email"
        name="driver_email"
        label="メールアドレス"
        value={draftBooking.driverEmail}
        onChange={(value: string) => handleInputChange(value, 'driverEmail')}
        isDisabled={disableDriver()}
        errorMessage={error.driverEmail}
      />
    </S.FormBlockWrapper>
  )
}

export default DriverInfoForm
