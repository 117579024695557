export const context = 'receptionCustom'

export const HEADER_HEIGHT = 60

export const TAB_HEIGHT = 30 + 16 * 2

export const FOOTER_HEIGHT = 38 + 16 * 2

export const TAB_MODE = {
  WAITING_SETTING: 'waiting-setting',
  TIME_SETTING: 'time-setting',
  COUNT_TRUCK_SETTING: 'count-truck-setting',
}

export const COUNT_TRUCK_SETTING_MODE = {
  TRUCK_CHECKED_BUT_NOT_IN_BERTH: 1,
  ALL_CARD_CHECKED: 2,
}

export const typeRow = {
  BASE: 'base',
  IMPORT: 'import',
  ONLY_CHECK_BOX: 'onlyCheckbox',
}

const INITIAL_VALUE_COMMON = {
  timeMin: '',
  timeMax: '',
  timeAverage: '',
  waitingFlag: false,
  waitingTimeFlag: false,
}

export const INITIAL_WAITING_SETTING = {
  ...INITIAL_VALUE_COMMON,
  type: null,
  countType: null,
}

export const INITIAL_TIME_SETTING = {
  ...INITIAL_VALUE_COMMON,
  type: 1,
}

export const INITIAL_COUNT_TRUCK_SETTING = {
  ...INITIAL_VALUE_COMMON,
  countType: 1,
}

export const WAITING_TIME = 5
export const TOTAL_WAITING_TIME = 360

export const calculateTimeWaitingBooking = Array(TOTAL_WAITING_TIME / WAITING_TIME)
  .fill()
  .map((_, i) => (i + 1) * WAITING_TIME)

export const getInitialValueForTabMode = (tabMode) => {
  switch (tabMode) {
    case TAB_MODE.TIME_SETTING:
      return INITIAL_TIME_SETTING
    case TAB_MODE.COUNT_TRUCK_SETTING:
      return INITIAL_COUNT_TRUCK_SETTING

    default:
      return INITIAL_WAITING_SETTING
  }
}

export const dataSimpleCardItem = [
  {
    label: '項目 1',
    order: 1,
  },
  {
    label: '項目 2',
    order: 2,
  },
  {
    label: '項目 3',
    order: 3,
  },
  {
    label: '項目 4',
    order: 4,
  },
  {
    label: '項目 5',
    order: 5,
  },
  {
    label: '項目 6',
    order: 6,
  },
]

export const CardLabelItem = {
  companyName: '運送会社名［車番］',
  packageType: '荷姿',
  vehicleCategory: '車両形態',
  goodsOwner: '荷主',
  goodsName: '荷物名',
  driverName: 'ドライバー氏名',
  goodsAmount: '荷量',
  memo: 'メモ',
  importDeliverySlipNumber: '伝票番号',
  contactNotes: '連絡事項',
}

export const cardLabelPreviewItem = {
  [CardLabelItem.companyName]: '運送会社名[00-00]',
  [CardLabelItem.packageType]: '荷姿',
  [CardLabelItem.vehicleCategory]: '車両形態',
  [CardLabelItem.goodsOwner]: '荷主',
  [CardLabelItem.goodsName]: '荷物名',
  [CardLabelItem.driverName]: '氏名',
  [CardLabelItem.goodsAmount]: '荷量',
  [CardLabelItem.memo]: 'メモ',
  [CardLabelItem.importDeliverySlipNumber]: '伝票番号',
  [CardLabelItem.contactNotes]: '連絡事項',
}
