import React, { Component, Fragment } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchAnything } from 'containers/App/reducers'

import Header from '../components/Header'
import RowButton from '../components/RowButton'
import { replaceBooking, clearBooking } from '../reducers'
import PrevStartButton from '../components/PrevStartButton'
import { HEADER_HEIGHT, DATE_TIME_FORMAT, DATE_TIME_FORMAT_API } from '../constants'
import PageNumber from '../components/PageNumber'
import AlertModal from 'components/AlertModal'
class EntryBookingsPage extends Component {
  state = {
    isOpenModalSubmitError: false,
  }

  componentDidMount() {
    const { match, driver, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
      return
    }

    this.props.fetchAnything({
      url: '/tablets/booking_list',
      key: 'bookings',
      storePath: 'bookings',
      data: {
        warehouseId,
        tenantId,
        periodType: 2,
        driverPhone: driver.driverPhone,
      },
    })
  }

  isValidCheckinTimeBooking = (startDate, startTime, presetMinutesForCheckinTime) => {
    if (presetMinutesForCheckinTime) {
      const currentDay = moment(moment().utcOffset('+09:00').format(DATE_TIME_FORMAT))
      const startTimeBooking = moment(`${startDate} ${startTime}`, DATE_TIME_FORMAT_API)
      const timeAlowChekinBooking = startTimeBooking.subtract(
        presetMinutesForCheckinTime,
        'minutes'
      )

      return currentDay.isBefore(timeAlowChekinBooking)
    }
  }

  onClickBooking = (bookingNo, startDate, startTime) => {
    const { match, history, bookings, driver } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const canSettingCheckinTime = driver?.tenant?.canSettingCheckinTime

    const isPresetMinutesForCheckinBooking = this.isValidCheckinTimeBooking(
      startDate,
      startTime,
      driver?.tenant?.presetMinutesForCheckin
    )

    if (canSettingCheckinTime && isPresetMinutesForCheckinBooking) {
      this.setState({ isOpenModalSubmitError: true })
    } else {
      const booking = bookings.find((b) => b.bookingNo === bookingNo)
      const newBooking = { ...booking }
      this.props.replaceBooking(newBooking)

      history.push(`/receipt-booking/entry-driver-info/${warehouseId}/${tenantId}`)
    }
  }

  onClickEmptyBooking = () => {
    const { match, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    this.props.clearBooking()
    history.push(`/receipt-booking/entry-driver-info/${warehouseId}/${tenantId}`)
  }

  handleClickCloseModal = () => {
    this.setState({ isOpenModalSubmitError: false })
  }

  render() {
    const { match, bookings, driver, driverConfirmations, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { isOpenModalSubmitError } = this.state
    const presetMinutesForCheckin = driver?.tenant?.presetMinutesForCheckin
    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header driver={driver} />

        <div
          className="pt-3 px-3"
          style={{ height: window.innerHeight - HEADER_HEIGHT - 138, overflowY: 'auto' }}
        >
          <div className="bg-white p-3" style={{ borderRadius: 12 }}>
            <div className="text-center mx-auto" style={{ width: 504 }}>
              <div className="mb-3 text-size32 font-weight-bold">予約の確認</div>

              {bookings.length ? (
                <Fragment>
                  <div className="mb-3">あなたの電話番号で以下の予約があります</div>
                  <div className="d-flex mb-3 border-bottom">
                    <div style={{ marginLeft: 54 }}>予約日時</div>
                    <div style={{ marginLeft: 162 }}>予約番号</div>
                  </div>
                </Fragment>
              ) : (
                <div className="mb-3">あなたの電話番号で予約はありません</div>
              )}
            </div>

            {Boolean(bookings.length) && (
              <div className="mx-auto" style={{ width: 504 }}>
                {bookings.map((booking) => (
                  <div key={booking.bookingNo} className="mb-3">
                    <RowButton
                      onClick={() => {
                        this.onClickBooking(
                          booking.bookingNo,
                          booking?.startDate,
                          booking?.startTime
                        )
                      }}
                      booking={booking}
                    >
                      <span>{moment(booking.planStartDate).format('M')}</span>
                      <span style={{ fontSize: 18 }}>月</span>
                      <span>{moment(booking.planStartDate).format('D')}</span>
                      <span className="mr-2" style={{ fontSize: 18 }}>
                        日
                      </span>
                      <span style={{ fontSize: 18 }}>
                        {booking.planStartTime
                          ? moment(
                              `${booking.planStartDate} ${booking.planStartTime}`,
                              'YYYYMMDD HH:mm'
                            ).format('HH:mm')
                          : '--:--'}
                        <span className="px-1">〜</span>
                        {booking.planEndTime
                          ? moment(
                              `${booking.planEndDate} ${booking.planEndTime}`,
                              'YYYYMMDD HH:mm'
                            ).format('HH:mm')
                          : '--:--'}
                      </span>
                    </RowButton>
                  </div>
                ))}
              </div>
            )}

            <div
              className="p-3"
              style={{
                position: 'fixed',
                backgroundColor: '#ededed',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <div className="d-flex justify-content-center pb-3">
                <Button
                  color="primary"
                  outline
                  className="px-4 mr-3 text-size24 rounded-pill bg-white text-primary"
                  style={{ height: 52 }}
                  onClick={() =>
                    history.push(`/receipt-booking/entry-booking-number/${warehouseId}/${tenantId}`)
                  }
                >
                  リストにない予約番号で受付
                </Button>
                <Button
                  color="primary"
                  outline
                  className="px-4 text-size24 rounded-pill bg-white text-primary"
                  style={{ height: 52 }}
                  onClick={this.onClickEmptyBooking}
                >
                  予約なし受付
                </Button>
              </div>
              <div className="d-flex justify-content-between">
                <div style={{ width: '33%' }}>
                  <PrevStartButton to={`/receipt-booking/${warehouseId}/${tenantId}`} />
                </div>
                <div className="text-center" style={{ width: '34%' }}>
                  <PageNumber
                    page="entry-bookings"
                    existsDriverConfirmations={Boolean(driverConfirmations.length)}
                    isFreePlan={!driver.tenant.canBerthBook}
                  />
                </div>
                <div className="text-right" style={{ width: '33%' }} />
              </div>
            </div>
          </div>
        </div>

        <AlertModal
          isOpen={isOpenModalSubmitError}
          title={`受付時間外です。<br/>予約開始時間から${presetMinutesForCheckin}分前に受付できます。`}
          textOk="OK"
          handleConfirm={this.handleClickCloseModal}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
    booking: reception.booking,
    bookings: reception.bookings,
    driverConfirmations: reception.driverConfirmations,
    returnEntryPage: reception.returnEntryPage,
  }
}

const mapDispatchToProps = {
  fetchAnything,
  replaceBooking,
  clearBooking,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryBookingsPage)
