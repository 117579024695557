import React from 'react'
import styled from 'styled-components'

const Badge = styled.div`
  position: absolute;
  top: -20px;
  right: -5px;
  font-size: 9px;
  border-radius: 8px;
  color: white;
  background-color: #d7738d;
  padding: 0 4px;
  min-width: 15px;
  text-align: center;
`

export default ({ count, style }) => <Badge style={style}>{count}</Badge>
