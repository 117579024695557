import { fromJS } from 'immutable'
import { handleActions, createActions } from 'redux-actions'
import _ from 'lodash'

import { context } from './constants'
import { RequestGeneralReceptionType } from './type'

const initialState = fromJS({
  loading: false,
  generalReceptions: [],
  error: null,
  selectedGeneralReception: {},
  selectedGeneralReceptionTenant: {
    totalPage: 0,
    page: 0,
    totalItem: 0,
    generalReceptionTenants: [],
  },
  selectedGeneralReceptionAdmin: {
    totalPage: 0,
    totalCount: 0,
    page: 0,
    userGeneralReception: [],
  },
  selectedGeneralReceptionController: {
    totalPage: 0,
    totalCount: 0,
    page: 0,
    userGeneralReception: [],
  },
})

export const {
  startLoading,
  endLoading,
  fetchFailed,
  fetchSuccess,
  fetchGeneralReceptions,
  fetchGeneralReceptionsSuccess,
  updateSelectedGeneralReception,
  fetchGeneralReceptionTenants,
  fetchGeneralReceptionAdmin,
  fetchGeneralReceptionController,
  resetAllData,
} = createActions(
  {
    START_LOADING: _.noop,
    END_LOADING: _.noop,
    FETCH_FAILED: (error) => error,
    FETCH_SUCCESS: (data) => data,
    FETCH_GENERAL_RECEPTIONS: _.noop,
    UPDATE_SELECTED_GENERAL_RECEPTION: (data) => data,
    FETCH_GENERAL_RECEPTION_TENANTS: (
      generalReceptionId: number,
      page: number,
      perPage: number
    ): RequestGeneralReceptionType => ({ generalReceptionId, page, perPage }),
    FETCH_GENERAL_RECEPTION_ADMIN: (
      generalReceptionId: number,
      page: number,
      perPage: number
    ): RequestGeneralReceptionType => ({
      generalReceptionId,
      page,
      perPage,
    }),
    FETCH_GENERAL_RECEPTION_CONTROLLER: (
      generalReceptionId: number,
      page: number,
      perPage: number
    ): RequestGeneralReceptionType => ({
      generalReceptionId,
      page,
      perPage,
    }),
    RESET_ALL_DATA: _.noop,
  },
  { prefix: context }
)

export const reducer = handleActions(
  {
    START_LOADING: (state) => state.set('loading', true),
    END_LOADING: (state) => state.set('loading', false),
    FETCH_FAILURE: (state, { payload }) => state.set('loading', false).set('error', payload),
    FETCH_SUCCESS: (state, { payload: { key, data } }) =>
      state.set('loading', false).set(key, data),
    UPDATE_SELECTED_GENERAL_RECEPTION: (state, { payload }) =>
      state.set('selectedGeneralReception', fromJS(payload)),
    RESET_ALL_DATA: (state) => state.merge(initialState.toJS()),
  },
  initialState,
  { prefix: context }
)
