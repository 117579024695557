import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import moment from 'moment'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'

export class DayPickerForm extends React.Component {
  render() {
    const { value, onChange, isDisabled } = this.props
    return (
      <DayPickerInput
        onDayChange={onChange}
        value={value}
        placeholder="YYYY-MM-DD"
        inputProps={{ readOnly: true, disabled: isDisabled }}
        dayPickerProps={{
          localeUtils: MomentLocaleUtils,
          locale: 'ja',
          firstDayOfWeek: 0,
          captionElement: ({ date }) => (
            <div className="DayPicker-Caption" role="heading">
              <div>{moment(date).format('YYYY年 MM月')}</div>
            </div>
          ),
        }}
      />
    )
  }
}

export default DayPickerForm
