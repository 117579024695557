import freezeR from 'utils/freezeR'

export const NoticeFilters = freezeR({
  CRITICAL: {
    id: 'CRITICAL',
    name: '重要なお知らせ',
  },
  NORMAL: {
    id: 'NORMAL',
    name: 'お知らせ',
  },
  MAINTENANCE: {
    id: 'MAINTENANCE',
    name: 'メンテナンス',
  },
  UNREAD: {
    id: 'UNREAD',
    name: '未読',
  },
  ALL: {
    id: 'ALL',
    name: 'すべて',
  },
})
