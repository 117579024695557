import React from 'react'

import Icon from 'components/Icon'

export default (
  <div className="mx-5 my-4">
    <Icon
      src="/assets/svgs/check-icon.svg"
      alt="check-icon"
      width="58px"
      height="58px"
      className="mb-3"
    />
    <div>登録完了</div>
  </div>
)
