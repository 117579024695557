import React from 'react'

import Header from 'components/Layout/Header'
import StyledSelect from 'containers/IntegratedManagementPage/components/StyledSelect'

export default ({
  warehouses,
  tenants,
  selectedWarehouse,
  selectedTenant,
  onChangeWarehouse,
  onChangeTenant,
}) => (
  <Header>
    <div className="d-flex justify-content-end w-100">
      <StyledSelect
        width="180px"
        options={warehouses}
        value={selectedWarehouse}
        onChange={onChangeWarehouse}
      />
      <div className="m-1" />
      <StyledSelect
        width="180px"
        options={tenants}
        value={selectedTenant}
        onChange={onChangeTenant}
      />
    </div>
  </Header>
)
