import React, { Component } from 'react'

import _get from 'lodash/get'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'

import {
  userDataSelectors,
  selectNotices,
  receptionDataSelectors,
  userBookingPermissionSelectors,
  loadingLogoutSelectors,
  customBerthLabelSelectors,
} from 'containers/App/selectors'
import { isRenderCSVSelectors, userRoleSelectors } from 'containers/AppPrivate/selectors'
import Sidebar from 'components/Layout/Sidebar'
import Icon from 'components/Icon'
import { signOutUser } from 'containers/App/actions'
import { HeaderWrapper, ButtonDropdownWrapper, LoadingIndicatorLogout } from './style'
import CriticalNotice from './CriticalNotice'
import { NoticeFilters } from 'constants/NoticeFilters'
import Badge from 'components/Badge'
import { formatUserRoleName } from 'utils/formatters'
import { ROLES } from 'constants/misc'

const LeftSideStyled = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
`

const RightSideStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const CustomDropdownItem = styled(DropdownItem)`
  span {
    overflow: visible !important;
  }
`

export class Header extends Component {
  state = {
    isDropdownOpen: false,
    isSidebarOpen: false,
  }

  componentDidMount() {
    Modal.setAppElement(this.element)
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }))
  }

  openSidebar = () => {
    this.setState({ isSidebarOpen: true })
  }

  closeSidebar = () => {
    this.setState({ isSidebarOpen: false })
  }

  handleLogoutClick = () => {
    this.toggleDropdown()
    this.props.dispatchSignOutUser()
  }

  render() {
    const {
      subTitle,
      children,
      userData,
      receptionData,
      userBookingPermission,
      isRenderCsv,
      notices,
      userRole,
      loadingLogout,
      customBerthLabel,
    } = this.props
    const unreadNotices = notices.filter((notice) => !notice.read)
    const isShowCriticalNotice = Boolean(
      notices.find((notice) => {
        if (notice.popupEndDate && moment().isAfter(notice.popupEndDate, 'day')) {
          return false
        }
        return (
          notice.category === NoticeFilters.CRITICAL.id &&
          notice.read === false &&
          notice.showPopup === true
        )
      })
    )

    const { attributes: { is_warehouse_admin } = {} } = userData

    return (
      <HeaderWrapper>
        <LeftSideStyled>
          <Icon
            src="/assets/svgs/hamburger-menu.svg"
            alt="menu"
            type="button"
            onClick={this.openSidebar}
          />
          <div style={{ position: 'relative' }}>
            {/* お知らせバッジ */}
            {Boolean(unreadNotices.length) && <Badge count={unreadNotices.length} />}
          </div>
          <Icon
            src="/assets/svgs/monoful-brand.svg"
            alt="monoful brand"
            className="ml-1 monoful-brand"
            width="100px"
          />
          <p className="font-md mb-0 ml-1 mr-2">トラック簿</p>
        </LeftSideStyled>
        <RightSideStyled>
          {children}
          <div className="d-flex justify-content-center">
            <h2 className="font-normal font-weight-bold">{subTitle}</h2>
            <div className="divider" />
            <ButtonDropdownWrapper isOpen={this.state.isDropdownOpen} toggle={this.toggleDropdown}>
              <DropdownToggle caret>
                <span className="ml-4 font-md">{_get(userData, 'attributes.name', '')}</span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled>
                  <div className="text-muted text-left">
                    {formatUserRoleName(is_warehouse_admin ? ROLES.SUPER_ADMIN : userRole)}
                  </div>
                </DropdownItem>
                <DropdownItem divider />
                <CustomDropdownItem className="btn-logout" onClick={this.handleLogoutClick}>
                  <span title="ログアウト" className="text-primary text-left">
                    ログアウト
                  </span>
                </CustomDropdownItem>
              </DropdownMenu>
            </ButtonDropdownWrapper>
            <div
              ref={(ref) => {
                this.element = ref
              }}
            >
              <Sidebar
                isOpen={this.state.isSidebarOpen}
                onRequestClose={this.closeSidebar}
                userData={userData}
                receptionData={receptionData}
                isRenderCsv={isRenderCsv}
                userBookingPermission={userBookingPermission}
                unreadNoticeCount={unreadNotices.length}
                customBerthLabel={customBerthLabel}
              />
            </div>
          </div>
        </RightSideStyled>
        {isShowCriticalNotice && <CriticalNotice />}
        {loadingLogout && <LoadingIndicatorLogout />}
      </HeaderWrapper>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userData: userDataSelectors(),
  receptionData: receptionDataSelectors(),
  userBookingPermission: userBookingPermissionSelectors(),
  isRenderCsv: isRenderCSVSelectors(),
  notices: selectNotices(),
  userRole: userRoleSelectors(),
  loadingLogout: loadingLogoutSelectors(),
  customBerthLabel: customBerthLabelSelectors(),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSignOutUser: () => dispatch(signOutUser()),
})

Header.defaultProps = {
  userData: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
