import React, { Fragment } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import _ from 'lodash'

import { BookingStatuses, GeneralBookingWaitingStatuses } from 'constants/misc'

import CheckCircleGray from 'assets/svgs/multi-booking/check-circle-gray.svg'

import CircleNumber from 'components/CircleNumber'
import Button from './Button'
import { ReactComponent as PencilIcon } from 'assets/svgs/multi-booking/pencil.svg'

import VehicleSummaryBookingEditor from './VehicleSummaryBookingEditor'
import VehicleSummary from './VehicleSummary'
import {
  GeneralBookingStatuses,
  BookingSidePanelType,
  AssignType,
  checkCanPreAssign,
  STANDARD_FULL_TIME_FORMAT,
  NON_VALUE_TEXT,
} from '../constants'
import { BookingItemThemes } from 'containers/HomePage/containers/BerthSchedule/components/BookingItem/style'
import BookingStatus from './BookingStatus'
import AssignButton from './AssignButton'

const ResetOrderButton = styled(Button)`
  margin-right: 20px;
  white-space: nowrap;
`

export const ContentTextWrapper = styled.div`
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
`

const convertToStatusLabel = (statusNumber) => {
  const bookingStatuses = Object.values(BookingStatuses)

  for (let i = 0; i < bookingStatuses.length; i += 1) {
    const bookingStatus = bookingStatuses[i]
    if (bookingStatus.id === statusNumber) {
      return bookingStatus.label
    }
  }

  return ''
}

const determineCircleColor = (foundTenant) => {
  if (foundTenant.deletedDate) {
    return 'gray-fill'
  }

  // 受付完了のときは一番最初だけ赤色にする
  if (foundTenant.bookingStatus === BookingStatuses.Recept.id) {
    switch (foundTenant.generalBookingWaitingStatus) {
      case GeneralBookingWaitingStatuses.WAITING_ZERO.id:
        return `${BookingItemThemes[BookingStatuses.Recept.id].bgColor}-fill`
      case GeneralBookingWaitingStatuses.WAITING_ONE.id:
        return `${BookingItemThemes[BookingStatuses.WaitingOne.id].bgColor}-fill`
      case GeneralBookingWaitingStatuses.WAITING_TWO.id:
        return `${BookingItemThemes[BookingStatuses.WaitingTwo.id].color}-outline`
      default:
        break
    }
  }

  // 受付完了以外のステータスのときはそのまま表示
  const theme = BookingItemThemes[foundTenant.bookingStatus]
  const style = ['white', '#FFFFFF'].includes(theme.bgColor) ? 'outline' : 'fill'
  const color = style === 'outline' ? theme.color : theme.bgColor
  return `${color}-${style}`
}

const renderLastBooking = (generalBookingOrders) => {
  if (!generalBookingOrders) return '-'

  const unDeleteBookings = _.filter(generalBookingOrders, ['deletedDate', null]) // Remove deleted booking
  const lastBooking = _.maxBy(
    unDeleteBookings,
    (generalBookingOrder) => generalBookingOrder.bookingOrder
  )

  return lastBooking && lastBooking.checkoutDate ? (
    <Fragment>
      <img src={CheckCircleGray} alt="" />
      <ContentTextWrapper>{moment(lastBooking.checkoutDate).format('H:mm')}</ContentTextWrapper>
    </Fragment>
  ) : (
    '-'
  )
}

const renderAssignButton = (booking, onClick, onClickCloseDetail, onHandleAcceptDraftOrder) => {
  const { generalBookingStatus, generalBookingOrders } = booking || {}

  // Show pre-assign button
  if (checkCanPreAssign(generalBookingStatus)) {
    return (
      <CustomButton
        label="仮割当する"
        type="blue-outline"
        onClick={(e) => onClick(e, AssignType.PRE_ASSIGN)}
      />
    )
  }

  // Show assign button
  if (generalBookingStatus === GeneralBookingStatuses.CHECKIN.id) {
    if (_.isEmpty(generalBookingOrders)) {
      return (
        <CustomButton
          label="割当する"
          type="red-fill"
          onClick={(e) => onClick(e, AssignType.ASSIGN)}
        />
      )
    }

    return (
      <AssignButton
        onClickAssign={(e) => onClick(e, AssignType.ASSIGN)}
        onClickCloseDetail={onClickCloseDetail}
        onHandleAcceptDraftOrder={() =>
          onHandleAcceptDraftOrder(booking.generalBookingId, generalBookingOrders)
        }
      />
    )
  }

  // Show re-assign button
  if (
    _.get(
      _.maxBy(
        generalBookingOrders || [],
        (generalBookingOrder) => generalBookingOrder.bookingOrder
      ),
      'bookingStatus'
    ) >= BookingStatuses.Calling.id
  ) {
    // Disabled re-assign button
    return <CustomButton label="再割当する" type="gray-fill" disabled />
  }
  // Enable re-assign button
  return (
    <CustomButton
      label="再割当する"
      type="blue-outline"
      onClick={(e) => onClick(e, AssignType.RE_ASSIGN)}
    />
  )
}

const findLabelOfStatus = (id) => {
  const value = Object.values(GeneralBookingStatuses)?.find((status) => status.id === id)
  return value?.labelTime || ''
}

const getCheckinTime = (booking) => {
  const { startDate, planStartTime, generalBookingStatus } = booking
  const time = startDate || planStartTime

  return `${findLabelOfStatus(generalBookingStatus)}/${
    time
      ? moment(time, !startDate && planStartTime && STANDARD_FULL_TIME_FORMAT).format('H:mm')
      : NON_VALUE_TEXT
  }`
}

export default ({
  warehouses,
  booking,
  role,
  onClick,
  onClickToggleWarehouse,
  onClickAssign,
  // onClickCancel,
  // onClickDecide,
  operationType,
  checkedWarehouseIds,
  style,
  vehicleCategoryMap,
  packageTypeMap,
  onClickResetDraftTenant,
  updateBookingSidePanelData,
  onClickCloseDetail,
  onHandleAcceptDraftOrder,
  onOpenChangeStatusModal,
  disableAssign,
  isCarBookingEditor,
  // colWidth,
  // rref,
}) => (
  // const Operations = {
  //   assign: {
  //     label: '割り当てる',
  //     onClick: onClickAssign,
  //     // svg: AssignButton,
  //   },
  //   decide: {
  //     label: '決定', // TODO 確認のほうがいいかも
  //     onClick: onClickDecide,
  //     // svg: DecideButton,
  //   },
  // }

  // const operation = Operations[operationType]

  <tr
    key={booking.generalBookingId}
    // ref={el => {
    //   rref(el)
    // }}
    className={operationType === 'assign' ? 'can-hover' : ''}
    style={{
      // backgroundColor:
      //   operationType === 'decide' ? 'white' : 'white',
      // padding: '14px 0',
      borderBottom: '1px solid #CCC',
      // position: 'relative',
      zIndex: 1049,
      cursor: operationType === 'assign' ? 'pointer' : '',
      ...style,
    }}
    onClick={onClick}
  >
    {/* 各種情報 */}
    <td style={{ padding: '6px 10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {operationType === 'assign' && (
          <span
            onClick={(e) => {
              e.stopPropagation()
              onClickCloseDetail()
              updateBookingSidePanelData({
                openType: BookingSidePanelType.EDIT,
                bookingId: booking.generalBookingId,
                canDeleteBooking: (booking.generalBookingOrders || []).every(
                  ({ bookingStatus }) => bookingStatus < BookingStatuses.Calling.id
                ),
              })
            }}
            style={{
              padding: '8px',
              marginRight: '8px',
            }}
          >
            <PencilIcon alt="編集" className="" width={16} height={16} />
          </span>
        )}
        {isCarBookingEditor ? (
          <VehicleSummaryBookingEditor
            booking={booking}
            vehicleCategoryMap={vehicleCategoryMap}
            packageTypeMap={packageTypeMap}
          />
        ) : (
          <VehicleSummary
            booking={booking}
            vehicleCategoryMap={vehicleCategoryMap}
            packageTypeMap={packageTypeMap}
          />
        )}
      </div>
    </td>

    {/* 操作ボタン */}
    <td style={{ textAlign: 'center' }}>
      {operationType === 'assign' && (
        <>
          <WrapperButton>
            {booking.generalBookingStatus === GeneralBookingStatuses.BOOKING.id && (
              <CustomButton
                label="予約済にする"
                type="blue-fill"
                onClick={(event) => {
                  onOpenChangeStatusModal(event, booking)
                }}
              />
            )}
            {!disableAssign &&
              (booking.generalBookingStatus === GeneralBookingStatuses.CONFIRM.id ||
                booking.generalBookingStatus === GeneralBookingStatuses.DELAY.id) && (
                <CustomButton
                  label="受付済にする"
                  type="red-fill"
                  disabled={!(role.adminCompany || role.adminGeneralReception)}
                  onClick={(event) => {
                    onOpenChangeStatusModal(event, booking)
                  }}
                />
              )}
          </WrapperButton>
          {!disableAssign &&
            renderAssignButton(
              booking,
              onClickAssign,
              onClickCloseDetail,
              onHandleAcceptDraftOrder
            )}
        </>
      )}
    </td>

    {/* 総合受付 */}
    <td style={{ textAlign: 'center' }}>
      <div style={{ display: 'inline-block' }}>
        <BookingStatus status={booking.generalBookingStatus} />
      </div>
      <ContentTextWrapper>{booking && getCheckinTime(booking)}</ContentTextWrapper>
    </td>

    {/* テナント */}
    {warehouses.map((warehouse) => {
      const foundTenant = booking.generalBookingOrders
        ? booking.generalBookingOrders.find(
            (tenant) => tenant.generalReceptionTenantId === warehouse.generalReceptionTenantId
          )
        : null
      return (
        <td key={warehouse.generalReceptionTenantId} style={{ textAlign: 'center' }}>
          {operationType === 'decide' ? (
            <Fragment>
              <Checkbox
                // checked={checkedWarehouseIds.includes(warehouse.id)}
                index={checkedWarehouseIds.indexOf(warehouse.generalReceptionTenantId) + 1}
                onClick={() => onClickToggleWarehouse(warehouse.generalReceptionTenantId)}
                disabled={foundTenant && foundTenant.bookingStatus >= BookingStatuses.Calling.id}
              />
              <div style={{ fontSize: 10 }}>-</div>
            </Fragment>
          ) : (
            <div>
              {foundTenant ? (
                <CircleNumber
                  num={foundTenant.bookingOrder}
                  type={determineCircleColor(foundTenant)}
                />
              ) : (
                <CircleNumber type="gray-outline" />
              )}
              <ContentTextWrapper>
                {foundTenant &&
                (![
                  GeneralBookingWaitingStatuses.WAITING_ONE.id,
                  GeneralBookingWaitingStatuses.WAITING_TWO.id,
                ].includes(foundTenant.generalBookingWaitingStatus) ||
                  foundTenant.deletedDate)
                  ? `${convertToStatusLabel(
                      foundTenant.deletedDate
                        ? BookingStatuses.Deleted.id
                        : foundTenant.bookingStatus
                    )}/ ${moment(foundTenant.updatedDate).format('H:mm')}`
                  : '-'}
              </ContentTextWrapper>
            </div>
          )}
        </td>
      )
    })}
    {/* 退場 */}
    <td style={{ width: 80, textAlign: 'center' }}>
      {renderLastBooking(booking.generalBookingOrders)}
    </td>
    {/* すべての選択を外す */}
    {operationType === 'decide' && (
      <td style={{ textAlign: 'center' }}>
        <ResetOrderButton
          type={checkedWarehouseIds.length ? 'blue-link' : 'blue-outline'}
          label="すべての選択を外す"
          disabled={!checkedWarehouseIds.length}
          onClick={onClickResetDraftTenant}
        />
      </td>
    )}
  </tr>
)

export const Colgroup = ({ tenants, operationType }) => (
  <colgroup>
    <col style={{ width: 350 }} />
    <col style={{ width: 120 }} />
    <col style={{ width: 120 }} />
    {tenants.map((tenant) => (
      <col
        key={tenant.generalReceptionTenantId}
        style={
          {
            // width: `calc((100% - 240px - 100px - 100px - 80px) / ${
            //   tenants.length
            // })`,
          }
        }
      />
    ))}
    <col style={{ width: 80 }} />
    {operationType === 'decide' && <col style={{ width: 160 }} />}
  </colgroup>
)

const Th = styled.th`
  top: 0;
  background-color: white;
  padding-top: 20px;
  vertical-align: bottom;
  z-index: 3;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: #434d63;
    z-index: 3;
  }
`

const WrapperContent = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Thead = ({ tenants, setRef, isSticky, operationType }) => (
  <thead ref={setRef}>
    <tr>
      <Th
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          borderRadius: '12px 0 0 0',
          position: isSticky ? 'sticky' : 'static',
        }}
      />
      <Th
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          borderRadius: '12px 0 0 0',
          position: isSticky ? 'sticky' : 'static',
        }}
      />
      <Th
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '4px 0',
          position: isSticky ? 'sticky' : 'static',
          whiteSpace: 'nowrap',
        }}
      >
        <WrapperContent>総合受付</WrapperContent>
      </Th>
      {tenants.map((tenant) => (
        <Th
          key={tenant.generalReceptionTenantId}
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            textAlign: 'center',
            position: isSticky ? 'sticky' : 'static',
          }}
        >
          <WrapperContent> {tenant.tenantName}</WrapperContent>
        </Th>
      ))}
      <Th
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          textAlign: 'center',
          position: isSticky ? 'sticky' : 'static',
          whiteSpace: 'nowrap',
        }}
      >
        <WrapperContent>退場</WrapperContent>
      </Th>
      {operationType === 'decide' && (
        <Th
          style={{
            borderRadius: '0 12px 0 0',
            position: isSticky ? 'sticky' : 'static',
          }}
        />
      )}
    </tr>
  </thead>
)

const Checkbox = ({ index, disabled, onClick }) => (
  <button
    onClick={disabled ? null : onClick}
    style={{
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      cursor: disabled ? 'unset' : 'pointer',
      opacity: disabled ? 0.5 : 1,
    }}
  >
    {index > 0 ? (
      <CircleNumber num={index} type="blue-fill" />
    ) : (
      <CircleNumber type="blue-outline" style={{ border: '2px solid #247CDB' }} />
    )}
  </button>
)

export const CustomButton = styled(Button).attrs(() => ({
  style: {
    width: 70,
    height: 29,
    fontSize: 9,
    padding: '5px 9px',
    borderRadius: '4px',
  },
}))``

const WrapperButton = styled.div`
  height: 29px;
  margin-bottom: 8px;
`
