import React, { memo } from 'react'
import Button from 'components/Button'

import * as S from '../style'

type Props = {
  title: string
  buttonLabel: string
  buttonIsDisabled: boolean
  onClick: () => void
}

const CardSection: React.FC<Props> = memo(
  ({ title, buttonIsDisabled, buttonLabel, onClick, children }) => {
    return (
      <S.CardInfo>
        <div className="card__header">
          <h3 className="text-ellipsis" style={{ maxWidth: '480px' }}>
            {title}
          </h3>
          <Button
            size="sm"
            color="blue"
            width="116px"
            onClick={onClick}
            disabled={buttonIsDisabled}
          >
            {buttonLabel}
          </Button>
        </div>
        <div className="card__body">{children}</div>
      </S.CardInfo>
    )
  }
)

export default CardSection
