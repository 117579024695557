import axios from 'axios'
import get from 'lodash/get'
import Qs from 'qs'
import loginStorage from 'utils/loginStorage'
import { DEFAULT_CONFIG, statusCode } from './constants'
import { store } from 'reduxStoreHelper'
import { openModalMaintain } from 'containers/App/reducers'

export const getAuthHeader = () => {
  const sessionData = loginStorage.getPhoneBookingAuthData()
  return {
    uid: get(sessionData, 'uid', ''),
    'access-token': get(sessionData, 'accessToken', ''),
  }
}

export default class RestPhoneBookingClient {
  constructor(config = {}) {
    this.config = Object.assign({}, DEFAULT_CONFIG, config)
  }

  get(url, params, config = {}) {
    return this.executeRequest(url, {
      method: 'get',
      ...config,
      params,
      paramsSerializer: (preParams) => Qs.stringify(preParams, { arrayFormat: 'brackets' }),
    })
  }

  patch({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'PATCH' })
  }

  put({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'PUT' })
  }

  post({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'POST' })
  }

  delete({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'DELETE' })
  }

  executeRequest(url, config) {
    const finalConfig = Object.assign({}, this.config, { url, ...config })
    return axios
      .request(finalConfig)
      .then((res) => {
        const accessToken = get(res, 'headers.access-token', '')
        const uid = get(res, 'headers.uid', '')
        if (accessToken && uid) {
          loginStorage.setPhoneBookingAuthData({ accessToken, uid })
        }
        return Promise.resolve(res.data)
      })
      .catch((errorResponse) => {
        const status = get(errorResponse, 'response.status')
        const error = get(errorResponse, 'response.data')
        if (status === statusCode.SERVER_MAINTAIN_ERROR) {
          store.dispatch(openModalMaintain())
          return null
        }
        if (status === statusCode.UNAUTHORIZED) {
          loginStorage.clearPhoneBookingAuth()
          window.location.reload()
          return null
        }
        return Promise.reject({ ...error, status })
      })
  }
}
