import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { ROLES } from 'constants/misc'
import { context } from './constants'

const appPrivateDomainSelectors = (state) => state.get(context)

export const appPrivateLoadingSelectors = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('loading'))

export const flashMessageSelectors = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('flashMessage').toJS())

export const isLoadMoreSelectors = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('isLoadMore'))

export const isUpdatedBookingSelectors = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('isUpdatedBooking'))

export const isRenderCSVSelectors = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('renderCSV'))

export const userRoleSelectors = () =>
  createSelector(appPrivateDomainSelectors, (state) => {
    const userRoles = state.get('userRoles').toJS()
    if (userRoles.admin) return ROLES.ADMIN
    const userTenant = _get(userRoles, 'user_tenant.data.attributes', {})
    if (!isEmpty(userTenant)) {
      const lists = Object.keys(ROLES)
      for (let i = 0; i < lists.length; i += 1) {
        if (userTenant[ROLES[lists[i]]]) {
          return ROLES[lists[i]]
        }
      }
      return ''
    }
    return ''
  })

export const isUserWarehouseManager = () =>
  createSelector(userRoleSelectors(), (role) => role === ROLES.ADMIN)

export const policyDataSelector = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('policyData').toJS())

export const userRoleForBerth = () =>
  createSelector(appPrivateDomainSelectors, (state) => state.get('userRoles')?.toJS())
