import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import { fetchAnything, openError, startLoading, stopLoading } from 'containers/App/reducers'
import { trimData } from 'utils/commonFunctions'

import Header from '../components/Header'
import { postCreateBooking, putUpdateBooking, patchUpdateBookingStatus } from '../api'
import PrevStartButton from '../components/PrevStartButton'
import { clearAll } from '../reducers'
import DriverConfirmations from '../components/DriverConfirmations'
import { buildBooking } from '../services'
import PageNumber from '../components/PageNumber'
import { STATUS_CHECKIN_TIME_NOT_FOUND, STATUS_CHECKIN_TIME_BOOKING_NOT_FOUND } from '../constants'
import AlertModal from 'components/AlertModal'
class EntryConfirmationPage extends Component {
  state = {
    confirmedIds: [],
    isOpenModalSubmitError: false,
    messageSubmitError: '',
  }

  componentDidMount() {
    const { match, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
    }
  }

  onChangeCheck = (e, confirmation) => {
    const { confirmedIds } = this.state
    const { checked } = e.target
    const newConfirmedIds = checked
      ? confirmedIds.concat(confirmation.id)
      : confirmedIds.filter((confirmedId) => confirmedId !== confirmation.id)
    this.setState({ confirmedIds: newConfirmedIds })
  }

  onClickConfirm = async () => {
    const { history, match, booking, driver, currentTenant } = this.props
    const { confirmedIds } = this.state
    const {
      params: { warehouseId, tenantId },
    } = match
    let methodNotification

    const data = buildBooking(
      { ...booking, confirmations: confirmedIds },
      warehouseId,
      tenantId,
      driver.driverPhone
    )

    if (data.repeatId) {
      data.applyType = 2
    }

    this.props.startLoading()

    try {
      const trimmedData = trimData(data)

      if (data.bookingNo) {
        const resp = await putUpdateBooking(trimmedData)
        const result = await patchUpdateBookingStatus({
          ...trimmedData,
          bookingId: resp?.data?.booking_id,
        })
        methodNotification = {
          lineFlag: result?.line_flag,
          portalFlag: result?.portal_flag,
          isCheckedPhone: driver?.phoneChecked,
        }
      } else {
        const resp = await postCreateBooking(trimmedData)
        methodNotification = {
          lineFlag: resp?.data?.line_flag,
          portalFlag: resp?.data?.portal_flag,
          isCheckedPhone: driver?.phoneChecked,
        }
      }
    } catch (error) {
      const { errors } = error?.data || {}
      const checkinTimeNotFound = errors?.find(
        (error) => error?.code === STATUS_CHECKIN_TIME_NOT_FOUND
      )
      const checkinTimeBookingNotFound = errors?.find(
        (error) => error?.code === STATUS_CHECKIN_TIME_BOOKING_NOT_FOUND
      )
      if (checkinTimeNotFound) {
        this.setState({ isOpenModalSubmitError: true })
        this.setState({ messageSubmitError: checkinTimeNotFound?.message })
        return
      }
      if (checkinTimeBookingNotFound) {
        this.setState({ isOpenModalSubmitError: true })
        this.setState({ messageSubmitError: checkinTimeBookingNotFound?.message })
        return
      }
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    this.props.clearAll()

    history.push({
      pathname: `/receipt-booking/entry-finish/${warehouseId}/${tenantId}`,
      state: {
        canUnmannedCheckin: currentTenant?.canUnmannedCheckin,
        methodNotification,
      },
    })
  }

  onClickCheck = (confirmation) => {
    if (confirmation?.checkFlag) {
      const { confirmedIds } = this.state
      const isUnCheck = confirmedIds?.includes(confirmation?.id)
      const newConfirmedIds = isUnCheck
        ? confirmedIds?.filter((confirmedId) => confirmedId !== confirmation?.id)
        : confirmedIds?.concat(confirmation?.id)
      this.setState({ confirmedIds: newConfirmedIds })
    }
  }

  handleClickRedirectCheckinPage = () => {
    const { history, match } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
    this.setState({ isOpenModalSubmitError: false })
  }

  render() {
    const { match, driverConfirmations, driver, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { confirmedIds, isOpenModalSubmitError, messageSubmitError } = this.state
    const requiredConfirmations = driverConfirmations.filter(
      (confirmation) => confirmation.checkFlag
    )
    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18, minWidth: 1024 }}>
        <Header driver={driver} />
        <div style={{ margin: 32 }}>
          <div
            className="bg-white mb-3 p-3"
            style={{ borderRadius: 12, maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}
          >
            <DriverConfirmations
              isCheckinPage
              confirmations={driverConfirmations}
              confirmedIds={confirmedIds}
              onChangeCheck={this.onChangeCheck}
              onClickCheck={this.onClickCheck}
            />
          </div>

          <div className="d-flex justify-content-between">
            <div style={{ width: '33%' }}>
              <PrevStartButton to={`/receipt-booking/${warehouseId}/${tenantId}`} />
            </div>
            <div className="text-center" style={{ width: '34%' }}>
              <PageNumber
                page="entry-confirmation"
                existsDriverConfirmations={Boolean(driverConfirmations.length)}
                isFreePlan={!driver.tenant.canBerthBook}
              />
            </div>
            <div className="text-right" style={{ width: '33%' }}>
              <Button
                outline
                color="primary"
                className="rounded-pill bg-white px-4 mr-3 text-primary"
                onClick={() =>
                  history.push(`/receipt-booking/entry-load/${warehouseId}/${tenantId}`)
                }
              >
                もどる
              </Button>
              <Button
                color="primary"
                className="rounded-pill px-4"
                onClick={this.onClickConfirm}
                disabled={confirmedIds.length !== requiredConfirmations.length}
              >
                確認して受付完了
              </Button>
            </div>
          </div>
        </div>

        <AlertModal
          isOpen={isOpenModalSubmitError}
          title={messageSubmitError}
          textOk="OK"
          handleConfirm={this.handleClickRedirectCheckinPage}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driverConfirmations: reception.driverConfirmations,
    booking: reception.booking,
    driver: reception.driver,
    currentTenant: reception?.driver?.tenant,
    returnEntryPage: reception.returnEntryPage,
  }
}

const mapDispatchToProps = {
  fetchAnything,
  openError,
  startLoading,
  stopLoading,
  clearAll,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryConfirmationPage)
