import React, { Fragment, useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { camelizeKeys, camelize, decamelize } from 'humps'

import { getAdditionalColumnName } from '../utils'
import { NUMBER_OF_ADDITIONAL_COLUMNS, IMPORT_EXPORT_COLUMN_NAME } from '../constants'
import { checkIsAdditionalColumn } from '../constants'
import { filteredNumber } from 'utils/helper'
import * as S from '../style'

const BaggageLabels = Object.freeze({
  import: '荷降ろし',
  export: '荷積み',
})

const PACKAGE_TYPE_KEY = 'PackageType'

const getNormalKey = (type) => [
  `${type}GoodsOwner`,
  `${type}GoodsName`,
  `${type}${PACKAGE_TYPE_KEY}`,
  `${type}GoodsAmount`,
  `${type}MainGoods`,
  `${type}ContactNotes`,
]

const getOtherNormalKey = (type) => [`${type}DeliverySlipNumber`]

const getKeyImportAdditionalColumn = (type) => {
  const arrKey = Array.from(new Array(NUMBER_OF_ADDITIONAL_COLUMNS).keys())
  return arrKey.map((key) => `${type}AdditionalColumn${key + 1}`)
}

const getNormalAndAdditionalColumnKey = (type) => {
  return [...getNormalKey(type), ...getKeyImportAdditionalColumn(type)]
}

const getFieldsAndOrder = (keys = [], datas = [], keyGet = 'columnName', flag = 'displayFlag') => {
  const dataFormat = datas.map((item) => {
    return { ...item, [keyGet]: camelize(item[keyGet]) }
  })

  const arrs = dataFormat.filter((item) => {
    return keys.includes(item[keyGet]) && item[flag]
  })

  const results = []
  keys.forEach((keyItem) => {
    if (
      _.find(arrs, {
        [keyGet]: keyItem,
      })
    ) {
      results.push(
        _.find(arrs, {
          [keyGet]: keyItem,
        })
      )
    }
  })

  return results
}

export default ({ booking, packageTypeMap, style, bookingMaster }) => {
  const importParameters = camelizeKeys(_.get(bookingMaster, 'tenant_parameters.import', []))
  const importNormalAndAdditional =
    getFieldsAndOrder(
      getNormalAndAdditionalColumnKey(IMPORT_EXPORT_COLUMN_NAME.import),
      importParameters
    ) || []
  const importOtherNormal =
    getFieldsAndOrder(getOtherNormalKey(IMPORT_EXPORT_COLUMN_NAME.import), importParameters) || []

  const exportParameters = camelizeKeys(_.get(bookingMaster, 'tenant_parameters.export', []))
  const exportNormalAndAdditional =
    getFieldsAndOrder(
      getNormalAndAdditionalColumnKey(IMPORT_EXPORT_COLUMN_NAME.export),
      exportParameters
    ) || []
  const exportOtherNormal =
    getFieldsAndOrder(getOtherNormalKey(IMPORT_EXPORT_COLUMN_NAME.export), exportParameters) || []

  return (
    <div style={{ display: 'flex', ...style }}>
      {/* 荷積み */}
      <S.GeneralBookingDetailWrapper>
        <S.Wrapper className="py-3 ph30">
          <Baggage
            title={BaggageLabels.import}
            baggage={booking}
            packageTypeMap={packageTypeMap}
            normalAndAdditionalInfo={importNormalAndAdditional}
            otherNormalInfo={importOtherNormal}
          />
        </S.Wrapper>
      </S.GeneralBookingDetailWrapper>

      {/* 荷降ろし */}
      <S.GeneralBookingDetailWrapper>
        <S.Wrapper className="py-3 ph30">
          <Baggage
            title={BaggageLabels.export}
            baggage={booking}
            packageTypeMap={packageTypeMap}
            normalAndAdditionalInfo={exportNormalAndAdditional}
            otherNormalInfo={exportOtherNormal}
          />
        </S.Wrapper>
      </S.GeneralBookingDetailWrapper>
    </div>
  )
}

const RowInfo = ({
  displayNameOfItemLeft,
  columnNameOfItemLeft,
  displayNameOfItemRight,
  columnNameOfItemRight,
  hasItemRight = false,
  index,
  elRefsRight,
  elRefsLeft,
}) => {
  return (
    <tr>
      <td>
        <S.ItemImportExportContent>
          <div
            className="display-name left"
            dangerouslySetInnerHTML={{
              __html: displayNameOfItemLeft,
            }}
            ref={(el) => Array.isArray(elRefsLeft.current) && (elRefsLeft.current[index] = el)}
          />
          <span className="colon-character">：</span>
          <div className="display-column-name">{columnNameOfItemLeft}</div>
        </S.ItemImportExportContent>
      </td>

      {hasItemRight && (
        <td>
          <S.ItemImportExportContent>
            <div
              className="display-name right"
              dangerouslySetInnerHTML={{
                __html: displayNameOfItemRight,
              }}
              ref={(el) => Array.isArray(elRefsRight.current) && (elRefsRight.current[index] = el)}
            />
            <span className="colon-character">：</span>
            <div className="display-column-name">{columnNameOfItemRight}</div>
          </S.ItemImportExportContent>
        </td>
      )}
    </tr>
  )
}

const Baggage = ({
  title = '',
  baggage = {},
  packageTypeMap,
  normalAndAdditionalInfo = [],
  otherNormalInfo = [],
}) => {
  const normalAndAdditionalInfoFormat = _.chunk(normalAndAdditionalInfo, 2)

  let elRefsLeft = useRef([])
  let elRefsRight = useRef([])
  const [maximumWidthColumn, setMaximumWidthColumn] = useState({ left: 0, right: 0 })

  useEffect(() => {
    const lengthWidthLeftColumns = elRefsLeft?.current?.map((el) => {
      return el?.clientWidth
    })
    const lengthWidthRightColumns = elRefsRight?.current?.map((el) => {
      return el?.clientWidth
    })
    setMaximumWidthColumn({
      left: Math.max(...lengthWidthLeftColumns),
      right: Math.max(...lengthWidthRightColumns),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elRefsLeft?.current, elRefsRight?.current])

  return (
    <Fragment>
      <S.FlexWrapper>
        <h2 style={{ fontSize: 15, minWidth: 80 }}>{title}</h2>
        {/* export/import_delivery_slip_number */}
        {!_.isEmpty(otherNormalInfo[0]) && (
          <div style={{ fontSize: 12, display: 'flex' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{`${otherNormalInfo[0]?.displayName}`}：</span>
            <span style={{ wordBreak: 'break-word' }}>
              {_.get(baggage, otherNormalInfo[0]?.columnName, '')}
            </span>
          </div>
        )}
      </S.FlexWrapper>
      <S.Table maximumWidthColumn={maximumWidthColumn}>
        <tbody style={{ border: 'none', wordBreak: 'break-word', verticalAlign: 'baseLine' }}>
          {normalAndAdditionalInfoFormat?.map((item, index) => {
            let { displayName: displayNameLeft = '', columnName: columnNameLeft = '' } =
              item[0] || {}
            let { displayName: displayNameRight = '', columnName: columnNameRight = '' } =
              item[1] || {}
            const hasItemRight = !_.isEmpty(item[1])

            const isAddinationColumnLeft = checkIsAdditionalColumn(decamelize(columnNameLeft))
            const isAddinationColumnRight = checkIsAdditionalColumn(decamelize(columnNameRight))

            if (isAddinationColumnLeft) {
              const numberAddinationColumnLeft = filteredNumber(columnNameLeft)
              displayNameLeft = !_.isNil(displayNameLeft)
                ? getAdditionalColumnName(displayNameLeft)
                : `${`追加項目${numberAddinationColumnLeft}`}`
              columnNameLeft = baggage[columnNameLeft]
            } else {
              columnNameLeft = columnNameLeft?.includes(PACKAGE_TYPE_KEY)
                ? packageTypeMap[_.get(baggage, columnNameLeft)]
                : baggage[columnNameLeft]
            }

            if (isAddinationColumnRight) {
              const numberAddinationColumnRight = filteredNumber(columnNameRight)
              displayNameRight = !_.isNil(displayNameRight)
                ? getAdditionalColumnName(displayNameRight)
                : `${`追加項目${numberAddinationColumnRight}`}`
              columnNameRight = baggage[columnNameRight]
            } else {
              columnNameRight = columnNameRight?.includes(PACKAGE_TYPE_KEY)
                ? packageTypeMap[_.get(baggage, columnNameRight)]
                : baggage[columnNameRight]
            }

            return (
              <RowInfo
                key={index}
                displayNameOfItemLeft={displayNameLeft}
                displayNameOfItemRight={displayNameRight}
                columnNameOfItemLeft={columnNameLeft}
                columnNameOfItemRight={columnNameRight}
                hasItemRight={hasItemRight}
                index={index}
                elRefsLeft={elRefsLeft}
                elRefsRight={elRefsRight}
              />
            )
          })}
        </tbody>
      </S.Table>
    </Fragment>
  )
}
