import { takeLatest, put, call } from 'redux-saga/effects'
import { context } from './constants'

import { startLoading, fetchFailed, fetchSuccess, fetchGeneralReceptionTenants } from './reducer'
import { getGeneralReceptions, getGeneralReceptionTenants, getUserGeneralReception } from './api'
import { FIRST_PAGE, ROLE_TYPE } from './constants'
import { RequestGeneralReceptionType } from './type'

function* fetchGeneralReceptionsWorker() {
  try {
    yield put(startLoading())
    const res = yield call(getGeneralReceptions)

    yield put(
      fetchSuccess({
        key: 'generalReceptions',
        data: res.data,
      })
    )
  } catch (error) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchGeneralReceptionTenantWorker({
  payload,
}: ReturnType<typeof fetchGeneralReceptionTenants>) {
  try {
    const { generalReceptionId, page = FIRST_PAGE, perPage } = payload

    const res = yield call(getGeneralReceptionTenants, generalReceptionId, page, perPage)

    // @ts-ignore
    const { generalReceptionTenants = [], totalPage, totalItem } = res.data || {}

    const generalReceptionTenantsFormat = generalReceptionTenants.map(
      (generalReceptionTenant: any) => ({
        id: generalReceptionTenant?.id,
        viewOrder: generalReceptionTenant?.viewOrder,
        generalTenantName: generalReceptionTenant?.generalTenantName,
        warehouseName: generalReceptionTenant?.tenant?.warehouseName,
        tenantName: generalReceptionTenant?.tenant?.name,
      })
    )
    yield put(
      fetchSuccess({
        key: 'selectedGeneralReceptionTenant',
        data: {
          generalReceptionTenants: generalReceptionTenantsFormat,
          totalPage,
          totalItem,
          page,
        },
      })
    )
  } catch (error) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchGeneralReceptionAdminWorker({
  payload,
}: {
  type: string
  payload: RequestGeneralReceptionType
}) {
  try {
    const { generalReceptionId, page, perPage } = payload
    //@ts-ignore
    const response = yield call(
      getUserGeneralReception,
      generalReceptionId,
      ROLE_TYPE.ADMIN,
      page,
      perPage
    )
    yield put(
      fetchSuccess({
        key: 'selectedGeneralReceptionAdmin',
        data: { ...response.data, page },
      })
    )
  } catch (error) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchGeneralReceptionControllerWorker({
  payload,
}: {
  type: string
  payload: RequestGeneralReceptionType
}) {
  try {
    const { generalReceptionId, page, perPage } = payload
    //@ts-ignore
    const response = yield call(
      getUserGeneralReception,
      generalReceptionId,
      ROLE_TYPE.CONTROLLER,
      page,
      perPage
    )
    yield put(
      fetchSuccess({
        key: 'selectedGeneralReceptionController',
        data: { ...response.data, page },
      })
    )
  } catch (error) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

export default function* () {
  yield takeLatest(`${context}/FETCH_GENERAL_RECEPTIONS`, fetchGeneralReceptionsWorker)
  yield takeLatest(`${context}/FETCH_GENERAL_RECEPTION_TENANTS`, fetchGeneralReceptionTenantWorker)
  yield takeLatest(`${context}/FETCH_GENERAL_RECEPTION_ADMIN`, fetchGeneralReceptionAdminWorker)
  yield takeLatest(
    `${context}/FETCH_GENERAL_RECEPTION_CONTROLLER`,
    fetchGeneralReceptionControllerWorker
  )
}
