import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { HEADER_HEIGHT } from '../constants'
import Contact from './Contact'
import ReceptionCustomSample from 'assets/pngs/reception-custom-sample.png'
import { canGeneralCustomizeSettingSelectors } from '../selectors'

const CanNotUse = ({ canGeneralCustomizeSetting }) => {
  return (
    <div
      className="p-4 text-center"
      style={{ height: window.innerHeight - HEADER_HEIGHT, overflowY: 'auto' }}
    >
      {canGeneralCustomizeSetting ? (
        <>
          <div className="mb-4 text-size24">総合メニューの受付カスタマイズをご利用ください</div>
          <div className="mx-auto mb-3" style={{ width: 750 }}>
            <div>
              現在、総合管理の受付カスタマイズをご利用中です。
              <br />
              設定画面が異なりますので、サイドメニューの総合メニューより受付カスタマイズをお選びください。
            </div>
            <Contact />
          </div>
        </>
      ) : (
        <>
          <div className="mb-4 text-size24">この区分では受付カスタマイズを使用できません</div>
          <div className="mx-auto mb-3" style={{ width: 750 }}>
            <div>
              本機能により、ドライバーの方々への受付内容を自由に設定/変更する
              <br />
              ことが可能になりますので、ご興味のある方は下記へお問い合わせください。
            </div>
            <Contact />
          </div>
        </>
      )}
      <div className="mb-3" style={{ opacity: 0.4 }}>
        <img src={ReceptionCustomSample} alt="受付カスタマイズサンプルイメージ" />
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  canGeneralCustomizeSetting: canGeneralCustomizeSettingSelectors(),
})

export default connect(mapStateToProps)(CanNotUse)
