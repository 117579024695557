import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { OptionTypeBase as OptionType } from 'react-select'

import { SelectOptionsWrapper } from 'components/SelectOptions/style'
import theme from 'vars/theme'

const StyledSelect = styled(Select)`
  .mono-select__control {
    background-color: white;
    border: 1px solid #cccccc;
  }

  .mono-select__control:hover,
  .mono-select__control:active {
    border-color: ${theme.blueDefault};
  }
  .mono-select__menu {
    background-color: white;
    color: #505155;
    .mono-select__option {
      color: #505155;
    }

    .mono-select__option--is-focused {
      background-color: ${theme.blueSoft5};
    }

    .mono-select__option--is-selected {
      color: white;
      background-color: ${theme.blueDefault};
    }
  }

  .mono-select__single-value {
    color: #505155;
  }

  .mono-select__indicator > svg {
    fill: ${theme.blueMedium5};
  }

  .mono-select__control {
    margin: 0;
  }
`

interface propsSelect {
  width?: string
  className?: string
  style?: object
  options?: OptionType[]
  placeholder?: string
  hasClass?: boolean
  name?: string
  isSearchable?: boolean
  isError?: boolean
  label?: string
  isDisabled?: boolean
  value?: OptionType
  color?: string
  defaultOption?: OptionType
  onChange: (option: OptionType) => void
}

export default (props: propsSelect) => {
  const {
    width,
    className,
    style,
    options,
    placeholder,
    hasClass,
    name,
    color,
    isSearchable,
    isError,
    isDisabled,
    value,
    onChange,
  } = props

  return (
    <div className={`flex-column align-items-start`}>
      <SelectOptionsWrapper
        width={width}
        className={className}
        style={style}
        color={color}
        isError={isError}
      >
        <StyledSelect
          className={hasClass ? 'ml-1' : ''}
          classNamePrefix="mono-select"
          clearable={false}
          name={name}
          options={options}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          menuPlacement="auto"
          noOptionsMessage={() => '選択値なし'}
        />
      </SelectOptionsWrapper>
    </div>
  )
}
