import styled from 'styled-components'
import { Button } from 'reactstrap'
import theme from 'vars/theme'

const buttonPrimary = ({ color, backgroundColor, hoverBackgroundColor, focusBorderColor }) => `
  color: ${color};
  background-color: ${backgroundColor};
  border-color: ${backgroundColor};

  &:hover {
    color: white;
    background-color: ${hoverBackgroundColor};
    border-color: ${hoverBackgroundColor};
  }

  &:focus {
    box-shadow: 0 0 0 2pt ${focusBorderColor};
  }
  &:disabled,
  &.disabled {
    color: ${theme.grayMedium1};
    border-color: ${theme.graySoft5};
    background-color: ${theme.graySoft5};
    cursor: not-allowed;
    opacity: 1;
  }
`

const buttonSecondary = ({ color, borderColor, hoverBackgroundColor, focusBorderColor }) => `
  color: ${color};
  background-color: transparent;
  border-color: ${borderColor};

  &:hover {
    color: white;
    background-color: ${hoverBackgroundColor};
    border-color: ${hoverBackgroundColor};
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 2pt ${focusBorderColor};
  }
`

const PRIMARY = {
  red: {
    color: 'white',
    backgroundColor: theme.redMedium1,
    hoverBackgroundColor: theme.redPrimary,
    focusBorderColor: theme.redMedium3,
  },
  blue: {
    color: 'white',
    backgroundColor: theme.blueMedium1,
    hoverBackgroundColor: theme.bluePrimary,
    focusBorderColor: theme.bluePrimary,
  },
  lightBlue: {
    color: theme.blueMedium1,
    backgroundColor: '#E5F2F7',
    hoverBackgroundColor: theme.blueMedium2,
    focusBorderColor: theme.blueMedium1,
  },
  lightRed: {
    backgroundColor: theme.redSoft3,
    color: theme.redMedium1,
    hoverBackgroundColor: theme.redMedium2,
  },
  white: {
    backgroundColor: theme.whitePrimary,
    color: theme.graySoft9,
    hoverBackgroundColor: theme.blueSoft8,
  },
  darkBlue: {
    backgroundColor: theme.blueSoft1,
    color: theme.blueMedium6,
    hoverBackgroundColor: theme.blueSoft8,
    hoverColor: theme.blueMedium6,
  },
  disabledButton: {
    backgroundColor: theme.graySoft5,
    borderColor: theme.graySoft5,
    color: theme.grayMedium1,
    hoverBackgroundColor: theme.graySoft5,
    hoverColor: theme.blueMedium6,
  },
}

const SECONDARY = {
  red: {
    color: theme.redMedium1,
    hoverBackgroundColor: theme.redMedium2,
    focusBorderColor: theme.redMedium1,
  },
  blue: {
    color: theme.blueMedium1,
    hoverBackgroundColor: theme.blueMedium1,
    focusBorderColor: theme.bluePrimary,
  },
  lightBlue: {
    color: theme.blueSoft1,
    hoverBackgroundColor: theme.blueSoft1,
    focusBorderColor: theme.blueSoft1,
  },
  lightRed: {
    color: theme.redSoft1,
    hoverBackgroundColor: theme.redSoft2,
    focusBorderColor: theme.redSoft1,
  },
}

const btnStyles = (context, color) => {
  switch (context) {
    case 'primary':
      return buttonPrimary(PRIMARY[color])
    case 'secondary':
      return buttonSecondary(SECONDARY[color])
    default:
      return buttonPrimary(PRIMARY.red)
  }
}

const ButtonWrapper = styled(Button)`
  text-align: center;
  border-radius: ${(props) => (props.rounded ? '999px' : '2px')};
  border: 2px solid;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};

  &:focus {
    outline: none;
  }

  &.btn-sm {
    padding: 0 0.75rem;
    font-size: 12px;
    height: 32px;
  }

  &.btn-md {
    padding: 0.375rem 1.25rem;
  }

  &.btn-lg {
    padding: 0.5rem 1.75rem;
  }
  ${({ context, color }) => btnStyles(context, color)};
`

export { ButtonWrapper }
