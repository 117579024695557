import React, { Component } from 'react'
import styled from 'styled-components'

import { Input, FormGroup, Label } from 'reactstrap'

import { ErrorText } from 'components/SingleForm/style'
import Icon from 'components/Icon'
import theme from 'vars/theme'
import arrowDown from 'assets/svgs/arrow-down.svg'

const FormWrapper = styled(({ isError, ...props }) => <FormGroup {...props} />)`
  width: ${(props) => props.width || '100%'};

  .form-control {
    font-size: 24px;
    flex: 1 0 65%;
    color: ${theme.darkBlueMedium1};
    border: 1px solid;
    border-color: ${({ isError }) => (isError ? theme.redMedium1 : theme.graySoft2)};
    height: 52px;
    font-weight: 500;

    &::-webkit-input-placeholder {
      font-size: ${(props) => props.fontSize || '14px'};
      color: ${theme.grayMedium1};
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      border-color: ${theme.bluePrimary};
      box-shadow: 0 0 0 1px ${theme.bluePrimary};
    }

    &[readonly] {
      background-color: ${theme.whitePrimary};
    }
  }

  select.form-control {
    background: url(${arrowDown}) no-repeat;
    background-position: right 7px top 14px;
    -webkit-appearance: none;

    &:disabled,
    &[readonly] {
      background: ${theme.grayPrimary};
      cursor: not-allowed;
    }
  }
`

class InputField extends Component {
  handleChange = (e) => {
    if (e.target.type === 'checkbox') {
      this.props.onChange(e.target.checked)
    } else {
      this.props.onChange(e.target.value)
    }
  }
  render() {
    const {
      className,
      id,
      disabled,
      width,
      fontSize,
      errorMessage,
      value,
      name,
      type,
      placeholder,
      label,
      labelRequired,
      children,
      maxLength,
      onMouseDown,
      readOnly,
    } = this.props

    return (
      <FormWrapper
        className={`${className}`}
        disabled={disabled}
        width={width}
        fontSize={fontSize}
        isError={!!errorMessage}
      >
        <button
          style={{ display: 'none' }}
          ref={(rf) => {
            this.autoClick = rf
          }}
        />
        {label && (
          <Label style={{ fontSize: '18px' }} className="label-flex mr-4">
            {label} {labelRequired && <span className="color-red-med1 font-weight-bold">*</span>}
          </Label>
        )}
        <div className="w-100 d-flex flex-column">
          <Input
            id={id}
            disabled={disabled}
            type={type}
            onChange={this.handleChange}
            name={name}
            value={value}
            placeholder={placeholder}
            maxLength={maxLength}
            onMouseDown={onMouseDown}
            readOnly={readOnly}
            autoComplete="off"
          >
            {children}
          </Input>
          {errorMessage && (
            <ErrorText>
              <Icon src="/assets/svgs/icon-alert.svg" alt="icon-alert" />
              {errorMessage}
            </ErrorText>
          )}
        </div>
      </FormWrapper>
    )
  }
}

InputField.defaultProps = {
  placeholder: null,
  type: 'text',
  name: '',
  className: '',
}

export default InputField
