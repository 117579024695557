import React, { Fragment } from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import ConfirmBooking from 'containers/ConfirmBooking/Loadable'
import LoginPage from 'containers/LoginPage/Loadable'
import ReceiptBooking from 'containers/ReceiptBooking/Loadable'
import PhoneBooking from 'containers/PhoneBooking/Loadable'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import AppPrivateWrapper from 'containers/Routes/AppPrivateWrapper'
import ReceptionRouting from 'containers/Reception/ReceptionRouting'
import IntegratedReceptionRouting from 'containers/IntegratedReception/IntegratedReceptionRouting'

import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import { getAuthenticationData, getUserData } from './actions'
import saga from './sagas'
import reducer, { updateWindowSize, openModal, addDebugLog } from './reducers'
import {
  appLoadingSelectors,
  userDataSelectors,
  baseSelector,
  selectNotices,
  selectDebugLogs,
  isOpenModalMaintainSelectors,
} from './selectors'
import { context, UPDATE_NOTICES } from './constants'

import LoadingIndicator from 'components/LoadingIndicator'
import Modal from 'containers/Reception/components/Modal'
import MaintainModal from 'components/MaintainModal'

import Toast from 'components/base/Toast'
import { Backdrop } from 'containers/Reception/components/utils'
import watchNotice, { NoticeStorage } from './watchNotice'
import { createInputLogger } from 'containers/App/InputLogger'
import { VERSION, VERSION_STORAGE_KEY, USE_DEV_CONSOLE, EnvironmentNames } from 'constants/App'
import DmpSender from 'utils/DmpSender'
import { reloadBrowser, buildReloadConfirmationModal, extractReloadNotice } from 'utils/ForceReload'
import { ReloadTypes } from 'constants/Notice'
import { setHistory } from 'utils/history'
import DmpActionNames from 'constants/DmpActionNames'
import { fetchAnything } from 'containers/App/reducers'
import loginStorage from 'utils/loginStorage'
import CustomMetaTag from 'components/CustomMetaTag'

const ONE_MINUTE = 1000 * 60

const IS_LOCAL = process.env.REACT_APP_ENV === EnvironmentNames.LOCAL
const LIMIT = IS_LOCAL ? 10 * 1000 : 3 * ONE_MINUTE
const INTERVAL = IS_LOCAL ? 1000 : 1000 * 10

let isFirstError = true
let noticeCount = 0

class App extends React.Component {
  logger = null

  constructor(props) {
    super(props)
    this.logger = createInputLogger(INTERVAL, LIMIT, (isForce, forceLogout) => {
      if (isForce) {
        reloadBrowser(forceLogout)
        return
      }

      this.props.openModal(buildReloadConfirmationModal(forceLogout))
    })

    if (process.env.REACT_APP_ENV !== EnvironmentNames.PRODUCTION) {
      this.logger.timerDebug = this.props.addDebugLog
    }
  }

  componentDidMount() {
    const setVariableVh = () => {
      // Calculator vh on touch devices and set variable --vh
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    setVariableVh()
    window.addEventListener('resize', _.debounce(setVariableVh, 200))

    window.onerror = this.onError
    window.addEventListener(
      'resize',
      _.debounce((e) => {
        const { pathname } = global.location
        if (!pathname.startsWith('/reception-custom') && !pathname.startsWith('/receipt-booking'))
          return
        this.props.updateWindowSize(e.target.innerHeight)
      })
    )

    const sessionData = loginStorage.getAuthData()

    if (sessionData) {
      this.props.fetchAnything({
        url: '/reception_notification',
        storePath: 'base/receptionNotificationSound',
      })
    }

    const oldVersion = localStorage.getItem(VERSION_STORAGE_KEY)
    this.props.addDebugLog(`prev version: ${oldVersion}, next version: ${VERSION}`)

    if (VERSION !== oldVersion) {
      const adminTenantId = JSON.parse(localStorage.getItem('w.h.s.sss') || '{}').tenantId
      const receptionTenantId = _.last(global.location.pathname.split('/'))
      const tenantId = Number(adminTenantId || receptionTenantId)

      DmpSender.send(DmpActionNames.PAGE_RELOAD, {
        tenantId: Number.isNaN(tenantId) ? undefined : tenantId,
        version: VERSION,
      })
      localStorage.setItem(VERSION_STORAGE_KEY, VERSION)
    }

    setHistory(this.props.history)

    if (process.env.REACT_APP_ENV === EnvironmentNames.LOCAL) return

    // お知らせを監視
    watchNotice(
      (notices, revision) => {
        this.props.updateNotices({ notices })

        const foundNotice = extractReloadNotice(notices)

        if (!foundNotice) return

        const { base } = this.props
        const { reload } = foundNotice

        switch (reload.type) {
          case ReloadTypes.FORCE: {
            this.props.addDebugLog(`start force reload ${JSON.stringify(reload)}`)
            this.logger.startLog(true, reload.forceLogout)
            break
          }
          case ReloadTypes.OPTION: {
            this.props.addDebugLog(`start option reload ${JSON.stringify(reload)}`)
            if (!base.modalOptions.isOpen) {
              this.props.openModal(buildReloadConfirmationModal(reload.forceLogout))
            }
            break
          }
          default:
            console.assert(false)
        }

        if (revision) this.props.addDebugLog(`notice revision: ${revision}`)
      },
      () => noticeCount
    )

    this.props.addDebugLog(`notice revision: ${NoticeStorage.getRevision()}`)
  }

  onError = (message, source, lineno, colno, error) => {
    const errorMessage = [
      `Message: ${message}`,
      `Source: ${source}`,
      `Line: ${lineno}`,
      `Column: ${colno}`,
    ].join('\n')

    // 連続して alert が表示されるのを防ぐ
    if (!isFirstError) {
      return
    }

    isFirstError = false

    if (process.env.REACT_APP_ENV === EnvironmentNames.PRODUCTION) {
      DmpSender.send(DmpActionNames.BERTH_CLIENT_ERROR, { message, source, lineno, colno })
    } else {
      console.error(error)
    }

    alert(errorMessage)
  }

  render() {
    const { base, closeModal, closeToast, notices, debugLogs, isOpenModalMaintain } = this.props
    const { loading, modalOptions, toastOptions } = base

    noticeCount = notices.length

    if (isOpenModalMaintain) {
      return <MaintainModal isOpen />
    }

    return (
      <div>
        <CustomMetaTag />
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/c" component={ConfirmBooking} />
          <Route path="/general-reception" component={ReceiptBooking} />
          <Route path="/receipt-booking/exit" component={ReceiptBooking} />
          <Route path="/general-reception-desk/exit" component={ReceiptBooking} />
          <Route path="/receipt-booking" component={ReceptionRouting} />
          <Route path="/general-reception-desk" component={IntegratedReceptionRouting} />
          <Route path="/sp" component={PhoneBooking} />
          <Route path="/mtenants/:mid" component={ReceptionRouting} />
          <Route path="/" render={(props) => <AppPrivateWrapper {...this.props} {...props} />} />
          <Route component={NotFoundPage} />
        </Switch>

        {/* Loader */}
        {loading && (
          <Fragment>
            <Backdrop style={{ zIndex: 101 }} />
            <LoadingIndicator />
          </Fragment>
        )}

        {/* Modal */}
        <Modal
          isOpen={modalOptions.isOpen}
          content={modalOptions.content}
          buttons={modalOptions.buttons}
          closeModal={closeModal}
        />

        {/* Toast */}
        {toastOptions.isOpen && <Toast content={toastOptions.content} closeToast={closeToast} />}

        {/* Dev Console */}
        {USE_DEV_CONSOLE && (
          <div
            className="bg-gray m-2"
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: 600,
              height: 300,
              opacity: 0.8,
              overflowY: 'scroll',
            }}
          >
            {debugLogs.map((log, index) => (
              <div key={index}>{log}</div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userData: userDataSelectors(),
  loading: appLoadingSelectors(),
  base: baseSelector(),
  notices: selectNotices(),
  debugLogs: selectDebugLogs(),
  isOpenModalMaintain: isOpenModalMaintainSelectors(),
})

const matchDispatchToProps = (dispatch) => ({
  getAuthenticationData: (data) => dispatch(getAuthenticationData(data)),
  getUserData: () => dispatch(getUserData()),
  closeModal: () => dispatch({ type: 'CLOSE_MODAL' }),
  closeToast: () => dispatch({ type: 'CLOSE_TOAST' }),
  updateWindowSize: (height) => dispatch(updateWindowSize(height)),
  updateNotices: (payload) => {
    dispatch({ type: UPDATE_NOTICES, payload })
  },
  openModal: (data) => dispatch(openModal(data)),
  addDebugLog: (data) => dispatch(addDebugLog(data)),
  fetchAnything: (data) => dispatch(fetchAnything(data)),
})

const withReducer = injectReducer({ key: context, reducer })
const withSaga = injectSaga({ key: context, saga })
const withConnect = connect(mapStateToProps, matchDispatchToProps)

export default compose(withRouter, withReducer, withSaga, withConnect)(App)
