import React, { Component } from 'react'
import { camelizeKeys } from 'humps'

import TermOfUseHeader from 'components/TermOfUseHeader'
import CheckboxForm from 'components/CheckboxForm'
import Button from 'components/Button'
import theme from 'vars/theme'
import { TERM_OF_USE_1, TERM_OF_USE_2 } from 'constants/misc'
import {
  ModalWrapper,
  CustomModalFooter,
  ConfirmTitle,
} from './style'

class PolicyModal extends Component {
  state = {
    isConfirmed: false,
    disabledSubmit: false,
  }

  static getDerivedStateFromProps(props) {
    // reset state if close modal
    if (!props.isOpen) {
      return {
        isConfirmed: false,
        disabledSubmit: false,
      }
    }
    return null
  }

  handleConfirm = () => {
    const { isConfirmed } = this.state
    this.setState({
      isConfirmed: !isConfirmed,
    })
  }

  handleAccept = () => {
    this.setState({
      disabledSubmit: true,
    })
    const { policyData, onAccept } = this.props
    onAccept(policyData.id)
  }

  render() {
    const { isConfirmed, disabledSubmit } = this.state
    const { onCancel, policyData, isOpen } = this.props
    const policy = camelizeKeys(policyData)
    return (
      <ModalWrapper modalClassName="term-of-use_modal" isOpen={isOpen}>
        <div>
          <ConfirmTitle>利用規約</ConfirmTitle>
          <TermOfUseHeader />
          <div className="d-flex">
            <div className="d-flex termOfUseWrapper">
              <iframe title="monoful利用規約" src={TERM_OF_USE_1} />
            </div>
            <div className="d-flex termOfUseWrapper">
              <iframe title="トラック簿利用規約" src={policy.policyUrl || TERM_OF_USE_2} />
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <CheckboxForm
              label="上記内容に同意する"
              name="confirm_dialog"
              id="confirm_dialog"
              isCheckType
              value={isConfirmed}
              onChange={this.handleConfirm}
            />
          </div>
        </div>
        <CustomModalFooter>
            <Button
              width="100px"
              height="36px"
              bgcolor={theme.whitePrimary}
              style={{
                borderRadius: '99px',
                color: theme.blueMedium5,
                backgroundColor: theme.whitePrimary,
                border: 'none',
                visibility: onCancel ? 'visible' : 'hidden',
              }}
            onClick={onCancel}
            >
              とじる
            </Button>
          <Button
            disabled={!isConfirmed || disabledSubmit}
            width="160px"
            height="36px"
            onClick={this.handleAccept}
            color="blue"
            style={{
              borderRadius: '99px',
            }}
          >
            同意して次へ
          </Button>
        </CustomModalFooter>
      </ModalWrapper>
    )
  }
}

PolicyModal.defaultProps = {
  isOpen: false,
  policyData: {},
  onAccept: () => {},
  onCancel: undefined,
}

export default PolicyModal
