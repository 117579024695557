import React from 'react'
import PropTypes from 'prop-types'
import hoistNonReactStatics from 'hoist-non-react-statics'

import getInjectors from './sagaInjectors'

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 *
 * cancelled with `task.cancel()` on component un-mount for improved performance. Another two options:
 *   - constants.DAEMON—starts the saga on component mount and never cancels it or starts again,
 *   - constants.ONCE_TILL_UNMOUNT—behaves like 'RESTART_ON_REMOUNT' but never runs it again.
 *
 */
export default ({ key, saga, mode }) => (WrappedComponent) => {
  class InjectSaga extends React.Component {
    static WrappedComponent = WrappedComponent
    static contextTypes = {
      store: PropTypes.object.isRequired,
    }
    static displayName = `withSaga(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`

    constructor(props, context) {
      super(props, context)

      this.injectors = getInjectors(context.store)

      this.injectors.injectSaga(key, { saga, mode }, this.props)
    }

    componentWillUnmount() {
      const { ejectSaga } = this.injectors

      ejectSaga(key)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistNonReactStatics(InjectSaga, WrappedComponent)
}
