import RestClient, { getAuthHeader } from './RestClient'

export default class UsersServices {
  constructor(restService = new RestClient()) {
    this.restService = restService
  }

  getUserData = () =>
    this.restService.get(
      'user_info',
      {},
      {
        headers: getAuthHeader(),
      }
    )

  getUserRoles = ({ warehouse_id, tenant_id }) =>
    this.restService.get(
      'warehouse/role_user_for_berth',
      {
        warehouse_id,
        tenant_id,
      },
      {
        headers: getAuthHeader(),
      }
    )

  checkUserPolicy = () =>
    this.restService.get(
      'policies/check-user',
      {},
      {
        headers: getAuthHeader(),
      }
    )

  updateUserPolicy = (id) =>
    this.restService.put({
      url: `user/policy/${id}`,
      config: {
        headers: getAuthHeader(),
      },
    })

  checkExistsReceptions = () =>
    this.restService.get(
      'user-general-reception/check-exists-reception',
      {},
      {
        headers: getAuthHeader(),
      }
    )

  checkUserBookingPermission = () =>
    this.restService.get(
      '/user-booking-permission',
      {},
      {
        headers: getAuthHeader(),
      }
    )
}
