import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import styled, { css } from 'styled-components'
import theme from 'vars/theme'

const ModalPopupWrapper = styled(({ centeredHeader, isFullWidth, ...props }) => (
  <Modal {...props} />
))`
  margin: 28vh auto;

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      min-width: 615px;

      @media screen and (min-width: 576px) {
        width: fit-content;
        max-width: calc(100% - 40px) !important;
      }

      @media screen and (max-width: 700px) {
        min-width: 0px;
      }
    `}

  @media screen and (max-height: 480px) {
    margin: 14vh auto;
  }

  .modal-content {
    border: 0;
  }

  .modal-header {
    font-size: 20px;
    line-height: 30px;
    padding: 20px 24px;
    border-bottom: 1px solid ${theme.darkBlueSoft1};
    ${({ centeredHeader }) => centeredHeader && `justify-content: center`}
  }

  .modal-body {
    padding: 20px 24px;
    color: ${theme.darkBlueMedium2};

    & > div {
      justify-content: flex-start !important;
      max-width: calc(100% - 40px);
      overflow-x: auto;
    }
  }

  .modal-footer {
    padding: 24px;
    border-top: 1px solid ${theme.darkBlueSoft1};

    & > div {
      margin: 0;
    }
  }
`

class ModalPopup extends React.Component {
  render() {
    const {
      onToggle,
      isOpen,
      title,
      content,
      centeredHeader,
      children,
      isFullWidth,
      ...restProps
    } = this.props

    return (
      <ModalPopupWrapper
        {...restProps}
        isOpen={isOpen}
        toggle={onToggle}
        centeredHeader={centeredHeader}
        isFullWidth={isFullWidth}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter>{children}</ModalFooter>
      </ModalPopupWrapper>
    )
  }
}

export default ModalPopup
