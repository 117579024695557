/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'

import Pagination from 'containers/HomePage/components/Pagination'
import Button from 'components/Button'
import { TableWrapper, StyledTable, PaginationWrapper, Td } from './style'

export class CustomTable extends React.PureComponent {
  handleButtonOnClick = (item) => {
    const { handleDeleteManager, handleRowButton } = this.props
    if (item.is_manager) {
      handleDeleteManager(item.id)
    } else {
      handleRowButton(item.id)
    }
  }

  renderData = (data) => {
    const {
      buttonColor,
      buttonSecondaryColor,
      buttonLabel,
      buttonSecondaryLabel,
      buttonDisabled,
      hasButton,
      handlePasswordChange,
      handleTimeWidthChange,
    } = this.props

    return (
      <tbody>
        {data.map((item) => {
          const { ButtonCustom } = item
          return (
            <tr key={item.id}>
              {this.props.columns.map((column) => {
                const label = column.key
                const isDelegationGroupName = column.isDelegationGroupName
                return (
                  <Td
                    title={!!column?.hasTruncate ? item[label] : ''}
                    key={label}
                    className={`${label} ${item.active && label === 'status' ? 'active' : ''} ${
                      isDelegationGroupName ? 'custom-delegation-name' : ''
                    }`}
                    isHighlight={column.isHightLightItem}
                    hasTruncate={!!column?.hasTruncate}
                  >
                    {(() => {
                      switch (label) {
                        case 'password':
                          return (
                            <button onClick={() => handlePasswordChange(item.id)}>
                              {item[label]}
                            </button>
                          )
                        case 'time':
                          return (
                            <button onClick={() => handleTimeWidthChange(item.id, item.time)}>
                              {item[label]}
                            </button>
                          )
                        default:
                          return item[label]
                      }
                    })()}
                  </Td>
                )
              })}
              {hasButton ? (
                <Td className="flex-end">
                  {ButtonCustom ? (
                    <ButtonCustom />
                  ) : (
                    <Button
                      size="sm"
                      width="88px"
                      color={item.is_manager === true ? buttonSecondaryColor : buttonColor}
                      onClick={() => this.handleButtonOnClick(item)}
                      disabled={buttonDisabled}
                    >
                      {item.is_manager === true ? buttonSecondaryLabel : buttonLabel}
                    </Button>
                  )}
                </Td>
              ) : (
                !this.props.hasFullWidthColumns && <Td className="flex-end"></Td>
              )}
            </tr>
          )
        })}
      </tbody>
    )
  }

  renderHeader = (data) => (
    <thead>
      <tr>
        {data.map((item, index) => (
          <th key={index}>{item.title}</th>
        ))}
        {!this.props.hasFullWidthColumns && <th></th>}
      </tr>
    </thead>
  )

  renderPagination = () => {
    const { meta, onNavigateRequest } = this.props

    return (
      <PaginationWrapper>
        <Pagination meta={meta} onNavigateRequest={onNavigateRequest} />
      </PaginationWrapper>
    )
  }

  render() {
    const { data, columns, withPagination, tableHeight, className, emptyMessage } = this.props

    return (
      <React.Fragment>
        {!data.length ? (
          <div className="flex-center w-100 h-100 p-4 color-gray-med1">
            {emptyMessage || 'データなし!'}
          </div>
        ) : (
          <div className="w-100">
            <TableWrapper className={`custom-scrollbar ${className}`} tableHeight={tableHeight}>
              <StyledTable hover>
                {this.renderHeader(columns)}
                {this.renderData(data)}
              </StyledTable>
            </TableWrapper>
            {withPagination && this.renderPagination(data)}
          </div>
        )}
      </React.Fragment>
    )
  }
}

CustomTable.defaultProps = {
  data: [],
  columns: [],
  withPagination: true,
  buttonLabel: '編集',
  buttonSecondaryLabel: '削除',
  buttonColor: 'lightBlue',
  buttonSecondaryColor: 'lightBlue',
  handleDeleteManager: () => null,
  hasButton: true,
}

export default CustomTable
