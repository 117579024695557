import React, { memo, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import LoadingIndicator from 'components/LoadingIndicator'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { reducer, resetAllData } from './reducer'
import saga from './saga'
import { context, INTEGRATED_RECEPTION_URL_BASE } from './constants'
import {
  loadingSelectors,
  bookingSelectors,
  isFromBookingNoSelectors,
  generalReceptionDetailSelectors,
} from './selectors'
import * as S from './style'
import PrivateRoute from './components/PrivateRoute'
import EntryTelNumberPage from './pages/EntryTelNumberPage'
import LoginPage from './pages/LoginPage'
import EntryPassCodePage from './pages/EntryPassCodePage'
import EntryPage from './pages/EntryPage'
import EntryBookingsPage from './pages/EntryBookingsPage'
import EntryLoadPage from './pages/EntryLoadPage'
import EntryFinishPage from './pages/EntryFinishPage'
import EntryBookingNumberPage from './pages/EntryBookingNumberPage'
import EntryDriverInfoPage from './pages/EntryDriverInfoPage'
import EntryConfirmationPage from './pages/EntryConfirmationPage'
import TermsOfServicePage from './pages/TermsOfServicePage'
import { GeneralBookingType } from './type'
import { GeneralReceptionType } from 'containers/IntegratedSetting/type'

type Props = {
  loading: boolean
  isFromBookingNo: boolean
  booking: GeneralBookingType
  generalReception: GeneralReceptionType
  resetAllData: () => void
}

const IntegratedReceptionRouting: React.FC<Props> = ({
  loading,
  isFromBookingNo,
  booking,
  generalReception,
  resetAllData,
}) => {
  const canGeneralBooking = generalReception?.features?.canGeneralBooking

  return (
    <>
      {loading && (
        <S.WrapperLoading>
          <LoadingIndicator isRelative />
        </S.WrapperLoading>
      )}
      <Switch>
        <Route
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/login/:generalReceptionNumber`}
          component={LoginPage}
        />
        <PrivateRoute
          exact
          isEntryPage
          path={`${INTEGRATED_RECEPTION_URL_BASE}/:generalReceptionNumber`}
        >
          <EntryPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-tel-number/:generalReceptionNumber`}
        >
          <EntryTelNumberPage />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-passcode/:generalReceptionNumber`}
        >
          <EntryPassCodePage />
        </PrivateRoute>

        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/terms-of-service/:generalReceptionNumber`}
        >
          <TermsOfServicePage canGeneralBooking={canGeneralBooking} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-bookings/:generalReceptionNumber`}
        >
          <EntryBookingsPage canGeneralBooking={canGeneralBooking} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-booking-number/:generalReceptionNumber`}
        >
          <EntryBookingNumberPage />
        </PrivateRoute>
        {/* @ts-ignore */}
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/:generalReceptionNumber`}
        >
          {/* @ts-ignore */}
          <EntryDriverInfoPage canGeneralBooking={canGeneralBooking} />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-load/:generalReceptionNumber`}
        >
          <EntryLoadPage
            booking={booking}
            isFromBookingNo={isFromBookingNo}
            canGeneralBooking={canGeneralBooking}
          />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-finish/:generalReceptionNumber`}
        >
          <EntryFinishPage />
        </PrivateRoute>
        <PrivateRoute
          exact
          path={`${INTEGRATED_RECEPTION_URL_BASE}/entry-confirmation/:generalReceptionNumber`}
        >
          <EntryConfirmationPage
            canGeneralBooking={canGeneralBooking}
            isFromBookingNo={isFromBookingNo}
          />
        </PrivateRoute>
      </Switch>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  loading: loadingSelectors(),
  booking: bookingSelectors(),
  isFromBookingNo: isFromBookingNoSelectors(),
  generalReception: generalReceptionDetailSelectors(),
})

const mapDispatchToProps = {
  resetAllData,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: context, reducer })
const withSaga = injectSaga({ key: context, saga, mode: null })

export default compose(memo, withReducer, withSaga, withConnect)(IntegratedReceptionRouting)
