import freezeR from 'utils/freezeR'

export const context = 'LIST_CARD'

export const NON_VALUE_TEXT = '未定'
export const DAY_FORMAT = 'YYYYMMDD'
export const STANDARD_TIME_FORMAT = 'HH:mm:ss'
export const STANDARD_DAY_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const TIME_FORMAT = 'MM/DD hh:mm:ss'

export const optionTotalItem = freezeR([
  { label: '10件', value: 10 },
  { label: '30件', value: 30 },
  { label: '50件', value: 50 },
])

export const typeRow = {
  BASE: 'base',
  IMPORT: 'import',
  ONLY_CHECK_BOX: 'onlyCheckbox',
}

export const ORDER = {
  DEFAULT: 'none',
  ASC: 'asc',
  DESC: 'desc',
} as const

export const emptyModalOptions = freezeR({
  isOpen: false,
  content: undefined,
})

export const FIRST_PAGE = 0
export const CURRENT_PRE_PAGE = 10
