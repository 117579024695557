import React from 'react'
import styled from 'styled-components'
import { Modal } from 'reactstrap'
import Button from 'components/Button'

export const Wrapper = styled(Modal)`
  width: fit-content;

  .modal-content {
    margin-top: 200px;
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    p {
      line-height: 1.2;
      font-weight: bold;
      font-size: 18px;
    }

    img {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .confirm--title {
      font-size: 18px;
      text-align: center;
    }

    label {
      cursor: pointer;
    }

    .button-group {
      button {
        margin-top: 20px;
        min-width: 120px;
      }
    }
  }
`
type Props = {
  isOpen: boolean
  handleConfirm: () => void
  handleCancel: () => void
}

const ConfirmModal: React.FC<Props> = ({ isOpen, handleConfirm, handleCancel }) => (
  <Wrapper isOpen={isOpen}>
    <div>
      <p>本当によろしいでしょうか？</p>
    </div>
    <div className="d-flex justify-content-between button-group">
      <Button className="mr-4" color="red" onClick={handleCancel}>
        キャンセル
      </Button>
      <Button color="blue" onClick={handleConfirm}>
        OK
      </Button>
    </div>
  </Wrapper>
)

export default ConfirmModal
