import React, { memo, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { useDispatch, useSelector } from 'reduxStoreHelper'
import { TIME_AUTO_REDIRECT_CHECKIN } from 'constants/misc'
import Header from '../components/Header'
import PrevStartButton from '../components/PrevStartButton'
import { INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import { resetAllData } from '../reducer'
import SMSIcon from 'assets/svgs/sms.svg'
import lineApp from 'assets/pngs/line-app.png'
import Truckbook from 'assets/svgs/monoful-logo-background-white.svg'

import { isPhoneCheckedSelectors } from '../selectors'
import * as S from '../style'

let timerId: number = 0

const EntryFinishPage = () => {
  const location = useLocation()
  const history: any = useHistory()
  const dispatch = useDispatch()
  const { generalReceptionNumber }: any = useParams()
  const isPhoneChecked = useSelector(isPhoneCheckedSelectors())

  const url: string = `${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`

  useEffect(() => {
    timerId = setTimeout(() => {
      history.push(url)
      dispatch(resetAllData())
    }, TIME_AUTO_REDIRECT_CHECKIN)

    return () => {
      if (timerId) {
        clearTimeout(timerId)
        timerId = 0
      }
    }
  }, [dispatch, generalReceptionNumber, history, url])

  const onClickExitButton = useCallback(() => {
    dispatch(resetAllData())
    history.push(url)
  }, [dispatch, history, url])

  const renderNotification = () => {
    const { lineFlag, portalFlag }: any = location?.state
    const smsFlag = !lineFlag && !portalFlag

    if (smsFlag) {
      return (
        <>
          <S.Text>
            入場準備が出来次第、<b>SMS</b>でご案内します。
          </S.Text>
          {!isPhoneChecked && (
            <S.TextNoteMessage>次回受付も、同じパスコードを使用します</S.TextNoteMessage>
          )}
          <S.IconWrapper sms>
            <S.LogoImage src={SMSIcon} alt="" />
          </S.IconWrapper>
          <S.Text sms>SMS</S.Text>
        </>
      )
    }

    if (lineFlag) {
      return (
        <>
          <S.Text>
            入場準備が出来次第、<b>LINE</b>でご案内します。
          </S.Text>
          {!isPhoneChecked && (
            <S.TextNoteMessage>次回受付も、同じパスコードを使用します</S.TextNoteMessage>
          )}
          <S.IconWrapper>
            <S.LogoImage src={lineApp} alt="" />
          </S.IconWrapper>
        </>
      )
    }

    if (portalFlag) {
      return (
        <>
          <S.Text>入場準備が出来次第、モノフルアプリでご案内します。</S.Text>
          {!isPhoneChecked && (
            <S.TextNoteMessage>次回受付も、同じパスコードを使用します</S.TextNoteMessage>
          )}
          <S.PortalIconWrapper>
            <S.LogoImage src={Truckbook} alt="" />
          </S.PortalIconWrapper>
          <h2 className="text-size32 mv12">モノフル</h2>
        </>
      )
    }
  }

  return (
    <S.Wrapper>
      <Header />
      <S.Content>
        <S.ContentTitle className="bg-white mb-3 p-5">
          <div className="text-center">
            <S.MessageDefault>入場受付完了</S.MessageDefault>
            <S.NotificationWrapper>{renderNotification()}</S.NotificationWrapper>
          </div>
        </S.ContentTitle>

        <div className="d-flex justify-content-between">
          <S.HeaderBox>
            <PrevStartButton onClick={onClickExitButton} />
          </S.HeaderBox>
          <S.HeaderBox />
          <S.HeaderBox className="text-right" />
        </div>
      </S.Content>
    </S.Wrapper>
  )
}

export default memo(EntryFinishPage)
