import React, { memo, useMemo } from 'react'
import _ from 'lodash'
import { Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { VEHICLE_NUMBER_LENGTH } from 'constants/Booking'
import * as S from '../style'
import Header from '../components/Header'
import InputNumberModal, { InputNumberModalSize } from '../components/InputNumberModal'
import { MAX_LENGTH_VEHICLE_NUMBER, INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import PrevStartButton from '../components/PrevStartButton'
import PageNumber from '../components/PageNumber'
import {
  bookingFromBookingNoSelectors,
  bookingSelectors,
  driverSelectors,
  isCreatedBookingDataSelectors,
  isFromBookingNoSelectors,
  listCompaniesSelectors,
  loadingSelectors,
  masterDataSelectors,
  phoneNumberEnteredSelectors,
  recentBookingSelectors,
  selectedBookingSelectors,
  isFromListCheckinSelectors,
  isAcceptPolicyChangeDriverNameSelectors,
  driverConfirmationsSelectors,
} from '../selectors'
import useEntryDriverInfoPage from '../hooks/useEntryDriverInfoPage'
import { Company, GeneralBookingType, Driver, GeneralMasterDataType } from '../type'
import theme from 'vars/theme'
import ConfirmDriverNameModal from 'components/ConfirmDriverNameModal'
import PolicyModal from 'components/PolicyModal'
import { ConfirmationType } from 'containers/IntegratedReceptionCustom/type'

const RequiredText = styled.span`
  color: ${theme.redPrimary2};
  font-size: 18px;
  margin-right: 16px;
`

export type Props = {
  booking: GeneralBookingType
  loading: boolean
  isCreatedBookingData: boolean
  isFromBookingNo: boolean
  phoneNumberEntered: string
  driver: Driver
  bookingFromBookingNo: Partial<GeneralBookingType> & { destination?: string }
  masterData: GeneralMasterDataType
  recentBooking: Partial<GeneralBookingType>
  listCompanies: Company[]
  generalReceptionId?: string
  selectedBooking: Partial<GeneralBookingType> & { destination?: string }
  isFromListCheckin: boolean
  canGeneralBooking?: boolean
  isAcceptPolicyChangeDriverName: boolean
  driverConfirmations?: ConfirmationType[]
}

export const DRIVER_NAME_GENERAL_ID = 'driver-name-general'

const EntryDriverInfoPage: React.FC<Props> = ({
  booking,
  loading,
  isCreatedBookingData,
  isFromBookingNo,
  phoneNumberEntered,
  driver: driverData,
  bookingFromBookingNo,
  masterData,
  recentBooking,
  listCompanies,
  generalReceptionId,
  selectedBooking,
  isFromListCheckin,
  canGeneralBooking,
  isAcceptPolicyChangeDriverName,
  driverConfirmations,
}) => {
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()
  const history = useHistory()

  const {
    driver,
    listCompanyOptions,
    isOpenVehicleNumberModal,
    isOpenModalConfirm,
    isOpenPolicyModal,
    policyData,
    setIsOpenModalConfirm,
    setIsOpenPolicyModal,
    handleChangeDriverCompany,
    handleBlurDriverCompany,
    handleChangeDriverName,
    handleSelectVehicleCategory,
    setIsOpenVehicleNumberModal,
    renderVehicleOptions,
    onClickEnterVehicleModal,
    onClickCloseVehicleModal,
    handleClickButtonExit,
    handleClickButtonBack,
    handleAcceptPolicyChangeNameDriver,
    handleConfirmChangeNameDriver,
    handleFocusInputDriverName,
  } = useEntryDriverInfoPage({
    loading,
    isCreatedBookingData,
    isFromBookingNo,
    phoneNumberEntered,
    driver: driverData,
    bookingFromBookingNo,
    masterData,
    recentBooking,
    listCompanies,
    generalReceptionId,
    selectedBooking,
    isFromListCheckin,
    canGeneralBooking,
    isAcceptPolicyChangeDriverName,
  })

  const isDisabledNexButton = useMemo(
    () =>
      !booking.driverCompanyName?.trim() ||
      !booking.vehicleNumber ||
      !booking.driverName?.trim() ||
      !booking.vehicleCategory,
    [booking]
  )

  const filteredConfirmations = driverConfirmations?.filter(
    (confirmation: ConfirmationType) => confirmation?.displayFlag
  )

  const handleOnDriverCompanyNameChange = (value: string) => {
    const maxLength = 200
    return value?.length <= maxLength ? value : value?.substr(0, maxLength)
  }

  const onClickExitButton = () => {
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
    handleClickButtonExit()
  }

  return (
    <S.Wrapper>
      <Header />
      <div className="p-3">
        <div className="bg-white mb-3 p-3" style={{ borderRadius: 12 }}>
          <div className="text-center mb-3">
            <div className="text-size32 font-weight-bold">ドライバー情報を入力</div>
            <div>以下の情報を入力してください</div>
          </div>
          <S.WrapperOptions>
            <S.WrapperFiledName className="mr-3">
              <S.OptionsBox>
                <RequiredText>必須</RequiredText>
                運送会社名
              </S.OptionsBox>
              {/* @ts-ignore */}
              <S.CustomCreatableSelect
                isClearable
                isInputDisplay
                options={listCompanyOptions}
                value={{
                  value: booking.driverCompanyName,
                  label: booking.driverCompanyName,
                }}
                onChange={handleChangeDriverCompany}
                onBlur={handleBlurDriverCompany}
                height="52px"
                fontSize="24px"
                onInputChange={handleOnDriverCompanyNameChange}
              />
            </S.WrapperFiledName>
            <S.WrapperFiledName>
              <S.OptionsBox>
                <RequiredText>必須</RequiredText>
                車番
              </S.OptionsBox>
              <S.InputInfo
                type="text"
                value={booking.vehicleNumber}
                maxLength={VEHICLE_NUMBER_LENGTH}
                onClick={() => setIsOpenVehicleNumberModal(true)}
                onChange={_.noop}
              />
            </S.WrapperFiledName>
          </S.WrapperOptions>
          <S.WrapperOptions>
            <S.WrapperFiledName className="mr-3">
              <S.OptionsBox>
                <RequiredText>必須</RequiredText>
                氏名フルネーム
              </S.OptionsBox>
              <S.InputInfo
                id={DRIVER_NAME_GENERAL_ID}
                type="text"
                value={booking.driverName}
                onChange={handleChangeDriverName}
                onFocus={handleFocusInputDriverName}
                placeholder={'例：山田　太郎'}
                maxLength={50}
              />
            </S.WrapperFiledName>
            <S.WrapperFiledName>
              <S.OptionsBox>
                <RequiredText>必須</RequiredText>
                車両形態
              </S.OptionsBox>
              <S.CustomSelect
                value={booking.vehicleCategory}
                onChange={handleSelectVehicleCategory}
              >
                {renderVehicleOptions()}
              </S.CustomSelect>
            </S.WrapperFiledName>
          </S.WrapperOptions>
        </div>
        <div className="d-flex justify-content-between">
          <div style={{ width: '33%' }}>
            <PrevStartButton onClick={onClickExitButton} />
          </div>
          <div className="text-center" style={{ width: '34%' }}>
            <PageNumber
              page="entry-driver-info"
              existsDriverConfirmations={Boolean(filteredConfirmations?.length)}
              isFreePlan={!canGeneralBooking}
            />
          </div>
          <div className="text-right" style={{ width: '33%' }}>
            <Button
              outline
              color="primary"
              className="rounded-pill bg-white px-4 text-primary"
              onClick={handleClickButtonBack}
            >
              もどる
            </Button>
            <Button
              color="primary"
              className="rounded-pill px-4 ml-3"
              disabled={isDisabledNexButton}
              onClick={() =>
                history.push(
                  `${INTEGRATED_RECEPTION_URL_BASE}/entry-load/${generalReceptionNumber}`
                )
              }
            >
              次へ
            </Button>
          </div>
        </div>
        <ConfirmDriverNameModal
          isOpen={isOpenModalConfirm}
          title="ドライバー氏名を変更しますか？<br/>※ 利用規約に同意が必要です。"
          textClose="いいえ"
          textOk="はい"
          handleCancel={() => setIsOpenModalConfirm(false)}
          handleConfirm={handleConfirmChangeNameDriver}
        />
        <PolicyModal
          isOpen={isOpenPolicyModal}
          policyData={policyData}
          onAccept={handleAcceptPolicyChangeNameDriver}
          onCancel={() => setIsOpenPolicyModal(false)}
        />
        <InputNumberModal
          isOpen={isOpenVehicleNumberModal}
          initValue={booking.vehicleNumber}
          size={InputNumberModalSize.SMALL}
          title="車番を入力"
          maxLength={MAX_LENGTH_VEHICLE_NUMBER}
          onClickEnter={onClickEnterVehicleModal}
          onClickClose={onClickCloseVehicleModal}
          isOneInput
        />
      </div>
    </S.Wrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  booking: bookingSelectors(),
  loading: loadingSelectors(),
  isCreatedBookingData: isCreatedBookingDataSelectors(),
  phoneNumberEntered: phoneNumberEnteredSelectors(),
  driver: driverSelectors(),
  bookingFromBookingNo: bookingFromBookingNoSelectors(),
  isFromBookingNo: isFromBookingNoSelectors(),
  masterData: masterDataSelectors(),
  recentBooking: recentBookingSelectors(),
  listCompanies: listCompaniesSelectors(),
  selectedBooking: selectedBookingSelectors(),
  isFromListCheckin: isFromListCheckinSelectors(),
  isAcceptPolicyChangeDriverName: isAcceptPolicyChangeDriverNameSelectors(),
  driverConfirmations: driverConfirmationsSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(memo, withConnect)(EntryDriverInfoPage)
