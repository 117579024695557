import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'
import {
  SHOW_FLASH_MESSAGE,
  HIDE_FLASH_MESSAGE,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
  UPDATED_BOOKING,
  TOGGLE_RENDER_CSV,
  CHECK_USER_POLICY_SUCCESS,
  UPDATE_USER_POLICY_SUCCESS,
} from './constants'

const initialState = fromJS({
  isUpdatedBooking: false,
  flashMessage: {},
  userRoles: {},
  loading: false,
  isLoadMore: true,
  renderCSV: false,
  policyData: {}
})

const reducer = handleActions(
  {
    [SHOW_FLASH_MESSAGE]: (state, { payload }) => state.set('flashMessage', fromJS(payload)),
    [HIDE_FLASH_MESSAGE]: (state) => state.set('flashMessage', fromJS({})),
    [GET_USER_ROLES_SUCCESS]: (state, { payload }) => state.set('userRoles', fromJS(payload)),
    [GET_USER_ROLES_FAILURE]: (state) => state.set('userRoles', fromJS({})),
    [UPDATED_BOOKING]: (state) => state.set('isUpdatedBooking', !state.get('isUpdatedBooking')),
    [TOGGLE_RENDER_CSV]: (state) => state.set('renderCSV', !state.get('renderCSV')),
    [CHECK_USER_POLICY_SUCCESS]: (state, { payload }) => state.set('policyData', fromJS(payload)),
    [UPDATE_USER_POLICY_SUCCESS]: (state) => state.set('policyData', fromJS({})),
  },
  initialState
)

export default reducer
