import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import moment from 'moment'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'

import Pagination from 'containers/HomePage/components/Pagination'
import {
  changePerPageItem,
  changeTenantId,
  changeWarehouseId,
  closeConfirmModalReload,
  fetchCardBookingsPagination,
  fetchListCardBookingOtherWorker,
  fetchListCardBookingWorker,
  fetchWarehouseList,
  reducer,
  resetAllTenantData,
  selectParameterFilterDate,
  updateBookingListCardStatus,
  updateOrder,
  updateOrderBy,
  fetchRoleTenant,
  resetData,
} from './reducer'
import { showFlashMessage } from 'containers/AppPrivate/actions'

import syncIcon from 'assets/svgs/sync-icon.png'
import { FIRST_PAGE } from 'constants/misc'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import CarRow, { Colgroup, Thead } from './components/CarRow'
import ConfirmModal from './components/ConfirmModal'
import Header from './components/Header'
import SettingColumnModal from './components/SettingColumnModal'
import { ORDER, TIME_FORMAT, context, optionTotalItem } from './constants'
import saga from './saga'
import {
  bookingMasterDataSelectors,
  lastUpdateTimeSelectors,
  listCardBookingsSelectors,
  listCardReceptionSelectors,
  loadingSelectors,
  orderBySelectors,
  roleTenantSelectors,
  activeTenantSelectors,
  modalOptionsConfirmSelectors,
  orderSelectors,
  paginationSelectors,
  selectedDaySelectors,
  tenantIdSelectors,
  warehouseIdSelectors,
  warehouseListSelectors,
  loadingRoleTenantSelectors,
} from './selectors'

import * as S from './style'

import {
  ConfirmModalReloadType,
  PaginationType,
  ParameterConfirmBookingWorkerType,
  ParameterFilterDateType,
  RoleTenantType,
  TenantType,
} from './type'

import { checkIsAdditionalColumn } from 'containers/IntegratedManagementPage/constants'
import { calculatePagination } from 'utils/commonFunctions'
import ConfirmModalReload from './components/ConfirmModalReload'
import { useHistory } from 'react-router-dom'
import loginStorage from 'utils/loginStorage'
import LoadingIndicator from './components/LoadingIndicator'
import errorMessages from 'utils/errorMessages'

type Props = {
  warehouseList: any[]
  listCardReception: any[]
  selectedDay: ParameterFilterDateType
  bookingMaster: any
  // TODO: update type listCards
  listCards: any[]
  warehouseId: number
  tenantId: number
  loading: boolean
  order: string
  orderBy: string
  roleTenant: RoleTenantType
  isLoadingRoleTenant: boolean
  activeTenant: TenantType
  modalOptions: {
    isOpen: boolean
    content?: string
  }
  pagination: PaginationType
  lastUpdateTime: Date
  loadingRoleTenant: boolean

  selectParameterFilterDate: (params: ParameterFilterDateType) => void
  changeWarehouseId: (id: number) => void
  changeTenantId: (id: number) => void
  resetAllTenantData: () => void
  fetchWarehouseList: (callback: () => void) => void
  fetchListCardBookingWorker: () => void
  updateOrder: (value: string) => void
  updateOrderBy: (value: string) => void
  fetchRoleTenant: () => void
  resetData: () => void
  fetchListCardBookingOtherWorker: () => void
  updateBookingListCardStatus: (data?: ParameterConfirmBookingWorkerType) => void
  closeConfirmModalReload: (data: ConfirmModalReloadType) => void
  fetchCardBookingsPagination: (currentPage: number, perPage: number) => void
  changePerPageItem: (perPage: number) => void
  showFlashMessage: (params: { message: string; isError: boolean }) => void
}

const IntegratedListCardsPage: FC<Props> = ({
  warehouseList,
  listCardReception,
  bookingMaster,
  listCards,
  selectedDay,
  warehouseId,
  tenantId,
  loading,
  order,
  orderBy,
  roleTenant,
  activeTenant,
  modalOptions,
  pagination,
  lastUpdateTime,

  selectParameterFilterDate,
  changeWarehouseId,
  changeTenantId,
  resetAllTenantData,
  fetchWarehouseList,
  fetchListCardBookingWorker,
  updateOrder,
  updateOrderBy,
  fetchRoleTenant,
  closeConfirmModalReload,
  resetData,
  loadingRoleTenant,
  fetchListCardBookingOtherWorker,
  updateBookingListCardStatus,
  fetchCardBookingsPagination,
  changePerPageItem,
  showFlashMessage,
}) => {
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isOpenSettingColumnModal, setIsOpenSettingColumnModal] = useState(false)
  const [statusUpdate, setStatusUpdate] = useState<ParameterConfirmBookingWorkerType>()

  const { startDate, endDate } = selectedDay
  const history = useHistory()
  const { totalPages, currentPage, totalElementsInPage, perPageItem } = pagination

  useEffect(() => {
    fetchWarehouseList(handleNotPermission)
    const { tenantId: tenantIdLocalStorage, warehouseId: warehouseIdLocalStorage } =
      loginStorage.getWarehouseSession()

    changeWarehouseId(warehouseIdLocalStorage)
    changeTenantId(tenantIdLocalStorage)

    return () => {
      resetData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loadingRoleTenant && !_isEmpty(roleTenant)) {
      const { admin_company, admin_tenant } = roleTenant || {}
      const canAcceptByRole = admin_company || admin_tenant

      if (canAcceptByRole) {
        fetchListCardBookingWorker()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRoleTenant])

  useEffect(() => {
    if (tenantId) {
      fetchRoleTenant()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])

  // Check role of page
  useEffect(() => {
    if (!_isEmpty(roleTenant) && !_isEmpty(activeTenant)) {
      const { can_list_card } = activeTenant || {}
      const { admin_company, admin_tenant, super_admin } = roleTenant || {}
      const canAcceptByRole = admin_company || admin_tenant || super_admin

      if (!can_list_card || !canAcceptByRole) {
        handleNotPermission()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleTenant, activeTenant])

  // Check if warehouse no tenant
  useEffect(() => {
    const warehouse = warehouseList.find(({ id }) => id === warehouseId)

    if (warehouse && _isEmpty(warehouse.tenants)) {
      handleNotPermission()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, warehouseList])

  useEffect(() => {
    if (tenantId && ((orderBy && order) || startDate || endDate)) {
      fetchListCardBookingOtherWorker()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, order, orderBy])

  const handleConfirmChangeStatus = (statusUpdate: ParameterConfirmBookingWorkerType) => {
    setStatusUpdate(statusUpdate)
    setIsOpenConfirmModal(true)
  }

  const handleNotPermission = () => {
    history.push('/')
    showFlashMessage({
      message: errorMessages.Unauthorized(),
      isError: true,
    })
  }

  const handleOrderByChange = useCallback(
    (nextColumnName: string) => {
      updateOrderBy(nextColumnName)

      if (orderBy !== nextColumnName) {
        updateOrder(ORDER.DESC)
        return
      }

      switch (order) {
        case ORDER.DESC:
          return updateOrder(ORDER.ASC)
        case ORDER.ASC:
          return updateOrder(ORDER.DEFAULT)
        case ORDER.DEFAULT:
          return updateOrder(ORDER.DESC)
        default:
          return
      }
    },
    [order, orderBy, updateOrder, updateOrderBy]
  )

  const cancelConfirmChangeBookingStatus = () => {
    setIsOpenConfirmModal(false)
  }

  const handleCloseSettingColumn = () => {
    setIsOpenSettingColumnModal(false)
  }

  const handleChangeBookingStatus = () => {
    updateBookingListCardStatus(statusUpdate)
    cancelConfirmChangeBookingStatus()
  }

  const handleCloseModal = useCallback(() => {
    closeConfirmModalReload({
      isOpen: false,
      content: '',
    })
    fetchListCardBookingOtherWorker()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeConfirmModalReload])

  const formatDataParameter = () => {
    const dataParameter = _filter(listCardReception || [], (item) => {
      return !checkIsAdditionalColumn(item?.column_name)
    })
    return dataParameter
  }

  const handlePagination = useCallback(
    (currentPage) => {
      fetchCardBookingsPagination(currentPage, perPageItem)
    },
    [fetchCardBookingsPagination, perPageItem]
  )

  const handlePerPageItem = useCallback(
    (perPageItem) => {
      fetchCardBookingsPagination(currentPage, perPageItem)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleUpdateListCardReload = useCallback(() => {
    fetchListCardBookingOtherWorker()
  }, [fetchListCardBookingOtherWorker])

  const { end, start, totalItems } = calculatePagination(
    currentPage,
    totalElementsInPage,
    perPageItem
  )

  return (
    <div className="h-100vh" style={{ backgroundColor: '#FAFAFA' }}>
      <Header
        selectedDay={selectedDay}
        warehouseList={warehouseList}
        warehouseId={warehouseId}
        tenantId={tenantId}
        selectParameterFilterDate={selectParameterFilterDate}
        changeWarehouseId={changeWarehouseId}
        changeTenantId={changeTenantId}
        resetAllTenantData={resetAllTenantData}
      />
      <S.Wrapper>
        <div style={{ display: 'flex' }}>
          <div style={{ fontSize: 18, fontWeight: 'bold', width: 120 }}>カード一覧</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '12px' }}>
            <img
              src={syncIcon}
              alt="sync-icon"
              style={{ cursor: 'pointer' }}
              onClick={handleUpdateListCardReload}
            />
          </span>
          <div className="font-md" style={{ fontWeight: 'bold' }}>
            最新の更新日時：{moment(lastUpdateTime).format(TIME_FORMAT)}
          </div>
        </div>
      </S.Wrapper>
      {!!totalPages && (
        <S.WrapperPagination>
          <S.PaginationContent>
            <S.WrapperSelect>
              <span className="total-item">表示件数</span>
              <span>
                <S.CustomSelectOption
                  color="#132D49"
                  name="total-item"
                  options={optionTotalItem}
                  width="90px"
                  skipAutoUpdate
                  selectValue={perPageItem}
                  handleUpdateData={(perPage: number) => {
                    changePerPageItem(perPage)
                    handlePerPageItem(perPage)
                  }}
                />
              </span>
            </S.WrapperSelect>

            <S.PaginationTextResult>
              <span className="total-item">{totalItems}</span>中
              <span>
                {start}-{end}
              </span>
              の結果
            </S.PaginationTextResult>
            <Pagination
              size="medium"
              meta={{
                total: totalPages,
                current: currentPage ?? FIRST_PAGE,
              }}
              onNavigateRequest={handlePagination}
            />
          </S.PaginationContent>
        </S.WrapperPagination>
      )}
      <S.WrapperContent className="mh20 ph20">
        {listCards?.length ? (
          <table style={{ position: 'relative' }}>
            <Colgroup columns={formatDataParameter()} />
            <Thead
              columns={formatDataParameter()}
              handleOrderByChange={handleOrderByChange}
              order={order}
              orderBy={orderBy}
            />
            <tbody>
              {listCards.map((booking: any) => (
                <CarRow
                  key={booking.generalBookingId}
                  dataColumnThead={formatDataParameter()}
                  booking={booking}
                  bookingMaster={bookingMaster}
                  handleConfirmChangeStatus={handleConfirmChangeStatus}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="my-3 text-center">該当する予約はありません</div>
        )}
      </S.WrapperContent>
      {loading && (
        <>
          <S.Backdrop />
          <LoadingIndicator />
        </>
      )}
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        handleCancel={cancelConfirmChangeBookingStatus}
        handleConfirm={handleChangeBookingStatus}
      />
      <ConfirmModalReload
        message={modalOptions.content}
        isOpen={modalOptions.isOpen}
        onClickClose={handleCloseModal}
      />
      <SettingColumnModal
        isOpen={isOpenSettingColumnModal}
        handleCloseModal={handleCloseSettingColumn}
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  warehouseList: warehouseListSelectors(),
  selectedDay: selectedDaySelectors(),
  warehouseId: warehouseIdSelectors(),
  tenantId: tenantIdSelectors(),
  listCardReception: listCardReceptionSelectors(),
  bookingMaster: bookingMasterDataSelectors(),
  listCards: listCardBookingsSelectors(),
  loading: loadingSelectors(),
  order: orderSelectors(),
  orderBy: orderBySelectors(),
  roleTenant: roleTenantSelectors(),
  activeTenant: activeTenantSelectors(),
  modalOptions: modalOptionsConfirmSelectors(),
  pagination: paginationSelectors(),
  lastUpdateTime: lastUpdateTimeSelectors(),
  loadingRoleTenant: loadingRoleTenantSelectors(),
})

const mapDispatchToProps = {
  fetchWarehouseList,
  selectParameterFilterDate,
  changeWarehouseId,
  changeTenantId,
  resetAllTenantData,
  fetchListCardBookingWorker,
  updateOrder,
  updateOrderBy,
  fetchRoleTenant,
  fetchListCardBookingOtherWorker,
  updateBookingListCardStatus,
  closeConfirmModalReload,
  resetData,
  fetchCardBookingsPagination,
  changePerPageItem,
  showFlashMessage,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: context, reducer })
const withSaga = injectSaga({ key: context, saga, mode: null })

export default compose(memo, withReducer, withSaga, withConnect)(IntegratedListCardsPage)
