import React, { FC, memo, useCallback, useState } from 'react'
import { Button } from 'reactstrap'

import * as S from './styles'

type Props = {
  onChangeFilterSearch(param: FilterSearchMode, onCloseBookingSearch: () => void): void
  filter: FilterSearchMode
}

export enum FilterSearchMode {
  IMPORT = 'import',
  EXPORT = 'export',
  BOTH = 'all',
}

const FilterSearchBooking: FC<Props> = ({ onChangeFilterSearch, filter }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)

  const handleCloseModalFilter = useCallback(() => setOpenModal(false), [])

  const handleOpenModalFilter = useCallback(() => setOpenModal(true), [])

  const isImport = FilterSearchMode.IMPORT === filter
  const isExport = FilterSearchMode.EXPORT === filter
  const isBoth = FilterSearchMode.BOTH === filter

  const handleChangeFilter = (filter: FilterSearchMode) => {
    onChangeFilterSearch(filter, () => setOpenModal(false))
  }

  return (
    <>
      <S.ButtonWrapper onClick={handleOpenModalFilter}>
        {isBoth ? (
          <S.ButtonAll>すべて</S.ButtonAll>
        ) : (
          <>
            <S.ButtonImport isSelected={isImport}>卸</S.ButtonImport>
            <S.ButtonExport isSelected={isExport}>積</S.ButtonExport>
          </>
        )}
      </S.ButtonWrapper>
      <S.MessageModal isOpen={openModal}>
        <S.ModalContent>
          <S.ModalHeader>
            <h5>卸し・積みで絞り込み</h5>
            <Button outline={false} color="none" onClick={handleCloseModalFilter}>
              閉じる
            </Button>
          </S.ModalHeader>
          <S.ModalBody>
            <S.RadioFilter
              id="both"
              type="radio"
              name="filter-search"
              onChange={() => handleChangeFilter(FilterSearchMode.BOTH)}
              checked={isBoth}
            />
            <S.FilterItem htmlFor="both">すべて</S.FilterItem>
            <S.RadioFilter
              id="import"
              type="radio"
              name="filter-search"
              onChange={() => handleChangeFilter(FilterSearchMode.IMPORT)}
              checked={isImport}
            />
            <S.FilterItem htmlFor="import">卸しを含む</S.FilterItem>
            <S.RadioFilter
              id="export"
              type="radio"
              name="filter-search"
              onChange={() => handleChangeFilter(FilterSearchMode.EXPORT)}
              checked={isExport}
            />
            <S.FilterItem htmlFor="export">積みを含む</S.FilterItem>
          </S.ModalBody>
        </S.ModalContent>
      </S.MessageModal>
    </>
  )
}

export default memo(FilterSearchBooking)
