import React from 'react'
import * as S from './styled'
import Button from 'components/Button'

import ReceptionItemRow from './ReceptionItemRow'
import { typeRow } from '../../constants'

type Props = {
  isOpen: boolean
  handleCloseModal: () => void
}

const data = [
  {
    columnName: 'vehicle_number',
    generalReceptionId: 26,
    parameterId: 242,
    parameterValue: 1,
    codeId: 12,
    requiredFlag: true,
    displayOrder: 1,
    displayName: '車番',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 3,
    type: 0,
    id: 1821,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'company_name',
    generalReceptionId: 26,
    parameterId: 243,
    parameterValue: 2,
    codeId: 12,
    requiredFlag: true,
    displayOrder: 2,
    displayName: '運送会社名',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 3,
    type: 0,
    id: 1822,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'driver_name',
    generalReceptionId: 26,
    parameterId: 244,
    parameterValue: 3,
    codeId: 12,
    requiredFlag: true,
    displayOrder: 3,
    displayName: 'ドライバー氏名',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 0,
    type: 0,
    id: 1823,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'vehicle_category',
    generalReceptionId: 26,
    parameterId: 245,
    parameterValue: 4,
    codeId: 12,
    requiredFlag: true,
    displayOrder: 4,
    displayName: '車両形態',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 2,
    type: 0,
    id: 1824,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_export_enable',
    generalReceptionId: 26,
    parameterId: 2598,
    parameterValue: 5,
    codeId: 12,
    requiredFlag: true,
    displayOrder: 5,
    displayName: '荷降ろし/荷積み',
    updatedAt: '2022-10-06T04:22:27.437+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 0,
    id: 1825,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_goods_owner',
    generalReceptionId: 26,
    parameterId: 246,
    parameterValue: 1,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 1,
    displayName: '荷主',
    updatedAt: '2022-10-11T07:44:25.163+00:00',
    displayFlag: true,
    cardViewOrder: 0,
    type: 0,
    id: 1826,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_goods_name',
    generalReceptionId: 26,
    parameterId: 247,
    parameterValue: 2,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 2,
    displayName: '荷物名',
    updatedAt: '2022-10-11T07:44:25.165+00:00',
    displayFlag: true,
    cardViewOrder: 0,
    type: 0,
    id: 1827,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_package_type',
    generalReceptionId: 26,
    parameterId: 248,
    parameterValue: 3,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 3,
    displayName: '荷姿',
    updatedAt: '2022-10-11T07:44:25.165+00:00',
    displayFlag: true,
    cardViewOrder: 5,
    type: 0,
    id: 1828,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_goods_amount',
    generalReceptionId: 26,
    parameterId: 249,
    parameterValue: 4,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 4,
    displayName: '荷量',
    updatedAt: '2022-10-11T07:44:25.165+00:00',
    displayFlag: true,
    cardViewOrder: 0,
    type: 0,
    id: 1829,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_main_goods',
    generalReceptionId: 26,
    parameterId: 250,
    parameterValue: 5,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 5,
    displayName: 'メモ',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 1,
    type: 0,
    id: 1830,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_delivery_slip_number',
    generalReceptionId: 26,
    parameterId: 251,
    parameterValue: 6,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 6,
    displayName: '伝票番号',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 0,
    type: 0,
    id: 1831,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_contact_notes',
    generalReceptionId: 26,
    parameterId: 252,
    parameterValue: 7,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 7,
    displayName: '連絡事項',
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: true,
    cardViewOrder: 0,
    type: 0,
    id: 1832,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_additional_column1',
    generalReceptionId: 26,
    parameterId: 253,
    parameterValue: 8,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 8,
    displayName: '1215',
    updatedAt: '2022-10-13T09:11:58.023+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1833,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_additional_column2',
    generalReceptionId: 26,
    parameterId: 254,
    parameterValue: 9,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 9,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1834,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_additional_column3',
    generalReceptionId: 26,
    parameterId: 255,
    parameterValue: 10,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 10,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.996+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1835,
    createdAt: '2022-09-30T04:36:02.996+00:00',
  },
  {
    columnName: 'import_additional_column4',
    generalReceptionId: 26,
    parameterId: 341,
    parameterValue: 11,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 11,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1836,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column5',
    generalReceptionId: 26,
    parameterId: 342,
    parameterValue: 12,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 12,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1837,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column6',
    generalReceptionId: 26,
    parameterId: 343,
    parameterValue: 13,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 13,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1838,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column7',
    generalReceptionId: 26,
    parameterId: 344,
    parameterValue: 14,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 14,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1839,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column8',
    generalReceptionId: 26,
    parameterId: 345,
    parameterValue: 15,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 15,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1840,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column9',
    generalReceptionId: 26,
    parameterId: 346,
    parameterValue: 16,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 16,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1841,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column10',
    generalReceptionId: 26,
    parameterId: 347,
    parameterValue: 17,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 17,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1842,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column11',
    generalReceptionId: 26,
    parameterId: 348,
    parameterValue: 18,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 18,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1843,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column12',
    generalReceptionId: 26,
    parameterId: 349,
    parameterValue: 19,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 19,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1844,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column13',
    generalReceptionId: 26,
    parameterId: 350,
    parameterValue: 20,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 20,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1845,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column14',
    generalReceptionId: 26,
    parameterId: 351,
    parameterValue: 21,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 21,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1846,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column15',
    generalReceptionId: 26,
    parameterId: 352,
    parameterValue: 22,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 22,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1847,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column16',
    generalReceptionId: 26,
    parameterId: 353,
    parameterValue: 23,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 23,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1848,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column17',
    generalReceptionId: 26,
    parameterId: 354,
    parameterValue: 24,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 24,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1849,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column18',
    generalReceptionId: 26,
    parameterId: 355,
    parameterValue: 25,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 25,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1850,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column19',
    generalReceptionId: 26,
    parameterId: 356,
    parameterValue: 26,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 26,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1851,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column20',
    generalReceptionId: 26,
    parameterId: 357,
    parameterValue: 27,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 27,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1852,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column21',
    generalReceptionId: 26,
    parameterId: 358,
    parameterValue: 28,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 28,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1853,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column22',
    generalReceptionId: 26,
    parameterId: 359,
    parameterValue: 29,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 29,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1854,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
  {
    columnName: 'import_additional_column23',
    generalReceptionId: 26,
    parameterId: 360,
    parameterValue: 30,
    codeId: 13,
    requiredFlag: false,
    displayOrder: 30,
    displayName: null,
    updatedAt: '2022-09-30T04:36:02.997+00:00',
    displayFlag: false,
    cardViewOrder: 0,
    type: 1,
    id: 1855,
    createdAt: '2022-09-30T04:36:02.997+00:00',
  },
]

// const IMPORT_PARAMETER_FIRST_INDEX = 5
const BASE_PARAMETER = ['company_name', 'vehicle_number', 'driver_name', 'vehicle_category']
const ONLY_CHECKBOX_PARAMETERS = ['import_export_enable']

const SettingColumnModal: React.FC<Props> = ({ isOpen, handleCloseModal }) => {
  const getTypeOfRowItem = (columnName: string) => {
    if (BASE_PARAMETER.includes(columnName)) return typeRow.BASE
    if (ONLY_CHECKBOX_PARAMETERS.includes(columnName)) return typeRow.ONLY_CHECK_BOX
    return typeRow.IMPORT
  }

  const renderBodyModal = () => {
    return (
      <S.Wrapper>
        <div style={{ overflowY: 'auto' }}>
          <S.Table>
            <colgroup>
              <S.ColCustom />
              <S.ColCustom />
              <S.ColCustom />
            </colgroup>
            <thead className="border-bottom">
              <tr>
                <S.Th className="pl-3">項目名</S.Th>
                <S.Th className="text-center">表示順</S.Th>
                <S.Th className="text-center">表示／非表示</S.Th>
              </tr>
            </thead>
            <tbody className="border-0">
              {data.map((item, index) => (
                <ReceptionItemRow
                  key={item?.id}
                  // @ts-ignore
                  item={item}
                  type={getTypeOfRowItem(item.columnName)}
                  // onClickUp={onClickUp}
                  // onClickDown={onClickDown}
                  // onChangeRequired={onChangeRequired}
                  // onChangeVisibility={onChangeVisibility}
                  // onClickEdit={onClickEdit}
                  // hideUpButton={index === IMPORT_PARAMETER_FIRST_INDEX}
                  // hideDownButton={index === parameters.length - 1}
                  canUpdate={true}
                />
              ))}
            </tbody>
          </S.Table>
        </div>
      </S.Wrapper>
    )
  }

  return (
    <S.Modal isOpen={isOpen} modalClassName={isOpen ? 'modal-base-style' : ''}>
      <S.ModalHeader>
        <S.TitleModal>表示設定</S.TitleModal>
        <S.CloseInHeader onClick={handleCloseModal}>閉じる</S.CloseInHeader>
      </S.ModalHeader>
      <S.ModalBody>{renderBodyModal()}</S.ModalBody>
      <S.ModalFooter>
        <div className="w-100 d-flex flex-column">
          <div className="d-flex w-100 flex-end">
            <Button
              className="mr-3"
              size="sm"
              color="lightBlue"
              width="115px"
              onClick={handleCloseModal}
            >
              キャンセル
            </Button>

            <Button
              color={true ? 'blue' : 'disabledButton'}
              className="ml-2"
              size="sm"
              width="115px"
              type="button"
              onClick={() => {}}
              disabled={false}
            >
              保存する
            </Button>
          </div>
        </div>
      </S.ModalFooter>
    </S.Modal>
  )
}

export default SettingColumnModal
