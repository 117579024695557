import { createSelector } from 'reselect'
import _ from 'lodash'
import { compareValue } from 'utils/commonFunctions'

const multiBookingSelector = (state: any) => state.get('multiBooking')

export const draftOrdersSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.draftOrders)

export const filtersSelectors = () => createSelector(multiBookingSelector, (state) => state.filters)

export const vehicleCategoryMapSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.vehicleCategoryMap)

export const packageTypeMapSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.packageTypeMap)

export const generalReceptionTenantsSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.generalReceptionTenants)

export const multiBookingsSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.multiBookings)

export const totalsSelectors = () => createSelector(multiBookingSelector, (state) => state.totals)

export const loadingSelectors = () => createSelector(multiBookingSelector, (state) => state.loading)

export const generalReceptionsSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.generalReceptions)

export const paginationSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.pagination)

export const bookingSidePanelSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.bookingSidePanel)

export const editingBookingIdSidePanelSelector = () =>
  createSelector(multiBookingSelector, (state) => state.bookingSidePanel.bookingId)

export const isProcessingSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.isProcessing)

export const selectedGeneralReceptionSelectors = () =>
  createSelector(generalReceptionsSelectors(), filtersSelectors(), (generalReceptions, filters) => {
    return _.find(generalReceptions, (generalReception) =>
      compareValue(generalReception.id, filters?.generalReceptionId)
    )
  })

export const roleGeneralReceptionSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.role)

export const filterSearchBookingGeneralSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.filterSearchBooking)

export const bookingMasterGeneralSelectors = () =>
  createSelector(multiBookingSelector, (state) => state.bookingMaster)
