import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import styled, { css } from 'styled-components'
import Modal from 'react-modal'

import LoadingIndicator from 'components/LoadingIndicator'
import Checkbox from 'components/Checkbox'
import { formatConfirmation } from 'utils/formatters'
import theme from 'vars/theme'
import RestClient, { getAuthHeader } from 'services/RestClient'
import Icon from 'components/Icon'
import { getCheckinAuthHeader } from 'services/RestCheckinClient'

const Pre = styled.pre`
  // wrap text in pre tag
  // ref: https://stackoverflow.com/questions/248011/how-do-i-wrap-text-in-a-pre-tag
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */

  // 連続した半角文字の場合も改行する
  word-break: break-all;
  text-align: start;
  font-size: 16px;
`

const WrapDriverConfirmation = styled.div`
  flex: 1;
  position: relative;
  border: 1px solid ${theme.graySoft12};
  padding: 1rem;
  margin: 0 0.5rem;

  ${({ checkFlag }) =>
    checkFlag &&
    css`
      cursor: pointer;
    `};

  ${({ isImportant }) =>
    isImportant &&
    css`
      border: 4px solid ${theme.redPrimary1};
    `};
`

const ContentDriverConfirmation = styled.div`
  height: 260px;
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${({ isAboveText }) =>
    isAboveText &&
    css`
      & > img {
        order: 1;
        margin-bottom: 0px;
      }

      & .wrap-pre-content {
        order: 2;
        margin-bottom: 25px;
      }
    `};
`

const ImageContent = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: zoom-in;
`

const ImageContentModal = styled.img`
  max-width: 100%;
`

const TextImportant = styled.span`
  position: absolute;
  top: -45px;
  left: -4px;
  color: white;
  background: ${theme.redPrimary1};
  padding: 3px 12px;
`

const customStylesModal = {
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px',
    border: 'none',
    background: 'none',
    inset: 'unset',
    minHeight: '100%',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.graySoft13,
    overflowY: 'auto',
  },
}

const IconCloseModal = styled.span`
  position: fixed;
  top: 65px;
  right: 30px;
  background: black;
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 2px 12px 4px 12px;
  border-radius: 3px;

  img {
    width: 10px;
  }
`

const LoadingCustom = styled(LoadingIndicator)`
  z-index: 1;
`

const buildConfig = (isCheckinPage) => {
  return {
    headers: isCheckinPage ? getCheckinAuthHeader() : getAuthHeader(),
    'Content-Type': 'application/octet-stream',
    responseType: 'blob',
  }
}

export default ({
  isCheckinPage,
  confirmations,
  confirmedIds = [],
  onChangeCheck = _.noop,
  onClickCheck = _.noop,
}) => {
  const filteredConfirmations = confirmations.filter((confirmation) => confirmation.displayFlag)
  return (
    <div className="mx-auto">
      <div className="text-center mb-3">
        <div className="text-size32 font-weight-bold">ドライバーの皆様へ</div>
        <div>
          伝達事項が{filteredConfirmations.length}件あります。確認をして受付完了してください。
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        {filteredConfirmations.map((confirmation) => (
          <DriverConfirmationItem
            isCheckinPage={isCheckinPage}
            confirmedIds={confirmedIds}
            confirmation={confirmation}
            onChangeCheck={onChangeCheck}
            onClickCheck={onClickCheck}
            key={confirmation?.id}
          />
        ))}
      </div>
    </div>
  )
}

const DriverConfirmationItem = ({
  isCheckinPage,
  confirmation,
  confirmedIds,
  onChangeCheck,
  onClickCheck,
}) => {
  const [loading, setLoading] = useState(false)
  const [fileImg, setFileImg] = useState('')
  const [isShowModalImage, setIsShowModalImage] = useState(false)

  useEffect(() => {
    ;(async () => {
      const client = new RestClient()
      if (!_.isEmpty(confirmation?.images)) {
        setLoading(true)
        client
          .get(
            `/tablets/driver-confirmation-image`,
            { id: confirmation.images[0]?.id },
            buildConfig(isCheckinPage)
          )
          .then((res) => {
            const file = new Blob([res.data])
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function () {
              setFileImg(reader.result)
            }
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenModalImage = (e) => {
    e.stopPropagation()
    setIsShowModalImage(true)
  }

  return (
    <>
      <WrapDriverConfirmation
        isImportant={confirmation?.importantFlag}
        checkFlag={confirmation?.checkFlag}
        onClick={() => (loading ? _.noop : onClickCheck(confirmation))}
      >
        {confirmation?.importantFlag && <TextImportant>重要!!</TextImportant>}
        <ContentDriverConfirmation
          isAboveText={!_.isEmpty(confirmation?.images) && confirmation?.images[0]?.aboveTextFlag}
        >
          {loading ? (
            <LoadingCustom isRelative />
          ) : (
            <>
              <div className="wrap-pre-content">
                <Pre className="m-1 py-2">{formatConfirmation(confirmation?.confirmation)}</Pre>
              </div>
              {!_.isEmpty(confirmation?.images) && (
                <ImageContent
                  src={fileImg}
                  alt="image confirmation"
                  onClick={handleOpenModalImage}
                />
              )}
            </>
          )}
          <div className="w-100" style={{ position: 'absolute', bottom: 16 }}>
            <div className="text-center">
              {confirmation?.checkFlag && (
                <Checkbox
                  label={confirmedIds.includes(confirmation?.id) ? '確認済' : '確認'}
                  checked={confirmedIds.includes(confirmation?.id)}
                  disabled={loading}
                  onChange={(e) => onChangeCheck(e, confirmation)}
                />
              )}
            </div>
          </div>
        </ContentDriverConfirmation>
      </WrapDriverConfirmation>
      <Modal
        isOpen={isShowModalImage}
        onRequestClose={() => setIsShowModalImage(false)}
        style={customStylesModal}
      >
        <IconCloseModal onClick={() => setIsShowModalImage(false)}>
          <Icon src="/assets/svgs/close-icon-white.svg" alt="close modal" type="button" />
        </IconCloseModal>

        <ImageContentModal src={fileImg} alt="image confirmation modal" />
      </Modal>
    </>
  )
}
