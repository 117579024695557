import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'

import Header from 'components/Layout/Header'
import ConfirmModal from 'components/ConfirmModal'
// import { MainWrapper } from 'components/Layout/commonLayoutStyles'
// TODO 共通コンポーネントの場所に移動する
import DayPickerInput from '../HomePage/components/DayPickerInput'
import CarRow, { Colgroup, Thead } from './components/CarRow'
import ConfirmDialog from './components/ConfirmDialog'
import FilterTabs from './components/FilterTabs'
import { GeneralBookingStatuses, FIRST_PAGE, BookingSidePanelType } from './constants'
import FloatingBookingDetail from 'containers/IntegratedManagementPage/components/FloatingBookingDetail'
import { uploadCsv } from 'containers/IntegratedManagementPage/reducers'
import FloatingMultiBookingEditor from 'containers/IntegratedManagementPage/components/FloatingMultiBookingEditor'
import DownloadUploadCSV from 'components/DownloadUploadCSV'
import CustomButton from 'components/CustomButton'
import plusIcon from 'assets/svgs/plus-icon.svg'
import { userDataSelectors, receptionDataSelectors } from 'containers/App/selectors'
import {
  draftOrdersSelectors,
  filtersSelectors,
  vehicleCategoryMapSelectors,
  packageTypeMapSelectors,
  generalReceptionTenantsSelectors,
  multiBookingsSelectors,
  totalsSelectors,
  loadingSelectors,
  generalReceptionsSelectors,
  paginationSelectors,
  editingBookingIdSidePanelSelector,
  selectedGeneralReceptionSelectors,
  roleGeneralReceptionSelectors,
  filterSearchBookingGeneralSelectors,
  bookingMasterGeneralSelectors,
} from './selectors'
import {
  reducer,
  assignOrders,
  fetchMultiBookings,
  changeMultiBookingFilterDate,
  changeMultiBookingFilterStatus,
  changeMultiBookingFilterGeneralReceptionId,
  fetchOrders,
  fetchBookingMaster,
  fetchGeneralReceptions,
  updateBookingSidePanelData,
  putConfirmGeneralBookings,
  putCheckinGeneralBookings,
  resetGeneralBookingData,
  updateRoleGeneralBooking,
  searchMultipleBookingWithFilter,
  updateFilterSearchGeneralBooking,
} from './reducers'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import saga from './sagas'
import LoadingIndicator from 'components/LoadingIndicator'
import StyledSelect from './components/StyledSelect'
import Pagination from 'containers/HomePage/components/Pagination'
import BookingSidePanel from './containers/BookingSidePanel'
import {
  AssignType,
  LOCAL_STORAGE_GENERAL_RECEPTION_ID,
  ChangeStatusType,
  NUMBER_ITEM_IN_PER_PAGE,
} from './constants'
import { HISTORY_SEARCH_GENERAL_BOOKING } from 'utils/loginStorage'
import { BookingStatuses, DownloadTypes } from 'constants/misc'
import errorMessages from 'utils/errorMessages'
import SearchBookingForm from 'components/SearchBookingForm'

import * as S from './style'
import FilterSearchBooking from 'components/FilterSearchBooking'
import { getHistoryFromLocalStorageById, setHistoryToLocalStorageById } from 'utils/commonFunctions'

const refs = {}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`
const CreateButton = styled(CustomButton)`
  margin-left: 0;

  img {
    margin-right: 4px;
  }

  &:disabled {
    color: #a1a6b1;
    border-color: #e9ecef;
    background-color: #e9ecef;
    box-shadow: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 20px 15px;

  @media (max-width: 1024px) {
    display: block;
  }
`

const WrapperContent = styled.div`
  position: relative;
  overflow-y: scroll;
  border: 1px solid #ddd;
  background-color: #fff;
  height: calc((var(--vh, 1vh) * 100) - 170px);

  @media (max-width: 1024px) {
    height: calc((var(--vh, 1vh) * 100) - 220px);
  }
`

const WrapperPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 10px;
`

// 総合管理ページ
class IntegratedManagementPage extends Component {
  state = {
    // 現在編集中の booking id
    editingBookingId: '',
    isModalOpen: false,
    bookingDetailTop: 0,
    multiBookingEditorTop: 0,
    selectedBookingId: 0,
    assignType: null,
    multiBookingEditorHeight: 0,
    multiBookingDetailWidth: 0,
    changeStatusData: {
      type: '',
      id: '',
    },
    predicateDraft: '',
    predicateHistoriesStore: null,
    predicateHistories: [],
  }
  // エラーが発生する
  // refs = {}

  // TODO 2回呼ばれている
  componentDidMount() {
    const { generalReceptionId } = this.props.filters

    this.isMountedFlag = true

    this.props.changeMultiBookingFilterDate({ date: new Date() })
    this.props.changeMultiBookingFilterStatus({ status: GeneralBookingStatuses.CHECKIN.id })

    this.props.fetchGeneralReceptions()

    if (generalReceptionId) {
      this.props.fetchMultiBookings()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userData,
      history,
      showFlashMessage,
      receptionData,
      selectedGeneralReception,
      filters,
    } = this.props
    // Check the isExistsReception: if it is false, the user isn't general admin, general company or controller
    if (!_.isEmpty(userData) && !_.isEmpty(receptionData) && !receptionData?.is_exists_reception) {
      showFlashMessage({
        message: errorMessages.Unauthorized(),
        isError: true,
      })
      history.push('/')
    }

    // Check role of user in the current general reception
    if (prevProps.selectedGeneralReception !== selectedGeneralReception && !_.isEmpty(userData)) {
      this.props.updateRoleGeneralBooking()
    }
    // If switch general reception, and the generation reception is off (previous tab is booking or unconfirm)
    if (
      selectedGeneralReception !== prevProps.selectedGeneralReception &&
      (filters?.status === GeneralBookingStatuses.BOOKING.id ||
        filters?.status === GeneralBookingStatuses.CONFIRM.id)
    ) {
      if (!selectedGeneralReception?.features?.canGeneralBooking) {
        this.onClickFilter(GeneralBookingStatuses.CHECKIN.id)
      }
    }

    if (
      selectedGeneralReception?.id &&
      selectedGeneralReception !== prevProps.selectedGeneralReception
    ) {
      this.props.fetchBookingMaster()
    }
  }

  componentWillUnmount() {
    this.isMountedFlag = false
    this.props.resetGeneralBookingData()
  }

  // 日付フィルタが変更された
  onChangeDateFilter = (date) => {
    const { changeMultiBookingFilterDate } = this.props
    this.setState({ bookingDetailTop: 0, predicateHistoriesStore: null, predicateHistories: [] })
    changeMultiBookingFilterDate({ date, isClearFilterSearch: true, page: FIRST_PAGE })
  }

  onClickOpenDetail = (e, bookingId) => {
    const { currentTarget } = e
    const { bookingDetailTop, selectedBookingId } = this.state
    const widthOfParent = currentTarget.closest('table').scrollWidth

    this.setState({
      // 今表示している行がクリックされたときは隠して、それ以外のときは表示する
      bookingDetailTop:
        bookingDetailTop && bookingId === selectedBookingId
          ? 0
          : currentTarget.offsetTop + currentTarget.offsetHeight,
      selectedBookingId: bookingId,
      multiBookingDetailWidth: widthOfParent,
    })
  }

  onClickCloseDetail = () => {
    this.setState({ bookingDetailTop: 0 })
  }

  // 割り当て確認
  onClickDecide = () => {
    this.setState({ isModalOpen: true })
  }

  // 倉庫のチェックボックス
  onClickToggleWarehouse = (warehouseId) => {
    // todo parent_booking_id, warehouse_id
    const { draftOrders } = this.props

    const ids = draftOrders.includes(warehouseId)
      ? draftOrders.filter((checkedWarehouseId) => checkedWarehouseId !== warehouseId)
      : draftOrders.concat(warehouseId)

    this.props.assignOrders({ orders: ids })
  }

  onClickMultiBookingEdit = (e, bookingId, assignType) => {
    e.stopPropagation()

    const { multiBookings, assignOrders } = this.props
    const booking = multiBookings.find((booking) => booking.generalBookingId === bookingId)
    // Set initial tenant order
    if (assignType === AssignType.ASSIGN) {
      assignOrders({ orders: [] })
    } else {
      assignOrders({
        orders: _.sortBy(booking.generalBookingOrders, 'bookingOrder')
          .filter((generalBookingOrder) => !generalBookingOrder.deletedDate)
          .map(({ generalReceptionTenantId }) => generalReceptionTenantId),
      })
    }

    const { parentElement } = e.currentTarget
    const tableNode = parentElement.closest('table')

    this.setState({
      editingBookingId: bookingId,
      multiBookingEditorTop: parentElement.offsetTop,
      bookingDetailTop: 0,
      assignType,
      multiBookingDetailWidth: 0,
      multiBookingEditorHeight: tableNode.scrollHeight,
    })
  }

  // 割り当てキャンセル
  onClickCancel = () => {
    this.props.assignOrders({ orders: [] })
    this.setState({
      editingBookingId: '',
      multiBookingEditorTop: 0,
      assignType: null,
      multiBookingEditorHeight: 0,
    })
  }

  onClickFilter = (filterStatus) => {
    this.props.changeMultiBookingFilterStatus({ status: filterStatus, page: FIRST_PAGE })
    this.setState({ bookingDetailTop: 0, multiBookingEditorHeight: 0, multiBookingDetailWidth: 0 })
  }

  // 割り当て
  onClickAssign = async () => {
    const { editingBookingId, assignType } = this.state
    const { draftOrders, generalReceptionTenants } = this.props

    const data = {
      generalBookingId: editingBookingId,
      tenantOrders: draftOrders.map((draftOrder, index) => ({
        tenantId: _.get(
          _.find(generalReceptionTenants, ['generalReceptionTenantId', draftOrder]),
          'tenantId'
        ),
        order: index + 1,
        generalReceptionTenantId: draftOrder,
      })),
      assignType,
      ...(assignType === AssignType.KEEP_PRE_ASSIGN_ORDER
        ? {
            assignType: AssignType.ASSIGN,
            isKeepOrder: true,
          }
        : {}),
    }
    this.props.fetchOrders(data)
    this.props.assignOrders({ orders: [] })
    this.setState({
      editingBookingId: '',
      isModalOpen: false,
      multiBookingEditorTop: 0,
      assignType: null,
    })
  }

  onChangeFilterGeneralReceptionId = (generalReception) => {
    const { changeMultiBookingFilterGeneralReceptionId } = this.props
    changeMultiBookingFilterGeneralReceptionId({
      generalReceptionId: generalReception.value,
      isClearFilterSearch: true,
      page: FIRST_PAGE,
    })
    this.setState({
      bookingDetailTop: 0,
      multiBookingEditorHeight: 0,
      multiBookingDetailWidth: 0,
      predicateHistoriesStore: null,
      predicateHistories: [],
    })
    localStorage.setItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID, generalReception.value)
  }

  hideConfirmModal = () => {
    this.setState((preState) => ({
      ...preState,
      isModalOpen: false,
      editingBookingId: preState.multiBookingEditorTop ? preState.editingBookingId : '',
    }))
  }

  handlePagination = (currentPage) => {
    this.props.fetchMultiBookings(currentPage)
  }

  onClickResetDraftTenant = () => {
    let orders = []
    const { editingBookingId } = this.state
    const { multiBookings } = this.props

    const editingBooking = _.find(multiBookings, ['generalBookingId', editingBookingId])
    const canNotReAssignOrders = _.get(editingBooking, 'generalBookingOrders', []).filter(
      ({ bookingStatus }) => bookingStatus >= BookingStatuses.Calling.id
    )

    if (canNotReAssignOrders.length > 0) {
      orders = _.sortBy(canNotReAssignOrders, 'bookingOrder').map(
        ({ generalReceptionTenantId }) => generalReceptionTenantId
      )
    }

    this.props.assignOrders({ orders })
  }

  onHandleAcceptDraftOrder = (generalBookingId, generalBookingOrders) => {
    this.props.assignOrders({
      orders: _.sortBy(generalBookingOrders, 'bookingOrder').map(
        ({ generalReceptionTenantId }) => generalReceptionTenantId
      ),
    })
    this.setState({
      editingBookingId: generalBookingId,
      isModalOpen: true,
      assignType: AssignType.KEEP_PRE_ASSIGN_ORDER,
    })
  }

  generateOrdersConfirmDialog = () => {
    const { assignType, editingBookingId } = this.state
    const { multiBookings, draftOrders } = this.props
    if (assignType === AssignType.KEEP_PRE_ASSIGN_ORDER) {
      const booking =
        multiBookings.find((booking) => booking.generalBookingId === editingBookingId) || {}
      return _.sortBy(
        _.filter(booking.generalBookingOrders, ({ deletedDate }) => !deletedDate),
        'bookingOrder'
      ).map(({ generalReceptionTenantId }) => generalReceptionTenantId)
    }

    return draftOrders
  }
  // ref: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
  isMountedFlag = false

  onOpenChangeStatusModal = (event, booking) => {
    const { generalBookingStatus, generalBookingId } = booking
    event.stopPropagation()
    this.setState({
      changeStatusData: {
        type:
          generalBookingStatus === GeneralBookingStatuses.BOOKING.id
            ? ChangeStatusType.CONFIRM
            : generalBookingStatus === GeneralBookingStatuses.CONFIRM.id ||
              generalBookingStatus === GeneralBookingStatuses.DELAY.id
            ? ChangeStatusType.CHECKIN
            : '',
        id: generalBookingId,
      },
    })
    this.onClickCloseDetail()
  }

  onSubmitChangeStatus = () => {
    const { putConfirmGeneralBookings, putCheckinGeneralBookings } = this.props
    const { id, type } = this.state.changeStatusData

    switch (type) {
      case ChangeStatusType.CONFIRM:
        putConfirmGeneralBookings([id], this.onCancelChangeStatus)
        break
      case ChangeStatusType.CHECKIN:
        putCheckinGeneralBookings(id, this.onCancelChangeStatus)
        break
      default:
        break
    }
  }

  onCancelChangeStatus = () => {
    this.setState({
      changeStatusData: {
        type: '',
        id: '',
      },
    })
  }

  handleChangeValueSearch = (predicate) => {
    this.setState({
      predicateDraft: predicate,
    })
    this.getSearchHistories(predicate)
  }

  getGeneralBookingListProcess = (
    page = FIRST_PAGE,
    callback = _.noop,
    predicateParam,
    filterParam
  ) => {
    const { predicateDraft } = this.state
    const { filterSearchBookingGeneral, filters } = this.props
    const { filter } = filterSearchBookingGeneral || {}
    const { generalReceptionId } = filters || {}
    if (generalReceptionId) {
      const data = {
        page,
        filter: filterParam || filter,
        predicate: (_.isString(predicateParam) ? predicateParam : predicateDraft)?.trim(),
      }
      setHistoryToLocalStorageById(
        HISTORY_SEARCH_GENERAL_BOOKING,
        generalReceptionId,
        predicateDraft
      )
      this.setState({
        predicateHistoriesStore: null,
        predicateHistories: [],
      })
      this.props.searchMultipleBookingWithFilter(data, callback)
    }
  }

  handleSubmitSearch = (callback = _.noop) => {
    this.getGeneralBookingListProcess(FIRST_PAGE, callback)
    this.clearPredicateDraft()
  }

  onKeyPressInput = (e, callback = _.noop) => {
    if (e.key === 'Enter') {
      this.getGeneralBookingListProcess(FIRST_PAGE, callback)
      e.target.blur()
      this.clearPredicateDraft()
    }
  }

  onCloseSearchForm = () => {
    const { predicateDraft } = this.state
    this.handleChangeValueSearch(predicateDraft)
  }

  handleChangeFilterSearch = (filter, onCloseBookingSearch) => {
    const { filterSearchBookingGeneral } = this.props
    const { predicate } = filterSearchBookingGeneral
    this.getGeneralBookingListProcess(FIRST_PAGE, onCloseBookingSearch, predicate, filter)
  }

  updatePredicateDraft = (value) => {
    this.setState({
      predicateDraft: value,
    })
  }

  clearPredicateDraft = () => {
    this.setState({
      predicateDraft: '',
    })
  }

  onActiveFormSearch = () => {
    const { filterSearchBookingGeneral } = this.props
    const { predicate } = filterSearchBookingGeneral
    this.updatePredicateDraft(predicate)
    this.getSearchHistories(predicate)
    this.onClickCloseDetail()
  }

  calculatePagination = () => {
    const { pagination } = this.props
    const currentPage = pagination.currentPage ?? FIRST_PAGE
    const totalItems = pagination?.totalElementsInPage
    let start = (currentPage - 1) * NUMBER_ITEM_IN_PER_PAGE + 1
    let end =
      NUMBER_ITEM_IN_PER_PAGE * currentPage < totalItems
        ? NUMBER_ITEM_IN_PER_PAGE * currentPage
        : totalItems

    return { start, end, totalItems }
  }

  clearPredicateHistories = () => {
    this.setState({
      predicateHistories: [],
    })
  }

  getSearchHistories = _.debounce((keyword_search) => {
    const { predicateHistoriesStore } = this.state
    const { generalReceptionId } = this?.props?.filters || {}

    if (generalReceptionId) {
      const historiesFromLocalStorage = predicateHistoriesStore
        ? predicateHistoriesStore
        : getHistoryFromLocalStorageById(HISTORY_SEARCH_GENERAL_BOOKING, generalReceptionId)

      this.setState({
        predicateHistories: keyword_search
          ? historiesFromLocalStorage.filter((history) => history.startsWith(keyword_search))
          : historiesFromLocalStorage,
        ...(!predicateHistoriesStore && { predicateHistoriesStore: historiesFromLocalStorage }),
      })
    }
  }, 200)

  onClickHistoryItem = (keyword_search) => {
    this.updatePredicateDraft(keyword_search)
    this.clearPredicateHistories()
  }

  handleClickClearSearch = () => {
    this.clearPredicateHistories()
    this.clearPredicateDraft()
    this.getSearchHistories('')
  }

  render() {
    const {
      history,
      filters,
      draftOrders,
      vehicleCategoryMap,
      packageTypeMap,
      generalReceptionTenants,
      multiBookings,
      totals,
      loading,
      userData,
      generalReceptions,
      pagination,
      role,
      selectedGeneralReception,
      updateBookingSidePanelData,
      editingBookingIdSidePanel,
      uploadCsv,
      filterSearchBookingGeneral,
      bookingMaster,
    } = this.props
    const {
      editingBookingId,
      isModalOpen,
      changeStatusData,
      bookingDetailTop,
      multiBookingEditorTop,
      selectedBookingId,
      multiBookingEditorHeight,
      multiBookingDetailWidth,
      predicateDraft,
      predicateHistories,
    } = this.state
    // const bookings = berths.length ? berths[0].bookings : []
    // const { bookings = [] } = berths[0]
    const options = generalReceptions.map((generalReception) => ({
      value: generalReception.id,
      label: generalReception.name,
    }))
    const selectedOption = options.find((option) => option.value === filters.generalReceptionId)
    const { canGeneralBooking, canCustomizeReception } = selectedGeneralReception?.features || {}
    // Check if it's controller and not a admin general or admin company
    const isControllerRole = role?.controller && !role?.adminGeneralReception && !role?.adminCompany

    const { predicate, filter } = filterSearchBookingGeneral || {}
    let { start, end, totalItems } = this.calculatePagination()
    return (
      <div className="h-100vh" style={{ backgroundColor: '#FAFAFA' }}>
        <BookingSidePanel
          generalReceptionId={filters.generalReceptionId}
          isControllerRole={isControllerRole}
        />
        <Header>
          <div className="d-flex w-100 justify-content-start">
            <DayPickerInput value={filters.date} onDayChange={this.onChangeDateFilter} />
            <span className="m-1" />
            <StyledSelect
              options={options}
              onChange={this.onChangeFilterGeneralReceptionId}
              width="180px"
              value={selectedOption}
            />
          </div>
        </Header>
        <Wrapper>
          <div style={{ display: 'flex' }}>
            <div style={{ fontSize: 18, fontWeight: 'bold', width: 80 }}>総合管理</div>
            {canGeneralBooking && (
              <>
                <CreateButton
                  onClick={() => {
                    updateBookingSidePanelData(
                      {
                        openType: BookingSidePanelType.CREATE,
                      },
                      this.onClickCloseDetail()
                    )
                  }}
                >
                  <img src={plusIcon} alt="plus-icon" />
                  新規予約
                </CreateButton>
                <DownloadUploadCSV
                  isShowDownload
                  isShowUpload
                  isShowModalUpload
                  textUpload="CSV計画アップロード"
                  textDownload="CSV計画ダウンロード"
                  uploadCsv={({ file, encodingType }) => uploadCsv({ file, encodingType })}
                  downloadCsv={() => {
                    history.push(`/general-csv-download/${DownloadTypes.PLAN.label}`)
                  }}
                />
              </>
            )}
          </div>
          <FilterTabs
            canGeneralBooking={canGeneralBooking}
            filterId={filters.status}
            countPerFilters={
              canGeneralBooking
                ? {
                    [GeneralBookingStatuses.ALL.id]: totals.totalAll,
                    [GeneralBookingStatuses.BOOKING.id]: totals.totalBooking,
                    [GeneralBookingStatuses.CONFIRM.id]: totals.totalConfirm,
                    [GeneralBookingStatuses.CHECKIN.id]: totals.totalNotOrder,
                    [GeneralBookingStatuses.ASSIGN.id]: totals.totalOrder,
                    [GeneralBookingStatuses.FINISH.id]: totals.totalFinishAll,
                  }
                : {
                    [GeneralBookingStatuses.ALL.id]: totals.totalAll,
                    [GeneralBookingStatuses.CHECKIN.id]: totals.totalNotOrder,
                    [GeneralBookingStatuses.ASSIGN.id]: totals.totalOrder,
                    [GeneralBookingStatuses.FINISH.id]: totals.totalFinishAll,
                  }
            }
            onClick={this.onClickFilter}
          />
        </Wrapper>
        <S.SearchFilterWrapper>
          <S.SearchFilterContent isEmptyBooking={_.isEmpty(multiBookings)}>
            <div onClick={this.onClickCloseDetail}>
              <FilterSearchBooking
                filter={filter}
                onChangeFilterSearch={this.handleChangeFilterSearch}
              />
            </div>
            <div
              className="bg-gray-soft1 d-flex flex-column flex-shrink-0"
              style={{ padding: '0px 10px' }}
            >
              <SearchBookingForm
                defaultWidth={212}
                activeWidth={500}
                draftValue={predicateDraft}
                value={predicate}
                placeholder="検索"
                isSmallPlaceholderActive={!canCustomizeReception}
                placeholderActive={
                  canCustomizeReception ? '検索' : '社名/車番/氏名/電話番号/メモで検索'
                }
                histories={predicateHistories}
                isShowCloseIcon={!!predicateDraft}
                onClickClearSearch={this.handleClickClearSearch}
                onHandleKeyPress={this.onKeyPressInput}
                onCloseSearchForm={this.onCloseSearchForm}
                onChangeValue={this.handleChangeValueSearch}
                onClickSubmitSearch={this.handleSubmitSearch}
                onActive={this.onActiveFormSearch}
                clearPredicateHistories={this.clearPredicateHistories}
                getSearchHistories={this.getSearchHistories}
                onClickHistoryItem={this.onClickHistoryItem}
                maxLength={50}
              />
            </div>
          </S.SearchFilterContent>
          {!!pagination.totalPages && (
            <WrapperPagination>
              <S.PaginationContent>
                <S.PaginationTextResult>
                  <span className="total-item">{totalItems}</span>台
                  <span>
                    {start}-{end}
                  </span>
                  台を表示中
                </S.PaginationTextResult>
                <Pagination
                  size="medium"
                  meta={{
                    total: pagination.totalPages,
                    current: pagination.currentPage ?? FIRST_PAGE,
                  }}
                  onNavigateRequest={this.handlePagination}
                />
              </S.PaginationContent>
            </WrapperPagination>
          )}
        </S.SearchFilterWrapper>
        <WrapperContent className="mh20 ph20">
          {!multiBookings.length ? (
            <div className="my-3 text-center">該当する車両はありません</div>
          ) : (
            <table
              style={{
                // width: 'calc(100% - 40px)',
                marginBottom: 350,
                position: 'relative',
                // zIndex: 0,
              }}
            >
              <Colgroup tenants={generalReceptionTenants} />
              <Thead tenants={generalReceptionTenants} isSticky />

              <tbody style={{ border: 'none' }}>
                {multiBookings.map((booking) => (
                  <CarRow
                    key={booking.generalBookingId}
                    disableAssign={isControllerRole}
                    warehouses={generalReceptionTenants}
                    booking={booking}
                    role={role}
                    onClick={(e) => {
                      this.onClickOpenDetail(e, booking.generalBookingId)
                    }}
                    onClickToggleWarehouse={this.onClickToggleWarehouse}
                    onClickAssign={(e, assignType) =>
                      editingBookingId && editingBookingId !== booking.generalBookingId
                        ? null
                        : this.onClickMultiBookingEdit(e, booking.generalBookingId, assignType)
                    }
                    operationType="assign"
                    checkedWarehouseIds={draftOrders}
                    style={{
                      opacity:
                        editingBookingId && editingBookingId !== booking.generalBookingId ? 0.4 : 1,
                      ...(editingBookingIdSidePanel === booking.generalBookingId
                        ? { backgroundColor: '#DADADA' }
                        : {}),
                    }}
                    colWidth={
                      (window.innerWidth - 240 - 100 - 100 - 80) / generalReceptionTenants.length
                    }
                    rref={(el) => {
                      refs[booking.generalBookingId] = el
                    }}
                    vehicleCategoryMap={vehicleCategoryMap}
                    packageTypeMap={packageTypeMap}
                    updateBookingSidePanelData={updateBookingSidePanelData}
                    onClickCloseDetail={this.onClickCloseDetail}
                    onHandleAcceptDraftOrder={this.onHandleAcceptDraftOrder}
                    onOpenChangeStatusModal={this.onOpenChangeStatusModal}
                  />
                ))}
              </tbody>
            </table>
          )}
          {Boolean(bookingDetailTop) && (
            <FloatingBookingDetail
              booking={
                _.find(multiBookings, { generalBookingId: selectedBookingId }) || {
                  status: {},
                  driver: {},
                  vehicle: { number: '' },
                  import: {},
                  export: {},
                }
              }
              top={bookingDetailTop}
              width={multiBookingDetailWidth}
              onClickClose={this.onClickCloseDetail}
              packageTypeMap={packageTypeMap}
              vehicleCategoryMap={vehicleCategoryMap}
              bookingMaster={bookingMaster}
            />
          )}

          <FloatingMultiBookingEditor
            booking={
              _.find(multiBookings, { generalBookingId: editingBookingId }) || {
                status: {},
                driver: {},
                vehicle: { number: '' },
                import: {},
                export: {},
                generalReceptionTenants: [],
              }
            }
            tenants={generalReceptionTenants}
            onClickCancel={this.onClickCancel}
            onClickDecide={this.onClickDecide}
            onClickToggleWarehouse={this.onClickToggleWarehouse}
            top={multiBookingEditorTop}
            height={multiBookingEditorHeight}
            checkedWarehouseIds={draftOrders}
            vehicleCategoryMap={vehicleCategoryMap}
            packageTypeMap={packageTypeMap}
            onClickResetDraftTenant={this.onClickResetDraftTenant}
            bookingMaster={bookingMaster}
          />
        </WrapperContent>
        <ConfirmDialog
          isModalOpen={isModalOpen}
          hideModal={this.hideConfirmModal}
          executeAssign={this.onClickAssign}
          tenants={generalReceptionTenants}
          checkedWarehouseIds={this.generateOrdersConfirmDialog()}
        />
        {loading && (
          <Fragment>
            <Backdrop />
            <LoadingIndicator />
          </Fragment>
        )}
        <ConfirmModal
          title="本当によろしいでしょうか？"
          isOpen={!!changeStatusData.type}
          handleCancel={this.onCancelChangeStatus}
          handleConfirm={this.onSubmitChangeStatus}
        />
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  userData: userDataSelectors(),
  receptionData: receptionDataSelectors(),
  draftOrders: draftOrdersSelectors(),
  filters: filtersSelectors(),
  vehicleCategoryMap: vehicleCategoryMapSelectors(),
  packageTypeMap: packageTypeMapSelectors(),
  generalReceptionTenants: generalReceptionTenantsSelectors(),
  multiBookings: multiBookingsSelectors(),
  totals: totalsSelectors(),
  loading: loadingSelectors(),
  generalReceptions: generalReceptionsSelectors(),
  pagination: paginationSelectors(),
  editingBookingIdSidePanel: editingBookingIdSidePanelSelector(),
  selectedGeneralReception: selectedGeneralReceptionSelectors(),
  role: roleGeneralReceptionSelectors(),
  filterSearchBookingGeneral: filterSearchBookingGeneralSelectors(),
  bookingMaster: bookingMasterGeneralSelectors(),
})

const mapDispatchToProps = {
  assignOrders,
  fetchMultiBookings,
  changeMultiBookingFilterDate,
  changeMultiBookingFilterStatus,
  changeMultiBookingFilterGeneralReceptionId,
  fetchOrders,
  fetchBookingMaster,
  fetchGeneralReceptions,
  updateBookingSidePanelData,
  putConfirmGeneralBookings,
  putCheckinGeneralBookings,
  uploadCsv,
  showFlashMessage,
  resetGeneralBookingData,
  updateRoleGeneralBooking,
  searchMultipleBookingWithFilter,
  updateFilterSearchGeneralBooking,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'multiBooking', reducer })
const withSaga = injectSaga({ key: 'multiBooking', saga })

export default compose(withReducer, withSaga, withConnect)(IntegratedManagementPage)
