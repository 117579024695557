import _ from 'lodash'

export const context = 'containers/IntegratedReceptionCustom'

export const tabs = [
  {
    path: '/reception-custom-general/items',
    label: '受付項目',
    idTab: 'items',
  },
  {
    path: '/reception-custom-general/confirmation',
    label: '受付伝達事項',
    idTab: 'confirmation',
  },
  {
    path: '/reception-custom-general/card-items',
    label: 'カード表示内容',
    idTab: 'card-items',
  },
]

export const HEADER_HEIGHT = 60

export const routes = {
  itemsPage: '/reception-custom-general/items',
  confirmationPage: '/reception-custom-general/confirmation',
  confirmationEditPage: '/reception-custom-general/confirmation-edit',
  cardItemsPage: '/reception-custom-general/card-items',
  optionsPage: '/reception-custom-general/options/:option',
  vehicleCategory: '/reception-custom-general/options/vehicle-category',
  importPackageType: '/reception-custom-general/options/package-type',
}

export const typeRow = {
  BASE: 'base',
  IMPORT: 'import',
  ONLY_CHECK_BOX: 'onlyCheckbox',
}

export const LOCAL_STORAGE_GENERAL_RECEPTION_ID = 'generalReceptionId'

export const NUMBER_OF_ADDITIONAL_COLUMNS = 23

export const ADDITIONAL_COLUMNS_RANGE = _.range(1, NUMBER_OF_ADDITIONAL_COLUMNS + 1) // additional column has max 23 column

export const PARAMETER_NAME_LENGTH = 10

export const OPTION_NAME_LENGTH = 20

const ADDITIONAL_ID_PLUS = 2

const ADDITIONAL_COLUMNS: { [key: string]: { key: string } } = ADDITIONAL_COLUMNS_RANGE.reduce(
  (ac, id) => ({
    ...ac,
    [`ADDITIONAL_COLUMN${id}`]: {
      key: `ADDITIONAL_COLUMN${id}`,
      id: id + ADDITIONAL_ID_PLUS,
      name: `追加項目${id}`,
    },
  }),
  {}
)

export const ADDITIONAL_COLUMN_KEYS = _.toArray(ADDITIONAL_COLUMNS).map((item) => item.key)

export const BaseTenantParameterDisplayOrders: { [key: string]: number } = Object.freeze({
  vehicle_number: 1,
  company_name: 2,
  driver_name: 3,
  vehicle_category: 4,
  import_export_enable: 5,
})

export const TenantParameterTypes: { [key: string]: any } = {
  READ_ONLY: {
    id: 0,
    key: 'READ_ONLY',
    label: '変更不可',
  },
  TEXT: {
    id: 1,
    key: 'TEXT',
    label: '記述式',
  },
  SELECT: {
    id: 2,
    key: 'SELECT',
    label: '選択式',
  },
}

export const ReceptionCustomOptionGroups: { [key: string]: any } = {
  VEHICLE_CATEGORY: {
    key: 'VEHICLE_CATEGORY',
    id: 1,
    name: '車両形態',
  },
  PACKAGE_TYPE: {
    key: 'PACKAGE_TYPE',
    id: 2,
    name: '荷姿',
  },
  ...ADDITIONAL_COLUMNS,
}

export const dataSimpleCardItem = [
  {
    label: '項目 1',
    order: 1,
  },
  {
    label: '項目 2',
    order: 2,
  },
  {
    label: '項目 3',
    order: 3,
  },
  {
    label: '項目 4',
    order: 4,
  },
  {
    label: '項目 5',
    order: 5,
  },
  {
    label: '項目 6',
    order: 6,
  },
]

export const CardLabelItem = {
  companyName: '運送会社名［車番］',
  packageType: '荷姿',
  vehicleCategory: '車両形態',
  goodsOwner: '荷主',
  goodsName: '荷物名',
  driverName: 'ドライバー氏名',
  goodsAmount: '荷量',
  memo: 'メモ',
  importDeliverySlipNumber: '伝票番号',
  contactNotes: '連絡事項',
}

export const cardLabelPreviewItem = {
  [CardLabelItem.companyName]: '運送会社名[00-00]',
  [CardLabelItem.packageType]: '荷姿',
  [CardLabelItem.vehicleCategory]: '車両形態',
  [CardLabelItem.goodsOwner]: '荷主',
  [CardLabelItem.goodsName]: '荷物名',
  [CardLabelItem.driverName]: '氏名',
  [CardLabelItem.goodsAmount]: '荷量',
  [CardLabelItem.memo]: 'メモ',
  [CardLabelItem.importDeliverySlipNumber]: '伝票番号',
  [CardLabelItem.contactNotes]: '連絡事項',
}

export const DISPLAY_ORDER_OFFSET = 1000
export const VEHICLE_NUMBER_COLUMN_NAME = 'vehicle_number'

export const BaseGeneralParameterDisplayOrders = Object.freeze({
  vehicle_number: 1,
  company_name: 2,
  driver_name: 3,
  vehicle_category: 4,
  import_export_enable: 5,
})
