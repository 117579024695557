import React, { FC, memo } from 'react'

import * as S from '../style'

export type Props = {
  isShowPhoneNumberPrefix?: boolean
  isDisabledNumberPrefix?: boolean
  canClickEnter?: boolean
  onClick: (value: string) => void
  onClickBackSpace: () => void
  onClickEnter: () => void
}

const NumericKeypad: FC<Props> = ({
  isShowPhoneNumberPrefix = false,
  isDisabledNumberPrefix = false,
  canClickEnter = false,
  onClick,
  onClickBackSpace,
  onClickEnter,
}) => (
  <S.NumericKeypadWrapper isShowPhoneNumberPrefix={isShowPhoneNumberPrefix}>
    {/* left */}
    {isShowPhoneNumberPrefix && (
      <S.NumericKeypadBox>
        <S.CustomNumberButton onClick={() => onClick('070')} disabled={isDisabledNumberPrefix}>
          070
        </S.CustomNumberButton>
        <S.CustomNumberButton onClick={() => onClick('080')} disabled={isDisabledNumberPrefix}>
          080
        </S.CustomNumberButton>
        <S.CustomNumberButton onClick={() => onClick('090')} disabled={isDisabledNumberPrefix}>
          090
        </S.CustomNumberButton>
      </S.NumericKeypadBox>
    )}

    {/* center */}
    <div style={{ width: 496 }}>
      <div>
        <S.NumberButton style={{ marginRight: 8 }} onClick={() => onClick('1')}>
          1
        </S.NumberButton>
        <S.NumberButton style={{ marginRight: 8 }} onClick={() => onClick('2')}>
          2
        </S.NumberButton>
        <S.NumberButton onClick={() => onClick('3')}>3</S.NumberButton>
      </div>
      <div>
        <S.NumberButton style={{ marginRight: 8 }} onClick={() => onClick('4')}>
          4
        </S.NumberButton>
        <S.NumberButton style={{ marginRight: 8 }} onClick={() => onClick('5')}>
          5
        </S.NumberButton>
        <S.NumberButton onClick={() => onClick('6')}>6</S.NumberButton>
      </div>
      <div>
        <S.NumberButton style={{ marginRight: 8 }} onClick={() => onClick('7')}>
          7
        </S.NumberButton>
        <S.NumberButton style={{ marginRight: 8 }} onClick={() => onClick('8')}>
          8
        </S.NumberButton>
        <S.NumberButton onClick={() => onClick('9')}>9</S.NumberButton>
      </div>
      <div>
        <S.NumberButton style={{ marginLeft: 168 }} onClick={() => onClick('0')}>
          0
        </S.NumberButton>
      </div>
    </div>

    {/* right */}
    <S.NumericKeypadRightWrapper>
      <S.BackSpaceButton onClick={onClickBackSpace}>一文字消す</S.BackSpaceButton>
      <S.EnterButton disabled={!canClickEnter} onClick={onClickEnter}>
        決定
      </S.EnterButton>
    </S.NumericKeypadRightWrapper>
  </S.NumericKeypadWrapper>
)

export default memo(NumericKeypad)
