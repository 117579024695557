import React from 'react'
import theme from 'vars/theme'

const ColorMap = Object.freeze({
  red: theme.redPrimary2,
  blue: theme.bluePrimary2,
  gray: theme.grayPrimary1,
})

const determineColor = (type, disabled) => {
  const [color, style] = type.split('-')
  if (disabled) {
    return {
      text: 'white',
      border: 'none',
      background: theme.grayPrimary1,
    }
  }
  if (style === 'outline') {
    return {
      text: ColorMap[color],
      border: `1px solid ${ColorMap[color]}`,
      background: 'white',
    }
  }
  if (style === 'link') {
    return {
      text: ColorMap[color],
      border: 'none',
      background: 'transparent',
    }
  }
  return {
    text: 'white',
    border: 'none',
    background: ColorMap[color],
  }
}

export default ({ label = '', type = '', onClick, className, style, disabled, children }) => {
  const btnType = type.split('-')[1]
  const colors = determineColor(type, disabled)
  return (
    <button
      className={btnType === 'link' ? className : `button-hover ${className}`}
      style={{
        borderRadius: 68,
        border: colors.border,
        outline: 'none',
        color: colors.text,
        backgroundColor: colors.background,
        minWidth: 98,
        padding: '0 18px',
        fontSize: 12,
        fontWeight: 'bold',
        height: 28,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{label}</span>
      {children}
    </button>
  )
}
