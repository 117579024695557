const MEGA_BYTE = 1024 * 1024
export const ATTACHMENT_MAX_SIZE = 10 * MEGA_BYTE // 10MB
export const ATTACHMENT_CONFIRMATION_MAX_SIZE = 2 * MEGA_BYTE // 2MB
export const ATTACHMENT_MAX_NUM = 10

export const ErrorMessages = Object.freeze({
  OVER_MAX_COUNT: 'アップロードできるファイル数は10個までです',
  /* eslint-disable-next-line no-template-curly-in-string */
  OVER_MAX_SIZE: '${fileName} は10MBを超えているので添付できません',
  /* eslint-disable-next-line no-template-curly-in-string */
  CONFIRMATION_OVER_MAX_SIZE: '${fileName} は2MBを超えているので添付できません。',
  /* eslint-disable-next-line no-template-curly-in-string */
  INVALID_FILE_TYPE: '${fileName} は添付できない形式のファイルです',
  INVALID_FILE_NAME: 'ファイル名に , を含むファイルは添付できません',
  DUPLICATED_FILE_NAME: 'ファイル名が重複しています',
})

const ATTACHMENT_ACCEPTED_FILES: Array<any> = [
  {
    extension: 'png',
    icon: 'far fa-file-image',
    canPreview: true,
  },
  {
    extension: 'jpg',
    icon: 'far fa-file-image',
    canPreview: true,
  },
  {
    extension: 'jpeg',
    icon: 'far fa-file-image',
    canPreview: true,
  },
  {
    extension: 'pdf',
    icon: 'far fa-file-pdf',
    canPreview: true,
  },
  {
    extension: 'xls',
    icon: 'far fa-file-excel',
    canPreview: false,
  },
  {
    extension: 'xlsx',
    icon: 'far fa-file-excel',
    canPreview: false,
  },
]

export const ATTACHMENT_EXTENSIONS = ATTACHMENT_ACCEPTED_FILES.map(
  (file: any) => `.${file.extension}`
)

export const ATTACHMENT_ICONS = Object.freeze(
  ATTACHMENT_ACCEPTED_FILES.reduce(
    (ac: any, file: any) => ({ ...ac, [file.extension]: file.icon }),
    {}
  )
)

export const ATTACHMENT_PREVIEW_EXTENSIONS = ATTACHMENT_ACCEPTED_FILES.filter(
  (file: any) => file.canPreview
).map((file: any) => file.extension)

// Source:  https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
export const TYPE_BLOB_FILE_EXCEL: {
  [key: string]: string | undefined
} = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: ' application/vnd.ms-excel',
}
