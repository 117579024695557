import React from 'react'
import { Modal } from 'reactstrap'
import styled from 'styled-components'

import Button from 'components/Button'

export const Wrapper = styled(Modal)`
  width: fit-content;
  .modal-content {
    margin-top: 200px;
    padding: 30px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`

const Message = styled.span`
  font-size: 18px;
  margin: 20px 0;
  white-space: pre-wrap;
`

const ModalBodyCustom = styled.div`
  margin-bottom: 16px;
`

interface Props {
  isOpen: boolean
  message?: string
  onClickClose: () => void
}

export default ({ isOpen, message, onClickClose }: Props) => {
  return (
    <Wrapper isOpen={isOpen}>
      <ModalBodyCustom>
        <Message dangerouslySetInnerHTML={{ __html: message }}></Message>
      </ModalBodyCustom>
      <div className="d-flex justify-content-between button-group">
        <Button color="blue" onClick={onClickClose}>
          最新のデータ更新
        </Button>
      </div>
    </Wrapper>
  )
}
