import React, { FC } from 'react'
import { Label } from 'reactstrap'

import SingleForm from 'components/SingleForm'
import { GeneralBooking, BookingError } from 'containers/IntegratedManagementPage/type'
import { BookingSidePanelType } from 'containers/IntegratedManagementPage/constants'
import * as S from './style'
import { getWorkingTimeOfBooking } from 'utils/commonFunctions'

const RegisterId: FC<{ registerId: string }> = ({ registerId }) => (
  <S.RegisterId className="mb-2">
    <Label className="label-flex d-flex align-items-center mb-2">登録ID</Label>
    <div className="input-flex ml-1 form-control register-id__value">{registerId}</div>
  </S.RegisterId>
)

type Props = {
  openType: number
  draftBooking: GeneralBooking
  disabledEdit: boolean
  onChange: (value: string | number, name: string) => void
  error: BookingError
}

const BasicForm: FC<Props> = ({ disabledEdit, openType, draftBooking, error, onChange }) => {
  const renderShowWorkingTime = () => {
    const { planStartDate, planStartTime, planEndDate, planEndTime } = draftBooking || {}

    return getWorkingTimeOfBooking(planStartDate, planStartTime, planEndDate, planEndTime)
  }

  return (
    <S.FormBlockWrapper>
      <h3 className="font-weight-bold mb-3">基本情報</h3>
      {openType === BookingSidePanelType.EDIT && (
        <>
          <RegisterId registerId={draftBooking.registerId!} />
          <SingleForm
            id="general_booking_no"
            name="general_booking_no"
            label="総合受付番号"
            value={draftBooking.generalBookingNo}
            isDisabled
          />
        </>
      )}

      <SingleForm
        id="plan_start_date"
        name="plan_start_date"
        label="開始日"
        value={draftBooking.planStartDate}
        onChange={(value: string) => onChange(value, 'planStartDate')}
        isDayPicker
        required
        errorMessage={error.planStartDate}
        isDisabled={disabledEdit}
      />
      <SingleForm
        id="plan_start_time"
        name="plan_start_time"
        value={draftBooking.planStartTime}
        label="開始時間"
        isTimePicker
        onChange={(value: string) => onChange(value, 'planStartTime')}
        errorMessage={error.planStartTime}
        isDisabled={disabledEdit}
      />
      <SingleForm
        id="plan_end_date"
        name="plan_end_date"
        label="終了日"
        value={draftBooking.planEndDate}
        onChange={(value: string) => onChange(value, 'planEndDate')}
        isDayPicker
        required
        errorMessage={error.planEndDate}
        isDisabled={disabledEdit}
      />
      <SingleForm
        id="plan_end_time"
        name="plan_end_time"
        label="終了時間"
        value={draftBooking.planEndTime}
        isTimePicker
        onChange={(value: string) => onChange(value, 'planEndTime')}
        errorMessage={error.planEndTime}
        isDisabled={disabledEdit}
      />
      <SingleForm
        id="working_time_text"
        label="作業時間"
        value={renderShowWorkingTime()}
        isDisabled
      />
    </S.FormBlockWrapper>
  )
}

export default BasicForm
