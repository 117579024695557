import styled from 'styled-components'
import theme from 'vars/theme'

export const Wrapper = styled.div`
  min-width: 220px;
  margin-left: 12px;
  display: flex;
  position: relative;
  border: 1px solid #cccccc;
  border-radius: 6px;

  .calendar-icon {
    position: absolute;
    right: 35px;
    top: 10px;
  }

  .DayPickerInput-Overlay {
    width: 230px;
    border: none;
    margin-top: 2px;
    border-radius: 18px;
    z-index: 100;
    transform: translateX(-202px);
  }

  .DayPicker-wrapper {
    color: ${theme.brownMedium1};
    font-weight: 600;
    width: 230px;
    font-size: 12px;
    padding: 12px 0;

    .DayPicker-Day {
      height: 30px;
      padding: 4px 6px;
    }

    .DayPicker-WeekdaysRow {
      .DayPicker-Weekday:nth-child(1) {
        color: ${theme.redPrimary1};
      }
      .DayPicker-Weekday:nth-child(7) {
        color: ${theme.blueMedium5};
      }
    }

    .DayPicker-Week {
      .DayPicker-Day:nth-child(1):not(.DayPicker-Day--disabled) {
        color: ${theme.redPrimary1};
      }
      .DayPicker-Day:nth-child(7):not(.DayPicker-Day--disabled) {
        color: ${theme.blueMedium5};
      }
    }

    .DayPicker-Day--today {
      color: ${theme.brownMedium1};
      background-color: ${theme.graySoft5};
    }

    .DayPicker-Month {
      margin: 0;
      width: 208px;
    }

    .DayPicker-Day--selected {
      color: ${theme.whitePrimary} !important;
    }

    .DayPicker-Months {
      width: 100%;
    }

    .DayPicker-Footer {
      display: flex;
      justify-content: center;
      .DayPicker-TodayButton {
        width: 190px;
        height: 34px;
        border: 1px solid ${theme.grayMedium3};
        color: ${theme.blueMedium5};
        border-radius: 4px;
        font-weight: 600;
      }
    }
  }

  .DayPickerInput {
    width: 0;
    margin: 0;

    input {
      width: 0;
      height: 0;
      &:focus {
        outline: none;
      }
    }
  }

  .value-content {
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 175px;
    padding-left: 8px;
    font-size: 12px;
    color: ${theme.brownMedium1};
    background: ${theme.whitePrimary};
    height: 34px;
    border: none;
    border-radius: 0;

    .number {
      font-size: 15px;
      display: flex;
    }

    .text,
    .number {
      line-height: 20px;
    }

    .day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 16px;
      font-size: 10px;
      line-height: 16px;
      border-radius: 8px;
      background: ${theme.grayMedium3};
    }
  }

  .navigate {
    height: 34px;
    padding: 0 8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.whitePrimary};
    &:hover {
      background: ${theme.blueSoft4};
    }
  }

  .nav--wrapper {
    height: 0;
    display: flex;
    justify-content: space-between;

    .nav-button {
      cursor: pointer;
      font-size: 11px;
      font-weight: 600;
      border: none;
      background: none;
      outline: none;
      color: ${theme.blueMedium5};
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(2px);
    }
  }

  .prev {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid ${theme.graySoft4};
  }

  .next {
    border-radius: 0 4px 4px 0;
    border-left: 1px solid ${theme.graySoft4};
  }
`
