import React from 'react'
import { takeLatest, takeEvery, put, call, select, all } from 'redux-saga/effects'
import { camelizeKeys, decamelizeKeys, camelize } from 'humps'
import _ from 'lodash'
import moment from 'moment'
import { formatDate } from './utils'

import {
  NUMBER_ITEM_IN_PER_PAGE,
  STANDARD_PLAN_DAY_FORMAT,
  NotificationType,
  justCheckin,
} from 'containers/IntegratedManagementPage/constants'
import { MessageUploadSuccess } from 'components/DownloadUploadCSV'
import {
  getMultiBookings,
  getMultiBookingTenants,
  postOrders,
  getBookingMaster,
  getGeneralReceptions,
  getInitialBooking,
  getGeneralBookingMaster,
  deleteGeneralBooking,
  getRemoteCompanies,
  getRemoteCompany,
  getRemoteDriver,
  getRemoteControllerByEmail,
  updateGeneralBooking,
  createGeneralBooking,
  uploadCsv,
  putConfirmGeneralBookings,
  putCheckinGeneralBookings,
  getRoleOfGeneralReception,
} from './api'
import { convertAttachFile } from 'containers/HomePage/containers/SlideModal/constants'
import { compareValue } from 'utils/commonFunctions'
import {
  filtersSelectors,
  generalReceptionsSelectors,
  selectedGeneralReceptionSelectors,
  bookingSidePanelSelectors,
  filterSearchBookingGeneralSelectors,
} from './selectors'
import { LOCAL_STORAGE_GENERAL_RECEPTION_ID } from './constants'
import { userDataSelectors } from 'containers/App/selectors'
import { FilterSearchMode } from 'components/FilterSearchBooking'

function* fetchMultiBookings(params) {
  yield put({ type: 'START_LOADING' })
  const multiBookingStore = yield select((state) => state.get('multiBooking'))
  const { date, status, generalReceptionId } = multiBookingStore.filters
  const { currentPage: currentPageStore } = multiBookingStore.pagination
  const currentPage =
    _.get(params, 'payload.page') || _.get(params, 'payload.currentPage') || currentPageStore
  const filterSearchBookingGeneral = yield select(filterSearchBookingGeneralSelectors())
  const { predicate, filter: filterSearch } = filterSearchBookingGeneral || {}
  const callback = _.get(params, 'payload.callback')
  const isClearFilterSearch = _.get(params, 'payload.isClearFilterSearch')
  let filter = FilterSearchMode.BOTH
  let keyword = ''

  if (!isClearFilterSearch) {
    keyword = _.isString(_.get(params, 'payload.data.predicate'))
      ? _.get(params, 'payload.data.predicate')
      : predicate
    filter = _.get(params, 'payload.data.filter') || filterSearch
  }

  try {
    const resp1 = yield call(getMultiBookingTenants, generalReceptionId)
    const data1 = camelizeKeys(resp1.data)
    const resp2 = yield call(
      getMultiBookings,
      date,
      status,
      generalReceptionId,
      currentPage,
      NUMBER_ITEM_IN_PER_PAGE,
      keyword,
      filter
    )
    const data2 = camelizeKeys(resp2.data)

    const { generalReceptionTenants } = data1
    const sortedGeneralReceptionTenants = _.sortBy(generalReceptionTenants, 'viewOrder')
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { key: 'generalReceptionTenants', data: sortedGeneralReceptionTenants },
    })
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { key: 'multiBookings', data: data2.generalBooking },
    })

    const {
      totalNotOrder,
      totalOrder,
      totalConfirm,
      totalBooking,
      totalFinishAll,
      totalAll,
    } = data2
    yield put({
      type: 'FETCH_SUCCESS',
      payload: {
        key: 'pagination',
        data: {
          totalPages: data2.totalPages,
          currentPage: currentPage,
          totalElementsInPage: data2?.totalElementsInPage || 0,
        },
      },
    })
    yield put({
      type: 'FETCH_SUCCESS',
      payload: {
        key: 'totals',
        data: {
          totalNotOrder,
          totalOrder,
          totalConfirm,
          totalBooking,
          totalFinishAll,
          totalAll,
        },
      },
    })
    yield put({
      type: 'UPDATE_FILTER_SEARCH_GENERAL_BOOKING',
      payload: { predicate: keyword, ...(filter && { filter }) },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  } finally {
    if (callback) {
      callback()
    }
  }
}

function* fetchOrders({ payload: { orders } }) {
  yield put({ type: 'START_LOADING' })

  try {
    const resp = yield call(postOrders, decamelizeKeys(orders))
    const data = camelizeKeys(resp.data)
    yield put({ type: 'UPDATE_MULTI_BOOKING', payload: { data } })
    yield fetchMultiBookings()
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* getRoleOfUserWorker({ payload: generalReceptionId }) {
  try {
    const selectedGeneralReception = yield select(selectedGeneralReceptionSelectors())
    const userData = yield select(userDataSelectors())
    const isAdminCompanyOfGeneralReception =
      userData?.attributes?.is_warehouse_admin &&
      compareValue(selectedGeneralReception?.companyId, userData?.attributes.company_id)

    if (isAdminCompanyOfGeneralReception) {
      yield put({
        type: 'FETCH_SUCCESS',
        payload: { key: 'role', data: { adminCompany: true } },
      })
    } else {
      yield put({ type: 'START_LOADING' })
      const resp = yield call(
        getRoleOfGeneralReception,
        generalReceptionId || selectedGeneralReception?.id
      )
      let data = camelizeKeys(resp.data)
      if (typeof data === 'object') {
        data.adminGeneralReception = data?.admin
        delete data.admin
      }
      yield put({
        type: 'FETCH_SUCCESS',
        payload: { key: 'role', data },
      })
    }
  } catch (error) {
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchBookingMaster() {
  yield put({ type: 'START_LOADING' })
  const selectedGeneralReception = yield select(selectedGeneralReceptionSelectors())
  try {
    const resp = yield call(getBookingMaster, selectedGeneralReception?.id)

    yield put({
      type: 'FETCH_SUCCESS',
      payload: { key: 'bookingMaster', data: resp?.data },
    })
    const vehicleCategoryMap = resp.data.vehicle_categories.reduce(
      (ac, category) => ({ ...ac, [category.parameter_id]: category.parameter_name }),
      {}
    )
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { key: 'vehicleCategoryMap', data: vehicleCategoryMap },
    })
    const packageTypeMap = resp.data.package_types.reduce(
      (ac, packageType) => ({ ...ac, [packageType.parameter_id]: packageType.parameter_name }),
      {}
    )
    yield put({ type: 'FETCH_SUCCESS', payload: { key: 'packageTypeMap', data: packageTypeMap } })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchGeneralReceptions() {
  yield put({ type: 'START_LOADING' })

  const { generalReceptionId } = yield select((state) => state.get('multiBooking').filters)

  let resp

  try {
    resp = yield call(getGeneralReceptions)
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }

  const data = camelizeKeys(resp.data)

  yield put({
    type: 'FETCH_SUCCESS',
    payload: { key: 'generalReceptions', data: data.generalReception },
  })

  if (!generalReceptionId) {
    const localStorageGeneralReceptionId = localStorage.getItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID)
    const receptionFromLocal = _.find(data.generalReception, (reception) =>
      compareValue(reception.id, localStorageGeneralReceptionId)
    )
    const selectedReception = receptionFromLocal || _.first(data.generalReception)

    if (!receptionFromLocal) {
      localStorage.setItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID, selectedReception?.id || '')
    }

    yield put({
      type: 'CHANGE_MULTI_BOOKING_FILTER_GENERAL_RECEPTION_ID',
      payload: { generalReceptionId: selectedReception?.id },
    })
  }
}

function* receiveMultiBooking({ payload: { type, data } }) {
  const multiBooking = camelizeKeys(data)

  const { filters, multiBookings } = yield select((state) => state.get('multiBooking'))
  const { generalReceptionId, date, status } = filters

  // Upload csv success, fetch api to get the update list booking
  if (type === 'csv_upload_general_booking') {
    if ((multiBooking.generalReceptionId || []).includes(generalReceptionId)) {
      yield fetchMultiBookings()
    }
    return
  }

  // If booking generalReceptionId is diffrent with generalReceptionId current or planStartDate is diffrent: don't handle realtime
  if (multiBooking.generalReceptionId !== generalReceptionId) {
    return
  }

  if (moment(date).format(STANDARD_PLAN_DAY_FORMAT) !== multiBooking.planStartDate) {
    if (
      multiBooking.oldDate &&
      moment(date).format(STANDARD_PLAN_DAY_FORMAT) === multiBooking.oldDate
    )
      yield fetchMultiBookings()

    return
  }
  // ステータスが異なる場合は一覧を再取得して件数を更新する

  // If the status booking is suitable, fetch all bookings (althogh we have any action)
  if (
    // If the status of booking is delay or the status of filter(tab) is all: the condiction is alway true
    status !== multiBooking.generalBookingStatus ||
    [
      NotificationType.CHECKOUT_ALL_GENERAL_BOOKING,
      NotificationType.CONFIRM_ORDER_GENERAL_BOOKING,
      NotificationType.UPDATE_STATUS_OF_BOOKING,
      NotificationType.DELETE_BOOKING,
    ].includes(type) ||
    justCheckin(multiBooking.updatedDate, multiBooking.startDate)
  ) {
    yield fetchMultiBookings()
    return
  }

  // If the above condition is wrong, update it in local
  yield put({ type: 'UPDATE_MULTI_BOOKING', payload: { type, data: multiBooking } })
}

// Booking side panel
function* fetchInitialBookingWorker({ payload }) {
  yield put({ type: 'START_LOADING' })

  try {
    const { bookingId } = payload
    const resp = yield call(getInitialBooking, bookingId)

    // Format value after fetch
    const data = camelizeKeys(resp.data)
    const dataFormat = {
      ...data,
      planStartDate: data?.planStartDate ? formatDate(data?.planStartDate) : null,
      planEndDate: data?.planEndDate ? formatDate(data?.planEndDate) : null,
      needConfirmFlag: data?.needConfirmFlag === 0 ? true : false,
      attachFileObject: data?.attachFile ? convertAttachFile(data?.attachFile) : undefined,
    }

    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { initialBooking: dataFormat, draftBooking: dataFormat } },
    })
  } catch (error) {
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchGeneralBookingMasterWorker({ payload: { generalReceptionId } }) {
  try {
    const resp = yield call(getGeneralBookingMaster, generalReceptionId)
    const data = camelizeKeys(resp.data)
    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { masterData: data } },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* deleteGeneralBookingWorker({ payload }) {
  yield put({ type: 'START_LOADING' })
  try {
    const { bookingId } = payload
    const resp = yield call(deleteGeneralBooking, bookingId)
    const data = camelizeKeys(resp.data)
    yield put({
      type: 'UPDATE_MULTI_BOOKING',
      payload: { type: NotificationType.DELETE_GENERAL_BOOKING, data },
    })
    yield put({ type: 'END_LOADING' })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchRemoteCompaniesWorker({ payload: { generalReceptionId } }) {
  try {
    const resp = yield call(getRemoteCompanies, generalReceptionId)
    let data = camelizeKeys(resp.data)
    const userData = yield select(userDataSelectors())
    const bookingSidePanel = yield select(bookingSidePanelSelectors())
    const {
      companyId,
      companyName,
      driverCompanyId,
      driverCompanyName,
    } = bookingSidePanel?.initialBooking
    const { company_id: companyIdUser, company_name: companyNameUser } = userData?.attributes || {}

    let initialCompany = []
    if (!!companyIdUser)
      initialCompany.push({
        attributes: {
          companyName: companyNameUser,
        },
        type: 'company',
        id: companyIdUser,
      })
    if (!!companyId)
      initialCompany.push({
        attributes: {
          companyName: companyName,
        },
        type: 'company',
        id: companyId,
      })
    if (!!driverCompanyId)
      initialCompany.push({
        attributes: {
          companyName: driverCompanyName,
        },
        type: 'company',
        id: driverCompanyId,
      })

    data = [...data, ...initialCompany]

    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { remoteCompanies: data } },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchRemoteCompanyWorker({ payload: { generalReceptionId, companyId, type } }) {
  try {
    const userData = yield select(userDataSelectors())
    const userId = userData?.id
    const {
      companyId: companyIdUser,
      companyName,
      name,
      telNumber,
      cellPhone,
      email,
    } = camelizeKeys(userData?.attributes)

    const resp = yield call(getRemoteCompany, generalReceptionId, companyId, type)
    const data = camelizeKeys(resp.data)
    const remoteName = type === 'driver' ? 'remoteCompany' : 'remoteController'

    let isExistController = false
    if (type === 'vehicle_controller' && compareValue(companyIdUser, companyId)) {
      const isExistDriver = (data?.driversControllers?.data || []).some((driveController) =>
        compareValue(userId, driveController?.id)
      )
      if (!isExistDriver && Array.isArray(data?.driversControllers?.data)) {
        const user = {
          id: userId,
          type: 'user_filter',
          attributes: {
            id: userId,
            name,
            telNumber,
            cellPhone,
            email: email,
            companyId: companyIdUser,
            companyName,
          },
        }
        data.driversControllers.data.push(user)
        isExistController = true
      }
    }

    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { [remoteName]: data } },
    })
    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { isExistController } },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchRemoteDriverWorker({ payload: { driverName, driverPhone } }) {
  try {
    const resp = yield call(getRemoteDriver, driverName, driverPhone)
    const data = resp.data
      ? camelizeKeys({ ...resp.data, driver_id: `${resp.data.driver_id}` })
      : {}
    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { remoteDriver: data } },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  } finally {
    yield put({ type: 'COMPLETE_PROCESS' })
  }
}

function* fetchRemoteControllerByEmailWorker({ payload: { vehicleControllerEmail } }) {
  try {
    const resp = yield call(getRemoteControllerByEmail, vehicleControllerEmail)
    const data = camelizeKeys(resp.data) || {}
    yield put({
      type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
      payload: { data: { remoteControllerByEmail: data } },
    })
  } catch (error) {
    console.error(error)
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  } finally {
    yield put({ type: 'COMPLETE_PROCESS' })
  }
}

function* updateGeneralBookingWorker({ payload: { bookingId, data } }) {
  yield put({ type: 'START_LOADING' })

  try {
    const resp = yield call(updateGeneralBooking, bookingId, decamelizeKeys(data))
    yield put({ type: 'RESET_BOOKING_SIDE_PANEL_DATA', payload: {} })
    yield put({ type: 'UPDATE_GENERAL_BOOKING_SUCCESS', payload: resp.data })
  } catch (error) {
    const errorData = _.get(camelizeKeys(error), 'data.errors', [])
    if (_.isEmpty(errorData) || errorData.some((err) => !err.field)) {
      yield put({ type: 'FETCH_FAILURE', payload: {} })
      yield put({
        type: 'OPEN_ERROR',
        payload: { message: error.message || '通信エラーが発生しました' },
      })
    } else {
      const bookingError = errorData.reduce(
        (ac, error) => ({ ...ac, [camelize(error.field)]: error.message }),
        {}
      )
      yield put({
        type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
        payload: { data: { error: bookingError } },
      })
    }
  }
}

function* createGeneralBookingWorker({ payload: { data } }) {
  yield put({ type: 'START_LOADING' })

  try {
    const { date } = yield select((state) => state.get('multiBooking').filters)
    const generalBooking = yield call(createGeneralBooking, decamelizeKeys(data))

    yield put({ type: 'RESET_BOOKING_SIDE_PANEL_DATA', payload: {} })

    if (moment(date).format(STANDARD_PLAN_DAY_FORMAT) === generalBooking.plan_start_date) {
      yield put({ type: 'UPDATE_MULTI_BOOKING', payload: { data: generalBooking } })
    }
  } catch (error) {
    const errorData = _.get(camelizeKeys(error), 'data.errors', [])
    if (_.isEmpty(errorData) || errorData.some((err) => !err.field)) {
      yield put({ type: 'FETCH_FAILURE', payload: {} })
      yield put({
        type: 'OPEN_ERROR',
        payload: { message: error.message || '通信エラーが発生しました' },
      })
    } else {
      const bookingError = errorData.reduce(
        (ac, error) => ({ ...ac, [camelize(error.field)]: error.message }),
        {}
      )
      yield put({
        type: 'UPDATE_BOOKING_SIDE_PANEL_DATA',
        payload: { data: { error: bookingError } },
      })
    }
  }
}

function* uploadCsvWorker({ payload: { file, encodingType } }) {
  yield put({ type: 'START_LOADING' })
  const { generalReceptionId } = yield select(filtersSelectors())
  const generalReceptions = yield select(generalReceptionsSelectors())

  const generalReceptionSelected = generalReceptions.find(
    (reception) => reception.id === generalReceptionId
  )
  const { generalReceptionCsvId } = generalReceptionSelected

  try {
    yield call(uploadCsv, file, encodingType, generalReceptionCsvId)
    yield put({
      type: 'UPLOAD_SUCCESS',
    })
    yield put({
      type: 'OPEN_MODAL',
      payload: {
        content: <MessageUploadSuccess />,
        buttons: [
          {
            label: 'OK',
            color: 'primary',
          },
        ],
      },
    })
  } catch (error) {
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* putConfirmGeneralBookingsWorker({ payload: { generalBookingIds, callback } }) {
  yield put({ type: 'START_LOADING' })

  try {
    yield call(putConfirmGeneralBookings, generalBookingIds)
    yield put({ type: 'END_LOADING' })
    callback()
  } catch (error) {
    callback()
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* putCheckinGeneralBookingsWorker({ payload: { generalBookingId, callback } }) {
  yield put({ type: 'START_LOADING' })

  try {
    yield call(putCheckinGeneralBookings, generalBookingId)
    yield put({ type: 'END_LOADING' })
    callback()
  } catch (error) {
    callback()
    yield put({ type: 'FETCH_FAILURE', payload: { error } })
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

export default function* () {
  yield all([
    takeLatest('FETCH_MULTI_BOOKINGS', fetchMultiBookings),
    takeLatest('CHANGE_MULTI_BOOKING_FILTER_STATUS', fetchMultiBookings),
    takeLatest('CHANGE_MULTI_BOOKING_FILTER_DATE', fetchMultiBookings),
    takeLatest('CHANGE_MULTI_BOOKING_FILTER_GENERAL_RECEPTION_ID', fetchMultiBookings),
    takeLatest('FETCH_ORDERS', fetchOrders),
    takeLatest('FETCH_BOOKING_MASTER', fetchBookingMaster),
    takeLatest('FETCH_GENERAL_RECEPTIONS', fetchGeneralReceptions),
    takeLatest('RECEIVE_MULTI_BOOKING', receiveMultiBooking),
    takeLatest('FETCH_INITIAL_BOOKING', fetchInitialBookingWorker),
    takeLatest('FETCH_GENERAL_BOOKING_MASTER', fetchGeneralBookingMasterWorker),
    takeLatest('DELETE_GENERAL_BOOKING', deleteGeneralBookingWorker),
    takeLatest('FETCH_REMOTE_COMPANIES', fetchRemoteCompaniesWorker),
    takeEvery('FETCH_REMOTE_COMPANY', fetchRemoteCompanyWorker),
    takeLatest('FETCH_REMOTE_DRIVER', fetchRemoteDriverWorker),
    takeLatest('FETCH_REMOTE_CONTROLLER_BY_EMAIL', fetchRemoteControllerByEmailWorker),
    takeLatest('UPDATE_GENERAL_BOOKING', updateGeneralBookingWorker),
    takeLatest('CREATE_GENERAL_BOOKING', createGeneralBookingWorker),
    takeLatest('UPLOAD_CSV', uploadCsvWorker),
    takeLatest('PUT_CONFIRM_GENERAL_BOOKINGS', putConfirmGeneralBookingsWorker),
    takeLatest('PUT_CHECKIN_GENERAL_BOOKINGS', putCheckinGeneralBookingsWorker),
    takeLatest('UPDATE_ROLE_GENERAL_BOOKING', getRoleOfUserWorker),
    takeLatest('SEARCH_MULTIPLE_BOOKING_WITH_FILTER', fetchMultiBookings),
  ])
}
