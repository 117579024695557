import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import _ from 'lodash'
import { camelize } from 'humps'
import styled from 'styled-components'

import { fetchAnything, openError, startLoading, stopLoading } from 'containers/App/reducers'
import Checkbox from 'components/Checkbox'
import freezeR from 'utils/freezeR'
import { isSelectType } from 'services/Booking'
import { ADDITIONAL_COLUMNS_RANGE } from 'constants/ReceptionCustom'
import { trimData } from 'utils/commonFunctions'

import Header from '../components/Header'
import { updateBooking, clearAll, fetchImportExportParams } from '../reducers'
import { postCreateBooking, putUpdateBooking, patchUpdateBookingStatus } from '../api'
import PrevStartButton from '../components/PrevStartButton'
import { Input, EMPTY_OPTION } from '../components/utils'
import { buildBooking } from '../services'
import {
  HEADER_HEIGHT,
  TEN_DIGITS,
  STATUS_CHECKIN_TIME_NOT_FOUND,
  STATUS_CHECKIN_TIME_BOOKING_NOT_FOUND,
} from '../constants'
import PageNumber from '../components/PageNumber'
import theme from 'vars/theme'
import SelectBase from 'components/SelectOptions/SelectBase'
import AlertModal from 'components/AlertModal'

const RequiredText = styled.span`
  color: ${theme.redPrimary2};
  font-size: 24px;
  width: 60px;
  margin: 0 5px 0 -12px;
`

const CheckboxCustom = styled(Checkbox)`
  display: flex;
  align-items: center;
  height: 38px;

  & input {
    width: 200px;
    height: 38px;
  }

  & .check-icon {
    width: 38px;
    height: 38px;

    img {
      width: 24px !important;
      height: 24px !important;
      margin-top: 0 !important;
    }
  }

  span.text-primary {
    font-size: 32px;
    margin-left: 50px !important;
  }
`

export const LabelTenantParameter = styled.div`
  width: 100%;
  min-width: 120px;
  max-width: 145px;
  line-height: 26px;
  word-break: break-word;
`
export const EntryLoadPageContent = styled.div`
  display: flex;
  justify-content: center;
`

const FOOTER_HEIGHT = 70
const TITLE_HEIGHT = 120

const IMPORT_EXPORT_TYPES = freezeR([
  {
    id: 'import',
    label: '荷降ろし',
  },
  {
    id: 'export',
    label: '荷積み',
  },
])

const getCurrentValue = (booking, importOrExport, columnName) =>
  booking[
    camelize(importOrExport === 'import' ? columnName : columnName.replace(/^import/, 'export'))
  ] || ''

const determineSelectedValue = (options = [], value) =>
  options.find((option) => option.value == value)

const convertToOptions = (items = [], isAdditionalColumn = false, value) => {
  const propName = isAdditionalColumn ? 'parameterName' : 'parameterId'
  const options = items
    ?.filter((item) => (value && item[propName] == value) || item.displayFlag)
    ?.map((item) => ({
      value: item[propName],
      label: item.parameterName,
    }))

  return [EMPTY_OPTION].concat(options)
}

const validateRequiredItems = (parameters, booking) =>
  !parameters.some(
    ({ requiredFlag, columnName }) =>
      requiredFlag && !booking[camelize(columnName)]?.toString()?.trim()
  )

const convertImportExportColumns = (booking, additionalColumns) =>
  ADDITIONAL_COLUMNS_RANGE.reduce(
    (ac, id) => ({
      ...ac,
      [`importAdditionalColumn${id}`]: convertToOptions(
        additionalColumns[`additionalColumn${id}`],
        true,
        booking[`importAdditionalColumn${id}`]
      ),
      [`exportAdditionalColumn${id}`]: convertToOptions(
        additionalColumns[`additionalColumn${id}`],
        true,
        booking[`exportAdditionalColumn${id}`]
      ),
    }),
    {}
  )

const getAdditionalColumnsMaxLength = () =>
  ADDITIONAL_COLUMNS_RANGE.reduce(
    (ac, id) => ({
      ...ac,
      [`import_additional_column${id}`]: 200,
    }),
    {}
  )

class EntryLoadPage extends Component {
  state = {
    windowInnerHeight: window.innerHeight,
    isOpenModalSubmitError: false,
    messageSubmitError: '',
  }

  componentDidMount() {
    const { booking, match, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
      return
    }

    // this.props.fetchAnything({
    //   url: '/tablets/driver_confirmation',
    //   storePath: 'driverConfirmations',
    //   data: { tenantId },
    // })

    if (!booking.bookingNo) {
      // 積荷情報を適切にクリアする（残すべきは残す）
      // const newBooking = Object.keys(booking).reduce((ac, key) => {
      //   if (!key.match(/^(import|export)/)) {
      //     return ac
      //   }

      //   if (['importFlag', 'exportFlag'].includes(key)) {
      //     return {
      //       ...ac,
      //       [key]: 0,
      //     }
      //   }

      //   return {
      //     ...ac,
      //     [key]: '',
      //   }
      // }, booking)

      // this.props.updateBooking(newBooking)

      const isEdited = Object.keys(booking).some((key) => {
        if (['importFlag', 'exportFlag'].includes(key)) return false
        return key.match(/^(import|export)/) && booking[key]
      })

      if (!isEdited) {
        this.props.fetchImportExportParams(tenantId)
      }
    }

    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = (e) => {
    this.setState({ windowInnerHeight: e.target.innerHeight })
  }

  onChangeCheckbox = (item) => {
    const { importFlag, exportFlag } = this.props.booking
    this.props.updateBooking({
      [item.id === 'import' ? 'importFlag' : 'exportFlag']:
        (item.id === 'import' ? importFlag : exportFlag) === 1 ? 0 : 1,
    })
  }

  onChangeText = (e, importOrExport, columnName, maxLength) => {
    const { value } = e.target
    let newValue = value

    const cn = camelize(
      importOrExport === 'import' ? columnName : columnName.replace(/^import/, 'export')
    )

    if (['importGoodsAmount', 'exportGoodsAmount'].includes(cn)) {
      let num = value.replace(/[^0-9]/g, '')

      if (+num > TEN_DIGITS) return

      newValue = num ? Number(num) : ''
    } else {
      if (maxLength && newValue.length > maxLength) {
        newValue = newValue.substr(0, maxLength)
      }
    }

    this.props.updateBooking({ [cn]: newValue })
  }

  onClickRegister = async () => {
    const { history, match, booking, driver, currentTenant } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    let methodNotification

    const data = buildBooking(booking, warehouseId, tenantId, driver.driverPhone)

    if (data.repeatId) {
      data.applyType = 2
    }

    this.props.startLoading()

    try {
      const trimmedData = trimData(data)

      if (data.bookingNo) {
        const resp = await putUpdateBooking(trimmedData)
        const result = await patchUpdateBookingStatus({
          ...trimmedData,
          bookingId: resp?.data?.booking_id,
        })
        methodNotification = {
          lineFlag: result?.line_flag,
          portalFlag: result?.portal_flag,
          isCheckedPhone: driver?.phoneChecked,
        }
      } else {
        const resp = await postCreateBooking(trimmedData)
        methodNotification = {
          lineFlag: resp?.data?.line_flag,
          portalFlag: resp?.data?.portal_flag,
          isCheckedPhone: driver?.phoneChecked,
        }
      }
    } catch (error) {
      const { errors } = error?.data || {}
      const checkinTimeNotFound = errors?.find(
        (error) => error?.code === STATUS_CHECKIN_TIME_NOT_FOUND
      )
      const checkinTimeBookingNotFound = errors?.find(
        (error) => error?.code === STATUS_CHECKIN_TIME_BOOKING_NOT_FOUND
      )
      if (checkinTimeNotFound) {
        this.setState({ isOpenModalSubmitError: true })
        this.setState({ messageSubmitError: checkinTimeNotFound?.message })
        return
      }
      if (checkinTimeBookingNotFound) {
        this.setState({ isOpenModalSubmitError: true })
        this.setState({ messageSubmitError: checkinTimeBookingNotFound?.message })
        return
      }
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    this.props.clearAll()

    history.push({
      pathname: `/receipt-booking/entry-finish/${warehouseId}/${tenantId}`,
      state: {
        canUnmannedCheckin: currentTenant?.canUnmannedCheckin,
        methodNotification,
      },
    })
  }

  handleClickRedirectCheckinPage = () => {
    const { history, match } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
    this.setState({ isOpenModalSubmitError: false })
  }

  canClickNext = () => {
    const { tenantParameters, booking } = this.props
    const isImportExportEnable = tenantParameters?.base?.some(
      (item) => item.columnName === 'import_export_enable' && item.requiredFlag
    )

    if (isImportExportEnable && !booking.importFlag && !booking.exportFlag) {
      return false
    }
    const importOk =
      booking.importFlag === 1 ? validateRequiredItems(tenantParameters.import, booking) : true
    const exportOk =
      booking.exportFlag === 1 ? validateRequiredItems(tenantParameters.export, booking) : true
    return importOk && exportOk
  }

  render() {
    const {
      match,
      tenantParameters,
      booking,
      driver,
      packageTypes,
      additionalColumns,
      driverConfirmations,
      history,
    } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { windowInnerHeight, isOpenModalSubmitError, messageSubmitError } = this.state
    const importTenantParameters = _.chain(tenantParameters.import)
      .filter((item) => item.displayFlag)
      .sortBy((item) => item.displayOrder)
      .value()

    const optionMap = {
      importPackageType: convertToOptions(packageTypes, false, booking.importPackageType),
      exportPackageType: convertToOptions(packageTypes, false, booking.exportPackageType),
      ...convertImportExportColumns(booking, additionalColumns),
    }
    const maxLength = {
      import_goods_owner: 255,
      import_goods_name: 255,
      import_goods_amount: 10,
      import_main_goods: 50,
      import_delivery_slip_number: 50,
      import_contact_notes: 255,
      ...getAdditionalColumnsMaxLength(),
    }

    const isImportExportChecked = !!booking.importFlag || !!booking.exportFlag

    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header driver={driver} />

        <div
          className="pt-3 px-3"
          style={{ height: windowInnerHeight - HEADER_HEIGHT - FOOTER_HEIGHT, overflowY: 'auto' }}
        >
          <div className="bg-white px-3 pb-3" style={{ borderRadius: 12, minWidth: 1000 }}>
            <div className="text-center mb-3">
              <div className="text-size32 font-weight-bold">入庫/出庫情報を入力</div>
            </div>

            <EntryLoadPageContent
              style={{
                height: windowInnerHeight - HEADER_HEIGHT - TITLE_HEIGHT - FOOTER_HEIGHT,
                overflowY: isImportExportChecked ? 'auto' : 'hidden',
                minWidth: 922,
                overflowX: 'auto',
              }}
            >
              {IMPORT_EXPORT_TYPES.map((item) => (
                <div
                  key={item.id}
                  style={{
                    borderRight: item.id === 'import' ? '1px solid #BDBDBD' : 'none',
                  }}
                >
                  <div
                    className="p-4"
                    style={{
                      width: 460,
                      marginRight: -1,
                      borderRight: item.id === 'import' ? '1px solid #BDBDBD' : 'none',
                    }}
                  >
                    <CheckboxCustom
                      label={item.label}
                      className="mb-3"
                      checked={
                        (item.id === 'import' ? booking.importFlag : booking.exportFlag) === 1
                      }
                      onChange={() => {
                        this.onChangeCheckbox(item)
                      }}
                    />
                    <div
                      style={{
                        visibility:
                          (item.id === 'import' ? booking.importFlag : booking.exportFlag) === 1
                            ? 'visible'
                            : 'hidden',
                      }}
                    >
                      {importTenantParameters.map((tenantParameter) => (
                        <div
                          key={tenantParameter.id}
                          className="d-flex align-items-center mb-1 mt-1"
                          style={{ fontSize: '24px' }}
                        >
                          <RequiredText>{tenantParameter.requiredFlag && <>必須</>}</RequiredText>
                          <LabelTenantParameter>{tenantParameter.displayName}</LabelTenantParameter>
                          <div style={{ width: 260 }}>
                            {isSelectType(tenantParameter) ? (
                              <SelectBase
                                className="w-100 bg-white px-2"
                                style={{
                                  height: 40,
                                  borderRadius: 4,
                                  border: '1px solid #CCC',
                                  lineHeight: '1.1',
                                  color: '#000',
                                }}
                                value={
                                  (
                                    determineSelectedValue(
                                      optionMap[camelize(tenantParameter.columnName)] || [],
                                      getCurrentValue(booking, item.id, tenantParameter.columnName)
                                    ) || {}
                                  ).value
                                }
                                onChange={(e) => {
                                  this.onChangeText(e, item.id, tenantParameter.columnName)
                                }}
                              >
                                {optionMap[camelize(tenantParameter.columnName)].map((option) => (
                                  <option key={option.value} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                              </SelectBase>
                            ) : (
                              <Input
                                type="text"
                                className="w-100"
                                value={getCurrentValue(
                                  booking,
                                  item.id,
                                  tenantParameter.columnName
                                )}
                                onChange={(e) => {
                                  this.onChangeText(
                                    e,
                                    item.id,
                                    tenantParameter.columnName,
                                    maxLength[tenantParameter.columnName]
                                  )
                                }}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </EntryLoadPageContent>
          </div>

          <div
            className="d-flex justify-content-between p-3"
            style={{
              position: 'fixed',
              backgroundColor: '#ededed',
              left: 0,
              right: 0,
              bottom: 0,
              whiteSpace: 'nowrap',
            }}
          >
            <div style={{ width: 'calc(50% - 15px)' }}>
              <PrevStartButton to={`/receipt-booking/${warehouseId}/${tenantId}`} />
            </div>
            <div className="text-center" style={{ width: 30 }}>
              <PageNumber
                page="entry-load"
                existsDriverConfirmations={Boolean(driverConfirmations.length)}
                isFreePlan={!driver.tenant.canBerthBook}
              />
            </div>
            <div
              className="text-right"
              style={{ width: 'calc(50% - 15px)', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                outline
                color="primary"
                className="rounded-pill bg-white mr-3 px-md-4 px-sm-1 px-0 text-primary"
                onClick={() =>
                  history.push(`/receipt-booking/entry-driver-info/${warehouseId}/${tenantId}`)
                }
              >
                もどる
              </Button>
              {Boolean(driverConfirmations.length) &&
                (this.canClickNext() ? (
                  <Button
                    color="primary"
                    className="rounded-pill px-md-4 px-sm-1 px-0"
                    onClick={() =>
                      history.push(`/receipt-booking/entry-confirmation/${warehouseId}/${tenantId}`)
                    }
                  >
                    次へ
                  </Button>
                ) : (
                  <Button color="primary" className="rounded-pill px-md-4 px-sm-1 px-0" disabled>
                    次へ
                  </Button>
                ))}

              {!driverConfirmations.length && (
                <Button
                  color="primary"
                  className="rounded-pill px-md-4 px-sm-1 px-0"
                  disabled={!this.canClickNext()}
                  onClick={this.onClickRegister}
                >
                  登録完了
                </Button>
              )}
            </div>
          </div>
        </div>

        <AlertModal
          isOpen={isOpenModalSubmitError}
          title={messageSubmitError}
          textOk="OK"
          handleConfirm={this.handleClickRedirectCheckinPage}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  const additionalColumns = ADDITIONAL_COLUMNS_RANGE.reduce(
    (ac, id) => ({
      ...ac,
      [`additionalColumn${id}`]: _.get(reception, `master.additionalColumn${id}`, []),
    }),
    {}
  )

  return {
    tenantParameters: reception.master.tenantParameters,
    booking: reception.booking,
    driver: reception.driver,
    packageTypes: reception.master.packageTypes,
    driverConfirmations: reception.driverConfirmations,
    additionalColumns,
    currentTenant: reception?.driver?.tenant,
    returnEntryPage: reception.returnEntryPage,
  }
}

const mapDispatchToProps = {
  fetchAnything,
  openError,
  startLoading,
  stopLoading,
  updateBooking,
  clearAll,
  fetchImportExportParams,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryLoadPage)
