import _ from 'lodash'

export const SettingTargets = Object.freeze({
  // 1画面あたりのバース数
  BERTH_COLUMNS_PER_PAGE: 'berthColumnsPerPage',
  // 受付完了時の通知音
  RECEPTION_NOTIFICATION_SOUND: 'receptionNotificationSound',
})

// デフォルトかつ最小バース列数
export const BERTH_COLUMNS_PER_PAGE_MIN: number = 5
export const BERTH_COLUMNS_PER_PAGE_MAX: number = 99

export const RECEPTION_NOTIFICATION_SOUND_VOLUME_MIN = 1
export const RECEPTION_NOTIFICATION_SOUND_VOLUME_MAX = 100

export const ReceptionNotificationSoundTones: Array<any> = [
  {
    id: 'Bell',
    name: 'ベル',
  },
  // {
  //   id: 'ComeOn',
  //   name: 'カモン',
  // },
  {
    id: 'Harmonics',
    name: 'ハーモニクス',
  },
  {
    id: 'Ring',
    name: 'リング',
  },
]

export const defaultBerthColumnsPerPage = Object.freeze({
  use: false,
  size: BERTH_COLUMNS_PER_PAGE_MIN,
})

export const defaultReceptionNotificationSound = Object.freeze({
  enabled: false,
  generalReceptionEnabled: false,
  singleTenant: true,
  toneId: _.first(ReceptionNotificationSoundTones).id,
  volume: 0.5,
})

export const UNIT_TIME_1_HOUR_OPTIONS = [
  { label: '15分', value: 15 },
  { label: '30分', value: 30 },
  { label: '45分', value: 45 },
  { label: '60分', value: 60 },
  { label: '75分', value: 75 },
  { label: '90分', value: 90 },
]

export const UNIT_TIME_4_HOUR_OPTIONS = [
  { label: '30分', value: 30 },
  { label: '60分', value: 60 },
  { label: '90分', value: 90 },
  { label: '120分', value: 120 },
  { label: '150分', value: 150 },
  { label: '180分', value: 180 },
]

export const REACT_SELECT_ID_PORTAL = 'react-select-portal'
