import React from 'react'
import Contact from './Contact'

export default () => (
  <div className="mx-auto mb-3" style={{ width: 700 }}>
    <div className="text-center">
      「受付カスタマイズの内容を再度変更する場合は、下記へお問い合わせください。
    </div>
    <Contact />
  </div>
)
