import React, { FC } from 'react'

import { GeneralBooking, BookingError, attachment } from 'containers/IntegratedManagementPage/type'
import FileUpload from './FileUpload'
import validateAttachments from 'containers/HomePage/containers/SlideModal/validateAttachments'

type Props = {
  draftBooking: GeneralBooking
  error: BookingError
  onChange: (value: boolean | number[] | attachment[], name: string) => void
  handleChangeDraftBooking: (data: object) => void
}

const AttachFileForm: FC<Props> = ({ draftBooking, error, onChange, handleChangeDraftBooking }) => {
  const handleChangeFile = async (e: any) => {
    const { attachments } = draftBooking

    const files: attachment[] = Array.from(e.target.files)
    const result = validateAttachments(attachments || [], files)

    if (result) {
      alert(result)
      return
    }

    const fileWithContents = await Promise.all(
      files.map((file) => {
        return new Promise((resolve, reject) => {
          const { id, name, lastModified, size } = file
          const reader = new FileReader()
          reader.readAsDataURL(file)
          // TODO content を state に入れるのは抵抗ある
          reader.onload = () => resolve({ id, name, size, lastModified, content: reader.result })
          reader.onerror = (error) => reject(error)
        })
      })
    )

    // @ts-ignore
    await onChange((attachments || []).concat(fileWithContents), 'attachments')
    const el = document.querySelector('form.reservation-form')
    if (el) {
      // @ts-ignore
      el.scrollTop = el?.offsetTop + el?.scrollHeight
    }
  }

  const handleClickRemove = (name: string, attachmentId: number) => {
    const { attachments, deleteAttachmentIds = [] } = draftBooking

    // ローカル削除とリモート削除
    const newAttachments = attachments?.filter((attachment) => attachment.name !== name)

    // リモート削除の場合
    if (attachmentId) {
      deleteAttachmentIds.push(attachmentId)
    }

    handleChangeDraftBooking({
      attachments: newAttachments,
      deleteAttachmentIds,
    })
  }

  return (
    <React.Fragment>
      {/* 資料添付 */}
      <FileUpload
        attachments={draftBooking?.attachments || []}
        className="my-3"
        style={{
          paddingLeft: 16,
          paddingRight: 16,
        }}
        errorMessage={error?.attachments}
        onChange={handleChangeFile}
        onClickRemove={handleClickRemove}
      />
    </React.Fragment>
  )
}

export default AttachFileForm
