import RestClient from './RestClient'
import RestCheckinClient from './RestCheckinClient'
import RestPhoneBookingClient from './RestPhoneBookingClient'
import UsersServices from './UsersServices'
import HomeServices from './HomeServices'
import ReceptionServices from './ReceptionServices'
import WarehouseServices from './WarehouseServices'
import ConfirmBookingService from './ConfirmBookingService'
import SettingsService from './SettingsService'
import AuthService from './AuthService'
import AddManagerService from './AddManagerService'
import BillService from './BillService'
import ActualService from './ActualServices'
import PhoneBookingService from './PhoneBookingServices'
import ReceptionCustomService from './ReceptionCustomService'
import FileCustomServices from './FileServices'
import CardListServices from './CardListService'
import TenantServices from './TenantServices'

export const restClient = new RestClient()
export const restCheckinClient = new RestCheckinClient()
export const restPhoneBookingClient = new RestPhoneBookingClient()
export const usersServices = new UsersServices(restClient)
export const homeServices = new HomeServices(restClient)
export const receptionServices = new ReceptionServices(restCheckinClient)
export const warehouseServices = new WarehouseServices(restClient)
export const tenantServices = new TenantServices(restClient)
export const confirmBookingService = new ConfirmBookingService(restClient)
export const billService = new BillService(restClient)
export const settingsService = new SettingsService(restClient)
export const authService = new AuthService(restClient)
export const addManagerService = new AddManagerService(restClient)
export const actualServices = new ActualService(restClient)
export const fileCustomServices = new FileCustomServices(restClient)
export const cardListService = new CardListServices(restClient)
export const phoneBookingService = new PhoneBookingService(restPhoneBookingClient)
export const receptionCustomService = new ReceptionCustomService(restCheckinClient)
