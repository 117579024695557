import React, { Component, Fragment } from 'react'
import { Button } from 'reactstrap'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import loginStorage from 'utils/loginStorage'
import { fetchAnything, fetchCustomBerthLabel } from 'containers/App/reducers'
import DriverConfirmations from 'containers/Reception/components/DriverConfirmations'

import Tabs from '../components/Tabs'
import Header from '../components/Header'
import { changeWarehouse, changeTenant, fetchReceptionParameters } from '../reducers'
import { HEADER_HEIGHT } from '../constants'
import CanNotUse from '../components/CanNotUse'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import CanNotUpdate from '../components/CanNotUpdate'

class ConfirmationPage extends Component {
  componentDidMount() {
    const { tenantId, fetchReceptionParameters, history } = this.props
    const { tenantId: storageTenantId } = loginStorage.getWarehouseSession()
    if (!tenantId && !storageTenantId) {
      history.push('/reception-custom/items')
      return
    }
    fetchReceptionParameters()
    this.fetch(tenantId || storageTenantId)
  }

  fetch(tenantId) {
    const { fetchAnything, fetchCustomBerthLabel } = this.props
    fetchAnything({
      url: '/reception/confirmation',
      storePath: 'confirmations',
      data: { tenantId },
    })
    fetchCustomBerthLabel(tenantId)
  }

  onChangeWarehouse = (option) => {
    const { warehouses } = this.props

    const warehouseId = option.value
    const selectedWarehouse = warehouses.find((warehouse) => warehouse.id === warehouseId)
    const tenantId = _.first(selectedWarehouse.tenants)?.id

    this.props.changeWarehouse(warehouseId)
    this.props.changeTenant(tenantId)

    this.props.fetchReceptionParameters()
    this.fetch(tenantId)
  }

  onChangeTenant = (option) => {
    const tenantId = option?.value
    this.props.changeTenant(tenantId)
    this.fetch(tenantId)
  }

  renderEmpty(
    warehouseOptions,
    tenantOptions,
    warehouseId,
    tenantId,
    onChangeWarehouse,
    onChangeTenant
  ) {
    return (
      <div>
        <Header
          warehouses={warehouseOptions}
          tenants={tenantOptions}
          selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
          selectedTenant={tenantOptions.find((tenant) => tenant.value === tenantId)}
          onChangeWarehouse={onChangeWarehouse}
          onChangeTenant={onChangeTenant}
        />
        <CanNotUse />
      </div>
    )
  }

  render() {
    const { warehouseId, tenantId, warehouses, tenants, confirmations, userData } = this.props
    const warehouseOptions = warehouses.map((warehouse) => ({
      value: warehouse.id,
      label: warehouse.name,
    }))
    const tenantOptions = tenants.map((tenant) => ({
      value: tenant.id,
      label: tenant.name,
    }))

    const selectedTenant = tenants.find((t) => t.id === tenantId) || {}

    if (!selectedTenant.canCustomizeReception) {
      return this.renderEmpty(
        warehouseOptions,
        tenantOptions,
        warehouseId,
        tenantId,
        this.onChangeWarehouse,
        this.onChangeTenant
      )
    }

    const isSystemAdmin = userData.attributes.is_warehouse_admin
    const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

    return (
      <div>
        <Header
          warehouses={warehouseOptions}
          tenants={tenantOptions}
          selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
          selectedTenant={tenantOptions.find((t) => t.value === tenantId)}
          onChangeWarehouse={this.onChangeWarehouse}
          onChangeTenant={this.onChangeTenant}
        />

        <div
          className="p-3"
          style={{ height: window.innerHeight - HEADER_HEIGHT, overflowY: 'auto' }}
        >
          <div className="mx-auto" style={{ width: 852 }}>
            <div className="font-weight-bold mb-3">受付カスタマイズ</div>

            {!canUpdate && <CanNotUpdate />}

            <div className="border px-3 pb-3 mx-auto mb-3 bg-white">
              <Tabs currentTabId="confirmation" />

              {confirmations.filter((confirmation) => confirmation.displayFlag).length > 0 ? (
                <Fragment>
                  <div className="text-center mb-3">
                    受付時、ドライバーへ開示する内容は以下の通りです。
                  </div>

                  <div className="mb-3 p-3 border" style={{ borderRadius: 12 }}>
                    <DriverConfirmations confirmations={confirmations} />
                  </div>
                </Fragment>
              ) : (
                <div className="text-center mb-3">
                  <div>ドライバーへ開示する内容は設定されていません。</div>
                  <div>設定する場合は、「編集」ボタンから入力をお願いします。</div>
                </div>
              )}
            </div>

            {canUpdate && (
              <div className="text-right">
                <NavLink to="/reception-custom/confirmation-edit">
                  <Button color="primary" className="rounded-pill px-4">
                    編集
                  </Button>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const receptionCustom = state.get('receptionCustom')
  return {
    warehouseId: receptionCustom.warehouseId,
    tenantId: receptionCustom.tenantId,
    warehouses: receptionCustom.warehouses,
    confirmations: receptionCustom.confirmations,
    tenants: receptionCustom.tenants,
    userData: state.get('containers/App').get('userData').toJSON(),
  }
}

const mapDispatchToProps = {
  fetchAnything,
  changeWarehouse,
  changeTenant,
  fetchReceptionParameters,
  fetchCustomBerthLabel,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage)
