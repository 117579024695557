import { fromJS } from 'immutable'
import { handleActions, createActions } from 'redux-actions'
import _ from 'lodash'
import findIndex from 'lodash/findIndex'

import { ORDER, context, emptyModalOptions, CURRENT_PRE_PAGE } from './constants'
import loginStorage from 'utils/loginStorage'
import { FIRST_PAGE } from 'constants/misc'
import { compareValue } from 'utils/commonFunctions'
import { getNewDateUtcMoment } from './utils'

const { warehouseId, tenantId } = loginStorage.getWarehouseSession()

const initialState = fromJS({
  loading: false,
  error: null,
  role: {},
  warehouseId,
  tenantId,
  warehouseList: [],
  tenantList: [],
  order: ORDER.DEFAULT,
  orderBy: '',
  listCardReception: [],
  listCards: [],
  bookingMaster: {
    tenant_parameters: {
      base: [],
      import: [],
    },
  },
  selectedDay: {
    startDate: getNewDateUtcMoment(),
    endDate: getNewDateUtcMoment(),
  },
  pagination: {
    totalPages: 1,
    currentPage: FIRST_PAGE,
    totalElementsInPage: 0,
    perPageItem: CURRENT_PRE_PAGE,
  },
  roleTenant: {},
  isLoadingRoleTenant: false,
  currentDate: getNewDateUtcMoment(),
  modalOptions: {
    isOpen: false,
    content: '',
  },
  lastUpdateTime: getNewDateUtcMoment(),
})

export const {
  startLoading,
  endLoading,
  fetchSuccess,
  fetchFailed,
  updateRole,
  fetchWarehouseList,
  selectParameterFilterDate,
  changeWarehouseId,
  changeTenantId,
  resetAllTenantData,
  fetchListCardBookingWorker,
  updateOrder,
  updateOrderBy,
  fetchRoleTenant,
  setIsLoadingGetRoleTenant,
  fetchListCardBookingOtherWorker,
  updateBookingListCardStatus,
  updateBookingListCardStatusSuccess,
  openConfirmModalReload,
  closeConfirmModalReload,
  resetData,
  fetchCardBookingsPagination,
  changePerPageItem,
  updateLastUpdateTime,
} = createActions(
  {
    START_LOADING: _.noop,
    END_LOADING: _.noop,
    FETCH_SUCCESS: (data) => data,
    FETCH_FAILURE: (data) => data,
    UPDATE_ROLE: (data) => data,
    FETCH_WAREHOUSE_LIST: (callback: void) => ({
      callback,
    }),
    SELECT_PARAMETER_FILTER_DATE: (data) => data,
    CHANGE_WAREHOUSE_ID: (data) => data,
    CHANGE_TENANT_ID: (data) => data,
    RESET_ALL_TENANT_DATA: () => _.noop(),
    FETCH_LIST_CARD_BOOKING_WORKER: () => _.noop(),
    UPDATE_ORDER: (data) => data,
    UPDATE_ORDER_BY: (data) => data,
    FETCH_ROLE_TENANT: () => _.noop(),
    SET_IS_LOADING_GET_ROLE_TENANT: (loading) => loading,
    FETCH_LIST_CARD_BOOKING_OTHER_WORKER: () => _.noop(),
    UPDATE_BOOKING_LIST_CARD_STATUS: (data) => data,
    UPDATE_BOOKING_LIST_CARD_STATUS_SUCCESS: (data) => ({ data }),
    OPEN_CONFIRM_MODAL_RELOAD: (data) => data,
    CLOSE_CONFIRM_MODAL_RELOAD: (data) => data,
    RESET_DATA: () => _.noop(),
    FETCH_CARD_BOOKINGS_PAGINATION: (currentPage, perPageItem) => ({ currentPage, perPageItem }),
    CHANGE_PER_PAGE_ITEM: (PerPage) => PerPage,
    UPDATE_LAST_UPDATE_TIME: _.noop,
  },
  { prefix: context }
)

export const reducer = handleActions(
  {
    START_LOADING: (state) => state.set('loading', true),
    END_LOADING: (state) => state.set('loading', false),
    FETCH_SUCCESS: (state, { payload: { key, data } }) =>
      state.set('loading', false).set(key, fromJS(data)),
    FETCH_FAILURE: (state, { payload }) =>
      state.set('loading', false).set('error', fromJS(payload)),
    UPDATE_ROLE: (state, { payload }) => state.set('role', fromJS(payload)),
    SELECT_PARAMETER_FILTER_DATE: (state, { payload }) => state.set('selectedDay', fromJS(payload)),
    CHANGE_WAREHOUSE_ID: (state, { payload }) => {
      const { tenantId } = loginStorage.getWarehouseSession()
      loginStorage.setWarehouseSession({ warehouseId: payload, tenantId })
      return state.set('warehouseId', payload)
    },
    CHANGE_TENANT_ID: (state, { payload }) => {
      const { warehouseId } = loginStorage.getWarehouseSession()
      loginStorage.setWarehouseSession({ warehouseId, tenantId: payload })
      return state.set('tenantId', payload)
    },
    //TODO: update data reset
    RESET_ALL_TENANT_DATA: (state) =>
      state.set('tenantId', '').set('listCards', []).set('roleTenant', []),
    UPDATE_ORDER: (state, { payload }) => state.set('order', fromJS(payload)),
    UPDATE_ORDER_BY: (state, { payload }) => state.set('orderBy', fromJS(payload)),
    SET_IS_LOADING_GET_ROLE_TENANT: (state, { payload }) =>
      state.set('isLoadingRoleTenant', payload),
    UPDATE_BOOKING_LIST_CARD_STATUS_SUCCESS: (state, { payload: { data } }) => {
      const mapCondition =
        compareValue(data.tenant_id, state.get('tenantId')) &&
        compareValue(data.warehouse_id, state.get('warehouseId'))
      const isRepeatBooking = data?.repeat_id

      if (!mapCondition) return state

      const currentListCard = state.get('listCards').toJS()

      const indexOfBookingNeedUpdate = findIndex(currentListCard, (it: any) =>
        isRepeatBooking
          ? it.repeat_id === data.repeat_id &&
            it.start_date === data.start_date &&
            it.end_date === data.end_date
          : it.booking_id === data.booking_id
      )

      if (indexOfBookingNeedUpdate > -1) {
        return state.setIn(['listCards', indexOfBookingNeedUpdate], data).set('loading', false)
      }

      return state
    },
    OPEN_CONFIRM_MODAL_RELOAD: (state, { payload }) => {
      const { message } = payload
      const modalOptions = {
        isOpen: true,
        content: message,
      }
      return state.set('modalOptions', fromJS(modalOptions).set('loading', false))
    },
    CLOSE_CONFIRM_MODAL_RELOAD: (state) => {
      return state.set('modalOptions', fromJS(emptyModalOptions).set('loading', false))
    },
    RESET_DATA: (state) =>
      state
        .set('warehouseList', fromJS([]))
        .set('tenantList', fromJS([]))
        .set('listCardReception', fromJS([]))
        .set('listCards', fromJS([]))
        .set('roleTenant', fromJS([])),
    CHANGE_PER_PAGE_ITEM: (state, { payload }) =>
      state.setIn(['pagination', 'perPageItem'], payload),
    UPDATE_LAST_UPDATE_TIME: (state) => state.set('lastUpdateTime', getNewDateUtcMoment()),
  },
  initialState,
  { prefix: context }
)
