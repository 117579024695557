import { GeneralBookingStatuses } from 'containers/IntegratedManagementPage/constants'

export const context = 'containers/IntegratedReceptionPage'

export const INTEGRATED_RECEPTION_URL_BASE = '/general-reception-desk'

//Max length of EntryTelNumberPage
export const MAX_LENGTH_LEFT_INPUT = 3
export const MAX_LENGTH_CENTER_INPUT = 4
export const MAX_LENGTH_RIGHT_INPUT = 4

//Max length of EntryBookingNumberPage
export const MAX_LENGTH_BOOKING_NUMBER_LEFT_INPUT = 4
export const MAX_LENGTH_BOOKING_NUMBER_CENTER_INPUT = 4
export const MAX_LENGTH_BOOKING_NUMBER_RIGHT_INPUT = 1
export const MAX_LENGTH_VEHICLE_NUMBER = 4

export const MAX_LENGTH_PASS_CODE = 4

export const checkBookingCanCheckin = (status: string | number) =>
  [
    GeneralBookingStatuses.BOOKING.id,
    GeneralBookingStatuses.CONFIRM.id,
    GeneralBookingStatuses.DELAY.id,
  ].includes(status)

const FULL_SPACE = '　'
export const EMPTY_OPTION = Object.freeze({ value: '', label: FULL_SPACE })

export const filterNumber = (value: any) => value.replace(/[^0-9]/g, '')

export enum ButtonRedirectMode {
  CHECKIN = 'checkin',
  CHECKOUT = 'checkout',
}

export const ImportFlagCheck = {
  CHECK: 1,
  UNCHECK: 0,
}
