import React from 'react'
import { Button } from 'reactstrap'

import Checkbox from 'components/Checkbox'
import Switch from 'components/Switch'
import Icon from 'components/Icon'
import { ADDITIONAL_COLUMNS_RANGE } from 'constants/ReceptionCustom'
import { routes, typeRow } from '../constants'
import { ParameterItemType } from '../type'
import * as S from './styles'

const importAdditionalColumns = ADDITIONAL_COLUMNS_RANGE.reduce(
  (ac, id) => ({
    ...ac,
    [`import_additional_column${id}`]: `/reception-custom-general/options/additional-column${id}`,
  }),
  {}
)

const EditorUrls: { [key: string]: any } = Object.freeze({
  vehicle_category: routes.vehicleCategory,
  import_package_type: routes.importPackageType,
  ...importAdditionalColumns,
})

interface ReceptionItemRowProps {
  item: ParameterItemType
  type: string
  hideUpButton: boolean
  hideDownButton: boolean
  canUpdate: boolean
  onClickUp: (params: ParameterItemType) => void
  onClickDown: (params: ParameterItemType) => void
  onChangeRequired: (params: ParameterItemType) => void
  onChangeVisibility: (params: ParameterItemType) => void
  onClickEdit: (url: string, item: ParameterItemType) => void
}

export default ({
  item,
  type,
  hideUpButton,
  hideDownButton,
  onClickUp,
  onClickDown,
  onChangeRequired,
  onChangeVisibility,
  onClickEdit,
  canUpdate,
}: ReceptionItemRowProps) => {
  const editorUrl = EditorUrls[item.columnName]
  const canUpdateReceptionRow = type !== typeRow.BASE && canUpdate
  return (
    <S.Tr>
      <td className="pl-3">
        {item.displayName ? item.displayName : <S.TextDisable>未設定</S.TextDisable>}
      </td>
      <td className="text-center">
        {editorUrl && (
          <Button
            color="primary"
            className="rounded-pill"
            onClick={() => onClickEdit(editorUrl, item)}
          >
            項目編集
          </Button>
        )}
      </td>
      <td>
        {canUpdateReceptionRow && type !== typeRow.ONLY_CHECK_BOX && (
          <S.CheckBoxContent>
            {hideDownButton ? (
              <S.CustomButton disabled>
                <S.EmptyCircle />
              </S.CustomButton>
            ) : (
              <S.CustomButton
                onClick={() => {
                  onClickDown(item)
                }}
              >
                <Icon src="/assets/svgs/circle-down-arrow.svg" width={26} height={26} />
              </S.CustomButton>
            )}
            {hideUpButton ? (
              <S.CustomButton disabled>
                <S.EmptyCircle />
              </S.CustomButton>
            ) : (
              <S.CustomButton onClick={() => onClickUp(item)}>
                <Icon src="/assets/svgs/circle-up-arrow.svg" width={26} height={26} />
              </S.CustomButton>
            )}
          </S.CheckBoxContent>
        )}
      </td>
      <td className="text-center">
        <S.CheckBoxWrapper>
          <Checkbox
            checked={type === typeRow.BASE ? true : item.requiredFlag}
            disabled={
              type !== typeRow.ONLY_CHECK_BOX && (!canUpdateReceptionRow || !item.displayFlag)
            }
            isGray={type === typeRow.BASE}
            onChange={() => onChangeRequired(item)}
            label=""
            className=""
          />
        </S.CheckBoxWrapper>
      </td>
      <td className="d-flex justify-content-center">
        {type === typeRow.ONLY_CHECK_BOX ? (
          '-'
        ) : (
          <Switch
            checked={item.displayFlag}
            disabled={!canUpdateReceptionRow}
            onChange={() => onChangeVisibility(item)}
          />
        )}
      </td>
    </S.Tr>
  )
}
