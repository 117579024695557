import React from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  position: relative;
  width: 100px;
  height: 32px;

  & input {
    position: absolute;
    display: block;
    width: 100%;
    height: 32px;
    z-index: 1;
    opacity: 0;
  }

  & label {
    position: absolute;
    top: 3px;
    left: 0px;
    padding-left: 35px;
    padding-right: 0;
    border: 1px solid #ccc;
    border-radius: 54px;
    display: block;
    width: 92px;
    height: 26px;
    line-height: 24px;
    font-size: 15px;
  }

  & input:checked ~ label {
    padding-left: 20px;
    padding-right: 0;
    color: white;
    background-color: #247cdb;
  }

  .circle {
    background-color: white;
    border: 1px solid #ccc;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    transition: transform 0.25s ease-in-out;
  }

  & input:checked ~ .circle {
    transform: translateX(62px);
  }
`

type Props = {
  id?: string
  checked: boolean
  disabled?: boolean
  className?: string
  showCircle?: boolean
  onChange: () => void
}

const Switch: React.FC<Props> = ({
  id,
  checked,
  disabled,
  onChange,
  className,
  showCircle = true,
}) =>
  disabled ? (
    <span className="text-center" style={{ width: 100 }}>
      {checked ? '表示' : '非表示'}
    </span>
  ) : (
    <Outer className={className}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
        style={{ cursor: showCircle ? 'pointer' : 'default' }}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={id}>{checked ? '表示' : '非表示'}</label>
      {showCircle && <div className="circle" />}
    </Outer>
  )

export default Switch
