import { createActions, handleActions } from 'redux-actions'
import _ from 'lodash'
import { camelizeKeys } from 'humps'

import {
  GeneralBookingStatuses,
  NotificationType,
  BookingSidePanelType,
  FIRST_PAGE,
} from './constants'

import { sortGeneralBooking } from './constants'
import { FilterSearchMode } from 'components/FilterSearchBooking'

const initialState = {
  loading: false,
  isProcessing: false,
  error: {},
  generalReceptionTenants: [],
  multiBookings: [],
  // 編集中の順番
  draftOrders: [],
  // 複数箇所積みリスト取得時のフィルタ
  filters: {
    date: new Date(),
    status: GeneralBookingStatuses.CHECKIN.id,
    generalReceptionId: 0,
  },
  role: {
    adminCompany: false,
    adminGeneralReception: false,
    viewer: false,
    controller: false,
  },
  vehicleCategoryMap: {},
  packageTypeMap: {},
  totals: {
    totalNotOrder: 0,
    totalOrder: 0,
    totalConfirm: 0,
    totalBooking: 0,
    totalFinishAll: 0,
    totalAll: 0,
  },
  generalReceptions: [],
  pagination: {
    totalPages: 1,
    currentPage: FIRST_PAGE,
    totalElementsInPage: 0,
  },
  bookingSidePanel: {
    openType: BookingSidePanelType.NONE,
    bookingId: null,
    canDeleteBooking: false,
    initialBooking: {},
    draftBooking: {},
    masterData: {}, // 荷姿(packageTypes) & 車両形能(vehicleCategories)
    remoteCompanies: [], // 運送会社名
    remoteCompany: {}, // 1 運送会社名
    remoteController: {}, // 会社名
    remoteControllerByEmail: {}, // メールアドレス
    remoteDriver: {}, // ドライバー名
    error: {},
    isExistController: false,
  },
  filterSearchBooking: {
    predicate: '',
    filter: FilterSearchMode.BOTH,
  },
  bookingMaster: {
    tenant_parameters: {
      base: [],
      import: [],
    },
  },
}

const GeneralBookingStatusIdToKey = {
  [GeneralBookingStatuses.BOOKING.id]: 'totalBooking',
  [GeneralBookingStatuses.CONFIRM.id]: 'totalConfirm',
  [GeneralBookingStatuses.CHECKIN.id]: 'totalNotOrder',
  [GeneralBookingStatuses.ASSIGN.id]: 'totalOrder',
  [GeneralBookingStatuses.FINISH.id]: 'totalFinishAll',
  [GeneralBookingStatuses.ALL.id]: 'totalAll',
}

export const {
  assignOrders,
  changeMultiBookingFilterDate,
  changeMultiBookingFilterStatus,
  changeMultiBookingFilterGeneralReceptionId,
  fetchMultiBookings,
  fetchOrders,
  fetchBookingMaster,
  fetchGeneralReceptions,
  startLoading,
  endLoading,
  receiveMultiBooking,

  // Booking side panel
  fetchInitialBooking,
  fetchGeneralBookingMaster,
  deleteGeneralBooking,
  fetchRemoteCompanies,
  fetchRemoteCompany,
  fetchRemoteDriver,
  fetchRemoteControllerByEmail,
  updateGeneralBooking,
  createGeneralBooking,
  updateBookingSidePanelData,
  resetBookingSidePanelData,
  uploadCsv,
  putConfirmGeneralBookings,
  putCheckinGeneralBookings,
  updateRemoteDriverName,
  resetGeneralBookingData,
  updateRoleGeneralBooking,
  searchMultipleBookingWithFilter,
  updateFilterSearchGeneralBooking,
  clearFilterSearchGeneralBooking,
} = createActions({
  ASSIGN_ORDERS: (orders) => orders,
  CHANGE_MULTI_BOOKING_FILTER_DATE: ({ date, isClearFilterSearch, page }) => ({
    date,
    isClearFilterSearch,
    page,
  }),
  CHANGE_MULTI_BOOKING_FILTER_STATUS: ({ status, page }) => ({ status, page }),
  CHANGE_MULTI_BOOKING_FILTER_GENERAL_RECEPTION_ID: ({
    generalReceptionId,
    isClearFilterSearch,
    page,
  }) => ({
    generalReceptionId,
    isClearFilterSearch,
    page,
  }),
  FETCH_MULTI_BOOKINGS: (currentPage) => ({ currentPage }),
  FETCH_ORDERS: (orders) => ({ orders }),
  FETCH_BOOKING_MASTER: _.noop,
  FETCH_GENERAL_RECEPTIONS: _.noop,
  START_LOADING: _.noop,
  END_LOADING: _.noop,
  RECEIVE_MULTI_BOOKING: (multiBooking) => ({ multiBooking }),

  // Booking side panel
  FETCH_INITIAL_BOOKING: (bookingId) => ({ bookingId }),
  FETCH_GENERAL_BOOKING_MASTER: (generalReceptionId) => ({ generalReceptionId }),
  DELETE_GENERAL_BOOKING: (bookingId) => ({ bookingId }),
  FETCH_REMOTE_COMPANIES: (generalReceptionId) => ({ generalReceptionId }),
  FETCH_REMOTE_COMPANY: (generalReceptionId, companyId, type = 'driver') => ({
    generalReceptionId,
    companyId,
    type,
  }),
  FETCH_REMOTE_DRIVER: (driverName, driverPhone) => ({
    driverName,
    driverPhone,
  }),
  FETCH_REMOTE_CONTROLLER_BY_EMAIL: (vehicleControllerEmail) => ({
    vehicleControllerEmail,
  }),
  UPDATE_GENERAL_BOOKING: (bookingId, data) => ({ bookingId, data }),
  CREATE_GENERAL_BOOKING: (data) => ({ data }),
  UPDATE_BOOKING_SIDE_PANEL_DATA: (data) => ({ data }),
  RESET_BOOKING_SIDE_PANEL_DATA: _.noop,
  UPLOAD_CSV: ({ file, encodingType }) => ({ file, encodingType }),
  PUT_CONFIRM_GENERAL_BOOKINGS: (generalBookingIds, callback) => ({ generalBookingIds, callback }),
  PUT_CHECKIN_GENERAL_BOOKINGS: (generalBookingId, callback) => ({ generalBookingId, callback }),
  UPDATE_REMOTE_DRIVER_NAME: (name) => ({ name }),
  RESET_GENERAL_BOOKING_DATA: _.noop,
  UPDATE_ROLE_GENERAL_BOOKING: (data) => data,
  SEARCH_MULTIPLE_BOOKING_WITH_FILTER: (data, callback) => ({ data, callback }),
  UPDATE_FILTER_SEARCH_GENERAL_BOOKING: (data) => data,
  CLEAR_FILTER_SEARCH_GENERAL_BOOKING: _.noop,
})

export const reducer = handleActions(
  {
    ASSIGN_ORDERS: (state, { payload: { orders } }) => ({ ...state, draftOrders: orders }),
    FETCH_SUCCESS: (state, { payload: { key, data } }) => ({
      ...state,
      [key]: data,
      loading: false,
    }),
    FETCH_FAILURE: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
    UPLOAD_SUCCESS: (state) => ({
      ...state,
      loading: false,
    }),
    CHANGE_MULTI_BOOKING_FILTER_DATE: (state, { payload: { date } }) => {
      const filters = { ...state.filters, date }
      return { ...state, filters }
    },
    CHANGE_MULTI_BOOKING_FILTER_STATUS: (state, { payload: { status } }) => {
      const filters = { ...state.filters, status: status }
      return { ...state, filters }
    },
    CHANGE_MULTI_BOOKING_FILTER_GENERAL_RECEPTION_ID: (
      state,
      { payload: { generalReceptionId } }
    ) => {
      const filters = { ...state.filters, generalReceptionId }
      return { ...state, filters }
    },
    FETCH_MULTI_BOOKINGS: (state) => ({ ...state, loading: true }),
    FETCH_ORDERS: (state) => ({ ...state, loading: true }),
    UPDATE_MULTI_BOOKING: (state, { payload }) => {
      const { type, data } = payload
      const { status } = state.filters
      const totals = { ...state.totals }
      const multiBooking = camelizeKeys(data)

      const multiBookings = [...state.multiBookings]
      // Find the index of general booking is updated
      // If is equal -1: it is the new booking, and it has value >=0: it is the updated existed booking
      const index = multiBookings.findIndex(
        (booking) => booking.generalBookingId === multiBooking.generalBookingId
      )
      const isNewBooking = index === -1
      const isExistedBooking = index !== -1

      if (type === NotificationType.DELETE_GENERAL_BOOKING) {
        if (isExistedBooking) {
          multiBookings.splice(index, 1) // Remove general booking deleted
          const key = GeneralBookingStatusIdToKey[multiBooking.generalBookingStatus]
          totals[key] -= 1
          totals.totalAll -= 1
        }
      } else if (
        multiBooking.generalBookingStatus === GeneralBookingStatuses.BOOKING.id ||
        multiBooking.generalBookingStatus === GeneralBookingStatuses.CONFIRM.id
      ) {
        const key = GeneralBookingStatusIdToKey[multiBooking.generalBookingStatus]
        let multiBookingsUpdate
        if (isNewBooking) {
          totals[key] += 1
          totals.totalAll += 1
          multiBookingsUpdate = [...multiBookings, multiBooking]
        } else {
          multiBookings[index] = multiBooking
          multiBookingsUpdate = [...multiBookings]
        }
        return {
          ...state,
          multiBookings: sortGeneralBooking(multiBookingsUpdate),
          totals,
          loading: false,
        }
      } else if (isNewBooking) {
        // Handle realtime when create the booking
        // Because of the notification has different type when the booking is created or edited, we check by index of the booking
        let lastIndex = multiBookings.length
        if (multiBooking.generalBookingStatus === GeneralBookingStatuses.CHECKIN.id) {
          lastIndex = _.findLastIndex(
            multiBookings,
            (booking) => booking.generalBookingStatus === GeneralBookingStatuses.CHECKIN.id
          )
        }

        if (status === multiBooking.generalBookingStatus) {
          multiBookings.splice(lastIndex + 1, 0, multiBooking)
        }

        const key = GeneralBookingStatusIdToKey[multiBooking.generalBookingStatus]
        totals[key] += 1
        totals.totalAll += 1
      } else {
        // Handle realtime when edit the booking
        multiBookings[index] = multiBooking
      }

      return { ...state, multiBookings, totals, loading: false }
    },
    UPDATE_GENERAL_BOOKING_SUCCESS: (state, { payload }) => {
      const generalBooking = camelizeKeys(payload)
      const multiBookings = [...state.multiBookings]
      const index = multiBookings.findIndex(
        (booking) => booking.generalBookingId === generalBooking.generalBookingId
      )

      if (index === -1) return { ...state }

      multiBookings[index] = generalBooking

      return {
        ...state,
        multiBookings,
      }
    },
    FETCH_BOOKING_MASTER: (state) => ({ ...state, loading: true }),
    START_LOADING: (state) => ({ ...state, loading: true }),
    END_LOADING: (state) => ({ ...state, loading: false }),
    UPDATE_BOOKING_SIDE_PANEL_DATA: (state, { payload: { data } }) => ({
      ...state,
      loading: false,
      bookingSidePanel: {
        ...state.bookingSidePanel,
        ...data,
      },
    }),
    UPDATE_REMOTE_DRIVER_NAME: (state, { payload }) => ({
      ...state,
      bookingSidePanel: {
        ...state.bookingSidePanel,
        remoteDriver: {
          ...state.bookingSidePanel.remoteDriver,
          driverName: payload.name,
          isNewDriverName: true,
        },
      },
    }),
    RESET_BOOKING_SIDE_PANEL_DATA: (state) => ({
      ...state,
      loading: false,
      bookingSidePanel: {
        openType: BookingSidePanelType.NONE,
        bookingId: null,
        canDeleteBooking: false,
        initialBooking: {},
        draftBooking: {},
        masterData: {},
        remoteCompanies: [],
        remoteCompany: {},
        remoteController: {},
        remoteDriver: {},
        remoteControllerByEmail: {},
        error: {},
      },
    }),
    FETCH_REMOTE_CONTROLLER_BY_EMAIL: (state) => ({ ...state, isProcessing: true }),
    FETCH_REMOTE_DRIVER: (state) => ({ ...state, isProcessing: true }),
    COMPLETE_PROCESS: (state) => ({ ...state, isProcessing: false }),
    RESET_GENERAL_BOOKING_DATA: () => ({ ...initialState }),
    UPDATE_FILTER_SEARCH_GENERAL_BOOKING: (state, { payload }) => ({
      ...state,
      filterSearchBooking: { ...state.filterSearchBooking, ...payload },
    }),
    CLEAR_FILTER_SEARCH_GENERAL_BOOKING: (state) => ({
      ...state,
      filterSearchBooking: { predicate: '', filter: FilterSearchMode.BOTH },
    }),
  },
  initialState
)
