import moment from 'moment'

import RestClient, { getAuthHeader } from 'services/RestClient'
import { NUMBER_ITEM_IN_PER_PAGE, FIRST_PAGE } from './constants'

const client = new RestClient()
const config = {
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader(),
  },
}

const requestGet = (url, data) => client.get(url, data, config)
const requestDelete = (url, data, params) => client.delete({ url, params, data, config })

const requestPost = (url, data) =>
  client.post({
    url,
    data,
    config,
  })

const requestPut = (url, data) =>
  client.put({
    url,
    data,
    config,
  })

export const postGeneralBookingCheckin = (data) => requestPost('/general-booking/checkin', data)

export const getMultiBookings = (
  date,
  status,
  general_reception_id,
  page = FIRST_PAGE,
  per_page = NUMBER_ITEM_IN_PER_PAGE,
  keyword,
  filter
) =>
  requestGet('/general-booking/bookings', {
    booking_date: moment(date).format('YYYYMMDD'),
    status,
    general_reception_id,
    page,
    per_page,
    keyword,
    filter,
  })

export const getMultiBookingTenants = (general_reception_id) =>
  requestGet('/general-booking/reception-tenants', { general_reception_id })

export const postOrders = (data) => requestPost('/general-booking/order', data)

export const getBookingMaster = (general_reception_id) =>
  requestGet('/general-receptions/master-data', { general_reception_id })

export const getGeneralReceptions = () => requestGet('/general-receptions')

// Booking side panel
export const getInitialBooking = (general_booking_id) =>
  requestGet(`/general-booking/${general_booking_id}/detail`)

export const getGeneralBookingMaster = (general_reception_id) =>
  requestGet('/general-receptions/master-data', { general_reception_id })

export const deleteGeneralBooking = (general_booking_id) =>
  requestDelete(`/general-booking/delete/${general_booking_id}`)

export const getRemoteCompanies = (general_reception_id) =>
  requestGet('/general-receptions/admin/list-companies', { general_reception_id })

export const getRemoteCompany = (general_reception_id, company_id, type) =>
  requestGet('/general-receptions/company/detail', { general_reception_id, company_id, type })

export const getRemoteDriver = (driver_name, driver_phone) =>
  requestGet('/berths/bookings/find_driver', { driver_name, driver_phone })

export const getRemoteControllerByEmail = (vehicle_controller_email) =>
  requestGet('/berths/bookings/find_driver_controller', { vehicle_controller_email })

export const updateGeneralBooking = (general_booking_id, data) =>
  requestPut(`/general-booking/${general_booking_id}/edit`, data)

export const createGeneralBooking = (data) => requestPost('/general-booking/bookings', data)

export const getAttachFile = (general_booking_id) => {
  return client.get(
    '/general-booking/download_attach_file',
    {
      general_booking_id,
    },
    {
      headers: getAuthHeader(),
      'Content-Type': 'application/octet-stream',
      responseType: 'blob',
    }
  )
}

export const uploadCsv = (file, encodingType, generalReceptionCsvId) => {
  let formData = new FormData()
  formData.append('general_reception_csv_id', generalReceptionCsvId)
  formData.append('upload_file', file)
  formData.append('encoding_type', encodingType)

  return requestPost('/general-booking/csv/upload', formData)
}

export const putConfirmGeneralBookings = (general_booking_ids) =>
  requestPut('/general-booking/status/confirm', { general_booking_ids: general_booking_ids })

export const putCheckinGeneralBookings = (general_booking_id) =>
  requestPut('/general-booking/status/checkin', { general_booking_id })

export const getRoleOfGeneralReception = (general_reception_id) =>
  requestGet('/user-general-reception/roles', { general_reception_id })
