import Moment from 'moment'
import { extendMoment } from 'moment-range'
import toString from 'lodash/toString'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'
import filter from 'lodash/filter'
import { camelize } from 'humps'

import {
  STANDARD_TIME_FORMAT,
  API_DAY_FORMAT,
  STANDARD_DAY_FORMAT,
} from 'containers/HomePage/containers/BerthSchedule/constants'
import {
  PERMISSIONS,
  matchParam,
  matchExitParam,
  matchEntryParam,
  matchParamLogout,
  MAX_ITEM_SEARCH_HISTORY,
  FIRST_PAGE,
  matchParamNew,
  matchExitParamNew,
} from 'constants/misc'
import CryptoJS from 'crypto-js'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _last from 'lodash/last'
import { ATTACHMENT_ICONS, ATTACHMENT_PREVIEW_EXTENSIONS } from 'constants/Attachment'
import { ImportFlagCheck } from 'containers/IntegratedReception/constants'

const moment = extendMoment(Moment)
const END_MOMENT = '00:00:00'

export const handleFormatVehicleNumber = (val) => {
  if (!val) return ''
  const arr = val.split('')
  arr.reverse()
  arr.splice(2, 0, '-')
  arr.reverse()
  return arr.join('')
}

export const convertDataToEdit = (currentData, newData) => {
  const { end_time } = currentData
  const newEndTime = newData.end_time
  const newStartDate = newData.start_date
  const newEndDate = newData.end_date
  let newEndDateUpdate = newEndDate

  if (newEndTime === END_MOMENT && newStartDate === newEndDate && newEndTime !== end_time) {
    newEndDateUpdate = moment(newEndDateUpdate, API_DAY_FORMAT)
      .add(1, 'days')
      .format(API_DAY_FORMAT)
  } else if (
    end_time === END_MOMENT &&
    newEndTime !== END_MOMENT &&
    moment(newEndDateUpdate, API_DAY_FORMAT).isAfter(moment(newStartDate, API_DAY_FORMAT))
  ) {
    newEndDateUpdate = moment(newEndDateUpdate, API_DAY_FORMAT)
      .subtract(1, 'days')
      .format(API_DAY_FORMAT)
  }
  return { ...currentData, ...newData, end_date: newEndDateUpdate }
}

export const isUpdateNewBooking = (currentDay, Obj) => {
  const { end_date, start_date, start_time, end_time } = Obj
  const currentDayRange = moment.range(
    moment(currentDay, API_DAY_FORMAT).startOf('day'),
    moment(currentDay, API_DAY_FORMAT).endOf('day')
  )
  const bookingRange = moment.range(
    moment(`${start_date} ${start_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`),
    moment(`${end_date} ${end_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`)
  )
  return !currentDayRange.overlaps(bookingRange)
}

export const compareValue = (val1, val2) => toString(val1) === toString(val2)

export const trimData = (data) => {
  const newObject = { ...data }
  Object.keys(newObject).forEach((key) => {
    if (typeof newObject[key] === 'string') {
      newObject[key] = newObject[key].trim()
    }
  })
  return newObject
}

// common function to check 2 durations is overlaps or isSame (same start_time and same end_time)
export const isOverLapDurations = (startTime, endTime, otherStartTime, otherEndTime) => {
  const endTimeFormat = endTime === END_MOMENT ? '24:00:00' : endTime
  const otherEndTimeFormat = otherEndTime === END_MOMENT ? '24:00:00' : otherEndTime
  const range1 = moment.range(
    moment(startTime, STANDARD_TIME_FORMAT),
    moment(endTimeFormat, STANDARD_TIME_FORMAT)
  )
  const range2 = moment.range(
    moment(otherStartTime, STANDARD_TIME_FORMAT),
    moment(otherEndTimeFormat, STANDARD_TIME_FORMAT)
  )
  return range1.intersect(range2) || range1.isSame(range2)
}

export const isSameBookingPosition = (bookingA = {}, bookingB = {}) => {
  return (
    bookingA.start_date === bookingB.start_date &&
    bookingA.start_time === bookingB.start_time &&
    bookingA.end_date === bookingB.end_date &&
    bookingA.end_time === bookingB.end_time &&
    bookingA.berth_id?.toString() === bookingB.berth_id?.toString() &&
    bookingA.tenant_id?.toString() === bookingB.tenant_id?.toString()
  )
}

export const isContainDurations = (startTime, endTime, otherStartTime, otherEndTime) => {
  const endTimeFormat = endTime === END_MOMENT ? '24:00:00' : endTime
  const otherEndTimeFormat = otherEndTime === END_MOMENT ? '24:00:00' : otherEndTime
  return (
    !(moment(startTime, STANDARD_TIME_FORMAT) > moment(otherStartTime, STANDARD_TIME_FORMAT)) &&
    !(
      moment(endTimeFormat, STANDARD_TIME_FORMAT) < moment(otherEndTimeFormat, STANDARD_TIME_FORMAT)
    )
  )
}

export const formatBookingTime = (data) => {
  if (isEmpty(data)) return ''

  const { start_date, end_date, start_time, end_time } = data
  const formatStartDate = moment(start_date).format('MM/DD')
  const formatEndDate = moment(end_date).format('MM/DD')
  const formatStartTime = start_time ? start_time.slice(0, start_time.lastIndexOf(':')) : '未定'
  const formatEndTime = end_time ? end_time.slice(0, end_time.lastIndexOf(':')) : '未定'

  if (start_date !== end_date)
    return `${formatStartDate} ${formatStartTime} - ${formatEndDate} ${formatEndTime}`

  return `${formatStartDate} ${formatStartTime} - ${formatEndTime}`
}

export const formatBookingTimeSMS = (data) => {
  moment.locale('ja')
  let bookingTime
  let bookingFormattedTime
  if (Object.keys(data).length > 0) {
    const formatStartDate = moment(data.start_date).format('MM/DD')
    const month = formatStartDate.slice(0, formatStartDate.lastIndexOf('/'))
    const day = formatStartDate
      .slice(formatStartDate.lastIndexOf('/'), formatStartDate.lastIndexOf(''))
      .substring(1, 3)
    // 11月03日(水) 17:03
    const displayFomattedDate = `${month}<span style="font-size: 15px; font-weight: bold">月</span>${day}<span style="font-size: 15px; font-weight: bold">日 (${moment(
      data.start_date
    ).format('dd')})</span>`
    const displayDate = `${month}月${day}日 (${moment(data.start_date).format('dd')})`
    const formatEndDate = moment(data.end_date).format('MM/DD')
    const formatStartTime = data.start_time.slice(0, data.start_time.lastIndexOf(':'))
    const formatEndTime = data.end_time.slice(0, data.end_time.lastIndexOf(':'))
    if (data.start_date !== data.end_date) {
      bookingTime = `${formatStartDate} ${formatStartTime} - ${formatEndDate} ${formatEndTime}`
    } else {
      bookingTime = `${displayDate} ${formatStartTime} - ${formatEndTime}`
      bookingFormattedTime = `${displayFomattedDate} ${formatStartTime} - ${formatEndTime}`
    }
  }
  return [{ __html: bookingFormattedTime }, bookingTime]
}

export const formatPhone = (phone) => {
  function replacer(_, p1, p2, p3) {
    // p1 is nondigits, p2 digits, and p3 non-alphanumerics
    return [p1, p2, p3].join(' - ')
  }
  return phone.replace(/([^\d]*)(\d*)([^\w]*)/, replacer)
}

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  return re.test(String(email).toLowerCase())
}

// Check if string passed is all numeric characters
export const isStringNumber = (number) => /^\d+$/.test(number)

export const convertPhoneNumber = (val) => {
  if (isStringNumber(val) && val.length <= 11) {
    let value = val.trim()
    if (value.indexOf('81') === 0) value = value.replace('81', '0')
    return value
  } else if (!val) {
    return ''
  }
  return false
}

export const formatVehicleNumber = (number) => {
  const val = toString(number)
  if (val.length === 0) return ''
  return `${'0'.repeat(4 - val.length)}${val}`
}

// Check user Permissions
export const checkPermission = (permissons, compare) =>
  permissons.includes(PERMISSIONS.MANAGE) || permissons.includes(compare)

// Format phone number
export const formatPhoneNumber = (number) => {
  if (!number) {
    return number
  }
  const arr = number.split('').reverse()
  const resultArr = arr.reduce((result, word, index) => {
    if (index % 4 === 3 && index < arr.length - 1) {
      return result.concat([word, '-'])
    }

    return result.concat(word)
  }, [])
  return resultArr.reverse().join('')
}

export const decryptFunc = (itemName) => {
  const storedData = localStorage.getItem(itemName)
  try {
    const data1 = CryptoJS.AES.decrypt(storedData, `1${itemName}`)
    return JSON.parse(data1.toString(CryptoJS.enc.Utf8))
  } catch (err) {
    return null
  }
}

export const isBookingIn12hBefore = (booking) => {
  const { start_time, start_date, end_time, end_date } = booking
  const range = moment.range(
    moment(`${start_date} ${start_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`),
    moment(`${end_date} ${end_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`)
  )
  const m = moment().subtract('hours', 12)
  return m < range.center()
}

export const isDelegationForUser = (userId, company_id, delegation, usersSameGroup = []) => {
  const vehicle_controllers = _get(delegation, 'vehicle_controllers', [])
  const item = vehicle_controllers.find(
    (it) =>
      compareValue(it.vehicle_controller_id, userId) ||
      compareValue(it.company_id, company_id) ||
      usersSameGroup.some((userId) => compareValue(it.vehicle_controller_id, userId))
  )
  return !!item
}

export const checkUserCanViewBooking = (userData, booking, usersSameGroup = []) => {
  const companyId = _get(userData, 'attributes.company_id')
  const userId = _get(userData, 'id')

  return (
    (booking?.company_id && compareValue(companyId, booking?.company_id)) ||
    compareValue(booking?.created_by, userId) ||
    compareValue(booking?.vehicle_controller_id, userId) ||
    usersSameGroup.some(
      (userId) =>
        compareValue(booking?.created_by, userId) ||
        compareValue(booking?.vehicle_controller_id, userId)
    )
  )
}

export const getTenantId = () => {
  const { pathname } = document.location

  if (pathname.indexOf('general-reception') > -1) {
    const matches = pathname.match(/[0-9]+/g)
    return _last(matches)
  }

  let tenantId = ''
  if (matchParamLogout()) {
    tenantId = _get(matchParamLogout(), 'params.tenantId', '')
  } else if (matchParamNew() || matchParam()) {
    tenantId = _get(matchParamNew() || matchParam(), 'params.tenantId', '')
  } else {
    tenantId = _get(
      matchEntryParam(),
      'params.tenantId',
      _get(matchExitParamNew() || matchExitParam(), 'params.tenantId', '')
    )
  }
  return tenantId
}

export const getUniqBooking = ({
  booking_id,
  repeat_id,
  start_date,
  hasBookingDate,
  curerSelectDay,
}) => `${booking_id}${repeat_id}${hasBookingDate ? curerSelectDay : start_date}`

export const makeSnakeRange = (list) => {
  const sortedList = _sortBy(list, (it) => `${it.start_date}${it.start_time}`)
  return sortedList.reduce((initial, it, index) => {
    const delegationRange = moment.range(
      moment(`${it.start_date} ${it.start_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`),
      moment(`${it.end_date} ${it.end_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`)
    )
    if (index === 0) {
      initial.push(delegationRange)
      return initial
    }
    const lastRange = initial[initial.length - 1]
    if (lastRange.overlaps(delegationRange, { adjacent: true })) {
      initial.pop()
      initial.push(lastRange.add(delegationRange, { adjacent: true }))
      return initial
    }
    initial.push(delegationRange)
    return initial
  }, [])
}

export const isBookingBelongDelegation = (
  bookingItem,
  delegationList,
  usersSameGroup = [],
  userId
) => {
  const { start_time, start_date, end_time, end_date } = bookingItem
  const startMoment = moment(
    `${start_date} ${start_time}`,
    `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`
  )
  const endMoment = moment(`${end_date} ${end_time}`, `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`)
  const filteredList = delegationList.filter(
    (it) =>
      (isDelegationForUser(userId, bookingItem.company_id, it, usersSameGroup) ||
        isDelegationForUser(userId, bookingItem.created_user_company_id, it, usersSameGroup)) &&
      compareValue(bookingItem.berth_id, it.berth_id)
  )
  const item = makeSnakeRange(filteredList).find(
    (range) => startMoment.within(range) && endMoment.within(range)
  )
  return !!item
}

export const getLoginVehicleController = (userRole, userData, fullDataStructure = false) => {
  const isDriverController = userRole === 'driver_controller'
  const user = {
    id: isDriverController ? userData.id : null,
    type: 'user_filter',
    attributes: {
      id: isDriverController ? userData.id : null,
      name: isDriverController ? userData.attributes.name : null,
      tel_number: isDriverController ? userData.attributes.tel_number : null,
      cell_phone: isDriverController ? userData.attributes.cell_phone : null,
      email: isDriverController ? userData.attributes.email : null,
      company_id: isDriverController ? userData.attributes.company_id : null,
      company_name: isDriverController ? userData.attributes.company_name : null,
    },
  }
  return fullDataStructure ? user : user.attributes
}

export const findWarehouse = (warehouseList, warehouseId) => {
  const warehouseItem = warehouseList.find((it) => compareValue(it.id, warehouseId))
  return warehouseItem
}

export const findTenant = (warehouseList, warehouseId, tenantId) => {
  const { tenants } = findWarehouse(warehouseList, warehouseId)
  if (!tenants) return null
  const tenantItem = tenants.find((tenant) => compareValue(tenant.id, tenantId))
  return tenantItem
}

export const convertApiError = (apiErrors = []) =>
  apiErrors.reduce((res, item) => {
    res[camelize(item?.field)] = item?.message
    return res
  }, {})

export const getHistoryFromLocalStorageById = (keyLocalStorage, id) => {
  const dataLocalStorage = localStorage.getItem(keyLocalStorage)

  if (!dataLocalStorage) return []

  try {
    const allHistory = JSON.parse(dataLocalStorage)

    if (!isArray(allHistory)) return []

    const historyById = allHistory.find((history) => compareValue(history.id, id))

    if (!historyById) return []

    return historyById?.histories || []
  } catch (error) {
    return []
  }
}

export const setHistoryToLocalStorageById = (keyLocalStorage, id, value) => {
  const dataLocalStorage = localStorage.getItem(keyLocalStorage)

  if (!dataLocalStorage) {
    localStorage.setItem(
      keyLocalStorage,
      JSON.stringify([
        {
          id,
          histories: [value],
        },
      ])
    )
    return
  }

  try {
    const allHistory = JSON.parse(dataLocalStorage)

    if (!isArray(allHistory)) return

    const indexHistoryById = allHistory.findIndex((item) => compareValue(item.id, id))

    if (indexHistoryById >= 0) {
      const newHistories = uniq(
        filter([value, ...allHistory[indexHistoryById]?.histories], (history) => !!history.trim())
      )

      allHistory[indexHistoryById] = {
        id,
        histories: newHistories.slice(0, MAX_ITEM_SEARCH_HISTORY),
      }
    } else {
      allHistory.push({
        id,
        histories: [value],
      })
    }
    localStorage.setItem(keyLocalStorage, JSON.stringify(allHistory))
    return
  } catch (error) {
    return
  }
}

export const calculatePagination = (currentPage = FIRST_PAGE, totalCount, limitPerPage) => {
  const totalItems = totalCount
  let start = (currentPage - 1) * limitPerPage + 1
  let end = limitPerPage * currentPage < totalItems ? limitPerPage * currentPage : totalItems

  return { start, end, totalItems }
}

export const extractExtensionOfFileName = (name) => {
  const nodes = name.split('.')
  return nodes[nodes.length - 1].toLowerCase()
}

export const determineIconOfFileName = (name) => {
  const extension = extractExtensionOfFileName(name)
  return ATTACHMENT_ICONS[extension.toLowerCase()] || ''
}

export const canPreviewFileAttachment = (name = '') => {
  const extension = extractExtensionOfFileName(name)
  return ATTACHMENT_PREVIEW_EXTENSIONS.includes(extension)
}

export const isReloadBrowserWhenInactive = (warehouseId = 0) => {
  const WAREHOUSE_IDS = [5, 312, 418]

  return WAREHOUSE_IDS.includes(warehouseId)
}

export const getWorkingTimeOfBooking = (planStartDate, planStartTime, planEndDate, planEndTime) => {
  if (!planStartDate || !planStartTime || !planEndDate || !planEndTime) {
    return 0
  }
  try {
    const startDate = Moment(`${planStartDate} ${planStartTime}`)
    const endDate = Moment(`${planEndDate} ${planEndTime}`)
    const diffTime = endDate.diff(startDate, 'minutes')

    return diffTime > 0 ? `${diffTime}分` : 0
  } catch (e) {
    return 0
  }
}

// set 00:00:00 using moment current time
export const convertUsingMomentCurrentTime = (currentTime, endDate) => {
  if (!currentTime || !endDate) {
    return
  }
  const yesterday = Moment(currentTime)
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .subtract(1, 'days')
  const dayCompare = Moment(endDate, API_DAY_FORMAT).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  })
  return dayCompare.isSameOrAfter(yesterday)
}

export const getCoordinateEle = (el, scrollEl) => {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + (scrollEl ? scrollEl.scrollLeft : 0),
  }
}

export const getDataImportExport = (bookingMaster = {}, data) => {
  if (!data) return []
  if (data.import_flag === ImportFlagCheck.CHECK && data.export_flag === ImportFlagCheck.CHECK) {
    const formatDataColumnName = bookingMaster?.tenant_parameters?.import.map((item) => {
      const { column_name = '' } = item
      if (data[column_name]) {
        return {
          ...item,
        }
      }
      return {
        ...item,
        column_name: column_name.replace('import', 'export'),
      }
    })

    return formatDataColumnName || []
  }
  if (data.import_flag === ImportFlagCheck.UNCHECK && data.export_flag === ImportFlagCheck.CHECK) {
    return bookingMaster?.tenant_parameters?.export || []
  }
  if (data.import_flag === ImportFlagCheck.CHECK && data.export_flag === ImportFlagCheck.UNCHECK) {
    return bookingMaster?.tenant_parameters?.import || []
  }
  if (
    data.import_flag === ImportFlagCheck.UNCHECK &&
    data.export_flag === ImportFlagCheck.UNCHECK
  ) {
    return []
  }
}
