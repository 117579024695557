import { Modal, ModalFooter } from 'reactstrap'
import styled from 'styled-components'

import theme from 'vars/theme'

export const CustomModalFooter = styled(ModalFooter)`
  background: ${theme.darkBlueSoft1};
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 4px 4px;
`

export const ModalWrapper = styled(Modal)`
  max-width: 700px !important;
  .modal-content {
    width: 700px !important;
    width: 100%;
    border-radius: 12px;
  }

  .termOfUseWrapper {
    width: 50%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    height: 300px;
  }

  iframe {
    border: 1px solid ${theme.graySoft2};
    width: 100%;
    height: 100%;
  }
  @media screen and (max-height: 800px) {
    max-width: 650px !important;
    .termOfUseWrapper {
      height: 260px;
    }
    .modal-content {
      width: 650px !important;
      width: 100%;
      border-radius: 12px;
    }
  }
  @media screen and (max-height: 650px) {
    .termOfUseWrapper {
      height: 250px;
    }
  }
`

export const ConfirmTitle = styled.div`
  font-size: 24px;
  font-weight: lighter;
  margin-bottom: 10px;
  text-align: center;
`
