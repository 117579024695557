export const NOTI_TYPE = {
  CREATE_BOOKING: 'create_booking',
  UPDATE_BOOKING: 'update_booking',
  DELETE_BOOKING: 'delete_booking',
  CREATE_UNASSIGN: 'create_unassign',
  UPDATE_UNASSIGN: 'update_unassign',
  UPDATE_STATUS: 'update_status',
  CREATE_RECEPT: 'create_reception',
  UPDATE_RECEPT: 'update_reception',
  UPLOAD_CSV_GENERAL: 'csv_upload_general_booking',
  UPLOAD_CSV_BERTH: 'upload_csv_booking',
  PUBLIC_API_BOOKING: 'public_api_booking',
  DELETE_BOOKINGS: 'delete_bookings',
}
