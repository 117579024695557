import { createSelector } from 'reselect'
import { context } from './constants'
import { customBerthlabel } from 'constants/misc'
import loginStorage from 'utils/loginStorage'

const appSelectDomain = (state) => state.get(context)

export const userDataSelectors = () =>
  createSelector(appSelectDomain, (state) => state.get('userData').toJS())

export const receptionDataSelectors = () =>
  createSelector(appSelectDomain, (state) => state.get('receptionData').toJS())

export const userBookingPermissionSelectors = () =>
  createSelector(appSelectDomain, (state) => state.get('userBookingPermission').toJS())

export const appLoadingSelectors = () =>
  createSelector(appSelectDomain, (state) => state.get('loading'))

export const loadingLogoutSelectors = () =>
  createSelector(appSelectDomain, (state) => state.get('loadingLogout'))

export const baseSelector = () =>
  createSelector(appSelectDomain, (state) => state.get('base').toJS())

export const selectNotices = () =>
  createSelector(appSelectDomain, (state) => state.get('notices').toJSON())

export const selectDebugLogs = () =>
  createSelector(appSelectDomain, (state) => state.get('debugLogs').toJSON())

export const isOpenModalMaintainSelectors = () =>
  createSelector(appSelectDomain, (state) => state.get('isOpenModalMaintain'))

export const customBerthLabelSelectors = () => {
  return createSelector(appSelectDomain, (state) => {
    return (
      state.get('customBerthLabel') ||
      loginStorage.getCustomBerthLabel() ||
      customBerthlabel.LABEL_DEFAUT
    )
  })
}

export const bookingRemindStatusSelector = () =>
  createSelector(appSelectDomain, (state) => state.get('bookingRemindStatus'))
