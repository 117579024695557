import React from 'react'

const ColorMap = Object.freeze({
  red: '#DB2464',
  green: '#17BEBB',
  gray: '#A8A9AB',
  black: '#25272C',
  blue: '#247CDB',
})

const determineColor = (type) => {
  const [color, style] = type.split('-')
  return style === 'outline'
    ? {
        text: ColorMap[color] || color,
        border: `1px solid ${ColorMap[color] || color}`,
        backgroundColor: 'white', // none を指定すると色が残る不具合がある
      }
    : {
        text: 'white',
        border: 'none',
        backgroundColor: ColorMap[color] || color,
      }
}

export default ({ num = '', type = '', style = {} }) => {
  const colors = determineColor(type)
  return (
    <div
      style={{
        border: colors.border,
        borderRadius: '50%',
        width: 26,
        height: 26,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.backgroundColor,
        marginLeft: 'auto',
        marginRight: 'auto',
        ...style,
      }}
    >
      <div
        style={{
          fontSize: 14,
          fontWeight: 700,
          color: `${colors.text}`,
        }}
      >
        {num !== '' ? num : <span>&nbsp;</span>}
      </div>
    </div>
  )
}
