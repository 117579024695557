import React from 'react'
import styled from 'styled-components'
import { Modal } from 'reactstrap'
import Button from 'components/Button'

export const Wrapper = styled(Modal)`
  width: fit-content;

  .modal-content {
    margin-top: 200px;
    padding: 20px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    p {
      line-height: 1.2;
    }

    img {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .confirm--title {
      font-size: 18px;
      text-align: center;
    }

    label {
      cursor: pointer;
    }

    .button-group {
      button {
        margin-top: 20px;
      }
    }
  }
`
type Props = {
  isOpen: boolean
  companyName?: String
  vehicleControllerName?: String
  label?: String
  handleConfirm: () => void
  handleCancel: () => void
}

const ConfirmControllerModal: React.FC<Props> = ({
  isOpen,
  companyName = '',
  vehicleControllerName = '',
  label = '配車担当者名',
  handleConfirm,
  handleCancel,
}) => (
  <Wrapper isOpen={isOpen}>
    <h3 className="confirm--title">
      <p>以下の名称登録済みのメールアドレス。</p>
      <p>
        会社名：{companyName} <br />
        {label}: {vehicleControllerName}
      </p>
      <p>
        登録済みの名称で進めますか？
        <br />
        今回入力した名称に修正しますか?
      </p>
    </h3>
    <div className="d-flex justify-content-between button-group">
      <Button className="mr-4" color="red" onClick={handleConfirm}>
        登録済みの名称
      </Button>
      <Button color="blue" onClick={handleCancel}>
        今回入力の名称
      </Button>
    </div>
  </Wrapper>
)

export default ConfirmControllerModal
