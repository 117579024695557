import { createGlobalStyle, css } from 'styled-components'
import theme from 'vars/theme'
import '@atlaskit/css-reset' // eslint-disable-line
import './custom.scss'

export const scrollStyle = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${theme.graySoft1};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.darkBlueSoft1};
    border: 0;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.graySoft1};
  }
`

/* eslint no-unused-expressions: 0 */
export default createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    touch-action: manipulation;

    @media screen and (max-width: 1024px) {
      overflow-x: auto;
    }
  }

  body,
  pre,
  * {
    font-family: 'Noto Sans JP', Helvetica, 'Hiragino Kaku Gothic Pro', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', メイリオ, 'MS Pゴシック', 'ＭＳ ゴシック', 'YuGothic', Meiryo, sans-serif;
    line-height: 1.75;
  }

  #root {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  a {
    &:hover,
    &:focus {
      color: ${theme.blueMedium2};
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }
  }

  h1, h2, h3 {
    font-weight: bold;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .flex-auto {
    flex-grow: 0 !important;
    flex-shrink: 1 !important;
    flex-basis: auto !important;
  }

  /* Fontsize */
  h1,
  .font-xl {
    font-size: 32px;
    margin: 0;
  }

  h2,
  .font-lg {
    font-size: 19px;
    margin: 0;
  }
  h3,
  .font-normal {
    font-size: 16px;
    margin: 0;
  }

  .font-md {
    font-size: 14px;
  }

  .font-sm {
    font-size: 12px;
  }

  .form-control {
    height: calc(2rem + 2px);
  }

  .btn-none {
    background: transparent !important;
    border: 0 !important;
    vertical-align: unset;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .h-100vh {
    height: 100vh !important;
  }

  .h-100--vh {
    height: calc((var(--vh, 1vh) * 100));
  }

  .mono-container {
    min-width: ${theme.minWidth};
  }

  .px-20 {
    padding-left: 20vw !important;
    padding-right: 20vw !important;

    @media screen and (max-width: 1220px) {
      padding-left: 8rem !important;
      padding-right: 8rem !important;
    }
  }

  .label-flex {
    font-size: 14px;
    color: ${theme.darkBlueMedium1};
    flex: 0 0 140px;
    align-self: flex-start;
  }

  .input-flex {
    font-size: 14px;
    display: flex;
    align-items: center;
    flex: 6 0 200px;
    word-break: break-word;
  }

  /* Backgrounds */
  .bg-gray {
    background: ${theme.grayPrimary};
  }

  .bg-gray-soft1 {
    background: ${theme.graySoft1};
  }

  /* Colors */
  .color-red-med1 {
    color: ${theme.redMedium1};
  }

  .color-gray-med1 {
    color: ${theme.grayMedium1};
  }

  .color-blue-med1 {
    color: ${theme.blueMedium1};
  }

  /* Custom scrollbar */
  .custom-scrollbar {
    overflow-y: auto;
    overflow-x: hidden;

    ${scrollStyle}
  }

  .ReactModal__Overlay {
    z-index: 11;
  }

  .slide-pane__overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: -1px;
    border-width: 8px 8px 0;
    border-top-color: ${theme.whitePrimary};
  }

  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: -1px;
    border-width: 0 8px 8px;
    border-bottom-color: ${theme.whitePrimary};
    z-index: 15;
  }

  .tooltip.show {
    opacity: 1;
  }

  .modal {
    overflow: hidden !important;

    &.term-of-use_modal {
      overflow: auto !important;
    }
  }

  .modal-dialog {
    word-break: break-word;
  }

  .modal-base-style {
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 615px;
      touch-action: manipulation;
    }
  }

  .text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 240px;
  }

  /* Safari custom height for tablet */
  @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
      .main__safari {
        height: calc(100vh - 275px) !important;
      }
    }}

  .jss27.jss28.jss1.jss3.jss6.jss21.sc-ifAKCX.eEwiEh {
    span {
      display: none !important;
     }
  }

  @media (min-width: 768px) {
    .sc-gqjmRU {
      transform: translate(270px, -53px) !important;
    }
  }

  @media (min-width: 500px) {
    .sc-gqjmRU {
      transform: translate(80px, -53px) !important;
    }
  }

  @media (min-width: 902px) {
    .sc-gqjmRU {
      transform: translate(200px, -53px) !important;
    }
  }

  @media (min-width: 1024px) {
    .sc-gqjmRU {
      transform: translate(270px, -53px) !important;
    }
  }

  .mono-creatable__menu-notice--no-options {
    display: none;
  }

  .sortable_helper {
    z-index: 10;
    transform: scale(1.05);
    box-shadow: gray 4px 4px 2px -2px;
  }

  .can-select {
    user-select: all;
  }

  tr.can-hover:hover {
    background-color: rgba(37, 39, 44, 0.04);
  }

  .mono-select__single-value--is-disabled {
    color: ${theme.darkBlueMedium2};
    opacity: 0.5;
  }

  .text-size24 {
    font-size: 24px;
  }

  .text-size32 {
    font-size: 32px;
  }

  .p10 {
    padding: 10px;
  }

  .p20 {
    padding: 20px;
  }

  .p30 {
    padding: 30px;
  }

  .ph20 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ph30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .m12 {
    margin: 12px;
  }

  .m20 {
    margin: 20px;
  }

  .mr10 {
    margin-right: 10px;
  }

  .mr20 {
    margin-right: 20px;
  }

  .mv12 {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .mh20 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .button-hover:hover {
    box-shadow: 0px 2px 8px rgba(17, 32, 64, 0.25);
  }

  @keyframes toast-fadeinout {
    0%, 100% {
      opacity: 0
    }
    20%, 80% {
      opacity: 1
    }
  }
`
