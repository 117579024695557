import { createSelector } from 'reselect'
import { context } from './constants'

const multiSettingSelector = (state: any) => state.get(context)

export const loadingSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('loading'))

export const generalReceptionSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('generalReceptions'))

export const selectedGeneralReceptionSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('selectedGeneralReception'))

export const isLoadingGeneralSuccessSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('isLoadingGeneralSuccess'))
