import React from 'react'
import styled from 'styled-components'

// We must use the default border style of select (in the Chrome)
// (In the IOS version 15, select tag have removed default border style)
const SelectBaseStyle = styled.select`
  border: 1px solid rgb(125, 125, 125);
`

const SelectBase: React.FC<React.SelectHTMLAttributes<HTMLSelectElement>> = ({
  children,
  ...rest
}) => {
  return <SelectBaseStyle {...rest}>{children}</SelectBaseStyle>
}

export default SelectBase
