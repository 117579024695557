import styled from 'styled-components'
import theme from 'vars/theme'

export const Wrapper = styled.div`
  padding: 1rem;
  margin: 0 auto 1.5rem;
  border: 1px solid ${theme.grayPrimary2};
  background-color: ${theme.whitePrimary};
`

export const SwitchsWrapper = styled.div`
  overflow-y: auto;
`

export const SwitchsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 0.25rem;
`

export const TextTile = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
`

export const LabelInput = styled.div`
  margin-right: 1rem;
  width: 60px;
`

export const OptionPageContainer = styled.div`
  width: 600px;
  margin: 1rem auto 0;
`

export const Input = styled.input`
  border: 1px solid ${theme.grayPrimary2};
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 1.1;

  ${({ disabled }) => disabled && `background-color: ${theme.graySoft1}`}
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CustomButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
`
