import React, { memo, FC, ReactNode } from 'react'

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
  newTab?: boolean
  children?: ReactNode
}

const A: FC<Props> = ({ href, newTab, children, ...rest }) => {
  return (
    <a href={href} target={newTab ? '_blank' : undefined} rel="noopener noreferrer" {...rest}>
      {children}
    </a>
  )
}

export default memo(A)
