import React, { useMemo } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import _ from 'lodash'

import * as S from './styles'
import HeaderCommon from 'components/Layout/Header'
import StyledSelect from './StyledSelect'
import {
  generalReceptionsSelectors,
  generalReceptionSelectedSelectors,
} from 'containers/IntegratedReceptionCustom/selectors'
import {
  updateGeneralReceptionSelected,
  clearConfirmations,
} from 'containers/IntegratedReceptionCustom/reducer'
import { LOCAL_STORAGE_GENERAL_RECEPTION_ID } from 'containers/IntegratedReceptionCustom/constants'
import {
  GeneralReceptionType,
  GeneralReceptionOptionType,
} from 'containers/IntegratedReceptionCustom/type'

interface HeaderProps {
  generalReceptions: GeneralReceptionType[]
  generalSelected: GeneralReceptionType
  updateGeneralReceptionSelected: (params: any) => void
  clearConfirmations: () => void
}

const Header: React.FC<HeaderProps> = ({
  generalReceptions,
  generalSelected,
  updateGeneralReceptionSelected,
  clearConfirmations,
}) => {
  const generalReceptionsOption = useMemo<GeneralReceptionOptionType[]>(() => {
    if (!_.isArray(generalReceptions)) return []

    return generalReceptions.map((reception) => ({
      value: reception.id,
      label: reception.name,
      ...reception,
    }))
  }, [generalReceptions])

  const selectedOption = generalReceptionsOption.find(
    (option) => option.value === generalSelected?.id
  )

  const onChangeReception = (value: any) => {
    updateGeneralReceptionSelected(value)
    localStorage.setItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID, value?.id || '')
    clearConfirmations()
  }

  return (
    <HeaderCommon>
      <S.HeaderWrapper>
        <StyledSelect
          width="180px"
          options={generalReceptionsOption}
          onChange={onChangeReception}
          value={selectedOption!}
        />
      </S.HeaderWrapper>
    </HeaderCommon>
  )
}

const mapStateToProps = createStructuredSelector({
  generalReceptions: generalReceptionsSelectors(),
  generalSelected: generalReceptionSelectedSelectors(),
})

const mapDispatchToProps = {
  updateGeneralReceptionSelected,
  clearConfirmations,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
