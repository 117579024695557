import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import Button from 'components/Button'
import GeneralReceptionList, { Props as ListProps } from './GeneralReceptionList'

import * as S from '../style'

const SideBar: React.FC<ListProps & { hasAdminCompanyPermission: boolean }> = memo(
  ({ idActive, generalReceptions, hasAdminCompanyPermission, onChange }) => {
    const history = useHistory()

    return (
      <aside className="aside d-flex flex-column">
        <div className="border-bottom p-3 d-flex flex-between bg-gray-soft1">
          <h3>総合受付</h3>
          <Button
            size="sm"
            color="blue"
            onClick={() => {
              history.push(`/multi-setting/create`)
            }}
            disabled={!hasAdminCompanyPermission}
          >
            総合受付追加
          </Button>
        </div>
        <S.WarehousesListWrapper className="custom-scrollbar">
          {!isEmpty(generalReceptions) ? (
            <GeneralReceptionList
              idActive={idActive}
              generalReceptions={generalReceptions}
              onChange={onChange}
            />
          ) : (
            <div className="h-100 flex-center color-gray-med1 font-md">
              総合受付データがありません。
            </div>
          )}
        </S.WarehousesListWrapper>
      </aside>
    )
  }
)

export default SideBar
