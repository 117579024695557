import { takeLatest, select, put, call } from 'redux-saga/effects'
import { camelizeKeys } from 'humps'
import _ from 'lodash'
import moment from 'moment'

import { getReception, requestGet } from './api'
import { BaseTenantParameterDisplayOrders } from 'constants/ReceptionCustom'
import loginStorage from 'utils/loginStorage'
import { receptionCustomService } from 'services'
import { TAB_MODE, getInitialValueForTabMode } from './constants'

function* fetchReceptionParameters({ payload: tabMode }) {
  const { warehouseId, tenantId } = loginStorage.getWarehouseSession()
  const { warehouses } = yield select((state) => state.get('receptionCustom'))
  yield put({ type: 'base/START_LOADING' })
  let firstWarehouse
  let resp

  if (!warehouses.length) {
    try {
      resp = yield call(requestGet, '/berths/load_warehouses', {
        bookingDate: moment().format('YYYYMMDD'),
      })
    } catch (error) {
      yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
      return
    }

    const camelizedWarehouses = camelizeKeys(resp.data)
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { storePath: 'warehouses', data: camelizedWarehouses },
    })

    firstWarehouse = _.first(camelizedWarehouses)
  }

  yield put({ type: 'CHANGE_WAREHOUSE', payload: warehouseId || firstWarehouse.id })

  try {
    resp = yield call(requestGet, '/warehouse', { id: warehouseId || firstWarehouse.id })
  } catch (error) {
    yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
    return
  }

  const tenants = camelizeKeys(resp.data.warehouse.data.attributes.tenants)
  yield put({ type: 'FETCH_SUCCESS', payload: { storePath: 'tenants', data: tenants } })
  const firstTenant = _.first(tenants)
  yield put({ type: 'CHANGE_TENANT', payload: tenantId || firstTenant.id })

  if (
    tabMode === TAB_MODE.WAITING_SETTING ||
    tabMode === TAB_MODE.TIME_SETTING ||
    tabMode === TAB_MODE.COUNT_TRUCK_SETTING
  ) {
    try {
      let response = yield call([receptionCustomService, 'getWaitingSetting'], {
        tenantId: tenantId || firstTenant.id,
        warehouseId: warehouseId || firstWarehouse.id,
      })
      const data = response.data ? camelizeKeys(response.data) : {}

      if (!_.isEmpty(data)) {
        const { timeMin, timeMax, timeAverage } = data
        if (timeMin) data.timeMin = timeMin.toString()
        if (timeMax) data.timeMax = timeMax.toString()
        if (timeAverage) data.timeAverage = timeAverage.toString()
      }

      const initValue = getInitialValueForTabMode(tabMode)
      const waitingSetting = { ...initValue, ...data }

      yield put({
        type: 'FETCH_SUCCESS',
        payload: { storePath: 'waitingSetting', data: waitingSetting },
      })
      yield put({
        type: 'FETCH_SUCCESS',
        payload: { storePath: 'initialWaitingSetting', data: waitingSetting },
      })
    } catch (error) {
      yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
      return
    }
  } else {
    try {
      resp = yield call(getReception, tenantId || firstTenant.id)
    } catch (error) {
      yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
      return
    }

    const data = camelizeKeys(resp.data)
    const baseParameters = _.chain(data.baseParameter)
      .map((parameter) => ({
        ...parameter,
        displayOrder: BaseTenantParameterDisplayOrders[parameter.columnName],
      }))
      .sortBy(data.importParameter, (parameter) => parameter.displayOrder)
      .value()
    const parameters = _.sortBy(data.importParameter, (parameter) => parameter.displayOrder)
    const exportParameters = _.sortBy(data.exportParameter, (parameter) => parameter.displayOrder)
    const baseAndImportParameters = baseParameters.concat(parameters)

    yield put({
      type: 'FETCH_SUCCESS',
      payload: {
        storePath: 'baseParameters',
        data: baseParameters,
      },
    })
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { storePath: 'parameters', data: baseAndImportParameters },
    })
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { storePath: 'exportParameters', data: exportParameters },
    })
    yield put({
      type: 'FETCH_SUCCESS',
      payload: { storePath: 'initialParameters', data: [...baseAndImportParameters] },
    })
  }

  yield put({ type: 'base/STOP_LOADING' })
}

export default function* () {
  yield takeLatest('FETCH_RECEPTION_PARAMETERS', fetchReceptionParameters)
}
