import arrowDown from 'assets/svgs/arrow-down.svg'

export const downArrowStyle = () => `
  content: url(${arrowDown});
  width: auto;
  height: auto;
  padding-left: 5px;
  top: 5px;
  border: 0;
`
