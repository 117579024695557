import React from 'react'

import BookingDetail from './BookingDetail'
import Button from './Button'
import { formatDriverTelNumber, formatCarNumber } from '../utils'
import Icon from 'components/Icon'
import * as S from '../style'

export default ({
  top,
  width,
  booking,
  onClickClose,
  packageTypeMap,
  vehicleCategoryMap,
  bookingMaster,
}) => {
  return (
    <S.BookingDetailWrapper width={width} top={top}>
      <S.BookingDetailTop>
        <S.BookingDriverName>
          <S.BookingDriverContent>
            <span className="ml-2 mr-2">
              <Icon
                src="/assets/svgs/multi-booking/human.svg"
                style={{ verticalAlign: 'baseline' }}
                width={15}
                height={15}
              />
            </span>
            <S.DriverInformationWrapper>
              {booking.driverName || ''}
              {booking.driverTel && `(${formatDriverTelNumber(booking.driverTel)})`}
              &nbsp;{booking.driverEmail}
            </S.DriverInformationWrapper>
          </S.BookingDriverContent>
        </S.BookingDriverName>

        <S.BookingVehicleNumber>
          <S.BookingDriverContent>
            <span className="ml-2 mr-2">
              <Icon
                src="/assets/svgs/multi-booking/company-name.svg"
                style={{ verticalAlign: 'center' }}
                width={15}
                height={15}
              />
            </span>
            <S.TextLabel>{booking.driverCompanyName || ''}</S.TextLabel>
            <span className="ml-1">{formatCarNumber(booking.vehicleNumber)}</span>
          </S.BookingDriverContent>
        </S.BookingVehicleNumber>

        <S.BookingVehicleCategory>
          <S.BookingDriverContent>
            <span className="ml-2 mr-2">
              <Icon
                src="/assets/svgs/multi-booking/truck.svg"
                style={{ verticalAlign: 'center' }}
                width={24}
                height={15}
              />
            </span>
            {booking.vehicleCategory &&
              vehicleCategoryMap &&
              typeof vehicleCategoryMap[booking.vehicleCategory] === 'string' && (
                <S.TextVehicleCategory>
                  {vehicleCategoryMap[booking.vehicleCategory] || ''}
                </S.TextVehicleCategory>
              )}
          </S.BookingDriverContent>
        </S.BookingVehicleCategory>

        <S.WrapperDetailClose>
          <Button label="とじる" type="blue-fill" onClick={onClickClose} style={{ height: 32 }} />
        </S.WrapperDetailClose>
      </S.BookingDetailTop>

      <BookingDetail
        booking={booking}
        packageTypeMap={packageTypeMap}
        style={{ maxHeight: 230, overflowY: 'auto' }}
        bookingMaster={bookingMaster}
      />
    </S.BookingDetailWrapper>
  )
}
