import React from 'react'
import styled from 'styled-components'
import { Modal, ModalProps } from 'reactstrap'
import { Button } from 'reactstrap'

import theme from 'vars/theme'

const Wrapper = styled(Modal)`
  width: ${({ width }) => `${width}px`};
  .modal-content {
    margin-top: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`

const Title = styled.span`
  font-size: 20px;
  margin: 20px 0;
  text-align: center;
  white-space: pre-wrap;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`

const ButtonCustom = styled(Button)<{ hasSmallBorder: boolean }>`
  min-width: 180px;
  margin-top: 20px;
  border-radius: ${({ hasSmallBorder }) => (hasSmallBorder ? '5px' : '20px')};
  font-size: 20px;
`

const ButtonNoCustom = styled(Button)<{ hasSmallBorder: boolean }>`
  min-width: 180px;
  margin-top: 20px;
  border-radius: ${({ hasSmallBorder }) => (hasSmallBorder ? '5px' : '20px')};
  background: white;
  color: ${theme.blueDefault} !important;
  border-color: ${theme.blueDefault} !important;
  font-size: 20px;

  :hover,
  :focus,
  :active {
    color: ${theme.blueMedium8} !important;
    border-color: ${theme.blueMedium8} !important;
    background: ${theme.blueSoft8} !important;
  }
`

type Props = ModalProps & {
  title: string
  textClose: string
  textOk: string
  width?: number
  isOpen: boolean
  hasSettingUnitTime?: boolean
  handleConfirm: () => void
  handleCancel: () => void
}

const ConfirmDriverNameModal: React.FC<Props> = ({
  isOpen,
  textClose,
  textOk,
  handleConfirm,
  handleCancel,
  title,
  width = 500,
  hasSettingUnitTime,
  ...rest
}) => (
  <Wrapper width={width} isOpen={isOpen} {...rest}>
    <Title dangerouslySetInnerHTML={{ __html: title }} />
    <ButtonGroup>
      <ButtonNoCustom color="primary" onClick={handleCancel} hasSmallBorder={hasSettingUnitTime}>
        {textClose}
      </ButtonNoCustom>
      <ButtonCustom color="primary" onClick={handleConfirm} hasSmallBorder={hasSettingUnitTime}>
        {textOk}
      </ButtonCustom>
    </ButtonGroup>
  </Wrapper>
)

export default ConfirmDriverNameModal
