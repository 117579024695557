import React, { useMemo, useState } from 'react'

import SearchForm from 'components/SearchForm'
import CustomTable from 'components/CustomTable'
import Button from 'components/Button'

import * as S from '../style'
import { NOTIFICATION_METHOD, TableHeaderManagerGeneralReception } from '../constants'
import useAddAdmin from '../hooks/useAddAdmin'

type Props = {
  hasAdminCompanyPermission: boolean
  handleCancel: () => void
}

const AddAdminPage: React.FC<Props> = ({ hasAdminCompanyPermission, handleCancel }) => {
  const [searchingValue, setSearchingValue] = useState<string>('')
  const {
    loading,
    managerGeneral,
    keyword,
    handleGetManageRoleGeneral,
    setKeyword,
    handleUpdateManageRoleGeneral,
  } = useAddAdmin()

  const listManager = useMemo(() => {
    const { userGeneralReception } = managerGeneral
    return userGeneralReception.map((user) => {
      const color = !!user.admin ? 'red' : 'blue'
      const text = !!user.admin ? '削除' : '追加'
      return {
        id: user.userId,
        line: user.notificationMethod === NOTIFICATION_METHOD.LINE ? 'なし' : '',
        ButtonCustom: () => (
          <Button
            color={color}
            width="88px"
            style={{ fontSize: '12px' }}
            onClick={() => handleUpdateManageRoleGeneral(user)}
            disabled={!hasAdminCompanyPermission || loading}
          >
            {text}
          </Button>
        ),
        ...user,
      }
    })
  }, [handleUpdateManageRoleGeneral, hasAdminCompanyPermission, loading, managerGeneral])

  return (
    <S.Wrapper>
      <div className="px-5 mb-5">
        <h2 className="py-3 text-center">管理者追加</h2>
        <SearchForm
          value={searchingValue}
          haveButton
          buttonLabel="サーチ"
          className="w-100"
          placeholder="従業員名を検索"
          disabled={loading}
          onSearch={(value: string) => {
            setSearchingValue(value)
          }}
          handleSearch={() => {
            setKeyword(searchingValue)
            handleGetManageRoleGeneral(searchingValue)
          }}
        />
      </div>
      <CustomTable
        data={listManager}
        columns={TableHeaderManagerGeneralReception}
        meta={{
          current: managerGeneral.page,
          total: managerGeneral.totalPages,
        }}
        onNavigateRequest={(page: number) => {
          if (keyword !== searchingValue) {
            setSearchingValue(keyword)
          }
          handleGetManageRoleGeneral(keyword, page)
        }}
      />
      <S.BottomWrapper>
        <Button
          type="submit"
          color="blue"
          width="124px"
          context="secondary"
          style={{ fontSize: '12px' }}
          onClick={handleCancel}
        >
          戻る
        </Button>
      </S.BottomWrapper>
    </S.Wrapper>
  )
}

export default React.memo(AddAdminPage)
