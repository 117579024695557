import { decamelizeKeys } from 'humps'
import moment from 'moment'
import { BookingStatuses } from 'constants/misc'
import RestCheckinClient, { getCheckinAuthHeader } from 'services/RestCheckinClient'

const client = new RestCheckinClient()
const buildConfig = () => ({
  headers: {
    'Content-Type': 'application/json',
    ...getCheckinAuthHeader(),
  },
})

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
}

export const requestGet = (url, data, useAuth = true) =>
  client.get(url, decamelizeKeys(data), useAuth ? buildConfig() : DEFAULT_CONFIG)

export const requestPost = (url, data, useAuth = true) =>
  client.post({
    url,
    data: decamelizeKeys(data),
    config: useAuth ? buildConfig() : DEFAULT_CONFIG,
  })

export const requestPut = (url, data, useAuth = true) =>
  client.put({
    url,
    data: decamelizeKeys(data),
    config: useAuth ? buildConfig() : DEFAULT_CONFIG,
  })

export const requestPatch = (url, data, useAuth = true) =>
  client.patch({
    url,
    data: decamelizeKeys(data),
    config: useAuth ? buildConfig() : DEFAULT_CONFIG,
  })

export const postCreateBooking = (booking) =>
  requestPost('/tablets/reception_booking', decamelizeKeys(booking))

export const putUpdateBooking = (booking) => {
  const startDatetime = `${moment(booking.planStartDate, 'YYYYMMDD').format('YYYY-MM-DD')} ${
    booking.planStartTime
  }`
  const endDatetime = `${moment(booking.planEndDate, 'YYYYMMDD').format('YYYY-MM-DD')} ${
    booking.planEndTime
  }`
  const data = {
    ...booking,
    startDatetime,
    endDatetime,
    shortenTimeFlag: 1,
  }

  return requestPut('/tablets/reception_booking', decamelizeKeys(data))
}

export const patchUpdateBookingStatus = (booking) => {
  const startDatetime = `${moment(booking.planStartDate, 'YYYYMMDD').format('YYYY-MM-DD')} ${
    booking.planStartTime
  }`
  const endDatetime = `${moment(booking.planEndDate, 'YYYYMMDD').format('YYYY-MM-DD')} ${
    booking.planEndTime
  }`
  const status =
    booking.status === BookingStatuses.Unassigned.id
      ? BookingStatuses.Recept.id
      : BookingStatuses.Arrival.id

  return requestPatch(
    '/tablets/update_status_reception',
    decamelizeKeys({
      bookingId: booking?.bookingId,
      isTablet: true,
      startDatetime,
      endDatetime,
      status,
    })
  )
}
