import { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { useDispatch } from 'reduxStoreHelper'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { validateYup } from 'utils/validators'
import { REGEX } from 'constants/misc'
import { compareValue, convertApiError } from 'utils/commonFunctions'
import { addTenantSchema } from '../validation'
import {
  getWarehousesByGeneralReceptionId,
  getTenantsByWarehouseId,
  getBerthsByWarehouseIdAnsTenantId,
  postGeneralReceptionTenant,
} from '../api'
import { FIRST_PAGE, PER_PAGE } from '../constants'

export const useAddTenant = () => {
  const [formData, setFormData] = useState({
    warehouseId: '',
    tenantId: '',
    tenantName: '',
    viewOrder: '',
  })
  const [warehouses, setWarehouses] = useState<any[]>([])
  const [tenants, setTenants] = useState<any[]>([])
  const [berth, setBerth] = useState<any>({
    currentPage: FIRST_PAGE,
    totalPage: 0,
    data: [],
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>({})

  const dispatch = useDispatch()
  const history = useHistory()
  const params: { generalReceptionId: string } = useParams()

  const handleOnChange = useCallback(
    (name: string) => (value: string) => {
      if (name === 'viewOrder' && !REGEX.VIEW_ORDER.test(value)) return

      setFormData((prev) => ({ ...prev, [name]: value }))
    },
    []
  )

  useEffect(() => {
    ;(async () => {
      if (!params.generalReceptionId) return
      setLoading(true)
      try {
        const res = await getWarehousesByGeneralReceptionId(+params.generalReceptionId)
        const warehousesRes: { id?: number }[] = _.sortBy(res.data, (warehouse) =>
          moment(warehouse.createdAt).unix()
        )
        setWarehouses(warehousesRes)
        handleOnChange('warehouseId')(`${_.first(warehousesRes)?.id || ''}`)
      } catch (e) {}
    })()
  }, [handleOnChange, params.generalReceptionId])

  useEffect(() => {
    ;(async () => {
      if (!formData.warehouseId) return
      try {
        handleOnChange('tenantId')('')
        setTenants([])
        setBerth({
          currentPage: FIRST_PAGE,
          totalPage: 0,
          data: [],
        })
        const res = await getTenantsByWarehouseId(+formData.warehouseId)
        const tenantsRes: { id?: number }[] = _.sortBy(res.data, (tenant) =>
          moment(tenant.createdAt).unix()
        )
        setTenants(tenantsRes)
        handleOnChange('tenantId')(`${_.first(tenantsRes)?.id || ''}`)
      } catch (e) {}
    })()
  }, [formData.warehouseId, handleOnChange])

  useEffect(() => {
    ;(async () => {
      if (!formData.warehouseId || !formData.tenantId) return
      try {
        setLoading(true)
        const res = await getBerthsByWarehouseIdAnsTenantId({
          warehouseId: formData.warehouseId,
          tenantId: formData.tenantId,
          page: berth.currentPage,
          perPage: PER_PAGE,
        })

        // @ts-ignore
        const formatBerth = res?.data?.map((it: any) => ({
          id: it?.id,
          screenDisplay: it?.attributes?.screenDisplay,
          name: it?.attributes?.name,
          autoCall: it?.attributes?.autoCall ? 'あり' : 'なし',
          camera: it?.attributes?.cameraUrl?.length,
          note: it?.attributes?.berthExpansion?.data?.attributes?.note,
        }))
        setBerth({
          // @ts-ignore
          totalPage: res?.meta?.totalPages,
          currentPage: berth.currentPage,
          data: formatBerth,
        })
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.tenantId, berth.currentPage])

  const handleOnPageBerthChange = useCallback(
    (page: number) => setBerth((prev: any) => ({ ...prev, currentPage: page })),
    []
  )

  const selectedTenant = useMemo(
    () => _.find(tenants, (it) => compareValue(it.id, formData.tenantId)) || {},
    [tenants, formData.tenantId]
  )

  const handleCancel = useCallback(() => {
    history.push('/multi-setting')
  }, [history])

  const handleSubmit = useCallback(async () => {
    try {
      const { yupData, errors, isError } = validateYup(addTenantSchema, formData)
      if (isError) {
        setError(errors)
        return
      }

      await postGeneralReceptionTenant({
        generalReceptionId: +params.generalReceptionId,
        ...yupData,
      })

      dispatch(
        showFlashMessage({
          message: '総合受付にテナント追加が成功しました。',
        })
      )

      history.push('/multi-setting')
    } catch (e) {
      const hasUnmannedCheckinError = _.get(
        convertApiError(e?.data?.errors),
        'canUnmannedCheckin',
        ''
      )
      if (hasUnmannedCheckinError) {
        dispatch(
          showFlashMessage({
            message: hasUnmannedCheckinError,
            isError: true,
          })
        )
      }
      setError(convertApiError(e.data.errors))
    }
  }, [dispatch, formData, history, params.generalReceptionId])

  return {
    warehouses,
    tenants,
    berth,
    selectedTenant,
    formData,
    error,
    handleOnChange,
    handleOnPageBerthChange,
    handleCancel,
    handleSubmit,
    loading,
  }
}
