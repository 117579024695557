import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'

import Header from 'components/Layout/Header'
import { NoticeStorage } from '../App/watchNotice'
import { UPDATE_NOTICES } from 'containers/App/constants'
import Icon from 'components/Icon'
import { selectNotices } from 'containers/App/selectors'
import { NoticeFilters } from 'constants/NoticeFilters'

const CATEGORY_FILTER_IDS = [
  NoticeFilters.CRITICAL.id,
  NoticeFilters.NORMAL.id,
  NoticeFilters.MAINTENANCE.id,
]

const ALL_FILTER_IDS = CATEGORY_FILTER_IDS.concat(NoticeFilters.UNREAD.id, NoticeFilters.ALL.id)

const filterNotices = (notices, filter) => {
  if (filter === NoticeFilters.ALL.id) {
    return notices
  }

  if (filter === NoticeFilters.UNREAD.id) {
    return notices.filter((notice) => !notice.read)
  }

  return notices.filter((notice) => notice.category === filter && !notice.read)
}

const renderFilterCount = (filterId, notices) => {
  if (CATEGORY_FILTER_IDS.includes(filterId)) {
    return notices.filter((notice) => notice.category === filterId && !notice.read).length
  }

  if (filterId === NoticeFilters.UNREAD.id) {
    return notices.filter((notice) => !notice.read).length
  }

  return notices.length
}

const CategoryLabel = styled.div`
  color: white;
  font-weight: bold;
  padding: 5px 15px;
  text-align: center;
  width: 160px;
  font-size: 14px;
  line-height: 1.25;
`

const determineColors = (filterId, selectedFilterId, notices) => {
  const isSelected = selectedFilterId === filterId

  if (filterId === NoticeFilters.CRITICAL.id) {
    const count = notices.filter(
      (notice) => notice.category === NoticeFilters.CRITICAL.id && !notice.read
    ).length
    if (count) {
      return {
        color: 'white',
        backgroundColor: '#DB2464',
      }
    }
  }

  return isSelected
    ? {
        color: '#212529',
        backgroundColor: 'white',
      }
    : {
        color: '#212529',
        backgroundColor: '#EAEAEA',
      }
}

class NoticePage extends Component {
  state = {
    filter: NoticeFilters.UNREAD.id,
    selectedNotice: null,
    detailHtml: '',
  }

  constructor(props) {
    super(props)

    const { location } = props

    if (location.pathname.endsWith('critical')) {
      this.state.filter = NoticeFilters.CRITICAL.id
    }
  }

  handleClickFilter = (filter) => {
    this.setState({ filter, selectedNotice: null })
  }

  handleClickTitle = async (selectedNotice) => {
    const { notices } = this.props

    let resp
    try {
      resp = await axios.get(selectedNotice.url, null, {
        headers: {
          'Content-Type': 'text/html',
        },
      })
    } catch (e) {
      console.log(e)
      return
    }

    const readIds = NoticeStorage.addReadId(selectedNotice.id)
    const newNotices = notices.map((notice) => ({
      ...notice,
      read: readIds[notice.id] || false,
    }))

    this.props.updateNotices({ notices: newNotices })
    this.setState({ selectedNotice, detailHtml: resp.data })
  }

  handleClickClose = () => {
    this.setState({ selectedNotice: null })
  }

  renderList() {
    const { notices } = this.props
    const { filter } = this.state
    const filteredNotices = filterNotices(notices, filter)

    return filteredNotices.length ? (
      <table>
        <colgroup>
          <col style={{ width: 24 }} />
          <col style={{ width: 180 }} />
          <col style={{ width: 180 }} />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th />
            <th colSpan={2}>日時</th>
            <th>件名</th>
          </tr>
        </thead>
        <tbody style={{ borderBottom: 'none' }}>
          {filteredNotices.map((notice) => (
            <tr
              key={notice.id}
              style={{
                cursor: 'pointer',
                borderBottom: '1px solid #CCC',
              }}
              onClick={() => {
                this.handleClickTitle(notice)
              }}
            >
              <td>
                {!notice.read && (
                  <div
                    style={{
                      backgroundColor: '#DB2464',
                      borderRadius: '50%',
                      width: 10,
                      height: 10,
                    }}
                  />
                )}
              </td>
              <td>
                <div className="my-2">{moment(notice.datetime).format('YYYY年M月D日 HH:mm')}</div>
              </td>
              <td>
                <CategoryLabel
                  style={{
                    backgroundColor:
                      notice.category === NoticeFilters.CRITICAL.id ? '#DB2464' : '#A8A9AB',
                  }}
                >
                  {NoticeFilters[notice.category].name}
                </CategoryLabel>
              </td>
              <td>
                <div>{notice.title}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div style={{ textAlign: 'center', marginTop: 80 }}>未読のお知らせはありません</div>
    )
  }

  renderDetail() {
    const { detailHtml, selectedNotice } = this.state
    return (
      <div>
        <div
          className="pb-3 mb-3"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #212529',
          }}
        >
          <div className="ml-3" style={{ display: 'flex' }}>
            <Icon
              src="/assets/svgs/arrow-left-blue.svg"
              width={16}
              type="button"
              onClick={this.handleClickClose}
            />
            <span className="ml-3" style={{ fontWeight: 'bold' }}>
              {selectedNotice.title}
            </span>
          </div>
          <div className="mr-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <span className="mr-3">
              {moment(selectedNotice.datetime).format('YYYY年M月D日 HH:mm')}
            </span>
            <CategoryLabel
              style={{
                backgroundColor:
                  selectedNotice.category === NoticeFilters.CRITICAL.id ? '#DB2464' : '#A8A9AB',
              }}
            >
              {NoticeFilters[selectedNotice.category].name}
            </CategoryLabel>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: detailHtml }} />
      </div>
    )
  }

  render() {
    const { notices } = this.props
    const { filter, selectedNotice } = this.state

    return (
      <React.Fragment>
        <Helmet>
          <title>トラック簿 - インフォメーション</title>
          <meta name="description" content="Monoful Berth BillInfo" />
        </Helmet>
        <Header showNotification={false}>
          <div className="d-flex justify-content-start ml-3">
            <span>インフォメーション</span>
          </div>
        </Header>
        <div className="pt-3 pr-3" style={{ backgroundColor: '#EAEAEA', minHeight: '100vh' }}>
          <div style={{ display: 'flex' }}>
            {/* left */}
            <div
              className="px-3"
              style={{ width: 220, backgroundColor: '#EAEAEA', color: '#212529' }}
            >
              {ALL_FILTER_IDS.map((filterId) => (
                <div key={filterId}>
                  <button
                    onClick={() => this.handleClickFilter(filterId)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: filter === filterId ? '#E0E0E0' : '#EAEAEA',
                      border: 'none',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '4px 12px',
                      borderRadius: 24,
                      width: '100%',
                      outline: 'none',
                    }}
                  >
                    <span
                      style={{
                        color: '#212529',
                        fontWeight: filter === filterId ? 'bold' : 'normal',
                      }}
                    >
                      {NoticeFilters[filterId].name}
                    </span>
                    <div
                      style={{
                        minWidth: 24,
                        padding: '0 4px',
                        borderRadius: 16,
                        fontSize: 14,
                        height: 24,
                        lineHeight: '22px',
                        ...determineColors(filterId, filter, notices),
                      }}
                    >
                      {renderFilterCount(filterId, notices)}
                    </div>
                  </button>
                  {filterId === NoticeFilters.MAINTENANCE.id && <hr />}
                </div>
              ))}
            </div>

            {/* right */}
            <div
              className="p-3"
              style={{
                backgroundColor: '#FAFAFA',
                width: 'calc(100% - 200px)',
                height: 'calc(100vh - 92px)',
                overflowY: 'scroll',
              }}
            >
              {selectedNotice ? this.renderDetail() : this.renderList()}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  notices: selectNotices(),
})

const mapDispatchToProps = (dispatch) => ({
  updateNotices: (payload) => {
    dispatch({ type: UPDATE_NOTICES, payload })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NoticePage)
