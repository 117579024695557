import styled from 'styled-components'

import theme from 'vars/theme'

export const FormWrapper = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  color: ${theme.blueMedium5};
  font-weight: bold;

  .text-checkbox {
    margin-left: 34px;
    cursor: pointer;
  }

  .custom-checktype {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 24px;
    width: 24px;
    border-radius: 4px;
    border: 2px solid ${theme.blueMedium5};
    background: ${theme.whitePrimary};
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  /* On mouse-over, add a hover background color */
  .form-check-label:hover input:not(:checked) ~ .checkmark {
    background-color: ${theme.blueMedium5};
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: ${theme.blueMedium5};
    border: 2px solid ${theme.blueMedium5};
    border-radius: 4px;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark::after {
    left: 6px;
    top: 2px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
