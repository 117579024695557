import React, { Suspense, lazy } from 'react'

import LoadingIndicator from 'components/LoadingIndicator'

const Loader = ({ children }: any) => (
  <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
)

const BerthSizePage = lazy(() => import('./pages/BerthSizePage'))

export const BerthSizePageLoader = () => (
  <Loader>
    <BerthSizePage />
  </Loader>
)

const NotificationSoundPage = lazy(() => import('./pages/NotificationSoundPage'))

export const NotificationSoundPageLoader = () => (
  <Loader>
    <NotificationSoundPage />
  </Loader>
)

const TenantSettingUnitTimePage = lazy(() => import('./pages/TenantSettingUnitTimePage'))

export const TenantSettingUnitTimePageLoader = () => (
  <Loader>
    <TenantSettingUnitTimePage />
  </Loader>
)
