import React, { memo, useState, useMemo, useCallback, ChangeEvent, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import _ from 'lodash'

import { useDispatch, useSelector } from 'reduxStoreHelper'
import Icon from 'components/Icon'
import { openModal } from 'containers/App/reducers'
import { formatPhoneNumber, formatPhoneNumberToObject } from 'utils/formatters'
import PrevButton from 'components/PrevButton'
import NumericKeypad from 'components/NumericKeypad'
import { INTEGRATED_RECEPTION_URL_BASE, MAX_LENGTH_PASS_CODE, filterNumber } from '../constants'
import Header from '../components/Header'
import {
  isPhoneCheckedSelectors,
  phoneNumberEnteredSelectors,
  policyDataSelectors,
} from '../selectors'
import {
  resetDriverInfo,
  startLoading,
  endLoading,
  fetchSuccess,
  clearBooking,
  setIsFromBookingNo,
  setGeneralReceptionDetail,
} from '../reducer'
import { postVerifyPassCode, postResendPassCode, getGeneralReceptionDetail } from '../api'
import * as S from '../style'

type PassCodeType = {
  input1: string
  input2: string
  input3: string
  input4: string
}

type Props = {
  generalReceptionId?: string
}

const PassCodeInitial: PassCodeType = {
  input1: '',
  input2: '',
  input3: '',
  input4: '',
}

const EntryPassCodePage: React.FC<Props> = ({ generalReceptionId }) => {
  const history = useHistory()
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()
  const dispatch = useDispatch()
  const isPhoneCheckedDriverInfo = useSelector(isPhoneCheckedSelectors())
  const phoneNumberEntered = useSelector(phoneNumberEnteredSelectors())
  const policyData = useSelector(policyDataSelectors())

  const [passCode, setPassCode] = useState<PassCodeType>({
    ...PassCodeInitial,
  })

  const [error, setError] = useState<string>('')
  const [isPhoneChecked, setIsPhoneChecked] = useState(isPhoneCheckedDriverInfo)

  useEffect(() => {
    setIsPhoneChecked(isPhoneCheckedDriverInfo)
  }, [isPhoneCheckedDriverInfo])

  const passCodeValue = useMemo<string>(
    () => `${passCode.input1}${passCode.input2}${passCode.input3}${passCode.input4}`,
    [passCode]
  )

  const onClickNumberKeyBoard = useCallback(
    (value: string) => {
      if (passCodeValue.length === MAX_LENGTH_PASS_CODE) return

      if (error) setError('')

      _.forEach(passCode, (passCodeValue, key) => {
        if (!passCodeValue) {
          setPassCode({
            ...passCode,
            [key]: value,
          })
          return false
        }
      })
    },
    [error, passCode, passCodeValue]
  )

  const onClickBackSpace = useCallback(() => {
    if (!passCodeValue.length) return

    if (error) setError('')

    _.forEachRight(passCode, (passCodeValue, key) => {
      if (passCodeValue) {
        setPassCode({
          ...passCode,
          [key]: '',
        })
        return false
      }
    })
  }, [error, passCode, passCodeValue])

  const onChangePassCode = (e: ChangeEvent<HTMLInputElement>, key: keyof PassCodeType) => {
    const { value } = e.target
    setPassCode({
      ...passCode,
      [key]: filterNumber(value),
    })
  }

  const handleClickCheckinNew = useCallback(() => {
    dispatch(clearBooking())
    dispatch(setIsFromBookingNo(false))
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/${generalReceptionNumber}`)
  }, [dispatch, generalReceptionNumber, history])

  const onClickPrevButton = useCallback(() => {
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-tel-number/${generalReceptionNumber}`)
    dispatch(resetDriverInfo())
  }, [dispatch, generalReceptionNumber, history])

  const onClickEnter = useCallback(async () => {
    try {
      dispatch(startLoading())
      const [dataGeneral] = await Promise.all([
        getGeneralReceptionDetail(generalReceptionId),
        postVerifyPassCode(passCodeValue, phoneNumberEntered),
      ])
      const generalReceptionDetail = _.get(dataGeneral, 'data', {})
      dispatch(setGeneralReceptionDetail(generalReceptionDetail))

      if (!_.isEmpty(policyData)) {
        history.push(`${INTEGRATED_RECEPTION_URL_BASE}/terms-of-service/${generalReceptionNumber}`)
        return
      }
      if (generalReceptionDetail?.features?.canGeneralBooking) {
        history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-bookings/${generalReceptionNumber}`)
      } else {
        handleClickCheckinNew()
      }
    } catch (error) {
      //@ts-ignore
      setError(error.message)
    } finally {
      dispatch(endLoading())
    }
  }, [
    dispatch,
    passCodeValue,
    phoneNumberEntered,
    generalReceptionId,
    policyData,
    history,
    generalReceptionNumber,
    handleClickCheckinNew,
  ])

  const onClickResend = useCallback(async () => {
    try {
      dispatch(startLoading())
      const [dataResendCode, generalDetail] = await Promise.all([
        postResendPassCode(generalReceptionId, phoneNumberEntered),
        getGeneralReceptionDetail(generalReceptionId),
      ])
      const policyData = _.get(dataResendCode, 'data.displayPolicyVersion') || {}
      const isPhoneChecked = _.get(dataResendCode, 'data.phoneChecked', false)

      const generalReceptionDetail = _.get(generalDetail, 'data', {})

      setIsPhoneChecked(isPhoneChecked)

      dispatch(setGeneralReceptionDetail(generalReceptionDetail))

      dispatch(
        fetchSuccess({
          key: 'policyData',
          data: policyData,
        })
      )
    } catch (error) {
      //@ts-ignore
      setError(error.message)
    } finally {
      dispatch(endLoading())
    }

    dispatch(
      openModal({
        content: (
          <div>
            <S.Title>
              SMSで
              <span style={{ textDecoration: 'underline' }}>
                {formatPhoneNumber(phoneNumberEntered)}
              </span>
              にパスコードを送信しました。
            </S.Title>
            <S.Notification
              dangerouslySetInnerHTML={{
                __html:
                  'パスコードが届かない場合は、<br/>入力した電話番号に誤りがないか、再度ご確認ください。',
              }}
            />
          </div>
        ),
        buttons: [
          {
            label: 'とじる',
            color: 'primary',
            onClick: () => {
              setError('')
              setPassCode({
                ...PassCodeInitial,
              })
            },
          },
        ],
      })
    )
  }, [dispatch, generalReceptionId, phoneNumberEntered])

  const phoneNumber = useMemo(
    () => formatPhoneNumberToObject(phoneNumberEntered),
    [phoneNumberEntered]
  )

  return (
    <S.Wrapper>
      <Header />
      <S.ModalFramePassCode>
        <S.HeaderModal>
          <S.HeaderBox>
            <PrevButton onClick={onClickPrevButton} />
          </S.HeaderBox>
          <S.IconBox>
            {isPhoneChecked ? (
              <Icon
                src="/assets/svgs/registered-mark.svg"
                width={44}
                height={44}
                className="mr-2"
              />
            ) : (
              <Icon src="/assets/svgs/beginner-mark.svg" width={44} height={44} className="mr-2" />
            )}
            <span>パスコードを入力</span>
          </S.IconBox>
          <S.HeaderBox />
        </S.HeaderModal>
        <div className="text-center text-danger">
          {!error ? <span>&nbsp;</span> : 'パスコードが違います。再度ご確認ください。'}
        </div>
        <S.Box>
          <S.LeftBox>
            <S.PassCodeText
              dangerouslySetInnerHTML={{
                __html: isPhoneChecked
                  ? `本人確認のため、<br/>ご登録の電話番号にお送りしている<br/>パスコードを入力してください。`
                  : '本人確認のため、<br/>ご登録の電話番号にお送りした<br/>４桁のパスコードを入力してください。',
              }}
            />
            <S.PhoneNumberLabel>
              <p>{phoneNumber?.left}</p>
              <p>-</p>
              <p>{phoneNumber?.center}</p>
              <p>-</p>
              <p>{phoneNumber?.right}</p>
            </S.PhoneNumberLabel>
            <S.PassCodeText>パスコードがわからない方</S.PassCodeText>
            <S.SendCodeButton onClick={onClickResend}>パスコードを再発行する</S.SendCodeButton>
            <S.PassCodeText>※パスコードは次回以降も</S.PassCodeText>
            <S.PassCodeText>同じ数字を使用します。</S.PassCodeText>
          </S.LeftBox>
          <S.RightBox>
            <S.WrapperInputPassCode>
              <S.InputPassCode
                type="text"
                value={passCode.input1}
                maxLength={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangePassCode(e, 'input1')}
                isValid={!error}
              />
              <S.InputPassCode
                type="text"
                value={passCode.input2}
                maxLength={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangePassCode(e, 'input2')}
                isValid={!error}
              />
              <S.InputPassCode
                type="text"
                value={passCode.input3}
                maxLength={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangePassCode(e, 'input3')}
                isValid={!error}
              />
              <S.InputPassCode
                type="text"
                value={passCode.input4}
                maxLength={1}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangePassCode(e, 'input4')}
                isValid={!error}
              />
            </S.WrapperInputPassCode>
            <S.BackSpaceCustom onClick={onClickBackSpace}>一文字消す</S.BackSpaceCustom>
            <NumericKeypad
              onClickNumber={onClickNumberKeyBoard}
              onClickEnter={onClickEnter}
              disabledEnterButton={passCodeValue.length < MAX_LENGTH_PASS_CODE}
            />
          </S.RightBox>
        </S.Box>
      </S.ModalFramePassCode>
      <S.Backdrop />
    </S.Wrapper>
  )
}

export default memo(EntryPassCodePage)
