import { takeLatest, put, call, all } from 'redux-saga/effects'
import _ from 'lodash'

import { context } from './constants'
import { getDriverInfo, postSendCode } from './api'
import { startLoading, fetchDriverInfoSuccess, fetchFailed } from './reducer'

//@ts-ignore
function* fetchDriverInfo({
  payload,
}: {
  type: string
  payload: { phoneNumberEntered: string; generalReceptionId: string }
}) {
  try {
    const { phoneNumberEntered, generalReceptionId } = payload
    yield put(startLoading())
    const [driverResponse, sendCodeResponse] = yield all([
      call(getDriverInfo, phoneNumberEntered),
      call(postSendCode, generalReceptionId, phoneNumberEntered),
    ])

    const isPhoneChecked = _.get(sendCodeResponse, 'data.phoneChecked', false)
    const policyData = _.get(sendCodeResponse, 'data.displayPolicyVersion') || {}

    yield put(
      fetchDriverInfoSuccess({
        phoneNumberEntered,
        driver: _.get(driverResponse, 'data', {}) || {},
        policyData,
        isPhoneChecked,
      })
    )
  } catch (error) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

export default function* () {
  yield takeLatest(`${context}/FETCH_DRIVER_INFO`, fetchDriverInfo)
}
