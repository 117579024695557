import {
  ATTACHMENT_EXTENSIONS,
  ATTACHMENT_MAX_SIZE,
  ATTACHMENT_MAX_NUM,
  ErrorMessages,
} from 'constants/Attachment'

const buildErrorMessage = (templateString: string, templateVars: any) => {
  const template = templateString.replace(/\$\{/g, '${this.')
  return new Function('return `' + template + '`').call(templateVars)
}

export default (attachments: Array<any>, files: Array<any>): string => {
  // 最大アップロード可能数
  const removeTargetFiles = attachments.filter((attachment) => !attachment.name)
  const totalFileLength = attachments.length - removeTargetFiles.length + files.length

  if (totalFileLength > ATTACHMENT_MAX_NUM) {
    return ErrorMessages.OVER_MAX_COUNT
  }

  // ファイルサイズチェック
  const over10mb = files.find((file) => file.size >= ATTACHMENT_MAX_SIZE)

  if (over10mb) {
    return buildErrorMessage(ErrorMessages.OVER_MAX_SIZE, { fileName: over10mb.name })
  }

  // ファイル種類チェック
  const invalidFile = files.find((file) => {
    const nodes = file.name.split('.')
    const extension = nodes[nodes.length - 1].toLowerCase()
    return !ATTACHMENT_EXTENSIONS.includes(`.${extension}`)
  })

  if (invalidFile) {
    return buildErrorMessage(ErrorMessages.INVALID_FILE_TYPE, { fileName: invalidFile.name })
  }

  // ファイル名チェック
  const invalidFileName = files.find((file) => {
    return file.name.indexOf(',') > -1
  })

  if (invalidFileName) {
    return ErrorMessages.INVALID_FILE_NAME
  }

  // 重複ファイル名チェック
  const isDuplicated = files.some((file) => {
    return attachments.some((attachment) => attachment.name === file.name)
  })

  if (isDuplicated) {
    return ErrorMessages.DUPLICATED_FILE_NAME
  }

  return ''
}
