import React, { useCallback, useState, useMemo, memo } from 'react'
import styled from 'styled-components'
import Creatable from 'react-select/creatable'
import { InputActionMeta, ActionMeta, OptionTypeBase as OptionType, components } from 'react-select'
import _ from 'lodash'

import Icon from 'components/Icon'
import theme from 'vars/theme'
import { compareValue, trimData } from 'utils/commonFunctions'
import { ReactComponent as LoadingIndicator } from 'assets/svgs/loading.svg'

import { SelectOptionsWrapper } from './style'
import { ErrorText } from '../SingleForm/style'

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ isHightLight }) => (isHightLight ? theme.blueMedium2 : '')};

  img {
    margin-left: 5px;
  }
`
const CustomLoading = styled(LoadingIndicator)`
  display: block;
  margin: 10px auto;
`

const MenuList = (props: any) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {/* @ts-ignore */}
      {props?.isLoading && <CustomLoading />}
    </components.MenuList>
  )
}

type Props = {
  className: string
  options: OptionType[]
  isLoading?: boolean
  isDisabled?: boolean
  errorMessage?: string
  required?: boolean
  label?: string
  isHightLight?: boolean
  labelFontSize?: string
  maxLength?: number
  type?: 'string' | 'number'
  value: string | number
  defaultOption?: OptionType
  onChange: (option: OptionType) => void
  onInputChange: (value: string) => void
  onBlur: (value: string) => void
  onFocus: () => void
  onMenuScrollToBottom: (element: any, inputValue: string) => void
}

enum InputAction {
  INPUT_CHANGE = 'input-change',
}

enum ActionType {
  SELECT_OPTION = 'select-option',
  CREATE_OPTION = 'create-option',
}

const CreatableSelectLoading: React.FC<Props> = ({
  value,
  options,
  errorMessage = '',
  required,
  label,
  isHightLight,
  labelFontSize,
  maxLength = 200,
  type = 'string',
  defaultOption,
  className,
  onChange,
  onBlur,
  onMenuScrollToBottom,
  onInputChange,
  ...rest
}) => {
  const [createdOption, setCreatedOption] = useState<OptionType[]>([])
  const [inputValue, setInputValue] = useState<string>('')

  const finalOptions = useMemo<OptionType[]>(
    () =>
      _.uniqBy([...(defaultOption ? [defaultOption] : []), ...createdOption, ...options], 'value'),
    [defaultOption, createdOption, options]
  )

  const selectedValue = useMemo(() => {
    const selected = finalOptions.find(
      (option) => compareValue(option.value, value) || option.label === value
    )
    return selected || null
  }, [finalOptions, value])

  const isValidNewOption = useCallback((inputValue: string) => {
    const val = inputValue.trim()
    return !!val
  }, [])

  const handleChange = useCallback(
    (option: OptionType, { action }: ActionMeta<OptionType>) => {
      const { isDisabled } = rest

      if (isDisabled) return

      switch (action) {
        case ActionType.SELECT_OPTION:
          onChange(option)
          break
        case ActionType.CREATE_OPTION:
          onChange(option)
          setCreatedOption([...createdOption, option])
          break
        default:
          break
      }
    },
    [createdOption, onChange, rest]
  )

  const handleCreate = useCallback(
    (value: string) => {
      const val = value.trim()
      if (val) {
        const option = finalOptions.find((it) => compareValue(val, it.label))
        if (option) {
          handleChange(option, { action: ActionType.SELECT_OPTION })
          return
        }
        const newOption = {
          value: val,
          label: val,
        }
        handleChange(newOption, { action: ActionType.CREATE_OPTION })
      }
    },
    [finalOptions, handleChange]
  )

  const handleBlur = useCallback(
    (event: any) => {
      const value = event?.target?.value || ''

      handleCreate(value)
      onBlur(value)
    },
    [handleCreate, onBlur]
  )

  const handleScrollToBottom = useCallback(
    (event: any) => {
      onMenuScrollToBottom(event, inputValue)
    },
    [inputValue, onMenuScrollToBottom]
  )

  const handleInputChange = useCallback(
    (value: string, { action }: InputActionMeta) => {
      if (action === InputAction.INPUT_CHANGE) {
        onInputChange(value)
      }

      setInputValue(value)
    },
    [onInputChange]
  )

  return (
    <React.Fragment>
      <SelectOptionsWrapper className={className || ' mb-2'} isError={!!errorMessage}>
        {label && (
          <Label
            style={{ fontSize: labelFontSize || '14px' }}
            isHightLight={isHightLight}
            className="label-flex"
          >
            {label}
            {required && <span className="color-red-med1 font-weight-bold">*</span>}
            {/* @ts-ignore */}
            {isHightLight && <Icon src="/assets/svgs/man-user.svg" />}
          </Label>
        )}
        <div className="input-flex flex-column">
          <Creatable
            autosize={false}
            className="w-100"
            classNamePrefix="mono-creatable"
            options={finalOptions}
            value={selectedValue}
            //@ts-ignore
            onChange={handleChange}
            isValidNewOption={isValidNewOption}
            formatCreateLabel={() => ''}
            noOptionsMessage={() => null}
            onBlur={handleBlur}
            onCreateOption={handleCreate}
            blurInputOnSelect={false}
            components={{
              LoadingIndicator: () => <></>,
              LoadingMessage: () => <></>,
              MenuList,
            }}
            onMenuScrollToBottom={handleScrollToBottom}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            {...rest}
          />
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </div>
      </SelectOptionsWrapper>
    </React.Fragment>
  )
}

export default memo(CreatableSelectLoading)
