import errorMessage from 'utils/errorMessages'
import moment from 'moment'
import toString from 'lodash/toString'
import _isEmpty from 'lodash/isEmpty'
import { fromJS } from 'immutable'
import { isUpdateNewBooking, isSameBookingPosition } from 'utils/commonFunctions'
import { REGEX } from 'constants/misc'
const { HomePage: homePageMessage } = errorMessage

export const imageToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
    reader.readAsDataURL(file)
  })

export const formatPhoneNumber = (numberVal) => {
  let number = toString(numberVal)
  if (number.indexOf('81') === 0) {
    number = number.replace('81', '0')
  }
  if (number.indexOf('84') === 0) {
    number = number.replace('84', '0')
  }
  const splitReverse = number.toString().split('').reverse()
  const appendDash = splitReverse.reduce((result, w, index) => {
    if (index % 4 === 3 && index !== splitReverse.length - 1) {
      return result.concat([w, '-'])
    }

    return result.concat(w)
  }, [])

  return appendDash.reverse().join('')
}

export const formatBookingNo = (val = '') => {
  const split = val.toString().split('')
  return split.reduce((result, w, index) => {
    result.push(w)
    if (index % 4 === 3 && index !== split.length - 1) {
      result.push('-')
    }
    return result
  }, [])
}

export const formatCurrency = (numberVal) =>
  numberVal && numberVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export function formatNegativeCurrency(number) {
  if (!number) return '0'

  const typeString = number.toString()
  if (/-/.test(typeString)) {
    return `-¥${formatCurrency(typeString.match(/\d+/))}`
  }
  return `¥${formatCurrency(typeString)}`
}

export const formatVehicleNumber = (number) => {
  const splitReverse = number.toString().split('')

  const appendDash = splitReverse.reduce((result, w, index) => {
    if (index === 1) {
      return result.concat([w, ' - '])
    }

    return result.concat(w)
  }, [])

  return appendDash.join('')
}

export const formatPhoneNumberWithSpace = (number) => {
  let splitReverse = number.toString().split('')

  if (splitReverse[0] === '8' && splitReverse[1] === '1') {
    splitReverse = splitReverse.join('').replace('81', '0').split('')
  }

  const appendDash = splitReverse.reduce((result, w, index) => {
    if (index % 4 === 2 && index !== splitReverse.length - 1) {
      return result.concat([w, ' - '])
    }

    return result.concat(w)
  }, [])

  return appendDash.join('')
}

export const formatBookingNoWithSpace = (bookingNo) => {
  const splitReverse = bookingNo.toString().split('')
  const appendDash = splitReverse.reduce((result, w, index) => {
    if (index % 4 === 3 && index !== splitReverse.length - 1) {
      return result.concat([w, ' - '])
    }

    return result.concat(w)
  }, [])
  return appendDash.join('')
}

export const validateRepeatBooking = (data) => {
  const { repeat_new, isRepeatDeadLine } = data
  if (_isEmpty(repeat_new)) return {}
  const errors = {}

  if (repeat_new.daily) {
    const { end_date } = repeat_new.daily
    if (isRepeatDeadLine && !end_date) {
      errors.deadLine = homePageMessage.deadLineBlank()
    }
  } else if (repeat_new.weekly) {
    const { end_date, day_of_week } = repeat_new.weekly
    if (isRepeatDeadLine && !end_date) {
      errors.deadLine = homePageMessage.deadLineBlank()
    }
    if (!day_of_week.length) {
      errors.dayOfWeek = homePageMessage.dayOfWeekBlank()
    }
  }
  return errors
}

export const isMobileDevice =
  typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1

export const isIpadDevice =
  // old iPad
  (/iPad/.test(navigator.userAgent) && !window.MSStream) ||
  // new iPad
  (/Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1)

export const isAndroidDevice = /Android/i.test(navigator.userAgent)

const IPAD_ADJUSTMENT_HEIGHT = 60
const ANDROID_ADJUSTMENT_HEIGHT = 100

export const TABLET_ADJUSTMENT_HEIGHT = (() => {
  if (isIpadDevice) {
    return IPAD_ADJUSTMENT_HEIGHT
  } else if (isAndroidDevice) {
    return ANDROID_ADJUSTMENT_HEIGHT
  }
  return 0
})()

export const isDeleteRepeat = (item1, item2) => {
  if (item1.status && item1.status > 6) return false
  const condition1 = item1.repeat_id === item2.repeat_id
  const condition2 = item1.booking_id && item1.booking_id === item2.booking_id
  const condition3 = item1.start_date === item2.start_date
  let condition4 = false
  if (condition1 && !condition3) {
    if (item1.start_date < item2.start_date) {
      condition4 = item2.apply_type === 1 // delete all
    } else {
      condition4 = item2.apply_type === 3 || item2.apply_type === 1 // delete all or from item to last
    }
  }
  return condition2 || (condition1 && (condition3 || condition4))
}
export const specialCompare = (item1, item2) =>
  `${item1.booking_id}${item1.repeat_id}` === `${item2.booking_id}${item2.repeat_id}`

export const updateBookingRepeat = (state, payload) => {
  const currentDay = moment(state.get('currentDay') || state.get('currentTime')).format('YYYYMMDD')
  const isBelongtoday = !isUpdateNewBooking(currentDay, payload)
  const currentBookingList = state.get('bookingList')?.toJS()
  const currentPendingItem = state.get('pendingItem')?.toJS()
  // neu payload la ao, case tao moi repeat ao
  if (!payload.booking_id) {
    const hasRepeat = currentBookingList.find((it) => it.repeat_id === payload.repeat_id)
    if (isBelongtoday && !hasRepeat) {
      return state
        .set('bookingList', fromJS([...currentBookingList, payload]))
        .set(
          'pendingItem',
          fromJS(isSameBookingPosition(currentPendingItem, payload) ? {} : currentPendingItem)
        )
        .set('actionProcessing', false)
    }
    return state.set('actionProcessing', false)
  }
  // payload la booking that
  // tim repeat booking ao
  const hasRepeat = currentBookingList.find(
    (it) => !it.booking_id && it.repeat_id === payload.repeat_id && !specialCompare(it, payload)
  )
  // tim repeat booking that
  const currentRepeat = currentBookingList.find((it) => specialCompare(it, payload))
  // hien tai chua co ao chua co that, case tao moi, case nay kho xay ra vi chi tao moi dc repeat ao
  if (!hasRepeat && !currentRepeat) {
    if (isBelongtoday) {
      return state
        .set('bookingList', fromJS([...currentBookingList, payload]))
        .set('actionProcessing', false)
    }
    return state.set('actionProcessing', false)
  }
  // co repeat ao, khong co repeat that, mot thang repeat ao trong serie se bi update
  if (hasRepeat && !currentRepeat) {
    const hasRepeatIndex = currentBookingList.findIndex(
      (it) =>
        !it.booking_id &&
        it.repeat_id === payload.repeat_id &&
        !specialCompare(it, payload) &&
        payload.old_start_date === it.start_date &&
        payload.old_end_date === it.end_date
    )
    if (hasRepeatIndex > -1) {
      // mot booking that thay the booking ao
      if (isBelongtoday) {
        return state.setIn(['bookingList', hasRepeatIndex], payload).set('actionProcessing', false)
      }
      // booking ao update status, chuyen sang ngay khac
      return state
        .set(
          'bookingList',
          fromJS(currentBookingList.filter((it, index) => index !== hasRepeatIndex))
        )
        .set('actionProcessing', false)
    }
    return state.set('actionProcessing', false)
  }
  // co booking that, case update booking repeat
  if (currentRepeat) {
    const repeatIndex = currentBookingList.findIndex((it) => specialCompare(it, payload))
    if (isBelongtoday) {
      return state.setIn(['bookingList', repeatIndex], payload).set('actionProcessing', false)
    }
    return state
      .set('bookingList', fromJS(currentBookingList.filter((it, index) => index !== repeatIndex)))
      .set('actionProcessing', false)
  }
  return state.set('actionProcessing', false)
}

export const toLowerCase = (item) => {
  if (_isEmpty(item)) return item

  if (typeof item === 'string') return item.toLowerCase()
  return item
}

export const testMailValidate = (mail) => REGEX.EMAIL.test(toLowerCase(mail))

export const testCellPhoneValidate = (cellPhone) => REGEX.CELL_PHONE.test(cellPhone)

export const testCellPhoneStartWithValidate = (cellPhone) =>
  REGEX.CELL_PHONE_START_WITH.test(cellPhone)

export const isArrayHasValue = (arrayCheck) => Array.isArray(arrayCheck) && arrayCheck.length > 0

export const filterBookingDuplicate = (currentList, newBooking) => {
  return (currentList || []).filter((it) => {
    const hasBookingId = !!newBooking.booking_id
    const isDiffBookingId = it.booking_id !== newBooking.booking_id
    const isDiffRepeatId = it.repeat_id !== newBooking.repeat_id
    if (hasBookingId) return isDiffBookingId
    return isDiffRepeatId
  })
}

export const getChangedDataInArray = (initials, compares) => {
  if (!isArrayHasValue(initials)) return []
  if (initials?.length !== compares?.length) return []

  return compares?.filter(function (itemCompare, idx) {
    return Object.keys(itemCompare)?.some(function (prop) {
      return itemCompare[prop] !== initials[idx][prop]
    })
  })
}

// General confirmation
export const ACCEPTED_FILE_IMAGE_CONFIRMING_DRIVER = ['.jpeg', '.jpg', '.png', '.gif']

export const checkValidFileName = (name) => {
  const nodes = name.split('.')
  const extension = nodes[nodes.length - 1].toLowerCase()
  return ACCEPTED_FILE_IMAGE_CONFIRMING_DRIVER.includes(`.${extension}`)
}

// 改行を除外して文字数をカウントする
export const countLengthExcludingReturnCodes = (value) => value.replace(/\r?\n|\r/g, '').length

export const validateConfirmingDriver = (confirmation) => {
  if (
    !confirmation.confirmation &&
    confirmation.displayFlag &&
    !(confirmation?.images || []).length
  ) {
    return {
      result: false,
      message: '伝達事項の内容が空の場合、事項表示を ON にはできません',
    }
  } else if (!confirmation.displayFlag && confirmation.checkFlag) {
    return {
      result: false,
      message: '事項表示が OFF の場合、既読必須を ON にはできません',
    }
  } else if (!confirmation.displayFlag && confirmation.importantFlag) {
    return {
      result: false,
      message: '事項表示が OFF の場合、強調を ON にはできません',
    }
  }

  return {
    result: true,
    message: '',
  }
}

export const filteredNumber = (value) => value?.replace(/[^0-9]/g, '')

// Touch device includes: mobile, tablet and pc touch
// Source: https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

export const getTimeDontAllowCloseBookingImmediate = () => (isAndroidDevice ? 2000 : 1000)

export const updateBookingListYesterdayRepeat = (state, payload) => {
  const currentDay = moment().utcOffset('+09:00').subtract(1, 'day').format('YYYYMMDD')
  const isBelongYesterday = !isUpdateNewBooking(currentDay, payload)

  const currentBookingListYesterdayItem = state.get('bookingListYesterday').toJS()
  // If the payload is virtual, the case creates a new virtual repeat
  if (!payload.booking_id) {
    const hasRepeat = currentBookingListYesterdayItem.find(
      (it) => it.repeat_id === payload.repeat_id
    )
    if (isBelongYesterday && !hasRepeat) {
      return state
        .set('bookingListYesterday', fromJS([...currentBookingListYesterdayItem, payload]))
        .set('actionProcessing', false)
    }
    return state.set('actionProcessing', false)
  }
  // Payload is the actual booking
  // Heart repeat pond reservation
  const hasRepeat = currentBookingListYesterdayItem.find(
    (it) => !it.booking_id && it.repeat_id === payload.repeat_id && !specialCompare(it, payload)
  )
  // Find real repeat bookings
  const currentYesterDayRepeat = currentBookingListYesterdayItem.find((it) =>
    specialCompare(it, payload)
  )
  // Currently, there is no virtuality yet it is not real, a new case is created, this case is unlikely to happen because only virtuality can be created
  if (!hasRepeat && !currentYesterDayRepeat) {
    if (isBelongYesterday) {
      return state
        .set('bookingListYesterday', fromJS([...currentBookingListYesterdayItem, payload]))
        .set('actionProcessing', false)
    }
    return state.set('actionProcessing', false)
  }
  // There are virtual repeats, there are no real repeats, a repeater in the series will be updated
  if (hasRepeat && !currentYesterDayRepeat) {
    const hasRepeatIndex = currentBookingListYesterdayItem.findIndex(
      (it) =>
        !it.booking_id &&
        it.repeat_id === payload.repeat_id &&
        !specialCompare(it, payload) &&
        payload.old_start_date === it.start_date &&
        payload.old_end_date === it.end_date
    )
    if (hasRepeatIndex > -1) {
      // A real booking is different from a virtual booking
      if (isBelongYesterday) {
        return state
          .setIn(['bookingListYesterday', hasRepeatIndex], payload)
          .set('actionProcessing', false)
      }
      // Virtual booking updates status, moves to another date
      return state
        .set(
          'bookingListYesterday',
          fromJS(currentBookingListYesterdayItem.filter((it, index) => index !== hasRepeatIndex))
        )
        .set('actionProcessing', false)
    }
    return state.set('actionProcessing', false)
  }
  // There is a real booking, in case the booking is updated again
  if (currentYesterDayRepeat) {
    const repeatIndex = currentBookingListYesterdayItem.findIndex((it) =>
      specialCompare(it, payload)
    )
    if (isBelongYesterday) {
      return state
        .setIn(['bookingListYesterday', repeatIndex], payload)
        .set('actionProcessing', false)
    }
    return state
      .set(
        'bookingListYesterday',
        fromJS(currentBookingListYesterdayItem.filter((it, index) => index !== repeatIndex))
      )
      .set('actionProcessing', false)
  }
  return state.set('actionProcessing', false)
}
