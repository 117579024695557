import { Tooltip } from 'reactstrap'
import styled from 'styled-components'
import theme from 'vars/theme'

export const Wrapper = styled.span`
  position: relative;

  .notification-amount {
    padding: 0 2px;
    min-width: 21px;
    height: 12px;
    position: absolute;
    top: 0;
    font-size: 9px;
    right: -12px;
    border-radius: 5px;
    background: ${theme.redMedium1};
    color: ${theme.whitePrimary};
  }
`

export const TootipWrapper = styled(Tooltip)`
  .tooltip-inner {
    width: 360px;
    max-width: 360px;
    background: ${theme.whitePrimary};
    color: ${theme.darkBlueMedium1};
    border-radius: 2px;
    border: 1px solid ${theme.graySoft2};
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;

    .noti-header {
      padding: 16px;
      border-bottom: 1px solid ${theme.graySoft2};

      .clear-all-noti {
        color: ${theme.redMedium1};
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .noti-content {
      max-height: 450px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${theme.graySoft2};
        border-radius: 4px;
      }
    }
  }
`

export const QuickNotificationWrapper = styled.div`
  position: fixed;
  top: 15px;
  left: 15px;
  border-radius: 2px;
  background: ${theme.redMedium1};
  z-index: 1000;
  display: ${({ isOpen }) => (isOpen ? 'default' : 'none')};

  & > div {
    font-size: 10px;
    width: 350px;
    height: 80px;
    padding: 10px 16px;
    color: ${theme.whitePrimary};
    position: relative;

    img {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 8px;
      height: 8px;
      cursor: pointer;
    }
  }

  .item-message {
    line-height: 15px;
    margin: 0;
    padding-right: 10px;
    height: 45px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
