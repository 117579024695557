import RestClient, { getAuthHeader } from 'services/RestClient'
import { decamelizeKeys } from 'humps'

const client = new RestClient()
const config = {
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader(),
  },
}

export const requestGet = (url, data) => client.get(url, decamelizeKeys(data), config)

const requestPatch = (url, data) =>
  client.patch({
    url,
    data,
    config,
  })

// const requestPut = (url, data) =>
//   client.put({
//     url,
//     data,
//     config,
//   })

export const getReception = (tenantId) => requestGet('reception', decamelizeKeys({ tenantId }))
export const patchReception = (data) => requestPatch('reception', decamelizeKeys(data))

export const getConfirmation = (tenantId) =>
  requestGet('reception/confirmation', decamelizeKeys({ tenantId }))
export const patchConfirmation = (data) =>
  requestPatch('reception/confirmation', decamelizeKeys(data))

export const getLoadOptions = (tenantId, optionGroupId) =>
  requestGet('reception/options', decamelizeKeys({ tenantId, optionGroupId }))
export const patchLoadOptions = (data) => requestPatch('reception/options', decamelizeKeys(data))

export const getWarehouses = () => requestGet('warehouse/list_tenants_for_warehouse')

export const patchCardView = (data) => requestPatch('reception/card_view', decamelizeKeys(data))
export const patchCardViewOrder = (data) =>
  requestPatch('reception/card_view_order', decamelizeKeys(data))
