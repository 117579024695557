import React, { Component } from 'react'
import styled from 'styled-components'
import { NavLink, withRouter } from 'react-router-dom'

import Icon from 'components/Icon'

const WIDTH = 550

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: calc(50% - ${WIDTH / 2}px);
  width: ${WIDTH}px;
  z-index: 200;
  font-size: 16px;
`

class CriticalNotice extends Component {
  state = {
    count: 60,
  }

  timerId = 0

  componentDidMount() {
    const { location } = global
    if (!location.pathname.startsWith('/notice')) {
      this.timerId = setInterval(this.timer, 1000)
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearInterval(this.timerId)
      this.timerId = 0
    }
  }

  timer = () => {
    const { history } = this.props
    const { count } = this.state

    if (count === 0) {
      clearInterval(this.timerId)
      this.timerId = 0
      history.push('/notice/critical')
      return
    }

    this.setState({ count: count - 1 })
  }

  render() {
    const { location } = global
    const { count } = this.state
    const inNoticePage = location.pathname.startsWith('/notice')

    return (
      <Outer>
        <div
          className="p-1"
          style={{
            backgroundColor: 'white',
            borderRadius: '0 0 12px 12px',
            borderRight: '2px solid #DB2464',
            borderBottom: '2px solid #DB2464',
            borderLeft: '2px solid #DB2464',
            textAlign: 'center',
          }}
        >
          <div
            className="mb-1"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <span style={{ color: '#DB2464', display: 'flex', alignItems: 'center' }}>
              <Icon src="/assets/svgs/icon-alert.svg" className="mr-1" />
              重要なお知らせがあります
            </span>
            {!inNoticePage && (
              <NavLink
                exact
                to="/notice/critical"
                className="px-2"
                style={{
                  color: 'white',
                  borderRadius: 8,
                  backgroundColor: '#DB2464',
                  padding: '2px 0',
                  width: 100,
                }}
              >
                確認する
              </NavLink>
            )}
          </div>
          {inNoticePage ? (
            <div style={{ fontSize: 14 }}>
              この警告を消すために、全ての「重要なお知らせ」の内容を確認してください。
            </div>
          ) : (
            <div style={{ fontSize: 14, color: '#212529' }}>
              {count}秒後に自動的にインフォメーションを表示します。
            </div>
          )}
        </div>
      </Outer>
    )
  }
}

export default withRouter(CriticalNotice)
