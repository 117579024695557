import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import theme from 'vars/theme'

const DEFAULT_HEIGHT = 51
// c*c = a*a + b*b ; a=b => c*c = 2(a*a) => a = c/Math.sqrt(2)
const calculateTriangleSize = (height: number) => height / Math.sqrt(2)

const Triangle = styled.div`
  border-left: 1px solid ${theme.grayMedium3};
  border-bottom: 1px solid ${theme.grayMedium3};
`

const Button = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  color: ${theme.blueMedium5};
  font-size: 24px;
  border: 1px solid ${theme.grayMedium3};
  border-left: 0;
  background-color: ${theme.whitePrimary};
  padding: 2px 5px;

  &:focus {
    outline: 0px;
  }
`

const Wrapper = styled.div<{ height: number }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${theme.whitePrimary};
  overflow: hidden;

  ${({ height }) => {
    const sizeOfTriangle = calculateTriangleSize(height)
    return css`
      height: ${height}px;
      ${Triangle} {
        height: ${sizeOfTriangle}px;
        width: ${sizeOfTriangle}px;
        transform: translateX(${height - sizeOfTriangle + 3}px) rotate(45deg);
      }
    `
  }}
`

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  height?: number
  children: React.ReactNode
}

const BackSpaceButton: FC<Props> = ({ children, height = DEFAULT_HEIGHT, ...rest }) => {
  return (
    <Wrapper height={height} {...rest}>
      <Triangle />
      <Button>{children}</Button>
    </Wrapper>
  )
}

export default BackSpaceButton
