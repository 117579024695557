import React from 'react'

import _range from 'lodash/range'
import SelectOptions from 'components/SelectOptions'
import styled from 'styled-components'
import clock from 'assets/svgs/clock.svg'

const defaultUnassignOptions = {
  value: '',
  label: '未定',
}

const Wrapper = styled.div`
  width: 100%;

  .mono-select__dropdown-indicator {
    background: url(${clock}) no-repeat scroll;
    background-position: right 7px top 5px;
    padding-right: 30px;
    cursor: pointer;

    svg {
      visibility: hidden;
    }
  }
`

class TimePicker extends React.PureComponent {
  state = { addColon: false }

  onInputChange = (inputValue) => {
    const val = inputValue.replace(/[^0-9:]/g, '').slice(0, 5)
    if (val.length === 2 && !val.split('').includes(':') && !this.state.addColon) {
      this.setState({
        addColon: true,
      })
      return `${val}:`
    }
    this.setState({
      addColon: false,
    })
    return val
  }

  getDefaultOptions = () =>
    _range(0, 24).reduce((result, item, index) => {
      const hour = String(index).padStart(2, '0')
      const newData = _range(0, 60, 15).map((it) => {
        const val = String(it).padStart(2, '0')
        return { label: `${hour}:${val}`, value: `${hour}:${val}:00` }
      })
      return [...result, ...newData]
    }, [])

  getOptions = () => {
    const { options, unassignTimePickerOptions } = this.props
    const finalOptions = options || this.getDefaultOptions()
    return [unassignTimePickerOptions || defaultUnassignOptions, ...finalOptions]
  }

  render() {
    const { value, isError, isDisabled } = this.props
    return (
      <Wrapper>
        <SelectOptions
          name="time-picker"
          isSearchable
          options={this.getOptions()}
          selectValue={value}
          handleUpdateData={this.props.onChange}
          isError={isError}
          onInputChange={this.onInputChange}
          isDisabled={isDisabled}
          styles={{
            option: (provided, { label }) => {
              return label.match(/:00$/)
                ? {
                    ...provided,
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: 16,
                  }
                : provided
            },
          }}
        />
      </Wrapper>
    )
  }
}

export default TimePicker
