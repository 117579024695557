import React, { Component } from 'react'
import { decamelizeKeys } from 'humps'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import styled from 'styled-components'

import { TitleScreen } from 'containers/ReceiptBooking/style'
import { startLoading, stopLoading, openError } from 'containers/App/reducers'

import Header from '../components/Header'
import { requestPost } from '../api'
import Storage from '../Storage'
import NotSupportedBrowser from 'components/NotSupportedBrowser'

export const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 52px;
  padding-left: 10px;
  padding-right: 10px;
`

class LoginPage extends Component {
  state = {
    password: '',
    errors: {},
  }

  componentDidMount() {
    //   const { dispatchCheckinSessionLogout, dispatchCheckData, dispatchCheckIsLogginSession, match: { params } } = this.props
    //   const mid = _get(this, 'props.location.state.mid', '')
    //   // check is Loggin
    //   if (mid) dispatchCheckIsLogginSession(mid)
    //   if (_get(params, 'logout', '') === 'logout') {
    //     dispatchCheckinSessionLogout(params)
    //     return
    //   }
    //   dispatchCheckData({ warehouse_id: params.warehouseId, tenant_id: params.tenantId })
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   if (!isEmpty(nextProps.errors)) {
  //     this.setState({
  //       errors: {
  //         message: nextProps.errors.message,
  //       },
  //     })
  //   }
  // }

  // componentWillUnmount() {
  //   this.setState({
  //     password: '',
  //     errors: {},
  //   })
  // }

  // handlePasswordChange = value => {
  //   this.setState(prevState => ({
  //     ...prevState,
  //     password: value,
  //   }))
  // }

  // handleLogin = () => {
  //   const { password } = this.state
  //   const warehouse_id = this.props.match.params.warehouseId
  //   const tenant_id = this.props.match.params.tenantId

  //   const { dispatchCheckinSessionLogin } = this.props

  //   if (password === '') {
  //     this.setState({
  //       errors: {
  //         message: errorMessages.Receipt.pleaseEnterPassword(),
  //       },
  //     })
  //   } else {
  //     this.setState({
  //       errors: {},
  //       password: '',
  //     }, () => dispatchCheckinSessionLogin({ warehouse_id, tenant_id, password }))
  //   }
  // }

  onChangePassword = (e) => {
    const { value } = e.target
    this.setState({ password: value })
  }

  onSubmitLogin = async (e) => {
    e.preventDefault()

    // TODO case of referrer is empty
    const {
      state: { referrer },
    } = this.props.location
    const { password } = this.state

    const matches = referrer.match(/[0-9]+/g)
    const [warehouseId, tenantId] = matches

    Storage.write('tenantId', tenantId)

    this.props.startLoading()

    try {
      await requestPost(
        '/tablets/sign_in',
        decamelizeKeys({
          warehouseId,
          tenantId,
          password,
        }),
        false
      )
    } catch (error) {
      this.props.openError(error.message, false)
      return
    } finally {
      this.props.stopLoading()
    }

    this.props.history.push(referrer)
  }

  render() {
    const { password, errors } = this.state

    if (errors.message === 'Data is empty') {
      return (
        <div>
          <div className="row justify-content-center">
            <TitleScreen>Page not found</TitleScreen>
          </div>
        </div>
      )
    }

    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header />
        <div style={{ margin: 32 }}>
          <NotSupportedBrowser />

          <div className="mb-3 p-5" style={{ borderRadius: 12, backgroundColor: 'white' }}>
            <div className="d-flex flex-column justify-content-center">
              <div className="row justify-content-center">
                <TitleScreen>ログイン</TitleScreen>
              </div>
              <div className="text-center mb-4">
                <p>パスワードを入力してログインしてください</p>
              </div>

              <form onSubmit={this.onSubmitLogin}>
                <div className="row justify-content-center mb-4">
                  <Input
                    // width="350px"
                    type="password"
                    value={password}
                    onChange={this.onChangePassword}
                    className="text-size24"
                    // errorMessage={errors.message}
                  />
                </div>
                <div className="row justify-content-center">
                  <Button
                    type="submit"
                    className="px-5 py-2 rounded-pill text-size24"
                    color="primary"
                  >
                    ログインする
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    multiTenants: reception.driver.multiTenants,
  }
}

const mapDispatchToProps = {
  openError,
  startLoading,
  stopLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
