import React, { FC, memo } from 'react'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'
import { camelize, camelizeKeys } from 'humps'
import { Collapse } from 'reactstrap'

import freezeR from 'utils/freezeR'
import * as S from './style'
import SingleForm from 'components/SingleForm'
import {
  GeneralBooking,
  BookingError,
  inputType,
  ReceptionOptionType,
  ParameterItemType,
} from 'containers/IntegratedManagementPage/type'
import { compareValue, isStringNumber } from 'utils/commonFunctions'
import { TEN_DIGITS } from 'constants/misc'
import {
  BookingSidePanelType,
  GeneralBookingStatuses,
  ADDITIONAL_COLUMNS_RANGE,
  IMPORT_EXPORT_COLUMN_NAME,
} from 'containers/IntegratedManagementPage/constants'
import { bookingMasterGeneralSelectors } from 'containers/IntegratedManagementPage/selectors'
import { ImportFlagCheck } from 'containers/IntegratedReception/constants'
import { isSelectType } from 'services/Booking'

const IMPORT_EXPORT_TYPES: inputType[] = freezeR([
  {
    id: 'import',
    label: '荷降ろし',
  },
  {
    id: 'export',
    label: '荷積み',
  },
])

const REGEX_IMPORT = /^import/

type Props = {
  openType: number
  draftBooking: GeneralBooking
  error: BookingError
  bookingMaster: any
  handleChangeDraftBooking: (data: object) => void
}

const ImportExportForm: FC<Props> = ({
  openType,
  draftBooking,
  error,
  bookingMaster,
  handleChangeDraftBooking,
}) => {
  const bookingMasterCamelizeKeys: any = camelizeKeys(bookingMaster)
  const importParams = _.get(bookingMasterCamelizeKeys, 'tenantParameters.import')

  const importTenantParameters = _.chain(importParams)
    .filter((item) => item.displayFlag)
    .sortBy((item) => item.displayOrder)
    .value()

  const handleInputChange = (val: number | string | boolean, name: string) => {
    let valueFormat
    switch (name) {
      case 'importMainGoods':
      case 'exportMainGoods':
      case 'importDeliverySlipNumber':
      case 'exportDeliverySlipNumber':
        // @ts-ignore
        valueFormat = val.substr(0, 50)
        break
      case 'importGoodsAmount':
      case 'exportGoodsAmount':
        if (val === '0' || val === '') {
          valueFormat = ''
          break
        }

        if (!isStringNumber(val) || Number(val) > TEN_DIGITS) {
          valueFormat = draftBooking[name] || ''
          break
        }

        valueFormat = Number(val)
        break
      default:
        valueFormat = val
    }

    handleChangeDraftBooking({ [name]: valueFormat })
  }

  const handleUpdateBooking = (
    value: any,
    typeImportOrExport: string,
    columnName: string,
    maxLength?: number
  ) => {
    let newValue = value

    if (['importFlag', 'exportFlag'].includes(columnName)) {
      const obj = importTenantParameters.reduce((ac, parameter: ParameterItemType) => {
        const columnNameUpdate = camelize(
          typeImportOrExport === IMPORT_EXPORT_COLUMN_NAME.import
            ? parameter.columnName
            : parameter.columnName.replace(REGEX_IMPORT, IMPORT_EXPORT_COLUMN_NAME.export)
        )
        return {
          ...ac,
          [columnNameUpdate]: '',
        }
      }, {})
      return handleChangeDraftBooking({ ...obj, [columnName]: value })
    }
    const columnNameUpdate = camelize(
      typeImportOrExport === IMPORT_EXPORT_COLUMN_NAME.import
        ? columnName
        : columnName.replace(REGEX_IMPORT, IMPORT_EXPORT_COLUMN_NAME.export)
    )

    if (['importGoodsAmount', 'exportGoodsAmount'].includes(columnNameUpdate)) {
      let num = value.replace(/[^0-9]/g, '')
      if (num > TEN_DIGITS) return
      newValue = num ? Number(num) : ''
    } else {
      if (maxLength && newValue.length > maxLength) {
        newValue = newValue.substr(0, maxLength)
      }
    }
    handleChangeDraftBooking({
      [columnNameUpdate]: newValue,
    })
  }

  const convertToOptions = (items = [], isAdditionalColumn = false, value: any) => {
    const propName = isAdditionalColumn ? 'parameterName' : 'parameterId'
    const options = items
      //@ts-ignore
      .filter(
        (item: ReceptionOptionType) =>
          (value && compareValue(item[propName], value)) || item.displayFlag
      )
      .map((item: ReceptionOptionType) => ({
        //@ts-ignore
        value: item[propName],
        label: item.parameterName,
      }))
    return options
  }

  const getAdditionalColumnsMaxLength = () =>
    ADDITIONAL_COLUMNS_RANGE.reduce(
      (ac, id) => ({
        ...ac,
        [`import_additional_column${id}`]: 200,
      }),
      {}
    )

  const getMaxLengthOfInput: { [key: string]: number } = {
    import_goods_owner: 255,
    import_goods_name: 255,
    import_goods_amount: 10,
    import_main_goods: 50,
    import_delivery_slip_number: 50,
    import_contact_notes: 255,
    ...getAdditionalColumnsMaxLength(),
  }

  const convertImportExportColumns = (booking: { [key: string]: any }) => {
    const additionalColumns: { [key: string]: any } = ADDITIONAL_COLUMNS_RANGE.reduce(
      (ac, id) => ({
        ...ac,
        [`additionalColumn${id}`]: _.get(bookingMasterCamelizeKeys, `additionalColumn${id}`, []),
      }),
      {}
    )

    return ADDITIONAL_COLUMNS_RANGE.reduce((ac, id) => {
      return {
        ...ac,
        [`importAdditionalColumn${id}`]: convertToOptions(
          additionalColumns[`additionalColumn${id}`],
          true,
          booking[`importAdditionalColumn${id}`]
        ),
        [`exportAdditionalColumn${id}`]: convertToOptions(
          additionalColumns[`additionalColumn${id}`],
          true,
          booking[`exportAdditionalColumn${id}`]
        ),
      }
    }, {})
  }

  const renderImportExportInput = (importExportType: inputType) => {
    const optionMap = {
      importPackageType: convertToOptions(
        bookingMasterCamelizeKeys.packageTypes,
        false,
        draftBooking.importPackageType
      ),
      exportPackageType: convertToOptions(
        bookingMasterCamelizeKeys.packageTypes,
        false,
        draftBooking.exportPackageType
      ),
      ...convertImportExportColumns(draftBooking),
    }

    return importTenantParameters.map((parameter: ParameterItemType) => {
      const columnName = camelize(
        importExportType.id === IMPORT_EXPORT_COLUMN_NAME.import
          ? parameter.columnName
          : parameter.columnName.replace(REGEX_IMPORT, IMPORT_EXPORT_COLUMN_NAME.export)
      )

      if (isSelectType(parameter)) {
        return (
          <SingleForm
            id={columnName}
            name={columnName}
            type="select"
            label={parameter.displayName}
            value={_.get(draftBooking, `${columnName}`)}
            onChange={(value: string | number) =>
              handleUpdateBooking(value, importExportType.id, columnName)
            }
            errorMessage={_.get(error, `${columnName}`)}
          >
            {
              <React.Fragment>
                <option value=""></option>
                {/* @ts-ignore */}
                {(optionMap[columnName] || []).map((option: { value: any; label: any }) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </React.Fragment>
            }
          </SingleForm>
        )
      }
      return (
        <SingleForm
          id={columnName}
          name={columnName}
          label={parameter.displayName}
          value={_.get(draftBooking, `${columnName}`)}
          onChange={(value: string | number) =>
            handleUpdateBooking(
              value,
              importExportType.id,
              columnName,
              getMaxLengthOfInput[parameter.columnName]
            )
          }
          errorMessage={_.get(error, `${columnName}`)}
        />
      )
    })
  }

  return (
    <S.FormBlockWrapper>
      <h3 className="font-weight-bold mb-3">案件名</h3>
      {IMPORT_EXPORT_TYPES.map((item) => {
        return (
          <>
            <div className="input-flex ml-1">
              <SingleForm
                className="label-flex"
                id={`${item.id}Flag`}
                name={`${item.id}Flag`}
                type="checkbox"
                value={
                  _.get(draftBooking, `${item.id}Flag`) === ImportFlagCheck.CHECK ? true : false
                }
                label={item.label}
                onChange={(checked: boolean) => {
                  handleUpdateBooking(
                    checked ? ImportFlagCheck.CHECK : ImportFlagCheck.UNCHECK,
                    item.id,
                    `${item.id}Flag`
                  )
                }}
                isCheckType
                isHightLight
              />
            </div>
            <Collapse
              isOpen={
                _.get(draftBooking, `${item.id}Flag`) === ImportFlagCheck.CHECK ? true : false
              }
            >
              {renderImportExportInput(item)}
            </Collapse>
            {item.id === IMPORT_EXPORT_COLUMN_NAME.import && <S.UnderLine />}
          </>
        )
      })}
      {draftBooking.status !== GeneralBookingStatuses.CHECKIN.id && (
        <div className="d-flex">
          <span className="label-flex font-md">確認なしに確定</span>
          <SingleForm
            className="input-flex ml-1"
            id="need_confirm_flag"
            name="need_confirm_flag"
            type="checkbox"
            label="確認なしに確定"
            value={draftBooking.needConfirmFlag}
            onChange={(value: boolean) => handleInputChange(value, 'needConfirmFlag')}
            isDisabled={openType === BookingSidePanelType.EDIT}
            isCheckType
          />
        </div>
      )}
    </S.FormBlockWrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  bookingMaster: bookingMasterGeneralSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(memo(ImportExportForm))
