import { createActions, handleActions } from 'redux-actions'
import _ from 'lodash'
import { defaultBerthColumnsPerPage, defaultReceptionNotificationSound } from 'constants/Settings'

const initialState = {
  warehouses: [],
  selectedWarehouseId: 0,
  selectedTenantId: 0,
  settings: {
    berthColumnsPerPage: defaultBerthColumnsPerPage,
    receptionNotificationSound: defaultReceptionNotificationSound,
  },
}

export const {
  settings: { fetchWarehouses, fetchSettings, changeWarehouse, changeTenant, saveSettings },
}: any = createActions({
  settings: {
    FETCH_WAREHOUSES: (data: any) => data,
    FETCH_SETTINGS: (data: any) => data,
    CHANGE_WAREHOUSE: (data: any) => data,
    CHANGE_TENANT: (data: any) => data,
    SAVE_SETTINGS: (data: any) => data,
  },
})

export default handleActions(
  {
    'settings/FETCH_SUCCESS': (state: any, { payload }: any) => {
      const { storePath, data }: any = payload
      return {
        ...state,
        [storePath]: data,
      }
    },
    'settings/CHANGE_WAREHOUSE': (state: any, { payload }: any) => {
      const selectedWarehouseId = payload
      const { warehouses } = state
      const warehouse = _.find(warehouses, { id: selectedWarehouseId })
      const firstTenant: any = _.first(warehouse.tenants)
      return {
        ...state,
        selectedWarehouseId,
        selectedTenantId: firstTenant.id,
        tenants: warehouse.tenants,
      }
    },
    'settings/CHANGE_TENANT': (state: any, { payload }: any) => {
      const selectedTenantId = payload
      return {
        ...state,
        selectedTenantId,
      }
    },
    'settings/SAVE_SETTINGS': (state: any, { payload }: any) => {
      const { target, data } = payload
      const { settings } = state
      return {
        ...state,
        settings: {
          ...settings,
          [target]: data,
        },
      }
    },
  },

  initialState
)
