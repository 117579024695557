import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects'
import { camelizeKeys } from 'humps'

import { usersServices, authService, tenantServices } from 'services'
import loginStorage, {
  HISTORY_SEARCH_GENERAL_BOOKING,
  HISTORY_SEARCH_NORMAL_BOOKING,
} from 'utils/loginStorage'
import {
  getUserData,
  getUserDataSuccess,
  getUserDataFailure,
  signOutUserSuccess,
  signOutUserFailure,
} from './actions'
import { GET_USER_DATA, GET_AUTHEN_DATA, SIGN_OUT_USER } from './constants'
import { requestGet } from './api'
import { fetchCustomBerthLabelSucess, fetchBookingRemindStatusSuccess } from './reducers'
import { customBerthlabel } from 'constants/misc'

export function* getAuthenticationWorker({ payload }) {
  try {
    loginStorage.clearAuthData()
    const authenData = loginStorage.encryptData(payload)
    yield call(loginStorage.setAuthData, authenData)
    if (authenData.id) {
      yield put(getUserData())
    }
    window.history.replaceState({}, '', '/')
  } catch (err) {
    if (!loginStorage.getAuthData()) window.location.replace('/login')
  }
}

export function* getUserDataWorker({ payload }) {
  try {
    const [{ data: userData }, { data: receptionData }, { data: userBookingPermission }] =
      yield all([
        call([usersServices, 'getUserData'], payload),
        call([usersServices, 'checkExistsReceptions'], payload),
        call([usersServices, 'checkUserBookingPermission'], payload),
      ])
    yield put(
      getUserDataSuccess({
        userData,
        receptionData,
        userBookingPermission,
      })
    )
  } catch (error) {
    yield put(getUserDataFailure(error))
  }
}

export function* signOutUserWorker() {
  try {
    yield call([authService, 'logout'])

    if (loginStorage.getAuthData()) {
      yield loginStorage.clearAuthData()
    }

    if (localStorage.getItem(HISTORY_SEARCH_NORMAL_BOOKING)) {
      localStorage.removeItem(HISTORY_SEARCH_NORMAL_BOOKING)
    }

    if (localStorage.getItem(HISTORY_SEARCH_GENERAL_BOOKING)) {
      localStorage.removeItem(HISTORY_SEARCH_GENERAL_BOOKING)
    }

    yield put(signOutUserSuccess())
    window.location.href = '/login'
  } catch (error) {
    yield put(signOutUserFailure())
    // yield LoginStorage.clearData()
  }
}

// 汎用 fetch
function* fetchAnything({ payload: { url, storePath, data, key } }) {
  let resp

  yield put({ type: 'base/START_LOADING' })

  try {
    resp = yield call(requestGet, url, data)
  } catch (error) {
    console.error(error)
    yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
    return
  } finally {
    yield put({ type: 'base/STOP_LOADING' })
  }

  const camelizedData = camelizeKeys(resp.data || {})

  yield put({
    type: 'FETCH_SUCCESS',
    payload: { storePath, data: key ? camelizedData[key] : camelizedData },
  })
}

export function* fetchCustomBerthLabelWorker({ payload }) {
  const { tenantId: tenantIdPayload, hasTenant = true } = payload || {}
  try {
    if (hasTenant) {
      const { tenantId: tenantIdLocalStorage } = loginStorage.getWarehouseSession()
      const tenantId = tenantIdPayload || tenantIdLocalStorage

      const req = yield call([tenantServices, 'getCustomBerthLabel'], tenantId)
      const data = camelizeKeys(req?.data || {})
      const { customBerthLabel = customBerthlabel.KEY_LOCAL, bookingRemindStatus } = data || {}

      yield put(fetchCustomBerthLabelSucess(customBerthLabel))
      loginStorage.setCustomBerthLabel(customBerthLabel)
      yield put(fetchBookingRemindStatusSuccess(bookingRemindStatus))
    } else {
      const customBerthLabel = customBerthlabel.LABEL_DEFAUT

      yield put(fetchCustomBerthLabelSucess(customBerthLabel))
      loginStorage.setCustomBerthLabel(customBerthLabel)
    }
  } catch (error) {
    yield put({ type: 'OPEN_ERROR', payload: { message: error?.message } })
  }
}

export default function* () {
  yield takeLatest(GET_AUTHEN_DATA, getAuthenticationWorker)
  yield takeLatest(GET_USER_DATA, getUserDataWorker)
  yield takeLatest(SIGN_OUT_USER, signOutUserWorker)
  yield takeEvery('FETCH_ANYTHING', fetchAnything)
  yield takeEvery('FETCH_CUSTOM_BERTH_LABEL', fetchCustomBerthLabelWorker)
}
