import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import styled from 'styled-components'
import Creatable from 'react-select/creatable'
import Icon from 'components/Icon'
import theme from 'vars/theme'
import _ from 'lodash'
import { ErrorText } from '../SingleForm/style'
import { SelectOptionsWrapper } from './style'

const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ isHightLight }) => (isHightLight ? theme.blueMedium2 : '')};

  img {
    margin-left: 5px;
  }
`

const ACTION_TYPE = {
  INPUT_CHANGE: 'input-change',
  MENU_CLOSE: 'menu-close',
}

export default ({
  placeholder,
  options,
  errorMessage = '',
  required,
  label,
  isHightLight,
  isDisabled,
  isClearable,
  isInputDisplay,
  isDriverInfoCompany,
  labelFontSize,
  value,
  onChange,
  onBlur,
  className,
  height,
  fontSize,
  onInputChange = () => {},
}) => {
  const [inputValue, setInputValue] = useState(value.label)
  const [isInputFocus, setIsInputFocus] = useState(false)

  const creatableRef = useRef(null)

  const handleInputChange = useCallback((value, { action }) => {
    if (action === ACTION_TYPE.INPUT_CHANGE) {
      setInputValue(value)
    }
    if (action === ACTION_TYPE.MENU_CLOSE) {
      const inputRef = _.get(creatableRef, 'current.select.select.inputRef')
      if (inputRef)
        setTimeout(() => {
          inputRef.style.opacity = '1'
        })
    }
  }, [])

  const handleInputProps = useMemo(
    () =>
      isInputDisplay
        ? {
            inputValue,
            isClearable: isClearable && inputValue && isInputFocus,
            onInputChange: handleInputChange,
            onFocus: () => {
              setTimeout(() => {
                setIsInputFocus(true)
              })
            },
            onChange: (option) => {
              const { value, label } = option || { value: '', label: '' }
              setInputValue(label)
              onChange({ value, label })
            },
            onBlur: (e) => {
              onBlur(e)
              setIsInputFocus(false)
            },
          }
        : {},
    [isInputDisplay, inputValue, isClearable, isInputFocus, handleInputChange, onChange, onBlur]
  )

  useEffect(() => {
    if (isInputDisplay) setInputValue(value.label)
  }, [isInputDisplay, value.label])

  return (
    <SelectOptionsWrapper
      className={`${isDriverInfoCompany ? '' : 'd-flex align-items-center'} mb-2`}
      isError={!!errorMessage}
      height={height}
      fontSize={fontSize}
      isClearable={isClearable}
      isInputDisplay={isInputDisplay}
    >
      {label && (
        <Label
          style={{ fontSize: labelFontSize || '14px' }}
          isHightLight={isHightLight}
          className="label-flex"
        >
          {label}
          {required && <span className="color-red-med1 font-weight-bold">*</span>}
          {isHightLight && <Icon src="/assets/svgs/man-user.svg" />}
        </Label>
      )}
      <div className="input-flex flex-column">
        <Creatable
          ref={creatableRef}
          isClearable={isClearable}
          isDisabled={isDisabled}
          autosize={false}
          className={`w-100 ${className}`}
          classNamePrefix="mono-creatable"
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          formatCreateLabel={() => ''}
          noOptionsMessage={() => null}
          blurInputOnSelect={false}
          {...handleInputProps}
        />
        {Boolean(errorMessage) && <ErrorText>{errorMessage}</ErrorText>}
      </div>
    </SelectOptionsWrapper>
  )
}
