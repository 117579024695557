import React from 'react'
import styled from 'styled-components'

import ImportExport from 'containers/HomePage/containers/BerthSchedule/components/BookingItem/ImportExport'
import theme from 'vars/theme'
import { cardLabelPreviewItem, dataSimpleCardItem } from '../constants'
import Icon from 'components/Icon'

const CartItemWrapper = styled.div`
  width: 200px;
  color: ${theme.whitePrimary};
  background-color: ${theme.redPrimary1};
  border-radius: 4px;
  padding: 5px;
  font-size: 11px;
  overflow: hidden;
  position: relative;

  span.text-item {
    font-size: 13px;
  }
`

const BookingItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
  width: 100%;
`

const BookingItem = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  flex: 1;
  text-align: start;
  img {
    margin-right: 2px;
  }

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-clamp: 1;
    box-orient: vertical;
  }
`

const BookingTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 600;
  overflow: hidden;
`

const Button = styled.button`
  border-radius: 11px;
  font-size: 10px;
  min-width: 22px;
  padding: 0 5px;
  color: ${theme.whitePrimary};
  background-color: ${theme.greenMedium2};
  border: 1px solid ${theme.whitePrimary};
  margin: 0 7px;
  pointer-events: none;
`

const StatusName = styled.span`
  margin-right: 20px;
`

interface CardItems {
  order: number
  label: string
  icon?: string
}

interface CardItemsFormat {
  item1?: CardItems
  item2?: CardItems
  item3?: CardItems
  item4?: CardItems
  item5?: CardItems
  item6?: CardItems
}

type Props = {
  data?: CardItems[]
}

const CardItemPreview = (props: Props) => {
  const { data } = props
  const dataFormated: CardItemsFormat = (data || dataSimpleCardItem).reduce((arr, item) => {
    return (arr = {
      ...arr,
      [`item${item.order}`]: {
        ...item,
        label: cardLabelPreviewItem[item.label] || item.label,
      },
    })
  }, {})

  return (
    <CartItemWrapper>
      <BookingTitle>
        <StatusName>受付済</StatusName>
        <ImportExport
          importFlag={false}
          exportFlag={false}
          color={theme.whitePrimary}
          bgColor={theme.redPrimary1}
        />
        <Icon src="/assets/svgs/blank_booking_icon.svg" width={22} height={22} />
        <Button>呼出</Button>
      </BookingTitle>
      <div>
        <BookingItemWrapper>
          {dataFormated?.item1 && (
            <BookingItem>
              <span className="text-item">{dataFormated?.item1?.label}</span>
            </BookingItem>
          )}
          {dataFormated?.item4 && (
            <>
              {dataFormated?.item1 && (
                <Icon src="/assets/svgs/strike-through.svg" width={10} height={30} />
              )}
              <BookingItem>
                <span className="text-item">{dataFormated?.item4?.label}</span>
              </BookingItem>
            </>
          )}
        </BookingItemWrapper>
        <BookingItemWrapper>
          {dataFormated?.item2 && (
            <BookingItem>
              <span className="text-item">{dataFormated?.item2?.label}</span>
            </BookingItem>
          )}
          {dataFormated?.item5 && (
            <>
              {dataFormated?.item2 && (
                <Icon src="/assets/svgs/strike-through.svg" width={10} height={30} />
              )}
              <BookingItem>
                <span className="text-item">{dataFormated?.item5?.label}</span>
              </BookingItem>
            </>
          )}
        </BookingItemWrapper>
        <BookingItemWrapper>
          {dataFormated?.item3 && (
            <BookingItem>
              <span className="text-item">{dataFormated?.item3?.label}</span>
            </BookingItem>
          )}
          {dataFormated?.item6 && (
            <>
              {dataFormated?.item3 && (
                <Icon src="/assets/svgs/strike-through.svg" width={10} height={30} />
              )}
              <BookingItem>
                <span className="text-item">{dataFormated?.item6?.label}</span>
              </BookingItem>
            </>
          )}
        </BookingItemWrapper>
      </div>
    </CartItemWrapper>
  )
}

export default CardItemPreview
