import RestClient, { getAuthHeader } from './RestClient'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})

export default class SettingsService {
  constructor(restClient = RestClient) {
    this.restClient = restClient
  }

  getMainSettings = ({ page }) =>
    this.restClient.get(
      `main_settings?page=${page}`,
      {},
      {
        headers: headers(),
      }
    )

  getListDepartment = (companyId) =>
    this.restClient.get(
      `choose_company?company_id=${companyId}`,
      {},
      {
        headers: headers(),
      }
    )

  getListSalesOffice = (departmentId) =>
    this.restClient.get(
      `choose_department?department_id=${departmentId}`,
      {},
      {
        headers: headers(),
      }
    )

  filterTenant = ({ companyId, departmentId, salesOfficeId, warehouseName, page }) =>
    this.restClient.get(
      `search?q[company_id_eq]=${companyId}&q[department_id_eq]=${departmentId}&q[sales_office_id_eq]=${salesOfficeId}&q[name_cont]=${warehouseName}&page=${page}`,
      {},
      {
        headers: headers(),
      }
    )

  updatePassword = ({ tenant_id, password }) =>
    this.restClient.put({
      url: 'main_setting/update_password',
      data: { tenant_id, password },
      config: {
        headers: headers(),
      },
    })

  updateTimeWidth = ({ tenant_id, time_width }) =>
    this.restClient.put({
      url: 'main_setting/update_time_width',
      data: { tenant_id, time_width },
      config: {
        headers: headers(),
      },
    })
}
