import React, { useCallback, FC, useState, memo } from 'react'
import { Button } from 'reactstrap'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import Header from '../components/Header'
import DriverConfirmations from 'containers/IntegratedReceptionCustom/components/DriverConfirmations'
import PrevStartButton from '../components/PrevStartButton'
import PageNumber from '../components/PageNumber'
import { INTEGRATED_RECEPTION_URL_BASE, ImportFlagCheck } from '../constants'
import { useDispatch } from 'reduxStoreHelper'
import { resetAllData, startLoading, endLoading } from '../reducer'
import { ConfirmationType } from 'containers/IntegratedReceptionCustom/type'
import { driverConfirmationsSelectors, bookingSelectors } from '../selectors'
import { postGeneralBookingCheckin, updateGeneralBookingCheckinWithBookingNo } from '../api'
import { GeneralBookingType } from '../type'
import { trimData } from 'utils/commonFunctions'
import { openError } from 'containers/App/reducers'
import * as S from '../style'

type Props = {
  canGeneralBooking?: boolean
  driverConfirmations?: ConfirmationType[]
  booking: GeneralBookingType
  isFromBookingNo: boolean
}

export const buildBooking = (
  booking: GeneralBookingType,
  generalReceptionNumber: Number,
  driverPhone = ''
) => {
  // import/export のチェックがついてない場合は、いっさい送らない
  const isImport = booking.importFlag === ImportFlagCheck.CHECK
  const isExport = booking.exportFlag === ImportFlagCheck.CHECK

  const filteredBooking = Object.keys(booking).reduce((ac, key) => {
    if (
      ['importFlag', 'exportFlag'].includes(key) ||
      !key.match(/^(import|export)/) ||
      (isImport && key.match(/^import/)) ||
      (isExport && key.match(/^export/))
    ) {
      return ac
    }

    return {
      ...ac,
      [key]: '',
    }
  }, booking)

  return {
    ...filteredBooking,
    generalReceptionNumber,
    driverPhone,
    attachments: [],
  }
}

const EntryConfirmationPage: FC<Props> = ({
  canGeneralBooking,
  driverConfirmations,
  booking,
  isFromBookingNo,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()
  const [confirmedIds, setConfirmedIds] = useState<any>([])

  const requiredConfirmations = driverConfirmations?.filter(
    (confirmation) => confirmation.checkFlag
  )

  const filteredConfirmations = driverConfirmations?.filter(
    (confirmation: ConfirmationType) => confirmation?.displayFlag
  )

  const handleClickButtonExit = useCallback(() => {
    dispatch(resetAllData())
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
  }, [dispatch, generalReceptionNumber, history])

  const onChangeCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    confirmation: ConfirmationType
  ) => {
    const { checked } = e.target || {}
    const newConfirmedIds = checked
      ? confirmedIds?.concat(confirmation?.id || '')
      : confirmedIds?.filter((confirmedId: string | number) => confirmedId !== confirmation.id)
    setConfirmedIds(newConfirmedIds)
  }

  const onClickCheck = (confirmation: ConfirmationType) => {
    if (confirmation?.checkFlag) {
      const isUnCheck = confirmedIds?.includes(confirmation?.id)
      const newConfirmedIds = isUnCheck
        ? confirmedIds?.filter((confirmedId: string | number) => confirmedId !== confirmation?.id)
        : confirmedIds?.concat(confirmation?.id)
      setConfirmedIds(newConfirmedIds)
    }
  }

  const onClickConfirm = async () => {
    const data = buildBooking(
      { ...booking, confirmationIds: confirmedIds },
      +generalReceptionNumber,
      booking.driverPhone
    )
    const bookingFormat = trimData(data)

    dispatch(startLoading())
    try {
      const resp = isFromBookingNo
        ? await updateGeneralBookingCheckinWithBookingNo(bookingFormat)
        : await postGeneralBookingCheckin(bookingFormat)
      history.push({
        pathname: `${INTEGRATED_RECEPTION_URL_BASE}/entry-finish/${generalReceptionNumber}`,
        state: {
          portalFlag: resp?.data?.portalFlag,
          lineFlag: resp?.data?.lineFlag,
        },
      })
    } catch (error) {
      //@ts-ignore
      dispatch(openError(error.message))
    } finally {
      dispatch(endLoading())
    }
  }

  return (
    <S.ConfirmationWrapper>
      <Header />
      <div className="p-3">
        <div className="bg-white mb-3 p-3" style={{ borderRadius: 12 }}>
          <DriverConfirmations
            isCheckinPage
            confirmations={driverConfirmations}
            confirmedIds={confirmedIds}
            onChangeCheck={onChangeCheck}
            onClickCheck={onClickCheck}
          />
        </div>

        <div className="d-flex justify-content-between px-3 pb-3">
          <div style={{ width: '33%' }}>
            <PrevStartButton onClick={handleClickButtonExit} />
          </div>
          <div className="text-center" style={{ width: '34%' }}>
            <PageNumber
              page="entry-confirmation"
              existsDriverConfirmations={Boolean(filteredConfirmations?.length)}
              isFreePlan={!canGeneralBooking}
            />
          </div>
          <div className="text-right" style={{ width: '33%' }}>
            <Button
              outline
              color="primary"
              className="mr-3 rounded-pill bg-white px-4 text-primary"
              onClick={() =>
                history.push(
                  `${INTEGRATED_RECEPTION_URL_BASE}/entry-load/${generalReceptionNumber}`
                )
              }
            >
              もどる
            </Button>
            <Button
              color="primary"
              className="rounded-pill px-4"
              onClick={onClickConfirm}
              disabled={confirmedIds.length !== requiredConfirmations?.length}
            >
              確認して受付完了
            </Button>
          </div>
        </div>
      </div>
    </S.ConfirmationWrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  driverConfirmations: driverConfirmationsSelectors(),
  booking: bookingSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(memo(EntryConfirmationPage))
