import React, { useState } from 'react'
import _ from 'lodash'
import * as S from './style'
import CreatableSelect from 'components/SelectOptions/CreatableSelect'
import SingleForm from 'components/SingleForm'
import {
  InputSelect,
  GeneralBooking,
  DriversControllers,
  DriversControllersMaster,
  BookingError,
  VehicleControllerInfo,
} from 'containers/IntegratedManagementPage/type'
import { convertPhoneNumber, compareValue } from 'utils/commonFunctions'
import { UserDataType } from 'utils/types'

const defaultOptions: InputSelect[] = [
  {
    value: '',
    label: '未定',
  },
]

type Props = {
  userData: UserDataType
  defaultCompanyList: InputSelect[]
  draftBooking: GeneralBooking
  remoteController: DriversControllersMaster
  remoteControllerByEmail: VehicleControllerInfo
  isExistController: Boolean
  initialBooking: GeneralBooking
  error: BookingError
  disabledEdit: boolean
  onChange: (value: string | number, name: string) => void
  handleChangeDraftBooking: (data: object) => void
  handleOnBlurControllerEmail: (e: any) => void
  setIsFocusEmailController: (value: boolean) => void
  resetRemoteControllers: () => void
}

const ControllerInfoForm: React.FC<Props> = ({
  userData,
  defaultCompanyList,
  draftBooking,
  initialBooking,
  remoteController,
  remoteControllerByEmail,
  isExistController,
  error,
  disabledEdit,
  onChange,
  handleChangeDraftBooking,
  handleOnBlurControllerEmail,
  setIsFocusEmailController,
  resetRemoteControllers,
}) => {
  const [isChangeCompany, setIsChangeCompany] = useState(false)
  const getCompanyOptions = (): InputSelect[] => {
    const { companyId } = initialBooking
    const remoteCompanyOption: any[] =
      !_.isEmpty(remoteControllerByEmail) &&
      !compareValue(companyId, remoteControllerByEmail.companyId) &&
      defaultCompanyList.every(
        (item: any) => !compareValue(item.value, remoteControllerByEmail.companyId)
      )
        ? [{ value: remoteControllerByEmail.companyId, label: remoteControllerByEmail.companyName }]
        : []
    return defaultCompanyList.concat(remoteCompanyOption)
  }

  const getVehicleControllerOptions = (): InputSelect[] => {
    const masterCompany = _.get(remoteController, 'driversControllers.data', [])
    const masterCompanyOptions = masterCompany.map((driver: any) => ({
      value: driver.id,
      label: _.get(driver, 'attributes.name'),
    }))
    const allOptionsFormat = _.uniqBy(
      masterCompanyOptions.filter((it: any) => {
        return isChangeCompany && isExistController
          ? it.value && !compareValue(it.value, userData?.id)
          : it.value
      }),
      (it: any) => it.value.toString()
    )

    const remoteControllerByEmailOption: any[] =
      !_.isEmpty(remoteControllerByEmail) &&
      allOptionsFormat.every(
        (item: any) => !compareValue(item.value, remoteControllerByEmail.vehicleControllerId)
      )
        ? [
            {
              value: remoteControllerByEmail.vehicleControllerId,
              label: remoteControllerByEmail.vehicleControllerName,
            },
          ]
        : []
    let defaultOptionWithUpdateBooking =
      !isChangeCompany &&
      compareValue(initialBooking?.companyId, draftBooking?.companyId) &&
      initialBooking?.vehicleControllerId
        ? [
            {
              value: initialBooking.vehicleControllerId,
              label: initialBooking.vehicleControllerName,
            },
          ]
        : []

    const dataVehicleControllerOptions = defaultOptions
      .concat(remoteControllerByEmailOption)
      .concat(allOptionsFormat)
      .concat(defaultOptionWithUpdateBooking)

    return _.uniqBy(dataVehicleControllerOptions, (it) => it?.value?.toString())
  }

  const disableController = () => {
    const { vehicleControllerId } = draftBooking
    const isExisted = _.some(_.get(remoteController, 'driversControllers.data', []), [
      'id',
      +vehicleControllerId,
    ])
    const sameVehicleController = _.isEmpty(remoteControllerByEmail)
      ? false
      : compareValue(vehicleControllerId, remoteControllerByEmail.vehicleControllerId)
    if (vehicleControllerId && !isExisted && !sameVehicleController) return false
    return true
  }

  const handleChangePhoneNumber = (val: string) => {
    if (typeof convertPhoneNumber(val) !== 'boolean') {
      handleChangeDraftBooking({
        vehicleControllerPhone: convertPhoneNumber(val),
      })
    }
  }

  const handleChangeVehicleController = (val: InputSelect) => {
    const driverControllers: DriversControllers[] = _.get(
      remoteController,
      'driversControllers.data',
      []
    )
    const isExistVehicleControllerId = _.some(driverControllers, ['id', +val.value])
    const isSameVehicleController = _.isEmpty(remoteControllerByEmail)
      ? false
      : compareValue(val.value, remoteControllerByEmail.vehicleControllerId)

    if (isExistVehicleControllerId) {
      const currentController = _.find(driverControllers, {
        id: Number(val.value),
      })
      handleChangeDraftBooking({
        vehicleControllerId: val.value,
        vehicleControllerName: currentController?.attributes.name || '',
        vehicleControllerPhone: currentController?.attributes.telNumber || '',
        vehicleControllerEmail: currentController?.attributes.email || '',
      })
    } else if (isSameVehicleController) {
      handleChangeDraftBooking({
        vehicleControllerId: val.value,
        vehicleControllerName: remoteControllerByEmail.vehicleControllerName || '',
        vehicleControllerPhone: remoteControllerByEmail.vehicleControllerPhone || '',
        vehicleControllerEmail: remoteControllerByEmail.vehicleControllerEmail || '',
      })
    } else if (val.value) {
      handleChangeDraftBooking({
        vehicleControllerId: val.value,
        vehicleControllerName: val.label,
        vehicleControllerPhone: '',
        vehicleControllerEmail: '',
      })
    } else {
      onChange(val.value, 'vehicleControllerId')
    }
  }

  const handleOnChangeControllerEmail = (value: string | number): void => {
    onChange(value, 'vehicleControllerEmail')
    setIsFocusEmailController(true)
  }

  const onCompanyIdChange = (val: InputSelect) => {
    const companyName = val.value && val.label ? val.label : ''
    const isChangeIdCompany = !val.value || Number(val.value) !== Number(draftBooking.companyId)
    const resetValue = isChangeIdCompany
      ? {
          vehicleControllerId: '',
          vehicleControllerName: '',
          vehicleControllerPhone: '',
          vehicleControllerEmail: '',
        }
      : {}
    handleChangeDraftBooking({
      companyId: val.value,
      companyName,
      ...resetValue,
    })

    if (isChangeIdCompany) {
      resetRemoteControllers()
      setIsChangeCompany(true)
    }
  }

  return (
    <S.FormBlockWrapper>
      <h3 className="font-weight-bold mb-3">配車担当者情報</h3>
      <CreatableSelect
        label="会社名"
        options={getCompanyOptions()}
        onChange={onCompanyIdChange}
        value={draftBooking.companyId}
        isUnique
        notShowNewOption
        errorMessage={error.companyId || error.companyName}
        isDisabled={disabledEdit}
      />
      <CreatableSelect
        label="配車担当者名"
        options={getVehicleControllerOptions()}
        onChange={handleChangeVehicleController}
        value={draftBooking.vehicleControllerId}
        maxLength={50}
        isHightLight
        notShowNewOption
        isDisabled={!draftBooking.companyId || disabledEdit}
        errorMessage={error.vehicleControllerId || error.vehicleControllerName}
      />
      <SingleForm
        id="vehicle_controller_phone"
        name="vehicle_controller_phone"
        label="電話番号"
        onChange={handleChangePhoneNumber}
        value={draftBooking.vehicleControllerPhone}
        isDisabled={disableController() || disabledEdit}
        errorMessage={error.vehicleControllerPhone}
      />
      <SingleForm
        id="vehicle_controller_email"
        name="vehicle_controller_email"
        label="メールアドレス"
        onChange={handleOnChangeControllerEmail}
        value={draftBooking.vehicleControllerEmail}
        autoComplete="off"
        required
        isDisabled={disableController() || disabledEdit}
        onBlur={handleOnBlurControllerEmail}
        errorMessage={error.vehicleControllerEmail}
      />
    </S.FormBlockWrapper>
  )
}

export default ControllerInfoForm
