import _isEmpty from 'lodash/isEmpty'
import { call, put, takeLatest, all, select } from 'redux-saga/effects'
import moment from 'moment'
import _get from 'lodash/get'

import {
  fetchFailed,
  startLoading,
  fetchSuccess,
  endLoading,
  setIsLoadingGetRoleTenant,
  updateBookingListCardStatusSuccess,
  updateLastUpdateTime,
  openConfirmModalReload,
} from './reducer'

import { DAY_FORMAT, ORDER, context, STANDARD_DAY_FORMAT, FIRST_PAGE } from './constants'

import { cardListService } from 'services'
import {
  orderBySelectors,
  orderSelectors,
  paginationSelectors,
  selectedDaySelectors,
  tenantIdSelectors,
} from './selectors'
import { ParameterConfirmBookingWorkerType, ParameterType } from './type'

function* fetchWarehouseListWorker({ payload }: { type: string; payload: any }): Generator<{}> {
  const { callback } = payload
  try {
    yield put(startLoading())
    const res = yield call([cardListService, 'getWarehouseList'], '20231212')
    // @ts-ignore
    const warehouseList = res?.data

    if (_isEmpty(warehouseList)) {
      callback()
    }

    yield put(
      fetchSuccess({
        key: 'warehouseList',
        data: warehouseList,
      })
    )
    yield put(endLoading())
  } catch (error) {
    yield put(fetchFailed(error))
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchListCardBookingWorker(): Generator<any, void, string> {
  try {
    yield put(startLoading())
    const tenantId = yield select(tenantIdSelectors())
    const selectedDay: any = yield select(selectedDaySelectors())
    const orders = yield select(orderSelectors())
    const orderBy = yield select(orderBySelectors())
    const pagination: any = yield select(paginationSelectors())
    const dataSort = orders === ORDER.DEFAULT ? ORDER.DEFAULT : `${orderBy},${orders}`

    const data = {
      tenant_id: tenantId,
      start_date: moment(selectedDay.startDate).format(DAY_FORMAT),
      end_date: moment(selectedDay.endDate).format(DAY_FORMAT),
      page: FIRST_PAGE,
      per_page: pagination.perPageItem,
      sort: dataSort,
    } as ParameterType

    // @ts-ignore
    const { listCardReceptionResult, bookingMasterResult, berthsListCardBookingsResult } =
      yield all({
        listCardReceptionResult: call([cardListService, 'getListCardReception'], tenantId),
        bookingMasterResult: call([cardListService, 'getBookingMasterData'], tenantId),
        berthsListCardBookingsResult: call([cardListService, 'getBerthsListCardBookings'], data),
      })

    const listCardReception = _get(listCardReceptionResult, 'data.list_card_parameters', [])
    const listBookingMaster = _get(bookingMasterResult, 'data', [])
    const listBerthsListCardBookings = _get(berthsListCardBookingsResult, 'data', [])
    const { total, per_page, total_page, page } = berthsListCardBookingsResult || {}

    yield put(
      fetchSuccess({
        key: 'listCardReception',
        data: listCardReception,
      })
    )
    yield put(
      fetchSuccess({
        key: 'bookingMaster',
        data: listBookingMaster,
      })
    )
    yield put(
      fetchSuccess({
        key: 'listCards',
        data: listBerthsListCardBookings,
      })
    )
    yield put(
      fetchSuccess({
        key: 'pagination',
        data: {
          totalPages: total_page,
          currentPage: page + 1,
          totalElementsInPage: total,
          perPageItem: per_page,
        },
      })
    )

    yield put(updateLastUpdateTime())
    yield put(endLoading())
  } catch (error) {
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message },
    })
  }
}

function* fetchListCardBookingOtherWorker(): Generator<any, void, string> {
  try {
    yield put(startLoading())
    const tenantId = yield select(tenantIdSelectors())
    const selectedDay: any = yield select(selectedDaySelectors())
    const orders = yield select(orderSelectors())
    const orderBy = yield select(orderBySelectors())
    const pagination: any = yield select(paginationSelectors())
    const dataSort = orders === ORDER.DEFAULT ? ORDER.DEFAULT : `${orderBy},${orders}`

    const data = {
      tenant_id: tenantId,
      start_date: moment(selectedDay.startDate).format(DAY_FORMAT),
      end_date: moment(selectedDay.endDate).format(DAY_FORMAT),
      page: FIRST_PAGE,
      per_page: pagination.perPageItem,
      sort: dataSort,
    } as ParameterType

    const berthsListCardBookingsResult = yield call(
      [cardListService, 'getBerthsListCardBookings'],
      data
    )

    const listBerthsListCardBookings = _get(berthsListCardBookingsResult, 'data', [])
    const { total, per_page, total_page, page }: any = berthsListCardBookingsResult || {}

    yield put(
      fetchSuccess({
        key: 'listCards',
        data: listBerthsListCardBookings,
      })
    )

    yield put(updateLastUpdateTime())

    yield put(
      fetchSuccess({
        key: 'pagination',
        data: {
          totalPages: total_page,
          currentPage: page + 1,
          totalElementsInPage: total,
          perPageItem: per_page,
        },
      })
    )

    yield put(endLoading())
  } catch (error) {
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message },
    })
  }
}

function* getRoleTenantWorker() {
  const tenant_id = yield select(tenantIdSelectors())
  try {
    yield put(setIsLoadingGetRoleTenant(true))
    const { data } = yield call([cardListService, 'getBerthPermission'], tenant_id)
    yield put(
      fetchSuccess({
        key: 'roleTenant',
        data,
      })
    )
  } catch (error) {
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  } finally {
    yield put(setIsLoadingGetRoleTenant(false))
  }
}

function* confirmBookingWorker({
  payload,
}: {
  type: string
  payload: ParameterConfirmBookingWorkerType
}): Generator<{}> {
  try {
    yield put(startLoading())
    const { booking_id, tenant_id, repeat_id, start_date, start_time, end_date, end_time } = payload
    const dataUpdateBooking =
      booking_id && tenant_id
        ? {
            booking_id,
            start_date: `${moment(start_date, DAY_FORMAT).format(
              STANDARD_DAY_FORMAT
            )} ${start_time}`,
            end_date: `${moment(end_date, DAY_FORMAT).format(STANDARD_DAY_FORMAT)} ${end_time}`,
          }
        : {
            booking_id,
            repeat_id,
            start_date: `${moment(start_date, DAY_FORMAT).format(
              STANDARD_DAY_FORMAT
            )} ${start_time}`,
            end_date: `${moment(end_date, DAY_FORMAT).format(STANDARD_DAY_FORMAT)} ${end_time}`,
          }
    const updateData = {
      tenant_id,
      bookings: [dataUpdateBooking],
    } as ParameterConfirmBookingWorkerType

    const res = yield call([cardListService, 'confirmBookingListCard'], updateData)

    // @ts-ignore
    // get one item in data res
    const updateListCardStatusData = _get(res?.data, '[0]', {})
    yield put(updateBookingListCardStatusSuccess(updateListCardStatusData))

    yield put(endLoading())
  } catch (error) {
    yield put(startLoading())
    yield put(openConfirmModalReload({ message: error.message || '通信エラーが発生しま' }))
    yield put(endLoading())
  }
}

function* fetchCardBookingsPagination({
  payload,
}: {
  type: string
  payload: { currentPage: number; perPageItem: number }
}): Generator<{}> {
  const { currentPage, perPageItem } = payload
  try {
    yield put(startLoading())
    const tenantId = yield select(tenantIdSelectors())
    const selectedDay: any = yield select(selectedDaySelectors())
    const orders = yield select(orderSelectors())
    const orderBy = yield select(orderBySelectors())
    const dataSort = orders === ORDER.DEFAULT ? ORDER.DEFAULT : `${orderBy},${orders}`

    const data = {
      tenant_id: tenantId,
      start_date: moment(selectedDay.startDate).format(DAY_FORMAT),
      end_date: moment(selectedDay.endDate).format(DAY_FORMAT),
      page: currentPage - 1,
      per_page: perPageItem,
      sort: dataSort,
    } as ParameterType

    const berthsListCardBookingsResult = yield call(
      [cardListService, 'getBerthsListCardBookings'],
      data
    )

    const listBerthsListCardBookings = _get(berthsListCardBookingsResult, 'data', [])
    const { total, total_page, per_page }: any = berthsListCardBookingsResult || {}

    yield put(
      fetchSuccess({
        key: 'listCards',
        data: listBerthsListCardBookings,
      })
    )

    yield put(
      fetchSuccess({
        key: 'pagination',
        data: {
          totalPages: total_page,
          currentPage: currentPage,
          totalElementsInPage: total || 0,
          perPageItem: per_page,
        },
      })
    )

    yield put(updateLastUpdateTime())
    yield put(endLoading())
  } catch (error) {
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message },
    })
  }
}

export default function* () {
  yield takeLatest(`${context}/FETCH_WAREHOUSE_LIST`, fetchWarehouseListWorker)
  yield takeLatest(`${context}/FETCH_LIST_CARD_BOOKING_WORKER`, fetchListCardBookingWorker)
  yield takeLatest(`${context}/FETCH_ROLE_TENANT`, getRoleTenantWorker)
  yield takeLatest(
    `${context}/FETCH_LIST_CARD_BOOKING_OTHER_WORKER`,
    fetchListCardBookingOtherWorker
  )
  yield takeLatest(`${context}/UPDATE_BOOKING_LIST_CARD_STATUS`, confirmBookingWorker)
  yield takeLatest(`${context}/FETCH_CARD_BOOKINGS_PAGINATION`, fetchCardBookingsPagination)
}
