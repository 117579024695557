import styled from 'styled-components'

import theme from 'vars/theme'
import Button from '../Button'

const CustomButton = styled(Button)`
  background-color: white;
  color: ${theme.blueMedium5};
  margin-left: 15px;
  border: none;
  border-radius: 68px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    background-color: white;
    color: ${theme.blueMedium5};
    box-shadow: 0px 2px 8px rgba(17, 32, 64, 0.25);
  }
`

export default CustomButton
