import { createSelector } from 'reselect'

import { context } from './constants'

const integratedReceptionSelector = (state: any) => state.get(context)

export const loadingSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('loading'))

export const driverSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('driver'))

export const isReturnEntryPageSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('isReturnEntryPage'))

export const phoneNumberEnteredSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('phoneNumberEntered'))

export const isPhoneCheckedSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('isPhoneChecked'))

export const policyDataSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('policyData'))

export const bookingSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('booking'))

export const isFromBookingNoSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('isFromBookingNo'))

export const bookingFromBookingNoSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('bookingFromBookingNo'))

export const masterDataSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('masterData'))

export const isCreatedBookingDataSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('isCreatedBookingData'))

export const listCompaniesSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('listCompanies'))

export const recentBookingSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('recentBooking'))

export const selectedBookingSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('selectedBooking'))

export const isFromListCheckinSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('isFromListCheckin'))

export const generalReceptionDetailSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('generalReceptionDetail'))

export const isAcceptPolicyChangeDriverNameSelectors = () =>
  createSelector(integratedReceptionSelector, (state) =>
    state.get('isAcceptPolicyChangeDriverName')
  )

export const driverConfirmationsSelectors = () =>
  createSelector(integratedReceptionSelector, (state) => state.get('driverConfirmations'))
