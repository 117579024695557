import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import { UserDataType, WarehouseType, TenantType, WaitingSettingType } from 'utils/types'
import { Button } from 'reactstrap'
import Switch from 'components/Switch'
import * as S from './style'
import { openError, openToast, openModal, fetchCustomBerthLabel } from 'containers/App/reducers'

import {
  changeWarehouse,
  changeTenant,
  fetchReceptionParameters,
  updateWaitingSetting,
} from '../reducers'
import Tabs from '../components/Tabs'
import FinishToast from '../components/FinishToast'
import CanNotUse from '../components/CanNotUse'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import Header from '../components/Header'
import CanNotUpdate from '../components/CanNotUpdate'
import {
  warehouseIdSelectors,
  tenantIdSelectors,
  warehousesSelectors,
  tenantsSelectors,
  waitingSettingSelectors,
  initialWaitingSettingSelectors,
} from '../selectors'
import { userDataSelectors } from 'containers/App/selectors'
import { Prompt } from 'react-router-dom'
import { receptionCustomService } from 'services'
import { TAB_MODE, COUNT_TRUCK_SETTING_MODE } from '../constants'
import { TIME_SETTING_WAITING_MODE } from 'constants/misc'

type Props = {
  warehouseId: number
  tenantId: number
  warehouses: WarehouseType[]
  tenants: TenantType[]
  userData: UserDataType
  waitingSetting: WaitingSettingType
  initialWaitingSetting: WaitingSettingType
  fetchReceptionParameters: (param: string) => void
  changeWarehouse: (warehouseId: number) => void
  changeTenant: (tenantId: number) => void
  updateWaitingSetting: (param: any) => void
  fetchCustomBerthLabel: (data: any) => void
  openError: (message: string) => void
  openToast: (param: any) => void
  openModal: (param: any) => void
}

type OptionsType = {
  value: number
}

enum WattingFlagType {
  WAITING_FLAG = 'waitingFlag',
  WAITING_TIME_FLAG = 'waitingTimeFlag',
  PORTAL_FLAG = 'portalFlag',
}

const WaitingPage: React.FC<Props> = ({
  warehouseId,
  tenantId,
  warehouses,
  tenants,
  userData,
  waitingSetting,
  initialWaitingSetting,
  fetchReceptionParameters,
  changeTenant,
  changeWarehouse,
  updateWaitingSetting,
  fetchCustomBerthLabel,
  openError,
  openToast,
  openModal,
}) => {
  const history = useHistory()
  const {
    timeMin,
    timeMax,
    timeAverage,
    type,
    waitingFlag,
    waitingTimeFlag,
    portalFlag,
    countType,
  } = waitingSetting

  const warehouseOptions = warehouses.map((warehouse) => ({
    value: warehouse.id,
    label: warehouse.name,
  }))

  const tenantOptions = tenants.map((tenant) => ({
    value: tenant.id,
    label: tenant.name,
  }))

  const onChangeWarehouse = ({ value: warehouseId }: OptionsType) => {
    const selectedWarehouse = warehouses.find((warehouse) => warehouse.id === warehouseId)
    const tenantId = _.first(selectedWarehouse?.tenants as any[])?.id

    changeWarehouse(warehouseId)
    changeTenant(tenantId)
    fetchReceptionParameters(TAB_MODE.WAITING_SETTING)
    fetchCustomBerthLabel({ tenantId })
  }

  const onChangeTenant = ({ value: tenantId }: OptionsType) => {
    changeTenant(tenantId)
    fetchReceptionParameters(TAB_MODE.WAITING_SETTING)
    fetchCustomBerthLabel({ tenantId })
  }

  const handleSubmit = async () => {
    const data = {
      tenantId,
      warehouseId,
      timeMin,
      timeMax,
      timeAverage,
      type,
      waitingFlag,
      waitingTimeFlag,
      countType,
      portalFlag,
    }

    try {
      await receptionCustomService.createWaitingSetting(data)
    } catch (error) {
      openError(_.get(error, 'data.errors[0].message'))
      return
    }
    fetchReceptionParameters(TAB_MODE.WAITING_SETTING)

    if (!type && waitingTimeFlag) {
      openModal({
        content: (
          <>
            <div>待ち時間の時間設定を行なってください</div>
            <S.RedText>※1台あたりの待機時間が未設定のため待ち時間は表示されません</S.RedText>
          </>
        ),
        buttons: [
          {
            label: 'とじる',
            color: 'primary',
          },
        ],
      })
    } else {
      openToast(FinishToast)
    }
  }

  const selectedTenant = tenants.find((t) => t.id === tenantId) || ({} as TenantType)
  const isSystemAdmin = userData?.attributes?.is_warehouse_admin
  const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

  const getTimeWaitingText = () => {
    if (!type) return '1台の待ち時間：未設定'
    return type === TIME_SETTING_WAITING_MODE.AVERAGE
      ? `1台の待ち時間：約 ${timeAverage}分`
      : `1台の待ち時間：${timeMin}分 ~ ${timeMax}分`
  }

  const getCountTruckText = () => {
    if (!countType || countType === COUNT_TRUCK_SETTING_MODE.TRUCK_CHECKED_BUT_NOT_IN_BERTH)
      return '集計方法：未割当の受付済'
    return `集計方法：すべての受付済`
  }

  useEffect(() => {
    fetchReceptionParameters(TAB_MODE.WAITING_SETTING)
  }, [fetchReceptionParameters])

  const handleUpdateWatingTime = (name: WattingFlagType, value: boolean) => {
    if (
      (name === WattingFlagType.WAITING_TIME_FLAG && !value && !waitingFlag) ||
      (name === WattingFlagType.WAITING_FLAG && !value && !waitingTimeFlag)
    ) {
      updateWaitingSetting({
        portalFlag: false,
      })
    }

    updateWaitingSetting({
      [name]: value,
    })
  }

  return (
    <div>
      <Header
        warehouses={warehouseOptions}
        tenants={tenantOptions}
        selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
        selectedTenant={tenantOptions.find((t) => t.value === tenantId)}
        onChangeWarehouse={onChangeWarehouse}
        onChangeTenant={onChangeTenant}
      />
      {!selectedTenant.canCustomizeReception ? (
        <CanNotUse />
      ) : (
        <S.TimeSettingWrapper>
          <S.SettingContent>
            <div className="font-weight-bold mb-3">受付カスタマイズ</div>
            {!canUpdate && <CanNotUpdate />}
            <div className="border px-3 pb-5 mx-auto mb-3 bg-white">
              <Tabs currentTabId="waiting-booking" />
              <div className="mb-3 text-center">
                入退場を選択する画面に表示する項目の表示／非表示を選択できます。
              </div>
              <div className="m-3">
                <div className="mb-3" style={{ overflowY: 'auto' }}>
                  <table className="w-100">
                    <colgroup>
                      <col />
                      <col style={{ width: 130 }} />
                      <col style={{ width: 240 }} />
                      <col style={{ width: 130 }} />
                    </colgroup>
                    <thead
                      className="border-bottom"
                      style={{ backgroundColor: '#f0f0f0', zIndex: 2 }}
                    >
                      <tr>
                        <th className="pl-3 py-3">項目名</th>
                        <th className="text-center py-3" />
                        <th className="text-center py-3" />
                        <th className="text-center py-3 pr-3">表示／非表示</th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      <tr className="border-bottom" style={{ height: 48 }}>
                        <td className="pl-3">待機台数</td>
                        <td>
                          <Button
                            color="primary"
                            className="rounded-pill px-4"
                            disabled={!canUpdate}
                            onClick={() => history.push('/reception-custom/waiting-truck-edit')}
                          >
                            台数設定
                          </Button>
                        </td>
                        <td>{getCountTruckText()}</td>
                        <td className={`pr-3 ${!canUpdate && 'text-center'}`}>
                          <Switch
                            disabled={!canUpdate}
                            className="ml-auto"
                            checked={waitingFlag}
                            onChange={() =>
                              handleUpdateWatingTime(WattingFlagType.WAITING_FLAG, !waitingFlag)
                            }
                          />
                        </td>
                      </tr>
                      <tr className="border-bottom" style={{ height: 48 }}>
                        <td className="pl-3">待ち時間</td>
                        <td>
                          <Button
                            color="primary"
                            className="rounded-pill px-4"
                            disabled={!canUpdate}
                            onClick={() => history.push('/reception-custom/waiting-edit')}
                          >
                            時間設定
                          </Button>
                        </td>
                        <td>{getTimeWaitingText()}</td>
                        <td className={`pr-3 ${!canUpdate && 'text-center'}`}>
                          <Switch
                            className="ml-auto"
                            disabled={!canUpdate}
                            checked={waitingTimeFlag}
                            onChange={() =>
                              handleUpdateWatingTime(
                                WattingFlagType.WAITING_TIME_FLAG,
                                !waitingTimeFlag
                              )
                            }
                          />
                        </td>
                      </tr>
                      <tr className="border-bottom" style={{ height: 48 }}>
                        <td className="pl-3">アプリの待機状況表示</td>
                        <td></td>
                        <td></td>
                        <td
                          className={`pr-3 ${
                            (!canUpdate || (!waitingFlag && !waitingTimeFlag)) && 'text-center'
                          }`}
                        >
                          <Switch
                            disabled={!canUpdate || (!waitingFlag && !waitingTimeFlag)}
                            className="ml-auto"
                            checked={portalFlag}
                            onChange={() =>
                              handleUpdateWatingTime(WattingFlagType.PORTAL_FLAG, !portalFlag)
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {canUpdate && (
              <div className="text-right m-2">
                <Button
                  color="primary"
                  className="rounded-pill px-4"
                  disabled={_.isEqual(initialWaitingSetting, waitingSetting)}
                  onClick={handleSubmit}
                >
                  登録
                </Button>
              </div>
            )}
          </S.SettingContent>
        </S.TimeSettingWrapper>
      )}
      <Prompt
        when={!_.isEqual(initialWaitingSetting, waitingSetting)}
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  warehouseId: warehouseIdSelectors(),
  tenantId: tenantIdSelectors(),
  warehouses: warehousesSelectors(),
  tenants: tenantsSelectors(),
  waitingSetting: waitingSettingSelectors(),
  initialWaitingSetting: initialWaitingSettingSelectors(),
  userData: userDataSelectors(),
})

const mapDispatchToProps = {
  changeWarehouse,
  openToast,
  changeTenant,
  fetchReceptionParameters,
  updateWaitingSetting,
  fetchCustomBerthLabel,
  openError,
  openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitingPage)
