import { decamelizeKeys } from 'humps'
import axios from 'axios'

import { EnvironmentNames } from 'constants/App'

const buildUrl = (actionName) => {
  const input = process.env.REACT_APP_ENV === EnvironmentNames.PRODUCTION ? 'input' : 'stg-input'
  return `https://raw.monoful.jp/${input}/truckbook/${actionName}`
}

export default {
  send(actionName, data) {
    axios.post(buildUrl(actionName), decamelizeKeys(data), {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}
