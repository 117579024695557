import RestClient, { getAuthHeader } from './RestClient'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})
export default class WarehouseServices {
  constructor(restClient = RestClient) {
    this.restClient = restClient
  }

  getBillInfo = (month) =>
    this.restClient.get('/berths/bill_of_department', month, {
      headers: headers(),
    })
}
