import React, { useEffect, useState, FC } from 'react'
import _ from 'lodash'
import Modal from 'react-modal'

import Checkbox from 'components/Checkbox'
import { formatConfirmation } from 'utils/formatters'
import Icon from 'components/Icon'
import RestClient, { getAuthHeader } from 'services/RestClient'
import { getCheckinAuthHeader } from 'services/RestCheckinClient'
import { ConfirmationType } from '../type'
import * as S from './styles'

const buildConfig = (isCheckinPage: Boolean = false) => {
  return {
    headers: isCheckinPage ? getCheckinAuthHeader(true) : getAuthHeader(),
    'Content-Type': 'application/octet-stream',
    responseType: 'blob',
  }
}

type Props = {
  isCheckinPage?: Boolean
  confirmations?: ConfirmationType[]
  confirmation?: ConfirmationType
  confirmedIds?: any
  onChangeCheck?: (e: React.ChangeEvent<HTMLInputElement>, item: ConfirmationType) => void
  onClickCheck?: (confirmation: ConfirmationType) => void
}

const DriverConfirmationItem: FC<Props> = ({
  confirmation,
  confirmedIds,
  onChangeCheck,
  onClickCheck,
  isCheckinPage,
}) => {
  const [loading, setLoading] = useState(false)
  const [fileImg, setFileImg] = useState('')
  const [isShowModalImage, setIsShowModalImage] = useState(false)
  const hasImageConfirmation = _.get(confirmation, 'images[0]', {})

  useEffect(() => {
    ;(async () => {
      const client = new RestClient()
      if (hasImageConfirmation?.id) {
        setLoading(true)
        client
          .get(
            `/general-receptions/driver-confirmation-image`,
            { id: hasImageConfirmation?.id },
            buildConfig(isCheckinPage)
          )
          .then((res) => {
            const file = new Blob([res.data])
            const reader: any = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function () {
              setFileImg(reader.result)
            }
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenModalImage = (e: Event) => {
    e.stopPropagation()
    setIsShowModalImage(true)
  }

  return (
    <>
      <S.WrapDriverConfirmation
        isImportant={confirmation?.importantFlag}
        checkFlag={confirmation?.checkFlag}
        onClick={() => (loading ? _.noop : onClickCheck && onClickCheck(confirmation!))}
      >
        {confirmation?.importantFlag && <S.TextImportant>重要!!</S.TextImportant>}
        <S.ContentDriverConfirmation
          isAboveText={hasImageConfirmation && hasImageConfirmation?.aboveTextFlag}
        >
          {loading ? (
            <S.LoadingCustom isRelative />
          ) : (
            <>
              <div className="wrap-pre-content">
                <S.Pre className="m-1 py-2">{formatConfirmation(confirmation?.confirmation)}</S.Pre>
              </div>
              {!_.isEmpty(confirmation?.images) && (
                <S.ImageContent
                  src={fileImg}
                  alt="image confirmation"
                  onClick={handleOpenModalImage}
                />
              )}
            </>
          )}
          <S.CheckboxWrapper>
            <div className="text-center">
              {confirmation?.checkFlag && (
                <Checkbox
                  label={confirmedIds?.includes(confirmation?.id || '') ? '確認済' : '確認'}
                  checked={confirmedIds?.includes(confirmation?.id || '')}
                  disabled={loading}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeCheck && onChangeCheck(e, confirmation)
                  }
                />
              )}
            </div>
          </S.CheckboxWrapper>
        </S.ContentDriverConfirmation>
      </S.WrapDriverConfirmation>
      <Modal
        isOpen={isShowModalImage}
        onRequestClose={() => setIsShowModalImage(false)}
        style={S.CustomStylesModal}
      >
        <S.IconCloseModal onClick={() => setIsShowModalImage(false)}>
          <Icon
            src="/assets/svgs/close-icon-white.svg"
            alt="close modal"
            type="button"
            width={undefined}
            height={undefined}
          />
        </S.IconCloseModal>

        <S.ImageContentModal src={fileImg} alt="image confirmation modal" />
      </Modal>
    </>
  )
}

const DriverConfirmations: FC<Props> = ({
  confirmations = [],
  confirmedIds = [],
  onChangeCheck = _.noop,
  onClickCheck = _.noop,
  isCheckinPage = false,
}) => {
  const filteredConfirmations = confirmations?.filter(
    (confirmation: ConfirmationType) => confirmation?.displayFlag
  )
  return (
    <div className="mx-auto">
      <div className="text-center mb-3">
        <div className="text-size32 font-weight-bold">ドライバーの皆様へ</div>
        <div>
          伝達事項が{filteredConfirmations.length}件あります。確認をして受付完了してください。
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        {filteredConfirmations.map((confirmation) => (
          <DriverConfirmationItem
            isCheckinPage={isCheckinPage}
            confirmedIds={confirmedIds}
            confirmation={confirmation}
            onChangeCheck={onChangeCheck}
            onClickCheck={onClickCheck}
            key={confirmation?.id}
          />
        ))}
      </div>
    </div>
  )
}

export default DriverConfirmations
