import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import axios from 'axios'

import theme from 'vars/theme'
import { ReactComponent as IconAlert } from 'assets/svgs/icon-alert.svg'
import { statusCode } from 'services/constants'
import { BASE_URL } from 'constants/misc'

const ModalWrapper = styled(({ ...props }) => <Modal {...props} />)`
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: #ffffff;
  margin: 0 !important;
  max-width: none !important;

  .modal-content {
    border: 0;
    text-align: center;
    font-size: 20px;
  }

  .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${theme.darkBlueSoft1};
    background-color: rgb(19, 45, 73);
    padding: 10px 15px;
  }

  .modal-body {
    padding: 20px 24px;
    color: rgb(19, 45, 73);
  }

  .modal-footer {
    padding: 24px;
    border-top: 1px solid ${theme.darkBlueSoft1};

    & > div {
      margin: 0;
    }
  }
`
type Props = {
  isOpen: boolean
}

const ModalMaintain: React.FC<Props> = ({ isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      const intervalCheckHeath = setInterval(() => {
        axios
          .get(`${BASE_URL}monitoring`)
          .then((res) => {
            if (res?.status === statusCode.OK) {
              clearInterval(intervalCheckHeath)
              window.location.reload()
            }
          })
          .catch((error) => {
            console.error(error?.message)
          })
      }, 60000)
    }
  }, [isOpen])

  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalHeader>
        <IconAlert fill="#fff" color="red" width={40} height={40} />
      </ModalHeader>
      <ModalBody>
        ただいまメンテナンス中です。
        <br />
        <br />
        ただいまシステムメンテナンスを実施しております。
        <br />
        大変ご不便をおかけいたしますが、今しばらくお待ちください。
      </ModalBody>
    </ModalWrapper>
  )
}

export default React.memo(ModalMaintain)
