import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'reduxStoreHelper'

import { decryptFunc, compareValue } from 'utils/commonFunctions'
import { GENERAL_CHECKIN } from 'utils/loginStorage'
import { INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import { resetAllData } from '../reducer'
import { isReturnEntryPageSelectors, loadingSelectors } from '../selectors'

type Props = RouteProps & {
  isEntryPage?: boolean
  children: JSX.Element
}

const PrivateRoute = ({ children, isEntryPage, ...rest }: Props) => {
  const dispatch = useDispatch()
  const loading = useSelector(loadingSelectors())
  const isReturnEntryPage = useSelector(isReturnEntryPageSelectors())

  return (
    <Route
      {...rest}
      render={({ match }) => {
        const generalReceptionNumber = match?.params?.generalReceptionNumber
        const dataSessionList = decryptFunc(GENERAL_CHECKIN) || []
        const dataLogin = dataSessionList.find((session: any) =>
          compareValue(session.generalReceptionNumber, generalReceptionNumber)
        )

        if (!dataLogin)
          return (
            <Redirect to={`${INTEGRATED_RECEPTION_URL_BASE}/login/${generalReceptionNumber}`} />
          )

        //Redirect to entry page when reload page
        if (!isEntryPage && !loading && isReturnEntryPage) {
          dispatch(resetAllData())
          return <Redirect to={`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`} />
        }

        return React.cloneElement(children, { generalReceptionId: dataLogin.uid })
      }}
    />
  )
}

export default PrivateRoute
