import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import styled from 'styled-components'

import theme from 'vars/theme'
import Icon from 'components/Icon'

const ModalCustom = styled(Modal)`
  border-radius: 17px;
`
const ModalBodyCustom = styled(ModalBody)`
  text-align: center;
`

const ModalFooterCustom = styled(ModalFooter)`
  background-color: ${theme.graySoft2};

  button {
    margin: 0 auto;
  }
`

const TitleModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 18px;
    margin-left: 7px;
    color: ${theme.redPrimary1};
  }
`

const Message = styled.span`
  font-size: 18px;
  margin: 20px 0;
  white-space: pre-wrap;
`

interface Props {
  isOpen: boolean
  message: string
  onClickClose: () => void
}

export default ({ isOpen, message, onClickClose }: Props) => (
  <ModalCustom isOpen={isOpen}>
    <ModalBodyCustom>
      <TitleModal>
        <Icon src="/assets/svgs/warning-icon.svg" width={22} height={22} /> <span>登録エラー</span>
      </TitleModal>
      <Message dangerouslySetInnerHTML={{ __html: message }}></Message>
    </ModalBodyCustom>
    <ModalFooterCustom>
      <Button color="primary" onClick={onClickClose}>
        とじる
      </Button>
    </ModalFooterCustom>
  </ModalCustom>
)
