import {
  CHECKIN_USER_DATA,
  GENERAL_CHECKIN,
  GENERAL_CHECKIN_BY_TENANT_ID,
} from 'utils/loginStorage'

import { INTEGRATED_RECEPTION_URL_BASE } from 'containers/IntegratedReception/constants'

export const CURRENT_AND_FOLLOWING_DATE = 2

const CHECKIN_TYPE = Object.freeze({
  NONE: -1,
  NORMAL: 0,
  GENERAL: 1,
  GENERAL_BY_TENANT_ID: 2,
})

const StorageKeyConversion = Object.freeze({
  [CHECKIN_TYPE.NORMAL]: CHECKIN_USER_DATA,
  [CHECKIN_TYPE.GENERAL]: GENERAL_CHECKIN,
  [CHECKIN_TYPE.GENERAL_BY_TENANT_ID]: GENERAL_CHECKIN_BY_TENANT_ID,
})

const determineCheckinTypeByPathname = (): number => {
  const pathname = document.location.pathname
  if (pathname.indexOf(INTEGRATED_RECEPTION_URL_BASE) > -1) {
    return CHECKIN_TYPE.GENERAL
  }
  if (pathname.indexOf('general-reception') > -1) {
    const params = pathname.match(/[0-9]+/g) || []
    return params.length === 1 ? CHECKIN_TYPE.GENERAL : CHECKIN_TYPE.GENERAL_BY_TENANT_ID
  }
  if (pathname.indexOf('receipt-booking') > -1) {
    return CHECKIN_TYPE.NORMAL
  }
  return CHECKIN_TYPE.NONE
}

export const extractUidByPathname = (): string => {
  const params = document.location.pathname.match(/[0-9]+/g) || []
  return params[params.length - 1]
}

export const getStorageKeyByCheckinType = (checkinType: number): string =>
  StorageKeyConversion[checkinType] || ''

export const getStorageKeyByPathname = (): string => {
  const checkinType = determineCheckinTypeByPathname()
  return getStorageKeyByCheckinType(checkinType)
}
