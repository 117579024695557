import React, { memo } from 'react'

import * as S from '../style'

type PropsInput = {
  label: string
  value: string
  requiredFlag: boolean
  onChange: (value: string) => void
}

const ImportExportInputRow: React.FC<PropsInput> = ({ label, value, onChange, requiredFlag }) => {
  return (
    <>
      <S.SelectInputWrapper>
        <S.RequiredText>{requiredFlag && '必須'}</S.RequiredText>
        <div style={{ width: 145 }} className="label">
          {label}
        </div>
        <div style={{ width: 255 }}>
          <S.InputImportExport
            type="text"
            className="w-100"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value)
            }}
          />
        </div>
      </S.SelectInputWrapper>
    </>
  )
}

export default memo(ImportExportInputRow)
