import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  color: #247cdb;
  background-color: white;
  border: 1px solid #247cdb;
  border-radius: 68px;
`

export default ({ children, className, style, onClick }) => (
  <Button className={`p-2 ${className}`} style={style} onClick={onClick}>
    {children}
  </Button>
)
