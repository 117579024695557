import React, { memo, FC } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { HeaderWrapper } from 'containers/ReceiptBooking/components/Layout/Header/style'
import Icon from 'components/Icon'
import { generalReceptionDetailSelectors } from '../selectors'
import { VERSION } from 'constants/App'
import * as S from '../style'

type Props = {
  generalReception?: object
}

const Header: FC<Props> = ({ generalReception }) => {
  const generalReceptionName = _.get(generalReception, 'name', '')

  return (
    <HeaderWrapper>
      <div className="brand flex-center">
        {/* @ts-ignore */}
        <Icon src="/assets/svgs/monoful-brand-white.svg" alt="monoful brand" className="ml-1" />
        <p className="font-md mb-0 ml-1">トラック簿</p>
      </div>

      <div className="main d-flex align-items-center justify-content-between">
        <S.HeaderTitle className="font-normal font-weight-bold">
          {generalReceptionName && <span className="ml-2">{generalReceptionName}</span>}
        </S.HeaderTitle>
        <h2
          className="font-normal font-weight-bold btn"
          style={{ color: 'white', cursor: 'auto', width: '240px', textAlign: 'right' }}
        >
          <span className="mr-3">総合</span>
          受付入力
          <span className="ml-3 font-weight-normal text-muted">ver. tf{VERSION}</span>
        </h2>
      </div>
    </HeaderWrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  generalReception: generalReceptionDetailSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(memo(Header))
