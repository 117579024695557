import React, { memo, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'reduxStoreHelper'
import PolicyModal from 'components/PolicyModal'
import { openError } from 'containers/App/reducers'
import { INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import {
  resetAllData,
  startLoading,
  endLoading,
  clearBooking,
  setIsFromBookingNo,
} from '../reducer'
import { policyDataSelectors, phoneNumberEnteredSelectors } from '../selectors'
import { postUpdatePolicyChecked } from '../api'
import { policyType } from '../type'

type Props = {
  canGeneralBooking?: boolean
}

const TermsOfServicePage: React.FC<Props> = ({ canGeneralBooking }) => {
  const history = useHistory()
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()
  const dispatch = useDispatch()
  const phoneNumberEntered = useSelector(phoneNumberEnteredSelectors())
  const policyData: policyType = useSelector(policyDataSelectors())

  const handleClickCheckinNew = useCallback(() => {
    dispatch(clearBooking())
    dispatch(setIsFromBookingNo(false))
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/${generalReceptionNumber}`)
  }, [dispatch, generalReceptionNumber, history])

  const onClickAccept = useCallback(async () => {
    try {
      dispatch(startLoading())
      await postUpdatePolicyChecked(policyData.id, phoneNumberEntered)
      if (canGeneralBooking) {
        history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-bookings/${generalReceptionNumber}`)
      } else {
        handleClickCheckinNew()
      }
    } catch (error) {
      //@ts-ignore
      dispatch(openError(error.message))
    } finally {
      dispatch(endLoading())
    }
  }, [
    canGeneralBooking,
    dispatch,
    generalReceptionNumber,
    handleClickCheckinNew,
    history,
    phoneNumberEntered,
    policyData.id,
  ])

  const onClickCancel = useCallback(() => {
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
    dispatch(resetAllData())
  }, [dispatch, generalReceptionNumber, history])

  return (
    <PolicyModal isOpen policyData={policyData} onAccept={onClickAccept} onCancel={onClickCancel} />
  )
}

export default memo(TermsOfServicePage)
