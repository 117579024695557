import React, { Component } from 'react'
import { connect } from 'react-redux'
import { camelizeKeys } from 'humps'
import _ from 'lodash'

import { fetchAnything, openError, startLoading, stopLoading } from 'containers/App/reducers'

import Header from '../components/Header'
import OutlineButton from '../components/OutlineButton'
import { updateDriver } from '../reducers'
import { requestGet } from '../api'
import loginStorage, { CHECKIN_USER_DATA } from 'utils/loginStorage'
import Storage from '../Storage'
import SessionStorage from '../SessionStorage'

const isLogin = (url, loginTenants) => {
  const nodes = url.split('/')
  const tenantId = nodes[nodes.length - 1]
  return loginTenants[tenantId]
}

class MultiTenantsPage extends Component {
  constructor(props) {
    super(props)
    const sessions = loginStorage.getCheckinAuthData(CHECKIN_USER_DATA) || []
    this.loginTenants = sessions.reduce(
      (ac, item) => ({
        ...ac,
        [item.uid]: true,
      }),
      {}
    )
  }

  async componentDidMount() {
    const { match } = this.props
    const {
      params: { multiTenantId },
    } = match

    let resp

    this.props.startLoading()

    try {
      resp = await requestGet('/tablets/load_multi_tenants', { mid: multiTenantId }, false)
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const multiTenants = camelizeKeys(resp).multiTenants
    const sortedMultiTenants = _.sortBy(multiTenants, (tenant) => tenant.id)
    this.props.updateDriver({ multiTenantId, multiTenants: sortedMultiTenants })
  }

  onClick = (url) => {
    const { match } = this.props
    const {
      params: { multiTenantId },
    } = match

    const nodes = url.split('/')
    const tenantId = nodes[nodes.length - 1]
    Storage.write('tenantId', tenantId)
    SessionStorage.write('multiTenantId', multiTenantId)

    this.props.history.push(`/receipt-booking/${url}`, { multiTenantId })
  }

  render() {
    const { multiTenants } = this.props
    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header />

        <div className="m-3 p-4 bg-white" style={{ borderRadius: 12 }}>
          <div className="text-center mb-4 font-weight-bold" style={{ fontSize: 32 }}>
            行き先を選択してください
          </div>
          <div
            className="mx-auto d-flex flex-column flex-wrap align-items-center"
            style={{ width: 840, height: 360 }}
          >
            {multiTenants.map((tenant) => (
              <OutlineButton
                key={tenant.id}
                color="primary"
                className="text-left mb-3 px-4 font-weight-bold"
                style={{
                  fontSize: 24,
                  width: 400,
                  height: 72,
                  ...(isLogin(tenant.url, this.loginTenants)
                    ? { color: 'white', backgroundColor: '#247cdb' }
                    : {}),
                }}
                onClick={() => this.onClick(tenant.url)}
              >
                {tenant.name}
              </OutlineButton>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    multiTenants: reception.driver.multiTenants,
  }
}

const mapDispatchToProps = {
  fetchAnything,
  updateDriver,
  openError,
  startLoading,
  stopLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiTenantsPage)
