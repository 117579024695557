import { ROLES } from 'constants/misc'

export const formatPhoneNumber = (value) => {
  const len = value.length
  const right = value.substr(len - 4, 4)
  const center = value.substr(len - 8, 4)
  const left = value.substring(0, len - 8)
  return `${left}-${center}-${right}`
}

export const formatPhoneNumberToObject = (value = '') => {
  const len = value.length
  const right = value.substr(len - 4, 4)
  const center = value.substr(len - 8, 4)
  const left = value.substring(0, len - 8)
  return {
    right,
    left,
    center,
  }
}

export const formatBookingNo = (value) => {
  if (!value) return ''
  return `${value.substr(0, 4)}-${value.substr(4, 4)}-${value.substr(8, 2)}`
}

export const formatConfirmation = (value) => {
  if (!value) return ''
  return value.replace(/\\n/g, '&#13;&#10;')
}

export const formatSelectedCardParameterLabel = (parameter) => {
  if (parameter.column_name === 'driver_name') {
    return '氏名：'
  }

  return `${parameter.display_name.substr(0, 2)}：`
}

const roles = {
  [ROLES.SUPER_ADMIN]: 'アドミン',
  [ROLES.ADMIN]: '管理者',
  [ROLES.FULL_OPEN_ADMIN]: '予約者フル',
  [ROLES.FULL_OPEN_NORMAL]: '予約者フル',
  [ROLES.DRIVER_CONTROLLER]: '予約者限定', // 配車担当
  [ROLES.DRIVER]: 'ドライバー',
}

export const formatUserRoleName = (userRole) => roles[userRole]
