import { BASE_URL } from 'constants/misc'

export const statusCode = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SERVER_MAINTAIN_ERROR: 502,
}

export const DEFAULT_CONFIG = {
  baseURL: BASE_URL,
  headers: {},
  withCredentials: false,
}
