import React, { memo, forwardRef, useState, useCallback, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import _ from 'lodash'

import * as S from './style'

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  isSmallPlaceholderActive: boolean
  placeholderActive: string
  defaultWidth?: number
  activeWidth?: number
  isShowCloseIcon?: boolean
  histories?: string[]
  draftValue?: string
  onChangeValue: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void
  onClickSearchIcon?: () => void
  onClickClearSearch?: () => void
  onClickSubmitSearch: (onCloseBookingSearch: () => void) => void
  onHandleKeyPress: (
    e: React.KeyboardEvent<HTMLInputElement>,
    onCloseBookingSearch: () => void
  ) => void
  onActive?: () => void
  onCloseSearchForm?: () => void
  onClickHistoryItem?: (keyword: string) => void
}

const SearchBookingForm = forwardRef<HTMLInputElement, Props>(
  (
    {
      isSmallPlaceholderActive = false,
      placeholderActive,
      placeholder = '',
      defaultWidth = 200,
      activeWidth = 400,
      value,
      isShowCloseIcon,
      histories,
      draftValue,
      onChangeValue,
      onCloseSearchForm,
      onClickSearchIcon,
      onClickClearSearch,
      onClickSubmitSearch,
      onHandleKeyPress,
      onActive,
      onClickHistoryItem,
      ...rest
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false)

    const onHandleFocusInput = useCallback(() => {
      setIsActive(true)
    }, [])

    useEffect(() => {
      if (onActive && isActive) {
        onActive()
      }
    }, [isActive, onActive])

    const onHandleSearch = useCallback(() => {
      onClickSubmitSearch(() => setIsActive(false))
    }, [onClickSubmitSearch])

    const onHandleChangeInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue(event?.target?.value, event)
      },
      [onChangeValue]
    )

    const onHandleClickOutside = useCallback(() => {
      setIsActive(false)
      if (onCloseSearchForm) {
        onCloseSearchForm()
      }
    }, [onCloseSearchForm])

    return (
      <S.Wrapper
        width={defaultWidth}
        activeWidth={activeWidth}
        isActive={isActive}
        isSmallPlaceholderActive={isSmallPlaceholderActive}
      >
        <OutsideClickHandler onOutsideClick={isActive ? onHandleClickOutside : _.noop}>
          <S.Content>
            <S.Header>
              <S.InputWrapper>
                <S.SearchIcon onClick={onClickSearchIcon} className="icon-search" />
                <S.Input
                  ref={ref}
                  type="text"
                  autoComplete="off"
                  onFocus={onHandleFocusInput}
                  onChange={onHandleChangeInput}
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                    onHandleKeyPress(e, () => setIsActive(false))
                  }
                  value={draftValue !== undefined && isActive ? draftValue : value}
                  className="input-search"
                  placeholder={isActive ? placeholderActive : placeholder}
                  {...rest}
                />
                {isActive && isShowCloseIcon && (
                  <S.CloseIcon onClick={onClickClearSearch} alt="close-search-icon" />
                )}
              </S.InputWrapper>
              {isActive && (
                <S.SearchButton className="btn-search" onClick={onHandleSearch}>
                  検索
                </S.SearchButton>
              )}
            </S.Header>
            {isActive && histories && !!histories.length && (
              <>
                <S.HistoryTitle>検索履歴(直近2件分)</S.HistoryTitle>
                <S.ListHistory>
                  {histories.map((history, index) => (
                    <S.HistoryItem
                      key={`historyItem-${index}`}
                      onClick={() => {
                        if (onClickHistoryItem) {
                          onClickHistoryItem(history)
                        }
                      }}
                    >
                      <S.SearchIcon />
                      <span className="text-truncate d-inline-block w-100">{history}</span>
                    </S.HistoryItem>
                  ))}
                </S.ListHistory>
              </>
            )}
          </S.Content>
        </OutsideClickHandler>
      </S.Wrapper>
    )
  }
)

export default memo(SearchBookingForm)
