import { useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

import { useDispatch } from 'reduxStoreHelper'
import { validateYup } from 'utils/validators'
import { convertApiError, convertPhoneNumber } from 'utils/commonFunctions'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { addGeneralReceptionSchema } from '../validation'
import {
  getWarehousesByGeneralReceptionId,
  getTenantsByWarehouseId,
  createGeneralReception,
  getDetailGeneralReception,
  putEditGeneralReception,
} from '../api'
import { GeneralReceptionType } from '../type'
import { updateSelectedGeneralReception, fetchGeneralReceptions } from '../reducer'

type FormData = Omit<GeneralReceptionType, 'id' | 'features'> & {
  parentWarehouseId?: number
  canGeneralBooking?: boolean
  canCustomizeReception?: boolean
}
export const useGeneralReception = (isCreatePage: boolean) => {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<FormData>({
    name: '',
    password: '',
    parentTenantId: -1,
  })
  const [warehouses, setWarehouses] = useState<any[]>([])
  const [tenants, setTenants] = useState<any[]>([])
  const [updateInfo, setUpdateInfo] = useState<{ updatedAt?: string; lastUpdater?: string }>({
    updatedAt: '',
    lastUpdater: '',
  })
  const [isFirstRendering, setIsFirstRendering] = useState(true)
  const [error, setError] = useState<any>({})
  // @ts-ignore
  const [detailGeneralReception, setDetailGeneralReception] = useState<GeneralReceptionType>({})
  const [loading, setLoading] = useState<boolean>(false)

  const history = useHistory()
  const params: { generalReceptionId: string } = useParams()

  const handleOnChange = useCallback(
    (name: string) => (value: string) => {
      let val: string
      let isUpdate = true
      switch (name) {
        case 'password':
          val = value
            .toString()
            .replace(/[^a-zA-Z0-9+!@#$%&*()_.,<>/?]/g, '')
            .slice(0, 20)
          break
        case 'contactPhone':
          if (typeof convertPhoneNumber(value) !== 'boolean') {
            val = convertPhoneNumber(value)
          } else {
            isUpdate = false
          }
          break
        default:
          val = value
      }

      if (isUpdate) {
        setFormData((prev) => ({ ...prev, [name]: val }))
      }
    },
    []
  )

  useEffect(() => {
    if (!isCreatePage) {
      ;(async () => {
        try {
          setLoading(true)
          const res = await getDetailGeneralReception(params.generalReceptionId)
          // @ts-ignore
          const {
            name,
            decodedPassword: password,
            contactName,
            contactPhone,
            checkinEnableDistance,
            longitude,
            latitude,
            parentTenantId,
            parentWarehouseId,
            updatedAt,
            lastUpdater,
            features,
          }: GeneralReceptionType = res.data

          const formData = {
            name,
            password,
            contactName,
            contactPhone,
            checkinEnableDistance,
            longitude,
            latitude,
            parentTenantId: parentTenantId,
            parentWarehouseId: parentWarehouseId,
            features,
          }
          // @ts-ignore
          setDetailGeneralReception(formData)
          // @ts-ignore
          setFormData({
            name,
            password: password || '',
            contactName,
            contactPhone,
            checkinEnableDistance,
            longitude,
            latitude,
            canGeneralBooking: features?.canGeneralBooking,
            canCustomizeReception: features?.canCustomizeReception,
          })
          setUpdateInfo({
            updatedAt,
            lastUpdater,
          })
        } catch (e) {}
      })()
    }
  }, [params.generalReceptionId, isCreatePage])

  useEffect(() => {
    if (!isCreatePage && _.isEmpty(detailGeneralReception)) return
    ;(async () => {
      setLoading(true)

      try {
        const res = await getWarehousesByGeneralReceptionId()
        const warehousesRes: { id?: number }[] = _.sortBy(res.data, (warehouse) =>
          moment(warehouse.createdAt).unix()
        )
        setWarehouses(warehousesRes)
        if (isCreatePage) {
          handleOnChange('parentWarehouseId')(`${_.first(warehousesRes)?.id || ''}`)
        } else {
          handleOnChange('parentWarehouseId')(`${detailGeneralReception.parentWarehouseId}`)
        }
      } catch (e) {}
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOnChange, isCreatePage, detailGeneralReception])

  useEffect(() => {
    ;(async () => {
      if (!formData.parentWarehouseId) return
      try {
        setTenants([])
        const res = await getTenantsByWarehouseId(+formData.parentWarehouseId)
        const tenantsRes: { id?: number }[] = _.sortBy(res.data, (tenant) =>
          moment(tenant.createdAt).unix()
        )
        setTenants(tenantsRes)

        if (!isFirstRendering || isCreatePage) {
          handleOnChange('parentTenantId')(`${_.first(tenantsRes)?.id || ''}`)
        } else {
          handleOnChange('parentTenantId')(`${detailGeneralReception.parentTenantId}`)
        }
        setIsFirstRendering(false)
        setLoading(false)
      } catch (e) {}
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.parentWarehouseId, handleOnChange, isCreatePage])

  // Clear data when switch create and edit page
  useEffect(() => {
    setFormData({
      ...formData,
      name: '',
      password: '',
      contactName: '',
      contactPhone: '',
      longitude: '',
      latitude: '',
      checkinEnableDistance: '',
      canGeneralBooking: false,
      canCustomizeReception: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatePage])

  const handleSubmit = useCallback(async () => {
    try {
      const { yupData, errors, isError } = validateYup(addGeneralReceptionSchema, formData)
      if (isError) {
        setError(errors)
        return
      }

      //@ts-ignore
      const { canGeneralBooking, canCustomizeReception, ...restData } = yupData
      const submitData = {
        ...restData,
        features: {
          canGeneralBooking,
          canCustomizeReception,
        },
      }
      let res: any = {}

      if (isCreatePage) {
        res = await createGeneralReception(submitData)
        dispatch(showFlashMessage({ message: '総合受付作成が成功しました。' }))
        history.push('/multi-setting')
      } else {
        res = await putEditGeneralReception({
          ...submitData,
          generalReceptionId: params.generalReceptionId,
        })
        dispatch(showFlashMessage({ message: '総合受付更新が成功しました。' }))
        history.push('/multi-setting')
      }
      dispatch(updateSelectedGeneralReception(res.data || {}))
      dispatch(fetchGeneralReceptions())
    } catch (e) {
      setError(convertApiError(e.data.errors))
    }
  }, [dispatch, formData, history, isCreatePage, params.generalReceptionId])

  return {
    warehouses,
    tenants,
    formData,
    error,
    updateInfo,
    handleOnChange,
    handleSubmit,
    loading,
  }
}
