import freezeR from 'utils/freezeR'
import moment from 'moment'
import _ from 'lodash'

export const GeneralBookingStatuses = freezeR({
  BOOKING: {
    id: 4,
    label: '調整中',
    labelStatus: '予',
    labelTime: '調整中',
  },
  CONFIRM: {
    id: 5,
    label: '予約済',
    labelStatus: '予',
    labelTime: '予約済',
  },
  CHECKIN: {
    id: 0,
    label: '受付済/ 未割当',
    labelStatus: '受',
    labelTime: '受付済',
  },
  ASSIGN: {
    id: 1,
    label: '割当済',
    labelStatus: '受',
    labelTime: '受付済',
  },
  FINISH: {
    id: 2,
    label: '完了/退場',
    labelStatus: '完',
    labelTime: '完了',
  },
  DELAY: {
    id: 6,
    label: '遅延中',
    labelStatus: '遅',
    labelTime: '遅延中',
  },
  ALL: {
    id: 3,
    label: 'すべて',
  },
})

export const STANDARD_DAY_FORMAT = 'YYYY-MM-DD'
export const STANDARD_DAY_OTHER_FORMAT = 'YYYY/MM/DD'
export const STANDARD_PLAN_DAY_FORMAT = 'YYYYMMDD'
export const STANDARD_TIME_FORMAT = 'HH:mm'
export const STANDARD_FULL_TIME_FORMAT = 'HH:mm:ss'
export const START_TIME_DAY = '00:00:00'
export const END_TIME_DAY = '23:59:59'
export const STANDARD_FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const NUMBER_ITEM_IN_PER_PAGE = 50
export const FIRST_PAGE = 1
export const NON_VALUE_TEXT = '未定'

export const AssignType = freezeR({
  ASSIGN: 0,
  RE_ASSIGN: 1,
  PRE_ASSIGN: 2,
  KEEP_PRE_ASSIGN_ORDER: 3,
})

export const BookingSidePanelType = freezeR({
  NONE: 0,
  CREATE: 1,
  EDIT: 2,
})

export const NotificationType = freezeR({
  CHECKOUT_ALL_GENERAL_BOOKING: 'checkout_all_general_booking',
  CONFIRM_ORDER_GENERAL_BOOKING: 'confirm_order_general_booking',
  UPDATE_STATUS_OF_BOOKING: 'update_status_of_booking',
  DELETE_GENERAL_BOOKING: 'delete_general_booking',
  DELETE_BOOKING: 'delete_booking',
  UPDATE_GENERAL_BOOKING: 'update_general_booking',
})

export const BookingSource = freezeR({
  TABLET: 1,
  PORTAL: 2,
  MANAGEMENT: 3,
})

export const checkCanPreAssign = (statusBooking) =>
  [
    GeneralBookingStatuses.BOOKING.id,
    GeneralBookingStatuses.CONFIRM.id,
    GeneralBookingStatuses.DELAY.id,
  ].includes(statusBooking)

export const justCheckin = (updatedDate, checkinDate) =>
  moment(updatedDate).format(STANDARD_FULL_DATE_TIME_FORMAT) ===
  moment(checkinDate).format(STANDARD_FULL_DATE_TIME_FORMAT)

export const LOCAL_STORAGE_GENERAL_RECEPTION_ID = 'generalReceptionId'

export const ChangeStatusType = freezeR({
  CONFIRM: 'confirm',
  CHECKIN: 'checkin',
})

export const sortGeneralBooking = (multipleBookings) => {
  if (!multipleBookings) return []
  return _.sortBy(
    multipleBookings,
    (booking) => {
      const planStartTime = booking?.planStartTime || END_TIME_DAY
      const startDateTime = moment(
        `${booking?.planStartDate} ${planStartTime}`,
        `${STANDARD_PLAN_DAY_FORMAT} ${STANDARD_FULL_TIME_FORMAT}`
      )
      return startDateTime.unix()
    },
    (booking) => moment(booking?.createdDate, STANDARD_FULL_DATE_TIME_FORMAT).unix()
  )
}

export const REGEX_IMPORT_EXPORT_ADDITIONAL_COLUMN = /^(import|export)_additional_column\d+$/g
export const REGEX_IMPORT_EXPORT = /^(import|export)([-\w\d]+)?$/g
export const REGEX_IMPORT_EXPORT_REPLACE = /(import|export)_/g

export const checkIsAdditionalColumn = (columnName) =>
  new RegExp(REGEX_IMPORT_EXPORT_ADDITIONAL_COLUMN).test(columnName)

export const ImportFlagCheck = {
  CHECK: 1,
  UNCHECK: 0,
}

export const NUMBER_OF_ADDITIONAL_COLUMNS = 23

// Addination column has 23 element
export const ADDITIONAL_COLUMNS_RANGE = _.range(1, NUMBER_OF_ADDITIONAL_COLUMNS + 1)
export const DRIVER_WIDTH = 240

export const IMPORT_EXPORT_COLUMN_NAME = {
  import: 'import',
  export: 'export',
}
