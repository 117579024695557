import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  display: inline-block;
  width: 160px;
  height: 80px;
  border: 1px solid #ccc;
  color: #247cdb;
  background-color: white;
  font-size: 48px;
  border-radius: 12px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  padding: 1px 6px;
  white-space: nowrap;
`

const CustomNumberButton = styled(Button)`
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1.0)};
`

export default ({
  isShowPhoneNumberPrefix = false,
  onClick,
  onClickBackSpace,
  onClickEnter,
  value,
  canClickEnter = false,
}) => (
  <div
    className="mx-auto"
    style={{
      width: isShowPhoneNumberPrefix ? 832 : 664,
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    {/* left */}
    {isShowPhoneNumberPrefix && (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomNumberButton onClick={() => onClick('070')} disabled={value.length}>
          070
        </CustomNumberButton>
        <CustomNumberButton onClick={() => onClick('080')} disabled={value.length}>
          080
        </CustomNumberButton>
        <CustomNumberButton onClick={() => onClick('090')} disabled={value.length}>
          090
        </CustomNumberButton>
      </div>
    )}

    {/* center */}
    <div style={{ width: 496 }}>
      <div>
        <Button style={{ marginRight: 8 }} onClick={() => onClick('1')}>
          1
        </Button>
        <Button style={{ marginRight: 8 }} onClick={() => onClick('2')}>
          2
        </Button>
        <Button onClick={() => onClick('3')}>3</Button>
      </div>
      <div>
        <Button style={{ marginRight: 8 }} onClick={() => onClick('4')}>
          4
        </Button>
        <Button style={{ marginRight: 8 }} onClick={() => onClick('5')}>
          5
        </Button>
        <Button onClick={() => onClick('6')}>6</Button>
      </div>
      <div>
        <Button style={{ marginRight: 8 }} onClick={() => onClick('7')}>
          7
        </Button>
        <Button style={{ marginRight: 8 }} onClick={() => onClick('8')}>
          8
        </Button>
        <Button onClick={() => onClick('9')}>9</Button>
      </div>
      <div>
        <Button style={{ marginLeft: 168 }} onClick={() => onClick('0')}>
          0
        </Button>
      </div>
    </div>

    {/* right */}
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button style={{ fontSize: 22, fontWeight: 'normal' }} onClick={onClickBackSpace}>
        一文字消す
      </Button>
      <Button
        style={{
          color: 'white',
          backgroundColor: '#247CDB',
          border: 'none',
          height: 256,
          fontWeight: 'normal',
          opacity: canClickEnter ? 1.0 : 0.2,
        }}
        disabled={!canClickEnter}
        onClick={onClickEnter}
      >
        決定
      </Button>
    </div>
  </div>
)
