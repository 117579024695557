import RestClient, { getAuthHeader } from './RestClient'
import { decamelizeKeys } from 'humps'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})
export default class ReceptionCustomService {
  constructor(restClient = RestClient) {
    this.restClient = restClient
  }

  getWaitingSetting(params) {
    return this.restClient.get('waiting-booking-tenants', decamelizeKeys(params), {
      headers: headers(),
    })
  }

  createWaitingSetting = (data) =>
    this.restClient.post({
      url: 'waiting-booking-tenants',
      data: decamelizeKeys(data),
      config: {
        headers: headers(),
      },
    })
}
