import React from 'react'

import Contact from './Contact'
import GeneralReceptionCustomSample from 'assets/pngs/general-reception-custom-sample.png'
import * as S from './styles'

export default () => (
  <S.CustomSettingWrapper className="text-center">
    <div className="mb-4 text-size24">この総合管理では受付カスタマイズを使用できません</div>
    <div className="mx-auto mb-3" style={{ width: 700 }}>
      <div>
        本機能により、ドライバーの方々への受付内容を自由に設定/変更する
        <br />
        ことが可能になりますので、ご興味のある方は下記へお問い合わせください。
      </div>
      <Contact />
    </div>

    <div className="mb-3" style={{ opacity: 0.4 }}>
      <img src={GeneralReceptionCustomSample} alt="受付カスタマイズサンプルイメージ" />
    </div>
  </S.CustomSettingWrapper>
)
