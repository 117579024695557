/* eslint-disable no-console */
import moment from 'moment'
import _ from 'lodash'
import { camelizeKeys } from 'humps'

import {
  DisplayModes,
  BookingStatuses,
  GENERAL_BOOKING_WAITING_STATUS_OFFSET,
  GeneralBookingWaitingStatuses,
} from 'constants/misc'
import { TenantParameterTypes } from 'constants/ReceptionCustom'
import { BERTH_FIXED_WIDTH } from 'constants/BerthManagement'
import { compareValue } from 'utils/commonFunctions'

const TIME_RATE_FOR_INTERVAL_1_HOUR = 1.1
const TimeRates = Object.freeze({
  1368: 1.7,
  1679: 1.9,
  1900: 2.0,
  // 1901 以上: 2.1,
})
const MAX_TIME_RATE = 2.1

const CellWidths = Object.freeze({
  1140: 150,
  1240: 160,
  1368: 170,
  1440: 205,
  1500: 215,
  1679: 225,
  1900: 260,
  // 1901 以上: 305,
})
const MAX_CELL_WIDTH = 305

// クリックされたバースが左から何番目かを取得する
export const getBerthIndexFromLeft = (x, windowInnerWidth, isFixedWidth = false) => {
  const keys = Object.keys(CellWidths)
  let cellWidth = isFixedWidth ? BERTH_FIXED_WIDTH : MAX_CELL_WIDTH

  if (!isFixedWidth) {
    for (let i = 0; i < keys.length; i += 1) {
      const width = Number(keys[i])
      if (windowInnerWidth <= width) {
        cellWidth = CellWidths[width]
        break
      }
    }
  }

  return Math.floor(x / cellWidth)
}

export const calcTimeRate = (displayMode, windowInnerWidth, isFixedTimeRate = false) => {
  if (displayMode === DisplayModes.INTERVAL_1_HOUR) {
    return TIME_RATE_FOR_INTERVAL_1_HOUR
  }

  if (isFixedTimeRate) {
    return 1.7
  }

  const keys = Object.keys(TimeRates)

  for (let i = 0; i < keys.length; i += 1) {
    const width = Number(keys[i])
    if (windowInnerWidth <= width) {
      return TimeRates[width]
    }
  }

  return MAX_TIME_RATE
}

// 2桁数字にする
const to2DigitNumber = (num) => _.padStart(num, 2, '0')

// y 座標と高さを開始時刻と終了時刻に変換する
export const convertPxToTime = (y, timeRate, displayMode, configs) => {
  const config = configs[displayMode]

  // 1分間に相当するピクセル値
  const pixelPerMinute = (60 * timeRate) / (displayMode === DisplayModes.INTERVAL_1_HOUR ? 10 : 60)

  // ピクセル値を分に変換する
  const startMin = Math.floor(y / pixelPerMinute)
  // n 分間隔にするため、はみ出る時間をカットする
  const roundedStartMin = startMin - (startMin % config.TIME_SKIP_MIN)

  const start = {
    hours: to2DigitNumber(Math.floor(roundedStartMin / 60)),
    minutes: to2DigitNumber(roundedStartMin % 60),
  }

  return `${start.hours}:${start.minutes}:00`
}

// convert 00:00 to number of minutes
const toMinutes = (time) => {
  const [hour, min] = time.split(':')
  return Number(hour) * 60 + Number(min)
}

// 指定可能な終了時刻を算出する
//   同じ日でフィルタ済みの bookings を前提とする
export const calcEndTime = (startTime, configItem, bookings, delegations = []) => {
  const { DEFAULT_MIN } = configItem
  const endTime = moment(`2020-02-02 ${startTime}`).add(DEFAULT_MIN, 'minutes').format('HH:mm:00')

  const startMinutes = toMinutes(startTime)
  const endMinutes = toMinutes(endTime)

  const sortedBookings = _.sortBy(bookings, (booking) => toMinutes(booking.start_time))

  for (let i = 0; i < sortedBookings.length; i += 1) {
    const booking = sortedBookings[i]

    if (
      // それ以前の予約は無視する
      startMinutes < toMinutes(booking.start_time) &&
      endMinutes > toMinutes(booking.start_time)
    ) {
      return booking.start_time
    }
  }

  // TODO 予約枠が重なっている場合は事前にマージしておく必要がある
  const sortedDelegations = _.sortBy(delegations, (delegation) => toMinutes(delegation.end_time))

  for (let i = 0; i < sortedDelegations.length; i += 1) {
    const delegation = sortedDelegations[i]

    if (
      // 開始時刻が予約枠内にあり、
      startMinutes >= toMinutes(delegation.start_time) &&
      startMinutes < toMinutes(delegation.end_time) &&
      // 終了時刻が予約枠外の場合。
      endMinutes > toMinutes(delegation.end_time)
    ) {
      return delegation.end_time
    }
  }

  return endTime
}

// 自分が割り当て可能な予約枠か判定する
export const canAssignDelegation = (
  x,
  y,
  berths,
  delegations,
  displayMode,
  windowInnerWidth,
  user_id,
  company_id,
  isFixedTimeRate = false,
  usersSameGroup = []
) => {
  const berthIndex = getBerthIndexFromLeft(x, windowInnerWidth)
  const berth = berths[berthIndex]

  if (!berth) return false

  const timeRate = calcTimeRate(displayMode, windowInnerWidth, isFixedTimeRate)

  // 1分間に相当するピクセル値
  const pixelPerMinute = (60 * timeRate) / (displayMode === DisplayModes.INTERVAL_1_HOUR ? 10 : 60)

  // ピクセル値を分に変換する
  const startMin = Math.floor(y / pixelPerMinute)

  const filteredDelegations = delegations.filter((d) => d.berth_id === berth.id)

  // マッチする delegation があれば true
  for (let i = 0; i < filteredDelegations.length; i += 1) {
    const delegation = filteredDelegations[i]

    const result =
      startMin >= toMinutes(delegation.start_time) &&
      startMin <= toMinutes(delegation.end_time) &&
      // vehicle_controllers の中に自分が入ってるかどうかも判定する必要があるのか？
      // それとも、すでにフィルタされた情報がサーバーから返ってくるのか？
      delegation.vehicle_controllers.find(
        (vc) =>
          compareValue(vc.company_id, company_id) ||
          compareValue(vc.vehicle_controller_id, user_id) ||
          usersSameGroup.some((userId) => compareValue(vc.vehicle_controller_id, userId))
      )

    if (result) return true
  }

  return false
}

export const sortWaitingCards = (bookings) =>
  _.sortBy(bookings, [
    (booking) =>
      -(booking.status < GENERAL_BOOKING_WAITING_STATUS_OFFSET
        ? booking.status
        : BookingStatuses.Recept.id),
    (booking) => Boolean(booking.general_booking_id),
    (booking) =>
      booking.general_booking_waiting_status || GeneralBookingWaitingStatuses.WAITING_ZERO.id,
    // null を返した場合、それ以降の評価が行われないっぽいので null を返さないようにする
    (booking) => (booking.checkin_date ? moment(booking.checkin_date).unix() : moment().unix()),
    (booking) =>
      booking.start_time
        ? moment(`${booking.start_date} ${booking.start_time}`, 'YYYYMMDD HH:mm:ss').unix()
        : moment(booking.start_date, 'YYYYMMDD').unix(),
    (booking) => moment(booking.created_date).unix(),
  ])

export const sortUnmannedCards = (bookings) =>
  _.sortBy(bookings, [
    (booking) => booking.status,
    (booking) => moment(booking.created_date).unix(),
  ])

export const isAdditionalColumn = (columnName) => columnName.indexOf('additional_column') > -1

export const getParameterValue = (
  parameter,
  data,
  bookingMaster,
  returnParameterId = true,
  forceDisplay = false
) => {
  const { column_name } = parameter
  const { package_types } = bookingMaster
  const value = data[parameter.column_name] || ''

  const cn = column_name.replace(/(import|export)_/, '')

  if (cn === 'package_type') {
    const filteredPackageTypes = forceDisplay
      ? package_types
      : package_types.filter((p) => p.display_flag)
    const selectedOption = filteredPackageTypes.find((p) => p.parameter_id === Number(value))
    if (!selectedOption) return ''
    return returnParameterId ? selectedOption.parameter_id : selectedOption.parameter_name
  }

  return value
}

// 選択式か記述式か判定する
export const isSelectType = (parameter) => {
  const camelizedParameter = camelizeKeys(parameter)
  if (camelizedParameter.type === TenantParameterTypes.READ_ONLY.id) {
    const columnName = camelizedParameter.columnName.replace(/^(import|export)_/, '')
    return ['vehicle_category', 'package_type'].includes(columnName)
  }

  return parameter.type === TenantParameterTypes.SELECT.id
}

// カード表示内容カスタマイズで選択された項目の値を取得
export const getCardViewValue = (bookingMaster, data) => {
  const { tenant_parameters } = bookingMaster
  const { import_main_goods, export_main_goods } = data

  const selectedBaseParameter = tenant_parameters.base.find((parameter) => parameter.card_view_flag)

  if (selectedBaseParameter) {
    return getParameterValue(selectedBaseParameter, data, bookingMaster, false, true)
  }

  const selectedImportParameter = tenant_parameters.import.find(
    (parameter) => parameter.card_view_flag
  )

  // 受付カスタマイズがOFFのテナント
  if (!selectedImportParameter) {
    // 降ろしが優先
    return import_main_goods || export_main_goods
  }

  const importValue = getParameterValue(selectedImportParameter, data, bookingMaster, false, true)

  // 降ろしが優先
  if (importValue) return importValue

  // 降ろしが空であれば積みを表示
  const selectedExportParameter = tenant_parameters.export.find(
    (parameter) => parameter.card_view_flag
  )
  const exportValue = getParameterValue(selectedExportParameter, data, bookingMaster, false, true)

  return exportValue
}

export const buildPackageValues = (parameters, data) => {
  const packageColumnNames = parameters.map((parameter) => parameter.column_name)
  return packageColumnNames.reduce(
    (ac, columnName) => ({
      ...ac,
      [columnName]: data[columnName],
    }),
    {}
  )
}
