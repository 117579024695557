import { camelizeKeys, decamelizeKeys } from 'humps'

import RestCheckinClient, { getCheckinAuthHeader } from 'services/RestCheckinClient'
import { GeneralBookingType } from './type'

const client = new RestCheckinClient()
const buildConfig = (useAuth = true) => ({
  headers: {
    'Content-Type': 'application/json',
    ...(useAuth && getCheckinAuthHeader(true)),
  },
})

export const requestGet = (url = '', data = {}, useAuth = true) =>
  client
    .get(url, decamelizeKeys(data), buildConfig(useAuth))
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const requestPost = (url = '', data = {}, useAuth = true) =>
  client
    .post({
      url,
      params: {},
      data: decamelizeKeys(data),
      config: buildConfig(useAuth),
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const requestPut = (url = '', data = {}, useAuth = true) =>
  client
    .put({
      url,
      params: {},
      data: decamelizeKeys(data),
      config: buildConfig(useAuth),
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const requestPatch = (url = '', data = {}, useAuth = true) =>
  client
    .patch({
      url,
      params: {},
      data: decamelizeKeys(data),
      config: buildConfig(useAuth),
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const requestDelete = (url = '', data: any, params: any, useAuth = true) =>
  client
    .delete({
      url,
      params: decamelizeKeys(params),
      data: decamelizeKeys(data),
      config: buildConfig(useAuth),
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

// Example
export const postCreateBooking = (booking: any) =>
  requestPost('/tablets/reception_booking', booking)

export const getDriverInfo = (driverPhone: string) =>
  requestGet('/tablets/get_driver', { driverPhone })

export const postSendCode = (generalReceptionId: string, phoneNumber: string) =>
  requestPost('general-receptions/send-code', { generalReceptionId, phoneNumber })

export const postVerifyPassCode = (code: string, phoneNumber: string) =>
  requestPost('tablets/verify', { code, phoneNumber })

export const postResendPassCode = (generalReceptionId: string = '', phoneNumber: string) =>
  requestPost('general-receptions/resend-code', { generalReceptionId, phoneNumber })

export const postLogin = (data: any, useAuth?: boolean) =>
  requestPost('/general-receptions/tablets/sign-in', data, useAuth)

export const postUpdatePolicyChecked = (policyId: string, phoneNumber: string) =>
  requestPatch('tablets/update_policy_checked', { policyId, phoneNumber })

export const getListBookingNotCheckin = (generalReceptionId: string = '', driverPhone: string) => {
  return requestGet('general-booking/not-checkin-booking', { generalReceptionId, driverPhone })
}

export const getBookingAndDriverInfo = (
  generalReceptionId: string = '',
  generalBookingNo: string,
  driverPhone: string
) =>
  requestGet('general-booking/booking-and-driver-info', {
    generalReceptionId,
    generalBookingNo,
    driverPhone,
  })

export const postGeneralBookingCheckin = (data: GeneralBookingType) => {
  return requestPost('general-booking/checkin', data)
}

export const updateGeneralBookingCheckinWithBookingNo = (data: GeneralBookingType) => {
  return requestPatch('general-booking/checkin-by-booking-no', data)
}

export const getMasterData = (generalReceptionId: string = '') =>
  requestGet('general-receptions/master-data', { generalReceptionId })

export const getRecentBooking = (generalReceptionId: string = '', driverPhone: string) =>
  requestGet('general-booking/recent-booking', { generalReceptionId, driverPhone })

export const getListCompanies = (generalReceptionId: string = '', phoneNumber: string) =>
  requestGet('general-receptions/list-companies', { generalReceptionId, phoneNumber })

export const getGeneralReceptionDetail = (generalReceptionId: string = '') =>
  requestGet('general-receptions/detail', { generalReceptionId })

export const getPolicy = () => {
  return requestGet('policies/newest')
}

export const getGeneralConfirmations = (generalReceptionId: string | number) =>
  requestGet('/general-receptions/confirmation', { generalReceptionId })
