import React, { Component } from 'react'

import AppPrivate from 'containers/AppPrivate'
import LoadingIndicator from 'components/LoadingIndicator'

class AppPrivateWrapper extends Component {
  UNSAFE_componentWillMount() {
    const query = this.props.location.search
    if (query) {
      this.props.getAuthenticationData(query.substring(6))
    }
  }

  render() {
    if (this.props.loading) {
      return <LoadingIndicator />
    }

    return <AppPrivate {...this.props} />
  }
}

export default AppPrivateWrapper
