import styled from 'styled-components'

// TODO rename ModalBackdrop
export const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ModalFrame = styled.div`
  position: relative;
  z-index: 100;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #dddddd;
  margin-top: -40px;
`

export const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 1.1;
  ${({ disabled }) => disabled && `background-color: rgb(237, 237, 237)`}
`

// 全角スペースを入れて高さを揃える
const FULL_SPACE = '　'
export const EMPTY_OPTION = Object.freeze({ value: '', label: FULL_SPACE })
