import React, { Suspense } from 'react'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{ id: string }>

const GeneralImagePreviewPage = React.lazy(() => import('.'))

export default ({ match }: Props) => (
  <Suspense fallback={<div />}>
    <GeneralImagePreviewPage match={match} />
  </Suspense>
)
