export const VERSION = '1.3.03'

export const VERSION_STORAGE_KEY = 'truckbook-version'

export const EnvironmentNames = Object.freeze({
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
  SANDBOX: 'sandbox',
  LOCAL: 'local',
})

export const USE_DEV_CONSOLE =
  process.env.REACT_APP_ENV === EnvironmentNames.PRODUCTION
    ? false
    : localStorage.getItem('USE_DEV_CONSOLE') === 'true'
