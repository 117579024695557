import React from 'react'
import styled from 'styled-components'
import theme from 'vars/theme'

const StrikeThroughWrapper = styled.div`
  padding-left: 4px;
  padding-right: 4px;
`

const StrikeThrough = () => {
  return (
    <StrikeThroughWrapper>
      <svg fill="none" viewBox="0 0 5 9" xmlns="http://www.w3.org/2000/svg" width={9} height={18}>
        <line
          x1="4.1565"
          x2=".46957"
          y1=".46957"
          y2="7.8435"
          stroke={theme.grayDark}
          strokeLinecap="round"
          strokeWidth=".7"
        />
      </svg>
    </StrikeThroughWrapper>
  )
}

export default StrikeThrough
