import React, { Component } from 'react'
import { Button } from 'reactstrap'
import _ from 'lodash'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Prompt } from 'react-router-dom'

import {
  startLoading,
  stopLoading,
  fetchAnything,
  fetchCustomBerthLabel,
  openError,
  openToast,
} from 'containers/App/reducers'

import Tabs from '../components/Tabs'
import ReceptionItemRow from '../components/ReceptionItemRow'
import { patchReception } from '../api'
import {
  selectParameter,
  changeWarehouse,
  changeTenant,
  updateParameters,
  fetchReceptionParameters,
} from '../reducers'
import Header from '../components/Header'
import FinishToast from '../components/FinishToast'
import { HEADER_HEIGHT, typeRow } from '../constants'
import CanNotUse from '../components/CanNotUse'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import CanNotUpdate from '../components/CanNotUpdate'

const Th = styled.th`
  background-color: #f0f0f0;
  z-index: 2;
`

const REQUIRED_PARAM_KEYS = [
  'id',
  'parameterId',
  'type',
  'requiredFlag',
  'displayFlag',
  'displayOrder',
  'displayName',
]

const IMPORT_PARAMETER_FIRST_INDEX = 5
const BASE_PARAMETER = ['company_name', 'vehicle_number', 'driver_name', 'vehicle_category']
const ONLY_CHECKBOX_PARAMETERS = ['import_export_enable']

class ItemsPage extends Component {
  state = {
    // fix "A history supports only one prompt at a time" warning
    // ref: https://github.com/ReactTraining/react-router/issues/5707
    enablePrompt: false,
  }

  componentDidMount() {
    this.props.fetchReceptionParameters()
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ enablePrompt: true })
  }

  onChangeRequired = (item) => {
    const { parameters } = this.props

    const foundIndex = parameters.findIndex((parameter) => parameter.id === item.id)
    parameters[foundIndex] = {
      ...item,
      requiredFlag: !item.requiredFlag,
    }

    this.props.updateParameters(parameters)
  }

  onChangeVisibility = (item) => {
    const { parameters } = this.props

    // カード表示内容に選択されている項目を非表示にしたときに警告を出す
    if (item.cardViewFlag && item.displayFlag) {
      this.props.openError(
        '本項目は「カード表示内容」にて選択されていたため、「カード表示内容」から他の項目を選択してください。',
        false
      )
    }

    const foundIndex = parameters.findIndex((parameter) => parameter.id === item.id)
    const displayFlag = !item.displayFlag
    parameters[foundIndex] = {
      ...item,
      displayFlag,
      // 非表示の場合は必須にはできない
      requiredFlag: displayFlag ? item.requiredFlag : false,
    }

    this.props.updateParameters(parameters)
  }

  onClickUp = (item) => {
    const { parameters } = this.props

    const foundIndex = parameters.findIndex((parameter) => parameter.id === item.id)
    const prevItem = parameters[foundIndex - 1]

    parameters[foundIndex - 1] = {
      ...item,
      displayOrder: prevItem.displayOrder,
    }
    parameters[foundIndex] = {
      ...prevItem,
      displayOrder: item.displayOrder,
    }

    this.props.updateParameters(parameters)
  }

  onClickDown = (item) => {
    const { parameters } = this.props

    const foundIndex = parameters.findIndex((parameter) => parameter.id === item.id)
    const nextItem = parameters[foundIndex + 1]

    parameters[foundIndex + 1] = {
      ...item,
      displayOrder: nextItem.displayOrder,
    }
    parameters[foundIndex] = {
      ...nextItem,
      displayOrder: item.displayOrder,
    }

    this.props.updateParameters(parameters)
  }

  onClickEdit = (url, item) => {
    this.props.selectParameter(_.pick(item, ['id', 'displayName', 'type']))
    this.props.history.push(url)
  }

  onChangeWarehouse = (option) => {
    const {
      warehouses,
      changeWarehouse,
      changeTenant,
      fetchReceptionParameters,
      fetchCustomBerthLabel,
    } = this.props

    const warehouseId = option.value
    const selectedWarehouse = warehouses.find((warehouse) => warehouse.id === warehouseId)
    const tenantId = _.first(selectedWarehouse.tenants)?.id

    changeWarehouse(warehouseId)
    changeTenant(tenantId)
    fetchReceptionParameters()
    fetchCustomBerthLabel({ tenantId })
  }

  onChangeTenant = (option) => {
    const { changeTenant, fetchReceptionParameters, fetchCustomBerthLabel } = this.props
    const tenantId = option?.value
    changeTenant(tenantId)
    fetchReceptionParameters()
    fetchCustomBerthLabel({ tenantId })
  }

  onClickRegister = async () => {
    const { tenantId, parameters, initialParameters } = this.props

    const changedParameters = _.differenceWith(parameters, initialParameters, _.isEqual)

    console.assert(changedParameters.length)

    // 名称が未入力で表示になっている場合はエラー
    if (changedParameters.some((parameter) => !parameter.displayName && parameter.displayFlag)) {
      this.props.openError('名称を未入力で表示にはできません', false)
      return
    }

    const data = {
      tenantId,
      parameters: changedParameters.map((parameter) =>
        REQUIRED_PARAM_KEYS.reduce((ac, key) => ({ ...ac, [key]: parameter[key] }), {})
      ),
    }

    this.props.startLoading()

    try {
      await patchReception(data)
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    this.props.fetchReceptionParameters()
    this.props.openToast(FinishToast)
  }

  renderEmpty(
    warehouseOptions,
    tenantOptions,
    warehouseId,
    tenantId,
    onChangeWarehouse,
    onChangeTenant
  ) {
    return (
      <div>
        <Header
          warehouses={warehouseOptions}
          tenants={tenantOptions}
          selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
          selectedTenant={tenantOptions.find((tenant) => tenant.value === tenantId)}
          onChangeWarehouse={onChangeWarehouse}
          onChangeTenant={onChangeTenant}
        />
        <CanNotUse />
      </div>
    )
  }

  render() {
    const {
      warehouseId,
      tenantId,
      parameters,
      warehouses,
      tenants,
      initialParameters,
      userData,
    } = this.props
    const { enablePrompt } = this.state
    const warehouseOptions = warehouses.map((warehouse) => ({
      value: warehouse.id,
      label: warehouse.name,
    }))
    const tenantOptions = tenants.map((tenant) => ({
      value: tenant.id,
      label: tenant.name,
    }))

    const selectedTenant = tenants.find((t) => t.id === tenantId) || {}

    if (!selectedTenant.canCustomizeReception) {
      return this.renderEmpty(
        warehouseOptions,
        tenantOptions,
        warehouseId,
        tenantId,
        this.onChangeWarehouse,
        this.onChangeTenant
      )
    }

    const isSystemAdmin = userData.attributes.is_warehouse_admin
    const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

    const getTypeOfRowItem = (columnName) => {
      if (BASE_PARAMETER.includes(columnName)) return typeRow.BASE
      if (ONLY_CHECKBOX_PARAMETERS.includes(columnName)) return typeRow.ONLY_CHECK_BOX

      return typeRow.IMPORT
    }

    return (
      <div>
        <Header
          warehouses={warehouseOptions}
          tenants={tenantOptions}
          selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
          selectedTenant={tenantOptions.find((t) => t.value === tenantId)}
          onChangeWarehouse={this.onChangeWarehouse}
          onChangeTenant={this.onChangeTenant}
        />

        <div
          className="p-3"
          style={{ height: window.innerHeight - HEADER_HEIGHT, overflowY: 'auto' }}
        >
          <div className="mx-auto" style={{ width: 852 }}>
            <div className="font-weight-bold mb-3">受付カスタマイズ</div>

            {!canUpdate && <CanNotUpdate />}

            <div className="border px-3 pb-3 mx-auto mb-3 bg-white">
              <Tabs currentTabId="items" />
              <div className="mb-3 text-center">
                ドライバー受付時の入力項目として表示/非表示を選択し、表示の場合は必須/任意をお選びください
              </div>
              <div className="m-3">
                <div className="mb-3" style={{ overflowY: 'auto' }}>
                  <table className="w-100">
                    <colgroup>
                      <col />
                      <col style={{ width: 110 }} />
                      <col style={{ width: 100 }} />
                      <col style={{ width: 100 }} />
                      <col style={{ width: 140 }} />
                    </colgroup>
                    <thead className="border-bottom">
                      <tr>
                        <Th className="pl-3 py-3">項目名</Th>
                        <Th />
                        <Th className="text-center py-3">表示順</Th>
                        <Th className="text-center py-3">必須項目</Th>
                        <Th className="text-center py-3">表示／非表示</Th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {parameters.map((item, index) => (
                        <ReceptionItemRow
                          key={item.id}
                          item={item}
                          type={getTypeOfRowItem(item.columnName)}
                          onClickUp={this.onClickUp}
                          onClickDown={this.onClickDown}
                          onChangeRequired={this.onChangeRequired}
                          onChangeVisibility={this.onChangeVisibility}
                          onClickEdit={this.onClickEdit}
                          hideUpButton={index === IMPORT_PARAMETER_FIRST_INDEX}
                          hideDownButton={index === parameters.length - 1}
                          canUpdate={canUpdate}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {canUpdate && (
              <div className="text-right">
                <Button
                  color="primary"
                  className="rounded-pill px-4"
                  disabled={_.isEqual(initialParameters, parameters)}
                  onClick={this.onClickRegister}
                >
                  登録
                </Button>
              </div>
            )}
          </div>
        </div>

        {enablePrompt && (
          <Prompt
            when={!_.isEqual(initialParameters, parameters)}
            message={
              '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
            }
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const receptionCustom = state.get('receptionCustom')
  return {
    warehouseId: receptionCustom.warehouseId,
    tenantId: receptionCustom.tenantId,
    baseParameters: receptionCustom.baseParameters, // TODO: not used
    parameters: receptionCustom.parameters,
    warehouses: receptionCustom.warehouses,
    initialParameters: receptionCustom.initialParameters,
    tenants: receptionCustom.tenants,
    userData: state.get('containers/App').get('userData').toJSON(),
  }
}

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openError,
  fetchAnything,
  openToast,
  selectParameter,
  changeWarehouse,
  changeTenant,
  updateParameters,
  fetchReceptionParameters,
  fetchCustomBerthLabel,
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemsPage)
