import React from 'react'
import { Route } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import loginStorage from 'utils/loginStorage'

class AuthRoute extends React.PureComponent {
  componentDidMount() {
    const { userData, getUserData } = this.props
    const id = localStorage.getItem('userId')
    if (id && isEmpty(userData)) {
      getUserData()
    }
  }

  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={(matchProps) => {
          if (!loginStorage.getAuthData()) {
            loginStorage.clearAuthData()
            window.location.replace('/login')
            return null
          }
          return <Component {...matchProps} />
        }}
      />
    )
  }
}

export default AuthRoute
