import RestClient, { getAuthHeader } from 'services/RestClient'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { FetchReceptionParameterType, PatchReceptionParameterType } from './type'

const client = new RestClient()
const config = {
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader(),
  },
}

const requestGet = (url = '', data = {}) =>
  client
    .get(url, decamelizeKeys(data), config)
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

const requestDelete = (url = '', data: any, params: any) =>
  client
    .delete({ url, params: decamelizeKeys(params), data: decamelizeKeys(data), config })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

const requestPost = (url = '', data: any) =>
  client
    .post({
      url,
      params: {},
      data: decamelizeKeys(data),
      config,
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

const requestPut = (url = '', data: any) =>
  client
    .put({
      url,
      params: {},
      data: decamelizeKeys(data),
      config,
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const requestPatch = (url = '', data = {}, useAuth = true) =>
  client
    .patch({
      url,
      params: {},
      data: decamelizeKeys(data),
      config,
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const getGeneralReceptionCustomize = (general_reception_id: string | number) =>
  requestGet('/general-receptions/customize', { general_reception_id })

export const pathCardViewOrderGeneralCustomize = (data: any) =>
  requestPatch('/general-receptions/card_view_order', { ...data })

export const getGeneralReceptions = () => requestGet('/general-receptions')

export const patchReceptionParameter = (params: PatchReceptionParameterType) =>
  requestPatch('/general-receptions/customize', params)

export const getReceptionOptions = (params: any) =>
  requestGet('/general-receptions/options', params)

export const patchReceptionOptions = (params: any) =>
  requestPatch('/general-receptions/options', params)

export const getGeneralConfirmations = (generalReceptionId: number) =>
  requestGet('/general-receptions/confirmation', { generalReceptionId })

export const updateGeneralConfirmation = (data: any) =>
  requestPatch('/general-receptions/confirmation', decamelizeKeys(data))

export const getRoleOfGeneralReception = (general_reception_id: String) =>
  requestGet('/user-general-reception/roles', { general_reception_id })
