import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { camelizeKeys } from 'humps'
import moment from 'moment'

import {
  fetchAnything,
  openError,
  openModal,
  startLoading,
  stopLoading,
} from 'containers/App/reducers'
import { BOOKING_NUMBER_LENGTH } from 'constants/Booking'
import { canCheckin } from 'constants/misc'
import errorMessages from 'utils/errorMessages'
import { DATE_TIME_FORMAT, DATE_TIME_FORMAT_API } from '../constants'

import NumericKeypad from '../components/NumericKeypad'
import Header from '../components/Header'
import { Backdrop, ModalFrame } from '../components/utils'
import { updateBooking } from '../reducers'
import { requestGet } from '../api'
import PrevButton from 'components/PrevButton'
import AlertModal from 'components/AlertModal'

const filterNumber = (value) => value.replace(/[^0-9]/g, '')

const Input = styled.input`
  border: none;
  width: 140px;
  text-align: center;
  font-size: 40px;
  color: #333;
  background: none;
`

class EntryBookingNumberPage extends Component {
  state = {
    left: '',
    center: '',
    right: '',
    isOpenModalSubmitError: false,
  }

  onClick = (value) => {
    const { left, center, right } = this.state

    let num

    if (left.length < 4) {
      num = { left: `${left}${value}` }
    } else if (center.length < 4) {
      num = { center: `${center}${value}` }
    } else if (right.length < 2) {
      num = { right: `${right}${value}` }
    }

    if (num) {
      this.setState(num)
    }
  }

  onClickBackSpace = () => {
    const { left, center, right } = this.state

    let num

    if (right.length > 0) {
      num = { right: right.substring(0, right.length - 1) }
    } else if (center.length > 0) {
      num = { center: center.substring(0, center.length - 1) }
    } else {
      num = { left: left.substring(0, left.length - 1) }
    }

    this.setState(num)
  }

  onChangeLeft = (e) => {
    const { value } = e.target
    this.setState({ left: filterNumber(value) })
  }

  onChangeCenter = (e) => {
    const { value } = e.target
    this.setState({ center: filterNumber(value) })
  }

  onChangeRight = (e) => {
    const { value } = e.target
    this.setState({ right: filterNumber(value) })
  }

  isValidCheckinTimeBooking = (startDate, startTime, presetMinutesForCheckinTime) => {
    if (presetMinutesForCheckinTime) {
      const currentDay = moment(moment().utcOffset('+09:00').format(DATE_TIME_FORMAT))
      const startTimeBooking = moment(`${startDate} ${startTime}`, DATE_TIME_FORMAT_API)
      const timeAlowChekinBooking = startTimeBooking.subtract(
        presetMinutesForCheckinTime,
        'minutes'
      )

      return currentDay.isBefore(timeAlowChekinBooking)
    }
  }

  onClickEnter = async () => {
    const { left, center, right } = this.state
    const { history, match, driver } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const bookingNo = `${left}${center}${right}`
    const canSettingCheckinTime = driver?.tenant?.canSettingCheckinTime

    let resp

    this.props.startLoading()

    try {
      resp = await requestGet('/tablets/booking_and_driver_info', {
        warehouseId,
        tenantId,
        driverPhone: driver.driverPhone,
        bookingNo,
      })
      const bookingData = camelizeKeys(resp.data)
      const isPresetMinutesForCheckinBooking = this.isValidCheckinTimeBooking(
        bookingData.startDate,
        bookingData.startTime,
        driver?.tenant?.presetMinutesForCheckin
      )
      if (canSettingCheckinTime && isPresetMinutesForCheckinBooking) {
        this.setState({ isOpenModalSubmitError: true })
        return
      }
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const booking = camelizeKeys(resp.data)

    if (!canCheckin(booking.status)) {
      this.props.openModal({
        content: <div>{errorMessages.Receipt.bookingNotFound()}</div>,
        buttons: [
          {
            label: 'OK',
            color: 'primary',
          },
        ],
      })
      return
    }

    this.props.updateBooking(booking)

    history.push(`/receipt-booking/entry-driver-info/${warehouseId}/${tenantId}`)
  }

  handleClickCloseModal = () => {
    this.setState({ isOpenModalSubmitError: false })
  }

  render() {
    const { match, driver, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { left, center, right, isOpenModalSubmitError } = this.state
    const presetMinutesForCheckin = driver?.tenant?.presetMinutesForCheckin

    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header driver={driver} />

        <ModalFrame className="mx-auto p-3" style={{ width: 664 + 32 }}>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div style={{ width: '33%' }}>
              <PrevButton
                onClick={() =>
                  history.push(`/receipt-booking/entry-bookings/${warehouseId}/${tenantId}`)
                }
              />
            </div>
            <div className="text-center text-size24" style={{ width: '34%' }}>
              <span>予約番号を入力</span>
            </div>
            <div style={{ width: '33%' }} />
          </div>

          <div
            className="mx-auto mb-5"
            style={{
              width: 496,
              display: 'flex',
              justifyContent: 'space-around',
              border: '2px solid #4F4F4F',
              borderRadius: 12,
            }}
          >
            {/* TODO set focus */}
            <Input type="text" value={left} maxLength={4} onChange={this.onChangeLeft} />
            <span style={{ fontSize: 40 }}>-</span>
            <Input type="text" value={center} maxLength={4} onChange={this.onChangeCenter} />
            <span style={{ fontSize: 40 }}>-</span>
            <Input type="text" value={right} maxLength={4} onChange={this.onChangeRight} />
          </div>

          <NumericKeypad
            onClick={this.onClick}
            onClickBackSpace={this.onClickBackSpace}
            onClickEnter={this.onClickEnter}
            value={`${left}${center}${right}`}
            canClickEnter={`${left}${center}${right}`.length === BOOKING_NUMBER_LENGTH}
          />
        </ModalFrame>

        <Backdrop />

        <AlertModal
          isOpen={isOpenModalSubmitError}
          title={`受付時間外です。<br/>予約開始時間から${presetMinutesForCheckin}分前に受付できます。`}
          textOk="OK"
          handleConfirm={this.handleClickCloseModal}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
  }
}

const mapDispatchToProps = {
  fetchAnything,
  updateBooking,
  openError,
  startLoading,
  stopLoading,
  openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryBookingNumberPage)
