import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _clone from 'lodash/clone'

function convertYupError(schema, params, error) {
  const schemaError = _clone(error)

  try {
    schema.validateSync(params, { abortEarly: false })
  } catch (err) {
    _forEach(err.inner, ({ path, message }) => {
      if (_isEmpty(schemaError[path])) {
        schemaError[path].push(message)
      }
    })
  }

  return schemaError
}

function summarizeError(inputError) {
  const finalError = {}
  let isError = false

  _forEach(inputError, (value, key) => {
    if (!_isEmpty(value)) {
      finalError[key] = [value.shift()]
      isError = true
    } else {
      finalError[key] = []
    }
  })

  return {
    data: {
      errors: finalError,
      isError,
    },
  }
}

function validateYup(schema, data) {
  let yupData = {}
  let isError = false
  const errors = {}

  try {
    yupData = schema.validateSync(data, { abortEarly: false, stripUnknown: true })
  } catch (err) {
    isError = true
    _forEach(err.inner, ({ path, message }) => {
      if (_isEmpty(errors[path])) {
        errors[path] = message
      }
    })
  }

  return { yupData, errors, isError }
}

export { convertYupError, summarizeError, validateYup }
