import React, { useEffect, FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from 'reactstrap'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { camelizeKeys } from 'humps'
import _ from 'lodash'

import Tabs from '../components/Tabs'
import DriverConfirmations from '../components/DriverConfirmations'
import { ConfirmationType, GeneralConfirmationType, GeneralReceptionType } from '../type'
import { getGeneralConfirmations } from '../api'
import {
  generalReceptionSelectedSelectors,
  settingGeneralConfirmationSelectors,
} from 'containers/IntegratedReceptionCustom/selectors'
import { startLoading, stopLoading, openError } from 'containers/App/reducers'
import { updateInitialGeneralConfirmation, updateSettingGeneralConfirmation } from '../reducer'
import { routes } from '../constants'

type Props = {
  generalSelected: GeneralReceptionType
  settingGeneralConfirmation: GeneralConfirmationType
  updateInitialGeneralConfirmation: (data: any) => void
  updateSettingGeneralConfirmation: (data: any) => void
}

const ConfirmationPage: FC<Props> = ({
  generalSelected,
  settingGeneralConfirmation,
  updateInitialGeneralConfirmation,
  updateSettingGeneralConfirmation,
}) => {
  const { confirmations } = settingGeneralConfirmation || []

  const fetchGeneralConfirmation = async () => {
    startLoading()
    try {
      const res = await getGeneralConfirmations(Number(generalSelected?.id))
      const dataFormat = camelizeKeys(res?.data || [])
      updateInitialGeneralConfirmation({ confirmations: dataFormat })
      updateSettingGeneralConfirmation({ confirmations: dataFormat })
    } catch (error) {
      openError(error.message)
    } finally {
      stopLoading()
    }
  }

  useEffect(() => {
    if (generalSelected?.id) {
      fetchGeneralConfirmation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSelected?.id])

  return (
    <>
      <div className="border px-3 pb-3 mx-auto mb-3 bg-white">
        <Tabs currentTabId="confirmation" />

        {confirmations &&
        confirmations?.filter((confirmation: ConfirmationType) => confirmation.displayFlag).length >
          0 ? (
          <>
            <div className="text-center mb-3">
              受付時、ドライバーへ開示する内容は以下の通りです。
            </div>

            <div className="mb-3 p-3 border" style={{ borderRadius: 12 }}>
              <DriverConfirmations confirmations={confirmations} />
            </div>
          </>
        ) : (
          <div className="text-center mb-3">
            <div>ドライバーへ開示する内容は設定されていません。</div>
            <div>設定する場合は、「編集」ボタンから入力をお願いします。</div>
          </div>
        )}
      </div>

      <div className="text-right">
        <NavLink to={routes.confirmationEditPage}>
          <Button color="primary" className="rounded-pill px-4">
            編集
          </Button>
        </NavLink>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  generalSelected: generalReceptionSelectedSelectors(),
  settingGeneralConfirmation: settingGeneralConfirmationSelectors(),
})

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openError,
  updateInitialGeneralConfirmation,
  updateSettingGeneralConfirmation,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage)
