import React, { FC, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import CsvUploadModal from './CsvUploadModal'

import { ReactComponent as IconArrowDown } from 'assets/svgs/upload-csv/arrow-down.svg'
import { ReactComponent as IconDownLoad } from 'assets/svgs/upload-csv/csv-download.svg'
import { ReactComponent as IconUpload } from 'assets/svgs/upload-csv/csv-upload.svg'
import * as S from './style'

export const MessageUploadSuccess = () => {
  return (
    <>
      <div>CSVアップロードが完了しました。</div>
      <div>現在登録手続き中ですので、完了次第メールにてお知らせします。</div>
      <div>※登録エラーになる可能性もありますので、メールのご確認をお願いします。</div>
    </>
  )
}

type Props = {
  isShowDownload: boolean
  isShowUpload: boolean
  textUpload?: string
  textDownload?: string
  isShowModalUpload?: boolean
  disabled: boolean
  downloadCsv: () => void
  uploadCsv: (fileContent: any) => void
}

const DownloadUploadCSV: FC<Props> = ({
  isShowDownload,
  isShowUpload,
  textDownload,
  textUpload,
  disabled = false,
  downloadCsv,
  uploadCsv,
  isShowModalUpload,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleUploadCsvChange = async (e: any) => {
    const file = e.target.files[0]
    await uploadCsv({
      file,
    })

    setIsOpen(false)
  }

  const handleUploadCsv = async (file: File | null, encodingType: string) => {
    await uploadCsv({
      file,
      encodingType,
    })
    setIsOpen(false)
    setIsOpenModal(false)
  }

  return (
    <>
      <S.UploadCSV>
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <ButtonDropdown isOpen={isOpen} toggle={() => {}}>
            <DropdownToggle onClick={toggle} disabled={disabled}>
              <IconArrowDown />
            </DropdownToggle>
            <DropdownMenu>
              {isShowUpload && (
                <S.CustomDropdownItem onClick={() => isShowModalUpload && setIsOpenModal(true)}>
                  <label htmlFor="upload-csv-booking">
                    <IconUpload />
                    {textUpload}
                    {!isShowModalUpload && (
                      <input
                        id="upload-csv-booking"
                        type="file"
                        accept=".csv"
                        onChange={handleUploadCsvChange}
                      />
                    )}
                  </label>
                </S.CustomDropdownItem>
              )}
              {isShowDownload && (
                <DropdownItem onClick={downloadCsv}>
                  <IconDownLoad />
                  {textDownload}
                </DropdownItem>
              )}
            </DropdownMenu>
          </ButtonDropdown>
        </OutsideClickHandler>
      </S.UploadCSV>
      {isShowModalUpload && (
        <CsvUploadModal
          isOpen={isOpenModal}
          uploadCsv={handleUploadCsv}
          onClose={() => setIsOpenModal(false)}
        />
      )}
    </>
  )
}

export default DownloadUploadCSV
