Object.keys(process.env).reduce((ac, key) => {
  if (!key.startsWith('REACT_APP_')) return ac

  const val = process.env[key]
  if (['true', 'false'].includes(val)) {
    return { ...ac, [key]: (process.env[key] = val === 'true') }
  }

  return ac
}, process.env)
