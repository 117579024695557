import styled, { css } from 'styled-components'

import LoadingIndicator from 'components/LoadingIndicator'
import theme from 'vars/theme'

import { HEADER_HEIGHT } from '../constants'

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;

  a {
    padding: 0 50px;

    &:first-child {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
    }

    &:last-child {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
    }
  }
`

export const CustomSettingWrapper = styled.div`
  height: calc((var(--vh, 1vh) * 100) - ${HEADER_HEIGHT}px);
  padding: 16px;
  overflow-y: auto;
`

export const CustomSettingContainer = styled.div`
  width: 852px;
  margin: 0 auto;
`

export const CustomSettingContent = styled.div`
  margin: 0 auto;
  padding: 0 1rem 1rem;
  background: ${theme.whitePrimary};
  border: 1px solid ${theme.grayPrimary2};
`

export const BlockCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Tr = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${theme.grayPrimary2};
`

export const Td = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${theme.grayPrimary2};
`

export const CustomButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
`

export const EmptyCircle = styled.div`
  border: 1px solid gray;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -14px 0 -22px;
`

export const EditContentConfirmation = styled.div`
  background-color: ${theme.whitePrimary};
  border: 1px solid ${theme.grayPrimary2};
  padding: 16px 16px 0;
  margin-bottom: 16px;
`

export const Pre = styled.pre`
  // wrap text in pre tag
  // ref: https://stackoverflow.com/questions/248011/how-do-i-wrap-text-in-a-pre-tag
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */

  // 連続した半角文字の場合も改行する
  word-break: break-all;
  text-align: start;
  font-size: 16px;
`

export const WrapDriverConfirmation = styled.div`
  flex: 1;
  position: relative;
  border: 1px solid ${theme.graySoft12};
  padding: 1rem;
  margin: 0 0.5rem;

  ${({ checkFlag }) =>
    checkFlag &&
    css`
      cursor: pointer;
    `};

  ${({ isImportant }) =>
    isImportant &&
    css`
      border: 4px solid ${theme.redPrimary1};
    `};
`

export const ContentDriverConfirmation = styled.div`
  height: 260px;
  overflow-y: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  ${({ isAboveText }) =>
    isAboveText &&
    css`
      & > img {
        order: 1;
        margin-bottom: 0px;
      }

      & .wrap-pre-content {
        order: 2;
        margin-bottom: 25px;
      }
    `};
`

export const ImageContent = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: zoom-in;
`

export const ImageContentModal = styled.img`
  max-width: 100%;
`

export const TextImportant = styled.span`
  position: absolute;
  top: -45px;
  left: -4px;
  color: white;
  background: ${theme.redPrimary1};
  padding: 3px 12px;
`

export const IconCloseModal = styled.span`
  position: fixed;
  top: 65px;
  right: 30px;
  background: black;
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 2px 12px 4px 12px;
  border-radius: 3px;

  img {
    width: 10px;
  }
`

export const LoadingCustom = styled(LoadingIndicator)`
  z-index: 1;
`

export const CheckboxWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 16px;
`

export const CustomStylesModal: any = {
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px',
    border: 'none',
    background: 'none',
    inset: 'unset',
    minHeight: '100%',
  },

  overlay: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.graySoft13,
    overflowY: 'auto',
  },
}

export const TextDisable = styled.div`
  color: ${theme.grayMedium3};
`

export const CheckBoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
