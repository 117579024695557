import React, { useMemo, useState } from 'react'

import CustomTable from 'components/CustomTable'
import Button from 'components/Button'
import { calculatePagination, compareValue } from 'utils/commonFunctions'
import CreatableSelect from 'components/SelectOptions/CreatableSelect'
import CreatableSelectLoading from 'components/SelectOptions/CreatableSelectLoading'
import SingleForm from 'components/SingleForm'
import SearchDriverForm from 'components/SearchDriverForm'
import Pagination from 'containers/HomePage/components/Pagination'

import { PER_PAGE, TableHeaderOfAddControllerPage, DEFAULT_OPTION } from '../constants'
import { ConfirmModalProps } from '../type'
import useAddController from '../hooks/useAddController'
import * as S from '../style'
import ConfirmControllerModal from 'components/ConfirmModal/ConfirmControllerModal'

type Props = {
  hasAdminCompanyPermission: boolean
  hasManagerGeneralPermission: boolean
  handleCancel: () => void
  showConfirmModal: (confirmModalProps: ConfirmModalProps) => void
}

const AddControllerPage: React.FC<Props> = ({
  hasAdminCompanyPermission,
  hasManagerGeneralPermission,
  handleCancel,
  showConfirmModal,
}) => {
  const {
    loading,
    formData,
    controllersInfo,
    userOptions,
    companyOptions,
    error,
    handleOnChange,
    handleFindUserByEmail,
    handleChangePage,
    handleRemoveController,
    handleSubmit,
    keyword,
    handleSearchDriver,
    isLoadingCompany,
    handleScrollMenuSelectToBottomCompany,
    handleInputChangeCompany,
    handleOnFocusSelectCompany,
    handleOnBlurSelectCompany,
    infoConfirmControllerModal,
    handleConfirmController,
    handleCanceController,
  } = useAddController(showConfirmModal)

  const [keywordDraff, setKeywordDraff] = useState<string>('')

  const listController = useMemo(() => {
    const { userGeneralReception } = controllersInfo
    return userGeneralReception.map((user) => ({
      id: user.userId,
      ButtonCustom: () => (
        <Button
          color="red"
          width="88px"
          style={{ fontSize: '12px' }}
          onClick={() => handleRemoveController(user)}
          disabled={!(hasAdminCompanyPermission || hasManagerGeneralPermission) || loading}
          context="secondary"
        >
          削除
        </Button>
      ),
      ...user,
    }))
  }, [
    controllersInfo,
    handleRemoveController,
    hasAdminCompanyPermission,
    hasManagerGeneralPermission,
    loading,
  ])

  const isDisabledInfo = useMemo<{
    telNumber: boolean
    email: boolean
  }>(() => {
    const foundedUser = userOptions.find((user) => compareValue(user.id, formData.id))

    return {
      telNumber: !!foundedUser && !!foundedUser.telNumber,
      email: !!foundedUser && !!foundedUser.email,
    }
  }, [formData.id, userOptions])

  const { end, start, totalItems } = calculatePagination(
    controllersInfo.page,
    controllersInfo?.totalCount,
    PER_PAGE
  )
  return (
    <S.Wrapper>
      <div className="px-2">
        <h2 className="py-3 text-center">予約者（限定）追加</h2>
        <S.WrapperInput>
          <CreatableSelectLoading
            className="col-md-2"
            label="運送会社名"
            options={companyOptions}
            onChange={handleOnChange('company')}
            value={formData.companyId}
            errorMessage={error.companyName}
            isLoading={isLoadingCompany}
            defaultOption={DEFAULT_OPTION}
            onMenuScrollToBottom={handleScrollMenuSelectToBottomCompany}
            onInputChange={handleInputChangeCompany}
            onFocus={handleOnFocusSelectCompany}
            onBlur={handleOnBlurSelectCompany}
          />
          <CreatableSelect
            className="col-md-2"
            label="名前"
            options={userOptions}
            onChange={handleOnChange('user')}
            value={formData.id}
            errorMessage={error.name}
            maxLength={50}
          />
          <SingleForm
            className="col-md-2"
            id="telNumber"
            isDisabled={!formData.id || isDisabledInfo.telNumber}
            name="telNumber"
            label={'電話'}
            value={formData.telNumber}
            placeholder="電話番号を入力"
            onChange={handleOnChange('telNumber')}
            errorMessage={error.telNumber}
          />
          <SingleForm
            className="col-md-2"
            id="email"
            isDisabled={!formData.id || isDisabledInfo.email}
            name="email"
            label={'メール'}
            onBlur={(event: any) => {
              const isSubmitting = event.relatedTarget && event.relatedTarget.type === 'submit'
              if (!isSubmitting) {
                handleFindUserByEmail()
              }
            }}
            value={formData.email}
            placeholder="メールを入力"
            onChange={handleOnChange('email')}
            errorMessage={error.email}
          />
          <S.WrapperAddButton>
            <Button
              type="submit"
              color="blue"
              width="112px"
              style={{ fontSize: '12px' }}
              className="mr-3"
              onClick={() => {
                handleSubmit()
                if (keyword !== keywordDraff) {
                  setKeywordDraff(keyword)
                }
              }}
              disabled={!(hasAdminCompanyPermission || hasManagerGeneralPermission) || loading}
            >
              追加
            </Button>
          </S.WrapperAddButton>
        </S.WrapperInput>
      </div>
      <S.HeaderTable>
        <SearchDriverForm
          placeholder="運送会社/名前/電話番号/メールで検索"
          value={keywordDraff}
          maxLength={200}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setKeywordDraff(event.target.value)
          }
          onClickClearSearch={() => {
            setKeywordDraff('')
          }}
          onClickSearch={() => handleSearchDriver(keywordDraff)}
          disableButton={!keywordDraff}
        />
        {!!controllersInfo.totalPages && (
          <S.WrapperPagination>
            <S.PaginationTextResult>
              <span className="total-item">{totalItems}</span>件
              <span>
                {start}-{end}
              </span>
              件を表示中
            </S.PaginationTextResult>
            <Pagination
              meta={{
                total: controllersInfo.totalPages,
                current: controllersInfo.page,
              }}
              onNavigateRequest={(page: number) => {
                handleChangePage(page, keywordDraff, setKeywordDraff)
              }}
            />
          </S.WrapperPagination>
        )}
      </S.HeaderTable>
      <CustomTable
        data={listController}
        columns={TableHeaderOfAddControllerPage}
        withPagination={false}
        emptyMessage="予約者（限定）がありません。"
      />
      <S.BottomWrapper>
        <Button
          type="submit"
          color="blue"
          width="124px"
          context="secondary"
          style={{ fontSize: '12px' }}
          onClick={handleCancel}
        >
          戻る
        </Button>
      </S.BottomWrapper>
      <ConfirmControllerModal
        isOpen={infoConfirmControllerModal.isOpen}
        label="予約者（限定）"
        companyName={infoConfirmControllerModal?.message?.companyName}
        vehicleControllerName={infoConfirmControllerModal?.message?.name}
        handleConfirm={handleConfirmController}
        handleCancel={handleCanceController}
      />
    </S.Wrapper>
  )
}

export default React.memo(AddControllerPage)
