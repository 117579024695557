import _ from 'lodash'

export const BaseTenantParameterDisplayOrders = Object.freeze({
  vehicle_number: 1,
  company_name: 2,
  driver_name: 3,
  vehicle_category: 4,
  import_export_enable: 5,
})

// TODO TenantParameterFormatTypes にする
export const TenantParameterTypes = {
  READ_ONLY: {
    id: 0,
    key: 'READ_ONLY',
    label: '変更不可',
  },
  TEXT: {
    id: 1,
    key: 'TEXT',
    label: '記述式',
  },
  SELECT: {
    id: 2,
    key: 'SELECT',
    label: '選択式',
  },
}

// 項目名の文字数
export const PARAMETER_NAME_LENGTH = 10

// 選択式の選択肢の名称の文字数
export const OPTION_NAME_LENGTH = 20

export const CONFIRMATION_CONTENT_LENGTH = 100

export const NUMBER_OF_ADDITIONAL_COLUMNS = 23

export const ADDITIONAL_COLUMNS_RANGE = _.range(1, NUMBER_OF_ADDITIONAL_COLUMNS + 1) // array 1 -> 23

const ADDITIONAL_COLUMNS = ADDITIONAL_COLUMNS_RANGE.reduce(
  (ac, id) => ({
    ...ac,
    [`ADDITIONAL_COLUMN${id}`]: {
      key: `ADDITIONAL_COLUMN${id}`,
      id: id + 2,
      name: `追加項目${id}`,
    },
  }),
  {}
)

export const ADDITIONAL_COLUMN_KEYS = _.toArray(ADDITIONAL_COLUMNS).map((item) => item.key)

export const ReceptionCustomOptionGroups = {
  VEHICLE_CATEGORY: {
    key: 'VEHICLE_CATEGORY',
    id: 1,
    name: '車両形態',
  },
  PACKAGE_TYPE: {
    key: 'PACKAGE_TYPE',
    id: 2,
    name: '荷姿',
  },
  ...ADDITIONAL_COLUMNS,
}
