import { fromJS } from 'immutable'
import { handleActions, createActions } from 'redux-actions'
import _ from 'lodash'

const initialState = fromJS({
  loading: false,
  isLoadingGeneralSuccess: false,
  generalReceptions: [],
  error: null,
  selectedGeneralReception: {},
})

export const {
  startLoading,
  endLoading,
  fetchFailed,
  fetchDataGeneralReceptions,
  fetchDataGeneralReceptionsSuccess,
  updateSelectedGeneralReception,
  downLoadGeneralCsv,
  resetStateDownloadGeneral,
} = createActions({
  START_LOADING: _.noop,
  END_LOADING: _.noop,
  FETCH_FAILED: (error) => error,
  FETCH_DATA_GENERAL_RECEPTIONS: (type?: string) => type,
  FETCH_DATA_GENERAL_RECEPTIONS_SUCCESS: (data) => data,
  UPDATE_SELECTED_GENERAL_RECEPTION: (data) => data,
  DOWN_LOAD_GENERAL_CSV: (data) => data,
  RESET_STATE_DOWNLOAD_GENERAL: _.noop,
})

export const reducer = handleActions(
  {
    START_LOADING: (state) => state.set('loading', true),
    END_LOADING: (state) => state.set('loading', false),
    FETCH_FAILED: (state, { payload }) => state.set('loading', false).set('error', payload),
    FETCH_DATA_GENERAL_RECEPTIONS_SUCCESS: (state, { payload }) => {
      const { generalReception, selectedReception } = payload

      if (selectedReception) {
        return state
          .set('generalReceptions', generalReception)
          .set('selectedGeneralReception', selectedReception)
          .set('isLoadingGeneralSuccess', true)
      }

      return state.set('generalReceptions', generalReception).set('isLoadingGeneralSuccess', true)
    },
    UPDATE_SELECTED_GENERAL_RECEPTION: (state, { payload }) =>
      state.set('selectedGeneralReception', payload),
    RESET_STATE_DOWNLOAD_GENERAL: (state) =>
      state
        .set('loading', false)
        .set('isLoadingGeneralSuccess', false)
        .set('generalReceptions', [])
        .set('error', null)
        .set('selectedGeneralReception', {}),
  },
  initialState
)
