import React from 'react'

import Switch from 'components/Switch'
import Icon from 'components/Icon'
import { typeRow } from '../../constants'
import { ParameterItemType } from '../../type'
import * as S from './styled'

interface ReceptionItemRowProps {
  item: ParameterItemType
  type: string
  hideUpButton: boolean
  hideDownButton: boolean
  canUpdate: boolean
  onClickUp: (params: ParameterItemType) => void
  onClickDown: (params: ParameterItemType) => void
  onChangeVisibility: (params: ParameterItemType) => void
}

export default ({
  item,
  type,
  hideUpButton,
  hideDownButton,
  onClickUp,
  onClickDown,
  onChangeVisibility,
  canUpdate,
}: ReceptionItemRowProps) => {
  const canUpdateReceptionRow = type !== typeRow.BASE && canUpdate
  return (
    <S.Tr>
      <td className="pl-3">
        {item.displayName ? item.displayName : <S.TextDisable>未設定</S.TextDisable>}
      </td>
      <td>
        {canUpdateReceptionRow && type !== typeRow.ONLY_CHECK_BOX && (
          <S.CheckBoxContent>
            {hideDownButton ? (
              <S.CustomButton disabled>
                <S.EmptyCircle />
              </S.CustomButton>
            ) : (
              <S.CustomButton
                onClick={() => {
                  onClickDown(item)
                }}
              >
                <Icon src="/assets/svgs/circle-down-arrow.svg" width={26} height={26} />
              </S.CustomButton>
            )}
            {hideUpButton ? (
              <S.CustomButton disabled>
                <S.EmptyCircle />
              </S.CustomButton>
            ) : (
              <S.CustomButton onClick={() => onClickUp(item)}>
                <Icon src="/assets/svgs/circle-up-arrow.svg" width={26} height={26} />
              </S.CustomButton>
            )}
          </S.CheckBoxContent>
        )}
      </td>
      <td className="d-flex justify-content-center">
        {type === typeRow.ONLY_CHECK_BOX ? (
          '-'
        ) : (
          <Switch
            checked={item.displayFlag}
            disabled={!canUpdateReceptionRow}
            onChange={() => onChangeVisibility(item)}
          />
        )}
      </td>
    </S.Tr>
  )
}
