import _ from 'lodash'
import * as Yup from 'yup'

import { calculateTimeWaitingBooking } from './constants'
import errorMessages from 'utils/errorMessages'

const handleMultipleOf5To360 = (value: any) => {
  const minNumber = _.min(calculateTimeWaitingBooking)
  const maxNumber = _.max(calculateTimeWaitingBooking)
  return (
    Number(value) >= Number(minNumber) &&
    Number(value) <= Number(maxNumber) &&
    _.includes(calculateTimeWaitingBooking, Number(value))
  )
}

export const addTimeWaitingSchema = Yup.object().shape({
  timeMin: Yup.string()
    .test(
      'timeMin',
      `${errorMessages.SettingTimeWaitingBooking.inputNotMultipleOf5To360()}`,
      handleMultipleOf5To360
    )
    .test(
      'timeMin',
      `${errorMessages.SettingTimeWaitingBooking.inputMinGetterThanMax()}`,
      function (value: any) {
        let timeMaxValue = this.parent['timeMax']
        return !(Number(timeMaxValue) <= Number(value))
      }
    ),
  timeMax: Yup.string().test(
    'timeMax',
    `${errorMessages.SettingTimeWaitingBooking.inputNotMultipleOf5To360()}`,
    handleMultipleOf5To360
  ),
})

export const addAverageTimeWaitingSchema = Yup.object().shape({
  timeAverage: Yup.string().test(
    'timeAverage',
    `${errorMessages.SettingTimeWaitingBooking.inputNotMultipleOf5To360()}`,
    handleMultipleOf5To360
  ),
})
