import moment from 'moment'
import _ from 'lodash'

import { STANDARD_PLAN_DAY_FORMAT, STANDARD_DAY_FORMAT } from './constants'

export const formatDate = (date: string) => {
  return moment(date, STANDARD_PLAN_DAY_FORMAT).format(STANDARD_DAY_FORMAT)
}

export const convertAttachFile = (attach?: string): string => (attach ? attach.split('/')[1] : '')

export const formatDriverTelNumber = (telNumber: string) => {
  if (!telNumber) return ''

  const len = telNumber.length
  const right = telNumber.substr(len - 4, 4)
  const center = telNumber.substr(len - 8, 4)
  const left = telNumber.substring(0, len - 8)
  return `${left}-${center}-${right}`
}

export const getAdditionalColumnName = (str: string) => {
  if (str) {
    return str?.replace(/(.{5})/g, '$1<br>')
  }
}

export const formatCarNumber = (carNumber: string) => {
  if (!carNumber) return ''
  const left = String(carNumber).substr(0, 2)
  const right = String(carNumber).substr(2, 2)
  return `[${left}-${right}]`
}
