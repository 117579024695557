import React from 'react'
import styled from 'styled-components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom'

import { isBookingServiceSelectors } from 'containers/HomePage/selectors'
import { uploadCsvRequest } from 'containers/HomePage/actions'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { isUserWarehouseManager } from 'containers/AppPrivate/selectors'
import { MenuItem } from 'components/Layout/style'
import { SHOW_CSV_UPLOAD } from 'constants/misc'
import theme from 'vars/theme'

const Wrapper = styled.span`
  color: ${theme.whitePrimary};

  .upload-csv-input {
    height: 0;
    position: absolute;
    left: -100000px;
  }

  .csv-label {
    display: flex;
    justify-content: flex-start;
    padding: 10px 24px;
    cursor: pointer;

    .csv-icon {
      margin-right: 18px;
    }
  }

  .download-csv-btn {
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

class CsvContainers extends React.PureComponent {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  handleRequestDownloadCSV = () => {
    this.props.closeSideBar()
    this.props.history.push('/csv-download')
  }

  handleUploadCsvChange = (event) => {
    const { uploadCSV, closeSideBar } = this.props
    closeSideBar()
    const file = event.target.files[0]
    const { size: file_size, name: file_name } = file
    if (file_size > 10485760) {
      this.inputRef.current.value = ''
      this.props.showFlashMessage({
        message: 'アップロードするファイルは10MBより越えました。',
        isError: true,
      })
    } else {
      this.toBase64File(file).then((res) => {
        const file_content = res.split(',')[1]
        const data = {
          csv_file: {
            file_name,
            file_size,
            file_content,
          },
        }
        this.inputRef.current.value = ''
        uploadCSV(data)
      })
    }
  }

  toBase64File = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  render() {
    const { isManager, isBookingService } = this.props
    if (!isManager) return null
    return (
      <React.Fragment>
        <Wrapper className="d-flex flex-column">
          {isBookingService && SHOW_CSV_UPLOAD && (
            <MenuItem as="label" htmlFor="upload-csv" className="mb-0 csv-label">
              CSVアップロード（旧）
              <input
                id="upload-csv"
                className="upload-csv-input"
                type="file"
                accept=".csv"
                ref={this.inputRef}
                onChange={this.handleUploadCsvChange}
              />
            </MenuItem>
          )}
          <MenuItem
            as="div"
            onClick={this.handleRequestDownloadCSV}
            role="presentation"
            className="csv-label"
          >
            <span>CSVダウンロード（旧）</span>
          </MenuItem>
        </Wrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  isBookingService: isBookingServiceSelectors(),
  isManager: isUserWarehouseManager(),
})
const matchDispatchToProps = (dispatch) => ({
  uploadCSV: (data) => dispatch(uploadCsvRequest(data)),
  showFlashMessage: (data) => dispatch(showFlashMessage(data)),
})
const withConnect = connect(mapStateToProps, matchDispatchToProps)

export default compose(withRouter, withConnect)(CsvContainers)
