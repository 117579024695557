import React, { memo } from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import _ from 'lodash'

import { compareValue } from 'utils/commonFunctions'

import * as S from '../style'
import { GeneralReceptionType } from '../type'

export type Props = {
  generalReceptions: GeneralReceptionType[]
  idActive: number
  onChange: (generalChoose: GeneralReceptionType) => void
}

const GeneralReceptionList: React.FC<Props> = memo(
  ({ generalReceptions = [], idActive, onChange }) => {
    const history = useHistory()

    const checkInTheGeneralReception = () => {
      return matchPath(_.get(history, 'location.pathname', ''), {
        path: '/multi-setting',
        exact: true,
      })
    }

    return (
      <React.Fragment>
        {generalReceptions.map((reception) => {
          const isActive = compareValue(reception.id, idActive)
          const className = `justify-content-between ${isActive ? 'active' : ''}`

          return (
            <S.GeneralReceptionList
              key={reception.id}
              className={className}
              onClick={() => {
                if (!checkInTheGeneralReception()) {
                  history.replace('/multi-setting')
                }
                onChange(reception)
              }}
              title={reception.name}
            >
              <span className="text-ellipsis">{reception.name}</span>
            </S.GeneralReceptionList>
          )
        })}
      </React.Fragment>
    )
  }
)

export default GeneralReceptionList
