import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import { SelectOptionsWrapper } from 'components/SelectOptions/style'
import theme from 'vars/theme'

const StyledSelect = styled(Select)`
  .mono-select__control {
    background-color: white;
    border: 1px solid #cccccc;
  }

  .mono-select__menu {
    background-color: white;
    color: #505155;
    .mono-select__option {
      color: #505155;
    }

    .mono-select__option--is-focused {
      background-color: rgba(77, 79, 83, 0.2);
    }

    .mono-select__option--is-selected {
      color: white;
      background-color: #505155;
    }
  }

  .mono-select__single-value {
    color: #505155;
  }

  .mono-select__indicator > svg {
    fill: ${theme.blueMedium5};
  }
`

export default (props) => {
  const {
    width,
    className,
    style,
    options,
    placeholder,
    hasClass,
    name,
    color,
    isSearchable,
    isError,
    label,
    isDisabled,
    required,
    onChange,
    value,
  } = props

  return (
    <div className={`d-flex flex-shrink-0 ${label ? 'w-100 mb-2' : ''}`} style={{ width }}>
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label className="label-flex">
          {label}
          {required && <span className="color-red-med1 font-weight-bold">*</span>}
        </label>
      )}
      <div className={`flex-column align-items-start ${label ? 'input-flex' : 'w-100'}`}>
        <SelectOptionsWrapper
          width={width}
          className={className}
          style={style}
          color={color}
          isError={isError}
        >
          <StyledSelect
            className={hasClass ? 'ml-1' : ''}
            classNamePrefix="mono-select"
            clearable={false}
            name={name}
            options={options}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            menuPlacement="auto"
            noOptionsMessage={() => '選択値なし'}
          />
        </SelectOptionsWrapper>
      </div>
    </div>
  )
}
