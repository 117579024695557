// recursively freeze
const freezeR = (value) => {
  if (typeof value === 'object') {
    Object.keys(value).map((key) => freezeR(value[key]))
    Object.freeze(value)
  } else if (Array.isArray(value)) {
    value.map((item) => freezeR(item))
  }
  return value
}

export default freezeR
