import React, { Component } from 'react'
import { connect } from 'react-redux'

import PolicyModal from 'components/PolicyModal'
import { startLoading, stopLoading, openError } from 'containers/App/reducers'
import { requestPatch } from '../api'

class TermsOfServicePage extends Component {
  componentDidMount() {
    const { match, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
    }
  }

  onClickAccept = async () => {
    const { match, driver } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    this.props.startLoading()

    try {
      await requestPatch(
        '/tablets/update_policy_checked',
        {
          phoneNumber: driver.driverPhone,
          policyId: driver.policyData.id,
        }
      )
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const url = driver.tenant.canBerthBook
      ? `/receipt-booking/entry-bookings/${warehouseId}/${tenantId}`
      : `/receipt-booking/entry-driver-info/${warehouseId}/${tenantId}`

    this.props.history.push(url)
  }

  onClickCancel = () => {
    const { match, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
  }

  render() {
    const { driver } = this.props

    return (
      <PolicyModal
        isOpen
        policyData={driver.policyData}
        onAccept={this.onClickAccept}
        onCancel={this.onClickCancel}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
    returnEntryPage: reception.returnEntryPage,
  }
}

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openError,
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfServicePage)
