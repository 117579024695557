import React, { memo, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'

import { useDispatch } from 'reduxStoreHelper'
import Header from '../components/Header'
import {
  Wrapper,
  Content,
  WrapperTitleCustom,
  HeaderBox,
  LabelTitleBottom,
  WrapperButton,
  TextBold,
  ButtonOutline,
  ImageLogo,
} from '../style'
import { ButtonRedirectMode, INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import { fetchSuccess, setGeneralReceptionDetail } from '../reducer'
import { startLoading, stopLoading, openError } from 'containers/App/reducers'
import { getGeneralReceptionDetail } from '../api'
import MonofulLogo from 'assets/svgs/monoful-logo-background-white.svg'
import theme from 'vars/theme'

type props = {
  generalReceptionId?: string
}

const EntryPage: React.FC<props> = ({ generalReceptionId }) => {
  const { generalReceptionNumber }: any = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      fetchSuccess({
        key: 'isReturnEntryPage',
        data: false,
      })
    )
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      dispatch(startLoading())
      try {
        const data = await getGeneralReceptionDetail(generalReceptionId)
        const generalReceptionDetail = _.get(data, 'data', {})
        dispatch(setGeneralReceptionDetail(generalReceptionDetail))
      } catch (err) {
        dispatch(openError(err.message, false))
      } finally {
        dispatch(stopLoading())
      }
    })()
  }, [dispatch, generalReceptionId])

  const handleClickRedirectPage = (mode: string) => {
    if (mode === ButtonRedirectMode.CHECKIN) {
      return history.push(
        `${INTEGRATED_RECEPTION_URL_BASE}/entry-tel-number/${generalReceptionNumber}`
      )
    }
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/exit/${generalReceptionNumber}`)
  }

  return (
    <Wrapper style={{ minWidth: 1024 }}>
      <Header />
      <Content>
        <WrapperTitleCustom>
          <LabelTitleBottom>
            <ImageLogo src={MonofulLogo} alt="monoful logo" />
            トラック簿
          </LabelTitleBottom>
          <WrapperButton className="mx-auto">
            <ButtonOutline
              color={theme.blueMedium5}
              onClick={() => handleClickRedirectPage(ButtonRedirectMode.CHECKIN)}
            >
              <TextBold>入場</TextBold>受付
            </ButtonOutline>
            <ButtonOutline
              color={theme.redPrimary1}
              onClick={() => handleClickRedirectPage(ButtonRedirectMode.CHECKOUT)}
            >
              <TextBold>退場</TextBold>受付
            </ButtonOutline>
          </WrapperButton>
        </WrapperTitleCustom>

        <div className="d-flex justify-content-between">
          <HeaderBox />
          <HeaderBox />
        </div>
      </Content>
    </Wrapper>
  )
}

export default memo(EntryPage)
