import RestClient, { getAuthHeader } from './RestClient'
import { trimData } from '../utils/commonFunctions'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})
export default class WarehouseServices {
  constructor(restClient = RestClient) {
    this.restClient = restClient
  }

  getWarehouses = () =>
    this.restClient.get(
      'warehouse/list_tenants_for_warehouse',
      {
        page: 1,
        per_page: 300,
      },
      {
        headers: headers(),
      }
    )

  getWarehouse = (id) =>
    this.restClient.get(
      'warehouse',
      {
        id,
      },
      {
        headers: headers(),
      }
    )

  getWarehouseBerths = (data) =>
    this.restClient.get(
      'warehouse/berths',
      {
        ...data,
      },
      {
        headers: headers(),
      }
    )

  getWarehouseManagers = (data) =>
    this.restClient.get(
      'warehouse/load_user_by_role',
      {
        ...data,
      },
      {
        headers: headers(),
      }
    )

  getWarehouseUsers = (data) =>
    this.restClient.get(
      'warehouse/user_warehouses',
      {
        ...data,
      },
      {
        headers: headers(),
      }
    )

  getWarehouseNew = () =>
    this.restClient.get(
      'warehouse/new',
      {},
      {
        headers: headers(),
      }
    )

  getWarehouseEdit = (id) =>
    this.restClient.get(
      'warehouse/edit',
      {
        usage_tenants: true,
        id,
      },
      {
        headers: headers(),
      }
    )

  getSalesOffices = (department_id) =>
    this.restClient.get(
      'warehouse/sale_office_by_department',
      {
        department_id,
      },
      {
        headers: headers(),
      }
    )

  createWarehouse = (data) =>
    this.restClient.post({
      url: 'warehouse',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  createWarehouseManager = (data) =>
    this.restClient.post({
      url: 'warehouse/user_warehouse',
      data,
      config: {
        headers: headers(),
      },
    })

  updateWarehouse = (data) =>
    this.restClient.patch({
      url: 'warehouse',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  deleteWarehouse = (data) =>
    this.restClient.delete({
      url: 'warehouse',
      data: {
        ...data,
      },
      config: {
        headers: headers(),
      },
    })

  deleteWarehouseManager = (data) =>
    this.restClient.put({
      url: 'warehouse/remove_role',
      data,
      config: {
        headers: headers(),
      },
    })

  deleteBerth = (data) =>
    this.restClient.delete({
      url: 'warehouse',
      data: {
        ...data,
      },
      config: {
        headers: headers(),
      },
    })

  getBerthNew = (data) =>
    this.restClient.get(
      'warehouse/berth/new',
      {
        ...data,
      },
      {
        headers: headers(),
      }
    )

  createBerth = (data) =>
    this.restClient.post({
      url: 'warehouse/berth',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  getBerth = (data) =>
    this.restClient.get(
      'warehouse/berth',
      {
        ...data,
      },
      {
        headers: headers(),
      }
    )

  updateBerth = (data) =>
    this.restClient.patch({
      url: 'warehouse/berth',
      data: {
        ...data,
      },
      config: {
        headers: headers(),
      },
    })

  deleteBerth = (data) =>
    this.restClient.delete({
      url: 'warehouse/berth',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  getPostalCodeData = (code) =>
    this.restClient.get(
      '/postal_code',
      { postal_code: code },
      {
        headers: headers(),
      }
    )

  updateTenant = (data) =>
    this.restClient.put({
      url: 'tenant',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  updateMessage = (data) =>
    this.restClient.put({
      url: '/tenant_customize_messages',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  getGroupDelegations = (data) =>
    this.restClient.get(
      '/warehouse/tenant/group_delegations',
      { ...data },
      {
        headers: headers(),
      }
    )

  addDelegationGroup = (data) =>
    this.restClient.post({
      url: '/warehouse/tenant/group_delegations',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  editDelegationGroup = (data) =>
    this.restClient.put({
      url: '/warehouse/tenant/group_delegations',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })

  deleteDelegationGroup = (id) =>
    this.restClient.delete({
      url: `/warehouse/tenant/group_delegations`,
      params: { id },
      config: {
        headers: headers(),
      },
    })

  getDelegationGroupDetail = (id) =>
    this.restClient.get(
      '/warehouse/tenant/group_delegations/detail',
      {
        group_delegation_id: id,
      },
      {
        headers: headers(),
      }
    )

  getBerthGroup = (data) =>
    this.restClient.get(
      '/berth-groups',
      { ...data },
      {
        headers: headers(),
      }
    )

  addBerthGroup = (data) =>
    this.restClient.post({
      url: '/berth-groups',
      data: trimData(data),
      config: {
        headers: headers(),
      },
    })

  deleteBerthGroup = (idGroupBerth, tenantId) =>
    this.restClient.delete({
      url: `/berth-groups`,
      params: { berth_group_id: idGroupBerth, tenant_id: tenantId },
      config: {
        headers: headers(),
      },
    })

  getBerthGroupDetail = (idGroupBerth, tenantId) =>
    this.restClient.get(
      '/berth-groups/detail',
      {
        berth_group_id: idGroupBerth,
        tenant_id: tenantId,
      },
      {
        headers: headers(),
      }
    )

  editBerthGroup = (data) =>
    this.restClient.put({
      url: '/berth-groups',
      data: data,
      config: {
        headers: headers(),
      },
    })

  updateCheckinTime(data) {
    return this.restClient.patch({
      url: 'tenant/checkin_time',
      data: data,
      config: {
        headers: headers(),
      },
    })
  }
}
