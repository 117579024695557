import React, { memo } from 'react'

import * as S from '../style'
import SelectBase from 'components/SelectOptions/SelectBase'

type PropsSelect = {
  label: string
  value: string
  optionMap: { [key: string]: { value: any; label: any }[] }
  columnName: string
  requiredFlag: boolean
  onChange: (value: string) => void
}

const ImportExportSelectRow: React.FC<PropsSelect> = ({
  optionMap,
  columnName,
  requiredFlag,
  label,
  value,
  onChange,
}) => {
  const convertToPackageTypeOptions = (): JSX.Element => {
    //@ts-ignore
    const dataOption = optionMap[columnName]

    return (
      <React.Fragment>
        <option value=""></option>
        {(dataOption || []).map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </React.Fragment>
    )
  }

  return (
    <S.SelectInputWrapper>
      <S.RequiredText>{requiredFlag && '必須'}</S.RequiredText>
      <div style={{ width: 145 }} className="label">
        {label}
      </div>
      <div style={{ width: 255 }}>
        <SelectBase
          className="w-100 bg-white px-2"
          style={{ height: 40, borderRadius: 4, border: '1px solid #CCC', color: '#000' }}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(e.target.value)
          }}
        >
          {convertToPackageTypeOptions()}
        </SelectBase>
      </div>
    </S.SelectInputWrapper>
  )
}

export default memo(ImportExportSelectRow)
