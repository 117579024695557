import { createSingleAction } from 'utils/reduxActions'
import * as types from './constants'

// behavior actions
export const changeWarehouseId = createSingleAction(types.CHANGE_WAREHOUSE_ID)
export const changeTenantId = createSingleAction(types.CHANGE_TENANT_ID)
export const updateCurrentDay = createSingleAction(types.UPDATE_CURRENT_DAY)
export const openSlideModal = createSingleAction(types.OPEN_SLIDE_MODAL)
export const closeSlideModal = createSingleAction(types.CLOSE_SLIDE_MODAL)
export const addBookingItems = createSingleAction(types.ADD_BOOKING_ITEM)
export const updateBookingItem = createSingleAction(types.UPDATE_BOOKING_ITEM)
export const deleteBookingItem = createSingleAction(types.DELETE_BOOKING_ITEM)
export const deleteBookingItems = createSingleAction(types.DELETE_BOOKING_ITEMS)
export const addPendingBookingItem = createSingleAction(types.ADD_PENDING_ITEM)
export const removePendingBookingItem = createSingleAction(types.DELETE_PENDING_ITEM)
export const deleteDelegationItem = createSingleAction(types.DELETE_DELEGATION_ITEM)
export const handleChangeSearchValue = createSingleAction(types.CHANGE_SEARCH_BOOKING)
export const resetAllTenantData = createSingleAction(types.RESET_ALL_TENANT_DATA)
export const deleteBookingRepeat = createSingleAction(types.DELETE_REPEAT_BOOKING)

// get warehouse list
export const getWarehouseList = createSingleAction(types.GET_WAREHOUSE_LIST)
export const getWarehouseListSuccess = createSingleAction(types.GET_WAREHOUSE_LIST_SUCCESS)
export const getWarehouseListFailure = createSingleAction(types.GET_WAREHOUSE_LIST_FAILURE)

// get berth list
export const getBerthList = createSingleAction(types.GET_BERTH_LIST)
export const getBerthListSuccess = createSingleAction(types.GET_BERTH_LIST_SUCCESS)
export const getBerthListFailure = createSingleAction(types.GET_BERTH_LIST_FAILURE)

export const changeMetaBerthList = createSingleAction(types.CHANGE_META_BERTH_LIST)

// get berth list of warehouse
export const getAllBerthList = createSingleAction(types.GET_ALL_BERTH_LIST)
export const getAllBerthListSuccess = createSingleAction(types.GET_ALL_BERTH_LIST_SUCCESS)
export const getAllBerthListFailure = createSingleAction(types.GET_ALL_BERTH_LIST_FAILURE)

// get permission of tenant
export const getRoleTenant = createSingleAction(types.GET_ROLE_TENANT)
export const getRoleTenantSuccess = createSingleAction(types.GET_ROLE_TENANT_SUCCESS)
export const getRoleTenantFailure = createSingleAction(types.GET_ROLE_TENANT_FAILURE)

export const setIsLoadingGetRoleTenant = createSingleAction(types.SET_IS_LOADING_ROLE_TENANT)

// create new booking
export const createNewBooking = createSingleAction(types.CREATE_NEW_BOOKING)
export const createNewBookingSuccess = createSingleAction(types.CREATE_NEW_BOOKING_SUCCESS)
export const createNewBookingFailure = createSingleAction(types.CREATE_NEW_BOOKING_FAILURE)

// create new booking
export const createNewBookingRepeat = createSingleAction(types.CREATE_BOOKING_REPEAT)
export const createNewBookingRepeatSuccess = createSingleAction(types.CREATE_BOOKING_REPEAT_SUCCESS)
export const createNewBookingRepeatFailure = createSingleAction(types.CREATE_BOOKING_REPEAT_FAILURE)

// create unassign booking
export const createUnassignBooking = createSingleAction(types.CREATE_UNASSIGN_BOOKING)
export const createUnassignBookingSuccess = createSingleAction(
  types.CREATE_UNASSIGN_BOOKING_SUCCESS
)
export const createUnassignBookingFailure = createSingleAction(
  types.CREATE_UNASSIGN_BOOKING_FAILURE
)

// update booking data by form
export const updateBookingData = createSingleAction(types.UPDATE_BOOKING_DATA)
export const updateBookingSuccess = createSingleAction(types.UPDATE_BOOKING_SUCCESS)
export const updateBookingFailure = createSingleAction(types.UPDATE_BOOKING_FAILURE)

// update booking data unassigned by form
export const [
  updateBookingUnassigned,
  updateBookingUnassignedSuccess,
  updateBookingUnassignedFailure,
] = [
  createSingleAction(types.UPDATE_BOOKING_UNASSIGNED),
  createSingleAction(types.UPDATE_BOOKING_UNASSIGNED_SUCCESS),
]

// update booking status
export const updateBookingStatus = createSingleAction(types.UPDATE_BOOKING_STATUS)
export const updateBookingStatusSuccess = createSingleAction(types.UPDATE_BOOKING_STATUS_SUCCESS)
export const updateBookingStatusFailure = createSingleAction(types.UPDATE_BOOKING_STATUS_FAILURE)

// create new delegation
export const createNewDelegation = createSingleAction(types.CREATE_NEW_DELEGATION)
export const createNewDelegationSuccess = createSingleAction(types.CREATE_NEW_DELEGATION_SUCCESS)
export const createNewDelegationFailure = createSingleAction(types.CREATE_NEW_DELEGATION_FAILURE)

// update delegation data
export const updateDelegationData = createSingleAction(types.UPDATE_DELEGATION_DATA)
export const updateDelegationSuccess = createSingleAction(types.UPDATE_DELEGATION_SUCCESS)
export const updateDelegationFailure = createSingleAction(types.UPDATE_DELEGATION_FAILURE)

// get company list
export const getCompanyList = createSingleAction(types.GET_COMPANY_LIST)
export const getCompanyListSuccess = createSingleAction(types.GET_COMPANY_LIST_SUCCESS)
export const getCompanyListFailure = createSingleAction(types.GET_COMPANY_LIST_FAILURE)
export const setCompanyList = createSingleAction(types.SET_COMPANY_LIST)

// errors
export const updateErrorMessage = createSingleAction(types.UPDATE_ERROR_MESSAGES)
export const clearErrorMessage = createSingleAction(types.CLEAR_ERROR_MESSAGES)

// get neutral list
export const getNeutralList = createSingleAction(types.GET_NEUTRAL_BOOKING)
export const getNeutralListSuccess = createSingleAction(types.GET_NEUTRAL_BOOKING_SUCCESS)
export const getNeutralListFailure = createSingleAction(types.GET_NEUTRAL_BOOKING_FAILURE)

export const updateNeutralMeta = createSingleAction(types.UPDATE_NEUTRAL_META)

// Upload Csv
export const uploadCsvRequest = createSingleAction(types.UPLOAD_CSV_REQUEST)
export const uploadCsvSuccess = createSingleAction(types.UPLOAD_CSV_SUCCESS)
export const uploadCsvFailure = createSingleAction(types.UPLOAD_CSV_FAILURE)

// download Csv
export const downloadCsvRequest = createSingleAction(types.DOWNLOAD_CSV_REQUEST)
export const downloadCsvSuccess = createSingleAction(types.DOWNLOAD_CSV_SUCCESS)
export const downloadCsvFailure = createSingleAction(types.DOWNLOAD_CSV_FAILURE)

// Upload Csv Homage Page
export const uploadCsvBerth = createSingleAction(types.UPLOAD_CSV_BERTH)

// get package type master
export const getPackageTypeMaster = createSingleAction(types.GET_PACKAGE_TYPE_LIST)
export const getPackageTypeMasterSuccess = createSingleAction(types.GET_PACKAGE_TYPE_SUCCESS)

// get bookings summary
export const getBookingSummary = createSingleAction(types.GET_BOOKING_SUMMARY)
export const getBookingSummarySuccess = createSingleAction(types.GET_BOOKING_SUMMARY_SUCCESS)
export const getBookingSummaryFailure = createSingleAction(types.GET_BOOKING_SUMMARY_FAILURE)

export const updateTaskListProcess = createSingleAction(types.UPDATE_TASK_LIST_PROCESS)
export const clearTaskListProcess = createSingleAction(types.CLEAR_TASK_LIST_PROCESS)

// force finish
export const requestForceFinish = createSingleAction(types.REQUEST_FORCE_FINISH)
export const cancelForceFinish = createSingleAction(types.CANCEL_FORCE_FINISH)
export const confirmForceFinish = createSingleAction(types.CONFIRM_FORCE_FINISH)
export const forceFinishSuccess = createSingleAction(types.FORCE_FINISH_SUCCESS)
export const forceFinishFailure = createSingleAction(types.FORCE_FINISH_FAILURE)

// save loading
export const toggleSaveLoading = createSingleAction(types.TOGGLE_SAVE_LOADING)
export const toggleDisplayMode = createSingleAction(types.TOGGLE_DISPLAY_MODE)

export const dispatchUpdateRepeatStatus = createSingleAction(types.DISPATCH_UPDATE_REPEAT_STATUS)

// update action processing
export const updateActionProcessing = createSingleAction(types.UPDATE_ACTION_PROCESSING)
export const setIsActionProcessingSubmitForm = createSingleAction(
  types.SET_IS_ACTION_PROCESSING_SUBMIT_FORM
)
export const setCountDownSubmitFail = createSingleAction(types.SET_COUNT_DOWN_SUBMIT_FAIL)

export const setIsLoadingChangeTime = createSingleAction(types.SET_IS_LOADING_CHANGE_TIME)
export const fetchSettings = createSingleAction(types.FETCH_SETTINGS)
export const updateSizeColumnSettings = createSingleAction(types.UPDATE_SIZE_COLUMN_SETTINGS)

// handle notification from upload csv of general booking
export const fetchBookingDataAfterUploadCSV = createSingleAction(
  types.FETCH_BOOKING_DATA_AFTER_UPLOAD_CSV
)

// update setting tenant (realtime)
export const updateTenantSetting = createSingleAction(types.UPDATE_TENANT_SETTING)
export const updateTenantSettingSuccess = createSingleAction(types.UPDATE_TENANT_SETTING_SUCCESS)

export const sendMessageCustomize = createSingleAction(types.SEND_MESSAGE_CUSTOMIZE)

export const fetchMessageList = createSingleAction(types.FETCH_MESSAGE_LIST)
export const fetchMessageListSuccess = createSingleAction(types.FETCH_MESSAGE_LIST_SUCCESS)
export const fetchMessageListFail = createSingleAction(types.FETCH_MESSAGE_LIST_FAIL)
export const clearMessageList = createSingleAction(types.CLEAR_MESSAGE_LIST)

export const setTimeAlowSendMessages = createSingleAction(types.SET_TIME_ALLOW_SEND_MESSAGES)

export const updateFilterSearchBooking = createSingleAction(types.UPDATE_FILTER_SEARCH_BOOKING)
export const clearFilterSearchBooking = createSingleAction(types.CLEAR_FILTER_SEARCH_BOOKING)
export const setStatusDoubleTapBooking = createSingleAction(types.SET_STATUS_TAP_BOOKING)
export const setMessageCopyPasteBooking = createSingleAction(types.SET_MESSAGE_COPY_PASTE_BOOKING)

export const [
  fetchYesterdayBookingList,
  fetchYesterdayBookingListSuccess,
  fetchYesterdayBookingListFail,
] = [
  createSingleAction(types.FETCH_YESTERDAY_BOOKING_LIST),
  createSingleAction(types.FETCH_YESTERDAY_BOOKING_LIST_SUCCESS),
  createSingleAction(types.FETCH_YESTERDAY_BOOKING_LIST_FAIL),
]
export const deleteYesterdayBooking = createSingleAction(types.DELETE_YESTERDAY_BOOKING)
export const deleteYesterdayBookingRepeat = createSingleAction(
  types.DELETE_REPEAT_YESTERDAY_BOOKING
)
export const updateYesterdayBookingSuccess = createSingleAction(
  types.UPDATE_YESTERDAY_BOOKING_SUCCESS
)
export const deleteYesterdayBookingItems = createSingleAction(types.DELETE_YESTERDAY_BOOKING_ITEMS)
export const fetchGroupBerthList = createSingleAction(types.FETCH_GROUP_BERTH_LIST)
export const fetchGroupBerthListSuccess = createSingleAction(types.FETCH_GROUP_BERTH_LIST_SUCCESS)
export const clearGroupBerthList = createSingleAction(types.CLEAR_GROUP_BERTH_LIST)
export const updateGroupBerthListMeta = createSingleAction(types.UPDATE_GROUP_BERTH_LIST_META)
export const forceAnimationBooking = createSingleAction(types.FORCE_ANIMATION_BOOKING)

export const setIsDraggingUnassignBooking = createSingleAction(
  types.SET_IS_DRAGGING_UNASSIGN_BOOKING
)

// update confirmed driver change time
export const confirmedDriverChangeTime = createSingleAction(types.CONFIRMED_DRIVER_CHANGE_TIME)
export const confirmedDriverChangeTimeSuccess = createSingleAction(
  types.CONFIRMED_DRIVER_CHANGE_TIME_SUCCESS
)
export const confirmedDriverChangeTimeFailure = createSingleAction(
  types.CONFIRMED_DRIVER_CHANGE_TIME_FAILURE
)

export const settingTaskListLoadInfo = createSingleAction(types.SETTING_TASK_LIST_LOAD_INFO)
