import React, { PureComponent } from 'react'
import ReactPaginate from 'react-paginate'

import styled from 'styled-components'
import Icon from 'components/Icon'
import theme from 'vars/theme'

const Wrapper = styled.div`
  .paginate-containers {
    padding: 4px 10px;
    margin: 0;
  }

  .list-style {
    margin: 0;
    list-style: none;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: ${theme.darkBlueMedium1};
      margin-left: 4px;
      font-size: 12px;
      height: ${(props) => (props.size === 'medium' ? '30px' : '22px')};
      width: ${(props) => (props.size === 'medium' ? '30px' : '22px')};
    }
  }

  .page-item,
  .next-button,
  .prev-button {
    a {
      border-radius: 2px;
      border: 1px solid ${theme.graySoft2};

      img {
        height: 12px;
      }
    }
  }

  a.active {
    color: ${theme.whitePrimary};
    background: ${theme.darkBlueMedium1};
  }

  .disabled {
    a {
      background: ${theme.graySoft2};
      border: none;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

class Pagination extends PureComponent {
  onPageChange = ({ selected }) => {
    this.props.onNavigateRequest(selected + 1)
  }

  previousLabel = () => <Icon src="/assets/svgs/prev.svg" />

  nextLabel = () => <Icon src="/assets/svgs/next.svg" />

  render() {
    const {
      meta: { total, current },
      size,
    } = this.props
    return (
      <Wrapper size={size}>
        <ReactPaginate
          forcePage={current - 1}
          marginPagesDisplayed={1}
          pageCount={total}
          onPageChange={this.onPageChange}
          containerClassName="paginate-containers flex-end"
          pageClassName="page-item flex-center list-style"
          nextLabel={this.nextLabel()}
          previousLabel={this.previousLabel()}
          nextClassName="flex-center next-button list-style"
          previousClassName="flex-center prev-button list-style"
          activeLinkClassName="active"
          disabledClassName="disabled"
          breakClassName="list-style"
        />
      </Wrapper>
    )
  }
}

export default Pagination
