import React, { useCallback, useState, useEffect, useMemo, ChangeEvent } from 'react'
import _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'
import { camelizeKeys } from 'humps'

import { openError } from 'containers/App/reducers'
import { VEHICLE_NUMBER_LENGTH } from 'constants/Booking'
import { useDispatch } from 'reduxStoreHelper'
import { INTEGRATED_RECEPTION_URL_BASE, EMPTY_OPTION } from '../constants'
import {
  resetAllData,
  startLoading,
  endLoading,
  updateBooking,
  clearBooking,
  updateCommonData,
  resetCommonData,
  resetSelectedBooking,
  setIsAcceptPolicyChangeDriverName,
} from '../reducer'
import {
  getMasterData,
  getRecentBooking,
  getListCompanies,
  postUpdatePolicyChecked,
  getPolicy,
} from '../api'
import { GeneralBookingType, DriverAttributesType, policyType } from '../type'
import {
  DRIVER_NAME_GENERAL_ID,
  Props as EntryDriverInfoPropsType,
} from '../pages/EntryDriverInfoPage'

const useEntryDriverInfoPage = ({
  isCreatedBookingData,
  isFromBookingNo,
  phoneNumberEntered,
  driver,
  bookingFromBookingNo,
  masterData,
  recentBooking,
  listCompanies,
  generalReceptionId,
  selectedBooking,
  isFromListCheckin,
  canGeneralBooking,
  isAcceptPolicyChangeDriverName,
}: Omit<EntryDriverInfoPropsType, 'booking'>) => {
  const history = useHistory()
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()

  const dispatch = useDispatch()

  const [isOpenVehicleNumberModal, setIsOpenVehicleNumberModal] = useState(false)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [isOpenPolicyModal, setIsOpenPolicyModal] = useState(false)
  const [policyData, setPolicyData] = useState<policyType>({ id: '', policyUrl: '', version: '' })

  const onClickEnterVehicleModal = useCallback(
    (value: string) => {
      dispatch(
        updateBooking({
          vehicleNumber: value.padStart(VEHICLE_NUMBER_LENGTH, '0'),
        })
      )
      setIsOpenVehicleNumberModal(false)
    },
    [dispatch]
  )

  const onClickCloseVehicleModal = useCallback(() => {
    setIsOpenVehicleNumberModal(false)
  }, [])

  const handleClickButtonExit = useCallback(() => {
    dispatch(resetAllData())
  }, [dispatch])

  const handleClickButtonBack = useCallback(() => {
    if (canGeneralBooking) {
      history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-bookings/${generalReceptionNumber}`)
    } else {
      history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
    }
    dispatch(clearBooking())
    dispatch(resetCommonData())
    dispatch(resetSelectedBooking())
  }, [canGeneralBooking, dispatch, generalReceptionNumber, history])

  useEffect(() => {
    ;(async () => {
      if (isCreatedBookingData) return

      dispatch(startLoading())
      try {
        const [respMasterData, respRecentBooking, respListCompanies] = await Promise.all([
          getMasterData(generalReceptionId),
          getRecentBooking(generalReceptionId, phoneNumberEntered),
          getListCompanies(generalReceptionId, phoneNumberEntered),
        ])
        const recentBookingData: GeneralBookingType = _.get(respRecentBooking, 'data', {})
        const listCompaniesData = _.get(respListCompanies, 'data', [])
        const { id, name, companyName } = _.get(
          driver,
          'attributes',
          {}
        ) as Partial<DriverAttributesType>

        if (isFromBookingNo) {
          const {
            id,
            generalBookingId,
            generalBookingNo,
            destination,
            driverId,
            driverCompanyName,
            vehicleCategory,
            vehicleNumber,
            importGoodsOwner,
            exportGoodsOwner,
            importGoodsName,
            exportGoodsName,
            importDeliverySlipNumber,
            exportDeliverySlipNumber,
            importPackageType,
            exportPackageType,
            importGoodsAmount,
            exportGoodsAmount,
            importMainGoods,
            exportMainGoods,
            importContactNotes,
            exportContactNotes,
            importFlag,
            exportFlag,
            status,
            ...booking
          } = isFromListCheckin ? selectedBooking : bookingFromBookingNo
          dispatch(
            updateBooking({
              ...booking,
              generalBookingId: isFromListCheckin ? generalBookingId : id,
              generalBookingNo,
              generalReceptionId,
              destination,

              driverId: driverId || '',
              driverName: name || '',
              driverPhone: phoneNumberEntered,
              driverCompanyName: driverCompanyName || '',
              vehicleCategory: vehicleCategory || '',
              vehicleNumber: vehicleNumber || '',

              importGoodsOwner: importGoodsOwner || '',
              exportGoodsOwner: exportGoodsOwner || '',
              importGoodsName: importGoodsName || '',
              exportGoodsName: exportGoodsName || '',
              importDeliverySlipNumber: importDeliverySlipNumber || '',
              exportDeliverySlipNumber: exportDeliverySlipNumber || '',
              importPackageType: importPackageType || '',
              exportPackageType: exportPackageType || '',
              importGoodsAmount: importGoodsAmount || '',
              exportGoodsAmount: exportGoodsAmount || '',
              importMainGoods: importMainGoods || '',
              exportMainGoods: exportMainGoods || '',
              importContactNotes: importContactNotes || '',
              exportContactNotes: exportContactNotes || '',
              importFlag,
              exportFlag,
              status,
              ...(_.isEmpty(driver) && {
                driverId: '',
                driverName: '',
                driverCompanyName: '',
                vehicleCategory: '',
                vehicleNumber: '',
              }),
            })
          )
        } else {
          dispatch(
            updateBooking({
              generalReceptionId,
              driverId: id || '',
              driverName: name || '',
              driverPhone: phoneNumberEntered,
              driverCompanyName: recentBookingData?.driverCompanyName || companyName || '',
              vehicleNumber: recentBookingData?.vehicleNumber || '',
              vehicleCategory: recentBookingData?.vehicleCategory || '',
            })
          )
        }
        dispatch(
          updateCommonData({
            masterData: _.get(respMasterData, 'data', {}),
            recentBooking: { ...recentBookingData },
            listCompanies: [...listCompaniesData],
          })
        )
      } catch (error) {
        //@ts-ignore
        dispatch(openError(error.message))
      } finally {
        dispatch(endLoading())
      }
    })()
  }, [
    isCreatedBookingData,
    bookingFromBookingNo,
    dispatch,
    driver,
    generalReceptionNumber,
    isFromBookingNo,
    phoneNumberEntered,
    generalReceptionId,
    isFromListCheckin,
    selectedBooking,
  ])

  const listCompanyOptions = useMemo(() => {
    const companyNameFromDriver = _.get(driver, 'attributes.companyName', '')
    const companyNameFromBookingNoData = isFromBookingNo
      ? _.get(bookingFromBookingNo, 'driverCompanyName', '')
      : ''
    const companyNameFromRecentBooking = _.get(recentBooking, 'driverCompanyName', '')
    const companyOptions = listCompanies
      .map((company) => ({
        value: company?.attributes?.companyName,
        label: company?.attributes?.companyName,
      }))
      .concat(
        companyNameFromDriver
          ? [{ value: companyNameFromDriver, label: companyNameFromDriver }]
          : [],
        companyNameFromBookingNoData
          ? [{ value: companyNameFromBookingNoData, label: companyNameFromBookingNoData }]
          : [],
        companyNameFromRecentBooking
          ? [{ value: companyNameFromRecentBooking, label: companyNameFromRecentBooking }]
          : []
      )
    return _.uniqBy(companyOptions, 'value')
  }, [bookingFromBookingNo, driver, isFromBookingNo, listCompanies, recentBooking])

  const handleChangeDriverCompany = useCallback(
    (option: any) => {
      dispatch(
        updateBooking({
          driverCompanyName: option?.value || '',
        })
      )
    },
    [dispatch]
  )

  const handleBlurDriverCompany = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      if (!value) {
        return
      }

      dispatch(
        updateBooking({
          driverCompanyName: value,
        })
      )
    },
    [dispatch]
  )

  const handleChangeDriverName = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      const value = e?.target?.value
      dispatch(
        updateBooking({
          driverName: value || '',
        })
      )
    },
    [dispatch]
  )

  const handleSelectVehicleCategory = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      dispatch(
        updateBooking({
          vehicleCategory: value || '',
        })
      )
    },
    [dispatch]
  )

  const handleAcceptPolicyChangeNameDriver = useCallback(async () => {
    try {
      dispatch(startLoading())
      await postUpdatePolicyChecked(policyData?.id, phoneNumberEntered)
      await dispatch(setIsAcceptPolicyChangeDriverName(true))
      handleChangeDriverName()
      const driverNameInput = document.getElementById(DRIVER_NAME_GENERAL_ID)
      if (driverNameInput) driverNameInput.focus()
    } catch (error) {
      //@ts-ignore
      dispatch(openError(error.message))
    } finally {
      dispatch(endLoading())
      setIsOpenPolicyModal(false)
    }
  }, [dispatch, handleChangeDriverName, phoneNumberEntered, policyData])

  const handleConfirmChangeNameDriver = useCallback(async () => {
    try {
      dispatch(startLoading())
      const result = await getPolicy()
      //@ts-ignore
      setPolicyData(camelizeKeys(result?.data?.displayPolicyVersion))
      setIsOpenPolicyModal(true)
    } catch (error) {
      //@ts-ignore
      dispatch(openError(error.message))
    } finally {
      dispatch(endLoading())
      setIsOpenModalConfirm(false)
    }
  }, [dispatch])

  const renderVehicleOptions = useCallback(() => {
    const vehicleCategories = _.get(masterData, 'vehicleCategories', [])
    const vehicleCategoriesOptions = _.filter(vehicleCategories, (vehicle) => vehicle.displayFlag)

    return (
      <>
        <option value={EMPTY_OPTION.value}>{EMPTY_OPTION.label}</option>
        {vehicleCategoriesOptions.map((vehicle) => (
          <option key={vehicle.id} value={vehicle.parameterId}>
            {vehicle.parameterName}
          </option>
        ))}
      </>
    )
  }, [masterData])

  const handleFocusInputDriverName = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (!isAcceptPolicyChangeDriverName && !!driver?.attributes?.name) {
        setIsOpenModalConfirm(true)
        event.target.blur()
      }
    },
    [driver, isAcceptPolicyChangeDriverName]
  )

  return {
    driver,
    listCompanyOptions,
    isOpenVehicleNumberModal,
    isOpenModalConfirm,
    isOpenPolicyModal,
    policyData,
    setIsOpenModalConfirm,
    setIsOpenPolicyModal,
    handleChangeDriverCompany,
    handleBlurDriverCompany,
    handleChangeDriverName,
    handleSelectVehicleCategory,
    setIsOpenVehicleNumberModal,
    renderVehicleOptions,
    onClickEnterVehicleModal,
    onClickCloseVehicleModal,
    handleClickButtonExit,
    handleClickButtonBack,
    handleAcceptPolicyChangeNameDriver,
    handleConfirmChangeNameDriver,
    handleFocusInputDriverName,
  }
}

export default useEntryDriverInfoPage
