export const context = 'containers/AppPrivate'

export const SHOW_FLASH_MESSAGE = `${context}/SHOW_FLASH_MESSAGE`
export const HIDE_FLASH_MESSAGE = `${context}/HIDE_FLASH_MESSAGE`

// updated booking from notification
export const UPDATED_BOOKING = `${context}/UPDATED_BOOKING`

export const GET_USER_ROLES = `${context}/GET_USER_ROLES`
export const GET_USER_ROLES_SUCCESS = `${context}/GET_USER_ROLES_SUCCESS`
export const GET_USER_ROLES_FAILURE = `${context}/GET_USER_ROLES_FAILURE`

// toggle render csv
export const TOGGLE_RENDER_CSV = `${context}/TOGGLE_RENDER_CSV`

export const CHECK_USER_POLICY = `${context}/CHECK_USER_POLICY`
export const CHECK_USER_POLICY_SUCCESS = `${context}/CHECK_USER_POLICY_SUCCESS`

export const UPDATE_USER_POLICY = `${context}/UPDATE_USER_POLICY`
export const UPDATE_USER_POLICY_SUCCESS = `${context}/UPDATE_USER_POLICY_SUCCESS`
