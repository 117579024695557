import React, { memo, useState, FC } from 'react'
import { MenuItemParent, TriangleIcon, ChildrenWrapper } from './style'

type Props = {
  title: string
}

const MenuItemGroup: FC<Props> = ({ title, children }) => {
  const [isShowChildren, setIsShowChildren] = useState<boolean>(true)

  return (
    <div>
      <MenuItemParent onClick={() => setIsShowChildren(!isShowChildren)}>
        <span>{title}</span>
        <TriangleIcon isOpen={isShowChildren} />
      </MenuItemParent>
      <ChildrenWrapper isShowChildren={isShowChildren}>{children}</ChildrenWrapper>
    </div>
  )
}

export default memo(MenuItemGroup)
