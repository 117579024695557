import { createSelector } from 'reselect'
import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import { compareValue, isDelegationForUser } from 'utils/commonFunctions'
import { context } from './constants'
import { ROLES } from 'constants/misc'
import { userDataSelectors } from 'containers/App/selectors'
import { userRoleSelectors, userRoleForBerth } from 'containers/AppPrivate/selectors'
import {
  API_DAY_FORMAT,
  STANDARD_TIME_FORMAT,
  STANDARD_DAY_FORMAT,
} from 'containers/HomePage/containers/BerthSchedule/constants'

import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { TypeMessageCustomIds, statusValues, BOOKING_SOURCES } from 'constants/misc'
import { checkUserCanViewBooking, convertUsingMomentCurrentTime } from 'utils/commonFunctions'

const moment = extendMoment(Moment)

const homePageDomainSelector = (state) => state.get(context)

export const isSlideModalSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('isSlideModal'))

export const warehouseIdSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('warehouseId'))

export const tenantIdSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('tenantId'))

export const currentDaySelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('currentDay'))

export const homeLoadingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('loading'))

export const allBerthListSelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('allBerthList').toJS())

export const homeSearchSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('searchValue'))

export const warehouseListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('warehouseList').toJS())

export const berthListMetaSelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('berthListMeta').toJS())

export const berthListSelectors = () =>
  createSelector(
    homePageDomainSelector,
    allBerthListSelector(),
    berthListMetaSelector(),
    groupBerthListSelectors(),
    groupBerthListMetaSelectors(),
    (state, allBerthList, { current }, groupBerthList, groupBerthListMeta) => {
      const { isChoose, current: currentGroupBerth } = groupBerthListMeta || {}
      if (isChoose) {
        return groupBerthList[currentGroupBerth - 1].berth_ids.reduce((initial, currentItem) => {
          const itemFinder = allBerthList.find((berth) => berth.id === currentItem)
          initial.push({ ...itemFinder, id: itemFinder?.id?.toString() })
          return initial
        }, [])
      } else {
        const { berthColumnsPerPage } = state.get('settings').toJS()
        const startIndex = (current - 1) * berthColumnsPerPage.size
        const endIndex = current * berthColumnsPerPage.size
        return allBerthList
          .slice(startIndex, endIndex)
          .map((it) => ({ ...it, id: it.id.toString() }))
      }
    }
  )

export const bookingListSelectors = () =>
  createSelector(homePageDomainSelector, berthListSelectors(), (state, berthList) => {
    const allBooking = state.get('bookingList').toJS()
    const berthIds = berthList.map((it) => it.id.toString())
    const berthsFilter = allBooking.filter((it) => berthIds.includes(it.berth_id))
    return berthsFilter.map((it) => {
      if (!it.status) {
        return { ...it, status: 3 }
      }
      return it
    })
  })

export const neutralBookingListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('neutralBookingList').toJS())

export const neutralBookingMetaSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('neutralListMeta').toJS())

export const delegationListSelectors = () =>
  createSelector(homePageDomainSelector, berthListSelectors(), (state, berthList) => {
    const allBooking = state.get('delegationList').toJS()
    const berthIds = berthList.map((it) => it.id.toString())
    return allBooking.filter((it) => berthIds.includes(it.berth_id))
  })

export const pendingItemSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('pendingItem').toJS())

export const companyListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('companyList').toJS())

export const homePageErrorSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('error').toJS())

export const bookingMasterDataSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('bookingMaster').toJS())

export const tenantListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('tenantsList').toJS())

export const actionProcessingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('actionProcessing'))

export const bookingSummarySelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('bookingSummary').toJS())

export const forceFinishSelectors = () =>
  createSelector(homePageDomainSelector, (state) => ({
    isModal: !!state.get('forceFinishData'),
    forceFinishData: state.get('forceFinishData') ? state.get('forceFinishData').toJS() : null,
  }))

export const tenantItemSelectors = () =>
  createSelector(
    warehouseListSelectors(),
    warehouseIdSelectors(),
    tenantIdSelectors(),
    (warehouseList, warehouseId, tenantId) => {
      const warehouseItem = warehouseList.find((it) => compareValue(it.id, warehouseId))
      if (warehouseItem) {
        const tenantItem = warehouseItem.tenants.find((it) => compareValue(it.id, tenantId))
        return tenantItem || {}
      }
      return {}
    }
  )

export const isBookingServiceSelectors = () =>
  createSelector(tenantItemSelectors(), (tenant) => !!tenant.can_berth_book)

export const canCopyPasteServiceSelector = () =>
  createSelector(tenantItemSelectors(), (tenant) => !!tenant.can_copy_paste)

export const isSameCompanyWithWarehouseSelectors = () =>
  createSelector(userRoleSelectors(), (userRole) =>
    [ROLES.ADMIN, ROLES.FULL_OPEN_ADMIN, ROLES.FULL_OPEN_NORMAL].includes(userRole)
  )

export const userSameGroupSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('usersSameGroup')?.toJS()
  })

export const bookingListOutSideDelegation = () =>
  createSelector(
    userDataSelectors(),
    bookingListSelectors(),
    delegationListSelectors(),
    userSameGroupSelectors(),
    (userData, bookingList, delegationList, usersSameGroup) => {
      const company_id = _get(userData, 'attributes.company_id') || ''

      const filterBookingList = bookingList.filter((it) =>
        checkUserCanViewBooking(userData, it, usersSameGroup)
      )
      const filterDelegationList = delegationList.filter((it) =>
        isDelegationForUser(userData?.id, company_id, it, usersSameGroup)
      )
      return filterBookingList.filter((bookingItem) => {
        const delegationOver = filterDelegationList
          .filter((it) => compareValue(it.berth_id, bookingItem.berth_id))
          .find((delegationItem) => {
            const startMoment = moment(
              `${bookingItem.start_date} ${bookingItem.start_time}`,
              `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`
            )
            const endMoment = moment(
              `${bookingItem.end_date} ${bookingItem.end_time}`,
              `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`
            )
            const delegationRange = moment.range(
              moment(
                `${delegationItem.start_date} ${delegationItem.start_time}`,
                `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`
              ),
              moment(
                `${delegationItem.end_date} ${delegationItem.end_time}`,
                `${API_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`
              )
            )
            return startMoment.within(delegationRange) && endMoment.within(delegationRange)
          })
        return !delegationOver
      })
    }
  )

export const saveLoadingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('saveLoading'))

export const renderCSVSelectors = () => createSelector(homePageDomainSelector, () => true)

export const taskListProcessSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('taskListProcess').toJS())

export const displayModeSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    const value = state.get('displayMode')
    return value === 5 ? 6 : 1
  })

export const settingsSelector = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('settings').toJS()
  })

export const settingsOriginSelector = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('settingsOrigin').toJS()
  })

export const berthListMetaOriginSelector = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('berthListMetaOrigin').toJS()
  })

export const isLoadingMessageListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('isLoadingMessageList')
  })

export const messageListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('messageList')?.toJS()
  })

export const messageActiveListSelectors = () =>
  createSelector(messageListSelectors(), (messageList) => {
    return messageList?.filter((message) => message?.active)
  })

export const isShowSendMessageModalSelectors = () =>
  createSelector(
    tenantItemSelectors(),
    userDataSelectors(),
    userRoleForBerth(),
    companyIdOfWarehouseSelectors(),
    (tenant, userData, userRoles, companyId) => {
      const { can_sms, can_customize_message, tenant_messages } = tenant || {}
      const tenantMessageCustomize = tenant_messages?.some(
        (tenantMessage) =>
          TypeMessageCustomIds?.includes(tenantMessage?.type) && tenantMessage?.active
      )

      const userRoleAttributes = userRoles?.user_tenant?.data?.attributes
      const userAttributes = userData?.attributes

      const isSuperAdmin = compareValue(userAttributes?.role, ROLES.SUPER_ADMIN)
      const isAdminCompany =
        userAttributes?.is_warehouse_admin && compareValue(userAttributes?.company_id, companyId)
      const isAdminTenant = userRoleAttributes?.admin

      const isSendMessageByRole = isSuperAdmin || isAdminCompany || isAdminTenant
      return can_sms && can_customize_message && tenantMessageCustomize && isSendMessageByRole
    }
  )

export const companyIdOfWarehouseSelectors = () =>
  createSelector(warehouseListSelectors(), warehouseIdSelectors(), (warehouseList, warehouseId) => {
    const warehouseCurrent = warehouseList?.find((warehouse) =>
      compareValue(warehouse?.id, warehouseId)
    )
    return warehouseCurrent?.company_id || -1
  })

export const timeAllowMessagesSelectors = () => {
  return createSelector(homePageDomainSelector, (state) => {
    return state.get('timeAllowSendMessages')?.toJS()
  })
}

export const filterSearchBookingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('filterSearchBooking')?.toJS()
  })

export const configsSettingTimeSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('configsSettingTime')?.toJS()
  })

export const statusTapBookingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('statusTapBooking')?.toJS()
  })

export const messageCopyPastedSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('messageCopyPasted')
  })

export const roleTenantSelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('roleTenant').toJS())

export const isLoadingRoleTenantSelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('isLoadingRoleTenant'))

export const canAcceptCardListPageSelectors = () =>
  createSelector(tenantItemSelectors(), roleTenantSelector(), (tenant, roleTenant) => {
    const { can_list_card } = tenant || {}
    const { admin_company, admin_tenant } = roleTenant || {}
    const canAcceptByRole = admin_company || admin_tenant

    return can_list_card && canAcceptByRole
  })
export const isAdminHasPermissionTenantSelectors = () =>
  createSelector(roleTenantSelector(), (roleTenant) => {
    const { admin_company, admin_tenant, super_admin } = roleTenant || {}

    return admin_company || admin_tenant || super_admin
  })

export const bookingListYesterdaySelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('bookingListYesterday').toJS())

export const yesterdayBookingListSelectors = () =>
  createSelector(bookingListYesterdaySelector(), (bookingListYesterdayList) => {
    const currentTime = moment()
    const totalYesterdayBooking = []

    _forEach(bookingListYesterdayList || [], (item) => {
      const { end_date } = item

      if (convertUsingMomentCurrentTime(currentTime, end_date)) {
        totalYesterdayBooking.push(item)
      }
    })

    return totalYesterdayBooking
  })

export const groupBerthListSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('groupBerthList')?.toJS()
  })

export const groupBerthListMetaSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    return state.get('groupBerthListMeta')?.toJS()
  })

export const canGroupBerthServiceSelectors = () =>
  createSelector(tenantItemSelectors(), (tenant) => !!tenant.can_group_berth)

export const warehouseItemSelectors = () =>
  createSelector(warehouseListSelectors(), warehouseIdSelectors(), (warehouseList, warehouseId) => {
    const warehouseCurrent = warehouseList?.find((warehouse) =>
      compareValue(warehouse?.id, warehouseId)
    )
    return warehouseCurrent
  })

export const actionAnimationBookingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('animationBookingIdInBerth'))

export const isActionProcessingSubmitFormSelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('isActionProcessingSubmitForm'))

export const countDownSubmitFailSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('countDownSubmitFail'))

export const isDraggingUnassignBookingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('isDraggingUnassignBooking'))

export const isLoadingChangeTimeSelector = () =>
  createSelector(homePageDomainSelector, (state) => state.get('isLoadingChangeTime'))

export const isAdminCompanyOrTenantSelectors = () =>
  createSelector(roleTenantSelector(), (roleTenant) => {
    const { admin_company, admin_tenant } = roleTenant || {}
    return admin_company || admin_tenant
  })

export const countBookingsSelectors = () =>
  createSelector(homePageDomainSelector, (state) => {
    const bookingInBerthList = state.get('bookingList').toJS()
    const neutralBookingList = state.get('neutralBookingList').toJS()
    const currentDay = state.get('currentDay')
    const allBookings = [...bookingInBerthList, ...neutralBookingList]

    const allCountBookingsList = allBookings.filter((item) => {
      const timeCheckInDate =
        item.checkin_date &&
        moment(item.checkin_date, `${STANDARD_DAY_FORMAT} ${STANDARD_TIME_FORMAT}`).format(
          STANDARD_DAY_FORMAT
        )
      const currentTimeMinutes = moment(currentDay).format(STANDARD_DAY_FORMAT)

      return timeCheckInDate === currentTimeMinutes
    })

    let notYetAllocatedForSameDayReception = 0
    let sameDayReservationsAllocated = 0
    let reservationsAccepted = 0

    allCountBookingsList.forEach((item) => {
      const { status, berth_id, source } = item

      // status = 2, berth_id = null, source in (2,3,4)
      if (
        status === statusValues.Recept &&
        _isEmpty(berth_id) &&
        [BOOKING_SOURCES.Two, BOOKING_SOURCES.Three, BOOKING_SOURCES.Four].includes(source)
      ) {
        notYetAllocatedForSameDayReception++
      }

      // status = 7, berth_id != null, source in (2,3,4)
      if (
        status === statusValues.Arrival &&
        berth_id &&
        [BOOKING_SOURCES.Two, BOOKING_SOURCES.Three, BOOKING_SOURCES.Four].includes(source)
      ) {
        sameDayReservationsAllocated++
      }

      // status = 2 || 7, source in (1,5)
      if (
        [statusValues.Arrival, statusValues.Recept].includes(status) &&
        [BOOKING_SOURCES.One, BOOKING_SOURCES.Five].includes(source)
      ) {
        reservationsAccepted++
      }
    })

    return {
      numberOfWaitingCars:
        notYetAllocatedForSameDayReception + sameDayReservationsAllocated + reservationsAccepted,
      notYetAllocatedForSameDayReception,
      sameDayReservationsAllocated,
      reservationsAccepted,
    }
  })

export const tasklistLoadInfoSettingSelectors = () =>
  createSelector(homePageDomainSelector, (state) => state.get('tasklistLoadInfo')?.toJS())
