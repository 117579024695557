import React, { Component } from 'react'

import { Label, Input } from 'reactstrap'

import { FormWrapper } from './style'

class CheckboxForm extends Component {
  handleChange = (evt) => {
    const { onChange } = this.props
    const { type, value, checked, name } = evt.target
    if (type === 'checkbox') {
      onChange(checked)
    } else {
      onChange(value, name)
    }
  }
  render() {
    const { id, name, isDisabled, value, label, className } = this.props

    return (
      <FormWrapper className={className}>
        <Label for={id}>
          <Input
            className="custom-checktype"
            type="checkbox"
            id={id}
            onChange={this.handleChange}
            checked={value}
            name={name}
            disabled={isDisabled}
          />
          <span className="checkmark" />
          <span className="text-checkbox">{label}</span>
        </Label>
      </FormWrapper>
    )
  }
}

export default CheckboxForm
