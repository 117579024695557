import styled from 'styled-components'

export const PreviewItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  margin: 0 auto;
`

export const Th = styled.th`
  background-color: #f0f0f0;
  padding-top: 20px;
  vertical-align: bottom;
  z-index: 2;
`
