import React, { memo, FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import CloseModalIcon from 'assets/svgs/close-modal.svg'
import theme from 'vars/theme'

export const ButtonClose = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  color: ${theme.blueMedium5};
  font-size: 18px;

  &:hover {
    color: ${theme.blueMedium2};
  }

  &:focus {
    outline: 0;
  }
`

export type Props = {
  to?: string
  onClick?: () => void
}

const PrevButton: FC<Props> = ({ to, onClick }) =>
  to ? (
    <Link to={to} className="d-flex align-items-center">
      <img src={CloseModalIcon} alt="close modal" className="mr-1" />
      もどる
    </Link>
  ) : (
    <ButtonClose onClick={onClick}>
      <img src={CloseModalIcon} alt="close modal" className="mr-1" />
      もどる
    </ButtonClose>
  )

export default memo(PrevButton)
