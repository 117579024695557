import styled from 'styled-components'
import { Button as ButtonReactstrap } from 'reactstrap'

import theme from 'vars/theme'

import SelectOptions from 'components/SelectOptions'
import Button from 'containers/IntegratedManagementPage/components/Button'

export const CustomSelectOption = styled(SelectOptions)`
  .mono-select__control {
    background-color: white;
    border: 1px solid #cccccc;
  }
  .mono-select__menu {
    background-color: white;
    color: #505155;
    .mono-select__option {
      color: #505155;
    }
    .mono-select__option--is-focused {
      background-color: rgba(77, 79, 83, 0.2);
    }
    .mono-select__option--is-selected {
      color: white;
      background-color: #505155;
    }
  }
  .mono-select__single-value {
    color: #505155;
  }
  .mono-select__indicator > svg {
    fill: ${theme.blueMedium5};
  }
`

export const SwitchButton = styled(ButtonReactstrap)`
  background-color: white;
  color: ${theme.blueMedium5};
  margin-left: 8px;
  border-radius: 64px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    background-color: ${theme.blueMedium5};
    box-shadow: 0 0 0 1px ${theme.blueMedium5};
    color: white;
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20px 15px;
`

export const WrapperPagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 4px 10px;
`

export const PaginationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PaginationTextResult = styled.span`
  font-size: 14px;
  margin-right: 16px;

  span {
    font-size: 16px;
    font-weight: bold;
    margin-left: 6px;
  }

  &.total-item {
    font-size: 22px;
  }
`

export const WrapperSelect = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  margin-right: 16px;

  span {
    font-size: 16px;

    &.total-item {
      padding-top: 6px;
    }
  }
`

export const WrapperContent = styled.div`
  position: relative;
  overflow-y: scroll;
  border: 1px solid #ddd;
  background-color: #fff;
  height: calc((var(--vh, 1vh) * 100) - 170px);

  @media (max-width: 1024px) {
    height: calc((var(--vh, 1vh) * 100) - 220px);
  }
`

export const Th = styled.th`
  top: 0;
  background-color: white;
  padding-top: 20px;
  vertical-align: bottom;
  z-index: 3;
  font-size: 16px;
  font-weight: bold;
  position: sticky;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: #434d63;
    z-index: 3;
  }
`

export const Td = styled.td`
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  padding: 12px 32px;
`

export const WrapperContentTable = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
export const WrapperButton = styled.div`
  margin-bottom: 8px;
`

export const ContentTextWrapper = styled.div`
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
`

export const WrapperIconImportExport = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  margin-top: 3px;

  .import,
  .export {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    border: 1px solid rgba(37, 39, 44, 0.6);
  }

  .import {
    border-right: none;
    border-bottom-left-radius: 4px;
  }

  .export {
    border-top-right-radius: 4px;
  }

  .active {
    opacity: 1;
    color: ${theme.whitePrimary};
    background: ${theme.darkBlueMedium4};
    border: none;
  }
`

export const VehicleSummaryRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const VehicleSummaryIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
`

export const CustomButton = styled(Button).attrs(() => ({
  style: {
    width: 70,
    height: 29,
    fontSize: 9,
    padding: '5px 9px',
    borderRadius: '4px',
  },
}))``

export const WrapperEditBooking = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2px;
`

export const EditBookingButton = styled(SwitchButton)`
  border-radius: 999px;
  height: 30px;
  width: 30px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #247cdb;
`

export const WrapperConfirmBooking = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2px;
`

export const ConfirmBookingButton = styled(Button).attrs(() => ({
  style: {
    minWidth: 'fit-content',
    minHeight: '35px',
    fontSize: 12,
    padding: '12px 15px',
    borderRadius: '20px',
  },
}))``

export const PositionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2px;
`

export const PositionItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 20px;
`

export const PositionItemElement = styled.div`
  display: flex;
  flex: 1;
  min-height: 20px;
  width: calc((100% / 2) - 10px);
`

export const PositionItemDescription = styled.p`
  display: flex;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
`

export const CharacterDatePicker = styled.span`
  margin-left: 12px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
`
export const ButtonItem = styled.div`
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  padding: 5px 5px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${theme.graySoft8};
  box-sizing: border-box;
  border-radius: 4px;

  :hover {
    box-shadow: 0px 2px 8px rgba(17, 32, 64, 0.25);
  }
`

export const TextLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-clamp: 1;
  box-orient: vertical;
  min-width: 25px;
`
export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`

export const WrapperLoadingIndicator = styled.div`
  width: 100%;
  height: 100%;
  position: ${(props) => (props.isRelative ? 'relative' : 'fixed')};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .sk-circle {
    margin: 100px auto;
    width: ${(props) => props.size || '60px'};
    height: ${(props) => props.size || '60px'};
    position: relative;
  }
  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #ad2a58;
    border-radius: 100%;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }
  .sk-circle .sk-circle2 {
    transform: rotate(30deg);
  }
  .sk-circle .sk-circle3 {
    transform: rotate(60deg);
  }
  .sk-circle .sk-circle4 {
    transform: rotate(90deg);
  }
  .sk-circle .sk-circle5 {
    transform: rotate(120deg);
  }
  .sk-circle .sk-circle6 {
    transform: rotate(150deg);
  }
  .sk-circle .sk-circle7 {
    transform: rotate(180deg);
  }
  .sk-circle .sk-circle8 {
    transform: rotate(210deg);
  }
  .sk-circle .sk-circle9 {
    transform: rotate(240deg);
  }
  .sk-circle .sk-circle10 {
    transform: rotate(270deg);
  }
  .sk-circle .sk-circle11 {
    transform: rotate(300deg);
  }
  .sk-circle .sk-circle12 {
    transform: rotate(330deg);
  }
  .sk-circle .sk-circle2:before {
    animation-delay: -1.1s;
  }
  .sk-circle .sk-circle3:before {
    animation-delay: -1s;
  }
  .sk-circle .sk-circle4:before {
    animation-delay: -0.9s;
  }
  .sk-circle .sk-circle5:before {
    animation-delay: -0.8s;
  }
  .sk-circle .sk-circle6:before {
    animation-delay: -0.7s;
  }
  .sk-circle .sk-circle7:before {
    animation-delay: -0.6s;
  }
  .sk-circle .sk-circle8:before {
    animation-delay: -0.5s;
  }
  .sk-circle .sk-circle9:before {
    animation-delay: -0.4s;
  }
  .sk-circle .sk-circle10:before {
    animation-delay: -0.3s;
  }
  .sk-circle .sk-circle11:before {
    animation-delay: -0.2s;
  }
  .sk-circle .sk-circle12:before {
    animation-delay: -0.1s;
  }

  @keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`

export const IconSort = styled.img`
  cursor: pointer;
`
