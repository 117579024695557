const STORAGE_KEY = 'reception'

const Storage = {
  read(key) {
    const data = localStorage.getItem(STORAGE_KEY)
    const values = data ? JSON.parse(data) : {}
    return key ? values[key] : values
  },

  write(key, value) {
    const values = Storage.read()
    values[key] = value
    const json = JSON.stringify(values)
    localStorage.setItem(STORAGE_KEY, json)
  },
}

export default Storage
