import styled, { css } from 'styled-components'
import {
  Modal as ModalDefault,
  ModalHeader as ModalHeaderDefault,
  ModalBody as ModalBodyDefault,
} from 'reactstrap'

import theme from 'vars/theme'

export const MessageModal = styled(ModalDefault)`
  width: 500px;

  .modal-content {
    padding: 5px;
  }
`

export const ModalHeader = styled(ModalHeaderDefault)`
  padding: 0;

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    outline: none;
    background: white;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  h5 {
    margin: 0;
    font-weight: bold;
  }
`

export const ModalContent = styled.div`
  border-radius: 10px;
  background: ${theme.graySoft1};
`

export const ModalBody = styled(ModalBodyDefault)`
  display: flex;
  flex-direction: column;
  margin: 25px;
`

export const FilterItem = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 5px;
  border: 2px solid ${theme.graySoft2};
  border-radius: 10px;
  outline: unset;
  background-color: white;
  cursor: pointer;

  &:hover {
    border: 2px solid ${theme.blueMedium5};
    background: white;
    color: ${theme.blueMedium5};
  }
`

export const RadioFilter = styled.input`
  display: none;

  &:checked + label {
    border: 2px solid ${theme.blueMedium5};
    color: ${theme.blueMedium5};
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-right: 5px;
`

export const ButtonImport = styled.button`
  margin-right: 2px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid ${theme.brownMedium1};
  color: ${theme.brownMedium1};
  background-color: white;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    border: 1px solid ${theme.blueMedium5};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
      background-color: ${theme.brownMedium1};
    `}

  @supports (-webkit-touch-callout: none) {
    font-size: 14px;
    margin-right: 3px;
    padding-left: 4px;
    padding-right: 4px;
  }
`

export const ButtonExport = styled.button`
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid ${theme.brownMedium1};
  color: ${theme.brownMedium1};
  background-color: white;
  font-size: 18px;
  padding-left: 10px;
  padding-right: 10px;

  &:hover {
    border: 1px solid ${theme.blueMedium5};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
      background-color: ${theme.brownMedium1};
    `}

  @supports (-webkit-touch-callout: none) {
    font-size: 14px;
    padding-left: 4px;
    padding-right: 4px;
  }
`

export const ButtonAll = styled.div`
  padding: 0 10px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid ${theme.brownMedium1};
  color: white;
  background-color: ${theme.brownMedium1};
`
