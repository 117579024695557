import React, { memo } from 'react'

const PAGE_INDEXES = Object.freeze({
  'entry-bookings': 0,
  'entry-driver-info': 1,
  'entry-load': 2,
  'entry-confirmation': 3,
})

type Props = {
  page: keyof typeof PAGE_INDEXES
  existsDriverConfirmations?: boolean
  isFreePlan?: boolean
}

const PageNumber: React.FC<Props> = ({
  page,
  existsDriverConfirmations = false,
  isFreePlan = false,
}) => {
  const currentPage = PAGE_INDEXES[page] + (isFreePlan ? 0 : 1)
  const totalPage = 2 + (existsDriverConfirmations ? 1 : 0) + (isFreePlan ? 0 : 1)
  return <span>{`${currentPage}/${totalPage}`}</span>
}

export default memo(PageNumber)
