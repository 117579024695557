import React, { Component, Fragment } from 'react'
import { Button } from 'reactstrap'
import { NavLink, Prompt } from 'react-router-dom'
import styled from 'styled-components'
import { connect } from 'react-redux'
import _ from 'lodash'
import { camelizeKeys } from 'humps'

import Switch from 'components/Switch'
import Icon from 'components/Icon'
import {
  fetchAnything,
  openError,
  startLoading,
  stopLoading,
  openToast,
} from 'containers/App/reducers'
import {
  TenantParameterTypes,
  PARAMETER_NAME_LENGTH,
  OPTION_NAME_LENGTH,
  ADDITIONAL_COLUMN_KEYS,
  ReceptionCustomOptionGroups,
} from 'constants/ReceptionCustom'
import StyledSelect from 'containers/Reception/components/StyledSelect'
import Header from 'components/Layout/Header'
import { Input } from 'containers/Reception/components/utils'

import { patchLoadOptions, requestGet } from '../api'
import { updateOptions, updateParameter } from '../reducers'
import { HEADER_HEIGHT } from '../constants'
import FinishToast from '../components/FinishToast'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import { trimData } from 'utils/commonFunctions'

const CustomButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
`

const READ_ONLY_VEHICLE_CATEGORY = 8
const READ_ONLY_PACKAGE_TYPE = 10

const formatTypes = [TenantParameterTypes.TEXT.key, TenantParameterTypes.SELECT.key].map((key) => ({
  value: TenantParameterTypes[key].id,
  label: TenantParameterTypes[key].label,
}))

const REQUIRED_PARAM_KEYS = ['id', 'tenantId', 'displayFlag', 'parameterName']

class OptionsPage extends Component {
  initialParameter = {}
  initialOptions = []

  optionGroup = {
    key: '',
    id: 0,
  }

  state = {
    // fix "A history supports only one prompt at a time" warning
    // ref: https://github.com/ReactTraining/react-router/issues/5707
    enablePrompt: false,
  }

  constructor(props) {
    super(props)
    const { optionGroup } = this.props.match.params
    this.optionGroup.key = optionGroup.replace('-', '_').toUpperCase()
    this.optionGroup.id = ReceptionCustomOptionGroups[this.optionGroup.key].id
  }

  async componentDidMount() {
    const { tenantId, selectedParameter } = this.props

    if (!selectedParameter.id) {
      this.props.history.push('/reception-custom/items')
      return
    }

    let resp

    this.props.startLoading()

    try {
      resp = await requestGet('/reception/options', {
        tenantId,
        optionGroupId: this.optionGroup.id,
      })
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const { options } = camelizeKeys(resp.data)
    this.initialOptions = [...options]
    this.initialParameter = { ...selectedParameter }

    this.props.updateOptions(options)

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ enablePrompt: true })
  }

  onChangeVisibility = (item) => {
    const { options } = this.props

    const foundIndex = options.findIndex((option) => option.id === item.id)

    options[foundIndex] = {
      ...item,
      displayFlag: !item.displayFlag,
    }

    this.props.updateOptions(options)
  }

  onClickRemove = (item) => {
    const { options } = this.props

    const foundIndex = options.findIndex((option) => option.id === item.id)

    options[foundIndex] = {
      ...item,
      parameterName: '',
      displayFlag: false,
    }

    this.props.updateOptions(options)
  }

  onChangeParameterName = (e) => {
    const { value } = e.target
    this.props.updateParameter({ displayName: value })
  }

  onChangeParameterType = (option) => {
    this.props.updateParameter({ type: option.value })
  }

  onChangeOptionName = (e, item) => {
    const { options } = this.props
    const { value } = e.target

    const foundIndex = options.findIndex((option) => option.id === item.id)

    options[foundIndex] = {
      ...item,
      parameterName: value,
      displayFlag: Boolean(value.length),
    }

    this.props.updateOptions(options)
  }

  onClickRegister = async () => {
    const { options, selectedParameter, tenantId } = this.props

    let changedOptions = []
    const selectedParameterTrim = trimData(selectedParameter)

    // 項目名は空にできない
    if (!selectedParameterTrim.displayName) {
      this.props.openError('項目名は空にできません', false)
      return
    }

    if (
      [TenantParameterTypes.READ_ONLY.id, TenantParameterTypes.SELECT.id].includes(
        selectedParameter.type
      )
    ) {
      changedOptions = _.differenceWith(options, this.initialOptions, _.isEqual).map((option) => {
        const optionTrim = trimData(option)
        return REQUIRED_PARAM_KEYS.reduce((ac, key) => ({ ...ac, [key]: optionTrim[key] }), {})
      })

      if (!options.concat(changedOptions).some((option) => option.displayFlag)) {
        this.props.openError('選択肢は1つ以上必要です', false)
        return
      }

      // 名称が未入力で表示になっている場合はエラー
      if (changedOptions.some((option) => !option.parameterName && option.displayFlag)) {
        this.props.openError('選択肢の名称を未入力で表示にはできません', false)
        return
      }
    }

    const data = {
      tenantId,
      optionGroupId: this.optionGroup.id,
      options: changedOptions,
      parameter: selectedParameterTrim,
    }

    this.props.startLoading()

    try {
      await patchLoadOptions(data)
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    this.initialParameter = selectedParameter
    this.initialOptions = options

    this.props.openToast(FinishToast)
    this.props.history.push('/reception-custom/items')
  }

  render() {
    const { options, selectedParameter, userData, tenants, tenantId } = this.props
    const { enablePrompt } = this.state

    const canEditParameter = ADDITIONAL_COLUMN_KEYS.includes(this.optionGroup.key)

    const selectedTenant = tenants.find((t) => t.id === tenantId) || {}
    const isSystemAdmin = _.get(userData, 'attributes.is_warehouse_admin', false)
    const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

    return (
      <div>
        <Header>
          <div />
        </Header>

        <div
          className="p-3"
          style={{ height: window.innerHeight - HEADER_HEIGHT, overflowY: 'auto' }}
        >
          <div className="mx-auto" style={{ width: 600 }}>
            <div className="p-3 mb-3 bg-white border">
              <div className="mb-3 font-weight-bold">
                {this.optionGroup.key ? ReceptionCustomOptionGroups[this.optionGroup.key].name : ''}
              </div>
              <div className="mb-3">
                追加選択肢を作成し、表示したい
                {ReceptionCustomOptionGroups[this.optionGroup.key].name}を選択ください。
              </div>

              {canEditParameter && (
                <Fragment>
                  <div className="d-flex align-items-center mb-3">
                    <div className="mr-3" style={{ width: 60 }}>
                      項目名
                    </div>
                    <Input
                      type="text"
                      placeholder="入力してください"
                      value={selectedParameter.displayName || ''}
                      maxLength={PARAMETER_NAME_LENGTH}
                      style={{ width: 200 }}
                      onChange={this.onChangeParameterName}
                      disabled={!canUpdate}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <div className="mr-3" style={{ width: 60 }}>
                      形式
                    </div>
                    <div style={{ width: 200 }}>
                      <StyledSelect
                        value={_.find(formatTypes, { value: selectedParameter.type })}
                        options={formatTypes}
                        onChange={this.onChangeParameterType}
                        isDisabled={!canUpdate}
                      />
                    </div>
                  </div>
                </Fragment>
              )}

              <div style={{ overflowY: 'auto' }}>
                {[TenantParameterTypes.READ_ONLY.id, TenantParameterTypes.SELECT.id].includes(
                  selectedParameter.type
                ) &&
                  options.map((option) => (
                    <div
                      key={option.id}
                      className="d-flex mb-1 align-items-center"
                      style={{ height: 40 }}
                    >
                      <Switch
                        checked={option.displayFlag}
                        onChange={() => this.onChangeVisibility(option)}
                        className="mr-3"
                        disabled={!canUpdate}
                      />
                      {[READ_ONLY_VEHICLE_CATEGORY, READ_ONLY_PACKAGE_TYPE].includes(
                        option.codeId
                      ) ? (
                        <span>{option.parameterName}</span>
                      ) : (
                        <Fragment>
                          <Input
                            type="text"
                            value={option.parameterName || ''}
                            maxLength={OPTION_NAME_LENGTH}
                            style={{ width: 360 }}
                            onChange={(e) => this.onChangeOptionName(e, option)}
                            disabled={!canUpdate}
                          />
                          {canUpdate && Boolean(option.parameterName) && (
                            <CustomButton onClick={() => this.onClickRemove(option)}>
                              <Icon src="/assets/svgs/circle-minus.svg" />
                            </CustomButton>
                          )}
                        </Fragment>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <NavLink to="/reception-custom/items">
                <Button outline color="primary" className="text-primary bg-white rounded-pill px-4">
                  もどる
                </Button>
              </NavLink>

              {canUpdate && (
                <React.Fragment>
                  {canEditParameter ? (
                    <Button
                      color="primary"
                      className="rounded-pill px-4"
                      disabled={
                        _.isEqual(this.initialParameter, selectedParameter) &&
                        _.isEqual(this.initialOptions, options)
                      }
                      onClick={this.onClickRegister}
                    >
                      登録
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="rounded-pill px-4"
                      disabled={_.isEqual(this.initialOptions, options)}
                      onClick={this.onClickRegister}
                    >
                      登録
                    </Button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {enablePrompt && (
          <Prompt
            when={
              canEditParameter
                ? !_.isEqual(this.initialParameter, selectedParameter) ||
                  !_.isEqual(this.initialOptions, options)
                : !_.isEqual(this.initialOptions, options)
            }
            message={
              '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
            }
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const receptionCustom = state.get('receptionCustom')
  return {
    tenantId: receptionCustom.tenantId,
    options: receptionCustom.options,
    selectedParameter: receptionCustom.selectedParameter,
    tenants: receptionCustom.tenants,
    userData: state.get('containers/App').get('userData').toJSON(),
  }
}

const mapDispatchToProps = {
  fetchAnything,
  openError,
  updateOptions,
  updateParameter,
  startLoading,
  stopLoading,
  openToast,
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsPage)
