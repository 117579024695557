import React from 'react'
import styled from 'styled-components'

import Icon from 'components/Icon'

const Outer = styled.span`
  position: relative;
  height: 24px;

  & input {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100px;
    height: 24px;
    z-index: 1;
  }

  & input:checked ~ .checked {
    display: block;
  }

  & input:checked ~ .not-checked {
    display: none;
  }

  & .check-icon {
    position: absolute;
    top: 2px;
    left: 0;
    border-radius: 4px;
    width: 24px;
    height: 24px;
  }

  & .checked {
    display: none;
    background-color: #247cdb;
  }

  & .not-checked {
    border-width: 2px;
    border-style: solid;
    background-color: white;
  }
`

export default ({ label, checked, disabled = false, isGray = false, className = '', onChange }) => (
  <Outer
    className={className}
    style={{ width: label ? undefined : 32, opacity: disabled && !isGray ? 0.4 : 1.0 }}
  >
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      style={{ cursor: disabled ? 'default' : 'pointer' }}
    />
    <span
      className="check-icon checked text-center"
      style={{ backgroundColor: isGray ? '#CCC' : '#247cdb' }}
    >
      <Icon
        src="/assets/svgs/icon-select-white.svg"
        width="14px"
        height="14px"
        style={{ marginTop: -10 }}
      />
    </span>
    <span className="check-icon not-checked" style={{ borderColor: isGray ? '#CCC' : '#247cdb' }} />
    {label && (
      <span className="text-primary font-weight-bold" style={{ marginLeft: 32 }}>
        {label}
      </span>
    )}
  </Outer>
)
