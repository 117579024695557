import React, { FC } from 'react'

import UserIcon from 'assets/svgs/multi-booking/human.svg'
import CompanyIcon from 'assets/svgs/multi-booking/company-name.svg'
import TruckBlackIcon from 'assets/svgs/multi-booking/truck.svg'
import lineApp from 'assets/pngs/line-app.png'
import { ReactComponent as MonofulIcon } from 'assets/svgs/multi-booking/monoful-icon.svg'
import { formatDriverTelNumber, formatCarNumber } from '../utils'
import { NON_VALUE_TEXT } from '../constants'
import * as S from '../style'

type Props = {
  booking: any
  vehicleCategoryMap: any
}

type ExportImportProps = {
  importFlag: Boolean
  exportFlag: Boolean
}

const ImportExportIcon: FC<ExportImportProps> = ({ importFlag, exportFlag }) => {
  const importClass = `import ${importFlag ? 'active' : ''}`
  const exportClass = `export ${exportFlag ? 'active' : ''}`
  return (
    <S.WrapperIconImportExport>
      <span className={importClass}>卸</span>
      <span className={exportClass}>積</span>
    </S.WrapperIconImportExport>
  )
}

const VehicleSummaryBookingEditor: FC<Props> = ({ booking, vehicleCategoryMap }) => {
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex' }}>
        {/* left */}
        <div style={{ width: 67, display: 'flex', alignItems: 'center' }}>
          <S.VehicleSummaryLeftWrapper>
            {/* top */}
            <S.VehicleSummaryIconWrapper>
              {booking?.lineConnected && <img src={lineApp} alt="" width={18} height={18} />}
              {booking?.portalFlag && (
                <MonofulIcon
                  width={18}
                  height={18}
                  style={{
                    marginLeft: 'auto',
                  }}
                />
              )}
            </S.VehicleSummaryIconWrapper>
            {/* bottom */}
            <div style={{ lineHeight: 1 }}>
              <ImportExportIcon
                importFlag={!!booking?.importFlag}
                exportFlag={!!booking?.exportFlag}
              />
            </div>
          </S.VehicleSummaryLeftWrapper>
        </div>

        {/* right */}
        <S.VehicleSummaryRightWrapper>
          {/* top */}
          <S.VehicleSummaryInfo>
            <img src={UserIcon} alt="" style={{ marginRight: 6 }} width={12} />
            <S.TextLabel>{booking?.driverName}</S.TextLabel>
            <span className="ml-1">
              {booking?.driverTel && `(${formatDriverTelNumber(booking?.driverTel)})`}
            </span>
          </S.VehicleSummaryInfo>
          {/* middle */}
          <S.VehicleSummaryInfo>
            <img src={CompanyIcon} alt="" style={{ marginRight: 6 }} width={12} />
            <S.TextLabel> {booking?.driverCompanyName || NON_VALUE_TEXT}</S.TextLabel>
            <span className="ml-1">
              {booking?.vehicleNumber ? formatCarNumber(booking?.vehicleNumber) : NON_VALUE_TEXT}
            </span>
          </S.VehicleSummaryInfo>
          {/* bottom */}
          <div style={{ lineHeight: 1 }}>
            <img src={TruckBlackIcon} alt="" style={{ marginRight: 6 }} width={20} height={15} />

            {booking?.vehicleCategory &&
              vehicleCategoryMap &&
              typeof vehicleCategoryMap[booking?.vehicleCategory] === 'string' && (
                <span>{vehicleCategoryMap[booking?.vehicleCategory]}</span>
              )}
          </div>
        </S.VehicleSummaryRightWrapper>
      </div>
    </div>
  )
}
export default VehicleSummaryBookingEditor
