import styled, { css } from 'styled-components'
import { Table } from 'reactstrap'

import theme from 'vars/theme'

const HightLight = css`
  color: ${theme.blueMedium1};
  max-width: 160px;
  word-break: break-word;
`

const TableWrapper = styled.div`
  padding: 0 20px;
  height: ${(props) => props.tableHeight || '100%'};
`

const StyledTable = styled(Table)`
  thead {
    border-width: 0;

    th {
      border: 0;
      font-size: 14px;
      color: ${theme.grayMedium1};
      font-weight: 300;
      padding: 0.75rem;
    }
  }

  tbody {
    border-bottom-width: 0;

    tr {
      border: 1px solid ${theme.graySoft2};
      border-bottom-width: 0;
      border-radius: 2px;

      &:hover {
        background-color: ${theme.blueSoft2};
      }

      &:first-child {
        padding-left: 1.5rem;
      }

      &:last-child {
        border-bottom-width: 1px;
      }
    }
  }
`

export const Td = styled.td`
  font-size: 14px;
  padding: 0.5rem 0.75rem;
  line-height: 2;
  border: 0;
  max-width: 180px;
  word-break: break-word;

  ${({ isHighlight }) => isHighlight && HightLight}

  ${({ hasTruncate }) =>
    hasTruncate &&
    css`
      overflow: hidden;
      white-space: nowrap;
      word-break: normal;
      text-overflow: ellipsis;
    `}

  &.name,
  &.email,
  &.password,
  &.time,
  &.password,
  &.url,
  &.active {
    ${HightLight}
  }

  &.time,
  &.password {
    button {
      color: ${theme.blueMedium1};
      border: none;
      background: transparent;
      padding: 0 1.5rem 0 0;
      cursor: pointer;
      word-break: keep-all;
      outline: none;
    }
  }

  &.btn-name {
    ${HightLight}
    width: 26%;
  }

  &.message,
  &.status {
    width: 26%;
  }

  &.custom-delegation-name {
    width: 20%;
  }

  &.dayOfWeek,
  &.bannedTime {
    width: 18%;
  }

  &.startDate,
  &.startTime {
    width: 15%;
  }
`

const PaginationWrapper = styled.div`
  padding: 0.5rem ${(props) => props.paddingX || '1rem'};
`

export { TableWrapper, StyledTable, PaginationWrapper }
