import React, { useState, useEffect } from 'react'

import ConfirmModal from 'components/ConfirmModal'
import { ConfirmModalProps } from '../type'

const initialConfirmModalProps = {
  title: '',
  handleCancel: () => {},
  handleConfirm: () => {},
}

const useConfirmModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [{ title, handleCancel, handleConfirm }, setConfirmModalProps] =
    useState<ConfirmModalProps>(initialConfirmModalProps)

  useEffect(() => {
    if (!isOpen) {
      setConfirmModalProps(initialConfirmModalProps)
    }
  }, [isOpen])

  const showConfirmModal = (confirmModal: ConfirmModalProps) => {
    setConfirmModalProps(confirmModal)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return {
    showConfirmModal,
    ConfirmModal: () => (
      <ConfirmModal
        width={480}
        isOpen={isOpen}
        title={title}
        handleCancel={() => handleCancel(closeModal)}
        handleConfirm={() => handleConfirm(closeModal)}
      />
    ),
  }
}

export default useConfirmModal
