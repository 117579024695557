import RestPhoneBookingClient, { getAuthHeader } from './RestPhoneBookingClient'
import { trimData } from 'utils/commonFunctions'

export default class PhoneBookingService {
  constructor(restClient = RestPhoneBookingClient) {
    this.restClient = restClient
  }

  vefiryPhoneNumber(phone_number) {
    return this.restClient.post({
      url: 'mobile/verify_phone',
      data: {
        phone_number,
      },
    })
  }

  vefiryCheckin(phone_number) {
    return this.restClient.post({
      url: 'mobile/verify_checkin',
      data: {
        phone_number,
      },
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  verifyPasscode({ phone_number, code }) {
    return this.restClient.post({
      url: 'mobile/verify_passcode',
      data: {
        phone_number,
        code,
      },
    })
  }

  resendCode({ phone_number, tenant_id }) {
    return this.restClient.post({
      url: 'mobile/resend_code',
      data: { phone_number, tenant_id },
      config: {},
    })
  }

  getMasterData({ tenant_id }) {
    return this.restClient.get(
      '/tablets/master_data',
      { tenant_id },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  getDriverCompanyList({ tenant_id }) {
    return this.restClient.get(
      'mobile/list_companies',
      { tenant_id },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  getRecentBooking({ driver_phone, tenant_id }) {
    return this.restClient.get(
      'mobile/recent_booking',
      { driver_phone, tenant_id },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  getDriverBookingList({ driver_phone }) {
    return this.restClient.get(
      'mobile/list_bookings',
      { driver_phone },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  getDriverTenantList({ driver_phone }) {
    return this.restClient.get(
      'mobile/list_tenants_by_driver_phone',
      { driver_phone },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  getBerthInFreeTime({ tenant_id, start_booking_time, duration }) {
    return this.restClient.get(
      'mobile/berth_in_free_time',
      { tenant_id, start_booking_time, duration },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  resendPasscode(phone_number) {
    return this.restClient.post({
      url: 'mobile/resend_code',
      data: { phone_number },
      config: {},
    })
  }

  getSingleTenantByUniqueNumber({ tenant_number }) {
    return this.restClient.get(
      'mobile/tenant_by_number',
      { tenant_number },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  createNewPhoneBooking(data) {
    return this.restClient.post({
      url: 'mobile/mobile_booking',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  updatePolicyChecked({ phone_number, policy_id }) {
    return this.restClient.patch({
      url: 'mobile/update_policy_checked',
      data: { phone_number, policy_id },
      config: {},
    })
  }

  getPolicy() {
    return this.restClient.get(
      'policies/newest',
      {},
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }
}
