import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 250px;
  font-size: 12px;

  .title {
    margin-bottom: 3px;
    padding: 0 10px;

    &-highlight {
      color: #ff0000;
    }
  }

  ul {
    padding-left: 25px;
    li {
      margin: 0;
    }
  }

  .style-custom {
    list-style: none;
    counter-reset: custom-counter;
    padding: 0px;
  }

  .style-custom > li {
    counter-increment: custom-counter;
  }

  .style-custom li::before {
    content: '(' counter(custom-counter) ') ';
  }

  .style-decimal {
    list-style-type: decimal;
    margin-bottom: 4px;
  }
  @media screen and (max-height: 800px) {
    max-height: 160px;
  }
`

function TermOfUseHeader() {
  return (
    <Wrapper className="custom-scrollbar">
      <p className="title">
        個人であるお客様は、モノフルが提供するトラック簿サービスを利用するにあたり、以下の事項に同意するものとします。
        <span className="title-highlight">（令和4年12月7日改訂）</span>
      </p>
      <ul className="style-decimal">
        <li>monoful利用規約、プライバシーポリシーおよびトラック簿利用規約の内容</li>
        <li>
          バース予約に関し、お客様（またはお客様の代わりに他の会員）がトラック簿の画面に入力するお客様の氏名、電話番号、所属する運送事業者名、車両形態、車番等の情報（以下「ドライバー情報」といいます。）が、モノフルを介して、当該バース予約についてトラック簿を利用する権限のある他の会員に対し、以下の目的のために提供されること。
          <ul className="style-custom">
            <li>バースの予約をした者および指定されたドライバー、車両等の確認</li>
            <li>倉庫入場、受付</li>
            <li>受付が完了したドライバーのバースへの呼出し</li>
            <li>バースの予約をした者またはドライバーへの緊急連絡</li>
            <li>
              その他トラック簿サービスを通して行われるバースの予約および受付に付帯・関連したドライバー情報の利用
            </li>
            <li>
              法令により、または裁判所、警察、税関等の公的機関からの求めに応じて、必要な範囲内でドライバー情報を開示すること
            </li>
          </ul>
          <li>
            モノフルは、前項(6)の目的のために、他の会員の求めに応じてドライバー情報をCSVファイル等のデータ（以下「ドライバー情報データ」といいます。）によって提供することがあること。なお、他の会員が、トラック簿利用規約の条件に反してドライバー情報データの提供をモノフルに対して求め、または、トラック簿利用規約の定めに反する態様でドライバー情報データを利用したことによってお客様に損害が生じた場合であっても、モノフルは何らの責任も負担しないものとします。
          </li>
        </li>
      </ul>
    </Wrapper>
  )
}

export default TermOfUseHeader
