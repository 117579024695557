import styled, { css } from 'styled-components'
import theme from 'vars/theme'
import { DropdownItem } from 'reactstrap'
import { Modal } from 'reactstrap'
import Button from 'components/Button'

export const UploadCSV = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;

  button:disabled {
    cursor: not-allowed;
  }

  button[aria-haspopup='true'] {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px !important;
    height: 24px;
    border: 1px solid ${theme.blueMedium5};
    border-radius: 999px !important;
    background-color: white;
    padding: 0;

    &:focus {
      box-shadow: none !important;
    }

    &:active {
      background-color: ${theme.whitePrimary} !important;
    }
  }

  .dropdown-menu {
    box-shadow: 0px 4px 24px rgba(37, 39, 44, 0.25);
    border-radius: 20px;
    margin-top: 6px;
    padding: 17px 0;
    overflow: hidden;
    border: none;
    transform: translate(-40%, 24px) !important;
  }

  .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    min-height: 33px;
    padding: 0 12px;

    &:hover {
      background: ${theme.blueMedium5};
      color: ${theme.whitePrimary};

      path {
        fill: ${theme.whitePrimary};
      }
    }

    &:focus {
      outline: 0;
    }

    svg {
      margin-right: 3px;
    }
  }

  .dropdown-divider {
    margin: 0;
  }
`
export const CustomDropdownItem = styled(DropdownItem)`
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    margin: 0;
    cursor: pointer;
  }

  input {
    display: none;
  }
`

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  label {
    margin-bottom: 0;
  }

  span {
    margin-right: 40px;
  }

  .label-flex {
    flex-basis: 100px;
  }
`

export const CustomModal = styled(Modal)`
  ${Wrapper}:nth-child(3) {
    margin-bottom: 45px;
  }

  .modal-content {
    padding: 30px 90px 55px 90px;
  }
`

export const NameFile = styled.div`
  margin-right: 10px;
  padding: 5px;
  width: 250px;
  height: 40px;
  border-radius: 3px;
  border: 2px solid #f2f2f2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 10px;
  }
`

export const CustomButton = styled(Button)`
  width: 125px;
  padding: 2px 0;
`

export const InputHidden = styled.input`
  display: none;
`
