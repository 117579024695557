import React, { useEffect, useState } from 'react'
import { NavLink, Prompt, useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import Header from 'components/Layout/Header'
import {
  fetchReceptionParameters,
  updateWaitingSetting,
  updateInitialWaitingSetting,
} from '../reducers'
import {
  waitingSettingSelectors,
  initialWaitingSettingSelectors,
  tenantsSelectors,
  tenantIdSelectors,
  warehouseIdSelectors,
} from '../selectors'
import { startLoading, stopLoading, openToast } from 'containers/App/reducers'
import { userDataSelectors } from 'containers/App/selectors'
import { TAB_MODE } from '../constants'
import { UserDataType, TenantType, WaitingSettingType } from 'utils/types'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import { receptionCustomService } from 'services'
import FinishToast from '../components/FinishToast'
import { COUNT_TRUCK_SETTING_MODE } from '../constants'
import * as S from './style'

interface Props {
  tenantId: number
  warehouseId: number
  userData: UserDataType
  tenants: TenantType[]
  waitingSetting: WaitingSettingType
  initialWaitingSetting: WaitingSettingType
  startLoading: () => void
  stopLoading: () => void
  fetchReceptionParameters: (param: string) => void
  changeWarehouse: (warehouseId: number) => void
  changeTenant: (tenantId: number) => void
  updateWaitingSetting: (params: any) => void
  updateInitialWaitingSetting: (params: any) => void
  openError: (message: string) => void
  openToast: (param: any) => void
}

const SettingWaitingTruckPage: React.FC<Props> = ({
  userData,
  tenantId,
  warehouseId,
  tenants,
  waitingSetting,
  initialWaitingSetting,
  startLoading,
  stopLoading,
  openToast,
  fetchReceptionParameters,
  updateWaitingSetting,
  updateInitialWaitingSetting,
}) => {
  const history = useHistory()
  const [error, setError] = useState<any>({})

  const selectedTenant = tenants.find((t) => t.id === tenantId) || ({} as TenantType)
  const userAttributes = userData?.attributes
  const isSystemAdmin = userAttributes?.is_warehouse_admin
  const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

  const { countType } = waitingSetting || {}
  const isCardCheckedButNotInBerthMode =
    countType === COUNT_TRUCK_SETTING_MODE.TRUCK_CHECKED_BUT_NOT_IN_BERTH
  const isAllCardCheckedMode = countType === COUNT_TRUCK_SETTING_MODE.ALL_CARD_CHECKED

  useEffect(() => {
    fetchReceptionParameters(TAB_MODE.COUNT_TRUCK_SETTING)
  }, [fetchReceptionParameters])

  useEffect(() => {
    if (
      (!_.isEmpty(userAttributes) && !canUpdate) ||
      (!_.isEmpty(selectedTenant) && !selectedTenant?.canCustomizeReception)
    ) {
      history.push('/reception-custom/waiting')
    }
  }, [canUpdate, history, selectedTenant, userAttributes])

  const handleChangeCountTruckSettingMode = (CountTruckSettingMode: number | string) => {
    updateWaitingSetting({ ...waitingSetting, countType: CountTruckSettingMode })
  }

  const onSubmit = async () => {
    const { countType } = waitingSetting || {}
    startLoading()
    try {
      await receptionCustomService.createWaitingSetting({
        ...waitingSetting,
        warehouseId,
        tenantId,
        countType,
      })
      stopLoading()
      openToast(FinishToast)
      updateInitialWaitingSetting({ ...initialWaitingSetting, ...waitingSetting })
      history.push('/reception-custom/waiting')
    } catch (error) {
      stopLoading()
      const errorMessageApi = error?.message
      setError({ errorMessageApi })
      return
    }
  }

  return (
    <div>
      <Header>
        <div />
      </Header>

      <S.TimeSettingWrapper>
        <S.SettingContent>
          <S.Content>
            <div className="mb-3 font-weight-bold">トラック待機台数の設定</div>
            <div className="mb-3 ml-1">
              待機台数の集計方法を選択してください。
              <br />
              集計された待機台数はドライバー受付画面に表示されます。
            </div>
            <div className="ml-3">
              <S.CheckBoxTruckWrapper>
                <S.CheckBox
                  id="BookingNotInBerth"
                  name="count-booking"
                  type="radio"
                  checked={isCardCheckedButNotInBerthMode}
                  onChange={() => {
                    handleChangeCountTruckSettingMode(
                      COUNT_TRUCK_SETTING_MODE.TRUCK_CHECKED_BUT_NOT_IN_BERTH
                    )
                  }}
                />
                <S.Label htmlFor="BookingNotInBerth">バース未割当にある受付済のみ</S.Label>
              </S.CheckBoxTruckWrapper>

              <S.CheckBoxTruckWrapper>
                <S.CheckBox
                  id="AllBookingInBerth"
                  name="count-booking"
                  type="radio"
                  checked={isAllCardCheckedMode}
                  onChange={() => {
                    handleChangeCountTruckSettingMode(COUNT_TRUCK_SETTING_MODE.ALL_CARD_CHECKED)
                  }}
                />
                <S.Label htmlFor="AllBookingInBerth">すべての受付済</S.Label>
              </S.CheckBoxTruckWrapper>
            </div>
            {error && <S.ErrorCustom>{error?.errorMessageApi}</S.ErrorCustom>}
          </S.Content>
          <div className="d-flex justify-content-between mb-3">
            <NavLink to="/reception-custom/waiting">
              <Button outline color="primary" className="text-primary bg-white rounded-pill px-4">
                もどる
              </Button>
            </NavLink>

            {canUpdate && (
              <Button
                color="primary"
                className="rounded-pill px-4"
                disabled={_.isEqual(initialWaitingSetting, waitingSetting)}
                onClick={onSubmit}
              >
                登録
              </Button>
            )}
          </div>
        </S.SettingContent>
      </S.TimeSettingWrapper>

      <Prompt
        when={!_.isEqual(initialWaitingSetting, waitingSetting)}
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  waitingSetting: waitingSettingSelectors(),
  initialWaitingSetting: initialWaitingSettingSelectors(),
  userData: userDataSelectors(),
  tenantId: tenantIdSelectors(),
  warehouseId: warehouseIdSelectors(),
  tenants: tenantsSelectors(),
})

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openToast,
  fetchReceptionParameters,
  updateWaitingSetting,
  updateInitialWaitingSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingWaitingTruckPage)
