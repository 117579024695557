import React from 'react'

import {
  USER_DATA,
  CHECKIN_USER_DATA,
  GENERAL_CHECKIN,
  GENERAL_CHECKIN_BY_TENANT_ID,
} from 'utils/loginStorage'
import { ReloadTypes } from 'constants/Notice'
import { getInputLogger } from 'containers/App/InputLogger'
import { VERSION } from 'constants/App'

const ForceLogoutTypes = Object.freeze({
  RECEPTION: 'RECEPTION',
  MANAGEMENT: 'MANAGEMENT',
  BOTH: 'BOTH',
})

const logger = getInputLogger()

export const reloadBrowser = (forceLogout) => {
  if (forceLogout) {
    if ([ForceLogoutTypes.RECEPTION, ForceLogoutTypes.BOTH].includes(forceLogout)) {
      localStorage.removeItem(CHECKIN_USER_DATA)
      localStorage.removeItem(GENERAL_CHECKIN)
      localStorage.removeItem(GENERAL_CHECKIN_BY_TENANT_ID)
    }

    if ([ForceLogoutTypes.MANAGEMENT, ForceLogoutTypes.BOTH].includes(forceLogout)) {
      localStorage.removeItem(USER_DATA)
    }
  }

  global.location.reload()
}

export const buildReloadConfirmationModal = (forceLogout) => ({
  content: (
    <div>
      <div className="text-size24 mb-3">更新のお知らせ</div>
      <div className="text-left">
        <div>
          トラック簿の新規バージョンがご利用可能になりましたので、更新してください。
          作業中の場合は、データが消えてしまうことがありますので、「あとで更新」を押してください。
        </div>
        <div>3分後に再度通知されます。</div>
      </div>
    </div>
  ),
  buttons: [
    {
      label: 'あとで更新',
      color: 'secondary',
      onClick: () => {
        logger.startLog(false, forceLogout)
      },
    },
    {
      label: '今すぐ更新',
      color: 'primary',
      onClick: () => {
        reloadBrowser(forceLogout)
      },
    },
  ],
})

const isHigherVersion = (currentVersion, latestVersion) => {
  const [c1, c2, c3] = currentVersion.split('.').map((s) => Number(s))
  const [l1, l2, l3] = latestVersion.split('.').map((s) => Number(s))

  const current = c1 * 1000 * 1000 + c2 * 1000 + c3
  const latest = l1 * 1000 * 1000 + l2 * 1000 + l3

  return current >= latest
}

export const extractReloadNotice = (notices) =>
  notices.find((notice) => {
    const { reload = {} } = notice
    return (
      [ReloadTypes.FORCE, ReloadTypes.OPTION].includes(reload.type) &&
      !isHigherVersion(VERSION, reload.truckbookVersion)
    )
  })
