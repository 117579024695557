import React from 'react'
import { Link } from 'react-router-dom'

import { tabs } from '../constants'
import { TabWrapper } from './styles'

const getSelectedTabStyles = (selected: boolean) =>
  selected
    ? {
        color: 'white',
        backgroundColor: '#247cdb',
      }
    : {}

interface TabsProps {
  currentTabId: string
}

const Tabs = ({ currentTabId }: TabsProps) => {
  return (
    <TabWrapper>
      {tabs.map((item) => (
        <Link
          key={item.idTab}
          to={item.path}
          className="border"
          style={{
            ...getSelectedTabStyles(currentTabId === item.idTab),
          }}
        >
          {item.label}
        </Link>
      ))}
    </TabWrapper>
  )
}

export default Tabs
