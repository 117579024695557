import { createActions, handleActions } from 'redux-actions'
import _ from 'lodash'

const EMPTY_BOOKING = Object.freeze({
  importFlag: 0,
  exportFlag: 0,
  vehicleNumber: '',
  driverName: '',
})

const EMPTY_DRIVER = Object.freeze({
  driverPhone: '',
  warehouse: {},
  tenant: {},
  multiTenantId: '',
  multiTenants: [],
  phoneChecked: false,
  policyData: {},
})

const initialState = {
  master: {
    vehicleCategories: [],
    packageTypes: [],
    tenantParameters: [],
  },
  driverConfirmations: [],
  recentBooking: {},
  booking: { ...EMPTY_BOOKING },
  bookings: [],
  driver: EMPTY_DRIVER,
  companies: [],
  // リロード対策
  returnEntryPage: true,
  isAcceptPolicyDriverName: false,
}

export const {
  updateDriver,
  updateBooking,
  replaceBooking,
  clearBooking,
  fetchImportExportParams,
  clearAll,
  fetchSuccess,
  updateReturnEntryPage,
  updateIsAcceptPolicy,
} = createActions({
  UPDATE_DRIVER: (data) => data,
  UPDATE_BOOKING: (data) => data,
  REPLACE_BOOKING: (data) => data,
  CLEAR_BOOKING: _.noop,
  FETCH_IMPORT_EXPORT_PARAMS: (data) => data,
  CLEAR_ALL: _.noop,
  FETCH_SUCCESS: (data) => data,
  UPDATE_RETURN_ENTRY_PAGE: _.noop,
  UPDATE_IS_ACCEPT_POLICY: (data) => data,
})

export default handleActions(
  {
    FETCH_SUCCESS: (state, { payload: { storePath, data } }) => {
      if (storePath.startsWith('base/')) {
        return state
      }

      return { ...state, [storePath]: data }
    },
    UPDATE_DRIVER: (state, { payload }) => {
      const driver = { ...state.driver, ...payload }
      return { ...state, driver }
    },
    UPDATE_BOOKING: (state, { payload }) => {
      const booking = { ...state.booking, ...payload }
      return {
        ...state,
        booking,
      }
    },
    REPLACE_BOOKING: (state, { payload }) => {
      const booking = payload
      return { ...state, booking }
    },
    CLEAR_BOOKING: (state) => ({ ...state, booking: EMPTY_BOOKING }),
    CLEAR_ALL: (state) => {
      const { driver } = state
      const newDriver = {
        ...EMPTY_DRIVER,
        warehouse: driver.warehouse,
        tenant: driver.tenant,
      }
      return { ...initialState, driver: newDriver, returnEntryPage: false }
    },
    UPDATE_RETURN_ENTRY_PAGE: (state) => ({ ...state, returnEntryPage: false }),
    UPDATE_IS_ACCEPT_POLICY: (state, { payload }) => ({
      ...state,
      isAcceptPolicyDriverName: payload,
    }),
  },
  initialState
)
