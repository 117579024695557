import RestClient, { getAuthHeader } from './RestClient'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})
export default class AddManagerService {
  constructor(restClient = RestClient) {
    this.restClient = restClient
  }

  getWarehouseManager(params) {
    return this.restClient.get('warehouse/user_tenants', params, {
      headers: headers(),
    })
  }

  getCompanyData(tenant_id) {
    return this.restClient.get(
      'warehouse/list_companies',
      { tenant_id },
      {
        headers: headers(),
      }
    )
  }

  getCompanyWithPagingData(data) {
    return this.restClient.get('warehouse/page_companies', data, {
      headers: headers(),
    })
  }

  addManager(data) {
    return this.restClient.post({
      url: 'warehouse/manage_role',
      data,
      config: {
        headers: headers(),
      },
    })
  }

  getUserInfoByMail(params) {
    return this.restClient.get('warehouse/find_user_info', params, {
      headers: headers(),
    })
  }

  getListDelegationGroup(params) {
    return this.restClient.get('warehouse/tenant/group_delegations', params, {
      headers: headers(),
    })
  }

  updateDelegationGroupForUser(data) {
    return this.restClient.put({
      url: 'warehouse/user_tenants/group_delegation',
      data: { ...data },
      config: {
        headers: headers(),
      },
    })
  }
}
