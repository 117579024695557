import RestClient, { getAuthHeader } from 'services/RestClient'

import { ResultGeneralReceptionType, DownloadCsvRequest } from './type'

const client = new RestClient()
const config = {
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader(),
  },
}

const requestGet = (url = '', data = {}) => client.get(url, data, config)

export const getGeneralReceptions = (
  role_type: string | null,
  enable: boolean | null
): Promise<ResultGeneralReceptionType> =>
  requestGet('/general-receptions/enable-general-booking', { role_type, enable })

export const downloadCsv = (data: DownloadCsvRequest) =>
  requestGet('/general-booking/csv/download', data)
