import React, { useState, useEffect, memo } from 'react'
import { decamelizeKeys } from 'humps'

import Button from 'components/Button'
import { Label } from 'components/SelectOptions/CreatableSelect'
import Icon from 'components/Icon'
import SingleForm from 'components/SingleForm'
import errorMessages from 'utils/errorMessages'
import { Wrapper } from '.'

type Props = {
  isOpen: boolean
  data: any
  width: number
  handleCancel: () => void
  handleConfirm: ({ newDriverName }: { newDriverName: string }) => void
}

const InputDriverNameModal: React.FC<Props> = ({
  isOpen,
  handleConfirm,
  handleCancel,
  data,
  width,
}) => {
  const [newDriverName, setNewDriverName] = useState('')

  useEffect(() => {
    setNewDriverName('')
  }, [isOpen])

  return (
    <Wrapper width={width} isOpen={isOpen}>
      <h3
        className="confirm--title"
        dangerouslySetInnerHTML={{
          __html: errorMessages.HomePage.driverNameNull(decamelizeKeys(data)),
        }}
      />
      <div className="flex-center">
        <Label style={{ fontSize: '14px' }} isHightLight className="label-flex">
          ドライバー名
          {/* @ts-ignore */}
          <Icon
            src="/assets/svgs/man-user.svg"
            style={{ position: 'relative', right: 0, left: 0, top: 0 }}
          />
        </Label>
        <SingleForm
          id="new_driver_name"
          value={newDriverName}
          maxLength={50}
          onChange={(value: string) => {
            setNewDriverName(value)
          }}
        />
      </div>
      <div className="d-flex justify-content-between button-group">
        <Button color="red" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button
          color="blue"
          onClick={() => handleConfirm({ newDriverName: newDriverName?.trim() })}
          disabled={!newDriverName?.trim()}
        >
          OK
        </Button>
      </div>
    </Wrapper>
  )
}

export default memo(InputDriverNameModal)
