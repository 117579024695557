import axios from 'axios'
import loginStorage from 'utils/loginStorage'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import Qs from 'qs'
import { statusCode, DEFAULT_CONFIG } from './constants'
import { store } from 'reduxStoreHelper'
import { openModalMaintain } from 'containers/App/reducers'

const BERTH_SIGN_IN_PATH = 'berths/auth/sign_in'
const BERTH_CHECK_TOKEN_PATH = 'berths/auth/password/edit'
const BERTH_SETTING_PASSWORD_PATH = 'berths/auth/password'
const BERTH_SESSION_PATHS = [
  BERTH_SIGN_IN_PATH,
  BERTH_CHECK_TOKEN_PATH,
  BERTH_SETTING_PASSWORD_PATH,
]

export function getAuthHeader() {
  const sessionData = loginStorage.getAuthData()
  return {
    uid: get(sessionData, 'uid', ''),
    client: get(sessionData, 'client', ''),
    'access-token': get(sessionData, 'accessToken', ''),
  }
}

export default class RestClient {
  constructor(config = {}) {
    this.config = Object.assign({}, DEFAULT_CONFIG, config)
  }

  get(url, params, config = {}) {
    return this.executeRequest(url, {
      method: 'get',
      ...config,
      params,
      paramsSerializer: (preParams) => Qs.stringify(preParams, { arrayFormat: 'brackets' }),
    })
  }

  patch({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'PATCH' })
  }

  put({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'PUT' })
  }

  post({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'POST' })
  }

  delete({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'DELETE' })
  }

  executeRequest(url, config) {
    const finalConfig = Object.assign({}, this.config, { url, ...config })
    return axios
      .request(finalConfig)
      .then((res) => {
        if (indexOf(BERTH_SESSION_PATHS, url) !== -1) return Promise.resolve(res)
        const accessToken = get(res, 'headers.access-token', '')
        const client = get(res, 'headers.client', '')
        const uid = get(res, 'headers.uid', '')
        const filename = get(res, 'headers.content-disposition')
        if (accessToken && client && uid) {
          loginStorage.setAuthData({ accessToken, uid, client })
        }
        if (filename) {
          return Promise.resolve({
            data: res.data,
            filename,
          })
        }
        return Promise.resolve(res.data)
      })
      .catch((errorResponse) => {
        const status = get(errorResponse, 'response.status')
        if (status === statusCode.SERVER_MAINTAIN_ERROR) {
          store.dispatch(openModalMaintain())
          return null
        }
        if (status === statusCode.UNAUTHORIZED || status === statusCode.FORBIDDEN) {
          window.location.replace('/login')
        }
        if (status === statusCode.INTERNAL_SERVER_ERROR && url.indexOf('user_info') === 0) {
          window.location.replace('/login')
        }
        const error = get(errorResponse, 'response.data')
        return Promise.reject({ status, ...error, errStatusCode: status })
      })
  }
}
