import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { Button } from 'reactstrap'
import { Prompt } from 'react-router-dom'

import {
  startLoading,
  stopLoading,
  openError,
  openToast,
  fetchCustomBerthLabel,
} from 'containers/App/reducers'
import { compareValue } from 'utils/commonFunctions'
import Tabs from '../components/Tabs'
import {
  changeWarehouse,
  changeTenant,
  updateParameters,
  fetchReceptionParameters,
} from '../reducers'
import Header from '../components/Header'
import { patchCardViewOrder } from '../api'
import { HEADER_HEIGHT, CardLabelItem } from '../constants'
import FinishToast from '../components/FinishToast'
import CanNotUse from '../components/CanNotUse'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import CanNotUpdate from '../components/CanNotUpdate'
import CardItemPreview from '../components/CardItemPreview'
import Icon from 'components/Icon'
import Select from 'components/SelectOptions'
import ErrorSubmitModal from '../components/ErrorSubmitModal'

const Th = styled.th`
  background-color: #f0f0f0;
  padding-top: 20px;
  vertical-align: bottom;
  z-index: 2;
`

const PreviewItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 450px;
  margin: 0 auto;
`

const DISPLAY_ORDER_OFFSET = 1000
const VEHICLE_NUMBER_COLUMN_NAME = 'vehicle_number'
const COMPANY_COLUMN_NAME = 'company_name'
const REGEX_IMPORT_ADDITIONAL_COLUMN = /^import_additional_column\d+$/g
const DEFAULT_OPTION_VALUE = 'DEFAULT_OPTION_VALUE'
const EMPTY_VALUE = 'EMPTY_VALUE'
const DEFAULT_ORDER = 0

const CARD_VIEW_ORDER_OPTIONS = [
  { label: 'なし', value: DEFAULT_OPTION_VALUE },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '', value: EMPTY_VALUE },
]

const canUpdateParameter = (columnName) =>
  !['company_name', 'vehicle_number', 'vehicle_category', 'import_package_type'].includes(
    columnName
  )

class CardItemsPage extends Component {
  state = {
    isOpenModalSumitError: false,
    messageSubmitError: '',
  }
  lastParameter = {}

  componentDidMount() {
    const { fetchReceptionParameters, fetchCustomBerthLabel } = this.props

    fetchReceptionParameters()
    fetchCustomBerthLabel()
  }

  onChangeWarehouse = (option) => {
    const { warehouses, fetchCustomBerthLabel } = this.props

    const warehouseId = option.value
    const selectedWarehouse = warehouses.find((warehouse) => warehouse.id === warehouseId)
    const tenantId = _.first(selectedWarehouse.tenants)?.id

    this.props.changeWarehouse(warehouseId)
    this.props.changeTenant(tenantId)
    this.props.fetchReceptionParameters()
    fetchCustomBerthLabel({ tenantId: tenantId })
  }

  getListCardViewOrder = (parameters) => {
    if (!parameters) return []
    return parameters?.filter((parameter) => parameter.cardViewOrder)
  }

  onChangeTenant = (option) => {
    const { fetchCustomBerthLabel, changeTenant, fetchReceptionParameters } = this.props
    const tenantId = option?.value
    changeTenant(tenantId)
    fetchReceptionParameters()
    fetchCustomBerthLabel({ tenantId })
  }

  onChange = (item, order) => {
    const { parameters } = this.props

    const cloneParameters = _.cloneDeep(parameters) || []
    const selectedItemIndex = cloneParameters.findIndex((parameter) =>
      compareValue(parameter.id, item.id)
    )
    const cardViewOrder = compareValue(order, DEFAULT_OPTION_VALUE) ? DEFAULT_ORDER : order
    if (selectedItemIndex >= 0 && Array.isArray(cloneParameters)) {
      const preSelectedIndex = cloneParameters.findIndex((parameter) =>
        compareValue(parameter.cardViewOrder, cardViewOrder)
      )
      if (preSelectedIndex >= 0) {
        cloneParameters[preSelectedIndex] = {
          ...cloneParameters[preSelectedIndex],
          cardViewOrder: DEFAULT_ORDER,
        }
      }

      cloneParameters[selectedItemIndex] = {
        ...item,
        cardViewOrder,
      }
      this.props.updateParameters(cloneParameters)
    }
  }

  getDataChanged = () => {
    const { parameters, exportParameters, initialParameters } = this.props

    const mTenantParameter = []

    _.forEach(parameters || [], (parameter) => {
      if (parameter.cardViewOrder <= 0) return
      //Update order of vehicle number when order of company name change
      if (compareValue(parameter.columnName, COMPANY_COLUMN_NAME)) {
        const vehicleNumberParameter = _.find(initialParameters, (initialParameter) =>
          compareValue(initialParameter.columnName, VEHICLE_NUMBER_COLUMN_NAME)
        )
        if (vehicleNumberParameter) {
          mTenantParameter.push({
            mTenantParameterIds: [parameter.id, vehicleNumberParameter.id],
            cardViewOrder: parameter.cardViewOrder,
          })
        }
        return
      }

      //Update order of export when import change
      if (_.includes(parameter.columnName || '', 'import')) {
        const selectedExportParameter = exportParameters.find(
          (exportParameter) =>
            compareValue(exportParameter.displayName, parameter.displayName) &&
            compareValue(exportParameter.displayOrder, parameter.displayOrder)
        )
        if (selectedExportParameter) {
          mTenantParameter.push({
            mTenantParameterIds: [parameter.id, selectedExportParameter.id],
            cardViewOrder: parameter.cardViewOrder,
          })
        }
        return
      }

      mTenantParameter.push({
        mTenantParameterIds: [parameter.id],
        cardViewOrder: parameter.cardViewOrder,
      })
    })
    return mTenantParameter
  }

  onClickRegister = async () => {
    const { tenantId } = this.props

    const data = {
      tenantId,
      mTenantParameter: this.getDataChanged(),
    }

    this.props.startLoading()

    try {
      await patchCardViewOrder(data)
    } catch (error) {
      this.setState({
        isOpenModalSumitError: true,
        messageSubmitError: error?.message,
      })
      return
    } finally {
      this.props.stopLoading()
    }

    this.props.fetchReceptionParameters()
    this.props.openToast(FinishToast)
  }

  getDataCardPreview = () => {
    const { parameters } = this.props
    return _.map(this.getListCardViewOrder(parameters), (parameter) => {
      if (REGEX_IMPORT_ADDITIONAL_COLUMN.test(parameter.columnName)) {
        return {
          label: parameter.displayName,
          order: parameter.cardViewOrder,
        }
      }

      return {
        label: compareValue(parameter.columnName, COMPANY_COLUMN_NAME)
          ? CardLabelItem.companyName
          : parameter.displayName,
        order: parameter.cardViewOrder,
      }
    })
  }

  checkIsChangedData = () => {
    const { parameters, initialParameters } = this.props
    const initial = _.sortBy(
      _.filter(
        initialParameters,
        (parameter) => !compareValue(parameter.columnName, VEHICLE_NUMBER_COLUMN_NAME)
      ),
      (parameter) => parameter.id
    )
    const current = _.sortBy(parameters, (parameter) => parameter.id)
    return _.isEqual(initial, current)
  }

  handleCloseModalSubmitError = () => {
    this.setState({
      isOpenModalSumitError: false,
      messageSubmitError: '',
    })
  }

  renderEmpty(
    warehouseOptions,
    tenantOptions,
    warehouseId,
    tenantId,
    onChangeWarehouse,
    onChangeTenant
  ) {
    return (
      <div>
        <Header
          warehouses={warehouseOptions}
          tenants={tenantOptions}
          selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
          selectedTenant={tenantOptions.find((tenant) => tenant.value === tenantId)}
          onChangeWarehouse={onChangeWarehouse}
          onChangeTenant={onChangeTenant}
        />
        <CanNotUse />
      </div>
    )
  }

  render() {
    const { isOpenModalSumitError, messageSubmitError } = this.state
    const { warehouseId, tenantId, parameters, warehouses, tenants, userData } = this.props
    const warehouseOptions = warehouses.map((warehouse) => ({
      value: warehouse.id,
      label: warehouse.name,
    }))
    const tenantOptions = tenants.map((tenant) => ({
      value: tenant.id,
      label: tenant.name,
    }))
    const isChangedParameter = this.checkIsChangedData()
    const selectedTenant = tenants.find((t) => t.id === tenantId) || {}

    if (!selectedTenant.canCustomizeReception) {
      return this.renderEmpty(
        warehouseOptions,
        tenantOptions,
        warehouseId,
        tenantId,
        this.onChangeWarehouse,
        this.onChangeTenant
      )
    }

    const isSystemAdmin = userData.attributes.is_warehouse_admin
    const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)
    return (
      <div>
        <Header
          warehouses={warehouseOptions}
          tenants={tenantOptions}
          selectedWarehouse={warehouseOptions.find((warehouse) => warehouse.value === warehouseId)}
          selectedTenant={tenantOptions.find((tenant) => tenant.value === tenantId)}
          onChangeWarehouse={this.onChangeWarehouse}
          onChangeTenant={this.onChangeTenant}
        />

        <div
          className="p-3"
          style={{ height: window.innerHeight - HEADER_HEIGHT, overflowY: 'auto' }}
        >
          <div className="mx-auto" style={{ width: 852 }}>
            <div className="font-weight-bold mb-3">受付カスタマイズ</div>

            {!canUpdate && <CanNotUpdate />}

            <div className="border px-3 pb-3 mx-auto mb-3 bg-white">
              <Tabs currentTabId="card-items" />

              <div className="text-center">
                <div className="mb-3">バース管理画面のカードに表示する項目を選択してください。</div>
                <PreviewItemWrapper>
                  <CardItemPreview />
                  <Icon src="/assets/svgs/arrow-right-black.svg" width={20} height={20} />
                  <CardItemPreview data={this.getDataCardPreview()} />
                </PreviewItemWrapper>
              </div>

              <div className="m-3 mx-auto" style={{ width: 620 }}>
                <div className="mb-3">
                  <table className="mb-3 mx-auto" style={{ width: 600 }}>
                    <colgroup>
                      <col />
                      <col style={{ width: 210 }} />
                    </colgroup>
                    <thead className="border-bottom">
                      <tr>
                        <Th className="pl-3 py-3">項目名</Th>
                        <Th className="text-center py-3">表示順</Th>
                      </tr>
                    </thead>
                    <tbody className="border-0">
                      {parameters.map((parameter) => (
                        <tr key={parameter.id} className="border-bottom" style={{ height: 48 }}>
                          <td className="pl-3">
                            {parameter.displayName ? (
                              parameter.columnName === COMPANY_COLUMN_NAME ? (
                                CardLabelItem.companyName
                              ) : (
                                parameter.displayName
                              )
                            ) : (
                              <span style={{ color: '#CCC' }}>未設定</span>
                            )}
                          </td>
                          <td className="text-center d-flex justify-content-center">
                            {parameter.displayFlag && (
                              <Select
                                name="card-view"
                                selectValue={parameter.cardViewOrder || EMPTY_VALUE}
                                options={CARD_VIEW_ORDER_OPTIONS}
                                width={'85px'}
                                isDisabled={!canUpdate}
                                handleUpdateData={(value) => {
                                  this.onChange(parameter, value)
                                }}
                                isOptionDisabled={(option) =>
                                  compareValue(EMPTY_VALUE, option.value)
                                }
                              />
                            )}

                            {!parameter.displayFlag && (
                              <span style={{ color: '#CCC' }}>受付非表示</span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {canUpdate && (
              <div className="text-right">
                <Button
                  color="primary"
                  className="rounded-pill px-4"
                  disabled={isChangedParameter}
                  onClick={this.onClickRegister}
                >
                  登録
                </Button>
              </div>
            )}
          </div>
        </div>

        <Prompt
          when={!isChangedParameter}
          message={
            '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
          }
        />
        <ErrorSubmitModal
          message={messageSubmitError}
          isOpen={isOpenModalSumitError}
          onClickClose={this.handleCloseModalSubmitError}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const receptionCustom = state.get('receptionCustom')
  return {
    warehouseId: receptionCustom.warehouseId,
    tenantId: receptionCustom.tenantId,
    parameters: _.sortBy(
      _.filter(
        receptionCustom.parameters,
        (parameter) => !compareValue(parameter.columnName, VEHICLE_NUMBER_COLUMN_NAME)
      ),
      (parameter) =>
        canUpdateParameter(parameter.columnName)
          ? DISPLAY_ORDER_OFFSET + parameter.displayOrder
          : parameter.displayOrder
    ),
    exportParameters: receptionCustom.exportParameters,
    warehouses: receptionCustom.warehouses,
    initialParameters: receptionCustom.initialParameters,
    tenants: receptionCustom.tenants,
    userData: state.get('containers/App').get('userData').toJSON(),
  }
}

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openError,
  openToast,
  changeWarehouse,
  changeTenant,
  updateParameters,
  fetchReceptionParameters,
  fetchCustomBerthLabel,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardItemsPage)
