import React, { PureComponent } from 'react'

import { ButtonWrapper } from './style'

export default class Button extends PureComponent {
  state = { isDisable: false }

  handleClick = (e) => {
    this.setState(
      {
        isDisable: true,
      },
      async () => {
        try {
          await this.props.onClick(e)
          setTimeout(() => {
            this.setState({
              isDisable: false,
            })
          }, 500)
        } catch (err) {
          this.setState({
            isDisable: false,
          })
        }
      }
    )
  }

  render() {
    const {
      className,
      context,
      color,
      type,
      onClick,
      style,
      children,
      rounded,
      size,
      disabled,
      needWait,
      ...otherProps
    } = this.props
    const { isDisable } = this.state
    return (
      <ButtonWrapper
        className={className}
        context={context}
        color={color}
        type={type}
        style={style}
        rounded={rounded}
        onClick={needWait ? this.handleClick : onClick}
        disabled={disabled || isDisable}
        size={size}
        {...otherProps}
      >
        {children}
      </ButtonWrapper>
    )
  }
}

Button.defaultProps = {
  context: 'primary',
  color: 'red',
  type: 'button',
  onClick: null,
  style: {},
}
