import styled from 'styled-components'

import theme from 'vars/theme'

export const FormBlockWrapper = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${theme.grayPrimary};

  .mono-creatable__control {
    width: 200px;
  }
`

export const UnderLine = styled.hr`
  margin: 4px 0 12px;
`

export const DeleteFileButton = styled.span`
  color: #ff0000;
  font-size: 12px;
  margin-left: 144px;
  margin-top: 8px;
  cursor: pointer;
`

export const StatusLabel = styled.span`
  width: 120px;
  opacity: 0.5;
`

export const StatusValue = styled.span`
  width: calc(100% - 120px);
  overflow-wrap: break-word;
`

export const FileUpload = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    height: 0;
    position: absolute;
    left: -100000px;
  }

  .text_file-name {
    border-radius: 2px;
    font-size: 12px;
    width: 100%;
    max-width: 128px;
    height: calc(2rem + 2px);
    padding: 4px 6px;
    margin: 0;
    margin-left: 0.25rem;
    border: 1px solid ${theme.graySoft2};
    overflow: hidden;
  }

  .error {
    border-color: ${theme.redMedium1};
  }

  .error-message {
    color: ${theme.redMedium1};
    font-size: 12px;
    text-align: center;
  }

  .label_upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: calc(2rem + 2px);
    font-size: 12px;
    background: #eef7fc;
    color: #278cb5;
    border-radius: 2px;
    cursor: pointer;
    margin: 0 0 0 0.35rem;

    &.disabled {
      background: ${theme.graySoft2};
    }
  }
`

export const ErrorText = styled.p`
  width: 100%;
  font-size: 12px;
  color: ${theme.redMedium1};
  text-align: center;
`

export const RegisterId = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;

  label {
    width: 140px;
  }

  .register-id__value {
    background: #e9ecef;
    border: 0;
  }

  .form-control {
    height: auto;
    min-height: calc(2rem + 2px);
  }
`
