import React from 'react'
import { camelize } from 'humps'

import Button from 'components/Button'

import warningIcon from 'assets/svgs/warning-icon.svg'
import arrowDownBlue from 'assets/svgs/arrow-down-blue.svg'
import arrowDownGreen from 'assets/svgs/arrow-down-green.svg'
import arrowDownOrange from 'assets/svgs/arrow-down-orange.svg'
import arrowDownWhite from 'assets/svgs/arrow-down-white.svg'
import arrowDown from 'assets/svgs/arrow-down.svg'
import arrowLeftBlue from 'assets/svgs/arrow-left-blue.svg'
import arrowLeftWhite from 'assets/svgs/arrow-left-white.svg'
import arrowLeft from 'assets/svgs/arrow-left.svg'
import arrowRightBlue from 'assets/svgs/arrow-right-blue.svg'
import arrowRightWhite from 'assets/svgs/arrow-right-white.svg'
import arrowRight from 'assets/svgs/arrow-right.svg'
import arrowUp from 'assets/svgs/arrow-up.svg'
import arrowRightBlack from 'assets/svgs/arrow-right-black.svg'
import strikeThrough from 'assets/svgs/strike-through.svg'
import berth from 'assets/svgs/berth.svg'
import bookingMainGood from 'assets/svgs/booking-main-good.svg'
import bookingNoInput from 'assets/svgs/booking-no-input.svg'
import borderHeader from 'assets/svgs/border-header.svg'
import calendarBlue from 'assets/svgs/calendar-blue.svg'
import calendar from 'assets/svgs/calendar.svg'
import cameraAdded from 'assets/svgs/camera-added.svg'
import cameraNormal from 'assets/svgs/camera-normal.svg'
import chatPerson from 'assets/svgs/chat-person.svg'
import checkActive from 'assets/svgs/check-active.svg'
import checkIcon from 'assets/svgs/check-icon.svg'
import checkInactive from 'assets/svgs/check-inactive.svg'
import chevronRight from 'assets/svgs/chevron-right.svg'
import clockIcon from 'assets/svgs/clock_icon.svg'
import clock from 'assets/svgs/clock.svg'
import closeIconGray from 'assets/svgs/close-icon-gray.svg'
import closeIconWhite from 'assets/svgs/close-icon-white.svg'
import closeIcon from 'assets/svgs/close-icon.svg'
import closeModal from 'assets/svgs/close-modal.svg'
import close from 'assets/svgs/close.svg'
import confirmBerth from 'assets/svgs/confirm-berth.svg'
import confirmCompany from 'assets/svgs/confirm-company.svg'
import controllerBlue from 'assets/svgs/controller-blue.svg'
import controllerGray from 'assets/svgs/controller-gray.svg'
import csvDown from 'assets/svgs/csv-down.svg'
import csvUp from 'assets/svgs/csv-up.svg'
import database from 'assets/svgs/database.svg'
import doubleArrowLeft from 'assets/svgs/double-arrow-left.svg'
import downloadCsv from 'assets/svgs/download-csv.svg'
import entry from 'assets/svgs/entry.svg'
import exit from 'assets/svgs/exit.svg'
import exportIcon from 'assets/svgs/export.svg'
import factoryIcon from 'assets/svgs/factory_icon.svg'
import hamburgerMenuGray from 'assets/svgs/hamburger-menu-gray.svg'
import hamburgerMenu from 'assets/svgs/hamburger-menu.svg'
import iconAlert from 'assets/svgs/icon-alert.svg'
import iconSelectBlue from 'assets/svgs/icon-select-blue.svg'
import iconSelectGreen from 'assets/svgs/icon-select-green.svg'
import iconSelectOrange from 'assets/svgs/icon-select-orange.svg'
import iconSelectWhite from 'assets/svgs/icon-select-white.svg'
import imageUploadTextGray from 'assets/svgs/image-upload-text-gray.svg'
import imageUploadText from 'assets/svgs/image-upload-text.svg'
import importIcon from 'assets/svgs/import.svg'
import jpYen from 'assets/svgs/jp-yen.svg'
import list from 'assets/svgs/list.svg'
import manUser from 'assets/svgs/man-user.svg'
import monofulBrandWhite from 'assets/svgs/monoful-brand-white.svg'
import monofulBrand from 'assets/svgs/monoful-brand.svg'
import next from 'assets/svgs/next.svg'
import notiRed from 'assets/svgs/noti--red.svg'
import notiWhite from 'assets/svgs/noti--white.svg'
import noti from 'assets/svgs/noti.svg'
import pallet from 'assets/svgs/pallet.svg'
import phoneBookingRefresh from 'assets/svgs/phone-booking-refresh.svg'
import phoneNumberInput from 'assets/svgs/phone-number-input.svg'
import plusIcon from 'assets/svgs/plus-icon.svg'
import popupWarning from 'assets/svgs/popup-warning.svg'
import prev from 'assets/svgs/prev.svg'
import removeIcon from 'assets/svgs/remove-icon.svg'
import repeat from 'assets/svgs/repeat.svg'
import ring from 'assets/svgs/ring.svg'
import searchWhite from 'assets/svgs/search-white.svg'
import search from 'assets/svgs/search.svg'
import selectWhite from 'assets/svgs/select-white.svg'
import setting from 'assets/svgs/setting.svg'
import singleArrowLeft from 'assets/svgs/single-arrow-left.svg'
import spinWebconfirm from 'assets/svgs/spin-webconfirm.svg'
import statistic from 'assets/svgs/statistic.svg'
import telWebconfirm from 'assets/svgs/tel-webconfirm.svg'
import trash from 'assets/svgs/trash.svg'
import truckBlue from 'assets/svgs/truck-blue.svg'
import truckGray from 'assets/svgs/truck-gray.svg'
import truck from 'assets/svgs/truck.svg'
import unionRight from 'assets/svgs/Union_right.svg'
import unionGroup from 'assets/svgs/union-group.svg'
import union from 'assets/svgs/union.svg'
import uploadCsv from 'assets/svgs/upload-csv.svg'
import warehouse from 'assets/svgs/warehouse.svg'
import workRecord from 'assets/svgs/work-record.svg'
import circleMinus from 'assets/svgs/circle-minus.svg'
import circleUpArrow from 'assets/svgs/circle-up-arrow.svg'
import circleDownArrow from 'assets/svgs/circle-down-arrow.svg'
import csvDownload from 'assets/svgs/csv-download.svg'
import csvUpload from 'assets/svgs/csv-upload.svg'
import generalBooking from 'assets/svgs/general-booking.svg'
import receptionCustom from 'assets/svgs/reception-custom.svg'
import information from 'assets/svgs/information.svg'
import portalAppIcon from 'assets/svgs/portal-app-icon.svg'
import beginnerMark from 'assets/svgs/beginner-mark.svg'
import registeredMark from 'assets/svgs/registered-mark.svg'

// booking icon
import deliveryTruck from 'assets/svgs/delivery-truck.svg'
import blankBookingIcon from 'assets/svgs/blank_booking_icon.svg'
// multi-booking
import multiBookingHuman from 'assets/svgs/multi-booking/human.svg'
import multiBookingCompanyName from 'assets/svgs/multi-booking/company-name.svg'
import multiBookingTruck from 'assets/svgs/multi-booking/truck.svg'

// png icon
import lineApp from 'assets/pngs/line-app.png'

const svgs = {
  warningIcon,
  arrowDownBlue,
  arrowDownGreen,
  arrowDownOrange,
  arrowDownWhite,
  arrowDown,
  arrowLeftBlue,
  arrowLeftWhite,
  arrowLeft,
  arrowRightBlue,
  arrowRightWhite,
  arrowRight,
  arrowUp,
  arrowRightBlack,
  strikeThrough,
  berth,
  bookingMainGood,
  bookingNoInput,
  borderHeader,
  calendarBlue,
  calendar,
  cameraAdded,
  cameraNormal,
  chatPerson,
  checkActive,
  checkIcon,
  checkInactive,
  chevronRight,
  clockIcon,
  clock,
  closeIconGray,
  closeIconWhite,
  closeIcon,
  closeModal,
  close,
  confirmBerth,
  confirmCompany,
  controllerBlue,
  controllerGray,
  csvDown,
  csvUp,
  database,
  doubleArrowLeft,
  downloadCsv,
  entry,
  exit,
  exportIcon,
  factoryIcon,
  hamburgerMenuGray,
  hamburgerMenu,
  iconAlert,
  iconSelectBlue,
  iconSelectGreen,
  iconSelectOrange,
  iconSelectWhite,
  imageUploadTextGray,
  imageUploadText,
  importIcon,
  jpYen,
  list,
  manUser,
  monofulBrandWhite,
  monofulBrand,
  next,
  notiRed,
  notiWhite,
  noti,
  pallet,
  phoneBookingRefresh,
  phoneNumberInput,
  plusIcon,
  popupWarning,
  prev,
  removeIcon,
  repeat,
  ring,
  searchWhite,
  search,
  selectWhite,
  setting,
  singleArrowLeft,
  spinWebconfirm,
  statistic,
  telWebconfirm,
  trash,
  truckBlue,
  truckGray,
  truck,
  unionRight,
  unionGroup,
  union,
  uploadCsv,
  warehouse,
  workRecord,
  circleMinus,
  circleUpArrow,
  circleDownArrow,
  csvDownload,
  csvUpload,
  generalBooking,
  receptionCustom,
  information,
  portalAppIcon,
  beginnerMark,
  registeredMark,
  deliveryTruck,

  // booking icon
  blankBookingIcon,

  // multi-booking
  multiBookingHuman,
  multiBookingCompanyName,
  multiBookingTruck,

  // png
  lineApp,
}

const getSvg = (src) => {
  const name = src
    .replace('/assets/svgs/', '')
    .replace('/assets/pngs/', '')
    .replace(/\//g, '-')
    .replace('.svg', '')
    .replace('.png', '')
  let camelizedName = camelize(name)
  if (['imporg', 'export'].includes(camelizedName)) {
    camelizedName = `${camelizedName}Icon`
  }
  const path = svgs[camelizedName]
  console.assert(path, src)
  return path
}

const Icon = ({
  className = '',
  alt = '',
  src,
  type = '',
  width,
  height,
  onClick = null,
  onMouseDown = null,
  style = {},
}) => {
  if (type === 'button') {
    return (
      <Button
        className={`btn-none p-0 m-0 d-inline-flex justify-content-center align-items-center ${className}`}
        onMouseDown={onMouseDown}
        onClick={onMouseDown ? null : onClick}
      >
        <img alt={alt} src={getSvg(src)} style={{ width, height, ...style }} />
      </Button>
    )
  }

  return (
    <img alt={alt} src={getSvg(src)} className={className} style={{ width, height, ...style }} />
  )
}

export default Icon
