import React from 'react'
import { Input } from 'reactstrap'

import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  margin-left: 0.25rem;
  width: 100%;

  .form-control {
    &:last-child {
      margin-right: 2px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`

export class InputArray extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      values: this.props.value,
    }
  }

  onInputChange = (e, index) => {
    const { onChange } = this.props
    const { values } = this.state
    values[index] = e.target.value
    this.setState({
      values,
    })

    onChange(this.state.values.join('-'))
  }

  render() {
    const { length, name, type } = this.props
    return (
      <Wrapper>
        {[...Array(length)].map((item, index) => (
          <Input
            key={index}
            onChange={(e) => this.onInputChange(e, index)}
            placeholder="123"
            value={this.state.values[index] || ''}
            type={type}
            name={`${name}[${index}]`}
          />
        ))}
      </Wrapper>
    )
  }
}

InputArray.defaultProps = {
  length: 3,
}

export default InputArray
