import React from 'react'
import styled, { css } from 'styled-components'

import { GeneralBookingStatuses } from '../constants'

const filters = (canGeneralBooking) =>
  canGeneralBooking
    ? [
        GeneralBookingStatuses.ALL,
        GeneralBookingStatuses.BOOKING,
        GeneralBookingStatuses.CONFIRM,
        GeneralBookingStatuses.CHECKIN,
        GeneralBookingStatuses.ASSIGN,
        GeneralBookingStatuses.FINISH,
      ]
    : [
        GeneralBookingStatuses.ALL,
        GeneralBookingStatuses.CHECKIN,
        GeneralBookingStatuses.ASSIGN,
        GeneralBookingStatuses.FINISH,
      ]

const BorderRadius = Object.freeze({
  [GeneralBookingStatuses.ALL.id]: '30px 0 0 30px',
  [GeneralBookingStatuses.FINISH.id]: '0 30px 30px 0',
})

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 34px;
  border: none;
  border-radius: ${({ filterId }) => BorderRadius[filterId] || 'none'};
  font-size: 14px;
  cursor: pointer;

  ${({ filterId, filterIdSelected }) =>
    filterId === filterIdSelected
      ? css`
          background: #daf5ff;
          border-radius: 30px;
          border: 1px solid #247cdb;
          color: #247cdb;
          margin: -1px;
          z-index: 1;
        `
      : css`
          background-color: #ffffff;
          color: rgba(0, 0, 0, 0.6);
        `};

  &:focus {
    outline: 0;
  }

  @media (max-width: 1024px) {
    padding: 2px 15px;
    white-space: nowrap;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: calc(100% - 220px);

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: start;
    margin-top: 20px;
  }
`

const TabList = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 30px;
  background-color: white;
  display: flex;
`

export default ({ canGeneralBooking, filterId, countPerFilters, onClick }) => (
  <Wrapper>
    <TabList>
      {filters(canGeneralBooking).map((filter) => (
        <Button
          key={filter.id}
          onClick={() => {
            onClick(filter.id)
          }}
          filterId={filter.id}
          filterIdSelected={filterId}
          canGeneralBooking={canGeneralBooking}
        >
          <div>
            {filter.label} {countPerFilters[filter.id]}
          </div>
        </Button>
      ))}
    </TabList>
  </Wrapper>
)
