import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, RouteProps, useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import * as S from './styles'
import Header from './Header'
import HeaderCommon from 'components/Layout/Header'
import { generalReceptionSelectedSelectors } from '../selectors'
import { GeneralReceptionType } from '../type'
import CanNotUse from './CanNotUse'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { roleOfGeneralReceptionSelectors } from '../selectors'
import { fetchRoleGeneralReception, fetchGeneralReceptions } from '../reducer'

type Props = RouteProps & {
  isEditPage?: boolean
  children: JSX.Element
  generalReception: GeneralReceptionType
  showFlashMessage: ({ message, isError }: { message: string; isError: boolean }) => void
  roleGeneral: {
    isAdminCompany: boolean
    isAdminGeneralReception: boolean
    isController: boolean
  }
  fetchRoleGeneralReception: () => void
  fetchGeneralReceptions: () => void
}

const DefaultLayout: React.FC<Props> = ({
  children,
  isEditPage,
  generalReception,
  roleGeneral,
  fetchRoleGeneralReception,
  showFlashMessage,
  fetchGeneralReceptions,
  ...rest
}: Props) => {
  const history = useHistory()

  const canCustomizeReception = _.get(generalReception, 'features.canCustomizeReception', false)

  useEffect(() => {
    if (generalReception?.id) {
      fetchRoleGeneralReception()
      fetchGeneralReceptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalReception?.id])

  useEffect(() => {
    if (!_.isEmpty(roleGeneral)) {
      const { isAdminCompany, isAdminGeneralReception, isController } = roleGeneral

      if (isAdminCompany || isAdminGeneralReception) {
        return
      }

      if (isController) {
        history.push('/multi-booking')
        showFlashMessage({
          message: 'アクセス権限がありません。',
          isError: true,
        })

        return
      }

      if (!isAdminCompany && !isAdminGeneralReception && !isController) {
        showFlashMessage({
          message: 'アクセス権限がありません。',
          isError: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleGeneral])

  return (
    <Route
      {...rest}
      render={() => {
        if (isEditPage) {
          return (
            <>
              <HeaderCommon>
                <div></div>
              </HeaderCommon>
              <S.CustomSettingWrapper>{children}</S.CustomSettingWrapper>
            </>
          )
        }
        return (
          <>
            <Header />
            {canCustomizeReception ? (
              <S.CustomSettingWrapper>
                <S.CustomSettingContainer>
                  <div className="font-weight-bold mb-3">受付カスタマイズ(総合管理)</div>
                  {children}
                </S.CustomSettingContainer>
              </S.CustomSettingWrapper>
            ) : (
              <CanNotUse />
            )}
          </>
        )
      }}
    ></Route>
  )
}

const mapStateToProps = () =>
  createStructuredSelector({
    generalReception: generalReceptionSelectedSelectors(),
    roleGeneral: roleOfGeneralReceptionSelectors(),
  })

const mapDispatchToProps = {
  showFlashMessage,
  fetchRoleGeneralReception,
  fetchGeneralReceptions,
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(DefaultLayout))
