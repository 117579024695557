import React, { PureComponent } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Alert } from 'reactstrap'

import { flashMessageSelectors } from '../selectors'
import { hideFlashMessage } from '../actions'
import theme from 'vars/theme'

export const Wrapper = styled.div`
  position: fixed;
  max-width: 480px;
  left: 20px;
  bottom: 20px;
  z-index: 100;

  .alert {
    word-wrap: break-word;
    line-height: 16px;
    padding: 0.75rem 1.25rem;
    padding-right: 2rem;
    border-radius: 3px;
    margin-bottom: 0;
    font-size: 14px;
    color: white;
    background-color: ${(props) => (props.isError ? theme.redPrimary : theme.blueMedium1)};
    border-color: ${(props) => (props.isError ? theme.redPrimary : theme.blueMedium1)};

    .close {
      font-size: 14px;
      padding: 0.5rem 1.25rem;
      box-shadow: none;
      outline: none;
    }
  }
`

class FlashMessage extends PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.flashMessage.message && !prevProps.flashMessage.message) {
      clearTimeout(this.timing)
      this.timing = setTimeout(() => {
        this.props.hideMessage()
      }, 3000)
    }
  }

  render() {
    const { message, isError } = this.props.flashMessage

    return message ? (
      <Wrapper isError={isError}>
        <Alert isOpen={!!message} toggle={this.props.hideMessage}>
          {message}
        </Alert>
      </Wrapper>
    ) : null
  }
}

const mapStateToProps = createStructuredSelector({
  flashMessage: flashMessageSelectors(),
})

const matchDispatchToProp = (dispatch) => ({
  hideMessage: () => dispatch(hideFlashMessage()),
})

FlashMessage.defaultProps = {
  flashMessage: {},
}

const withConnect = connect(mapStateToProps, matchDispatchToProp)

export default withConnect(FlashMessage)
