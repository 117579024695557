import React from 'react'

import { Route, Switch } from 'react-router-dom'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import reducer from './reducers'
import saga from './sagas'
import { context } from './constants'

import PolicyModal from 'components/PolicyModal'
import AuthRoute from 'containers/Routes/AuthRoute'
import HomePage from 'containers/HomePage'
import ActionCable from 'actioncable'
import { ActionCableProvider } from 'react-actioncable-provider'
import { SOCKET_URL } from 'constants/misc'

import WarehousePage from 'containers/WarehousePage/Loadable'
import IntegratedManagementPage from 'containers/IntegratedManagementPage'
import IntegratedSettingRouting from 'containers/IntegratedSetting/IntegratedSettingRouting'
import IntegratedDownloadCsvPage from 'containers/IntegratedDownloadCsvPage'
import BillInfo from 'containers/BillInfo/Loadable'
import NotificationContainers from './Notification'
import FlashMessage from './FlashMessage'
import NotFoundPage from 'containers/NotFoundPage/Loadable'
import NoticePage from 'containers/NoticePage/NoticePage'
import IntegratedListCardsPage from 'containers/IntegratedListCardsPage'

import ReceptionCustomRouting from 'containers/ReceptionCustom/ReceptionCustomRouting'
import { getUserRoles, checkUserPolicy, updateUserPolicy } from './actions'
import { policyDataSelector } from './selectors'
import loginStorage from 'utils/loginStorage'
import { openModal, closeModal, stopLoading } from 'containers/App/reducers'
import { VERSION, VERSION_STORAGE_KEY } from 'constants/App'
import SettingsRouting from 'pages/Settings/SettingsRouting'
import FilePreviewPage from 'pages/FilePreview/Loader'
import ImagePreviewPage from 'pages/ImagePreview/Loader'
import GeneralReceptionImagePreviewPage from 'pages/GeneralReceptionImagePreview/Loader'

import { DownloadTypes } from 'constants/misc'
import IntegratedReceptionCustomRouter from 'containers/IntegratedReceptionCustom/IntegratedReceptionCustomRouting'

const cable = ActionCable.createConsumer(SOCKET_URL)

class AppPrivate extends React.Component {
  updateModalIsClosed = false

  componentDidMount() {
    this.props.checkUserPolicy()

    const oldVersion = localStorage.getItem(VERSION_STORAGE_KEY)

    if (VERSION === oldVersion) return

    this.props.openModal({
      content: <div>トラック簿が最新版に更新されました</div>,
      buttons: [
        {
          label: 'OK',
          color: 'primary',
          onClick: () => {
            this.updateModalIsClosed = true
          },
        },
      ],
    })

    const { warehouseId, tenantId } = loginStorage.getWarehouseSession()
    if (warehouseId && tenantId) {
      this.props.getUserRoles({ warehouse_id: warehouseId, tenant_id: tenantId })
    }

    setTimeout(() => {
      if (this.updateModalIsClosed) return
      this.props.closeModal()
    }, 30 * 1000)
  }

  componentDidUpdate(prevProps) {
    const currPathname = this.props.location.pathname
    const prevPathname = prevProps.location.pathname
    const { policyData, checkUserPolicy, stopLoading } = this.props

    // call api check policy when switch route
    if (currPathname !== prevPathname) {
      checkUserPolicy()
    }

    // stop app loading if policy modal is open
    if (!_.isEmpty(policyData)) {
      stopLoading()
    }
  }

  renderNotification = () => {
    const { userData } = this.props
    if (!userData.id) return null
    return <NotificationContainers userData={userData} />
  }

  render() {
    const { policyData, updateUserPolicy } = this.props
    const needAcceptPolicy = !_.isEmpty(this.props.policyData)

    if (needAcceptPolicy) {
      return <PolicyModal isOpen policyData={policyData} onAccept={updateUserPolicy} />
    }

    return (
      <ActionCableProvider cable={cable}>
        <Switch>
          <AuthRoute {...this.props} path="/warehouse" component={WarehousePage} />
          <AuthRoute {...this.props} path="/multi-setting" component={IntegratedSettingRouting} />
          <AuthRoute {...this.props} path="/multi-booking" component={IntegratedManagementPage} />
          <AuthRoute
            {...this.props}
            path="/reception-custom-general"
            component={IntegratedReceptionCustomRouter}
          />
          <AuthRoute {...this.props} path="/billInfo" component={BillInfo} />
          <AuthRoute {...this.props} path="/notice" component={NoticePage} />
          <AuthRoute {...this.props} path="/reception-custom" component={ReceptionCustomRouting} />
          <AuthRoute {...this.props} path="/settings" component={SettingsRouting} />
          <AuthRoute
            {...this.props}
            exact
            path={`/general-csv-download/:type(${DownloadTypes.PLAN.label}|${DownloadTypes.ACTUAL.label})`}
            component={IntegratedDownloadCsvPage}
          />
          <AuthRoute
            {...this.props}
            path="/file-preview/:attachmentId/:fileExtension"
            component={FilePreviewPage}
          />
          <AuthRoute
            {...this.props}
            path="/confirmation/file-preview/:id"
            component={ImagePreviewPage}
          />
          <AuthRoute
            {...this.props}
            path="/general-confirmation/file-preview/:id"
            component={GeneralReceptionImagePreviewPage}
          />
          <AuthRoute {...this.props} path="/list-card" component={IntegratedListCardsPage} />
          <Route path="/not_found" component={NotFoundPage} />
          <AuthRoute {...this.props} path="/" component={HomePage} />
        </Switch>
        {this.renderNotification()}
        <FlashMessage />
      </ActionCableProvider>
    )
  }
}

const withSaga = injectSaga({ key: context, saga })
const withReducer = injectReducer({ key: context, reducer })

const mapStateToProps = createStructuredSelector({
  policyData: policyDataSelector(),
})

const mapDispatchToProps = (dispatch) => ({
  stopLoading: () => dispatch(stopLoading()),
  openModal: (data) => dispatch(openModal(data)),
  getUserRoles: (data) => dispatch(getUserRoles(data)),
  closeModal: () => dispatch(closeModal()),
  checkUserPolicy: () => dispatch(checkUserPolicy()),
  updateUserPolicy: (data) => dispatch(updateUserPolicy(data)),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withReducer, withSaga, withConnect)(AppPrivate)
