import React, { memo, useEffect, useState, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { Button } from 'reactstrap'
import moment from 'moment'

import { openError } from 'containers/App/reducers'
import Header from '../components/Header'
import RowButton from '../components/RowButton'
import PrevStartButton from '../components/PrevStartButton'
import PageNumber from '../components/PageNumber'
import * as S from '../style'
import { getListBookingNotCheckin } from '../api'
import { useSelector, useDispatch } from 'reduxStoreHelper'
import {
  phoneNumberEnteredSelectors,
  isCreatedBookingDataSelectors,
  driverConfirmationsSelectors,
} from '../selectors'
import { GeneralBookingType } from '../type'
import {
  clearBooking,
  startLoading,
  endLoading,
  setIsFromBookingNo,
  resetCommonData,
  setSelectedBooking,
  resetSelectedBooking,
} from '../reducer'
import { INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import { ConfirmationType } from 'containers/IntegratedReceptionCustom/type'

type Props = {
  generalReceptionId?: string
  driverConfirmations?: ConfirmationType[]
  canGeneralBooking?: boolean
}

const EntryBookingsPage: React.FC<Props> = ({
  generalReceptionId,
  driverConfirmations,
  canGeneralBooking,
}) => {
  const [bookings, setBookings] = useState<GeneralBookingType[]>([])
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()

  const history = useHistory()
  const dispatch = useDispatch()
  const phoneNumberEntered = useSelector(phoneNumberEnteredSelectors())
  const isCreatedBookingData = useSelector(isCreatedBookingDataSelectors())

  const filteredConfirmations = driverConfirmations?.filter(
    (confirmation: ConfirmationType) => confirmation?.displayFlag
  )

  useEffect(() => {
    ;(async () => {
      dispatch(startLoading())
      try {
        const res = await getListBookingNotCheckin(generalReceptionId, phoneNumberEntered)
        setBookings(res?.data)
      } catch (error) {
        //@ts-ignore
        dispatch(openError(error.message))
      } finally {
        dispatch(endLoading())
      }
    })()
  }, [dispatch, generalReceptionId, phoneNumberEntered])

  const handleClickBookingNo = useCallback(
    async (booking: GeneralBookingType) => {
      dispatch(setSelectedBooking({ ...booking }))
      dispatch(setIsFromBookingNo(true))
      history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/${generalReceptionNumber}`)
    },
    [dispatch, generalReceptionNumber, history]
  )

  useEffect(() => {
    if (isCreatedBookingData) {
      dispatch(resetCommonData())
      dispatch(resetSelectedBooking())
      dispatch(clearBooking())
    }
  }, [dispatch, isCreatedBookingData])

  const handleClickCheckinNew = () => {
    dispatch(clearBooking())
    dispatch(setIsFromBookingNo(false))
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/${generalReceptionNumber}`)
  }

  return (
    <S.Wrapper>
      <Header />
      <S.EntryBookingPage className="pt-3 px-3">
        <div className="bg-white p-3" style={{ borderRadius: 12 }}>
          <div className="text-center mx-auto" style={{ width: 504 }}>
            <div className="mb-3 text-size32 font-weight-bold">予約の確認</div>

            {bookings?.length ? (
              <>
                <div className="mb-3">あなたの電話番号で以下の予約があります</div>
                <div className="d-flex mb-3 border-bottom">
                  <div style={{ marginLeft: 54 }}>予約日時</div>
                  <div style={{ marginLeft: 162 }}>予約番号</div>
                </div>
              </>
            ) : (
              <div className="mb-3">あなたの電話番号で予約はありません</div>
            )}
          </div>

          {Boolean(bookings?.length) && (
            <div className="mx-auto" style={{ width: 504 }}>
              {bookings.map((booking) => (
                <div key={booking.bookingNo} className="mb-3">
                  {/* @ts-ignore */}
                  <RowButton
                    onClick={(bookingDetail) => {
                      handleClickBookingNo(bookingDetail)
                    }}
                    booking={booking}
                  >
                    <>
                      <span>{moment(booking.planStartDate).format('M')}</span>
                      <span style={{ fontSize: 18 }}>月</span>
                      <span>{moment(booking.planStartDate).format('D')}</span>
                      <span className="mr-2" style={{ fontSize: 18 }}>
                        日
                      </span>
                      <span style={{ fontSize: 18 }}>
                        {booking.planStartTime
                          ? moment(
                              `${booking.planStartDate} ${booking.planStartTime}`,
                              'YYYYMMDD HH:mm'
                            ).format('HH:mm')
                          : '--:--'}
                        <span className="px-1">〜</span>
                        {booking.planEndTime
                          ? moment(
                              `${booking.planEndDate} ${booking.planEndTime}`,
                              'YYYYMMDD HH:mm'
                            ).format('HH:mm')
                          : '--:--'}
                      </span>
                    </>
                  </RowButton>
                </div>
              ))}
            </div>
          )}

          <div
            className="p-3"
            style={{
              position: 'fixed',
              backgroundColor: '#ededed',
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <div className="d-flex justify-content-center pb-3">
              <Button
                color="primary"
                outline
                className="px-4 mr-3 text-size24 rounded-pill bg-white text-primary"
                style={{ height: 52 }}
                onClick={() =>
                  history.push(
                    `${INTEGRATED_RECEPTION_URL_BASE}/entry-booking-number/${generalReceptionNumber}`
                  )
                }
              >
                リストにない予約番号で受付
              </Button>
              <Button
                color="primary"
                outline
                className="px-4 text-size24 rounded-pill bg-white text-primary"
                style={{ height: 52 }}
                onClick={handleClickCheckinNew}
              >
                予約なし受付
              </Button>
            </div>
            <div className="d-flex justify-content-between">
              <div style={{ width: '33%' }}>
                <PrevStartButton
                  onClick={() =>
                    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
                  }
                />
              </div>
              <div className="text-center" style={{ width: '34%' }}>
                <PageNumber
                  page="entry-bookings"
                  existsDriverConfirmations={Boolean(filteredConfirmations?.length)}
                  isFreePlan={!canGeneralBooking}
                />
              </div>
              <div className="text-right" style={{ width: '33%' }} />
            </div>
          </div>
        </div>
      </S.EntryBookingPage>
    </S.Wrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  driverConfirmations: driverConfirmationsSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(memo(EntryBookingsPage))
