import styled from 'styled-components'

import theme from 'vars/theme'

export const UploadImageButton = styled.label`
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  color: ${theme.blueMedium5};
  border: 1px solid ${theme.blueMedium5};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`

export const WrapperUploadFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 4px 4px 4px;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
`

export const InputFile = styled.input`
  display: none;

  &:disabled {
    & + ${UploadImageButton} {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
`

export const ListFile = styled.div`
  margin-left: 30px;
`

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FileBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  font-size: 14px;
`

export const WrapperIconAndNameFile = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RemoveIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const ImageIcon = styled.i.attrs(() => ({
  className: 'far fa-file-image',
}))`
  margin-right: 5px;
`

export const WrapperCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 4px;
`
