import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { HeaderWrapper } from 'containers/ReceiptBooking/components/Layout/Header/style'
import Icon from 'components/Icon'
import { VERSION } from 'constants/App'

const HeaderTitle = styled.h2`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
`

export default ({ driver = {}, generalReceptionName }) => (
  <HeaderWrapper>
    <div className="brand flex-center">
      <Icon src="/assets/svgs/monoful-brand-white.svg" alt="monoful brand" className="ml-1" />
      <p className="font-md mb-0 ml-1">トラック簿</p>
    </div>
    <div className="main d-flex align-items-center justify-content-between">
      <HeaderTitle className="font-normal font-weight-bold">
        {generalReceptionName && <span className="ml-2">{generalReceptionName}</span>}
        {driver.warehouse && <span className="mr-2">{driver.warehouse.name}</span>}
        {driver.warehouse && driver.tenant && <span>|</span>}
        {driver.tenant && <span className="ml-2">{driver.tenant.name}</span>}
      </HeaderTitle>

      {driver.multiTenantId ? (
        <NavLink to={`/mtenants/${driver.multiTenantId}`}>
          <h2
            className="font-normal font-weight-bold btn"
            style={{ color: 'white', width: '220px' }}
            role="presentation"
          >
            トップへ戻る
          </h2>
        </NavLink>
      ) : (
        <h2
          className="font-normal font-weight-bold btn"
          style={{ color: 'white', cursor: 'auto', width: '220px', textAlign: 'right' }}
        >
          {generalReceptionName && <span className="mr-3">総合</span>}
          受付入力
          <span className="ml-3 font-weight-normal text-muted">ver. tf{VERSION}</span>
        </h2>
      )}
    </div>
  </HeaderWrapper>
)
