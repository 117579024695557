import {
  ParameterConfirmBookingWorkerType,
  ParameterType,
} from 'containers/IntegratedListCardsPage/type'
import RestClient, { getAuthHeader } from './RestClient'

export default class CardListService {
  restService: RestClient

  constructor(restService = new RestClient()) {
    this.restService = restService
  }

  getWarehouseList(booking_date: string) {
    return this.restService.get(
      'berths/load_warehouses',
      { booking_date },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getListCardReception(tenant_id: string) {
    return this.restService.get(
      'list-card-reception',
      { tenant_id },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getBookingMasterData(tenant_id: string) {
    return this.restService.get(
      'berths/bookings/master_data',
      { tenant_id },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getBerthsListCardBookings = (data: ParameterType) => {
    return this.restService.post({
      url: 'berths/list-card/bookings',
      data: data,
      params: {},
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getBerthPermission(tenant_id: string) {
    return this.restService.get(
      'berths/permissions-by-tenant',
      { tenant_id },
      {
        headers: getAuthHeader(),
      }
    )
  }

  confirmBookingListCard = (data: ParameterConfirmBookingWorkerType) => {
    return this.restService.patch({
      url: 'berths/list-card/confirm-bookings',
      data,
      params: {},
      config: {
        headers: getAuthHeader(),
      },
    })
  }
}
