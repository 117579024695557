import React, { Component } from 'react'
import { connect } from 'react-redux'
import { camelizeKeys } from 'humps'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import moment from 'moment'

import Header from '../components/Header'
import { fetchAnything, openError, startLoading, stopLoading } from 'containers/App/reducers'
import { updateDriver, clearAll, updateReturnEntryPage } from '../reducers'
import { requestGet } from '../api'
import SessionStorage from '../SessionStorage'
import PrevStartButton from '../components/PrevStartButton'
import MonofulLogo from 'assets/svgs/monoful-logo-background-white.svg'
import theme from 'vars/theme'
import SpinAround from 'assets/svgs/spin-around.svg'
import { ButtonRedirectMode } from 'containers/IntegratedReception/constants'
import {
  MAX_MINUTE_GET_WATTING_BOOKING,
  TIMER_GET_WATTING_BOOKING,
  STANDARD_TIME_FORMAT,
  STANDARD_TIME_CHECKIN_FORMAT,
} from '../constants'
const LabelTitle = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 1.5rem;
`

const TextBold = styled.span`
  font-weight: bold;
  font-size: 70px;
`

const ButtonOutline = styled.button`
  color: #247cdb;
  background-color: white;
  border: 1px solid #247cdb;
  border-radius: 68px;
  min-width: 440px;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 32px;

  ${({ color }) =>
    color &&
    css`
      text-align: center;
      color: white;
      background-color: ${color};
      border: 1px solid ${color};
    `};
`

const WrapperNavLink = styled.div`
  display: flex;
  justify-content: space-between;
  width: 940px;
  padding-top: 35px;
`

const Content = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 100px;
  padding-top: 100px;
  border-radius: 12px;
  background: white;
  min-height: 500px;
  text-align: center;
`

const ImageLogo = styled.img`
  width: 70px;
  height: 80px;
`

const Wrapper = styled.div`
  background-color: ${theme.graySoft6};
  height: 100vh;
  font-size: 18px;
  min-width: 1024px;
`

const WaittingInforWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  border-top: 2px solid ${theme.graySoft6};
  border-bottom: 2px solid ${theme.graySoft6};
`

const WaittingContent = styled.div`
  display: flex;
  align-items: center;

  ${({ hasMarginRight }) =>
    hasMarginRight &&
    css`
      margin-right: 15px;
    `};
`

const WaittingInforText = styled.span`
  margin: 0 5px;
  padding: 0 10px;
  background-color: ${theme.grayMedium3};
  font-size: 25px;
  font-weight: bold;
`

const WaittingInforNumber = styled.span`
  font-size: 40px;
  padding: 0 5px 0 10px;
  font-weight: bold;
`
const WaittingInforHorizontal = styled.span`
  font-size: 35px;
`

const WattingInforUnit = styled.span`
  padding-right: 5px;
  font-size: 20px;
  font-weight: bold;
`

const LastUpdateText = styled.p`
  font-size: 12px;
  text-align: center;
  margin: 0;
`

const SpinAroundImage = styled.img`
  margin-left: 5px;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const WaittingInforChekinTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const WaittingInforTextChekinTime = styled.span`
  font-size: 25px;
  font-weight: bold;
`

class EntryPage extends Component {
  constructor(props) {
    super(props)
    this.props.clearAll()
    this.state = {
      loadingWattingBooking: false,
      timeLeftCallApi: 0,
      dataWattingBooking: {},
    }
    this.handleTimer = this.handleTimer.bind(this)
    this.intervalTimeWaitingBooking = 0
  }

  async componentDidMount() {
    const { location, match } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { multiTenantId = '' } = location.state || {
      multiTenantId: SessionStorage.read('multiTenantId'),
    }
    this.getWattingTimeData(true)
    SessionStorage.write('multiTenantId', multiTenantId)

    let resp

    this.props.startLoading()

    try {
      resp = await requestGet('/tablets/base_data', { warehouseId, tenantId })
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const data = camelizeKeys(resp.data)
    this.props.updateDriver({ multiTenantId, warehouse: data.warehouse, tenant: data.tenant })
    this.props.updateReturnEntryPage()
  }

  handleClickRedirectPage = (mode) => {
    const { history, match } = this.props
    const { warehouseId, tenantId } = match.params

    if (mode === ButtonRedirectMode.CHECKIN) {
      return history.push(`/receipt-booking/entry-tel-number/${warehouseId}/${tenantId}`)
    }
    history.push(`/receipt-booking/exit/${warehouseId}/${tenantId}`)
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimeWaitingBooking)
  }

  handleTimer() {
    if (this.state.timeLeftCallApi + 1 === MAX_MINUTE_GET_WATTING_BOOKING) {
      this.getWattingTimeData()
    }
    this.setState({
      timeLeftCallApi: this.state.timeLeftCallApi + 1,
    })
  }

  renderCheckinTime(timeCheckinTime) {
    if (timeCheckinTime?.allowStartTime && timeCheckinTime?.allowEndTime) {
      return `${moment(timeCheckinTime?.allowStartTime, STANDARD_TIME_FORMAT).format(
        STANDARD_TIME_CHECKIN_FORMAT
      )}~${moment(timeCheckinTime?.allowEndTime, STANDARD_TIME_FORMAT).format(
        STANDARD_TIME_CHECKIN_FORMAT
      )}`
    }
    if (timeCheckinTime?.allowStartTime) {
      return `${moment(timeCheckinTime?.allowStartTime, STANDARD_TIME_FORMAT).format(
        STANDARD_TIME_CHECKIN_FORMAT
      )}から`
    }
    if (timeCheckinTime?.allowEndTime) {
      return `${moment(timeCheckinTime?.allowEndTime, STANDARD_TIME_FORMAT).format(
        STANDARD_TIME_CHECKIN_FORMAT
      )}まで`
    }
  }

  async getWattingTimeData(isFisrtCallApi = false) {
    const {
      params: { warehouseId, tenantId },
    } = this.props.match
    this.setState({ loadingWattingBooking: true })

    try {
      const resp = await requestGet('/tablets/waiting-booking-tenants', { warehouseId, tenantId })
      const data = camelizeKeys(resp.data)
      this.setState({ dataWattingBooking: data })

      const { waitingFlag, waitingTimeFlag, timeMin, timeAverage } = data
      const isShowWaitingTimeByRange = waitingTimeFlag && _.isNumber(timeMin)
      const isShowWattingTimeByAverage = waitingTimeFlag && _.isNumber(timeAverage)
      if (
        isFisrtCallApi &&
        (waitingFlag || isShowWaitingTimeByRange || isShowWattingTimeByAverage)
      ) {
        this.intervalTimeWaitingBooking = setInterval(this.handleTimer, TIMER_GET_WATTING_BOOKING)
        return
      }
      if (!isFisrtCallApi && !waitingFlag && !waitingTimeFlag) {
        clearInterval(this.intervalTimeWaitingBooking)
      }
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.setState({ loadingWattingBooking: false, timeLeftCallApi: 0 })
    }
  }

  render() {
    const { driver } = this.props
    const { timeMin, timeMax, timeAverage, bookingCount, waitingFlag, waitingTimeFlag } =
      this.state.dataWattingBooking
    const isShowWaitingTimeByRange = waitingTimeFlag && _.isNumber(timeMin)
    const isShowWattingTimeByAverage = waitingTimeFlag && _.isNumber(timeAverage)
    const timeCheckinTime = driver?.tenant?.tenantCheckinTime
    const canAllowCheckinTime = driver?.tenant?.allowCheckin
    return (
      <Wrapper>
        <Header driver={driver} />
        <div style={{ margin: 32 }}>
          <Content>
            <LabelTitle>
              <ImageLogo src={MonofulLogo} alt="monoful logo" />
              トラック簿
            </LabelTitle>
            {(waitingFlag || isShowWattingTimeByAverage || isShowWaitingTimeByRange) && (
              <LastUpdateText>
                最終更新：{this.state.timeLeftCallApi}分前
                {this.state.loadingWattingBooking && (
                  <SpinAroundImage src={SpinAround} alt="spin loading" />
                )}
              </LastUpdateText>
            )}
            <WaittingInforWrapper>
              {waitingFlag && (
                <WaittingContent hasMarginRight={waitingTimeFlag}>
                  <WaittingInforText>待機中</WaittingInforText>
                  <div>
                    <WaittingInforNumber>{bookingCount}</WaittingInforNumber>
                    <WattingInforUnit>台</WattingInforUnit>
                  </div>
                </WaittingContent>
              )}
              {isShowWaitingTimeByRange && (
                <WaittingContent>
                  <WaittingInforText>待ち時間</WaittingInforText>
                  <div>
                    <WaittingInforNumber>{timeMin}</WaittingInforNumber>
                    <WattingInforUnit>分</WattingInforUnit>
                  </div>
                  <WaittingInforHorizontal>−</WaittingInforHorizontal>
                  <div>
                    <WaittingInforNumber>{timeMax}</WaittingInforNumber>
                    <WattingInforUnit>分</WattingInforUnit>
                  </div>
                </WaittingContent>
              )}
              {isShowWattingTimeByAverage && (
                <WaittingContent>
                  <WaittingInforText>待ち時間</WaittingInforText>
                  <div>
                    <WattingInforUnit>約</WattingInforUnit>
                    <WaittingInforNumber>{timeAverage}</WaittingInforNumber>
                    <WattingInforUnit>分</WattingInforUnit>
                  </div>
                </WaittingContent>
              )}
            </WaittingInforWrapper>
            {!canAllowCheckinTime && (
              <WaittingInforChekinTime>
                <WaittingInforTextChekinTime>入場受付時間外です。</WaittingInforTextChekinTime>
                <WaittingInforTextChekinTime>
                  受付できる時間は{this.renderCheckinTime(timeCheckinTime)}です。
                </WaittingInforTextChekinTime>
              </WaittingInforChekinTime>
            )}
            <WrapperNavLink
              className="mx-auto"
              style={{
                justifyContent: canAllowCheckinTime ? 'space-between' : 'center',
              }}
            >
              {canAllowCheckinTime && (
                <ButtonOutline
                  color={theme.blueMedium5}
                  onClick={() => this.handleClickRedirectPage(ButtonRedirectMode.CHECKIN)}
                >
                  <TextBold>入場</TextBold>受付
                </ButtonOutline>
              )}
              <ButtonOutline
                color={theme.redPrimary1}
                onClick={() => this.handleClickRedirectPage(ButtonRedirectMode.CHECKOUT)}
              >
                <TextBold>退場</TextBold>受付
              </ButtonOutline>
            </WrapperNavLink>
          </Content>

          <div className="d-flex justify-content-between">
            <div style={{ width: '33%' }}>
              {driver.multiTenantId && <PrevStartButton to={`/mtenants/${driver.multiTenantId}`} />}
            </div>
            <div style={{ width: '34%' }} />
            <div style={{ width: '33%' }} />
          </div>
        </div>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
  }
}

const mapDispatchToProps = {
  updateDriver,
  fetchAnything,
  clearAll,
  openError,
  startLoading,
  stopLoading,
  updateReturnEntryPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryPage)
