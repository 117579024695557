import React, { Suspense } from 'react'

type Props = {
  match: any
}

const FilePreviewPage = React.lazy(() => import('./FilePreviewPage'))

export default ({ match }: Props) => (
  <Suspense fallback={<div />}>
    <FilePreviewPage match={match} />
  </Suspense>
)
