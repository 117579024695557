/* Modal confirm when admin calling a new booking */
import React from 'react'
import styled from 'styled-components'
import { Modal } from 'reactstrap'
import Button from 'components/Button'

export const Wrapper = styled(Modal)`
  width: ${({ width }) => `${width}px`};
  .modal-content {
    margin-top: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .confirm--title {
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
      white-space: pre-wrap;
    }

    label {
      cursor: pointer;
    }

    .form-check-input {
      width: 22px;
      width: 22px;
      cursor: pointer;
    }

    .button-group {
      width: 250px;
      button {
        width: 120px;
        margin-top: 20px;
      }
    }
  }
`

const ConfirmModal = ({ isOpen, handleConfirm, handleCancel, title, width }) => (
  <Wrapper width={width} isOpen={isOpen}>
    <h3 className="confirm--title" dangerouslySetInnerHTML={{ __html: title }} />
    <div className="d-flex justify-content-between button-group">
      <Button color="red" onClick={handleCancel}>
        キャンセル
      </Button>
      <Button color="blue" onClick={handleConfirm}>
        OK
      </Button>
    </div>
  </Wrapper>
)

export default ConfirmModal

ConfirmModal.defaultProps = {
  width: 400,
}
