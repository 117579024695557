import { takeLatest, put, call } from 'redux-saga/effects'

import { GET_USER_ROLES, CHECK_USER_POLICY, UPDATE_USER_POLICY } from './constants'
import {
  getUserRolesSuccess,
  getUserRolesFailure,
  checkUserPolicySuccess,
  updateUserPolicySuccess,
} from './actions'
import { usersServices } from 'services'

function* getUserRolesProcess({ payload }) {
  try {
    const { data } = yield call([usersServices, 'getUserRoles'], payload)
    yield put(getUserRolesSuccess(data || {}))
  } catch (error) {
    yield put(getUserRolesFailure())
  }
}

function* checkUserPolicyProcess() {
  try {
    const { data = {} } = yield call([usersServices, 'checkUserPolicy'])
    yield put(checkUserPolicySuccess(data.display_policy_version || {}))
  } catch (error) {}
}

function* updateUserPolicyProcess({ payload }) {
  try {
    yield call([usersServices, 'updateUserPolicy'], payload)
    yield put(updateUserPolicySuccess())
  } catch (error) {}
}

function* watchSaga() {
  yield takeLatest(GET_USER_ROLES, getUserRolesProcess)
  yield takeLatest(CHECK_USER_POLICY, checkUserPolicyProcess)
  yield takeLatest(UPDATE_USER_POLICY, updateUserPolicyProcess)
}

export default watchSaga
