import React, { Component } from 'react'

import 'react-sliding-pane/dist/react-sliding-pane.css'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import CsvContainer from 'containers/HomePage/components/CsvContainer'
import { VERSION } from 'constants/App'

import MenuItemGroup from './MenuItemGroup'
import * as S from './style'
export class Sidebar extends Component {
  renderGeneralReceptionMenu = () => {
    const { userData, receptionData } = this.props
    const { is_warehouse_admin, is_general_manager } = _get(userData, 'attributes', {})
    const { is_exists_reception } = receptionData

    if (is_warehouse_admin) {
      return (
        <MenuItemGroup title="総合メニュー ">
          {is_exists_reception && (
            <S.MenuItem to="/multi-booking">
              <span>総合管理</span>
            </S.MenuItem>
          )}

          <S.MenuItem to="/multi-setting">
            <span>マスタ</span>
          </S.MenuItem>
          {is_exists_reception && (
            <S.MenuItem to="/general-csv-download/actual">
              <span>実績ダウンロード</span>
            </S.MenuItem>
          )}
          {is_exists_reception && (
            <S.MenuItem
              to="/reception-custom-general/items"
              isActive={(match, location) => {
                return location?.pathname?.includes('/reception-custom-general')
              }}
            >
              <span>受付カスタマイズ</span>
            </S.MenuItem>
          )}
        </MenuItemGroup>
      )
    }

    if (is_general_manager && is_exists_reception) {
      return (
        <MenuItemGroup title="総合メニュー ">
          <S.MenuItem to="/multi-booking">
            <span>総合管理</span>
          </S.MenuItem>
          <S.MenuItem to="/multi-setting">
            <span>マスタ</span>
          </S.MenuItem>
          <S.MenuItem to="/general-csv-download/actual">
            <span>実績ダウンロード</span>
          </S.MenuItem>
          <S.MenuItem
            to="/reception-custom-general/items"
            isActive={(match, location) => {
              return location?.pathname?.includes('/reception-custom-general')
            }}
          >
            <span>受付カスタマイズ</span>
          </S.MenuItem>
        </MenuItemGroup>
      )
    }

    // If it is controller general
    if (is_exists_reception) {
      return (
        <MenuItemGroup title="総合メニュー ">
          <S.MenuItem to="/multi-booking">
            <span>総合管理</span>
          </S.MenuItem>
        </MenuItemGroup>
      )
    }

    return <></>
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      userData,
      receptionData,
      isRenderCsv,
      unreadNoticeCount,
      userBookingPermission,
      customBerthLabel,
      ...props
    } = this.props
    const { is_warehouse_admin, is_warehouse_manager, is_general_manager } = _get(
      userData,
      'attributes',
      {}
    )
    const { general_booking_permission, normal_booking_permission } = userBookingPermission
    const isPermission = is_warehouse_admin || is_warehouse_manager
    // The controler or viewer's user don't show home page
    const isControllerOrViewer =
      !_isEmpty(userData) &&
      !_isEmpty(userBookingPermission) &&
      !is_warehouse_admin &&
      !is_general_manager &&
      general_booking_permission &&
      !normal_booking_permission

    return (
      <S.SideBarWrapper
        closeTimeoutMS={200}
        isOpen={isOpen}
        from="left"
        width="250px"
        title={`${customBerthLabel}管理`}
        onRequestClose={onRequestClose}
        {...props}
      >
        <S.SideBar>
          {!isControllerOrViewer && (
            <MenuItemGroup title={`${customBerthLabel}メニュー`}>
              <S.MenuItem exact to="/">
                <span>{`${customBerthLabel}管理`}</span>
              </S.MenuItem>
              {isPermission && (
                <>
                  <S.MenuItem to="/warehouse">
                    <span>マスタ</span>
                  </S.MenuItem>
                  <S.MenuItem to="/actual">
                    <span>実績ダッシュボード</span>
                  </S.MenuItem>
                  <S.MenuItem
                    to="/reception-custom/items"
                    isActive={(match, location) => {
                      return location?.pathname?.includes('/reception-custom/')
                    }}
                  >
                    <span>受付カスタマイズ</span>
                  </S.MenuItem>
                  {isRenderCsv && <CsvContainer closeSideBar={onRequestClose} />}
                </>
              )}
            </MenuItemGroup>
          )}
          {this.renderGeneralReceptionMenu()}
          <S.MenuItem exact to="/notice">
            <span>インフォメーション</span>
            {Boolean(unreadNoticeCount) && <S.NoticeCount>{unreadNoticeCount}</S.NoticeCount>}
          </S.MenuItem>
          <S.MenuItemLink href="https://teachme.jp/100694/f/roomyab0FSOK" target="_blank">
            Webマニュアル
          </S.MenuItemLink>
          {isPermission && (
            <S.MenuItem to="/settings/berth">
              <span>設定</span>
            </S.MenuItem>
          )}
          <div
            className="text-white text-center mb-2"
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
          >
            <S.MenuItemText>
              <S.ItemText isFontBold>サポートセンター</S.ItemText>
              <S.ItemText isFontBold>0120-507-765</S.ItemText>
              <S.ItemText>営業時間: 9:00~18:00</S.ItemText>
              <S.ItemText>（土・日・祝を除く）</S.ItemText>
            </S.MenuItemText>
            ver. tf{VERSION}
          </div>
        </S.SideBar>
      </S.SideBarWrapper>
    )
  }
}

export default Sidebar
