import RestClient, { getAuthHeader } from './RestClient'
import { trimData } from 'utils/commonFunctions'
import { BASE_URL_V2_1 } from 'constants/misc'

export default class HomeServices {
  constructor(restService = RestClient) {
    this.restService = restService
  }

  getWarehouseList(booking_date) {
    return this.restService.get(
      'berths/load_warehouses',
      { booking_date },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getBerthList({ warehouse_id, page, tenant_id }) {
    return this.restService.get(
      'berths/list_berths_by_warehouse',
      {
        warehouse_id,
        tenant_id,
        page,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getAllBerthList({ warehouse_id, tenant_id }) {
    return this.restService.get(
      'berths/list_berths_by_tenant',
      { warehouse_id, tenant_id },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getBerthListWithBooking({ warehouse_id, tenant_id, booking_date, page, predicate }) {
    return this.restService.get(
      'berths/berth_managements',
      {
        page,
        warehouse_id,
        tenant_id,
        included_type: 0,
        booking_date,
        predicate,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getCompanyList(tenant_id) {
    return this.restService.get(
      'berths/bookings/list_companies',
      { tenant_id },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  getCompanyMasterData(data) {
    return this.restService.get(
      'berths/booking/company/detail',
      { ...data },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  createNewBooking(data) {
    return this.restService.post({
      url: 'berths/booking',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  createNewBookingRepeat(data) {
    return this.restService.post({
      url: 'berths/bookings/repeat',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  createUnassignBooking(data) {
    return this.restService.post({
      url: 'berths/booking/unassign',
      data: { ...data },
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  createNewDelegation(data) {
    return this.restService.post({
      url: 'berths/delegation_booking',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getDelegationDetail({ delegation_id, tenant_id }) {
    return this.restService.get(
      'berths/delegation_booking',
      { delegation_id, tenant_id },
      {
        headers: getAuthHeader(),
      }
    )
  }

  updateDelegation(data) {
    return this.restService.put({
      url: 'berths/delegation_booking',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  deleteDelegation(params) {
    return this.restService.delete({
      url: 'berths/delegation_booking',
      params: { ...params },
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getCommentList(booking_id) {
    return this.restService.get(
      'berths/booking/comments',
      { booking_id },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  addNewComment(data) {
    return this.restService.post({
      url: 'berths/booking/comment',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  sendLineMessage(line) {
    return this.restService.post({
      url: 'send_line_msg',
      data: { line },
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getBookingDetail({ booking_id, tenant_id }) {
    return this.restService.get(
      'berths/booking',
      {
        booking_id,
        tenant_id,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeader(),
        },
      }
    )
  }

  updateBookingItem(data) {
    return this.restService.put({
      url: 'berths/booking',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  updateBookingItemUnassigned(data) {
    return this.restService.put({
      url: 'berths/booking/unassign',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  updateBookingItemRecept(data) {
    return this.restService.put({
      url: 'berths/booking/update_reception',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  updateBookingRepeat(data) {
    return this.restService.put({
      url: 'berths/bookings/repeat',
      data: trimData(data),
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  updateBookingStatus(data) {
    return this.restService.patch({
      url: 'berths/booking/update_status',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getPreviousWorkingBooking({ booking_id, tenant_id }) {
    return this.restService.get(
      'berths/booking/latest_in_past',
      { booking_id, tenant_id },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  getRepeatPreviousWorkingBooking({ berth_id, tenant_id, start_date, start_time }) {
    return this.restService.get(
      'berths/bookings/latest_booking_in_past',
      { berth_id, tenant_id, start_date, start_time },
      {
        headers: {
          ...getAuthHeader(),
        },
      }
    )
  }

  deleteBookingItem({ booking_id, apply_type, tenant_id }) {
    return this.restService.delete({
      url: 'berths/booking',
      params: {
        booking_id,
        apply_type,
        tenant_id,
      },
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  deleteBookingRepeat({
    tenant_id,
    repeat_id,
    booking_start_date,
    booking_end_date,
    booking_start_time,
    booking_end_time,
    apply_type,
  }) {
    return this.restService.delete({
      url: 'berths/bookings/repeat',
      params: {
        tenant_id,
        repeat_id,
        booking_start_date,
        booking_end_date,
        booking_start_time,
        booking_end_time,
        apply_type,
      },
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getNeutralList(data) {
    return this.restService.get(
      'berths/bookings/pending',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  downloadCsv(data) {
    return this.restService.get(
      'berths/csv/download',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  downloadCsvNewVersion(data) {
    return this.restService.get(
      'berths/csv/download',
      { ...data, header_language: 'ja' },
      {
        headers: getAuthHeader(),
        baseURL: BASE_URL_V2_1,
      }
    )
  }

  uploadCsv(data) {
    return this.restService.post({
      url: 'berths/csv/upload',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  uploadCsvBerth({ file, tenantCsvId, encodingType }) {
    let formData = new FormData()
    formData.append('tenant_csv_id', tenantCsvId)
    formData.append('upload_file', file)
    formData.append('encoding_type', encodingType)

    return this.restService.post({
      url: 'berths/csv/upload',
      data: formData,
      config: {
        headers: getAuthHeader(),
        baseURL: BASE_URL_V2_1,
      },
    })
  }

  getListTenantsByWarehouse(warehouse_id) {
    return this.restService.get(
      'berths/list_tenants_by_warehouse',
      { warehouse_id },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getBookingMasterData(tenant_id) {
    return this.restService.get(
      'berths/bookings/master_data',
      { tenant_id },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getLatestInPastByTime({ start_time, start_date, berth_id, tenant_id }) {
    // get previous booking for direct calling
    return this.restService.get(
      'berths/booking/latest_in_past_by_time',
      {
        start_time: start_time.toString(),
        start_date,
        berth_id,
        tenant_id,
      },
      {
        headers: getAuthHeader(),
      }
    )
  }

  callingReceiptBooking(data) {
    return this.restService.post({
      url: 'berths/booking/calling_reception',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getListBookingsByBerth(data) {
    return this.restService.get(
      'berths/list_bookings_by_berth',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getBookingSummary(data) {
    return this.restService.get(
      '/berths/bookings/summary',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  findDriverInfo({ driver_name, driver_phone }) {
    return this.restService.get(
      'berths/bookings/find_driver',
      { driver_name, driver_phone },
      {
        headers: getAuthHeader(),
      }
    )
  }

  findControllerInfo(vehicle_controller_email) {
    return this.restService.get(
      'berths/bookings/find_driver_controller',
      { vehicle_controller_email },
      {
        headers: getAuthHeader(),
      }
    )
  }

  findIndexUnassignBooking(data) {
    return this.restService.get(
      'berths/bookings/find_index_pending_booking',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  updateRepeatStatus(data) {
    return this.restService.patch({
      url: 'berths/bookings/repeat/status',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  fetchListMessageCustomize(bookingId) {
    return this.restService.get(
      '/tenant_customize_messages',
      { booking_id: bookingId },
      {
        headers: getAuthHeader(),
      }
    )
  }

  sendCustomizeMessage(data) {
    return this.restService.post({
      url: '/tenant_customize_messages/send_message',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getUserTenantsDelegationSameGroup(data) {
    return this.restService.get(
      'warehouse/user_tenants/delegations/same_group',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getCustomTaskList(data) {
    return this.restService.get(
      '/custom-task-list',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  updateCustomTaskList(data) {
    return this.restService.put({
      url: '/custom-task-list',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  getBookingYesterDay(data) {
    return this.restService.get(
      'berths/booking-only',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getBerthGroupList(data) {
    return this.restService.get(
      'berth-groups',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  getBerthPermission(data) {
    return this.restService.get(
      'berths/permissions-by-tenant',
      { ...data },
      {
        headers: getAuthHeader(),
      }
    )
  }

  confirmedDriverChangeTime(data) {
    return this.restService.put({
      url: 'berths/booking/confirm-booking-time-change',
      data,
      config: {
        headers: getAuthHeader(),
      },
    })
  }
}
