import React from 'react'

const PAGE_INDEXES = Object.freeze({
  'entry-bookings': 0,
  'entry-driver-info': 1,
  'entry-load': 2,
  'entry-confirmation': 3,
})

export default ({ page, existsDriverConfirmations, isFreePlan }) => {
  const currentPage = PAGE_INDEXES[page] + (isFreePlan ? 0 : 1)
  const totalPage = 2 + (existsDriverConfirmations ? 1 : 0) + (isFreePlan ? 0 : 1)

  return <span>{`${currentPage}/${totalPage}`}</span>
}
