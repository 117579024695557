import styled, { css } from 'styled-components'
import theme from 'vars/theme'

import SingleForm from 'components/SingleForm'

export const WarehousesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  height: 100%;
  margin-top: 1rem;
`

export const MainWrapper = styled.div`
  flex: 1 1 ${theme.minWidth};
  min-width: 100%;
  height: 100%;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .aside {
    flex: 0 0 ${theme.sidebarWidth};
    max-width: ${theme.sidebarWidth};
    height: calc(100vh - ${theme.headerHeight});
    position: relative;

    &--booking-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 34px;
      background-color: #fafafa;
      font-size: 13px;
      color: ${theme.darkBlueMedium1};
    }
  }

  & > div {
    flex-grow: 1;
    max-width: calc(100vw - ${theme.sidebarWidth});
  }

  .main {
    flex: 1 0 calc(${theme.minWidth} - ${theme.sidebarWidth});
    min-width: calc(${theme.minWidth} - ${theme.sidebarWidth});
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const GeneralReceptionList = styled.div`
  font-size: 14px;
  color: ${theme.darkBlueMedium1};
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 0 24px;
  height: 44px;
  border: 0;
  border-top: 1px solid ${theme.graySoft2};
  border-bottom: 1px solid ${theme.graySoft2};
  cursor: pointer;

  &:not(:first-child) {
    border-top: 0;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: white;
    font-weight: bold;
    background-color: ${theme.redMedium1};
    text-decoration: none;
    outline: none;
  }
`

export const CardInfo = styled.div`
  width: 100%;
  height: 380px;
  background-color: white;
  border-radius: 2px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 5rem;
  }

  .card__header {
    width: 100%;
    height: 56px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
  }

  .card__body {
    height: calc(380px - 56px);
  }
`

export const GeneralReceptionInfo = styled.div`
  padding: 20px;

  .label-flex {
    flex: 0 0 163px;
  }
`

export const ImgPreview = styled.div`
  position: relative;
  margin: 0 1rem 0.5rem 0;

  &:hover {
    .remove {
      display: block;
    }
  }

  img {
    width: 125px;
    height: 90px;
    object-fit: contain;
    border-radius: 4px;
  }
`

const CenterSpace = css`
  width: 70%;
  max-width: 1200px;
  min-width: 800px;
`
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - ${theme.headerHeight} - 100px);
  background: white;
  border-radius: 2px;
`

export const FormBody = styled.div`
  ${CenterSpace}
`

export const WrapperButton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  ${CenterSpace}
`
export const FormButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 100%;
  padding: 12px 0px;
  background: ${theme.graySoft1};
`

export const Label = styled.label`
  flex: 0 0 140px;
  align-self: flex-start;
  font-size: 14px;
  color: ${theme.darkBlueMedium1};
`

export const InfoBox = styled.div`
  display: flex;

  span {
    font-size: 14px;
    color: ${theme.darkBlueMedium1};
    flex: 1;
    padding-left: 5px;
  }
`

export const InputCustom = styled(SingleForm)`
  input {
    width: 40px;
  }
`

export const BerthListWrapper = styled.div`
  display: flex;

  & > div {
    padding-top: 50px;

    & > div {
      padding-left: 0;
    }
  }
`

export const FormCreateGeneralWrapper = styled.div`
  .label-flex {
    flex: 0 0 163px;
  }
`

export const BottomWrapper = styled.div`
  padding: 20px;
  border-top: 2px solid ${theme.graySoft1};
`

export const Wrapper = styled.div`
  background-color: white;
  padding-bottom: 76px;
`

export const WrapperAddButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const WrapperInput = styled.div`
  display: flex;
  margin-bottom: 35px;

  & > ${WrapperAddButton} {
    flex: 2;
    flex-direction: row;
  }

  & > div {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    padding: 0;

    & .label-flex {
      flex: none;
      margin-left: 5px;
      color: ${theme.darkBlueMedium3};
    }

    & .input-flex {
      flex: none;
      width: 100%;

      .mono-creatable__control {
        max-width: none;
      }

      .form-control {
        height: 38px;
      }
    }
  }
`

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 20px;
`

export const PaginationTextResult = styled.span`
  font-size: 14px;
  margin-right: 16px;

  & > span {
    margin-left: 6px;
    &.total-item {
      font-weight: bold;
      font-size: 22px;
    }
  }
`

export const WrapperPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
