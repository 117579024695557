import RestClient, { getAuthHeader } from 'services/RestClient'
import { GeneralReceptionType, RoleType } from './type'
import { FIRST_PAGE, PER_PAGE } from './constants'
import { camelizeKeys, decamelizeKeys } from 'humps'

const client = new RestClient()
const config = {
  headers: {
    'Content-Type': 'application/json',
    ...getAuthHeader(),
  },
}

const requestGet = (url = '', data = {}) =>
  client
    .get(url, decamelizeKeys(data), config)
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

const requestDelete = (url = '', data: any, params: any) =>
  client
    .delete({ url, params: decamelizeKeys(params), data: decamelizeKeys(data), config })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

const requestPost = (url = '', data: any) =>
  client
    .post({
      url,
      params: {},
      data: decamelizeKeys(data),
      config,
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

const requestPut = (url = '', data: any) =>
  client
    .put({
      url,
      params: {},
      data: decamelizeKeys(data),
      config,
    })
    .then((res) => Promise.resolve(camelizeKeys(res) as any))

export const getGeneralReceptions = (): Promise<{
  data: {
    generalReceptions: GeneralReceptionType
  }
}> => requestGet('/user-general-reception/general-receptions-without-tenants')

export const getGeneralReceptionTenants = (
  general_reception_id: number,
  page = FIRST_PAGE,
  per_page = PER_PAGE
) =>
  requestGet('/user-general-reception/general-reception-tenants', {
    general_reception_id,
    page,
    per_page,
  })

export const getListTenantsOfParent = (company_id: number): Promise<{}> =>
  requestGet('/user-general-reception/tenants-info', {
    company_id,
  })

export const createGeneralReception = (data: object): Promise<{}> =>
  requestPost('/general-receptions', data)

export const putEditGeneralReception = (data: object): Promise<{}> =>
  requestPut('/general-receptions', data)

export const getUserGeneralReception = (
  general_reception_id: number,
  role_type: RoleType,
  page = FIRST_PAGE,
  per_page = PER_PAGE,
  keyword?: string
) =>
  requestGet('/user-general-reception/users', {
    general_reception_id,
    role_type,
    page,
    per_page,
    keyword,
  })

export const getWarehousesByGeneralReceptionId = (general_reception_id?: number) =>
  requestGet('/user-general-reception/warehouses', { general_reception_id })

export const getTenantsByWarehouseId = (warehouse_id: number) =>
  requestGet('/user-general-reception/tenants', { warehouse_id })

export const getBerthsByWarehouseIdAnsTenantId = (data: any) =>
  requestGet('/warehouse/berths', data)

export const postGeneralReceptionTenant = (data: object) =>
  requestPost('/user-general-reception/general-reception-tenants', data)

// AddAdminPage
export const getGeneralReceptionManager = (
  general_reception_id: number,
  keyword: string,
  page = FIRST_PAGE,
  per_page = PER_PAGE
) =>
  requestGet('/user-general-reception/general-reception-managers', {
    general_reception_id,
    page,
    per_page,
    'q[name_or_email_cont]': keyword,
  })

export const postManageRoleGeneral = (data: object) =>
  requestPost('/user-general-reception/manage-role-general', data)

export const getDetailGeneralReception = (generalReceptionId: string) =>
  requestGet(
    `/user-general-reception/general-receptions-without-tenants/detail/${generalReceptionId}`
  )

export const getListCompany = (general_reception_id: string | number) =>
  requestGet('/user-general-reception/list-companies', {
    general_reception_id,
  })

export const getListCompanyWithPaging = (
  general_reception_id: string | number,
  page: number = FIRST_PAGE,
  keyword: string | number = ''
) =>
  requestGet('/user-general-reception/page-companies', {
    general_reception_id,
    keyword,
    page,
    per_page: PER_PAGE,
  })

export const findUserInfoGeneral = (email: string, type_message: string) =>
  requestGet('/user-general-reception/find_user_info_general', {
    email,
    type_message,
  })
