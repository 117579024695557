import { createSelector } from 'reselect'
import _isEmpty from 'lodash/isEmpty'

import { context } from './constants'

const receptionCustomSelectors = (state) => state.get(context)

export const warehouseIdSelectors = () =>
  createSelector(receptionCustomSelectors, (state) => state.warehouseId)

export const tenantIdSelectors = () =>
  createSelector(receptionCustomSelectors, (state) => state.tenantId)

export const warehousesSelectors = () =>
  createSelector(receptionCustomSelectors, (state) => state.warehouses)

export const tenantsSelectors = () =>
  createSelector(receptionCustomSelectors, (state) => state.tenants)

export const canGeneralCustomizeSettingSelectors = () =>
  createSelector(tenantsSelectors(), tenantIdSelectors(), (tenants, tenantId) => {
    if (!_isEmpty(tenants) && tenantId) {
      const tenant = tenants.find((item) => item?.id === tenantId)
      return tenant?.generalCustomizeSetting
    }
    return false
  })

export const waitingSettingSelectors = () =>
  createSelector(receptionCustomSelectors, (state) => state.waitingSetting)

export const initialWaitingSettingSelectors = () =>
  createSelector(receptionCustomSelectors, (state) => state.initialWaitingSetting)
