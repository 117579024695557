import axios from 'axios'
import _ from 'lodash'

import { NOTICE_REVISION_TXT_URL, NOTICES_JSON_URL } from 'constants/misc'
import { EnvironmentNames } from 'constants/App'

const INTERVAL = process.env.REACT_APP_ENV === EnvironmentNames.LOCAL ? 1000 * 20 : 1000 * 60

const NOTICE_REVISION_KEY = 'notice-revision'
const NOTICE_READ_IDS_KEY = 'notice-read-ids'

export const NoticeStorage = {
  getRevision() {
    const item = localStorage.getItem(NOTICE_REVISION_KEY)
    return item ? Number(item) : 0
  },

  setRevision(revision) {
    localStorage.setItem(NOTICE_REVISION_KEY, String(revision))
  },

  // 既読済みの notice id を取得する
  getReadIds() {
    const item = localStorage.getItem(NOTICE_READ_IDS_KEY)
    return item ? JSON.parse(item) : {}
  },

  addReadId(noticeId) {
    const ids = NoticeStorage.getReadIds()
    const newIds = { ...ids, [noticeId]: true }
    localStorage.setItem(NOTICE_READ_IDS_KEY, JSON.stringify(newIds))
    return newIds
  },
}

const watchNotice = async (callback, countNotices) => {
  const t = Date.now()

  try {
    let revision = 0

    // お知らせがゼロ件の場合は、必ず notices.json を取得する
    if (countNotices()) {
      const resp1 = await axios.get(`${NOTICE_REVISION_TXT_URL}?t=${t}`, null, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      revision = _.chain(resp1.data).trim().toNumber().value()

      if (Number.isNaN(revision) || revision <= NoticeStorage.getRevision()) {
        throw new Error('do nothing')
      }
    }

    // インクリメントされていたら json を取得
    const resp2 = await axios.get(`${NOTICES_JSON_URL}?t=${t}`, null, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const readIds = NoticeStorage.getReadIds()
    const newNotices = _.chain(resp2.data)
      .map((notice) => ({
        ...notice,
        read: readIds[notice.id] || false,
        showPopup: _.isNil(notice.showPopup) ? true : notice.showPopup,
      }))
      .sortBy((item) => item.datetime)
      .reverse()
      .value()

    // store に保存する
    callback(newNotices, revision)

    if (revision) {
      NoticeStorage.setRevision(revision)
    }
  } catch (e) {
    // do nothing
  }

  setTimeout(() => {
    watchNotice(callback, countNotices)
  }, INTERVAL)
}

export default watchNotice
