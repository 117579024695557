import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, useRouteMatch, Prompt } from 'react-router-dom'
import { Button } from 'reactstrap'
import _ from 'lodash'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { camelizeKeys } from 'humps'

import * as S from './styles'
import {
  PARAMETER_NAME_LENGTH,
  OPTION_NAME_LENGTH,
  ReceptionCustomOptionGroups,
  ADDITIONAL_COLUMN_KEYS,
} from 'containers/IntegratedReceptionCustom/constants'
import { Input } from 'containers/Reception/components/utils'
import Switch from 'components/Switch'
import StyledSelect from 'containers/IntegratedReceptionCustom/components/StyledSelect'
import { routes } from 'containers/IntegratedReceptionCustom/constants'
import Icon from 'components/Icon'
import {
  selectParameterSelectors,
  generalReceptionSelectedSelectors,
} from 'containers/IntegratedReceptionCustom/selectors'
import {
  updateParameter,
  startLoading,
  endLoading,
  fetchGeneralReceptions,
} from 'containers/IntegratedReceptionCustom/reducer'
import { openError, openToast } from 'containers/App/reducers'

import { trimData } from 'utils/commonFunctions'
import { TenantParameterTypes } from 'containers/IntegratedReceptionCustom/constants'
import {
  getReceptionOptions,
  patchReceptionOptions,
} from 'containers/IntegratedReceptionCustom/api'
import FinishToast from 'containers/IntegratedReceptionCustom/components/FinishToast'
import {
  GeneralReceptionType,
  ReceptionOptionType,
} from 'containers/IntegratedReceptionCustom/type'
const READ_ONLY_VEHICLE_CATEGORY = 8
const READ_ONLY_PACKAGE_TYPE = 10

const formatTypes = [TenantParameterTypes.TEXT.key, TenantParameterTypes.SELECT.key].map(
  (key: string) => {
    return {
      value: TenantParameterTypes[key].id,
      label: TenantParameterTypes[key].label,
    }
  }
)

interface selectedParameter {
  id: number
  displayName: string
  type: number
}

interface OptionGroup {
  id: number
  key: string
}
export interface IOptionsPageProps {
  selectedParameter: selectedParameter
  generalSelected: GeneralReceptionType
  updateParameter: (params: selectedParameter) => void
  startLoading: () => void
  endLoading: () => void
  openError: (message: string, useNetworkError: boolean) => void
  openToast: (params: any) => void
  fetchGeneralReceptions: () => void
}

interface mathParams {
  option: string
}

function OptionPage({
  selectedParameter,
  generalSelected,
  updateParameter,
  startLoading,
  endLoading,
  openError,
  openToast,
  fetchGeneralReceptions,
}: IOptionsPageProps) {
  const [options, setOptions] = useState<ReceptionOptionType[]>([])
  const [initialParameter, setInitialParameter] = useState<selectedParameter>()
  const [initialOptions, setInitialOptions] = useState<ReceptionOptionType[]>([])

  const [canEditParameter, setCanEditParameter] = useState<boolean>(false)
  const [optionGroup, setOptionGroup] = useState<OptionGroup>()

  const match = useRouteMatch<mathParams>()
  const history = useHistory()

  useEffect(() => {
    if (!generalSelected?.id || !selectedParameter.id) {
      return history.push(routes.itemsPage)
    }
    setInitialParameter({ ...selectedParameter })
    const { option } = match.params
    const key = option.replace('-', '_').toUpperCase()

    setOptionGroup({
      id: ReceptionCustomOptionGroups[key].id,
      key,
    })

    if (key) {
      setCanEditParameter(ADDITIONAL_COLUMN_KEYS.includes(key))
    }

    ;(async () => {
      startLoading()
      try {
        const resp = await getReceptionOptions({
          generalReceptionId: generalSelected?.id,
          optionGroupId: ReceptionCustomOptionGroups[key].id,
        })
        const data = camelizeKeys(resp?.data?.options) as unknown as ReceptionOptionType[]
        setOptions(data)
        setInitialOptions(data)
      } catch (error) {
        openError(error.message, false)
        return
      } finally {
        endLoading()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeParameterType = (option: any) => {
    updateParameter({ ...selectedParameter, type: option.value })
  }

  const onChangeParameterName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    updateParameter({ ...selectedParameter, displayName: value })
  }

  const onChangeVisibility = (item: ReceptionOptionType) => {
    const foundIndex = options.findIndex((option) => option?.id === item.id)
    const newOptions = [...options]
    newOptions[foundIndex] = {
      ...item,
      displayFlag: !item.displayFlag,
    }
    setOptions(newOptions)
  }

  const onChangeOptionName = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: ReceptionOptionType
  ) => {
    const { value } = e?.target
    const newOptions = [...options]
    const foundIndex = options.findIndex((option) => option.id === item.id)

    newOptions[foundIndex] = {
      ...item,
      parameterName: value,
      displayFlag: Boolean(value.length),
    }

    setOptions(newOptions)
  }

  const onClickRemove = (item: ReceptionOptionType) => {
    const foundIndex = options.findIndex((option) => option.id === item.id)

    const newOptions = [...options]
    newOptions[foundIndex] = {
      ...item,
      parameterName: '',
      displayFlag: false,
    }

    setOptions(newOptions)
  }
  const onClickRegister = async () => {
    let changedOptions: ReceptionOptionType[] = []
    const selectedParameterTrim = trimData(selectedParameter)
    // 項目名は空にできない
    if (!selectedParameterTrim.displayName) {
      openError('項目名は空にできません', false)
      return
    }

    if (
      [TenantParameterTypes.READ_ONLY.id, TenantParameterTypes.SELECT.id].includes(
        selectedParameter.type
      )
    ) {
      changedOptions = _.differenceWith(options, initialOptions, _.isEqual)
      changedOptions = changedOptions.map((option) => trimData(option))
      if (!options.concat(changedOptions).some((option) => option.displayFlag)) {
        openError('選択肢は1つ以上必要です', false)
        return
      }

      if (changedOptions.some((option) => !option.parameterName && option.displayFlag)) {
        openError('選択肢の名称を未入力で表示にはできません', false)
        return
      }
    }

    const data = {
      generalReceptionId: generalSelected?.id,
      optionGroupId: optionGroup?.id,
      options: changedOptions,
      parameter: selectedParameterTrim,
    }

    startLoading()

    try {
      await patchReceptionOptions(data)
    } catch (error) {
      openError(error.message, false)
      return
    } finally {
      endLoading()
    }

    setInitialParameter(selectedParameter)
    setInitialOptions(options)
    fetchGeneralReceptions()
    openToast(FinishToast)
    history.push(routes.itemsPage)
  }
  return (
    <S.OptionPageContainer>
      <S.Wrapper>
        <S.TextTile>
          {optionGroup?.key ? ReceptionCustomOptionGroups[optionGroup.key]?.name : ''}
        </S.TextTile>
        <div className="mb-3">
          追加選択肢を作成し、表示したい
          {optionGroup?.key ? ReceptionCustomOptionGroups[optionGroup.key]?.name : ''}
          を選択ください。
        </div>
        {canEditParameter && (
          <>
            <div className="d-flex align-items-center mb-3">
              <S.LabelInput>項目名</S.LabelInput>
              <S.Input
                type="text"
                placeholder="入力してください"
                value={selectedParameter.displayName || ''}
                maxLength={PARAMETER_NAME_LENGTH}
                style={{ width: 200 }}
                onChange={onChangeParameterName}
              />
            </div>
            <div className="d-flex align-items-center mb-3">
              <S.LabelInput>形式</S.LabelInput>
              <div style={{ width: 200 }}>
                <StyledSelect
                  value={_.find(formatTypes, { value: selectedParameter.type })}
                  options={formatTypes}
                  onChange={onChangeParameterType}
                />
              </div>
            </div>
          </>
        )}
        <S.SwitchsWrapper>
          {!_.isEmpty(options) &&
            [TenantParameterTypes.READ_ONLY.id, TenantParameterTypes.SELECT.id].includes(
              selectedParameter.type
            ) &&
            options.map((option) => (
              <S.SwitchsContainer key={option.id}>
                <Switch
                  checked={option.displayFlag}
                  onChange={() => onChangeVisibility(option)}
                  className="mr-3"
                />

                {[READ_ONLY_VEHICLE_CATEGORY, READ_ONLY_PACKAGE_TYPE].includes(option.codeId) ? (
                  <span>{option.parameterName}</span>
                ) : (
                  <>
                    <Input
                      type="text"
                      value={option.parameterName || ''}
                      maxLength={OPTION_NAME_LENGTH}
                      style={{ width: 360 }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChangeOptionName(e, option)
                      }}
                    />
                    {option.parameterName && (
                      <S.CustomButton
                        onClick={() => {
                          onClickRemove(option)
                        }}
                      >
                        <Icon src="/assets/svgs/circle-minus.svg" width={26} height={26} />
                      </S.CustomButton>
                    )}
                  </>
                )}
              </S.SwitchsContainer>
            ))}
        </S.SwitchsWrapper>
      </S.Wrapper>

      <S.WrapperButton>
        <NavLink to={routes.itemsPage}>
          <Button outline color="primary" className="text-primary bg-white rounded-pill px-4">
            もどる
          </Button>
        </NavLink>
        {canEditParameter ? (
          <Button
            color="primary"
            className="rounded-pill px-4"
            onClick={onClickRegister}
            disabled={
              _.isEqual(initialParameter, selectedParameter) && _.isEqual(initialOptions, options)
            }
          >
            登録
          </Button>
        ) : (
          <Button
            color="primary"
            className="rounded-pill px-4"
            onClick={onClickRegister}
            disabled={_.isEqual(initialOptions, options)}
          >
            登録
          </Button>
        )}
      </S.WrapperButton>
      <Prompt
        when={
          canEditParameter
            ? !_.isEqual(initialParameter, selectedParameter) || !_.isEqual(initialOptions, options)
            : !_.isEqual(initialOptions, options)
        }
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
    </S.OptionPageContainer>
  )
}

const mapStateToProps = createStructuredSelector({
  selectedParameter: selectParameterSelectors(),
  generalSelected: generalReceptionSelectedSelectors(),
})

const mapDispatchToProps = {
  updateParameter,
  startLoading,
  endLoading,
  openToast,
  openError,
  fetchGeneralReceptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionPage)
