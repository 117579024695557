import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const WIDTH = 200

const Outer = styled.div`
  position: fixed;
  top: 100px;
  z-index: 200;
  animation: toast-fadeinout 4s linear forwards;
  color: white;
  background-color: rgba(17, 32, 64, 0.8);
  border-radius: 12px;
`

export default ({ content, closeToast }) => {
  setTimeout(closeToast, 4000)

  return _.isString(content) ? (
    <Outer
      className="p-3 text-center"
      style={{
        width: WIDTH,
        left: window.innerWidth / 2 - WIDTH / 2,
      }}
    >
      {content}
    </Outer>
  ) : (
    <Outer
      style={{
        width: 160,
        left: window.innerWidth / 2 - 160 / 2,
      }}
    >
      {content}
    </Outer>
  )
}
