import React, { useState, useMemo, memo, useEffect, useRef } from 'react'
import _ from 'lodash'
import { Helmet } from 'react-helmet'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import Header from 'components/Layout/Header'
import Select from 'components/SelectOptions'
import StyledSelect from 'containers/IntegratedManagementPage/components/StyledSelect'
import {
  DownloadTypes,
  LIMIT_DAYS_DOWNLOAD_CSV,
  STANDARD_PLAN_PERIOD_YEAR,
  optionEnCodingsDownloadCsv,
} from 'constants/misc'
import ConfirmModal from 'components/ConfirmModal'
import LoadingIndicator from 'components/LoadingIndicator'

import {
  reducer,
  fetchDataGeneralReceptions,
  updateSelectedGeneralReception,
  downLoadGeneralCsv,
  resetStateDownloadGeneral,
} from './reducer'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import saga from './saga'
import {
  loadingSelectors,
  generalReceptionSelectors,
  selectedGeneralReceptionSelectors,
  isLoadingGeneralSuccessSelectors,
} from './selectors'
import { context, FORMAT_DATE_OF_GENERAL_CSV_DOWNLOAD, HEADER_LANGUAGE } from './constants'
import * as S from './style'
import { GeneralReceptionType, GeneralReceptionOptionType, DownloadCsvRequest } from './type'
import { userDataSelectors, receptionDataSelectors } from 'containers/App/selectors'
import errorMessages from 'utils/errorMessages'
import { LOCAL_STORAGE_GENERAL_RECEPTION_ID } from 'containers/IntegratedManagementPage/constants'

type Props = {
  userData: Object
  receptionData: Object
  isLoadingGeneralSuccess: boolean
  loading: boolean
  generalReceptions: GeneralReceptionType[]
  selectedGeneralReception: GeneralReceptionType
  fetchDataGeneralReceptions: (type: string) => void
  updateSelectedGeneralReception: (data: GeneralReceptionType) => void
  downLoadGeneralCsv: (data: DownloadCsvRequest) => void
  showFlashMessage: (data: Object) => void
  resetStateDownloadGeneral: () => void
}

const IntegratedDownloadCsvPage: React.FC<Props> = ({
  userData,
  receptionData,
  loading,
  isLoadingGeneralSuccess,
  generalReceptions,
  selectedGeneralReception,
  fetchDataGeneralReceptions,
  updateSelectedGeneralReception,
  downLoadGeneralCsv,
  showFlashMessage,
  resetStateDownloadGeneral,
}) => {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(moment().toDate())
  const [endDate, setEndDate] = useState<Date>(moment().toDate())
  const [isDeletedIncluded, setIsDeletedIncluded] = useState<boolean>(false)
  const [isFutureIncluded, setIsFutureIncluded] = useState<boolean>(false)
  const [encodingType, setEncodingType] = useState<string>('Shift-JIS')
  const endDatePickerRef = useRef<any>(null)
  const history = useHistory()
  const { type } = useParams<{
    type: string
  }>()

  const isValidDate = useMemo(() => {
    return (date: Date): boolean => {
      if (!date) return false

      const minDate = moment().subtract(STANDARD_PLAN_PERIOD_YEAR, 'years').startOf('year').toDate()
      const maxDate = moment().toDate()

      return moment(date).isBetween(minDate, maxDate, 'day', '[]')
    }
  }, [])

  const isOverDate = useMemo(() => {
    return isFutureIncluded
      ? false
      : moment(endDate).diff(startDate, 'days') > LIMIT_DAYS_DOWNLOAD_CSV
  }, [endDate, isFutureIncluded, startDate])

  const isDisabledButton = useMemo<boolean>(() => {
    if (!isValidDate(startDate) || !isValidDate(endDate)) return true

    if (isFutureIncluded) return false

    if (isOverDate) return true

    return moment(endDate).isBefore(startDate, 'day')
  }, [isValidDate, startDate, endDate, isFutureIncluded, isOverDate])

  const generalReceptionsOption = useMemo<GeneralReceptionOptionType[]>(() => {
    if (!_.isArray(generalReceptions)) return []

    return generalReceptions.map((reception) => ({
      value: reception.id,
      label: reception.name,
      ...reception,
    }))
  }, [generalReceptions])

  const selectedOptions = useMemo<Partial<GeneralReceptionOptionType>>(() => {
    if (!selectedGeneralReception.id)
      return {
        value: '',
        label: '',
      }

    return {
      value: selectedGeneralReception.id,
      label: selectedGeneralReception.name,
      ...selectedGeneralReception,
    }
  }, [selectedGeneralReception])

  const dayPickerConfig = useMemo(
    () => ({
      localeUtils: MomentLocaleUtils,
      locale: 'ja',
      firstDayOfWeek: 0,
      captionElement: ({ date }: { date: Date }) => (
        <div className="DayPicker-Caption" role="heading">
          <div>{moment(date).format('YYYY年 MM月')}</div>
        </div>
      ),
      disabledDays: {
        before: moment().subtract(STANDARD_PLAN_PERIOD_YEAR, 'years').startOf('year').toDate(),
        after: moment().toDate(),
      },
    }),
    []
  )

  const customInputDayPicker = useMemo(
    () => ({
      style: { width: 120, fontSize: 14, color: '#434D63' },
    }),
    []
  )

  useEffect(() => {
    fetchDataGeneralReceptions(type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  // Have not permission when
  // - All general reception is off
  // - Nothing is assign
  // - If the actual download page, the use isn't admin general or general admin
  useEffect(() => {
    const { is_warehouse_admin, is_general_manager } = _.get(userData, 'attributes', {})
    const isExistsReception = _.get(receptionData, 'is_exists_reception', false)

    if (
      (!_.isEmpty(receptionData) && !isExistsReception) ||
      (type === DownloadTypes.ACTUAL.label &&
        !_.isEmpty(userData) &&
        !(is_warehouse_admin || is_general_manager))
    ) {
      showFlashMessage({
        message: errorMessages.Unauthorized(),
        isError: true,
      })
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, receptionData, type])

  useEffect(() => {
    if (isLoadingGeneralSuccess && generalReceptions.length === 0) {
      showFlashMessage({
        message: 'データーがありません。',
        isError: true,
      })
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, isLoadingGeneralSuccess, generalReceptions])

  useEffect(() => {
    return () => {
      resetStateDownloadGeneral()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (endDatePickerRef.current && isFutureIncluded) {
      //@ts-ignore
      endDatePickerRef.current.state.typedValue = ''
    }
  }, [isFutureIncluded])

  const handleYesterdayClick = () => {
    const date = moment().subtract(1, 'days').toDate()
    setStartDate(date)
    setEndDate(date)

    if (isFutureIncluded) setIsFutureIncluded(false)
  }

  const handleTodayClick = () => {
    const date = moment().toDate()
    setStartDate(date)
    setEndDate(date)

    if (isFutureIncluded) setIsFutureIncluded(false)
  }

  const handleLastMonthClick = () => {
    const startDate = moment().subtract(1, 'months').startOf('month').toDate()
    const endDate = moment().subtract(1, 'months').endOf('month').toDate()
    setStartDate(startDate)
    setEndDate(endDate)

    if (isFutureIncluded) setIsFutureIncluded(false)
  }

  const handleCurrentMonthClick = () => {
    const startDate = moment().startOf('month').toDate()
    const endDate = moment().toDate()
    setStartDate(startDate)
    setEndDate(endDate)

    if (isFutureIncluded) setIsFutureIncluded(false)
  }

  const handleDownloadCsv = () => {
    if (!isDisabledButton) {
      const parameters: DownloadCsvRequest = {
        startDate: moment(startDate).format(FORMAT_DATE_OF_GENERAL_CSV_DOWNLOAD),
        endDate: isFutureIncluded
          ? null
          : moment(endDate).format(FORMAT_DATE_OF_GENERAL_CSV_DOWNLOAD),
        generalReceptionCsvId: selectedGeneralReception.generalReceptionCsvId,
        isDeletedIncluded,
        downloadType: _.get(_.find(DownloadTypes, ['label', type]), 'value'),
        encodingType,
        headerLanguage: HEADER_LANGUAGE,
      }
      downLoadGeneralCsv(parameters)
      setIsOpenModalConfirm(false)
    }
  }

  if (loading)
    return (
      <div className="w-100 h-100vh flex-center">
        <LoadingIndicator isRelative />
      </div>
    )

  return (
    <div className="h-100vh">
      <Helmet>
        <title>トラック簿 - バース管理マスタ</title>
        <meta name="description" content="Monoful Berth Warehouses" />
      </Helmet>
      <Header>
        <StyledSelect
          options={generalReceptionsOption}
          onChange={(data: GeneralReceptionOptionType) => {
            updateSelectedGeneralReception(_.omit(data, ['value', 'label']))
            //@ts-ignore
            localStorage.setItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID, data.value)
          }}
          width="180px"
          value={selectedOptions}
        />
      </Header>
      <div style={{ width: 400, margin: '30px auto 0' }}>
        <h3 className="font-weight-bold mb-4">出力期間</h3>
        <div className="mb-4">
          <S.FormWrapper style={{ alignItems: 'center' }}>
            <S.LabelDayPicker>開始日</S.LabelDayPicker>
            <DayPickerInput
              inputProps={customInputDayPicker}
              value={startDate}
              style={{ width: 130 }}
              dayPickerProps={dayPickerConfig}
              onDayChange={(date: Date) => {
                setStartDate(date)
              }}
              onDayPickerHide={() => {
                if (!isValidDate(startDate)) setStartDate(moment().toDate())
              }}
            />
          </S.FormWrapper>
          <S.FormWrapper style={{ alignItems: 'center' }}>
            <S.LabelDayPicker>終了日</S.LabelDayPicker>
            <DayPickerInput
              ref={endDatePickerRef}
              inputProps={customInputDayPicker}
              value={isFutureIncluded ? '' : endDate}
              style={{ width: 130 }}
              dayPickerProps={dayPickerConfig}
              onDayChange={(date: Date) => {
                if (isFutureIncluded) setIsFutureIncluded(false)
                setEndDate(date)
              }}
              onDayPickerHide={() => {
                if (!isValidDate(endDate)) setEndDate(moment().toDate())
              }}
            />
            <input
              type="checkbox"
              id="future-included"
              className="mr-2"
              checked={isFutureIncluded}
              onChange={(event: any) => {
                setIsFutureIncluded(event.target.checked)
              }}
            />
            <S.Label htmlFor="future-included">先日付を含む</S.Label>
          </S.FormWrapper>
          {isOverDate ? <p style={{ color: '#E11D57' }}>90日間以内選択してください。</p> : ''}
        </div>
        <div className="mb-4">
          <S.Button className="mr-2" onClick={handleYesterdayClick}>
            昨日
          </S.Button>
          <S.Button className="mr-2" onClick={handleTodayClick}>
            今日
          </S.Button>
          <S.Button className="mr-2" onClick={handleLastMonthClick}>
            先月
          </S.Button>
          <S.Button className="mr-2" onClick={handleCurrentMonthClick}>
            今月
          </S.Button>
        </div>
        <div>
          <input
            type="checkbox"
            id="unassign-included"
            className="mr-2"
            checked={isDeletedIncluded}
            onChange={(event: any) => {
              setIsDeletedIncluded(event.target.checked)
            }}
          />
          <S.Label htmlFor="unassign-included">削除済みを含む</S.Label>
        </div>
        <S.WrapperSelect>
          <Select
            name="encoding-type"
            label="文字コード"
            options={optionEnCodingsDownloadCsv}
            selectValue={encodingType}
            handleUpdateData={(value: string) => setEncodingType(value)}
            width={'140px'}
          />
        </S.WrapperSelect>
        <div className="mt-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <S.DownloadCsvButton
            onClick={() => {
              setIsOpenModalConfirm(true)
            }}
            disabled={isDisabledButton}
            className="btn btn-sm"
          >
            CSVダウンロード
          </S.DownloadCsvButton>
        </div>
        <ConfirmModal
          title="CSVファイルをダウンロードしますか？<br />ダウンロードには時間がかかる場合があります。"
          width={470}
          isOpen={isOpenModalConfirm}
          handleCancel={() => {
            setIsOpenModalConfirm(false)
          }}
          handleConfirm={handleDownloadCsv}
        />
      </div>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  loading: loadingSelectors(),
  isLoadingGeneralSuccess: isLoadingGeneralSuccessSelectors(),
  generalReceptions: generalReceptionSelectors(),
  selectedGeneralReception: selectedGeneralReceptionSelectors(),
  userData: userDataSelectors(),
  receptionData: receptionDataSelectors(),
})

const mapDispatchToProps = {
  fetchDataGeneralReceptions,
  updateSelectedGeneralReception,
  downLoadGeneralCsv,
  showFlashMessage,
  resetStateDownloadGeneral,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: context, reducer })
const withSaga = injectSaga({ key: context, saga, mode: null })

export default compose(memo, withReducer, withSaga, withConnect)(IntegratedDownloadCsvPage)
