import React, { useState, useMemo, useCallback, ChangeEvent, memo, useEffect } from 'react'

import * as S from '../style'
import NumericKeypad from './NumericKeypad'
import PrevButton from 'components/PrevButton'
import { filterNumber } from '../constants'

type InputNumberType = {
  left: string
  center: string
  right: string
}

export const InputNumberModalSize = {
  SMALL: 'sm',
  MEDIUM: 'md',
} as const

export type OptionInputNumberType = {
  initValue?: string
  title: string
  maxLengthLeft?: number
  maxLengthCenter?: number
  maxLengthRight?: number
  maxLength?: number
  isOpen: boolean
  isOneInput?: boolean
  isShowPhoneNumberPrefix?: boolean
  size?: 'sm' | 'md'
  onClickEnter: (value: string) => void
  onClickClose: (value: string) => void
}

export const InputNumberModal: React.FC<OptionInputNumberType> = ({
  initValue,
  title,
  maxLengthLeft,
  maxLengthCenter,
  maxLengthRight,
  maxLength,
  isOpen,
  isShowPhoneNumberPrefix,
  size,
  isOneInput,
  onClickEnter,
  onClickClose,
}) => {
  const [inputNumber, setInputNumber] = useState<InputNumberType>({
    left: '',
    center: '',
    right: '',
  })

  useEffect(() => {
    if (typeof initValue === 'undefined') return

    if (isOpen && isOneInput && maxLength) {
      setInputNumber({
        left: initValue.slice(0, maxLength),
        center: '',
        right: '',
      })
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isOneInput, maxLength, initValue])

  const inputNumberValue = useMemo<string>(
    () => `${inputNumber.left}${inputNumber.center}${inputNumber.right}`,
    [inputNumber]
  )

  const maxLengthInputNumber = useMemo<number>(() => {
    if (!maxLengthLeft || !maxLengthCenter || !maxLengthRight) return 0
    return maxLengthLeft + maxLengthCenter + maxLengthRight
  }, [maxLengthCenter, maxLengthLeft, maxLengthRight])

  const onChangeInputNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: keyof InputNumberType) => {
      const { value } = e.target
      setInputNumber({
        ...inputNumber,
        [key]: filterNumber(value),
      })
    },
    [inputNumber]
  )

  const onClickNumberKeyBoard = useCallback(
    (value: string) => {
      const { left, center, right } = inputNumber

      if (isOneInput && maxLength && left.length < maxLength) {
        setInputNumber({
          ...inputNumber,
          left: `${left}${value}`,
        })
        return
      }
      if (!maxLengthLeft || !maxLengthCenter || !maxLengthRight) return

      let num: { value: string; key: keyof InputNumberType } | null = null

      if (left.length < maxLengthLeft) {
        num = { value: `${left}${value}`, key: 'left' }
      } else if (center.length < maxLengthCenter) {
        num = { value: `${center}${value}`, key: 'center' }
      } else if (right.length < maxLengthRight) {
        num = { value: `${right}${value}`, key: 'right' }
      }

      if (num) {
        setInputNumber({
          ...inputNumber,
          [num.key]: num.value,
        })
      }
    },
    [inputNumber, isOneInput, maxLength, maxLengthCenter, maxLengthLeft, maxLengthRight]
  )

  const onClickBackSpace = useCallback(() => {
    const { left, center, right } = inputNumber

    if (isOneInput && left.length > 0) {
      setInputNumber({
        ...inputNumber,
        left: left.substring(0, left.length - 1),
      })
      return
    }

    let num: { value: string; key: keyof InputNumberType } | null = null

    if (right.length > 0) {
      num = { value: right.substring(0, right.length - 1), key: 'right' }
    } else if (center.length > 0) {
      num = { value: center.substring(0, center.length - 1), key: 'center' }
    } else {
      num = { value: left.substring(0, left.length - 1), key: 'left' }
    }

    if (num) {
      setInputNumber({
        ...inputNumber,
        [num.key]: num.value,
      })
    }
  }, [inputNumber, isOneInput])

  return (
    <S.CustomModal isOpen={isOpen} size={size}>
      <S.HeaderModal>
        <S.HeaderBox>
          <PrevButton onClick={() => onClickClose(inputNumberValue)} />
        </S.HeaderBox>
        <S.HeaderCenterBox>
          <span>{title}</span>
        </S.HeaderCenterBox>
        <S.HeaderBox />
      </S.HeaderModal>
      {isOneInput ? (
        <S.CustomInput
          type="text"
          value={inputNumber.left}
          maxLength={maxLength}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputNumber(e, 'left')}
        />
      ) : (
        <S.WrapperInput>
          <S.Input
            type="text"
            value={inputNumber.left}
            maxLength={maxLengthLeft}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputNumber(e, 'left')}
          />
          <span style={{ fontSize: 40 }}>-</span>
          <S.Input
            type="text"
            value={inputNumber.center}
            maxLength={maxLengthCenter}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputNumber(e, 'center')}
          />
          <span style={{ fontSize: 40 }}>-</span>
          <S.Input
            type="text"
            value={inputNumber.right}
            maxLength={maxLengthRight}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeInputNumber(e, 'right')}
          />
        </S.WrapperInput>
      )}
      <NumericKeypad
        isShowPhoneNumberPrefix={isShowPhoneNumberPrefix}
        onClick={onClickNumberKeyBoard}
        onClickBackSpace={onClickBackSpace}
        onClickEnter={() => onClickEnter(inputNumberValue)}
        isDisabledNumberPrefix={isShowPhoneNumberPrefix && inputNumberValue?.length > 0}
        canClickEnter={
          isOneInput
            ? !!inputNumberValue?.length
            : inputNumberValue?.length === maxLengthInputNumber
        }
      />
    </S.CustomModal>
  )
}

export default memo(InputNumberModal)
