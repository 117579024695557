import React, { InputHTMLAttributes, memo, useRef, useState } from 'react'
import styled from 'styled-components'

import theme from 'vars/theme'
import { ReactComponent as CloseIconSVG } from 'assets/svgs/close-icon-gray.svg'
import { ReactComponent as SearchIconSVG } from 'assets/svgs/search.svg'
import Button from 'components/Button'

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 5px;
  background-color: white;
  border-radius: 3px;
  flex: 1;
`

export const SearchIcon = styled(SearchIconSVG)`
  cursor: pointer;
  position: absolute;
  left: 6px;
  width: 24px;
  height: 24px;
  border: none;
`

export const CloseIcon = styled(CloseIconSVG)`
  cursor: pointer;
  position: absolute;
  right: 8px;
  width: 14px;
  height: 14px;

  path {
    fill: ${theme.blackDark};
  }
`

export const Input = styled.input`
  border: 1px solid ${theme.graySoft2};
  border-radius: 3px;
  font-size: 13px;
  height: 38px;
  padding: 0px 25px 0px 35px;
  width: 100%;

  &::placeholder {
    font-size: 14px;
    color: ${theme.darkBlueMedium2};
    font-weight: normal;
    opacity: 0.5;
  }

  &:focus,
  &:active {
    border: 1px solid ${theme.bluePrimary};
    outline: none;
    box-shadow: 0 0 0 1px ${theme.bluePrimary};
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => `${width}px`};
  height: 38px;
`

export const SearchButton = styled(Button)`
  color: ${theme.whitePrimary};
  background: ${theme.blueMedium7};
  border-radius: 8px;
  font-size: 13px;
  margin-left: 10px;

  &:hover {
    background: ${theme.blueMedium7};
    color: ${theme.whitePrimary};
  }
`

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  width?: number
  value: string
  disableButton?: boolean
  onClickSearch: () => void
  onClickSearchIcon?: () => void
  onClickClearSearch?: () => void
}

const SearchDriverForm: React.FC<Props> = ({
  width = 350,
  value,
  disableButton = false,
  onClickSearchIcon,
  onClickClearSearch,
  onClickSearch,
  ...rest
}) => {
  const btnRef = useRef<HTMLButtonElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  const onBlurInputSearch = (e: React.FocusEvent<HTMLInputElement>) => {
    if (btnRef.current && btnRef.current.contains(e?.relatedTarget as Node)) {
      onClickSearch()
    }
    setIsFocus(false)
  }

  const handleClickClearSearch = () => {
    onClickClearSearch?.()
    inputRef?.current?.focus()
  }

  return (
    <Wrapper width={width}>
      <InputWrapper>
        <SearchIcon onClick={onClickSearchIcon} />
        <Input
          type="text"
          autoComplete="off"
          className="input-search"
          value={value}
          onBlur={onBlurInputSearch}
          onFocus={() => setIsFocus(true)}
          ref={inputRef}
          {...rest}
        />
        {value && <CloseIcon onClick={handleClickClearSearch} alt="close search icon" />}
      </InputWrapper>
      <Button
        type="submit"
        color="blue"
        onClick={() => onClickSearch()}
        disabled={!isFocus && disableButton}
        innerRef={btnRef}
      >
        検索
      </Button>
    </Wrapper>
  )
}

export default memo(SearchDriverForm)
