import React from 'react'
import { Button } from 'reactstrap'
import styled from 'styled-components'

import Checkbox from 'components/Checkbox'
import Switch from 'components/Switch'
import Icon from 'components/Icon'
import { ADDITIONAL_COLUMNS_RANGE } from 'constants/ReceptionCustom'
import { typeRow } from '../constants'

const CustomButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
`

const EmptyCircle = styled.div`
  border: 1px solid gray;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

const importAdditionalColumns = ADDITIONAL_COLUMNS_RANGE.reduce(
  (ac, id) => ({
    ...ac,
    [`import_additional_column${id}`]: `/reception-custom/options/additional-column${id}`,
  }),
  {}
)

const EditorUrls = Object.freeze({
  // 車両形態
  vehicle_category: '/reception-custom/options/vehicle-category',
  // 荷姿
  import_package_type: '/reception-custom/options/package-type',
  // 任意項目
  ...importAdditionalColumns,
})

export default ({
  item,
  type,
  hideUpButton,
  hideDownButton,
  onClickUp,
  onClickDown,
  onChangeRequired,
  onChangeVisibility,
  onClickEdit,
  canUpdate,
}) => {
  const editorUrl = EditorUrls[item.columnName]
  const canUpdateReceptionRow = type !== typeRow.BASE && canUpdate
  return (
    <tr className="border-bottom" style={{ height: 48 }}>
      <td className="pl-3">
        {item.displayName ? item.displayName : <div style={{ color: '#CCC' }}>未設定</div>}
      </td>
      <td className="text-center">
        {/* 車両形態と荷姿のときに出す */}
        {editorUrl && (
          <Button
            color="primary"
            className="rounded-pill"
            onClick={() => {
              onClickEdit(editorUrl, item)
            }}
          >
            項目編集
          </Button>
        )}
      </td>
      <td>
        {canUpdateReceptionRow && type !== typeRow.ONLY_CHECK_BOX && (
          <div className="d-flex justify-content-center align-items-center">
            {hideDownButton ? (
              <CustomButton disabled>
                <EmptyCircle />
              </CustomButton>
            ) : (
              <CustomButton onClick={() => onClickDown(item, type)}>
                <Icon src="/assets/svgs/circle-down-arrow.svg" />
              </CustomButton>
            )}
            {hideUpButton ? (
              <CustomButton disabled>
                <EmptyCircle />
              </CustomButton>
            ) : (
              <CustomButton onClick={() => onClickUp(item, type)}>
                <Icon src="/assets/svgs/circle-up-arrow.svg" />
              </CustomButton>
            )}
          </div>
        )}
      </td>
      <td className="text-center">
        <div style={{ marginTop: -14, marginLeft: -22 }}>
          <Checkbox
            checked={type === typeRow.BASE ? true : item.requiredFlag}
            disabled={
              type !== typeRow.ONLY_CHECK_BOX && (!canUpdateReceptionRow || !item.displayFlag)
            }
            isGray={type === typeRow.BASE}
            onChange={() => onChangeRequired(item, type)}
          />
        </div>
      </td>
      <td className="d-flex justify-content-center">
        {type === typeRow.ONLY_CHECK_BOX ? (
          '-'
        ) : (
          <Switch
            checked={item.displayFlag}
            disabled={!canUpdateReceptionRow}
            onChange={() => onChangeVisibility(item, type)}
          />
        )}
      </td>
    </tr>
  )
}
