import styled from 'styled-components'
import theme from 'vars/theme'

export const ReceiptBookingContainer = styled.div`
  background-color: ${theme.graySoft6};
  width: 100%;
  min-width: ${theme.minWidth};
  height: auto;
  min-height: 420px;
  padding: 0 1rem;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  user-select: none;

  header {
    height: 89px;
  }

  main {
    height: ${(props) => `calc(${props.windowHeight}px - ${theme.headerHeight} - 89px - 68px)`};
    min-height: 200px;
    padding: 1rem 0;
  }

  .column {
    border-radius: 4px;
    padding: 16px;

    &__left {
      flex: 1 1 745px;
      background: white;
      border: 1px solid ${theme.graySoft8};
      border-radius: 12px 0px 0px 12px;
    }

    &__right {
      flex: 1 1 320px;
      background: ${theme.graySoft6};
      border: 1px solid ${theme.graySoft8};
      border-radius: 0px 12px 12px 0px;
      padding: 20px;

      hr {
        border-color: ${theme.blueSoft1};
      }

      .custom-list {
        margin: 0;
        padding: 0;

        li {
          margin-bottom: 12px;
        }

        .input-flex {
          flex: 1;
        }

        hr:last-of-type {
          display: none;
        }
      }
    }
  }

  footer {
    height: 68px;
    position: relative;
  }

  hr {
    margin: 0;
  }

  .left-button {
    position: absolute;
    left: 0;
  }

  .center-button {
    display: flex;
  }
`

export const ReceiptBookingWrapper = styled.div`
  overflow-y: auto;
`

export const BusinessContainerWrapper = styled.div`
  background-color: ${theme.whitePrimary};
  width: 100%;
  max-width: 644px;
  height: 355px;
  margin: 0 auto;
  margin-top: 50px;
  width: 600px;
  border-radius: 12px;
`

export const BusinessSelectWrapper = styled.div`
  background-color: ${theme.graySoft6};
  height: 100vh;
`

export const TitleScreen = styled.p`
  font-size: 32px;
  font-weight: bold;
  line-height: normal;
  color: ${theme.status.Confirmed};
`

export const WrapperLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1056;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
`

export const ListBookingCheckoutBox = styled.div`
  display: flex;
  justify-content: center;
`
