import _ from 'lodash'
import { decryptFunc } from 'utils/commonFunctions'
import { getStorageKeyByPathname } from 'constants/Reception'

export const getGeneralReceptionFromLocal = (generalReceptionNumber) => {
  const localStorageKey = getStorageKeyByPathname()
  const dataSessionList = decryptFunc(localStorageKey) || []

  return _.find(
    dataSessionList,
    (session) => session.generalReceptionNumber === generalReceptionNumber
  )
}
