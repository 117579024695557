import React from 'react'
import { NavLink } from 'react-router-dom'

const getSelectedTabStyles = (selected) =>
  selected
    ? {
        color: 'white',
        backgroundColor: '#247cdb',
      }
    : {}

export default ({ currentTabId }) => (
  <div className="d-flex justify-content-center my-4">
    <NavLink
      to="/reception-custom/items"
      className="px-5 border"
      style={{
        borderRadius: '30px 0 0 30px',
        ...getSelectedTabStyles(currentTabId === 'items'),
      }}
    >
      受付項目
    </NavLink>
    <NavLink
      to="/reception-custom/confirmation"
      className="px-5 border-top border-bottom border-right"
      style={getSelectedTabStyles(currentTabId === 'confirmation')}
    >
      受付伝達事項
    </NavLink>
    <NavLink
      to="/reception-custom/card-items"
      className="px-5 border-top border-bottom border-right"
      style={{
        ...getSelectedTabStyles(currentTabId === 'card-items'),
      }}
    >
      カード表示内容
    </NavLink>
    <NavLink
      to="/reception-custom/waiting"
      className="px-5 border-top border-bottom border-right"
      style={{
        borderRadius: '0 30px 30px 0',
        ...getSelectedTabStyles(currentTabId === 'waiting-booking'),
      }}
    >
      入退場画面
    </NavLink>
  </div>
)
