import RestClient, { getAuthHeader } from './RestClient'

const buildConfig = () => ({
  headers: getAuthHeader(),
  'Content-Type': 'application/octet-stream',
  responseType: 'blob',
})

export default class FileServices {
  restService: RestClient

  constructor(restService = new RestClient()) {
    this.restService = restService
  }

  getAttachmentFile = (id: string | number) =>
    this.restService.get('/berths/booking/attachment', { id }, buildConfig())
}
