import styled from 'styled-components'
import { ButtonDropdown } from 'reactstrap'

import theme from 'vars/theme'
import { downArrowStyle } from 'vars/style-utils'
import LoadingIndicator from 'components/LoadingIndicator'

const HeaderWrapper = styled.div`
  height: ${theme.headerHeight};
  width: 100%;
  min-width: ${theme.minWidth};
  display: flex;
  font-size: 20px;
  align-items: center;
  border-bottom: 1px solid #dddddd;
  background-color: #fff;
  padding: 0px 15px;

  @media (max-width: 1024px) {
    min-width: 0;
  }

  .brand {
    flex: 0 0 ${theme.sidebarWidth};
    background-color: ${theme.darkBluePrimary};
    font-size: 16px;
    padding: 0 22px;
  }

  .main {
    color: ${theme.darkBluePrimary};
    flex: 1 0 calc(${theme.minWidth} - ${theme.sidebarWidth});
    min-width: calc(${theme.minWidth} - ${theme.sidebarWidth});
    padding: 0 22px;
    background-color: ${theme.bluePrimary};

    .divider {
      border-color: white;
      border-style: solid;
      border-width: 0 1px 0 0;
      width: 20px;
      height: 32px;
    }
  }

  .dropdown-toggle:after {
    ${downArrowStyle()};
  }

  .dropdown-toggle > span {
    color: ${theme.darkBluePrimary};
  }
`

const ButtonDropdownWrapper = styled(ButtonDropdown)`
  button {
    display: flex;
    align-items: center;
  }

  button,
  button:hover,
  button:focus,
  button.dropdown-toggle {
    padding: 0;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .dropdown-menu {
    padding: 0.375rem 0.75rem;
    right: 0 !important;
    left: auto !important;
    min-width: auto;
    max-width: 100%;
    font-size: 12px;

    .dropdown-item {
      justify-content: center;
    }

    .btn-logout {
      &:hover {
        color: ${theme.blueMedium2};
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`

const LoadingIndicatorLogout = styled(LoadingIndicator)`
  background-color: #fff;
`

export { HeaderWrapper, ButtonDropdownWrapper, LoadingIndicatorLogout }
