import styled from 'styled-components'
import { FormGroup } from 'reactstrap'

import theme from 'vars/theme'
import arrowDown from 'assets/svgs/arrow-down.svg'
import arrowLeft from 'assets/svgs/arrow-left.svg'
import arrowRight from 'assets/svgs/arrow-right.svg'
import calendar from 'assets/svgs/calendar.svg'
import clock from 'assets/svgs/clock.svg'

export const FormWrapper = styled(FormGroup)`
  display: flex;
  width: ${(props) => props.width || '100%'};

  .form-check-input {
    position: relative;
    margin: 0 0.5rem 0.25rem 0;
  }

  &.form-check {
    padding-left: 0;
    position: relative;
    padding-left: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    label {
      cursor: pointer;
      color: ${({ isHightLight }) => (isHightLight ? theme.blueMedium2 : '')};
    }

    .custom-checktype {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;
      height: 18px;
      width: 18px;
      border: 1px solid ${theme.blueSoft1};
      border-radius: 2px;
      background-color: ${theme.blueSoft2};
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    /* On mouse-over, add a hover background color */
    .form-check-label:hover input:not(:checked) ~ .checkmark {
      background-color: ${theme.blueSoft1};
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: ${theme.bluePrimary};
      border: 1px solid ${theme.bluePrimary};
      border-radius: 2px;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark::after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .checkbox-disabled {
    .checkmark {
      background: ${theme.graySoft4};
      &:hover {
        background: ${theme.graySoft4} !important;
      }
    }
  }

  .form-control {
    font-size: 14px;
    color: ${theme.darkBlueMedium1};
    border: 1px solid;
    border-color: ${({ isError }) => (isError ? theme.redMedium1 : theme.graySoft2)};

    &[type='text']:disabled {
      -webkit-text-fill-color: #495057;
    }

    &:not(:only-child) {
      margin-right: 16px;
    }

    &::-webkit-input-placeholder {
      font-size: 14px;
      color: ${theme.grayMedium1};
    }

    &[type='time'] {
      background: url(${clock}) no-repeat scroll;
      background-position: right 7px top 5px;
      padding-right: 30px;

      &:not(:only-child) {
        margin-right: 25px;
      }
    }

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      border-color: ${theme.bluePrimary};
      box-shadow: 0 0 0 1px ${theme.bluePrimary};
    }
  }

  select.form-control {
    padding: 0 12px;
    background: url(${arrowDown}) no-repeat;
    background-position: right 7px top 5px;
    -webkit-appearance: none;

    &:disabled,
    &[readonly] {
      background: ${theme.grayPrimary};
      cursor: not-allowed;
    }
  }

  .DayPickerInput {
    width: 100%;
    margin-left: 0.25rem;

    &:not(:only-child) {
      margin-right: 0.25rem;
    }

    input {
      width: 100%;
      height: calc(2rem + 6px);
      border-radius: 4px;
      outline: none;
      color: ${theme.darkBlueMedium1};
      border: 1px solid ${theme.graySoft2};
      border-color: ${(props) => (props.isError ? theme.redMedium1 : '')};
      padding-left: 12px;
      background: url(${calendar}) no-repeat scroll;
      background-position: right 7px top 5px;
      cursor: pointer;

      &::-webkit-input-placeholder {
        font-size: 14px;
        color: ${theme.grayMedium1};
      }

      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        border-color: ${theme.bluePrimary};
        box-shadow: 0 0 0 1px ${theme.bluePrimary};
      }

      &:disabled {
        background-color: ${theme.graySoft5};
      }
    }
  }

  .DayPickerInput-Overlay {
    left: auto;
    right: 0;

    &.DayPicker {
      width: 100%;
      font-size: 12px;
    }

    .DayPicker {
      &-Caption {
        text-align: center;

        div {
          font-size: 14px;
        }
      }

      &-NavButton {
        top: 1rem;
        width: 50px;

        &--prev {
          background-image: url(${arrowLeft});
          left: 0;
        }

        &--next {
          background-image: url(${arrowRight});
          right: 0;
        }
      }

      &-Weekday {
        color: ${theme.darkBlueMedium1};
        padding: 6px 8px;
        font-size: 14p;
      }

      &-Day {
        padding: 4px 2px;
        font-size: 12px;

        &:nth-child(1):not(.DayPicker-Day--today) {
          color: ${theme.redMedium1};
        }

        &:nth-child(7):not(.DayPicker-Day--today) {
          color: ${theme.redMedium1};
        }

        &--disabled {
          color: ${theme.grayMedium1} !important;
        }

        &--selected {
          color: white !important;
          background-color: ${theme.blueMedium1};
        }
      }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: ${theme.blueMedium1};

      &:hover {
        background-color: ${theme.bluePrimary};
      }
    }
  }
`

export const ErrorText = styled.p`
  width: 100%;
  margin: 0.25rem 0 0 0.5rem;
  font-size: 12px;
  color: ${theme.redMedium1};
`

export const Label = styled.label`
  cursor: pointer;
  margin-bottom: 0;
  font-size: 14px;
  color: #434d63;
`

export const WrapperSelect = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  .label-flex {
    flex: 0 0 80px;
    align-self: center;
    margin-bottom: 0;
  }
`

export const Button = styled.span`
  font-size: 14px;
  background: #eef7fc;
  color: #278cb5;
  border-radius: 2px;
  cursor: pointer;
  padding: 0.5rem 1rem;
`

export const LabelDayPicker = styled.div`
  width: 80px;
  font-size: 14px;
  color: ${theme.darkBlueMedium1};
`

export const DownloadCsvButton = styled.button`
  color: white;
  font-size: 14px;
  border-radius: 2px;
  background-color: ${({ disabled }) => (disabled ? theme.graySoft2 : theme.blueMedium1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    color: white;
  }
`
