import styled from 'styled-components'
import {
  Modal as ModalDefault,
  ModalHeader as ModalHeaderDefault,
  ModalBody as ModalBodyDefault,
  ModalFooter as ModalFooterDefault,
} from 'reactstrap'

import theme from 'vars/theme'

export const Modal = styled(ModalDefault)`
  .modal-content {
    border: 0;
  }
`

export const ModalHeader = styled(ModalHeaderDefault)`
  font-size: 20px;
  padding: 8px 24px;
  border-bottom: 1px solid ${theme.darkBlueSoft1};

  .modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`

export const TitleModal = styled.h5`
  font-weight: bold;
  font-size: 18px;
`

export const CloseInHeader = styled.p`
  font-size: 16px;
`

export const ModalBody = styled(ModalBodyDefault)`
  padding: 8px 24px;
  color: ${theme.darkBlueMedium2};

  & > div {
    justify-content: flex-start !important;
    max-width: 100%;
    /* overflow-x: auto; */
  }
`

export const ModalFooter = styled(ModalFooterDefault)`
  padding: 20px;
  border-top: 0;

  l & > div {
    margin: 0;
  }
`

export const BodyWrapper = styled.div`
  max-width: 100% !important;
  font-size: 13px;
  color: black;
`

export const Wrapper = styled.div`
  background-color: ${theme.whitePrimary};
  width: 100%;
`

export const TextTile = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`

export const Th = styled.th`
  z-index: 2;
  background-color: #f0f0f0;
  padding: 16px 8px;
`

export const Table = styled.table`
  width: 100%;
`

export const ColCustom = styled.col`
  min-width: 100px;
  width: auto;

  &:nth-child(2) {
    width: 110px;
  }

  &:last-child {
    width: 140px;
  }
`

export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;

  a {
    padding: 0 50px;

    &:first-child {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
    }

    &:last-child {
      border-bottom-right-radius: 30px;
      border-top-right-radius: 30px;
    }
  }
`

export const Tr = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${theme.grayPrimary2};
`

export const Td = styled.tr`
  height: 48px;
  border-bottom: 1px solid ${theme.grayPrimary2};
`

export const CustomButton = styled.button`
  background: none;
  border: none;
  outline: none !important;
`

export const EmptyCircle = styled.div`
  border: 1px solid gray;
  border-radius: 50%;
  width: 24px;
  height: 24px;
`

export const CheckBoxContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextDisable = styled.div`
  color: ${theme.grayMedium3};
`
