import React from 'react'
import styled from 'styled-components'
import { Modal, ModalProps } from 'reactstrap'
import { Button } from 'reactstrap'

const Wrapper = styled(Modal)`
  width: ${({ width }) => `${width}px`};
  .modal-content {
    margin-top: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
`

const Title = styled.span`
  font-size: 20px;
  margin: 20px 0;
  text-align: center;
  white-space: pre-wrap;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 400px;
`

const ButtonCustom = styled(Button)<{ hasSmallBorder: boolean }>`
  min-width: 180px;
  margin-top: 20px;
  border-radius: '20px';
  font-size: 20px;
`

type Props = ModalProps & {
  title: string
  textOk: string
  width?: number
  isOpen: boolean
  handleConfirm: () => void
}

const AlertModal: React.FC<Props> = ({
  isOpen,
  textOk,
  handleConfirm,
  title,
  width = 500,
  ...rest
}) => (
  <Wrapper width={width} isOpen={isOpen} {...rest}>
    <Title className="font-weight-bold" dangerouslySetInnerHTML={{ __html: title }} />
    <ButtonGroup>
      <ButtonCustom color="primary" onClick={handleConfirm}>
        {textOk}
      </ButtonCustom>
    </ButtonGroup>
  </Wrapper>
)

export default AlertModal
