import React from 'react'

import Icon from 'components/Icon'
import moment from 'moment'
import _get from 'lodash/get'
import OutsideClickComponent from 'react-outside-click-handler'
import MomentLocaleUtils from 'react-day-picker/moment'

import { Wrapper } from './style'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

class DayPicker extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.container = React.createRef()
  }

  onDayChange = (val) => {
    this.handleCloseDayPicker()
    this.props.onDayChange(val)
  }

  getCurrentState = () => _get(this, 'inputRef.current.state.showOverlay', false)

  handleNext = () => {
    const m = moment(this.props.value)
    const val = m.clone().add(1, 'days').toDate()
    this.props.onDayChange(val)
    this.handleCloseDayPicker()
  }

  handlePrev = () => {
    const m = moment(this.props.value)
    const val = m.clone().subtract(1, 'days').toDate()
    this.props.onDayChange(val)
    this.handleCloseDayPicker()
  }

  formatDate = () => {
    const m = moment(this.props.value)
    return (
      <div role="presentation" onClick={this.handleFocus} className="value-content">
        <span className="number">{m.format('YYYY')}</span>
        <span className="text">年</span>
        <span className="number ml-1">{m.format('MM')}</span>
        <span className="text">月</span>
        <span className="number ml-1">{m.format('DD')}</span>
        <span className="text">日</span>
        <span className="day text ml-1" style={{ backgroundColor: '#EAEAEA' }}>
          {m.format('dd')}
        </span>
      </div>
    )
  }

  handleFocus = () => {
    const isShow = this.getCurrentState()
    if (!isShow) this.handleOpenDayPicker()
    else this.handleCloseDayPicker()
  }

  handleOpenDayPicker = () => {
    this.inputRef.current.showDayPicker()
  }

  handleCloseDayPicker = () => {
    this.inputRef.current.hideDayPicker()
  }

  handleOutsideClick = () => {
    const isShow = this.getCurrentState()
    if (isShow) this.handleCloseDayPicker()
  }

  renderDayCaption = ({ date }) => (
    <div className="DayPicker-Caption" role="heading">
      <div className="d-flex align-items-start justify-content-center">
        {moment(date).format('YYYY年 MM月')}
      </div>
    </div>
  )

  renderNavBar = ({ onPreviousClick, onNextClick }) => (
    <div className="nav--wrapper d-flex">
      <button className="nav-button" onClick={() => onPreviousClick()}>
        <Icon src="/assets/svgs/arrow-left-blue.svg" />
        <span className="ml-1">先月</span>
      </button>
      <button className="nav-button" onClick={() => onNextClick()}>
        <span className="mr-1">次月</span>
        <Icon src="/assets/svgs/arrow-right-blue.svg" />
      </button>
    </div>
  )

  render() {
    const { value } = this.props
    return (
      <OutsideClickComponent onOutsideClick={this.handleOutsideClick}>
        <Wrapper ref={this.container}>
          <Icon className="calendar-icon" src="/assets/svgs/calendar-blue.svg" alt="pref" />
          <div onClick={this.handlePrev} role="presentation" className="navigate prev">
            <Icon src="/assets/svgs/arrow-left-blue.svg" alt="pref" />
          </div>
          {this.formatDate()}
          <DayPickerInput
            value={value}
            onDayChange={this.onDayChange}
            ref={this.inputRef}
            keepFocus={false}
            inputProps={{ readOnly: true }}
            dayPickerProps={{
              todayButton: '今日にもどす',
              captionElement: this.renderDayCaption,
              navbarElement: this.renderNavBar,
              locale: 'ja',
              localeUtils: MomentLocaleUtils,
            }}
          />
          <div onClick={this.handleNext} role="presentation" className="navigate next">
            <Icon src="/assets/svgs/arrow-right-blue.svg" alt="pref" />
          </div>
        </Wrapper>
      </OutsideClickComponent>
    )
  }
}

export default DayPicker
