import RestClient, { getAuthHeader } from './RestClient'

const headers = () => ({
  'Content-Type': 'application/json',
  ...getAuthHeader(),
})

export default class ActualService {
  constructor(restClient = RestClient) {
    this.restClient = restClient
  }

  getWarehouseList() {
    return this.restClient.get(
      'analytics/load_warehouses',
      {},
      {
        headers: headers(),
      }
    )
  }

  getAnalyticData(params) {
    return this.restClient.get('analytics/statistic_chart', params, {
      headers: headers(),
    })
  }

  getOperationData(params) {
    return this.restClient.get('analytics/operation_time', params, {
      headers: headers(),
    })
  }

  getWaitingData(params) {
    return this.restClient.get('analytics/waiting_time', params, {
      headers: headers(),
    })
  }

  getTotalCountData(params) {
    return this.restClient.get('analytics/count', params, {
      headers: headers(),
    })
  }

  getAverageTimeFinish(params) {
    return this.restClient.get('analytics/average_time_finish_booking', params, {
      headers: headers(),
    })
  }
}
