import React from 'react'
import _ from 'lodash'

import CustomTable from 'components/CustomTable'
import CardSection from './CardSection'

export type TableHeaderType = {
  key: string
  title: string
  isHightLightItem?: boolean
}

export type Props = {
  data: any[]
  tableHeader: TableHeaderType[]
  title: string
  labelHeaderButton: string
  labelTableItemButton?: string
  isDisabledHeaderButton?: boolean
  isDisabledTableItemButton?: boolean
  withPagination?: boolean
  currentPage: number
  totalPage: number
  handleHeaderButton: () => void
  handleTableItemButton?: (data: any) => void
  handleChangePage?: (page: number) => void
}

export const TableData: React.FC<Props> = ({
  data,
  tableHeader,
  title,
  labelHeaderButton,
  labelTableItemButton,
  isDisabledHeaderButton = false,
  isDisabledTableItemButton,
  withPagination = true,
  currentPage,
  totalPage,
  handleHeaderButton,
  handleTableItemButton,
  handleChangePage,
}) => {
  return (
    <CardSection
      title={title}
      buttonLabel={labelHeaderButton}
      buttonIsDisabled={isDisabledHeaderButton}
      onClick={handleHeaderButton}
    >
      {_.isArray(data) && !!data.length ? (
        <CustomTable
          data={data}
          columns={tableHeader}
          tableHeight="268px"
          buttonColor="lightRed"
          buttonLabel={labelTableItemButton}
          buttonDisabled={isDisabledTableItemButton}
          handleRowButton={handleTableItemButton}
          meta={{
            current: currentPage,
            total: totalPage,
          }}
          onNavigateRequest={handleChangePage}
          withPagination={withPagination}
          hasButton={!!labelTableItemButton}
        />
      ) : (
        <div className="h-100 w-100 flex-center color-gray-med1">データなし!</div>
      )}
    </CardSection>
  )
}

export default React.memo(TableData)
