import { createSelector } from 'reselect'
import { context } from './constants'

const multiSettingSelector = (state: any) => state.get(context)

export const loadingSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('loading'))

export const generalReceptionsSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('generalReceptions'))

export const selectedGeneralReceptionSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('selectedGeneralReception').toJS())

export const selectedGeneralTenantsReceptionSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('selectedGeneralReceptionTenant'))

export const selectedGeneralReceptionAdminSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('selectedGeneralReceptionAdmin'))

export const selectedGeneralReceptionControllerSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('selectedGeneralReceptionController'))
