import React from 'react'
import UAParser from 'ua-parser-js'

const browser = new UAParser().getBrowser()
const isSupportedBrowser = ['Chrome', 'Firefox'].includes(browser.name)

export default ({ className = '' }) => {
  if (isSupportedBrowser) return null

  return (
    <div className={`text-danger text-center mb-5 ${className}`} style={{ fontSize: 20 }}>
      <p>トラック簿では、Google Chromeでのご利用を推奨しております。</p>
      <p>ご利用の中で何か問題等ございましたら、一度Google Chromeをお試し頂けますと幸いです。</p>
    </div>
  )
}
