import React, { memo, FC } from 'react'
import { Button } from 'reactstrap'

import DoubleArrowLeft from 'assets/svgs/double-arrow-left.svg'

type Props = {
  onClick?: () => void
}

const PrevStartButton: React.FC<Props> = ({ onClick }) => (
  <Button
    className="d-flex align-items-center rounded-pill bg-white text-primary"
    color="primary"
    outline
    onClick={() => onClick && onClick()}
  >
    <img src={DoubleArrowLeft} alt="double arrow left" className="mr-2" />
    はじめにもどる
  </Button>
)

export default memo(PrevStartButton)
