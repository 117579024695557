import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { compose } from 'redux'

import ItemsPage from './pages/ItemsPage'
import ConfirmationPage from './pages/ConfirmationPage'
import ConfirmationEditPage from './pages/ConfirmationEditPage'
import CardItemsPage from './pages/CardItemsPage'
import OptionsPage from './pages/OptionsPage'
import TimeSettingPage from './pages/TimeSettingPage'
import WaitingPage from './pages/WaitingPage'
import SettingWaitingTruckPage from './pages/SettingWaitingTruckPage'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import reducers from './reducers'
import saga from './saga'

const ReceptionCustomRouting = () => (
  <Switch>
    <Route path="/reception-custom/waiting" component={WaitingPage} />
    <Route path="/reception-custom/items" component={ItemsPage} />
    <Route path="/reception-custom/confirmation" component={ConfirmationPage} />
    <Route path="/reception-custom/confirmation-edit" component={ConfirmationEditPage} />
    <Route path="/reception-custom/card-items" component={CardItemsPage} />
    <Route path="/reception-custom/options/:optionGroup" component={OptionsPage} />
    <Route path="/reception-custom/waiting-edit" component={TimeSettingPage} />
    <Route path="/reception-custom/waiting-truck-edit" component={SettingWaitingTruckPage} />
  </Switch>
)

const withReducer = injectReducer({ key: 'receptionCustom', reducer: reducers })
const withSaga = injectSaga({ key: 'receptionCustom', saga })

export default compose(withReducer, withSaga)(ReceptionCustomRouting)
