import * as Yup from 'yup'

import errorMessage from 'utils/errorMessages'
import { MIN_LONGITUDE, MIN_LATITUDE, MAX_LATITUDE, MAX_LONGITUDE } from './constants'
import { REGEX } from 'constants/misc'

const MUST_NUMBER = '数を入力してください。'
const POSITIVE_NUMBER = '正の数を入力してください。'

export const addTenantSchema = Yup.object().shape({
  warehouseId: Yup.number()
    .typeError(MUST_NUMBER)
    .transform((value, originalVal) => (!!originalVal ? value : undefined))
    .required('倉庫名を入力してください。')
    .positive(POSITIVE_NUMBER)
    .integer(POSITIVE_NUMBER),
  tenantId: Yup.number()
    .typeError(MUST_NUMBER)
    .transform((value, originalVal) => (!!originalVal ? value : undefined))
    .required('テナント名を入力してください。')
    .positive(POSITIVE_NUMBER)
    .integer(POSITIVE_NUMBER),
  tenantName: Yup.string().trim().required('表示名を入力してください。'),
  viewOrder: Yup.number()
    .typeError(MUST_NUMBER)
    .transform((value, originalVal) => (!!originalVal ? value : undefined))
    .required('表示順を入力してください。')
    .positive(POSITIVE_NUMBER)
    .integer(POSITIVE_NUMBER),
})

export const addGeneralReceptionSchema = Yup.object().shape({
  name: Yup.string().trim().required(errorMessage.GeneralReception.nameBlank()),
  password: Yup.string()
    .trim()
    .required(errorMessage.GeneralReception.passwordBlank())
    .min(6, errorMessage.GeneralReception.passwordShort()),
  parentTenantId: Yup.number()
    .required(errorMessage.GeneralReception.parentTenantIdBlank())
    .transform((value, originalVal) => (!!originalVal ? value : undefined)),
  contactName: Yup.string().trim().nullable(),
  contactPhone: Yup.string().trim().matches(REGEX.CONTACT_PHONE, {
    excludeEmptyString: true,
    message: errorMessage.GeneralReception.contactPhoneInvalid(),
  }),
  checkinEnableDistance: Yup.number()
    .typeError(MUST_NUMBER)
    .transform((value, originalVal) => (!!originalVal ? value : undefined))
    .positive(errorMessage.GeneralReception.checkinEnableDistanceInvalid())
    .integer(errorMessage.GeneralReception.checkinEnableDistanceInvalid()),
  latitude: Yup.number()
    .typeError(MUST_NUMBER)
    .transform((value, originalVal) => (originalVal === 0 || !!originalVal ? value : undefined))
    .max(MAX_LATITUDE, errorMessage.GeneralReception.latitudeInvalid())
    .min(MIN_LATITUDE, errorMessage.GeneralReception.latitudeInvalid()),
  longitude: Yup.number()
    .typeError(MUST_NUMBER)
    .transform((value, originalVal) => (originalVal === 0 || !!originalVal ? value : undefined))
    .max(MAX_LONGITUDE, errorMessage.GeneralReception.longitudeInvalid())
    .min(MIN_LONGITUDE, errorMessage.GeneralReception.longitudeInvalid()),
  canGeneralBooking: Yup.bool(),
  canCustomizeReception: Yup.bool(),
})

export const addControllerSchema = Yup.object().shape({
  companyName: Yup.string().trim().required(errorMessage.HomePage.companyBlank()),
  name: Yup.string().trim().required(errorMessage.GeneralReception.controllerNameBlank()),
  telNumber: Yup.string().trim().matches(REGEX.CONTACT_PHONE, {
    excludeEmptyString: true,
    message: errorMessage.GeneralReception.controllerPhoneInvalid(),
  }),
  email: Yup.string()
    .trim()
    .required(errorMessage.HomePage.controllerEmailBlank())
    .matches(REGEX.EMAIL, {
      excludeEmptyString: true,
      message: errorMessage.HomePage.emailInvalid(),
    }),
})
