import React, { ChangeEvent, FC, useCallback } from 'react'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import { camelizeKeys, decamelizeKeys } from 'humps'

import * as S from '../style'
import { ColumnsType, ExportImportProps, TheadProps } from '../type'
import { ReactComponent as MonofulIcon } from 'assets/svgs/multi-booking/monoful-icon.svg'
import ascIcon from 'assets/svgs/asc-icon.png'
import descIcon from 'assets/svgs/desc-icon.png'
import sortingDefaultIcon from 'assets/svgs/sorting-default.png'
import StrikeThrough from 'containers/IntegratedManagementPage/components/StrikeThrough'
import { compareValue, formatPhoneNumber, handleFormatVehicleNumber } from 'utils/commonFunctions'
import {
  REGEX_IMPORT_EXPORT,
  REGEX_IMPORT_EXPORT_REPLACE,
  checkIsAdditionalColumn,
} from 'containers/IntegratedManagementPage/constants'

import lineApp from 'assets/pngs/line-app.png'
import { NON_VALUE_TEXT, ORDER } from '../constants'
import { BookingStatuses, statusLabel, statusValues } from 'constants/misc'
import { AttachmentType } from 'utils/types'

export const Colgroup = ({ columns }: any) => {
  return (
    <colgroup>
      <col style={{ width: 350 }} />
      <col style={{ width: 120 }} />
      <col style={{ width: 120 }} />
      {columns.map((column: ColumnsType) => (
        <col key={column?.parameter_id} />
      ))}
      <col style={{ width: 80 }} />
    </colgroup>
  )
}

export const Thead: React.FC<TheadProps> = ({
  columns,
  setRef,
  order,
  orderBy,
  handleOrderByChange,
}) => {
  const renderIconSort = useCallback(
    (columnName: string) => {
      if (orderBy !== columnName) {
        return (
          <S.IconSort
            key={columnName}
            src={sortingDefaultIcon}
            alt="default-icon"
            onClick={() => {
              handleOrderByChange(columnName)
            }}
          />
        )
      }
      switch (order) {
        case ORDER.ASC:
          return (
            <S.IconSort
              key={columnName}
              src={ascIcon}
              alt="asc-icon"
              onClick={() => {
                handleOrderByChange(columnName)
              }}
            />
          )
        case ORDER.DESC:
          return (
            <S.IconSort
              key={columnName}
              src={descIcon}
              alt="desc-icon"
              onClick={() => {
                handleOrderByChange(columnName)
              }}
            />
          )
        default:
          return (
            <S.IconSort
              key={columnName}
              src={sortingDefaultIcon}
              alt="default-icon"
              onClick={() => {
                handleOrderByChange(columnName)
              }}
            />
          )
      }
    },
    [handleOrderByChange, order, orderBy]
  )
  return (
    <thead ref={setRef}>
      <tr>
        <S.Th style={{ borderRadius: '12px 0 0 0' }} />
        <S.Th style={{ borderRadius: '12px 0 0 0' }} />

        {columns?.map((column: ColumnsType) => (
          <S.Th
            key={column.display_name}
            style={{
              whiteSpace: 'nowrap',
              padding: '12px 32px',
              textAlign: 'center',
            }}
          >
            <S.WrapperContentTable>
              <span>{column.display_name}</span>
              {renderIconSort(column.column_name)}
            </S.WrapperContentTable>
          </S.Th>
        ))}
      </tr>
    </thead>
  )
}

const ImportExportIcon: FC<ExportImportProps> = ({ importFlag, exportFlag }) => {
  const importClass = `import ${importFlag ? 'active' : ''}`
  const exportClass = `export ${exportFlag ? 'active' : ''}`
  return (
    <S.WrapperIconImportExport>
      <span className={importClass}>卸</span>
      <span className={exportClass}>積</span>
    </S.WrapperIconImportExport>
  )
}
const renderButtonListCard = (status: string | number | Array<AttachmentType>) => {
  switch (status) {
    case statusValues.Unassigned:
    case statusValues.Recept:
    case statusValues.Booking:
      return (
        <S.ButtonItem color="#247CDB" background="#FFF">
          {statusLabel.Booking}
        </S.ButtonItem>
      )
    case statusValues.Confirmed:
      return (
        <S.ButtonItem color="#FFF" background="#247CDB">
          {statusLabel.Confirmed}
        </S.ButtonItem>
      )
    case statusValues.Delay_Expected:
      return (
        <S.ButtonItem color="#EF8A67" background="#FFF">
          {statusLabel.Delay_Expected}
        </S.ButtonItem>
      )
    case statusValues.Delay:
      return (
        <S.ButtonItem color="#FFF" background="#EF8A67">
          {statusLabel.Delay}
        </S.ButtonItem>
      )
    case statusValues.Arrival:
      return (
        <S.ButtonItem color="#FFF" background="#DB2464">
          {statusLabel.Arrival}
        </S.ButtonItem>
      )
    case statusValues.Calling:
      return (
        <S.ButtonItem color="#FFF" background="#17BEBB">
          {statusLabel.Calling}
        </S.ButtonItem>
      )
    case statusValues.Working:
      return (
        <S.ButtonItem color="#17BEBB" background="#FFF">
          {statusLabel.Working}
        </S.ButtonItem>
      )
    case statusValues.Finish:
      return (
        <S.ButtonItem color="#FFF" background="#A8A9AB">
          {statusLabel.Finish}
        </S.ButtonItem>
      )
    case statusValues.All:
      return (
        <S.ButtonItem color="#FFF" background="#4D4F53">
          {statusLabel.All}
        </S.ButtonItem>
      )
    default:
      return
  }
}

const renderTextLabel = (cardData = [] as any, bookingMaster = [] as any, booking = [] as any) => {
  const vehicle_categories = _get(bookingMaster, 'vehicle_categories', [])
  const dataBooking = booking || {}
  const { import_package_type, export_package_type, vehicle_category } = dataBooking || {}
  const columnName = `${cardData?.columnName || ''}`.trim()

  const regexImportExport = new RegExp(REGEX_IMPORT_EXPORT)
  const isImportExportColumn = regexImportExport.test(columnName)

  if (isImportExportColumn) {
    const value =
      dataBooking[columnName] || dataBooking[`${columnName}`.replace('import', 'export')]
    if (
      (import_package_type || export_package_type) &&
      compareValue(columnName.replace(REGEX_IMPORT_EXPORT_REPLACE, ''), 'package_type')
    ) {
      return <S.TextLabel>{import_package_type || export_package_type}</S.TextLabel>
    }

    if (checkIsAdditionalColumn(columnName)) {
      return <S.TextLabel>{value}</S.TextLabel>
    }

    return <S.TextLabel>{value}</S.TextLabel>
  }

  if (compareValue(columnName, 'company_name')) {
    return (
      <>
        <S.TextLabel>{dataBooking?.driver_company_name || NON_VALUE_TEXT}</S.TextLabel>
        <span className="ml-1">
          {dataBooking?.vehicle_number
            ? `[${handleFormatVehicleNumber(dataBooking?.vehicle_number?.toString())}]`
            : NON_VALUE_TEXT}
        </span>
      </>
    )
  }

  if (
    !!vehicle_categories?.length &&
    vehicle_category &&
    compareValue(columnName, 'vehicle_category')
  ) {
    return <S.TextLabel>{vehicle_category}</S.TextLabel>
  }

  return <S.TextLabel>{dataBooking[`${columnName}`]}</S.TextLabel>
}

const renderPositionItem = (
  allParameters = [] as any,
  bookingMaster = [] as any,
  booking = [] as any
) => {
  const listCardViewOrder = allParameters?.filter((parameter: any) => {
    return parameter.card_view_order
  })

  const bookingPositionViewOrder = listCardViewOrder?.reduce((arr: any, item: any) => {
    return (arr = {
      ...arr,
      [`item${item.card_view_order}`]: {
        ...item,
        cardViewOrder: item?.card_view_order,
        columnName: item?.column_name,
      },
    })
  }, {})

  return (
    <S.PositionWrapper>
      <S.PositionItem>
        {bookingPositionViewOrder?.item1?.cardViewOrder && (
          <S.PositionItemElement className="booking-content">
            <S.PositionItemDescription>
              {renderTextLabel(bookingPositionViewOrder.item1, bookingMaster, booking)}
            </S.PositionItemDescription>
          </S.PositionItemElement>
        )}
        {bookingPositionViewOrder?.item4?.cardViewOrder && (
          <>
            {/* Position Element 4 */}
            {bookingPositionViewOrder?.item1 && <StrikeThrough />}
            <S.PositionItemElement className="booking-content">
              <S.PositionItemDescription>
                {renderTextLabel(bookingPositionViewOrder.item4, bookingMaster, booking)}
              </S.PositionItemDescription>
            </S.PositionItemElement>
          </>
        )}
      </S.PositionItem>

      <S.PositionItem>
        {bookingPositionViewOrder?.item2?.cardViewOrder && (
          // Position Element 2
          <S.PositionItemElement className="booking-content">
            <S.PositionItemDescription>
              {renderTextLabel(bookingPositionViewOrder.item2, bookingMaster, booking)}
            </S.PositionItemDescription>
          </S.PositionItemElement>
        )}

        {bookingPositionViewOrder?.item5?.cardViewOrder && (
          <>
            {/* Position Element 4 */}
            {bookingPositionViewOrder?.item2 && <StrikeThrough />}
            <S.PositionItemElement className="booking-content">
              <S.PositionItemDescription>
                {renderTextLabel(bookingPositionViewOrder.item5, bookingMaster, booking)}
              </S.PositionItemDescription>
            </S.PositionItemElement>
          </>
        )}
      </S.PositionItem>

      <S.PositionItem>
        {bookingPositionViewOrder?.item3?.cardViewOrder && (
          //  Position Element 3
          <S.PositionItemElement className="booking-content">
            <S.PositionItemDescription>
              {renderTextLabel(bookingPositionViewOrder.item3, bookingMaster, booking)}
            </S.PositionItemDescription>
          </S.PositionItemElement>
        )}

        {bookingPositionViewOrder?.item6?.cardViewOrder && (
          <>
            {/* Position Element 6 */}
            {bookingPositionViewOrder?.item3 && <StrikeThrough />}
            <S.PositionItemElement className="booking-content">
              <S.PositionItemDescription>
                {renderTextLabel(bookingPositionViewOrder.item6, bookingMaster, booking)}
              </S.PositionItemDescription>
            </S.PositionItemElement>
          </>
        )}
      </S.PositionItem>
    </S.PositionWrapper>
  )
}

const vehicleSummaryBookingEditor = (booking = [] as any, bookingMaster = [] as any) => {
  const baseParameters = _get(bookingMaster, 'tenant_parameters.base')
  const importParameters = _get(bookingMaster, 'tenant_parameters.import')
  const allParameters = baseParameters?.concat(importParameters)

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex' }}>
        {/* left */}
        <S.PositionWrapper>
          {renderPositionItem(allParameters, bookingMaster, decamelizeKeys(booking || {}))}
        </S.PositionWrapper>

        {/* right */}
        <div style={{ width: 67, display: 'flex', alignItems: 'center' }}>
          <S.VehicleSummaryRightWrapper>
            {/* bottom */}
            <div style={{ lineHeight: 1 }}>
              <ImportExportIcon
                importFlag={!!booking.importFlag}
                exportFlag={!!booking.exportFlag}
              />
            </div>
            <S.VehicleSummaryIconWrapper>
              {booking.lineFlag && (
                <img
                  src={lineApp}
                  alt=""
                  width={18}
                  height={18}
                  style={{
                    marginTop: '4px',
                  }}
                />
              )}
              {booking.portalFlag && (
                <MonofulIcon
                  width={18}
                  height={18}
                  style={{
                    marginLeft: 'auto',
                    marginTop: '4px',
                  }}
                />
              )}
            </S.VehicleSummaryIconWrapper>
          </S.VehicleSummaryRightWrapper>
        </div>
      </div>
    </div>
  )
}

export default ({ booking, bookingMaster, dataColumnThead, handleConfirmChangeStatus }: any) => {
  const formatColumnName = dataColumnThead?.map((parameter: any) => parameter.column_name)
  const listCartFormat = dataColumnThead.reduce((arr: any, item: any) => {
    return (arr = {
      ...arr,
      [item.column_name]: booking[item.column_name],
    })
  }, {})

  const formatDataDynamic = formatColumnName.map((key: string) => {
    return {
      key,
      value: listCartFormat[key],
      berth_name: booking.berth_name,
    }
  })

  const renderDataListCards = () => {
    return formatDataDynamic.map(
      (item: {
        key: string
        value: string | number | Array<AttachmentType>
        berth_name: string
      }) => {
        switch (item.key) {
          case 'status':
            return <S.Td>{renderButtonListCard(item.value)}</S.Td>
          case 'attachments':
            const isAttachments = _isArray(item.value) && item.value.length > 0

            return <S.Td>{isAttachments ? 'あり' : '-'}</S.Td>
          case 'driver_phone':
            return (
              <S.Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: 14 }}>
                {item.value ? formatPhoneNumber(item.value) : '-'}
              </S.Td>
            )
          case 'berth_id':
            return (
              <S.Td style={{ textAlign: 'center', wordBreak: 'break-word', fontSize: 14 }}>
                {item.berth_name ? item.berth_name : '-'}
              </S.Td>
            )
          default:
            return (
              <S.Td style={{ textAlign: 'center', whiteSpace: 'nowrap', fontSize: 14 }}>
                {item.value ? item.value : '-'}
              </S.Td>
            )
        }
      }
    )
  }

  const handleConfirmChangeStatusOneBooking = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { booking_id, repeat_id, start_date, start_time, end_date, end_time, tenant_id } = booking

    handleConfirmChangeStatus({
      booking_id,
      tenant_id,
      repeat_id,
      start_date,
      start_time,
      end_date,
      end_time,
    })
  }

  const renderStatusButton = (status: number) => {
    const isDisabled = status !== BookingStatuses.Booking.id

    return (
      <S.WrapperConfirmBooking>
        <S.ConfirmBookingButton
          type="red"
          disabled={isDisabled}
          onClick={(e: ChangeEvent<HTMLInputElement>) => handleConfirmChangeStatusOneBooking(e)}
        >
          確定
        </S.ConfirmBookingButton>
      </S.WrapperConfirmBooking>
    )
  }

  return (
    <tr
      key={booking.booking_id}
      className={'can-hover'}
      style={{
        borderBottom: '1px solid #CCC',
        zIndex: 1049,
      }}
    >
      <S.Td>{renderStatusButton(booking.status)}</S.Td>
      <S.Td style={{ padding: '6px 10px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <S.WrapperButton>
            {vehicleSummaryBookingEditor(camelizeKeys(booking), bookingMaster)}
          </S.WrapperButton>
        </div>
      </S.Td>
      {renderDataListCards()}
    </tr>
  )
}
