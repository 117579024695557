export const HEADER_HEIGHT = 60

export const filterNumber = (value) => value.replace(/[^0-9]/g, '')

export const TEN_DIGITS = 2147483647

export const TIMER_GET_WATTING_BOOKING = 1000 * 60

export const MAX_MINUTE_GET_WATTING_BOOKING = 5

export const STATUS_CHECKIN_TIME_NOT_FOUND = '803'

export const STATUS_CHECKIN_TIME_BOOKING_NOT_FOUND = '804'

export const STANDARD_TIME_FORMAT = 'HH:mm:ss'

export const STANDARD_TIME_CHECKIN_FORMAT = 'HH:mm'

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const DATE_TIME_FORMAT_API = 'YYYYMMDD HH:mm:ss'
