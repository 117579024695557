import React, { FC, useState, useEffect } from 'react'
import { NavLink, Prompt, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { validateConfirmingDriver } from 'utils/helper'
import { openToast, openError } from 'containers/App/reducers'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import {
  ConfirmationType,
  ConfirmationImageType,
  GeneralConfirmationType,
  GeneralReceptionType,
} from '../type'
import { CONFIRMATION_CONTENT_LENGTH } from 'constants/ReceptionCustom'
import RowConfirmation from '../components/RowConfirmation'
import FinishToast from 'containers/ReceptionCustom/components/FinishToast'
import {
  generalReceptionSelectedSelectors,
  settingGeneralConfirmationSelectors,
  initialGeneralConfirmationSelectors,
} from 'containers/IntegratedReceptionCustom/selectors'
import {
  updateInitialGeneralConfirmation,
  updateSettingGeneralConfirmation,
  startLoading,
  endLoading,
  clearConfirmations,
  fetchGeneralReceptions,
} from '../reducer'
import { updateGeneralConfirmation } from '../api'
import { routes } from '../constants'
import * as S from '../components/styles'

type Props = {
  generalReception: GeneralReceptionType
  initialGeneralConfirmation: GeneralConfirmationType
  settingGeneralConfirmation: GeneralConfirmationType
  startLoading: () => void
  endLoading: () => void
  clearConfirmations: () => void
  updateInitialGeneralConfirmation: (data: GeneralConfirmationType) => void
  updateSettingGeneralConfirmation: (data: GeneralConfirmationType) => void
  fetchGeneralReceptions: () => void
  openError: (message: string) => void
  openToast: (content: any) => void
}

const ConfirmationEditPage: FC<Props> = ({
  generalReception,
  initialGeneralConfirmation,
  settingGeneralConfirmation,
  openError,
  updateInitialGeneralConfirmation,
  updateSettingGeneralConfirmation,
  startLoading,
  endLoading,
  openToast,
  clearConfirmations,
  fetchGeneralReceptions,
}) => {
  const history = useHistory()
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false)
  const { confirmations: settingConfirmations } = settingGeneralConfirmation || []
  const [imageIdsDelete, setImageIdsDelete] = useState<any>([])
  const isValidationError = settingConfirmations?.some(
    (confirmation) => !validateConfirmingDriver(confirmation).result
  )
  const isDontChangeChangeValue = _.isEqual(initialGeneralConfirmation, settingGeneralConfirmation)
  const isDisabledButton = isDontChangeChangeValue || isValidationError

  useEffect(() => {
    if (!generalReception?.id) {
      history.push(routes.confirmationPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalReception?.id])

  useEffect(() => {
    return () => {
      clearConfirmations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeConfirmation = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: ConfirmationType
  ) => {
    const dataValue = e?.target?.value
    if (dataValue?.length <= CONFIRMATION_CONTENT_LENGTH) {
      const newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
      const foundIndex: number | string = newConfirmations!.findIndex(
        (i: ConfirmationType) => i?.id === item?.id
      )

      newConfirmations[foundIndex] = {
        ...item,
        confirmation: dataValue,
      }

      updateSettingGeneralConfirmation({
        ...settingGeneralConfirmation,
        confirmations: newConfirmations,
      })
    }
  }

  const onChangeRequired = (item: ConfirmationType) => {
    const newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
    const foundIndex: number | string = newConfirmations!.findIndex(
      (i: ConfirmationType) => i?.id === item?.id
    )

    newConfirmations[foundIndex] = {
      ...item,
      checkFlag: !item?.checkFlag,
    }

    updateSettingGeneralConfirmation({
      ...settingGeneralConfirmation,
      confirmations: newConfirmations,
    })
  }

  const onChangeDisplay = (item: ConfirmationType) => {
    const newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
    const foundIndex: number | string = newConfirmations!.findIndex(
      (i: ConfirmationType) => i?.id === item?.id
    )

    newConfirmations[foundIndex] = {
      ...item,
      displayFlag: !item?.displayFlag,
    }

    updateSettingGeneralConfirmation({
      ...settingGeneralConfirmation,
      confirmations: newConfirmations,
    })
  }

  const onChangeImportant = (item: ConfirmationType) => {
    let newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
    const foundIndex: number | string = newConfirmations!.findIndex(
      (i: ConfirmationType) => i?.id === item?.id
    )

    if (foundIndex >= 0) {
      newConfirmations[foundIndex] = {
        ...item,
        importantFlag: !item?.importantFlag,
      }
    }

    updateSettingGeneralConfirmation({
      ...settingGeneralConfirmation,
      confirmations: newConfirmations,
    })
  }

  const onRemoveImage = (item: ConfirmationType) => {
    let newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
    const foundIndex = newConfirmations.findIndex((i) => i?.id === item?.id)

    if (foundIndex >= 0) {
      newConfirmations[foundIndex] = {
        ...item,
        images: [],
      }
      const idImage = _.get(item, 'images[0].id', '')
      if (idImage) {
        setImageIdsDelete([...imageIdsDelete, idImage])
      }
      updateSettingGeneralConfirmation({
        ...settingGeneralConfirmation,
        confirmations: newConfirmations,
      })
    }
  }

  const onUpdateImage = (item: ConfirmationType, image: ConfirmationImageType) => {
    let newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
    const foundIndex = newConfirmations.findIndex((i) => i?.id === item?.id)

    if (foundIndex >= 0) {
      newConfirmations[foundIndex] = {
        ...item,
        images: [image],
      }
      const idImage = _.get(item, 'images[0].id', '')

      if (idImage) {
        setImageIdsDelete([...imageIdsDelete, idImage])
      }
      updateSettingGeneralConfirmation({
        ...settingGeneralConfirmation,
        confirmations: newConfirmations,
      })
    }
  }

  const onChangeAboveTextFlag = (item: ConfirmationType) => {
    let newConfirmations: ConfirmationType[] = _.cloneDeep(settingConfirmations) || []
    const foundIndex = newConfirmations.findIndex((i) => i?.id === item?.id)
    if (foundIndex >= 0) {
      const image = _.get(item, 'images[0]', {})
      newConfirmations[foundIndex] = {
        ...item,
        images: [{ ...image, aboveTextFlag: !image?.aboveTextFlag }],
      }
      updateSettingGeneralConfirmation({
        ...settingGeneralConfirmation,
        confirmations: newConfirmations,
      })
    }
  }

  const onClickRegister = async () => {
    const dataBeforeSubmit = {
      generalReceptionId: generalReception?.id,
      confirmations: settingConfirmations,
      imageIdsDelete,
    }
    startLoading()
    try {
      setIsSubmitLoading(true)
      const res = await updateGeneralConfirmation(dataBeforeSubmit)
      updateInitialGeneralConfirmation({
        confirmations: res.data,
      })
      updateSettingGeneralConfirmation({ confirmations: res.data })
      openToast(FinishToast)
      fetchGeneralReceptions()
      history.push(routes.confirmationPage)
    } catch (error) {
      openError(error.message)
    } finally {
      endLoading()
      setIsSubmitLoading(false)
    }
  }

  return (
    <S.CustomSettingContainer>
      <S.EditContentConfirmation>
        <div className="mb-3 font-weight-bold">ドライバーへの伝達事項</div>
        <div className="mb-3">
          受付完了後に、ドライバーに表示される内容です。３件まで表示できます。
        </div>

        <div>
          {settingConfirmations?.map((confirmation: ConfirmationType) => (
            <RowConfirmation
              key={confirmation.id}
              item={confirmation}
              onChangeConfirmation={onChangeConfirmation}
              onChangeRequired={onChangeRequired}
              onChangeDisplay={onChangeDisplay}
              onChangeImportant={onChangeImportant}
              onRemoveImage={onRemoveImage}
              onUpdateImage={onUpdateImage}
              onChangeAboveTextFlag={onChangeAboveTextFlag}
              openError={openError}
              validationResult={validateConfirmingDriver(confirmation)}
            />
          ))}
        </div>
      </S.EditContentConfirmation>

      <div className="d-flex justify-content-between mb-3">
        <NavLink to={routes.confirmationPage}>
          <Button outline color="primary" className="text-primary bg-white rounded-pill px-4">
            もどる
          </Button>
        </NavLink>

        <Button
          color="primary"
          className="rounded-pill px-4"
          onClick={onClickRegister}
          disabled={isSubmitLoading || isDisabledButton}
        >
          登録
        </Button>
      </div>

      <Prompt
        when={!isDontChangeChangeValue}
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
    </S.CustomSettingContainer>
  )
}

const mapStateToProps = createStructuredSelector({
  generalReception: generalReceptionSelectedSelectors(),
  initialGeneralConfirmation: initialGeneralConfirmationSelectors(),
  settingGeneralConfirmation: settingGeneralConfirmationSelectors(),
})

const mapDispatchToProps = {
  startLoading,
  endLoading,
  openError,
  openToast,
  showFlashMessage,
  updateInitialGeneralConfirmation,
  updateSettingGeneralConfirmation,
  clearConfirmations,
  fetchGeneralReceptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEditPage)
