import React from 'react'

import A from 'components/A'

export default () => (
  <div className="text-center">
    <span className="mr-3">TEL：0120-507-765</span>
    <span>WEB：</span>
    <A href="https://monoful.co.jp/contact" newTab>
      https://monoful.co.jp/contact
    </A>
  </div>
)
