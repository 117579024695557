import { createSelector } from 'reselect'
import { context } from './constants'
import _ from 'lodash'

import { compareValue } from 'utils/commonFunctions'
import { VEHICLE_NUMBER_COLUMN_NAME, DISPLAY_ORDER_OFFSET } from './constants'

const multiSettingSelector = (state: any) => state.get(context)

export const loadingSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('loading'))

export const generalReceptionsSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('generalReceptions')?.toJS())

export const generalReceptionSelectedSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('generalReceptionSelected')?.toJS())

export const baseParametersSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('baseParameters')?.toJS())

export const exportParametersSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('exportParameters')?.toJS())

const canUpdateParameter = (columnName: string) =>
  !['company_name', 'vehicle_number', 'vehicle_category', 'import_package_type'].includes(
    columnName
  )

export const parametersOrderSelectors = () =>
  createSelector(parametersSelectors(), (parameters) =>
    _.sortBy(
      _.filter(
        parameters,
        (parameter) => !compareValue(parameter?.columnName, VEHICLE_NUMBER_COLUMN_NAME)
      ),
      (parameter) =>
        canUpdateParameter(parameter?.columnName)
          ? DISPLAY_ORDER_OFFSET + parameter?.displayOrder
          : parameter?.displayOrder
    )
  )

export const selectParameterSelectors = () =>
  createSelector(multiSettingSelector, (state) => {
    return state.get('selectedParameter')?.toJS()
  })

export const parametersSelectors = () =>
  createSelector(multiSettingSelector, (state) => {
    return state.get('parameters')?.toJS()
  })

export const initialParametersSelectors = () =>
  createSelector(multiSettingSelector, (state) => {
    return state.get('initialParameters')?.toJS()
  })

export const initialGeneralConfirmationSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('initialGeneralConfirmation')?.toJS())

export const settingGeneralConfirmationSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('settingGeneralConfirmation')?.toJS())

export const roleOfGeneralReceptionSelectors = () =>
  createSelector(multiSettingSelector, (state) => state.get('role')?.toJS())
