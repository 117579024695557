import { statusLabel, statusValues, BookingStatuses } from 'constants/misc'
import _range from 'lodash/range'

export const STANDARD_TIME_FORMAT = 'HH:mm:ss'
export const STANDARD_DAY_FORMAT = 'YYYY-MM-DD'
export const API_DAY_FORMAT = 'YYYYMMDD'

export const TIME_TITLES = (MODE) =>
  _range(0, 24).reduce((initial, item) => {
    const hours = String(item).padStart(2, '0')
    const result = _range(0, 60, 60 / MODE).map((it) => {
      const mins = String(it).padStart(2, 0)
      return `${hours}:${mins}`
    })
    return [...initial, ...result]
  }, [])
export const BLOCK15 = 15
export const HEADER_HEIGHT = 50
export const MINUTES_PER_HOUR = 60
export const HEADER_LEFT_WIDTH = 60
export const DELEGATION_WIDTH = 10

export const MIN_NUMBER_PAGINATION_SET_MIN_WIDTH = 5

export const UNASSIGN_TITLE = '未定'
export const START_TIME_DAY = '00:00:00'
export const END_TIME_DAY = '24:00:00'

export const BOOKING_STATUS = {
  [statusValues.Unassigned]: {
    ja: statusLabel.Unassigned,
  },
  [statusValues.Recept]: {
    ja: statusLabel.Recept,
  },
  [statusValues.Booking]: {
    ja: statusLabel.Booking,
  },
  [statusValues.Confirmed]: {
    ja: statusLabel.Confirmed,
  },
  [statusValues.Delay_Expected]: {
    ja: statusLabel.Delay_Expected,
  },
  [statusValues.Delay]: {
    ja: statusLabel.Delay,
  },
  [statusValues.Arrival]: {
    ja: statusLabel.Arrival,
  },
  [statusValues.Calling]: {
    ja: statusLabel.Calling,
  },
  [statusValues.Working]: {
    ja: statusLabel.Working,
  },
  [statusValues.Finish]: {
    ja: statusLabel.Finish,
  },
  [BookingStatuses.WaitingOne.id]: {
    ja: BookingStatuses.WaitingOne.label,
  },
  [BookingStatuses.WaitingTwo.id]: {
    ja: BookingStatuses.WaitingTwo.label,
  },
}

export const responsiveScheduleTable = (MODE) => {
  const { innerWidth } = window
  if (innerWidth <= 1140) {
    return {
      CELL_WIDTH: 150,
      TIME_RATE: MODE === 6 ? 1.1 : 1.7,
    }
  } else if (innerWidth <= 1240) {
    return {
      CELL_WIDTH: 160,
      TIME_RATE: MODE === 6 ? 1.1 : 1.7,
    }
  } else if (innerWidth <= 1368) {
    return {
      CELL_WIDTH: 170,
      TIME_RATE: MODE === 6 ? 1.1 : 1.7,
    }
  } else if (innerWidth <= 1440) {
    return {
      CELL_WIDTH: 205,
      TIME_RATE: MODE === 6 ? 1.1 : 1.9,
    }
  } else if (innerWidth <= 1500) {
    return {
      CELL_WIDTH: 215,
      TIME_RATE: MODE === 6 ? 1.1 : 1.9,
    }
  } else if (innerWidth <= 1679) {
    return {
      CELL_WIDTH: 225,
      TIME_RATE: MODE === 6 ? 1.1 : 1.9,
    }
  } else if (innerWidth <= 1900) {
    return {
      CELL_WIDTH: 260,
      TIME_RATE: MODE === 6 ? 1.1 : 2.0,
    }
  }
  return {
    CELL_WIDTH: 305,
    TIME_RATE: MODE === 6 ? 1.1 : 2.1,
  }
}

export const REACTSTRAP_TOOLTIP_CLASS = 'tooltip'

export const RND_WRAPPER_CLASS = 'react-draggable'

export const REGEX_IMPORT_EXPORT_ADDITIONAL_COLUMN = /^(import|export)_additional_column\d+$/g
export const REGEX_IMPORT_EXPORT = /^(import|export)([-\w\d]+)?$/g
export const REGEX_IMPORT_EXPORT_REPLACE = /(import|export)_/g

export const checkIsAdditionalColumn = (columnName) =>
  new RegExp(REGEX_IMPORT_EXPORT_ADDITIONAL_COLUMN).test(columnName)
