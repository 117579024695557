import React, { useState, useEffect, memo, FC } from 'react'
import useWindowFocus from 'hooks/useWindowFocus'
import { Helmet } from 'react-helmet'

const CustomMetaTag: FC = () => {
  const isWindowFocus = useWindowFocus()
  const [hasViewPortCustom, setHasViewPortCustom] = useState(true)

  useEffect(() => {
    if (isWindowFocus) {
      setTimeout(() => {
        setHasViewPortCustom(false)
      }, 1000)
    } else {
      setHasViewPortCustom(true)
    }
  }, [isWindowFocus])

  return (
    <>
      {hasViewPortCustom && (
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
      )}
    </>
  )
}

export default memo(CustomMetaTag)
