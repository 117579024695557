import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Creatable from 'react-select/creatable'

import _forEarch from 'lodash/forEach'
import _findIndex from 'lodash/findIndex'
import Icon from 'components/Icon'
import theme from 'vars/theme'
import { compareValue } from 'utils/commonFunctions'
import { ErrorText } from '../SingleForm/style'
import { SelectOptionsWrapper } from './style'

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ isHightLight }) => (isHightLight ? theme.blueMedium2 : '')};

  img {
    margin-left: 5px;
  }
`

class CreatableSelect extends PureComponent {
  state = {
    options: [],
  }

  static getDerivedStateFromProps(props, state) {
    const resetedOptions = []
    // This code using in case when exists addition option of this state in options of props it will remove state that exists
    // and using new data from option of props
    _forEarch(state.options, (option) => {
      if (_findIndex(props.options, { label: option.label }) === -1) resetedOptions.push(option)
    })

    if (
      props.value &&
      props.isDuplicate &&
      !props.options.some((option) => compareValue(option.value, props.value)) &&
      !state.options.some((option) => compareValue(option.value, props.value))
    ) {
      resetedOptions.push({ label: props.value, value: props.value })
    }
    return {
      options: resetedOptions,
    }
  }

  onInputChange = (inputValue) => {
    const { type, disableCreateSelect } = this.props
    if ((type === 'number' && isNaN(Number(inputValue))) || disableCreateSelect) {
      // eslint-disable-line
      return inputValue.replace(/[^0-9]/g, '').substr(0, inputValue.length - 1)
    }
    const maxLength = this.props.maxLength
    return inputValue.substr(0, maxLength)
  }

  getNewOptionData = (inputValue, optionLabel) => ({
    value: optionLabel.trim(),
    label: optionLabel.trim(),
  })

  getValue = () => {
    const { options: stateOptions } = this.state
    const { value, options } = this.props
    const selected = options
      .concat(stateOptions)
      .find((option) => compareValue(option.value, value) || option.label === value)
    return selected || null
  }

  isValidNewOption = (inputValue) => {
    const val = inputValue.trim()
    const { options, isUnique, notShowNewOption } = this.props
    if (notShowNewOption) return false
    if (isUnique) {
      const finalOptions = [...options, ...this.state.options]
      const item = finalOptions.find((it) => compareValue(val, it.label))
      return !item
    }
    return !!val
  }

  handleCreate = (value) => {
    const val = value.trim()
    const { isUnique, options, onChange } = this.props
    if (value.trim()) {
      if (isUnique) {
        if (val === '未定') return
        const finalOptions = [...options, ...this.state.options]
        const option = finalOptions.find((it) => compareValue(val, it.label))
        if (option) {
          onChange(option)
          return
        }
      }
      const newOption = {
        value: val.trim(),
        label: val.trim(),
      }
      this.handleChange(newOption, { action: 'create-option' })
    }
  }

  handleBlur = (event) => {
    this.handleCreate(event.target.value)
  }

  handleChange = (option, { action }) => {
    const { isNeedSaveOption, handleSaveCompanyOption, isDisabled } = this.props
    switch (action) {
      case 'select-option':
        if (!isDisabled) this.props.onChange(option)
        break
      case 'create-option':
        if (!isDisabled) {
          this.props.onChange(option)
          const { options } = this.state
          options.push(option)
          this.setState({ options })
          if (isNeedSaveOption) {
            handleSaveCompanyOption(this.state.options)
          }
        }
        break
      default:
        break
    }
  }

  render() {
    const {
      placeholder,
      options,
      errorMessage,
      required,
      label,
      isHightLight,
      isDisabled,
      isDriverInfoCompany,
      labelFontSize,
      ...rest
    } = this.props
    const finalOptions = [...options, ...this.state.options]
    return (
      <React.Fragment>
        <SelectOptionsWrapper
          className={`${isDriverInfoCompany ? '' : 'd-flex align-items-center'} mb-2`}
          isError={!!errorMessage}
          {...rest}
        >
          {label && (
            <Label
              style={{ fontSize: labelFontSize || '14px' }}
              isHightLight={isHightLight}
              className="label-flex"
            >
              {label}
              {required && <span className="color-red-med1 font-weight-bold">*</span>}
              {isHightLight && <Icon src="/assets/svgs/man-user.svg" />}
            </Label>
          )}
          <div className="input-flex flex-column">
            <Creatable
              isDisabled={isDisabled}
              autosize={false}
              className="w-100"
              classNamePrefix="mono-creatable"
              placeholder={placeholder}
              options={finalOptions}
              value={this.getValue()}
              getNewOptionData={this.getNewOptionData}
              onInputChange={this.onInputChange}
              onChange={this.handleChange}
              isValidNewOption={this.isValidNewOption}
              formatCreateLabel={() => ''}
              noOptionsMessage={() => null}
              onBlur={this.handleBlur}
              onCreateOption={this.handleCreate}
              blurInputOnSelect={false}
            />
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          </div>
        </SelectOptionsWrapper>
      </React.Fragment>
    )
  }
}

CreatableSelect.defaultProps = {
  type: 'string',
  isUnique: false,
  maxLength: 200,
  errorMessage: '',
}

export default CreatableSelect
