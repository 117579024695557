import { createSingleAction } from 'utils/reduxActions'
import * as types from './constants'

// Show/hide flash message
export const showFlashMessage = createSingleAction(types.SHOW_FLASH_MESSAGE)
export const hideFlashMessage = createSingleAction(types.HIDE_FLASH_MESSAGE)

// update user role
export const getUserRoles = createSingleAction(types.GET_USER_ROLES)
export const getUserRolesSuccess = createSingleAction(types.GET_USER_ROLES_SUCCESS)
export const getUserRolesFailure = createSingleAction(types.GET_USER_ROLES_FAILURE)

// updatedBooking action
export const updatedBooking = createSingleAction(types.UPDATED_BOOKING)

// toggle render csv
export const toggleRenderCsv = createSingleAction(types.TOGGLE_RENDER_CSV)

export const receiveMultiBooking = createSingleAction('RECEIVE_MULTI_BOOKING')
export const updateMultiBooking = createSingleAction('UPDATE_MULTI_BOOKING')

// check user policy
export const checkUserPolicy = createSingleAction(types.CHECK_USER_POLICY)
export const checkUserPolicySuccess = createSingleAction(types.CHECK_USER_POLICY_SUCCESS)

// update user policy
export const updateUserPolicy = createSingleAction(types.UPDATE_USER_POLICY)
export const updateUserPolicySuccess = createSingleAction(types.UPDATE_USER_POLICY_SUCCESS)
