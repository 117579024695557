import React, { useEffect, useCallback } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import PrivateRoute from './components/PrivateRoute'
import MasterPage from './pages/MasterPage'
import GeneralReceptionCreatePage from './pages/GeneralReceptionCreatePage'
import AddTenantPage from './pages/AddTenantPage'
import AddAdminPage from './pages/AddAdminPage'
import AddControllerPage from './pages/AddControllerPage'
import Header from 'components/Layout/Header'
import SideBar from './components/SideBar'
import * as S from './style'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import saga from './saga'
import {
  reducer,
  fetchGeneralReceptions,
  updateSelectedGeneralReception,
  resetAllData,
} from './reducer'
import {
  loadingSelectors,
  generalReceptionsSelectors,
  selectedGeneralReceptionSelectors,
} from './selectors'
import { context, Routes } from './constants'
import { GeneralReceptionType } from './type'
import { userDataSelectors } from 'containers/App/selectors'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import useConfirmModal from './hooks/useConfirmModal'

type Props = {
  userData: object
  receptionData: Object
  loading: boolean
  generalReceptions: GeneralReceptionType[]
  selectedGeneralReception: GeneralReceptionType
  fetchGeneralReceptions: () => void
  updateSelectedGeneralReception: (data: GeneralReceptionType) => void
  showFlashMessage: (data: Object) => void
  resetAllData: () => void
}

const MultiSettingRouting: React.FC<Props> = ({
  userData,
  fetchGeneralReceptions,
  updateSelectedGeneralReception,
  loading,
  generalReceptions,
  selectedGeneralReception,
  showFlashMessage,
  resetAllData,
}) => {
  const { is_warehouse_admin } = _.get(userData, 'attributes', {})
  const history = useHistory()
  const { ConfirmModal, showConfirmModal } = useConfirmModal()

  useEffect(() => {
    fetchGeneralReceptions()
    return () => {
      resetAllData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = useCallback(() => {
    history.push('/multi-setting')
  }, [history])

  const hasGeneralReception = !!generalReceptions?.length

  return (
    <div className="h-100vh">
      <Helmet>
        <title>トラック簿 - バース管理マスタ</title>
        <meta name="description" content="Monoful Berth Warehouses" />
      </Helmet>
      <Header>総合管理マスタ</Header>
      <S.MainWrapper>
        <SideBar
          generalReceptions={generalReceptions}
          idActive={selectedGeneralReception?.id}
          onChange={(generalChoose) => {
            updateSelectedGeneralReception(generalChoose)
          }}
          hasAdminCompanyPermission={is_warehouse_admin}
        />
        <main className="main custom-scrollbar">
          <div className="w-100 h-100">
            <Switch>
              <PrivateRoute
                path={Routes.master}
                render={(props: any) => (
                  <MasterPage
                    loading={loading}
                    selectedGeneralReception={selectedGeneralReception}
                    showConfirmModal={showConfirmModal}
                    hasGeneralReception={hasGeneralReception}
                    {...props}
                  />
                )}
                exact
              />
              <PrivateRoute
                path={Routes.createGeneralReception}
                render={(props: any) => (
                  <GeneralReceptionCreatePage
                    isCreatePage
                    handleCancel={handleCancel}
                    hasAdminCompanyPermission={is_warehouse_admin}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                path={Routes.editGeneralReception}
                render={(props: any) => (
                  <GeneralReceptionCreatePage
                    handleCancel={handleCancel}
                    hasAdminCompanyPermission={is_warehouse_admin}
                    {...props}
                  />
                )}
              />
              <PrivateRoute
                path={Routes.addTenant}
                render={(props: any) => <AddTenantPage {...props} />}
              />
              <PrivateRoute
                path={Routes.addAdmin}
                render={(props: any) => <AddAdminPage handleCancel={handleCancel} {...props} />}
              />
              <PrivateRoute
                path={Routes.addController}
                render={(props: any) => (
                  <AddControllerPage
                    handleCancel={handleCancel}
                    showConfirmModal={showConfirmModal}
                    {...props}
                  />
                )}
              />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
          <ConfirmModal />
        </main>
      </S.MainWrapper>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  userData: userDataSelectors(),
  loading: loadingSelectors(),
  generalReceptions: generalReceptionsSelectors(),
  selectedGeneralReception: selectedGeneralReceptionSelectors(),
})

const mapDispatchToProps = {
  showFlashMessage,
  fetchGeneralReceptions,
  updateSelectedGeneralReception,
  resetAllData,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: context, reducer })
const withSaga = injectSaga({ key: context, saga, mode: null })

export default compose(withReducer, withSaga, withConnect)(MultiSettingRouting)
