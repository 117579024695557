import React, { memo } from 'react'
import { Form } from 'reactstrap'

import Button from 'components/Button'
import SingleForm from 'components/SingleForm'
import CustomTable from 'components/CustomTable'
import { CommonWrapper } from 'components/Layout/commonLayoutStyles'
import SelectOptions from 'components/SelectOptions'

import * as S from '../style'
import { TableHeaderManagerDetailTenant } from '../constants'
import { useAddTenant } from '../hooks/useAddTenant'

const AddTenantPage: React.FC<{}> = () => {
  const {
    warehouses,
    tenants,
    berth,
    formData,
    error,
    handleOnChange,
    handleOnPageBerthChange,
    selectedTenant,
    handleCancel,
    handleSubmit,
    loading,
  } = useAddTenant()

  return (
    <S.FormCreateGeneralWrapper>
      <Form className="mono-container w-100 h-100 bg-gray p-4 m-0">
        <CommonWrapper className="custom-scrollbar py-4 d-flex align-items-center">
          <div className="main-wrapper">
            <h2 className="my-4 text-center">テナント</h2>
            <SelectOptions
              name="warehouseId"
              label="倉庫名"
              placeholder="倉庫名"
              options={warehouses.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectValue={formData.warehouseId}
              handleUpdateData={handleOnChange('warehouseId')}
              required
              errorMessage={error.warehouseId}
            />
            <SelectOptions
              name="tenantId"
              label="テナント名"
              placeholder="テナント名"
              options={tenants.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectValue={formData.tenantId}
              handleUpdateData={handleOnChange('tenantId')}
              required
              errorMessage={error.tenantId}
            />
            <SingleForm
              id="tenantName"
              name="tenantName"
              label="表示名"
              placeholder="表示名"
              maxLength={200}
              required
              value={formData.tenantName}
              onChange={handleOnChange('tenantName')}
              errorMessage={error.tenantName}
            />
            <SingleForm
              id="viewOrder"
              name="viewOrder"
              label="表示順"
              placeholder="表示順"
              required
              value={formData.viewOrder}
              onChange={handleOnChange('viewOrder')}
              errorMessage={error.viewOrder}
            />
            <SingleForm
              id="tenantNumber"
              name="tenantNumber"
              label="テナント番号"
              disabled
              value={selectedTenant.tenantNumber}
            />
            <SingleForm
              id="contactName"
              name="contactName"
              label="連絡担当者"
              disabled
              value={selectedTenant.contactName}
            />
            <SingleForm
              id="contactPhone"
              name="contactPhone"
              label="連絡先電話番号"
              disabled
              value={selectedTenant.contactPhone}
            />
            <S.InfoBox className="mb-2">
              <S.Label> 利用サービス</S.Label>
              <span>
                {selectedTenant.canCheckin && 'チェックイン'}
                {selectedTenant.canBerthBook && ', バース予約'}
                {selectedTenant.canSms && ', SMS'}
                {selectedTenant.canReceptionUseHistory && ', 受付履歴'}
                {selectedTenant.canCustomizeReception && ', 受付カスタマイズ'}
                {selectedTenant.canCustomizeReceptionSuperAdminOnly && ', 受付カスタマイズ変更不可'}
              </span>
            </S.InfoBox>
            <S.InputCustom
              id="berthCount"
              name="berthCount"
              label="バース数"
              disabled
              value={selectedTenant.totalBerths}
            />
            <S.BerthListWrapper>
              <S.Label>バース</S.Label>
              <CustomTable
                data={berth.data}
                tableHeight="268px"
                hasButton={false}
                columns={TableHeaderManagerDetailTenant}
                meta={{
                  current: berth.currentPage,
                  total: berth.totalPage,
                }}
                onNavigateRequest={handleOnPageBerthChange}
              />
            </S.BerthListWrapper>
          </div>
        </CommonWrapper>
        <S.FormButton>
          <S.WrapperButton>
            <Button size="sm" width="115px" color="lightBlue" onClick={handleCancel}>
              キャンセル
            </Button>
            <Button
              className="ml-3"
              size="sm"
              width="120px"
              color="blue"
              needWait
              disabled={loading}
              onClick={handleSubmit}
            >
              保存する
            </Button>
          </S.WrapperButton>
        </S.FormButton>
      </Form>
    </S.FormCreateGeneralWrapper>
  )
}

export default memo(AddTenantPage)
