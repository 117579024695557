import React, { memo } from 'react'
import CardSection from './CardSection'

import { INTEGRATED_RECEPTION_URL_BASE } from 'containers/IntegratedReception/constants'
import * as S from '../style'
import { GeneralReceptionType } from '../type'

type PropsItem = {
  label: string
}

const Item: React.FC<PropsItem> = ({ label, children }) => {
  return (
    <div className="d-flex">
      <div className="label-flex color-gray-med1">{label}</div>
      <div className="input-flex">{children}</div>
    </div>
  )
}

type Props = {
  data?: GeneralReceptionType
  permissions: boolean
  numberTenant: number
  hasGeneralReception: boolean
  onClick?: any
}

const GeneralReceptionInfo: React.FC<Props> = memo(
  ({ data, numberTenant, permissions, hasGeneralReception, onClick }) => {
    return (
      <CardSection
        buttonLabel="情報変更"
        title={data?.name || ''}
        buttonIsDisabled={!hasGeneralReception || !permissions}
        onClick={onClick}
      >
        <S.GeneralReceptionInfo>
          {!!data?.id ? (
            <>
              <Item label="チェックインURL">
                <a
                  className="color-blue-med1 input-flex can-select"
                  href={`${window.location.protocol}//${window.location.host}${INTEGRATED_RECEPTION_URL_BASE}/${data?.generalReceptionNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`${window.location.protocol}//${window.location.host}${INTEGRATED_RECEPTION_URL_BASE}/${data?.generalReceptionNumber}`}
                </a>
              </Item>
              <Item label="チェックインパスワード">{data?.decodedPassword}</Item>
              <Item label="総合受付番号">{data?.generalReceptionNumber}</Item>
              <Item label="総合受付ID">{data?.generalReceptionCsvId}</Item>
              <Item label="連絡担当者">{data?.contactName}</Item>
              <Item label="連絡先電話番号">{data?.contactPhone}</Item>
              <Item label="スマホ受付範囲">{data?.checkinEnableDistance}</Item>
              <Item label="スマホ受付緯度">{data?.latitude}</Item>
              <Item label="スマホ受付経度">{data?.longitude}</Item>
              <Item label="利用サービス">
                {data?.features?.canGeneralBooking && '総合予約'}
                {data?.features?.canGeneralBooking && data?.features?.canCustomizeReception && ','}
                {data?.features?.canCustomizeReception && '受付カスタマイズ'}
              </Item>
              <Item label="テナント数">{numberTenant}</Item>
            </>
          ) : (
            <div className="h-100 w-100 flex-center color-gray-med1">データなし!</div>
          )}
        </S.GeneralReceptionInfo>
      </CardSection>
    )
  }
)

export default GeneralReceptionInfo
