import React, { memo } from 'react'
import styled from 'styled-components'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import {
  REGEX_IMPORT_EXPORT,
  REGEX_IMPORT_EXPORT_REPLACE,
  checkIsAdditionalColumn,
} from 'containers/IntegratedManagementPage/constants'
import lineApp from 'assets/pngs/line-app.png'
import { ReactComponent as MonofulIcon } from 'assets/svgs/multi-booking/monoful-icon.svg'
import StrikeThrough from './StrikeThrough'
import { bookingMasterGeneralSelectors } from 'containers/IntegratedManagementPage/selectors'
import { compareValue, handleFormatVehicleNumber } from 'utils/commonFunctions'
import { decamelizeKeys } from 'humps'

const PositionItemDescription = styled.p`
  display: flex;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
`

export const EllipsisWrapper = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const TextLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-clamp: 1;
  box-orient: vertical;
  min-width: 25px;
`

const PositionItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 20px;
`

const PositionItemElement = styled.div`
  display: flex;
  flex: 1;
  min-height: 20px;
  width: calc((100% / 2) - 10px);
`

const PositionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2px;
`

export const UNASSIGN_TITLE = '未定'

const VehicaleSummary = ({ booking, bookingMaster }) => {
  const allParameters = bookingMaster?.tenant_parameters?.base.concat(
    bookingMaster?.tenant_parameters?.import
  )
  const dataBooking = decamelizeKeys(booking || {})

  const getVehicleCategory = () => {
    const { vehicle_category } = dataBooking || {}
    const vehicle_categories = _.get(bookingMaster, 'vehicle_categories', [])
    const getItem = vehicle_categories.find((it) => compareValue(it.parameter_id, vehicle_category))
    const categoryName = _.get(getItem, 'parameter_name', '')
    if (!categoryName) {
      return ''
    }
    return categoryName
  }

  const getPackageType = () => {
    const { import_package_type, export_package_type } = dataBooking
    const package_types = _.get(bookingMaster, 'package_types', [])

    const input_package_type = package_types.find((it) =>
      compareValue(it.parameter_id, import_package_type)
    )
    const output_package_type = package_types.find((it) =>
      compareValue(it.parameter_id, export_package_type)
    )
    const input_package_name = _.get(input_package_type, 'parameter_name', '')
    const output_package_name = _.get(output_package_type, 'parameter_name', '')
    return `${input_package_name || output_package_name}`
  }

  const renderTextLabel = (cardData) => {
    const vehicle_categories = _.get(bookingMaster, 'vehicle_categories', [])
    const { import_package_type, export_package_type, vehicle_category } = dataBooking || {}
    const columnName = `${cardData?.columnName || ''}`.trim()
    const regexImportExport = new RegExp(REGEX_IMPORT_EXPORT)
    const isImportExportColumn = regexImportExport.test(columnName)
    if (isImportExportColumn) {
      const value =
        dataBooking[columnName] || dataBooking[`${columnName}`.replace('import', 'export')]
      if (
        (import_package_type || export_package_type) &&
        compareValue(columnName.replace(REGEX_IMPORT_EXPORT_REPLACE, ''), 'package_type')
      ) {
        return <TextLabel>{getPackageType()}</TextLabel>
      }

      if (checkIsAdditionalColumn(columnName)) {
        return <TextLabel>{value}</TextLabel>
      }
      return <TextLabel>{value}</TextLabel>
    }

    if (compareValue(columnName, 'company_name')) {
      return (
        <>
          <TextLabel>{dataBooking?.driver_company_name || UNASSIGN_TITLE}</TextLabel>
          <span className="ml-1">
            {dataBooking?.vehicle_number
              ? `[${handleFormatVehicleNumber(dataBooking?.vehicle_number?.toString())}]`
              : UNASSIGN_TITLE}
          </span>
        </>
      )
    }

    if (
      !!vehicle_categories?.length &&
      vehicle_category &&
      compareValue(columnName, 'vehicle_category')
    ) {
      return <TextLabel>{getVehicleCategory()}</TextLabel>
    }

    return <TextLabel>{dataBooking[`${columnName}`]}</TextLabel>
  }

  const renderPositionItem = (allParameters) => {
    const listCardViewOrder = allParameters?.filter((parameter) => {
      return parameter.card_view_order
    })
    const bookingPositionViewOrder = listCardViewOrder.reduce((arr, item) => {
      return (arr = {
        ...arr,
        [`item${item.card_view_order}`]: {
          ...item,
          cardViewOrder: item?.card_view_order,
          columnName: item?.column_name,
        },
      })
    }, {})

    return (
      <PositionWrapper>
        <PositionItem>
          {bookingPositionViewOrder?.item1?.cardViewOrder && (
            // Position Element 1
            <PositionItemElement className="booking-content">
              <PositionItemDescription>
                {renderTextLabel(bookingPositionViewOrder.item1)}
              </PositionItemDescription>
            </PositionItemElement>
          )}

          {bookingPositionViewOrder?.item4?.cardViewOrder && (
            <>
              {/* Position Element 4 */}
              {bookingPositionViewOrder?.item1 && <StrikeThrough />}
              <PositionItemElement className="booking-content">
                <PositionItemDescription>
                  {renderTextLabel(bookingPositionViewOrder.item4)}
                </PositionItemDescription>
              </PositionItemElement>
            </>
          )}
        </PositionItem>

        <PositionItem>
          {bookingPositionViewOrder?.item2?.cardViewOrder && (
            // Position Element 2
            <PositionItemElement className="booking-content">
              <PositionItemDescription>
                {renderTextLabel(bookingPositionViewOrder.item2)}
              </PositionItemDescription>
            </PositionItemElement>
          )}

          {bookingPositionViewOrder?.item5?.cardViewOrder && (
            <>
              {/* Position Element 5 */}
              {bookingPositionViewOrder?.item2 && <StrikeThrough />}
              <PositionItemElement className="booking-content">
                <PositionItemDescription>
                  {renderTextLabel(bookingPositionViewOrder.item5)}
                </PositionItemDescription>
              </PositionItemElement>
            </>
          )}
        </PositionItem>

        <PositionItem>
          {bookingPositionViewOrder?.item3?.cardViewOrder && (
            //  Position Element 3
            <PositionItemElement className="booking-content">
              <PositionItemDescription>
                {renderTextLabel(bookingPositionViewOrder.item3)}
              </PositionItemDescription>
            </PositionItemElement>
          )}

          {bookingPositionViewOrder?.item6?.cardViewOrder && (
            <>
              {/* Position Element 6 */}
              {bookingPositionViewOrder?.item3 && <StrikeThrough />}
              <PositionItemElement className="booking-content">
                <PositionItemDescription>
                  {renderTextLabel(bookingPositionViewOrder.item6)}
                </PositionItemDescription>
              </PositionItemElement>
            </>
          )}
        </PositionItem>
      </PositionWrapper>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex' }}>
        {/* left */}
        <div style={{ width: 67, display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {/* top */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '36px',
                marginBottom: 2,
              }}
            >
              {booking.lineConnected && <img src={lineApp} alt="" width={18} height={18} />}
              {booking.portalFlag && (
                <MonofulIcon
                  width={18}
                  height={18}
                  style={{
                    marginLeft: 'auto',
                  }}
                />
              )}
            </div>
            {/* bottom */}
            <div style={{ lineHeight: 1 }}>
              <ImportExportIcon
                importFlag={!!booking.importFlag}
                exportFlag={!!booking.exportFlag}
              />
            </div>
          </div>
        </div>
        <PositionWrapper>{renderPositionItem(allParameters)}</PositionWrapper>
      </div>
    </div>
  )
}

const WrapperIconImportExport = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  margin-top: 3px;

  .import,
  .export {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    border: 1px solid rgba(37, 39, 44, 0.6);
  }

  .import {
    border-right: none;
    border-bottom-left-radius: 4px;
  }

  .export {
    border-top-right-radius: 4px;
  }

  .active {
    opacity: 1;
    color: #ffffff;
    background: #14213e;
    border: none;
  }
`

const ImportExportIcon = ({ importFlag, exportFlag }) => {
  const importClass = `import ${importFlag ? 'active' : ''}`
  const exportClass = `export ${exportFlag ? 'active' : ''}`
  return (
    <WrapperIconImportExport>
      <span className={importClass}>卸</span>
      <span className={exportClass}>積</span>
    </WrapperIconImportExport>
  )
}

const mapStateToProps = createStructuredSelector({
  bookingMaster: bookingMasterGeneralSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(memo(VehicaleSummary))
