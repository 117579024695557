import React, { Component } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { NavLink, Prompt } from 'react-router-dom'
import _ from 'lodash'

import {
  startLoading,
  stopLoading,
  fetchAnything,
  openError,
  openToast,
} from 'containers/App/reducers'
import Header from 'components/Layout/Header'
import Checkbox from 'components/Checkbox'
import { CONFIRMATION_CONTENT_LENGTH } from 'constants/ReceptionCustom'
import { formatConfirmation } from 'utils/formatters'
import Icon from 'components/Icon'
import { ATTACHMENT_CONFIRMATION_MAX_SIZE, ErrorMessages } from 'constants/Attachment'

import { updateConfirmations, clearConfirmations } from '../reducers'
import { patchConfirmation } from '../api'
import { HEADER_HEIGHT } from '../constants'
import FinishToast from '../components/FinishToast'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import * as S from './style'
import { camelizeKeys } from 'humps'
import {
  checkValidFileName,
  ACCEPTED_FILE_IMAGE_CONFIRMING_DRIVER,
  validateConfirmingDriver,
} from 'utils/helper'

export const Row = ({
  item,
  onChangeConfirmation,
  onChangeRequired,
  onChangeDisplay,
  onChangeImportant,
  onRemoveImage,
  validationResult,
  onUpdateImage,
  onChangeAboveTextFlag,
  openError,
}) => {
  const onInputClick = (event) => {
    event.target.value = ''
  }

  const onChangeFile = (event) => {
    const file = Array.from(event?.target?.files)[0]
    const isOver10mb = file.size >= ATTACHMENT_CONFIRMATION_MAX_SIZE
    let errorMessage = ''

    if (isOver10mb) {
      errorMessage = ErrorMessages.CONFIRMATION_OVER_MAX_SIZE.replace('${fileName}', file?.name)
    } else if (file.name.includes(',')) {
      errorMessage = ErrorMessages.INVALID_FILE_NAME
    } else if (!checkValidFileName(file.name)) {
      errorMessage = ErrorMessages.INVALID_FILE_TYPE.replace('${fileName}', file?.name)
    }

    if (errorMessage) {
      alert(errorMessage)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      onUpdateImage(item, {
        driverConfirmationId: item?.id,
        content: reader?.result,
        name: file?.name,
        aboveTextFlag: false,
        size: file?.size,
      })
    }
    reader.onerror = (error) => {
      openError(error)
    }
  }

  return (
    <div className="mb-4">
      <textarea
        value={formatConfirmation(item?.confirmation) || ''}
        className="w-100"
        style={{ height: 126 }}
        onChange={onChangeConfirmation}
        placeholder="未設定"
        maxLength={CONFIRMATION_CONTENT_LENGTH}
      />
      <S.WrapperUploadFile>
        <S.Box>
          <S.InputFile
            id={`upload-file-${item?.id}`}
            type="file"
            accept={ACCEPTED_FILE_IMAGE_CONFIRMING_DRIVER.join(',')}
            onChange={onChangeFile}
            onClick={onInputClick}
          />
          <S.UploadImageButton htmlFor={`upload-file-${item.id}`}>画像を追加</S.UploadImageButton>
          <S.ListFile>
            {(item?.images || []).map((image) => (
              <S.FileWrapper key={`${image?.id || ''}-${image?.name}`}>
                <S.FileBox>
                  <S.WrapperIconAndNameFile>
                    <S.ImageIcon />
                    {Boolean(image?.id) ? (
                      <a
                        href={`/confirmation/file-preview/${image?.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={image?.name}
                      >
                        {image?.name}
                      </a>
                    ) : (
                      <span title={image?.name}>{image?.name}</span>
                    )}
                  </S.WrapperIconAndNameFile>
                  <S.RemoveIconWrapper>
                    <Icon
                      src="/assets/svgs/trash.svg"
                      alt="削除ボタン"
                      type="button"
                      width={16}
                      height={16}
                      onClick={onRemoveImage}
                    />
                  </S.RemoveIconWrapper>
                </S.FileBox>
                <Checkbox
                  className="ml-2"
                  checked={image?.aboveTextFlag}
                  onChange={onChangeAboveTextFlag}
                  label="画像を文書の上に移動"
                />
              </S.FileWrapper>
            ))}
          </S.ListFile>
        </S.Box>
        <div>
          {item?.confirmation?.length || 0}/{`${CONFIRMATION_CONTENT_LENGTH}`}
        </div>
      </S.WrapperUploadFile>
      <S.WrapperCheckbox>
        <div className="d-flex justify-content-start">
          <div className="mr-3 d-flex">
            <Checkbox
              checked={item?.displayFlag}
              onChange={onChangeDisplay}
              label="事項表示"
              className="mr-3"
            />
            <Checkbox
              className="mr-3"
              checked={item?.checkFlag}
              onChange={onChangeRequired}
              label="既読必須"
            />
            <Checkbox checked={!!item?.importantFlag} onChange={onChangeImportant} label="強調" />
          </div>
        </div>
        {!validationResult?.result && (
          <div className="text-danger">{validationResult?.message}</div>
        )}
      </S.WrapperCheckbox>
    </div>
  )
}

class ConfirmationEditPage extends Component {
  initialConfirmations = []

  constructor(props) {
    super(props)

    const { confirmations } = this.props
    this.initialConfirmations = [...confirmations]
    this.state = {
      imageIdsDelete: [],
      isRegister: false,
    }
  }

  componentDidMount() {
    const { tenantId, history } = this.props

    if (!tenantId) {
      history.push('/reception-custom/confirmation')
    }
  }

  componentWillUnmount() {
    const { clearConfirmations } = this.props
    if (!this.state.isRegister) {
      clearConfirmations([])
    }
  }

  onChangeConfirmation = (e, item) => {
    const { confirmations, updateConfirmations } = this.props
    const { value } = e.target

    if (value?.length <= CONFIRMATION_CONTENT_LENGTH) {
      const newConFirmations = [...confirmations]
      const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)
      newConFirmations[foundIndex] = {
        ...item,
        confirmation: value,
      }

      updateConfirmations(newConFirmations)
    }
  }

  onChangeRequired = (item) => {
    const { confirmations, updateConfirmations } = this.props
    const newConFirmations = [...confirmations]

    const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)
    newConFirmations[foundIndex] = {
      ...item,
      checkFlag: !item?.checkFlag,
    }

    updateConfirmations(newConFirmations)
  }

  onChangeDisplay = (item) => {
    const { confirmations, updateConfirmations } = this.props
    const newConFirmations = [...confirmations]

    const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)
    newConFirmations[foundIndex] = {
      ...item,
      displayFlag: !item?.displayFlag,
    }

    updateConfirmations(newConFirmations)
  }

  onChangeImportant = (item) => {
    const { confirmations, updateConfirmations } = this.props
    const newConFirmations = [...confirmations]

    const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)

    if (foundIndex >= 0) {
      newConFirmations[foundIndex] = {
        ...item,
        importantFlag: !item?.importantFlag,
      }

      updateConfirmations(newConFirmations)
    }
  }

  onRemoveImage = (item) => {
    const { confirmations, updateConfirmations } = this.props
    const { imageIdsDelete } = this.state
    const newConFirmations = [...confirmations]

    const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)

    if (foundIndex >= 0) {
      newConFirmations[foundIndex] = {
        ...item,
        images: [],
      }

      const idImage = _.get(item, 'images[0].id', '')

      if (idImage) {
        this.setState({
          imageIdsDelete: [...imageIdsDelete, idImage],
        })
      }
      updateConfirmations(newConFirmations)
    }
  }

  onUpdateImage = (item, image) => {
    const { confirmations, updateConfirmations } = this.props
    const { imageIdsDelete } = this.state
    const newConFirmations = [...confirmations]

    const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)

    if (foundIndex >= 0) {
      newConFirmations[foundIndex] = {
        ...item,
        images: [image],
      }
      const idImage = _.get(item, 'images[0].id', '')

      if (idImage) {
        this.setState({
          imageIdsDelete: [...imageIdsDelete, idImage],
        })
      }
      updateConfirmations(newConFirmations)
    }
  }

  onChangeAboveTextFlag = (item) => {
    const { confirmations, updateConfirmations } = this.props
    const newConFirmations = [...confirmations]

    const foundIndex = newConFirmations.findIndex((i) => i?.id === item?.id)

    if (foundIndex >= 0) {
      const image = _.get(item, 'images[0]', {})
      newConFirmations[foundIndex] = {
        ...item,
        images: [{ ...image, aboveTextFlag: !image?.aboveTextFlag }],
      }

      updateConfirmations(newConFirmations)
    }
  }

  onClickRegister = async () => {
    const { confirmations, tenantId } = this.props
    const { imageIdsDelete } = this.state

    // convert null to ''
    const convertedConfirmations = confirmations.map((c) => {
      return _.isNil(c?.confirmation) ? { ...c, confirmation: '' } : c
    })
    console.log('convertedConfirmations', convertedConfirmations)

    this.props.startLoading()
    try {
      const res = await patchConfirmation({
        tenantId,
        confirmations: convertedConfirmations,
        imageIdsDelete,
      })
      this.initialConfirmations = camelizeKeys([...res.data])
      this.props.updateConfirmations(camelizeKeys([...res.data]))
      this.setState({
        isRegister: true,
      })
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }
    this.props.openToast(FinishToast)
    this.props.history.push('/reception-custom/confirmation')
  }

  render() {
    const { confirmations, userData, tenants, tenantId, openError } = this.props
    const isValidationError = confirmations.some(
      (confirmation) => !validateConfirmingDriver(confirmation).result
    )
    const selectedTenant = tenants.find((t) => t.id === tenantId) || {}
    const isSystemAdmin = _.get(userData, 'attributes.is_warehouse_admin', false)
    const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

    return (
      <div>
        <Header>
          <div />
        </Header>

        <div
          className="p-3"
          style={{ height: window.innerHeight - HEADER_HEIGHT, overflowY: 'auto' }}
        >
          <div className="mx-auto" style={{ width: 852 }}>
            <div className="pt-3 px-3 mb-3 bg-white border" style={{ overflowY: 'auto' }}>
              <div className="mb-3 font-weight-bold">ドライバーへの伝達事項</div>
              <div className="mb-3">
                受付完了後に、ドライバーに表示される内容です。３件まで表示できます。
              </div>

              <div>
                {confirmations.map((confirmation) => (
                  <Row
                    key={confirmation.id}
                    item={confirmation}
                    onChangeConfirmation={(e) => this.onChangeConfirmation(e, confirmation)}
                    onChangeRequired={() => this.onChangeRequired(confirmation)}
                    onChangeDisplay={() => this.onChangeDisplay(confirmation)}
                    onChangeImportant={() => this.onChangeImportant(confirmation)}
                    onRemoveImage={() => this.onRemoveImage(confirmation)}
                    onUpdateImage={this.onUpdateImage}
                    onChangeAboveTextFlag={() => this.onChangeAboveTextFlag(confirmation)}
                    openError={openError}
                    validationResult={validateConfirmingDriver(confirmation)}
                  />
                ))}
              </div>
            </div>

            <div className="d-flex justify-content-between mb-3">
              <NavLink to="/reception-custom/confirmation">
                <Button outline color="primary" className="text-primary bg-white rounded-pill px-4">
                  もどる
                </Button>
              </NavLink>

              {canUpdate && (
                <Button
                  color="primary"
                  className="rounded-pill px-4"
                  disabled={
                    _.isEqual(this.initialConfirmations, confirmations) || isValidationError
                  }
                  onClick={this.onClickRegister}
                >
                  登録
                </Button>
              )}
            </div>
          </div>
        </div>

        <Prompt
          when={!_.isEqual(this.initialConfirmations, confirmations)}
          message={
            '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const receptionCustom = state.get('receptionCustom')
  return {
    tenantId: receptionCustom.tenantId,
    confirmations: receptionCustom.confirmations,
    tenants: receptionCustom.tenants,
    userData: state.get('containers/App').get('userData').toJSON(),
  }
}

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openError,
  fetchAnything,
  openToast,
  updateConfirmations,
  clearConfirmations,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEditPage)
