import { takeLatest, put, call, all } from 'redux-saga/effects'
import { camelizeKeys, decamelizeKeys } from 'humps'
import EncodingJapanese from 'encoding-japanese'
import FileSaver from 'file-saver'
import _ from 'lodash'

import { compareValue } from 'utils/commonFunctions'
import { DownloadTypes } from 'constants/misc'
import { ROLE_TYPE } from 'containers/IntegratedSetting/constants'
import { startLoading, endLoading, fetchFailed, fetchDataGeneralReceptionsSuccess } from './reducer'
import { getGeneralReceptions, downloadCsv } from './api'
import { ResultGeneralReceptionType } from './type'
import { LOCAL_STORAGE_GENERAL_RECEPTION_ID } from 'containers/IntegratedManagementPage/constants'

function* fetchGeneralReceptions({ payload }: any) {
  try {
    yield put(startLoading())
    const generalReceptionsRes: ResultGeneralReceptionType = yield call(
      getGeneralReceptions,
      DownloadTypes.ACTUAL.label === payload ? ROLE_TYPE.ADMIN : null,
      DownloadTypes.PLAN.label === payload || null
    )
    const generalReceptionsData = camelizeKeys(generalReceptionsRes.data)
    // @ts-ignore
    const { generalReception } = generalReceptionsData

    const localStorageGeneralReceptionId = localStorage.getItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID)
    const receptionFromLocal = _.find(generalReception, (reception) =>
      compareValue(reception.id, localStorageGeneralReceptionId)
    )

    const selectedReception = receptionFromLocal || _.first(generalReception)

    if (!receptionFromLocal) {
      localStorage.setItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID, selectedReception?.id || '')
    }

    yield put(
      fetchDataGeneralReceptionsSuccess(
        camelizeKeys({
          generalReception,
          selectedReception,
        })
      )
    )

    yield put(endLoading())
  } catch ({ message }) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: message || '通信エラーが発生しました' },
    })
  }
}

function* downloadCsvProcess({ payload }: any) {
  try {
    yield put(startLoading())
    //@ts-ignore
    const response = yield call(downloadCsv, decamelizeKeys(payload))
    const { downloadType, encodingType } = payload

    const { data, filename } = response
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(filename)

    if (matches != null && matches[1]) {
      const encodingConvert = downloadType && encodingType === 'UTF-8' ? 'UTF-8' : 'SJIS'
      const charset = downloadType && encodingType === 'UTF-8' ? 'utf-8' : 'shift_jis'

      const result = matches[1].replace(/['"]/g, '')
      const str_array = EncodingJapanese.stringToCode(data)
      //@ts-ignore
      const sjis_array = EncodingJapanese.convert(str_array, encodingConvert, 'UNICODE')
      const uint8_array = new Uint8Array(sjis_array)
      const blob = new Blob([uint8_array], { type: `text/csv;charset=${charset}` })
      FileSaver.saveAs(blob, result)
    }

    yield put(endLoading())
  } catch ({ message }) {
    yield put(fetchFailed())
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: message || '通信エラーが発生しました' },
    })
  }
}

export default function* () {
  yield all([
    takeLatest('FETCH_DATA_GENERAL_RECEPTIONS', fetchGeneralReceptions),
    takeLatest('DOWN_LOAD_GENERAL_CSV', downloadCsvProcess),
  ])
}
