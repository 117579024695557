/**
 * ユーザーのキーボードとマウスの入力を監視する
 */
class InputLogger {
  time = 0
  callback = null
  interval = 0
  limit = 0
  isRunning = false
  isInitialized = false
  isForce = false
  forceLogout = ''
  timerDebug = null

  initialize(interval, limit, callback) {
    this.interval = interval
    this.limit = limit
    this.callback = callback

    if (this.isInitialized) return

    const { document } = global
    document.addEventListener('input', this.handleInput)
    document.addEventListener('mousedown', this.handleInput)
    document.addEventListener('wheel', this.handleInput)
    document.addEventListener('touchstart', this.handleInput)
  }

  handleInput = () => {
    this.time = 0
  }

  handleTimer = () => {
    this.time += this.interval
    console.log('timer', this.time)

    if (this.timerDebug) {
      this.timerDebug(`reload count: ${this.time / 1000}s`)
    }

    if (this.time >= this.limit) {
      this.time = 0
      console.assert(this.callback)
      this.callback(this.isForce, this.forceLogout)
      return
    }

    setTimeout(this.handleTimer, this.interval)
  }

  startLog(isForce = false, forceLogout = '') {
    this.time = 0
    this.isForce = isForce
    this.forceLogout = forceLogout
    this.handleTimer(this.callback)
  }
}

const instance = new InputLogger()

export const createInputLogger = (interval, limit, callback) => {
  instance.initialize(interval, limit, callback)
  return instance
}

export const getInputLogger = () => instance
