import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'
import SlidingPane from 'react-sliding-pane'

import theme from 'vars/theme'

export const NoticeCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 2px 10px;
  border-radius: 20px;
  background-color: ${theme.redMedium2};
  font-size: 12px;
`

export const MenuItem = styled(NavLink)`
  color: white;
  display: flex;
  align-items: center;
  padding: 0 10px 0 24px;
  font-weight: bold;
  height: 44px;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: white;
    background-color: ${theme.blueMedium1};
    text-decoration: none;
    outline: none;
  }
`

export const MenuItemParent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
  padding: 0px 20px;
  color: white;
  font-weight: 700;
  cursor: pointer;
`

export const TriangleIcon = styled.div<{ isOpen: boolean }>`
  width: 0;
  height: 0;
  transition: all 0.5s ease;

  ${({ isOpen }) =>
    isOpen
      ? css`
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-bottom: 13px solid white;
        `
      : css`
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 13px solid white;
        `}
`

export const ChildrenWrapper = styled.div`
  overflow: hidden;
  max-height: ${({ isShowChildren }) => (isShowChildren ? '300px' : '0px')};
  transition: max-height 0.35s ease-in;

  ${MenuItem}, span > ${MenuItem} {
    padding-left: 47px;
    font-weight: 300;
  }
`

export const CustomScrollSidebar = css`
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: ${theme.graySoft1};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.darkBlueSoft1};
    border: 0;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.graySoft1};
  }
`

export const SideBarWrapper = styled(SlidingPane)`
  .slide-pane {
    &__header {
      height: ${theme.headerHeight};
      flex: 0 0 ${theme.headerHeight};
      border-bottom: 0;
      background-color: ${theme.darkBluePrimary};
      color: white;
    }

    &__title {
      font-weight: bold;
    }

    &__content {
      flex: 1 1 100%;
      padding: 0;
      ${CustomScrollSidebar}
    }
  }
`

export const SideBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  min-height: 100%;
  background-color: ${theme.darkBluePrimary};
  padding-bottom: 220px;
`

export const MenuItemText = styled.div`
  position: absolute;
  bottom: 40px;
  text-align: center;
  padding: 0 4px;
  width: 100%;
`

export const ItemText = styled.span`
  display: block;
  ${(props) => props.isFontBold && `font-weight:bold`};
`

export const MenuItemLink = styled.a`
  color: white;
  display: flex;
  align-items: center;
  padding: 0 10px 0 24px;
  font-weight: bold;
  height: 44px;

  &:hover,
  &:focus,
  &:active,
  &.active {
    color: white;
    background-color: ${theme.blueMedium1};
    text-decoration: none;
    outline: none;
  }
`
