import React from 'react'

import styled from 'styled-components'

const Wrapper = styled.span`
  display: flex;
  margin: 4px;

  .import,
  .export {
    font-size: 11px;
    width: 20px;
    height: 16px;
    border: 1px solid ${({ color }) => color};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
  }

  .import {
    border-right: none;
    border-bottom-left-radius: 4px;
  }

  .export {
    border-top-right-radius: 4px;
  }

  .active {
    opacity: 1;
    color: ${({ bgColor }) => bgColor};
    background-color: ${({ color }) => color};
    border: none;
  }
`

function ImportExport({ importFlag, exportFlag, color, bgColor }) {
  const importClass = `import ${importFlag ? 'active' : ''}`
  const exportClass = `export ${exportFlag ? 'active' : ''}`
  return (
    <Wrapper bgColor={bgColor} color={color}>
      <span className={importClass}>卸</span>
      <span className={exportClass}>積</span>
    </Wrapper>
  )
}

export default ImportExport
