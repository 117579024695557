import React from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'

import theme from 'vars/theme'
import { GeneralBookingStatuses } from '../constants'

type Props = {
  status: number
}

const BaseStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  box-sizing: border-box;
  border-radius: 100%;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;

  ${({ status }) => {
    switch (status) {
      case GeneralBookingStatuses.BOOKING.id:
        return css`
          background-color: white;
          color: ${theme.bluePrimary2};
          border: 1px solid;
        `
      case GeneralBookingStatuses.CONFIRM.id:
        return css`
          background-color: ${theme.bluePrimary2};
        `
      case GeneralBookingStatuses.CHECKIN.id:
      case GeneralBookingStatuses.ASSIGN.id:
        return css`
          background-color: ${theme.redPrimary2};
        `
      case GeneralBookingStatuses.DELAY.id:
        return css`
          background-color: ${theme.orangeMedium5};
        `
      case GeneralBookingStatuses.FINISH.id:
        return css`
          background-color: ${theme.grayPrimary1};
        `
    }
  }};
`

const findLabelOfStatus = (id: number): string => {
  const key = _.findKey(GeneralBookingStatuses, (status) => status.id === id)
  // @ts-ignore
  return GeneralBookingStatuses[key]?.labelStatus || ''
}

const BookingStatus: React.FC<Props> = ({ status }) => {
  return <BaseStatus status={status}>{findLabelOfStatus(status)}</BaseStatus>
}

export default BookingStatus
