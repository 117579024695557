import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { compose } from 'redux'

import injectReducer from 'utils/injectReducer'
import {
  BerthSizePageLoader,
  NotificationSoundPageLoader,
  TenantSettingUnitTimePageLoader,
} from './Loader'
import injectSaga from 'utils/injectSaga'
import reducers from './reducers'
import saga from './saga'

const SettingsRouting = (a: any) => (
  <Switch>
    <Route path="/settings/berth" component={BerthSizePageLoader} />
    <Route path="/settings/reception-notification-sound" component={NotificationSoundPageLoader} />
    <Route path="/settings/tenant-unit-time" component={TenantSettingUnitTimePageLoader} />
  </Switch>
)

const STORE_KEY: string = 'settings'

const withReducer = injectReducer({ key: STORE_KEY, reducer: reducers })
const withSaga = injectSaga({ key: STORE_KEY, saga, mode: null })

const composed: any = compose(withReducer, withSaga)
export default composed(SettingsRouting)
