import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Button } from 'reactstrap'
import _ from 'lodash'

import * as S from './styles'
import {
  generalReceptionSelectedSelectors,
  exportParametersSelectors,
  parametersOrderSelectors,
  initialParametersSelectors,
} from '../../selectors'
import {
  updateParameters,
  startLoading,
  endLoading,
  fetchReceptionParameters,
  fetchGeneralReceptions,
} from '../../reducer'
import { pathCardViewOrderGeneralCustomize } from '../../api'
import ErrorSubmitModal from '../../components/ErrorSubmitModal'
import { openToast } from 'containers/App/reducers'
import Tabs from '../../components/Tabs'
import CardItemPreview from '../../components/CardItemPreview'
import FinishToast from '../../components/FinishToast'
import Select from 'components/SelectOptions'
import Icon from 'components/Icon'
import { compareValue } from 'utils/commonFunctions'
import { VEHICLE_NUMBER_COLUMN_NAME } from '../../constants'
import { GeneralReceptionType, ParameterItemType } from '../../type'

const COMPANY_COLUMN_NAME = 'company_name'
const REGEX_IMPORT_ADDITIONAL_COLUMN = /^import_additional_column\d+$/g
const DEFAULT_OPTION_VALUE = 'DEFAULT_OPTION_VALUE'
const EMPTY_VALUE = 'EMPTY_VALUE'
const DEFAULT_ORDER = 0

const CARD_VIEW_ORDER_OPTIONS = [
  { label: 'なし', value: DEFAULT_OPTION_VALUE },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '', value: EMPTY_VALUE },
]

const CardLabelItem = {
  companyName: '運送会社名［車番］',
  packageType: '荷姿',
  vehicleCategory: '車両形態',
  goodsOwner: '荷主',
  goodsName: '荷物名',
  driverName: 'ドライバー氏名',
  goodsAmount: '荷量',
  memo: 'メモ',
  importDeliverySlipNumber: '伝票番号',
  contactNotes: '連絡事項',
}

export interface ICardItemsPageProps {
  generalSelected: GeneralReceptionType
  exportParameters: ParameterItemType[]
  parameters: ParameterItemType[]
  initialParameters: ParameterItemType[]
  updateParameters: (data: any) => void
  startLoading: () => void
  endLoading: () => void
  fetchReceptionParameters: () => void
  fetchGeneralReceptions: () => void
  openToast: (data: any) => void
}

const CardItemsPage: React.FC<ICardItemsPageProps> = ({
  generalSelected,
  exportParameters,
  parameters,
  initialParameters,
  updateParameters,
  startLoading,
  endLoading,
  fetchReceptionParameters,
  openToast,
  fetchGeneralReceptions,
}) => {
  const [messageSubmitError, setMessageSubmitError] = useState('')
  const [isOpenModalSubmitError, setIsOpenModalSubmitError] = useState(false)

  useEffect(() => {
    if (generalSelected?.id) {
      fetchReceptionParameters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSelected?.id])

  const getListCardViewOrder = (parameters: any) => {
    if (!parameters) return []
    return parameters?.filter((parameter: any) => parameter.cardViewOrder)
  }

  const getDataCardPreview = () => {
    return _.map(getListCardViewOrder(parameters), (parameter) => {
      if (REGEX_IMPORT_ADDITIONAL_COLUMN.test(parameter?.columnName)) {
        return {
          label: parameter?.displayName,
          order: parameter?.cardViewOrder,
        }
      }
      return {
        label: compareValue(parameter.columnName, COMPANY_COLUMN_NAME)
          ? CardLabelItem?.companyName
          : parameter?.displayName,
        order: parameter?.cardViewOrder,
      }
    })
  }

  const onChange = (item: any, order: any) => {
    const cloneParameters = _.cloneDeep(parameters) || []
    const selectedItemIndex = cloneParameters.findIndex((parameter: any) =>
      compareValue(parameter.id, item.id)
    )
    const cardViewOrder = compareValue(order, DEFAULT_OPTION_VALUE) ? DEFAULT_ORDER : order
    if (selectedItemIndex >= 0 && Array.isArray(cloneParameters)) {
      const preSelectedIndex = cloneParameters.findIndex((parameter) =>
        compareValue(parameter.cardViewOrder, cardViewOrder)
      )
      if (preSelectedIndex >= 0) {
        cloneParameters[preSelectedIndex] = {
          ...cloneParameters[preSelectedIndex],
          cardViewOrder: DEFAULT_ORDER,
        }
      }

      cloneParameters[selectedItemIndex] = {
        ...item,
        cardViewOrder,
      }
      updateParameters(cloneParameters)
    }
  }

  const checkIsChangedData = () => {
    const initial = _.sortBy(
      _.filter(
        initialParameters,
        (parameter) => !compareValue(parameter?.columnName, VEHICLE_NUMBER_COLUMN_NAME)
      ),
      (parameter) => parameter?.id
    )
    const current = _.sortBy(parameters, (parameter) => parameter?.id)
    return _.isEqual(initial, current)
  }

  const getDataChanged = () => {
    const mGeneralParameter: any[] = []

    _.forEach(parameters || [], (parameter) => {
      if (parameter?.cardViewOrder <= 0) return
      //Update order of vehicle number when order of company name change
      if (compareValue(parameter?.columnName, COMPANY_COLUMN_NAME)) {
        const vehicleNumberParameter = _.find(initialParameters, (initialParameter) =>
          compareValue(initialParameter?.columnName, VEHICLE_NUMBER_COLUMN_NAME)
        )
        if (vehicleNumberParameter) {
          mGeneralParameter.push({
            mGeneralParameterIds: [parameter?.id, vehicleNumberParameter?.id],
            cardViewOrder: parameter?.cardViewOrder,
          })
        }
        return
      }

      //Update order of export when import change
      if (_.includes(parameter?.columnName || '', 'import')) {
        const selectedExportParameter = exportParameters.find(
          (exportParameter: any) =>
            compareValue(exportParameter?.displayName, parameter?.displayName) &&
            compareValue(exportParameter?.displayOrder, parameter?.displayOrder)
        )
        if (selectedExportParameter) {
          mGeneralParameter.push({
            mGeneralParameterIds: [parameter?.id, selectedExportParameter?.id],
            cardViewOrder: parameter?.cardViewOrder,
          })
        }
        return
      }

      mGeneralParameter.push({
        mGeneralParameterIds: [parameter?.id],
        cardViewOrder: parameter?.cardViewOrder,
      })
    })
    return mGeneralParameter
  }

  const onClickRegister = async () => {
    const data = {
      generalReceptionId: generalSelected?.id,
      mGeneralParameter: getDataChanged(),
    }
    startLoading()

    try {
      await pathCardViewOrderGeneralCustomize(data)
    } catch (error) {
      setIsOpenModalSubmitError(true)
      setMessageSubmitError(error?.message)

      return
    } finally {
      endLoading()
    }

    fetchReceptionParameters()
    fetchGeneralReceptions()
    openToast(FinishToast)
  }

  const handleCloseModalSubmitError = () => {
    setIsOpenModalSubmitError(false)
    setMessageSubmitError('')
  }

  const isChangedParameter = checkIsChangedData()

  return (
    <>
      <div className="border px-3 pb-3 mx-auto mb-3 bg-white">
        <Tabs currentTabId="card-items" />

        <div className="text-center">
          <div className="mb-3">
            総合管理画面とバース管理画面のカードに表示する項目を下記から選択して下さい。
          </div>
          <S.PreviewItemWrapper>
            <CardItemPreview />
            <Icon src="/assets/svgs/arrow-right-black.svg" width={20} height={20} />
            <CardItemPreview data={getDataCardPreview()} />
          </S.PreviewItemWrapper>
        </div>

        <div className="m-3 mx-auto" style={{ width: 620 }}>
          <div className="mb-3">
            <table className="mb-3 mx-auto" style={{ width: 600 }}>
              <colgroup>
                <col />
                <col style={{ width: 210 }} />
              </colgroup>
              <thead className="border-bottom">
                <tr>
                  <S.Th className="pl-3 py-3">項目名</S.Th>
                  <S.Th className="text-center py-3">表示順</S.Th>
                </tr>
              </thead>
              <tbody className="border-0">
                {(parameters || []).map((parameter) => (
                  <tr key={parameter.id} className="border-bottom" style={{ height: 48 }}>
                    <td className="pl-3">
                      {parameter.displayName ? (
                        parameter.columnName === COMPANY_COLUMN_NAME ? (
                          CardLabelItem.companyName
                        ) : (
                          parameter.displayName
                        )
                      ) : (
                        <span style={{ color: '#CCC' }}>未設定</span>
                      )}
                    </td>
                    <td className="text-center d-flex justify-content-center">
                      {parameter?.displayFlag && (
                        <Select
                          name="card-view"
                          selectValue={parameter.cardViewOrder || EMPTY_VALUE}
                          options={CARD_VIEW_ORDER_OPTIONS}
                          width={'85px'}
                          handleUpdateData={(value: string) => {
                            onChange(parameter, value)
                          }}
                          isOptionDisabled={(option: any) =>
                            compareValue(EMPTY_VALUE, option.value)
                          }
                        />
                      )}

                      {!parameter.displayFlag && <span style={{ color: '#CCC' }}>受付非表示</span>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {true && (
        <div className="text-right">
          <Button
            color="primary"
            className="rounded-pill px-4"
            disabled={isChangedParameter}
            onClick={onClickRegister}
          >
            登録
          </Button>
        </div>
      )}

      <Prompt
        when={!isChangedParameter}
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
      <ErrorSubmitModal
        message={messageSubmitError}
        isOpen={isOpenModalSubmitError}
        onClickClose={handleCloseModalSubmitError}
      />
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  generalSelected: generalReceptionSelectedSelectors(),
  selectedReceptionId: generalReceptionSelectedSelectors(),
  exportParameters: exportParametersSelectors(),
  parameters: parametersOrderSelectors(),
  initialParameters: initialParametersSelectors(),
})

const mapDispatchToProps = {
  updateParameters,
  startLoading,
  endLoading,
  fetchReceptionParameters,
  openToast,
  fetchGeneralReceptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(CardItemsPage)
