import React, { useState, useEffect } from 'react'
import { NavLink, Prompt, useHistory } from 'react-router-dom'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import Header from 'components/Layout/Header'
import { UserDataType, TenantType, WaitingSettingType } from 'utils/types'
import {
  fetchReceptionParameters,
  updateWaitingSetting,
  updateInitialWaitingSetting,
} from '../reducers'
import { addTimeWaitingSchema, addAverageTimeWaitingSchema } from '../validation'
import { validateYup } from 'utils/validators'
import {
  waitingSettingSelectors,
  initialWaitingSettingSelectors,
  tenantsSelectors,
  tenantIdSelectors,
  warehouseIdSelectors,
} from '../selectors'
import { canUpdateReceptionCustom } from '../ReceptionCustomService'
import { userDataSelectors } from 'containers/App/selectors'
import { startLoading, stopLoading, openToast } from 'containers/App/reducers'
import FinishToast from '../components/FinishToast'
import { receptionCustomService } from 'services'
import { TAB_MODE } from '../constants'
import * as S from './style'

export enum WaitingSettingMode {
  WAITING_TIME = 1,
  AVERAGE_WAITING_TIME = 2,
}

const resetValueInputTime = {
  timeMin: '',
  timeMax: '',
  timeAverage: '',
}

type Props = {
  tenantId: number
  warehouseId: number
  userData: UserDataType
  tenants: TenantType[]
  waitingSetting: WaitingSettingType
  initialWaitingSetting: WaitingSettingType
  startLoading: () => {}
  stopLoading: () => {}
  fetchReceptionParameters: (param: string) => void
  changeWarehouse: (warehouseId: number) => void
  changeTenant: (tenantId: number) => void
  updateWaitingSetting: (params: any) => void
  updateInitialWaitingSetting: (params: any) => void
  openError: (message: string) => void
  openToast: (param: any) => void
}

const TimeSettingPage: React.FC<Props> = ({
  userData,
  tenantId,
  warehouseId,
  tenants,
  waitingSetting,
  initialWaitingSetting,
  startLoading,
  stopLoading,
  openToast,
  fetchReceptionParameters,
  updateWaitingSetting,
  updateInitialWaitingSetting,
}) => {
  const history = useHistory()
  const [error, setError] = useState<any>({})

  const { timeMin, timeMax, timeAverage, type } = waitingSetting

  const isWaitingTimeMode = Boolean(type === WaitingSettingMode.WAITING_TIME)
  const isAverageTimeMode = Boolean(type === WaitingSettingMode.AVERAGE_WAITING_TIME)

  const selectedTenant = tenants.find((t) => t.id === tenantId) || ({} as TenantType)
  const userAttributes = userData?.attributes
  const isSystemAdmin = userAttributes?.is_warehouse_admin
  const canUpdate = canUpdateReceptionCustom(isSystemAdmin, selectedTenant)

  const enabledForWaitingTimeMode = Boolean(timeMax && timeMin)
  const enabledAverageTimeMode = Boolean(timeAverage)
  const errorForWaitingMode =
    isWaitingTimeMode && (error?.timeMin || error?.timeMax || error?.errorMessageApi)
  const errorForAverageMode = isAverageTimeMode && (error?.timeAverage || error?.errorMessageApi)
  const enabledButton = !(enabledForWaitingTimeMode || enabledAverageTimeMode)

  useEffect(() => {
    fetchReceptionParameters(TAB_MODE.TIME_SETTING)
  }, [fetchReceptionParameters])

  useEffect(() => {
    if (
      (!_.isEmpty(userAttributes) && !canUpdate) ||
      (!_.isEmpty(selectedTenant) && !selectedTenant?.canCustomizeReception)
    ) {
      history.push('/reception-custom/waiting')
    }
  }, [canUpdate, history, selectedTenant, userAttributes])

  const handleChangeSettingTimeMode = (WaitingSettingMode: WaitingSettingMode) => {
    setError({})
    updateWaitingSetting({ ...waitingSetting, ...resetValueInputTime, type: WaitingSettingMode })
  }

  const onSubmit = async () => {
    const timeMinValue = Number(timeMin)
    const timeMaxValue = Number(timeMax)
    const timeAverageValue = Number(timeAverage)
    const data = isWaitingTimeMode
      ? { timeMinValue, timeMaxValue, type }
      : { timeAverageValue, type }
    const { errors, isError } = validateYup(
      isWaitingTimeMode ? addTimeWaitingSchema : addAverageTimeWaitingSchema,
      isWaitingTimeMode ? { timeMax, timeMin } : { timeAverage }
    )
    if (isError) {
      setError(errors)
      return
    }

    startLoading()
    try {
      setError({})
      await receptionCustomService.createWaitingSetting({
        ...waitingSetting,
        ...data,
        warehouseId,
        tenantId,
      })
      stopLoading()
      openToast(FinishToast)
      updateInitialWaitingSetting({ ...initialWaitingSetting, ...waitingSetting })
      history.push('/reception-custom/waiting')
    } catch (error) {
      stopLoading()
      const errorMessageApi = error?.message
      setError({ errorMessageApi })
      return
    }
  }

  const handleChange = (evt: any) => {
    let { value, name } = evt.target
    const re = /^\d{0,3}$/
    switch (name) {
      case 'timeMin':
      case 'timeMax':
        if (re.test(value)) {
          updateWaitingSetting({ [name]: value })
        }
        break

      case 'timeAverage':
        if (re.test(value)) {
          updateWaitingSetting({ [name]: value })
        }
        break
    }
  }

  return (
    <div>
      <Header>
        <div />
      </Header>

      <S.TimeSettingWrapper>
        <S.SettingContent>
          <S.Content>
            <div className="mb-3 font-weight-bold">トラック待機時間の設定</div>
            <div className="mb-3">
              トラック1台あたりの待機時間を5分間隔で設定してください。 <br />
              ドライバー受付画面に、待機台数分を乗じた待機時間が表示されます。
            </div>

            <div className="p-3">
              <S.CheckBoxWrapper>
                <S.CheckBox
                  id="waitingTime"
                  name="waitingTime"
                  type="radio"
                  checked={isWaitingTimeMode}
                  onChange={() => {
                    handleChangeSettingTimeMode(WaitingSettingMode.WAITING_TIME)
                  }}
                />
                <S.Label htmlFor="waitingTime">待機時間を範囲で設定</S.Label>
              </S.CheckBoxWrapper>

              <S.TimeSettingContent isFlexCenter>
                <div className="d-flex">
                  <span className="mr-3">
                    最小時間<span className="ml-3">約</span>
                  </span>
                  <S.InputForm
                    type="text"
                    id="timeMin"
                    name="timeMin"
                    value={timeMin}
                    disabled={!isWaitingTimeMode}
                    onChange={handleChange}
                    placeholder="0"
                  />
                  <span className="ml-1">分</span>
                </div>

                <S.MinusIcon></S.MinusIcon>

                <div className="d-flex">
                  <span>
                    最大時間<span className="mx-3">約</span>
                  </span>
                  <S.InputForm
                    type="text"
                    id="timeMax"
                    name="timeMax"
                    disabled={!isWaitingTimeMode}
                    value={timeMax}
                    onChange={handleChange}
                    placeholder="0"
                  />
                  <span className="ml-1">分</span>
                </div>
              </S.TimeSettingContent>

              {errorForWaitingMode && <S.ErrorCustom>{errorForWaitingMode}</S.ErrorCustom>}
            </div>

            <div className="p-3">
              <S.CheckBoxWrapper>
                <S.CheckBox
                  id="averageWaitingTime"
                  name="averageWaitingTime"
                  type="radio"
                  checked={isAverageTimeMode}
                  onChange={() => {
                    handleChangeSettingTimeMode(WaitingSettingMode.AVERAGE_WAITING_TIME)
                  }}
                />
                <S.Label htmlFor="averageWaitingTime">待機時間を平均で設定</S.Label>
              </S.CheckBoxWrapper>

              <S.TimeSettingContent>
                <div className="d-flex">
                  <span>
                    平均時間<span className="mx-3">約</span>
                  </span>
                  <S.InputForm
                    type="text"
                    id="timeAverage"
                    name="timeAverage"
                    checked={isAverageTimeMode}
                    disabled={!isAverageTimeMode}
                    value={timeAverage}
                    onChange={handleChange}
                    placeholder="0"
                  />
                  <span className="ml-1">分</span>
                </div>
                {errorForAverageMode && <S.ErrorCustom>{errorForAverageMode}</S.ErrorCustom>}
              </S.TimeSettingContent>
            </div>
          </S.Content>

          <div className="d-flex justify-content-between mb-3">
            <NavLink to="/reception-custom/waiting">
              <Button outline color="primary" className="text-primary bg-white rounded-pill px-4">
                もどる
              </Button>
            </NavLink>

            {canUpdate && (
              <Button
                color="primary"
                className="rounded-pill px-4"
                disabled={enabledButton || _.isEqual(initialWaitingSetting, waitingSetting)}
                onClick={onSubmit}
              >
                登録
              </Button>
            )}
          </div>
        </S.SettingContent>
      </S.TimeSettingWrapper>

      <Prompt
        when={!_.isEqual(initialWaitingSetting, waitingSetting)}
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  waitingSetting: waitingSettingSelectors(),
  initialWaitingSetting: initialWaitingSettingSelectors(),
  userData: userDataSelectors(),
  tenantId: tenantIdSelectors(),
  warehouseId: warehouseIdSelectors(),
  tenants: tenantsSelectors(),
})

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  openToast,
  fetchReceptionParameters,
  updateWaitingSetting,
  updateInitialWaitingSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSettingPage)
