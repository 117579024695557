import { Tooltip } from 'reactstrap'
import styled from 'styled-components'
import { statusValues, BookingStatuses } from 'constants/misc'
import theme from 'vars/theme'
import Button from 'components/Button'
import LoadingIndicator from 'components/LoadingIndicator'

export const BookingItemWrapper = styled.div`
  background: linear-gradient(
        90deg,
        ${({ bgColor, color }) => (bgColor === theme.whitePrimary ? color : bgColor)} 50%,
        transparent 0
      )
      repeat-x,
    linear-gradient(
        90deg,
        ${({ bgColor, color }) => (bgColor === theme.whitePrimary ? color : bgColor)} 50%,
        transparent 0
      )
      repeat-x,
    linear-gradient(
        0deg,
        ${({ bgColor, color }) => (bgColor === theme.whitePrimary ? color : bgColor)} 50%,
        transparent 0
      )
      repeat-y,
    linear-gradient(
        0deg,
        ${({ bgColor, color }) => (bgColor === theme.whitePrimary ? color : bgColor)} 50%,
        transparent 0
      )
      repeat-y;
  background-size: 8px 6px, 8px 6px, 6px 8px, 6px 8px;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  animation: linearGradientMove 0.3s infinite linear;
  padding: 2px;
  margin-bottom: -6px !important;
  border-radius: 4px;
  height: ${({ actualHeight }) => `${actualHeight + 3}px`};
  width: ${({ itemWidth }) => `${itemWidth + 3}px`};

  @keyframes linearGradientMove {
    100% {
      background-position: 4px 0, -4px 100%, 0 -4px, 100% 4px;
    }
  }
`

export const ItemWrapper = styled.div`
  height: ${({ actualHeight }) => `${actualHeight - 1}px`};
  width: ${({ itemWidth }) => `${itemWidth - 2}px`};
  border-radius: 4px;
  padding: 2px 5px 5px 5px;
  font-size: 11px;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  border: 2px solid ${({ bgColor, color }) => (bgColor === theme.whitePrimary ? color : bgColor)};
  overflow: hidden;
  position: relative;

  .min-width {
    min-width: 40px;
  }

  .margin-top {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
  }

  .booking-title {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 600;
    overflow: hidden;
  }

  .booking-content {
    display: flex;
    align-items: center;
    img {
      height: 14px;
    }
  }

  .quick_status {
    border-radius: 11px;
    font-size: 10px;
    min-width: 22px;
    white-space: nowrap;
    padding: 0 5px;
    cursor: pointer;
    color: ${({ btnBg, bgColor }) => (btnBg !== theme.whitePrimary ? theme.whitePrimary : bgColor)};
    background-color: ${({ btnBg }) => btnBg};
    border: 1px solid ${({ btnBg }) => btnBg};
    border-color: ${({ btnBg, btnBorder }) => btnBorder || btnBg};

    &:focus {
      outline: 0;
    }
  }

  .message_icon_list {
    margin: 4px 0;

    .message_icon_item {
      position: relative;
      margin-right: 4px;

      .message_icon_item__img {
        height: 16px;
        width: 16px;
        background-color: white;
        border: 1px solid white;
        border-radius: 4px;
      }

      .message_icon_item__unseen {
        position: absolute;
        top: -2px;
        right: -2px;
        width: 7px;
        height: 7px;
        background-color: #ff2b2b;
        border: 1px solid white;
        border-radius: 50%;
      }

      .portal_app_icon {
        padding: 2px;
      }
    }
  }

  .vehicle-border {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    border: 1px solid ${({ color }) => color};
  }
`

export const TootipWrapper = styled(Tooltip)`
  .tooltip-inner {
    width: 460px;
    max-width: 460px;
    border: 1px solid ${theme.graySoft2};
    background: ${theme.whitePrimary};
    color: ${theme.darkBlueMedium1};
    border-radius: 4px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    padding: 5px;
    z-index: 10;
  }
  .detail-title {
    text-align: left;
  }
  .tooltip-content {
    word-break: break-word;

    p {
      padding: 0;
      margin: 0;
      font-size: 13px;
      color: ${theme.darkBlueMedium1};
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 22px;
    }
    .tooltip-title {
      opacity: 0.5;
      width: 55px;
      margin-right: 22px;
      text-align: left;
    }
  }
`

export const BookingItemThemes = {
  [statusValues.Unassigned]: {
    color: theme.blueMedium5,
    bgColor: theme.whitePrimary,
    btnBg: theme.redPrimary1,
    iconColor: 'blue',
  },
  [statusValues.Recept]: {
    color: theme.whitePrimary,
    bgColor: theme.redPrimary1,
    btnBg: theme.greenMedium2,
    iconColor: 'gray',
    btnBorder: theme.whitePrimary,
  },
  [statusValues.Booking]: {
    color: theme.blueMedium5,
    bgColor: theme.whitePrimary,
    btnBg: theme.redPrimary1,
    iconColor: 'blue',
  },
  [statusValues.Confirmed]: {
    color: theme.whitePrimary,
    bgColor: theme.blueMedium5,
    btnBg: theme.redPrimary1,
    iconColor: 'gray',
  },
  [statusValues.Delay_Expected]: {
    color: theme.orangeMedium4,
    bgColor: theme.whitePrimary,
    btnBg: theme.greenMedium2,
    iconColor: 'orange',
  },
  [statusValues.Delay]: {
    color: theme.whitePrimary,
    bgColor: theme.orangeMedium4,
    btnBg: theme.redPrimary1,
    iconColor: 'gray',
  },
  [statusValues.Arrival]: {
    color: theme.whitePrimary,
    bgColor: theme.redPrimary1,
    btnBg: theme.greenMedium2,
    iconColor: 'gray',
    btnBorder: theme.whitePrimary,
  },
  [statusValues.Calling]: {
    color: theme.whitePrimary,
    bgColor: theme.greenMedium2,
    btnBg: theme.whitePrimary,
    iconColor: 'gray',
  },
  [statusValues.Working]: {
    color: theme.greenMedium2,
    bgColor: theme.whitePrimary,
    btnBg: theme.redPrimary1,
    iconColor: 'green',
  },
  [statusValues.Finish]: {
    color: theme.whitePrimary,
    bgColor: theme.grayMedium2,
    btnBg: theme.redPrimary1,
    iconColor: 'gray',
  },
  [BookingStatuses.WaitingOne.id]: {
    color: theme.whitePrimary,
    bgColor: '#F1A7C1',
    btnBg: '#F1A7C1',
    iconColor: 'gray',
  },
  [BookingStatuses.WaitingTwo.id]: {
    color: '#F1A7C1',
    bgColor: theme.whitePrimary,
    btnBg: '#F1A7C1',
    iconColor: 'pink',
  },
  [BookingStatuses.PreAssign.id]: {
    color: '#93C7FF',
    bgColor: theme.whitePrimary,
    btnBg: '#93C7FF',
    iconColor: 'lightBlue',
  },
}

export const CustomButton = styled(Button)`
  background: ${theme.blueSoft8};
  border: none;
  border-radius: 8px;
  color: ${theme.blueMedium6};
  height: fit-content;
  min-height: 32px;
  white-space: nowrap;

  &:hover,
  &:active {
    background: ${theme.blueSoft8};
    border: none;
    color: ${theme.blueMedium6};
  }

  &:disabled {
    background: ${theme.grayMedium1};
  }
`

export const CopyButton = styled(Button)`
  background: ${theme.whitePrimary};
  border: solid 1px ${theme.blueSoft9};
  border-radius: 8px;
  color: ${theme.blueSoft9};
  margin-right: 4px;
  white-space: nowrap;
  height: fit-content;
  min-height: 32px;

  &:hover {
    background-color: ${theme.blueSoft9};
    border: solid 1px ${theme.blueSoft9};
    color: ${theme.whitePrimary};
  }
`

export const WrapperListButton = styled.div`
  display: flex;
  justify-content: center;
`

export const LoadingMessageCustomize = styled(LoadingIndicator)`
  width: fit-content;
  margin-right: 4px;

  .sk-circle {
    margin: 0;
  }
`

export const PositionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

export const PositionItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const PositionItemElement = styled.div`
  display: flex;
  flex: 1;
  min-height: 20px;
  width: calc((100% / 2) - 10px);
`

export const PositionItemDescription = styled.p`
  display: flex;
  margin: 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
`

export const TextLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-clamp: 1;
  box-orient: vertical;
  min-width: 25px;
  color: ${({ isHighLightText }) => isHighLightText && 'black'};
`

export const CardTipBookingItem = styled.span`
  display: flex;
  align-items: center;
  align-content: center;
  position: absolute;
  top: -4px;
  left: 35px;
  transform: translateX(-50%);
  background: ${theme.orangeMedium2};
  height: 15px;
  font-size: 10px;
  font-weight: bold;
  color: ${theme.darkBlueSoft2};
  z-index: 99 !important;
  padding: 8px;
  border-radius: 8px;
`
