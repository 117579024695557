import { call, put, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash'

import {
  context,
  LOCAL_STORAGE_GENERAL_RECEPTION_ID,
  BaseGeneralParameterDisplayOrders,
} from './constants'
import {
  getGeneralReceptions,
  getGeneralReceptionCustomize,
  getRoleOfGeneralReception,
} from './api'
import {
  fetchFailed,
  fetchSuccess,
  startLoading,
  updateGeneralReceptionSelected,
  updateRole,
} from './reducer'
import { compareValue } from 'utils/commonFunctions'
import { generalReceptionSelectedSelectors } from './selectors'
import { userDataSelectors } from 'containers/App/selectors'

function* fetchGeneralReceptionsWorker() {
  try {
    yield put(startLoading())
    const res = yield call(getGeneralReceptions)
    const generalReception = res?.data?.generalReception
    yield put(
      fetchSuccess({
        key: 'generalReceptions',
        data: generalReception,
      })
    )

    const localStorageGeneralReceptionId = localStorage.getItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID)
    const receptionFromLocal = _.find(generalReception, (reception) =>
      compareValue(reception.id, localStorageGeneralReceptionId)
    )

    const selectedReception = receptionFromLocal || _.first(generalReception)

    if (!receptionFromLocal) {
      localStorage.setItem(LOCAL_STORAGE_GENERAL_RECEPTION_ID, selectedReception?.id || '')
    }
    yield put(updateGeneralReceptionSelected(selectedReception))
  } catch (error) {
    yield put(fetchFailed(error))
    yield put({
      type: 'OPEN_ERROR',
      payload: { message: error.message || '通信エラーが発生しました' },
    })
  }
}

function* fetchReceptionParametersWorker() {
  try {
    const { id: generalReceptionId } = yield select(generalReceptionSelectedSelectors()) || {}
    yield put(startLoading())
    const result = yield call(getGeneralReceptionCustomize, generalReceptionId)

    const data = result.data
    const baseParameters = _.chain(data.baseParameter)
      .map((parameter) => ({
        ...parameter,
        // @ts-ignore
        displayOrder: BaseGeneralParameterDisplayOrders[parameter.columnName],
      }))
      .sortBy(data.importParameter, (parameter) => parameter.displayOrder)
      .value()

    const parameters = _.sortBy(data.importParameter, (parameter) => parameter.displayOrder)
    const exportParameters = _.sortBy(data.exportParameter, (parameter) => parameter.displayOrder)
    const baseAndImportParameters = baseParameters.concat(parameters)

    yield put(
      fetchSuccess({
        key: 'parameters',
        data: baseAndImportParameters,
      })
    )
    yield put(
      fetchSuccess({
        key: 'exportParameters',
        data: exportParameters,
      })
    )
    yield put(
      fetchSuccess({
        key: 'initialParameters',
        data: baseAndImportParameters,
      })
    )
  } catch (error) {
    yield put(fetchFailed(error))
  }
}

function* getRoleOfGeneralReceptionWorker() {
  try {
    const selectedGeneralReception = yield select(generalReceptionSelectedSelectors())
    const userData = yield select(userDataSelectors())
    const isBelongToCompany = compareValue(
      selectedGeneralReception?.companyId,
      userData?.attributes.company_id
    )
    const isAdminCompanyOfGeneralReception =
      userData?.attributes?.is_warehouse_admin && isBelongToCompany
    if (isAdminCompanyOfGeneralReception) {
      // Becasue it don't call api, i don't use action fetchSuccess
      yield put(updateRole({ isAdminCompany: true }))
    } else {
      yield put(startLoading())
      const resp = yield call(getRoleOfGeneralReception, selectedGeneralReception?.id)
      const { data } = resp || {}
      const isAdminGeneralReception = data?.admin
      const isController =
        data?.controller && !isAdminCompanyOfGeneralReception && !isAdminGeneralReception
      yield put(
        fetchSuccess({
          key: 'role',
          data: { isAdminCompany: false, isAdminGeneralReception, isController },
        })
      )
    }
  } catch (error) {
    yield put(fetchFailed(error))
  }
}

export default function* () {
  yield takeLatest(`${context}/FETCH_GENERAL_RECEPTIONS`, fetchGeneralReceptionsWorker)
  yield takeLatest(`${context}/FETCH_RECEPTION_PARAMETERS`, fetchReceptionParametersWorker)
  yield takeLatest(`${context}/FETCH_ROLE_GENERAL_RECEPTION`, getRoleOfGeneralReceptionWorker)
}
