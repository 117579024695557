// 受付カスタマイズを更新できるかどうか
export const canUpdateReceptionCustom = (isSystemAdmin, tenant) => {
  // システム管理者の場合は常に true
  if (isSystemAdmin) {
    return true
  }

  // 変更不可フラグがセットされている場合
  if (tenant.canCustomizeReceptionSuperAdminOnly) {
    return false
  }

  return tenant.canCustomizeReception
}
