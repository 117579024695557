import React, { FormEvent, memo, useState, useEffect } from 'react'
import _ from 'lodash'
import { decamelizeKeys } from 'humps'
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'

import { compareValue } from 'utils/commonFunctions'
import Icon from 'components/Icon'
import errorMessages from 'utils/errorMessages'
import * as S from './style'
import Button from 'components/Button'
import { Form } from 'reactstrap'
import ImportExportForm from './components/ImportExportForm'
import DriverForm from './components/DriverForm'
import ConfirmModal from 'components/ConfirmModal'
import ConfirmControllerModal from 'components/ConfirmModal/ConfirmControllerModal'
import InputDriverNameModal from 'components/ConfirmModal/InputDriverNameModal'
import { validateBooking } from 'containers/IntegratedManagementPage/validation'
import {
  GeneralBooking,
  Company,
  GeneralMasterData,
  CompanyDetail,
  DriverDetail,
  BookingError,
  GeneralReception,
  DriversControllersMaster,
  VehicleControllerInfo,
  attachment,
  InputSelect,
} from 'containers/IntegratedManagementPage/type'
import {
  bookingSidePanelSelectors,
  generalReceptionsSelectors,
  isProcessingSelectors,
} from 'containers/IntegratedManagementPage/selectors'
import {
  fetchInitialBooking,
  fetchGeneralBookingMaster,
  fetchRemoteCompanies,
  fetchRemoteCompany,
  fetchRemoteDriver,
  fetchRemoteControllerByEmail,
  updateGeneralBooking,
  createGeneralBooking,
  deleteGeneralBooking,
  updateBookingSidePanelData,
  resetBookingSidePanelData,
  updateRemoteDriverName,
} from 'containers/IntegratedManagementPage/reducers'
import { trimData, formatVehicleNumber } from 'utils/commonFunctions'
import { isIpadDevice } from 'utils/helper'
import {
  BookingSidePanelType,
  STANDARD_DAY_FORMAT,
  STANDARD_TIME_FORMAT,
  START_TIME_DAY,
  STANDARD_PLAN_DAY_FORMAT,
  STANDARD_DAY_OTHER_FORMAT,
} from 'containers/IntegratedManagementPage/constants'
import BasicForm from './components/BasicForm'
import ControllerForm from './components/ControllerForm'
import AttachFileForm from './components/AttachFileForm'
import { testMailValidate, isArrayHasValue } from 'utils/helper'
import { userDataSelectors } from 'containers/App/selectors'
import { UserDataType } from 'utils/types'

const defaultOptions: InputSelect[] = [
  {
    value: '',
    label: '未定',
  },
]

type Props = {
  openType: number
  generalReceptionId: number
  bookingId: number
  canDeleteBooking: boolean
  initialBooking: GeneralBooking
  draftBooking: GeneralBooking
  masterData: GeneralMasterData
  remoteCompanies: Company[]
  remoteCompany: CompanyDetail
  remoteDriver: DriverDetail
  remoteController: DriversControllersMaster
  remoteControllerByEmail: VehicleControllerInfo
  isExistController: Boolean
  error: BookingError
  generalReceptions: GeneralReception[]
  isProcessing: boolean
  isControllerRole: boolean
  userData: UserDataType
  fetchInitialBooking: (bookingId: number | string) => void
  fetchGeneralBookingMaster: (generalReceptionId: number) => void
  fetchRemoteCompanies: (generalReceptionId: number) => void
  fetchRemoteCompany: (generalReceptionId: number, companyId: number, type?: string) => void
  fetchRemoteDriver: (driverName: string, driverPhone: string) => void
  fetchRemoteControllerByEmail: (email: string) => void
  updateGeneralBooking: (bookingId: number, data: GeneralBooking) => void
  createGeneralBooking: (data: GeneralBooking) => void
  deleteGeneralBooking: (bookingId: number) => void
  updateBookingSidePanelData: (data: object) => void
  updateRemoteDriverName: (name: string) => void
  resetBookingSidePanelData: () => void
  resetRemoteControllerByEmail: () => void
  resetRemoteDriver: () => void
}

type ModalConfirm = {
  isOpenConfirmModal: boolean
  isOpenInputDriverNameModal?: boolean
  message: string
  type: string
}

type ModalConfirmController = {
  isOpen: boolean
  message: {
    companyName?: String
    vehicleControllerName?: string
  }
}

const isOpenSlidePanel = (openType: number): boolean => {
  const { CREATE, EDIT } = BookingSidePanelType
  return [CREATE, EDIT].includes(openType)
}

const BookingSidePanel: React.FC<Props> = ({
  openType,
  generalReceptionId,
  bookingId,
  canDeleteBooking,
  initialBooking,
  draftBooking,
  masterData,
  remoteCompanies,
  remoteCompany,
  remoteDriver,
  remoteController,
  isExistController,
  error,
  generalReceptions,
  remoteControllerByEmail,
  isProcessing,
  isControllerRole,
  userData,
  fetchInitialBooking,
  fetchGeneralBookingMaster,
  fetchRemoteCompanies,
  fetchRemoteCompany,
  fetchRemoteDriver,
  fetchRemoteControllerByEmail,
  updateGeneralBooking,
  createGeneralBooking,
  deleteGeneralBooking,
  updateBookingSidePanelData,
  updateRemoteDriverName,
  resetBookingSidePanelData,
}) => {
  const initialInfoModalConfirm: ModalConfirm = {
    isOpenConfirmModal: false,
    message: '',
    type: '',
    isOpenInputDriverNameModal: false,
  }

  const initialConfirmControllerModal: ModalConfirmController = {
    isOpen: false,
    message: {
      companyName: '',
      vehicleControllerName: '',
    },
  }
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [isFocusEmailController, setIsFocusEmailController] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [infoOfConfirmModal, setInfoOfConfirmModal] = useState<ModalConfirm>(
    initialInfoModalConfirm
  )
  const [
    infoConfirmControllerModal,
    setInfoConfirmControllerModal,
  ] = useState<ModalConfirmController>(initialConfirmControllerModal)
  const [isControllerBookingAssignByOther, setIsControllerBookingAssignByOther] = useState(false)

  // Check to disabled booking if user is a controller , is editing and the booking is created by the other
  useEffect(() => {
    if (isControllerRole && openType === BookingSidePanelType.EDIT) {
      setIsControllerBookingAssignByOther(!compareValue(initialBooking.createdBy, userData.id))
    } else {
      setIsControllerBookingAssignByOther(false)
    }
  }, [userData, openType, initialBooking, isControllerRole])

  const handleChangeDraftBooking = (data: object) => {
    updateBookingSidePanelData({
      draftBooking: {
        ...draftBooking,
        ...data,
      },
    })
  }

  const resetRemoteCompany = () => {
    updateBookingSidePanelData({
      remoteCompany: {},
    })
  }

  const resetRemoteDriver = () => {
    updateBookingSidePanelData({
      remoteDriver: {},
    })
  }

  const resetRemoteControllers = () => {
    updateBookingSidePanelData({
      remoteControllerByEmail: {},
      remoteController: {},
    })
  }

  //reset error when change value
  useEffect(() => {
    if (!_.isEmpty(error)) {
      updateBookingSidePanelData({
        error: {},
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draftBooking])

  // Fetch driver company info
  useEffect(() => {
    const { driverCompanyId } = draftBooking
    const isSameInitial =
      driverCompanyId && compareValue(driverCompanyId, initialBooking.driverCompanyId)
    const isExistDriver = driverCompanyId && _.some(remoteCompanies, ['id', +driverCompanyId])
    if (isOpenSlidePanel(openType) && (isExistDriver || isSameInitial))
      fetchRemoteCompany(generalReceptionId, driverCompanyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openType, draftBooking.driverCompanyId])

  // Fetch vehicle controller info
  useEffect(() => {
    const { companyId } = draftBooking
    const isSameInitial = companyId && compareValue(companyId, initialBooking.companyId)
    const isExistDriver = companyId && _.some(remoteCompanies, ['id', +companyId])
    if (isOpenSlidePanel(openType) && (isExistDriver || isSameInitial))
      fetchRemoteCompany(generalReceptionId, Number(companyId), 'vehicle_controller')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openType, draftBooking.companyId])

  // Fetch driver info
  useEffect(() => {
    const { driverId, driverName, driverPhone } = draftBooking
    if (driverPhone && driverPhone.length === 11) {
      const driverMaster = _.get(remoteCompany, 'drivers.data', [])
      const hasDriver = driverMaster.some((it: any) => compareValue(it.id, driverId))
      if (!hasDriver && driverId !== initialBooking.driverId) {
        fetchRemoteDriver(driverName, driverPhone)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openType, draftBooking.driverPhone])

  //show modal confirm of driver
  useEffect(() => {
    if (isOpenSlidePanel(openType)) {
      // @ts-ignore
      const { driverName, isNewDriverName } = remoteDriver
      if (!_.isEmpty(remoteDriver) && !isNewDriverName) {
        setInfoOfConfirmModal({
          isOpenConfirmModal: !!driverName,
          isOpenInputDriverNameModal: !driverName,
          type: 'driver',
          message: errorMessages.HomePage.driverExit(decamelizeKeys(remoteDriver)),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openType, remoteDriver])

  //show modal confirm of controller
  useEffect(() => {
    if (isOpenSlidePanel(openType)) {
      setIsFocusEmailController(false)
      if (!_.isEmpty(remoteControllerByEmail)) {
        const { companyName, vehicleControllerName } = remoteControllerByEmail || {}
        setInfoConfirmControllerModal({
          isOpen: true,
          message: {
            companyName,
            vehicleControllerName,
          },
        })
      } else if (isSubmitting) {
        setIsSubmitting(false)
        handleSubmit()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openType, remoteControllerByEmail])

  const formatDataSubmit = () => {
    const dataTrim = trimData(draftBooking)
    const { planStartDate, planEndDate, needConfirmFlag, attachments, vehicleNumber } = dataTrim

    const draftBookingFormat = {
      ...dataTrim,
      planStartDate: planStartDate
        ? moment(planStartDate, STANDARD_DAY_OTHER_FORMAT).format(STANDARD_PLAN_DAY_FORMAT)
        : null,
      planEndDate: planEndDate
        ? moment(planEndDate, STANDARD_DAY_OTHER_FORMAT).format(STANDARD_PLAN_DAY_FORMAT)
        : null,
      needConfirmFlag: needConfirmFlag ? 0 : 1,
      vehicleNumber: formatVehicleNumber(vehicleNumber),
      // Format file attach: only submit new file
      attachments: isArrayHasValue(attachments)
        ? attachments.filter((attachment: any) => !attachment.id)
        : [],
    }
    return draftBookingFormat
  }

  const handleSubmit = () => {
    const dataSubmit = formatDataSubmit()

    const error = validateBooking(dataSubmit)
    if (!_.isEmpty(error)) {
      updateBookingSidePanelData({ error })
      return
    }
    if (openType === BookingSidePanelType.EDIT) {
      updateGeneralBooking(bookingId, dataSubmit)
      return
    }
    if (openType === BookingSidePanelType.CREATE) {
      const dataCreate = {
        ...dataSubmit,
        generalReceptionId,
      }
      createGeneralBooking(dataCreate)
      return
    }
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (isFocusEmailController) return
    handleSubmit()
  }

  const handleOnBlurControllerEmail = (e: any): void => {
    const { type } = e.relatedTarget || {}
    const { vehicleControllerEmail } = draftBooking
    if (testMailValidate(vehicleControllerEmail)) {
      type === 'submit' ? setIsSubmitting(true) : setIsSubmitting(false)
      fetchRemoteControllerByEmail(vehicleControllerEmail)
    } else {
      setIsFocusEmailController(false)
    }
  }

  const handleOpenDeleteModal = () => {
    setIsOpenDeleteModal(true)
  }

  const handleConfirmDeleteModal = () => {
    setIsOpenDeleteModal(false)
    closeSidePanel()
    deleteGeneralBooking(bookingId)
  }

  const handleCancelDeleteModal = () => {
    setIsOpenDeleteModal(false)
  }

  const handleConfirmSetDriverMaster = async ({ newDriverName }: { newDriverName: string }) => {
    const { driverId, driverEmail, driverPhone } = remoteDriver
    let driverName = remoteDriver.driverName

    if (!driverName) {
      updateRemoteDriverName(newDriverName || '')
      driverName = newDriverName
    }
    updateBookingSidePanelData({
      draftBooking: {
        ...draftBooking,
        driverId,
        driverName,
        driverEmail,
        driverPhone,
      },
    })

    setInfoOfConfirmModal(initialInfoModalConfirm)
  }

  const handleCancelSetDriverMaster = () => {
    updateBookingSidePanelData({
      draftBooking: {
        ...draftBooking,
        driverId: '',
        driverName: '',
        driverEmail: '',
        driverPhone: '',
      },
    })
    resetRemoteDriver()
    setInfoOfConfirmModal(initialInfoModalConfirm)
  }

  const handleConfirmSetControllerMaster = async () => {
    const {
      companyId,
      companyName,
      vehicleControllerEmail,
      vehicleControllerPhone,
      vehicleControllerId,
      vehicleControllerName,
    } = remoteControllerByEmail
    handleChangeDraftBooking({
      companyId: companyId || '',
      companyName: companyName || '',
      vehicleControllerEmail: vehicleControllerEmail || '',
      vehicleControllerPhone: vehicleControllerPhone || '',
      vehicleControllerId: vehicleControllerId || '',
      vehicleControllerName: vehicleControllerName || '',
    })

    setInfoConfirmControllerModal(initialConfirmControllerModal)
  }

  const handleCancelSetControllerMaster = () => {
    const {
      vehicleControllerEmail,
      vehicleControllerPhone,
      vehicleControllerId,
      vehicleControllerName,
    } = remoteControllerByEmail
    handleChangeDraftBooking({
      vehicleControllerEmail: vehicleControllerEmail || '',
      vehicleControllerPhone: vehicleControllerPhone || '',
      vehicleControllerId: vehicleControllerId || '',
      vehicleControllerName: vehicleControllerName || '',
    })
    setInfoConfirmControllerModal(initialConfirmControllerModal)
  }

  useEffect(() => {
    if (openType === BookingSidePanelType.EDIT) {
      fetchInitialBooking(bookingId)
    }

    // When create booking:
    // - If the user is the controller, auto fill data to driver controller form
    // - Else fill init data
    if (openType === BookingSidePanelType.CREATE) {
      const { company_id, company_name, name, email, tel_number } = userData?.attributes || {}

      handleChangeDraftBooking({
        generalReceptionId: '',
        companyId: isControllerRole ? company_id : '',
        companyName: isControllerRole ? company_name : '',
        vehicleControllerId: isControllerRole ? userData?.id : '',
        vehicleControllerName: isControllerRole ? name : '',
        vehicleControllerPhone: isControllerRole ? tel_number : '',
        vehicleControllerEmail: isControllerRole ? email : '',
        planStartTime: '',
        planEndTime: '',
        planStartDate: moment().format(STANDARD_DAY_FORMAT),
        planEndDate: moment().format(STANDARD_DAY_FORMAT),
        id: 0,
        driverCompanyName: '',
        driverCompanyId: '',
        driverEmail: '',
        driverId: '',
        driverName: '',
        driverPhone: '',
        exportContactNotes: '',
        exportDeliverySlipNumber: '',
        exportFlag: 0,
        exportGoodsAmount: '',
        exportGoodsName: '',
        exportGoodsOwner: '',
        exportMainGoods: '',
        exportPackageType: '',
        importContactNotes: '',
        importDeliverySlipNumber: '',
        importFlag: 0,
        importGoodsAmount: '',
        importGoodsName: '',
        importGoodsOwner: '',
        importMainGoods: '',
        importPackageType: '',
        vehicleCategory: '',
        vehicleNumber: '',
      })

      if (isControllerRole) {
        fetchRemoteCompany(generalReceptionId, company_id, 'vehicle_controller')
      }
    }

    if (isOpenSlidePanel(openType)) {
      fetchGeneralBookingMaster(generalReceptionId)
      fetchRemoteCompanies(generalReceptionId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openType, isControllerRole])

  const closeSidePanel = () => {
    if (isProcessing) return
    resetBookingSidePanelData()
  }

  const onHandleBasicInfoChange = async (
    value: string | number | boolean | attachment[] | number[],
    name: string
  ) => {
    if (name === 'companyId' && !value) {
      handleChangeDraftBooking({
        companyId: '',
        companyName: '',
        vehicleControllerId: '',
        vehicleControllerName: '',
        vehicleControllerPhone: '',
        vehicleControllerEmail: '',
      })
      return
    }

    if (name === 'vehicleControllerId' && !value) {
      handleChangeDraftBooking({
        vehicleControllerId: '',
        vehicleControllerName: '',
        vehicleControllerPhone: '',
        vehicleControllerEmail: '',
      })
      return
    }
    let endTime = {}
    let newValue: string | number | boolean | attachment[] | number[] = value
    if (
      name === 'planStartTime' &&
      moment(initialBooking.planEndTime || START_TIME_DAY, STANDARD_TIME_FORMAT).isBefore(
        // @ts-ignore
        moment(value, STANDARD_TIME_FORMAT)
      )
    ) {
      endTime = {
        planEndTime: value,
      }
    } else if (name === 'planStartDate' || name === 'planEndDate') {
      // @ts-ignore
      newValue = moment(value).format(STANDARD_DAY_FORMAT)
    }

    handleChangeDraftBooking({
      [name]: newValue || '',
      ...endTime,
    })
  }

  const getDefaultCompanyList = (): InputSelect[] => {
    const remoteCompanyOptions = remoteCompanies.map((company) => ({
      value: `${company.id}`,
      label: company.attributes.companyName,
    }))

    const allOptionsFormat = _.uniqBy(
      remoteCompanyOptions.filter((it) => it.value),
      (it: InputSelect) => it.value.toString()
    )

    return defaultOptions.concat(allOptionsFormat)
  }

  return (
    <div>
      <S.SlidingPaneWrapper
        isOpen={
          openType === BookingSidePanelType.CREATE ||
          (isOpenSlidePanel(openType) && !_.isEmpty(initialBooking))
        }
        from="right"
        width={'380px'}
        onRequestClose={closeSidePanel}
        onAfterOpen={(rootModal: any) => {
          if (isIpadDevice) {
            setTimeout(() => {
              _.set(rootModal, 'overlayEl.style.position', 'absolute')
            }, 500)
          }
        }}
      >
        {(openType === BookingSidePanelType.EDIT || openType === BookingSidePanelType.CREATE) && (
          <S.WrapperModal
            type={openType === BookingSidePanelType.EDIT ? 'edit' : 'create'}
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <S.ModalHeader>
              <span>
                {openType === BookingSidePanelType.EDIT ? '総合受付予約変更' : '総合予約新規'}
              </span>
              <span onClick={closeSidePanel}>
                <Icon src="/assets/svgs/close-icon.svg" height="12px" width="12px" />
              </span>
            </S.ModalHeader>
            {openType === BookingSidePanelType.EDIT &&
              canDeleteBooking &&
              !isControllerBookingAssignByOther && (
                <Button size="sm" className="btn-delete" onClick={handleOpenDeleteModal}>
                  削除
                </Button>
              )}
            <Form className="reservation-form mt-0 custom-scrollbar" noValidate>
              <BasicForm
                disabledEdit={isControllerBookingAssignByOther}
                openType={openType}
                draftBooking={draftBooking}
                error={error}
                onChange={onHandleBasicInfoChange}
              />
              <ControllerForm
                userData={userData}
                defaultCompanyList={getDefaultCompanyList()}
                disabledEdit={isControllerBookingAssignByOther}
                draftBooking={draftBooking}
                initialBooking={initialBooking}
                remoteController={remoteController}
                remoteControllerByEmail={remoteControllerByEmail}
                isExistController={isExistController}
                onChange={onHandleBasicInfoChange}
                handleChangeDraftBooking={handleChangeDraftBooking}
                setIsFocusEmailController={setIsFocusEmailController}
                handleOnBlurControllerEmail={handleOnBlurControllerEmail}
                resetRemoteControllers={resetRemoteControllers}
                error={error}
              />
              <DriverForm
                defaultCompanyList={getDefaultCompanyList()}
                initialBooking={initialBooking}
                draftBooking={draftBooking}
                masterData={masterData}
                remoteCompany={remoteCompany}
                remoteDriver={remoteDriver}
                error={error}
                handleChangeDraftBooking={handleChangeDraftBooking}
                resetRemoteCompany={resetRemoteCompany}
              />
              <ImportExportForm
                openType={openType}
                draftBooking={draftBooking}
                masterData={masterData}
                error={error}
                handleChangeDraftBooking={handleChangeDraftBooking}
              />
              <AttachFileForm
                draftBooking={draftBooking}
                error={error}
                onChange={onHandleBasicInfoChange}
                handleChangeDraftBooking={handleChangeDraftBooking}
              />
              <S.FixedButtonWrapper>
                <S.SubmitButton
                  color="blue"
                  type="button"
                  className="w-100"
                  size="sm"
                  disabled={isProcessing}
                  onClick={onSubmit}
                >
                  {openType === BookingSidePanelType.EDIT ? '予約変更' : '予約追加'}
                </S.SubmitButton>
              </S.FixedButtonWrapper>
            </Form>
          </S.WrapperModal>
        )}
      </S.SlidingPaneWrapper>
      <ConfirmModal
        title="この動作によってSMSなどの通知はされませんのでご注意ください"
        isOpen={isOpenDeleteModal}
        handleConfirm={handleConfirmDeleteModal}
        handleCancel={handleCancelDeleteModal}
      />
      <ConfirmModal
        width={480}
        title={infoOfConfirmModal.message}
        isOpen={infoOfConfirmModal.isOpenConfirmModal}
        handleConfirm={handleConfirmSetDriverMaster}
        handleCancel={handleCancelSetDriverMaster}
      />
      <ConfirmControllerModal
        isOpen={infoConfirmControllerModal.isOpen}
        companyName={infoConfirmControllerModal?.message?.companyName}
        vehicleControllerName={infoConfirmControllerModal?.message?.vehicleControllerName}
        handleConfirm={handleConfirmSetControllerMaster}
        handleCancel={handleCancelSetControllerMaster}
      />
      <InputDriverNameModal
        width={550}
        data={remoteDriver}
        isOpen={!!infoOfConfirmModal.isOpenInputDriverNameModal}
        handleConfirm={handleConfirmSetDriverMaster}
        handleCancel={handleCancelSetDriverMaster}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const bookingSidePanel: any = bookingSidePanelSelectors()(state)
  const {
    openType,
    bookingId,
    canDeleteBooking,
    initialBooking,
    draftBooking,
    masterData,
    remoteCompanies,
    remoteCompany,
    remoteDriver,
    remoteController,
    remoteControllerByEmail,
    isExistController,
    error,
  } = bookingSidePanel

  return {
    openType,
    bookingId,
    canDeleteBooking,
    initialBooking,
    draftBooking,
    masterData, // 荷姿(packageTypes) & 車両形能(vehicleCategories)
    remoteCompanies, // 運送会社名
    remoteCompany, // 1 運送会社名
    remoteDriver, // ドライバー名
    remoteControllerByEmail,
    remoteController,
    isExistController,
    error,
    generalReceptions: generalReceptionsSelectors()(state),
    isProcessing: isProcessingSelectors()(state),
    userData: userDataSelectors()(state),
  }
}

const mapDispatchToProps = {
  fetchInitialBooking,
  fetchGeneralBookingMaster,
  fetchRemoteCompanies,
  fetchRemoteCompany,
  fetchRemoteDriver,
  updateGeneralBooking,
  createGeneralBooking,
  deleteGeneralBooking,
  updateBookingSidePanelData,
  resetBookingSidePanelData,
  fetchRemoteControllerByEmail,
  updateRemoteDriverName,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(memo(BookingSidePanel))
