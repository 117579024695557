import { trimData } from 'utils/commonFunctions'
import RestCheckinClient, { getCheckinAuthHeader } from './RestCheckinClient'

export default class ReceptionServices {
  constructor(restService = new RestCheckinClient()) {
    this.restService = restService
  }

  getMasterData(tenant_id) {
    return this.restService.get(
      'tablets/master_data',
      { tenant_id },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getMasterDataForGeneral(general_reception_id) {
    return this.restService.get(
      'general-receptions/master-data',
      { general_reception_id },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getWarehouseList() {
    return this.restService.get(
      'tablets/list_warehouses',
      {},
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getDriverByPhoneNumber(driver_phone) {
    return this.restService.get(
      'tablets/get_driver',
      { driver_phone },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getCompanyInfoOfDriver({ tenant_id, phone_number }) {
    return this.restService.get(
      // Update get company list relate tenant
      // 'tablets/company_info_of_driver',
      'tablets/list_companies',
      { tenant_id, phone_number },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getCompanyInfoOfDriverForGeneral({ general_reception_id, phone_number }) {
    return this.restService.get(
      'general-receptions/list-companies',
      { general_reception_id, phone_number },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getRecentBooking({ driver_phone, warehouse_id, tenant_id }) {
    return this.restService.get(
      'tablets/recent_booking',
      { driver_phone, warehouse_id, tenant_id },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getRecentBookingForGeneral({ general_reception_id, driver_phone }) {
    return this.restService.get(
      'general-booking/recent-booking',
      { general_reception_id, driver_phone },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getBookingDetail({ booking_no, warehouse_id, start_date, tenant_id }) {
    return this.restService.get(
      'tablets/booking_detail',
      { booking_no, warehouse_id, start_date, tenant_id },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getGeneralBookingDetail({ booking_no, general_reception_id, driver_phone }) {
    return this.restService.get(
      'general-booking/booking-and-driver-info',
      { general_booking_no: booking_no, general_reception_id, driver_phone },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  createNewReceptionBooking(data) {
    return this.restService.post({
      url: 'tablets/reception_booking',
      data: trimData(data),
      config: {
        headers: getCheckinAuthHeader(),
      },
    })
  }

  updateReceptionBooking(data) {
    return this.restService.put({
      url: 'tablets/reception_booking',
      data: trimData(data),
      config: {
        headers: getCheckinAuthHeader(),
      },
    })
  }

  getDriverBookingList({ warehouse_id, period_type, driver_phone, tenant_id }) {
    return this.restService.get(
      'tablets/booking_list',
      { warehouse_id, period_type, driver_phone, tenant_id },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getDriverBookingListForGeneral({ general_reception_id, period_type, driver_phone }) {
    return this.restService.get(
      'general-booking/booking-list',
      { general_reception_id, period_type, driver_phone },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  getCountVehicleWaiting(booking_id) {
    return this.restService.get(
      'tablets/count_vehicle_waiting',
      { booking_id },
      {
        headers: getCheckinAuthHeader(),
      }
    )
  }

  updateStatus = (data) =>
    this.restService.patch({
      url: 'tablets/update_status_reception',
      data,
      config: {
        headers: getCheckinAuthHeader(),
      },
    })

  getBookingByDriverPhone = ({ warehouse_id, driver_phone, tenant_id }) =>
    this.restService.get(
      `tablets/checkout_booking?warehouse_id=${warehouse_id}&driver_phone=${driver_phone}&tenant_id=${tenant_id}`,
      {},
      {
        headers: getCheckinAuthHeader(),
      }
    )

  getWarehouseAndTenant = ({ warehouse_id, tenant_id }) =>
    this.restService.get(
      `tablets/base_data?warehouse_id=${warehouse_id}&tenant_id=${tenant_id}`,
      {},
      {
        headers: getCheckinAuthHeader(),
      }
    )

  checkData = ({ warehouse_id, tenant_id }) =>
    this.restService.get(
      `tablets/check_data?warehouse_id=${warehouse_id}&tenant_id=${tenant_id}`,
      {}
    )

  checkinSessionLogin = ({ warehouse_id, tenant_id, password }) =>
    this.restService.post({
      url: 'tablets/sign_in',
      data: { warehouse_id, tenant_id, password },
    })

  checkinSessionLoginForGeneral = ({ general_reception_id, password }) =>
    this.restService.post({
      url: 'general-booking/tablets/sign-in',
      data: { general_reception_id, password },
    })

  checkinSessionLogout = () =>
    this.restService.delete({
      url: 'tablets/sign_out',
      data: null,
      config: {
        headers: getCheckinAuthHeader(),
      },
    })

  verifyCode(data) {
    return this.restService.post({
      url: 'tablets/verify',
      data,
    })
  }

  conFirmPolicyModal({ phone_number, policy_id }) {
    return this.restService.patch({
      url: 'tablets/update_policy_checked',
      data: { phone_number, policy_id },
      config: {
        headers: getCheckinAuthHeader(),
      },
    })
  }
  getMultiTenant(mid) {
    return this.restService.get('tablets/load_multi_tenants', { mid })
  }

  // 複数箇所
  postGeneralBookingCheckin(data) {
    return this.restService.post({
      url: 'general-booking/checkin',
      data,
      config: {
        headers: getCheckinAuthHeader(),
      },
    })
  }

  postGeneralBookingCheckinWithBookingNo(data) {
    return this.restService.patch({
      url: 'general-booking/checkin-by-booking-no',
      data,
      config: {
        headers: getCheckinAuthHeader(),
      },
    })
  }

  getBookingByDriverPhoneForGeneralBooking = (
    { driver_phone, general_reception_id },
    isCheckinByGeneralReceptionNumber
  ) =>
    this.restService.get(
      `general-booking/not-checkout-general?driver_phone=${driver_phone}&general_reception_id=${general_reception_id}`,
      {},
      {
        headers: getCheckinAuthHeader(isCheckinByGeneralReceptionNumber),
      }
    )

  patchGeneralBookingCheckout(booking, isCheckinByGeneralReceptionNumber) {
    return this.restService.patch({
      url: 'general-booking/checkout-general',
      data: booking,
      config: {
        headers: getCheckinAuthHeader(isCheckinByGeneralReceptionNumber),
      },
    })
  }

  fetchGeneralReception = ({ generalReceptionId }) =>
    this.restService.get(
      `general-receptions/detail?general_reception_id=${generalReceptionId}`,
      {},
      {
        headers: getCheckinAuthHeader(),
      }
    )

  checkoutAllNormalBooking = (tenantId, driverPhone) =>
    this.restService.patch({
      url: `tablets/checkout/all?tenant_id=${tenantId}&driver_phone=${driverPhone}`,
      config: {
        headers: getCheckinAuthHeader(),
      },
    })

  checkoutAllGeneralBooking = (
    { generalReceptionId, driverPhone },
    isCheckinByGeneralReceptionNumber
  ) =>
    this.restService.patch({
      url: `general-booking/checkout-general/all?general_reception_id=${generalReceptionId}&driver_phone=${driverPhone}`,
      config: {
        headers: getCheckinAuthHeader(isCheckinByGeneralReceptionNumber),
      },
    })
}
