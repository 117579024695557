import styled from 'styled-components'

import theme from 'vars/theme'

export const Wrapper = styled.div`
  padding: 0 1rem 1rem;
  margin: 0 auto 1.5rem;
  border: 1px solid ${theme.grayPrimary2};
  background-color: ${theme.whitePrimary};
`

export const TextTile = styled.div`
  margin-bottom: 1rem;
  text-align: center;
`

export const Th = styled.th`
  z-index: 2;
  background-color: #f0f0f0;
  padding: 16px 8px;
`

export const Table = styled.table`
  width: 100%;
`

export const ColCustom = styled.col`
  width: 100px;

  &:nth-child(2) {
    width: 110px;
  }

  &:last-child {
    width: 140px;
  }
`
