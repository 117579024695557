import React, { Component } from 'react'

import styled from 'styled-components'

import Button from 'components/Button'
import Icon from 'components/Icon'
import theme from 'vars/theme'
import search from 'assets/svgs/search.svg'

const InputWrapper = styled.div`
  background-color: white;
  border-radius: 3px;
  position: relative;
  margin-left: ${(props) => (props.label ? '16px' : 0)};
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  align-items: center;

  [type='text'] {
    border: 1px solid ${theme.graySoft2};
    border-radius: 3px;
    font-size: 13px;
    height: 34px;
    padding: 0px 35px 0px 10px;
    flex: 1 0 200px;
    min-width: 200px;

    &:focus,
    &:active {
      border: 1px solid ${theme.bluePrimary};
      outline: none;
      box-shadow: 0 0 0 1px ${theme.bluePrimary};
    }
  }

  input::placeholder {
    font-size: 14px;
    color: ${theme.darkBlueMedium2};
    font-weight: normal;
    opacity: 0.5;
  }

  button[type='submit'] {
    margin-left: 10px;
    width: 90px;
    height: 30px;
  }

  input[type='submit'] {
    background: url(${search}) transparent center no-repeat;
    width: 20px;
    border: none;
    position: absolute;
    right: 10px;

    &:focus {
      outline: none;
    }

    &:hover {
      border: 0;
      cursor: pointer;
    }
  }
`

export class SearchForm extends Component {
  handleChange = (e) => {
    this.props.onSearch(e.target.value)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.handleSearch()
  }

  renderSubmitButton() {
    const { haveButton, buttonLabel, disabled } = this.props

    if (!haveButton) return <input type="submit" value="" />

    return (
      <Button
        type="submit"
        color="blue"
        size="sm"
        width="115px"
        onClick={this.onCancel}
        disabled={disabled}
      >
        <Icon src="/assets/svgs/search-white.svg" alt="search-icon" />
        &nbsp;
        {buttonLabel || ''}
      </Button>
    )
  }

  render() {
    const { id, value, placeholder, maxLength, label, className, width } = this.props
    return (
      <form onSubmit={this.handleSubmit} className={className} style={{ width }}>
        <label htmlFor={id} className="d-flex align-items-center">
          {label || ''}
          <InputWrapper width={width}>
            <input
              id={id}
              type="text"
              onChange={this.handleChange}
              value={value}
              placeholder={placeholder}
              maxLength={maxLength}
              autoComplete="off"
            />
            {this.renderSubmitButton()}
          </InputWrapper>
        </label>
      </form>
    )
  }
}

SearchForm.defaultProps = {
  placeholder: '',
  maxLength: '200',
}

export default SearchForm
