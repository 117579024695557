import styled, { css } from 'styled-components'
import theme from 'vars/theme'

const SelectOptionsWrapper = styled.div`
  width: ${(props) => props.width || '100%'};

  .mono-select,
  .mono-creatable {
    &__control {
      height: ${(props) => props.height || 'calc(2rem + 2px)'};
      background: ${(props) => props.color};
      border: ${(props) => (props.color ? 'none' : '')};
      border-color: ${(props) => (props.color ? '' : theme.graySoft2)};
      border-color: ${(props) => props.isError && theme.redMedium1};
      border-radius: 0.3rem;

      &--is-disabled {
        background-color: ${theme.graySoft5};
      }

      &:hover,
      &--is-focused,
      &--is-focused:hover {
        border-color: ${theme.bluePrimary};
        box-shadow: 0 0 0 1px ${theme.bluePrimary};
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator,
    &__indicator:hover {
      ${({ color }) =>
        color &&
        `
        color: white;
      `}
    }

    &__value-container {
      font-size: ${(props) => props.fontSize || '14px'};
    }

    &__placeholder {
      font-size: ${(props) => props.fontSize || '14px'};
      ${({ color }) =>
        color &&
        `
        color: white;
      `}
    }

    &__single-value {
      ${({ color }) =>
        color &&
        `
        color: white;
      `}

      &--is-disabled {
        color: ${theme.darkBlueMedium1};
      }
    }

    &__menu {
      font-size: ${(props) => props.fontSize || '14px'};
      background: ${(props) => props.color};
      z-index: 10;
    }

    &__menu-list {
      padding: 0;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: ${theme.graySoft1};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.darkBlueSoft1};
        border: 0;
      }

      &::-webkit-scrollbar-track {
        background-color: ${theme.graySoft1};
      }
    }

    &__option {
      padding: 8px 12px;
      ${({ color }) =>
        color &&
        `
        color: white;
      `}

      &--is-selected {
        color: white;
        background-color: ${(props) =>
          props.color ? theme.darkBlueMedium1 : theme.darkBlueMedium3};
      }

      &--is-focused,
      &--is-focused:hover {
        color: white;
        background-color: ${(props) => (props.color ? theme.darkBlueMedium1 : theme.bluePrimary)};
      }
    }

    &__control {
      margin-left: 0.25rem;
      max-width: ${(props) => props.maxWidth || '300px'};
    }

    ${(props) =>
      props.isInputDisplay &&
      css`
        &__value-container {
          display: block;
        }

        &__input {
          input {
            width: 100% !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            word-break: normal;
          }
        }
      `};

    ${(props) =>
      props.isClearable &&
      css`
        &__clear-indicator {
          padding: 2px;
          color: white;
          background: ${theme.grayMedium3};
          border-radius: 50%;

          &:hover {
            color: white;
            background: ${theme.grayMedium2};
          }

          svg {
            width: 14px;
            height: 14px;
          }
        }
      `};
  }
`

export { SelectOptionsWrapper }
