import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'

const onClickButton = (onClick, closeModal) => {
  if (onClick) onClick()
  if (closeModal) closeModal()
}

// TODO replace message -> content
// TODO 既に message を使ってるとこがバグらないようにする
export default ({ isOpen, message, content, buttons, closeModal }) => (
  <Modal isOpen={isOpen}>
    <ModalBody className="m-3 text-center font-weight-bold">{content || message}</ModalBody>
    <ModalFooter className="d-flex justify-content-center" style={{ backgroundColor: '#EDEDED' }}>
      {buttons.map((button) => (
        <Button
          key={button.label}
          color={button.color}
          outline={button.outline}
          className={button.className}
          style={button.style}
          onClick={() => onClickButton(button.onClick, closeModal)}
        >
          {button.label}
        </Button>
      ))}
    </ModalFooter>
  </Modal>
)
