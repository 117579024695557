import { statusValues } from 'constants/misc'
const theme = {
  // Red palette
  redPrimary: '#AD2A58',
  redPrimary1: '#DB2464',
  redPrimary2: '#E11D57',
  redMedium1: '#D7738D',
  redMedium2: '#E59BAB',
  redMedium3: '#F2C3CB',
  redMedium4: '#D7B5BC',
  redMedium5: '#EFEFEF',
  redSoft1: '#FAE1E5',
  redSoft2: '#FEF4F5',
  redSoft3: '#F8EDEF',
  redSoft4: '#EBE1E3',

  // Blue palette
  bluePrimary: '#2C4E73',
  bluePrimary1: '#112040',
  bluePrimary2: '#0073D2',
  blueMedium1: '#278CB5',
  blueMedium2: '#66A6C5',
  blueMedium3: '#9FC5D9',
  blueMedium4: '#A9C2CC',
  blueMedium5: '#247CDB',
  blueMedium6: '#5783ca',
  blueMedium7: '#257CDB',
  blueMedium8: '#073e79 ',
  blueSoft1: '#CBDEE9',
  blueSoft2: '#F5F8FA',
  blueSoft3: '#EEF7FC',
  blueSoft4: '#D9E3E7',
  blueDefault: '#2196F3',
  blueSoft5: 'rgba(36, 124, 219, 0.2)',
  blueSoft6: '#48AFF1',
  blueSoft7: '#E5F2F7',
  blueSoft8: '#d9e3f2',
  blueSoft9: '#3c7dd4',

  // Dark Blue palette
  darkBluePrimary: '#132D49',
  darkBlueMedium1: '#434D63',
  darkBlueMedium2: '#61708F',
  darkBlueMedium3: '#A6B0C5',
  darkBlueMedium4: '#14213e ',
  darkBlueSoft1: '#DCDEE3',
  darkBlueSoft2: '#F7FAFF',

  // Green palette
  greenMedium1: '#249688',
  greenMedium2: '#17BEBB',
  greenMedium3: '#57bbba',

  // white palette
  whitePrimary: '#FFFFFF',

  // Gray pallete
  grayPrimary: '#EAEAEA',
  grayPrimary1: '#9FA0A3',
  grayPrimary2: '#dee2e6',
  grayMedium1: '#A1A6B1',
  grayMedium2: '#A8A9AB',
  grayMedium3: '#CCCCCC',
  graySoft1: '#FAFAFA',
  graySoft2: '#ECECEC',
  graySoft3: '#C1C1C1',
  graySoft4: '#BFC3CF',
  graySoft5: '#E9ECEF',
  graySoft6: '#EDEDED',
  grayHard1: '#AAAAAA',
  graySoft7: '#D6DFE5',
  graySoft8: '#DDDDDD',
  graySoft9: 'rgba(37, 39, 44, 0.32)',
  graySoft10: 'rgba(17, 32, 64, 0.52)',
  graySoft11: 'rgba(168, 169, 171, 0.16)',
  graySoft12: '#dee2e6',
  graySoft13: 'rgba(0, 0, 0, 0.5)',
  graySoft14: '#ced4df',
  graySoft15: '#8d93a0',
  graySoft16: 'rgba(246, 246, 246, 0.52)',
  grayDark: '#7f7f7f',
  graySoft17: '#c4c4c4',
  graySoft18: '#DFE2E6',

  // Orange pallete
  orangeMedium1: '#FAD390',
  orangeMedium2: '#F6B93B',
  orangeMedium3: '#FA983A',
  orangeMedium4: '#EF8A67',
  orangeMedium5: '#F67E5E',

  // Black pallete
  termOfUseBlack: '#25272C',
  blackDark: '#112040',

  // Others palette
  blackSoft: '#25272C',
  brownMedium1: '#4D4F53',

  // Width, Height supports
  minWidth: '1024px',
  sidebarWidth: '220px',
  headerHeight: '60px',
  fixedButtonHeight: '62px',

  // incomplete...
  status: {
    [statusValues.Unassigned]: '#DCDEE3',
    [statusValues.Booking]: '#434D63',
    [statusValues.Confirmed]: '#61708F',
    [statusValues.Delay_Expected]: '#AD2A58',
    [statusValues.Arrival]: '#FAE1E5',
    [statusValues.Recept]: '#DCDEE3',
    [statusValues.Delay]: '#AD2A58',
    [statusValues.Calling]: '#A6B0C5',
    [statusValues.Working]: '#E59BAB',
    [statusValues.Finish]: '#DCDEE3',
    Error: '#E3563C',
  },
}

export default theme
