import { decamelizeKeys } from 'humps'

import RestClient, { getAuthHeader } from 'services/RestClient'
import { getCheckinAuthHeader } from 'services/RestCheckinClient'

const client = new RestClient()

const DEFAULT_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
}

const buildConfig = (url) => {
  const authHeader = url.indexOf('/tablets') > -1 ? getCheckinAuthHeader() : getAuthHeader()
  return {
    headers: {
      ...DEFAULT_CONFIG.headers,
      ...authHeader,
    },
  }
}

export const requestGet = (url, data, useAuth = true) =>
  client.get(url, decamelizeKeys(data), useAuth ? buildConfig(url) : DEFAULT_CONFIG)
