import React from 'react'

import Select from 'react-select'
import { compareValue } from 'utils/commonFunctions'
import { SelectOptionsWrapper } from './style'
import { ErrorText } from '../SingleForm/style'
import { REACT_SELECT_ID_PORTAL } from 'constants/Settings'

class SelectOptions extends React.PureComponent {
  componentDidMount() {
    const { selectValue, handleUpdateData, options } = this.props
    if (!selectValue && options.length) {
      handleUpdateData(options[0].value)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectValue, handleUpdateData, options, skipAutoUpdate } = this.props
    if (!selectValue && !options.length && nextProps.options.length && !skipAutoUpdate) {
      handleUpdateData(nextProps.options[0].value)
    }
    if (nextProps.selectValue && !nextProps.options.length && !selectValue) {
      handleUpdateData('')
    }
  }

  componentDidUpdate() {
    const { options, selectValue, handleUpdateData, skipAutoUpdate } = this.props
    if (options.length && !skipAutoUpdate) {
      const option = options.find((it) => compareValue(it.value, selectValue))
      if (!option) {
        handleUpdateData(options[0].value)
      }
    }
  }

  getValue = () => {
    const { options, selectValue } = this.props
    const value = options.find((item) => compareValue(item.value, selectValue))
    return value || null
  }

  getDefaultSelectValue = () => {
    const { options, defaultSelectValue } = this.props
    const value = options.find((item) => compareValue(item.value, defaultSelectValue))
    return value || null
  }

  handleChange = (obj) => {
    const { isDisabled, handleUpdateData } = this.props
    if (!isDisabled) handleUpdateData(obj.value)
  }

  render() {
    const {
      width,
      className,
      style,
      options,
      placeholder,
      onInputChange,
      hasClass,
      name,
      color,
      isSearchable,
      rest,
      isError,
      label,
      isDisabled,
      errorMessage,
      required,
      selectValue,
      styles,
      isOptionDisabled,
      hasMenuPortalTarget,
      maxWidth,
    } = this.props

    const valueProp = selectValue ? { value: this.getValue() } : null
    return (
      <div className={`d-flex flex-shrink-0 ${label ? 'w-100 mb-2' : ''}`} style={{ width }}>
        {label && (
          // eslint-disable-next-line jsx-a11y/label-has-for
          <label className="label-flex">
            {label}
            {required && <span className="color-red-med1 font-weight-bold">*</span>}
          </label>
        )}
        <div className={`flex-column align-items-start ${label ? 'input-flex' : 'w-100'}`}>
          <SelectOptionsWrapper
            width={width}
            className={className}
            style={style}
            color={color}
            maxWidth={maxWidth}
            isError={isError}
            {...rest}
          >
            <Select
              className={hasClass ? 'ml-1' : ''}
              classNamePrefix="mono-select"
              clearable={false}
              name={name}
              options={options}
              {...valueProp}
              defaultValue={this.getDefaultSelectValue()}
              placeholder={placeholder}
              onChange={this.handleChange}
              isSearchable={isSearchable}
              isDisabled={isDisabled}
              menuPlacement="auto"
              onInputChange={onInputChange}
              noOptionsMessage={() => '選択値なし'}
              styles={styles}
              isOptionDisabled={isOptionDisabled}
              menuPortalTarget={
                hasMenuPortalTarget ? document.getElementById(REACT_SELECT_ID_PORTAL) : undefined
              }
            />
          </SelectOptionsWrapper>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        </div>
      </div>
    )
  }
}

SelectOptions.defaultProps = {
  placeholder: '',
  hasClass: true,
  isSearchable: false,
  skipAutoUpdate: false,
  name: '',
}

export default SelectOptions
