import React, { useState, memo, useEffect } from 'react'
import { Button } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'

import { decryptFunc, compareValue } from 'utils/commonFunctions'
import { GENERAL_CHECKIN } from 'utils/loginStorage'
import NotSupportedBrowser from 'components/NotSupportedBrowser'
import { TitleScreen } from 'containers/ReceiptBooking/style'
import Header from '../components/Header'
import { Wrapper, Content, WrapperTitle } from '../style'
import errorMessages from 'utils/errorMessages'
import InputField from 'containers/ReceiptBooking/components/InputField'
import { postLogin } from '../api'
import { startLoading, stopLoading, openError } from 'containers/App/reducers'
import { INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import { useDispatch } from 'reduxStoreHelper'

const LoginPage = () => {
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState<string>('')
  const { generalReceptionNumber }: any = useParams()
  const history: any = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const dataSessionList = decryptFunc(GENERAL_CHECKIN) || []
    const isLogin = dataSessionList.some((session: any) =>
      compareValue(session.generalReceptionNumber, generalReceptionNumber)
    )

    if (isLogin) {
      history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
    }
  }, [generalReceptionNumber, history])

  const onChangePassword = (value: any) => {
    setPassword(value)
  }

  const onSubmitLogin = async (e: any) => {
    e.preventDefault()

    if (password === '') {
      setError(errorMessages.Receipt.pleaseEnterPassword())
    } else {
      dispatch(startLoading())
      try {
        await postLogin({ generalReceptionNumber, password }, false)
        history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
      } catch (err) {
        // @ts-ignore
        dispatch(openError(err.message, false))
      } finally {
        dispatch(stopLoading())
      }
    }
  }

  return (
    <Wrapper>
      <Header />
      <Content>
        <NotSupportedBrowser />
        <WrapperTitle className="mb-3 p-5">
          <div className="d-flex flex-column justify-content-center">
            <div className="row justify-content-center">
              <TitleScreen>ログイン</TitleScreen>
            </div>
            <div className="text-center mb-4">
              <p>パスワードを入力してログインしてください</p>
            </div>

            <form onSubmit={onSubmitLogin}>
              <div className="row justify-content-center mb-4">
                <InputField
                  width="350px"
                  id="password"
                  name="password"
                  type="password"
                  onChange={onChangePassword}
                  errorMessage={error}
                />
              </div>
              <div className="row justify-content-center">
                <Button
                  type="submit"
                  className="px-5 py-2 rounded-pill text-size24"
                  color="primary"
                >
                  ログインする
                </Button>
              </div>
            </form>
          </div>
        </WrapperTitle>
      </Content>
    </Wrapper>
  )
}

export default memo(LoginPage)
