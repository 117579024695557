import React from 'react'
import FileSaver from 'file-saver'

import Icon from 'components/Icon'
import A from 'components/A'
import {
  ATTACHMENT_EXTENSIONS,
  ATTACHMENT_MAX_NUM,
  TYPE_BLOB_FILE_EXCEL,
} from 'constants/Attachment'
import { openError } from 'containers/App/reducers'
import {
  determineIconOfFileName as determineIcon,
  extractExtensionOfFileName as extractExtension,
  canPreviewFileAttachment,
} from 'utils/commonFunctions'
import { fileCustomServices } from 'services'
import { AttachmentType } from 'utils/types'
import { useDispatch } from 'reduxStoreHelper'

import * as S from './style'

type Props = {
  className: string
  errorMessage?: string
  style: any
  onChange: (e: any) => void
  onClickRemove: (name: string, attachmentId: number) => void
  attachments: Array<any>
}

export const AttachmentList = ({
  attachments,
  onClickRemove,
}: {
  attachments: Array<AttachmentType>
  onClickRemove?: (name: string, attachmentId: number) => void
}) => {
  const dispatch = useDispatch()

  const onClickFileDownload = async (e: React.MouseEvent, attachment: AttachmentType) => {
    try {
      e?.preventDefault()
      const { id, name } = attachment || {}
      const extensionOfFile: String = extractExtension(name) || ''
      const typeOfFile = TYPE_BLOB_FILE_EXCEL[extensionOfFile?.toLowerCase() || ''] || ''

      const resp = await fileCustomServices.getAttachmentFile(id)
      const file = new Blob([resp?.data], {
        type: typeOfFile,
      })
      FileSaver.saveAs(file, name)
    } catch (e) {
      dispatch(openError(e?.message))
    }
  }

  const renderFile = (attachment: AttachmentType) => {
    const { id, name } = attachment || {}

    if (Boolean(id)) {
      if (canPreviewFileAttachment(name)) {
        return (
          <A href={`/file-preview/${id}/${extractExtension(name)}`} newTab title={name}>
            {name}
          </A>
        )
      } else {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className="text-primary"
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              onClickFileDownload(e, attachment)
            }}
          >
            {name}
          </a>
        )
      }
    }

    return <span title={name}>{name}</span>
  }

  return (
    <div className="ml-3">
      {attachments.map((attachment) => (
        <div
          key={`${attachment.id || ''}-${attachment.name}`}
          className="d-flex justify-content-between align-items-center py-1"
          style={{ fontSize: 14, color: '#434D63' }}
        >
          <div className="text-truncate">
            <i className={`${determineIcon(attachment.name)} mr-2`}></i>
            {renderFile(attachment)}
          </div>
          {onClickRemove && (
            <Icon
              src="/assets/svgs/trash.svg"
              className=""
              alt="削除ボタン"
              type="button"
              width={16}
              height={16}
              style={{}}
              onClick={() => {
                onClickRemove(attachment.name, attachment.id)
              }}
            />
          )}
        </div>
      ))}
    </div>
  )
}

export default ({
  attachments,
  errorMessage,
  className,
  style,
  onChange,
  onClickRemove,
}: Props) => (
  <div className={className} style={style}>
    {/* 追加ボタン */}
    <div className="d-flex justify-content-between align-items-center pb-2">
      <h3 className="font-weight-bold">資料添付</h3>
      <div>
        <span className="text-secondary mr-2" style={{ fontSize: 12 }}>
          あと
          <span className="px-1">{ATTACHMENT_MAX_NUM - attachments.length}</span>
          個追加できます
        </span>
        <label
          htmlFor="file-upload"
          className={`btn btn-sm ${
            attachments.length === ATTACHMENT_MAX_NUM
              ? 'btn-outline-dark disabled'
              : 'btn-outline-primary'
          }`}
          style={{ marginBottom: 0 }}
        >
          追加
        </label>
        <input
          type="file"
          id="file-upload"
          className="d-none"
          accept={ATTACHMENT_EXTENSIONS.join(',')}
          multiple
          disabled={attachments.length === ATTACHMENT_MAX_NUM}
          onChange={onChange}
          onClick={(e) => {
            // @ts-ignore
            e.target.value = null
          }}
        />
      </div>
    </div>

    {/* ファイルリスト */}
    {Boolean(attachments.length) && (
      <AttachmentList attachments={attachments} onClickRemove={onClickRemove} />
    )}

    {errorMessage && <S.ErrorText className="">{errorMessage}</S.ErrorText>}
  </div>
)
