import React, { memo } from 'react'
import { Form } from 'reactstrap'
import moment from 'moment'

import SelectOptions from 'components/SelectOptions'
import Button from 'components/Button'
import SingleForm from 'components/SingleForm'
import { CommonWrapper, ButtonGroup } from 'components/Layout/commonLayoutStyles'

import * as S from '../style'
import { GeneralReceptionType } from '../type'
import { useGeneralReception } from '../hooks/useGeneralReception'

type Props = {
  isCreatePage: boolean
  selectedGeneralReception: GeneralReceptionType
  handleCancel: () => void
  hasAdminCompanyPermission: boolean
}

const GeneralReceptionCreatePage: React.FC<Props> = ({
  isCreatePage,
  handleCancel,
  hasAdminCompanyPermission,
}) => {
  const {
    warehouses,
    tenants,
    formData,
    error,
    updateInfo,
    handleOnChange,
    handleSubmit,
    loading,
  } = useGeneralReception(isCreatePage)

  return (
    <S.FormCreateGeneralWrapper>
      <Form className="mono-container w-100 h-100 bg-gray p-4 m-0">
        <CommonWrapper className="custom-scrollbar py-4 d-flex align-items-center">
          <div className="main-wrapper">
            <h2 className="mb-4 text-center">総合受付情報</h2>
            <SingleForm
              maxLength={200}
              id="general_reception_name"
              name="general_reception_name"
              label="総合受付名"
              placeholder="総合受付名"
              required
              value={formData.name}
              onChange={handleOnChange('name')}
              errorMessage={error.name}
            />
            <SingleForm
              maxLength={20}
              id="general_reception_password"
              name="general_reception_password"
              label="チェックインパスワード"
              placeholder="チェックインパスワード"
              required
              value={formData.password}
              onChange={handleOnChange('password')}
              errorMessage={error.password}
            />
            <SingleForm
              maxLength={200}
              id="contact_name"
              name="contact_name"
              label="連絡担当者"
              placeholder="連絡担当者"
              value={formData.contactName}
              onChange={handleOnChange('contactName')}
              errorMessage={error.contactName}
            />
            <SingleForm
              maxLength={11}
              id="contact_phone"
              name="contact_phone"
              label="連絡先電話番号"
              placeholder="連絡先電話番号"
              value={formData.contactPhone}
              onChange={handleOnChange('contactPhone')}
              errorMessage={error.contactPhone}
            />
            <SelectOptions
              name="parentWarehouseId"
              label="倉庫名"
              placeholder="倉庫名"
              options={warehouses.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectValue={formData.parentWarehouseId}
              handleUpdateData={handleOnChange('parentWarehouseId')}
              skipAutoUpdate
              required
              errorMessage={error.parentWarehouseId}
            />
            <SelectOptions
              name="parentTenantId"
              label="親テナント名"
              placeholder="親テナント名"
              options={tenants.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              selectValue={formData.parentTenantId}
              handleUpdateData={handleOnChange('parentTenantId')}
              skipAutoUpdate
              required
              errorMessage={error.parentTenantId}
            />
            <div className="d-flex">
              <SingleForm
                maxLength={200}
                id="checkin_enable_distance"
                name="checkin_enable_distance"
                label="スマホ受付範囲"
                placeholder="スマホ受付範囲"
                type="number"
                value={formData.checkinEnableDistance}
                onChange={handleOnChange('checkinEnableDistance')}
                errorMessage={error.checkinEnableDistance}
              />
              <span className="w-100 font-md d-flex ml-1">m</span>
            </div>
            <SingleForm
              maxLength={200}
              id="latitude"
              name="latitude"
              label="スマホ受付緯度"
              placeholder="スマホ受付緯度"
              type="number"
              value={formData.latitude}
              onChange={handleOnChange('latitude')}
              errorMessage={error.latitude}
            />
            <SingleForm
              maxLength={200}
              id="longitude"
              name="longitude"
              label="スマホ受付経度"
              placeholder="スマホ受付経度"
              type="number"
              value={formData.longitude}
              onChange={handleOnChange('longitude')}
              errorMessage={error.longitude}
            />
            <div className="d-flex w-100 flex-shrink-0">
              <span className="label-flex">利用サービス</span>
              <SingleForm
                className="ml-1"
                id="canGeneralBooking"
                name="canGeneralBooking"
                type="checkbox"
                label="総合予約"
                value={formData.canGeneralBooking}
                onChange={handleOnChange('canGeneralBooking')}
                isCheckType
              />
            </div>
            <div className="d-flex w-100 flex-shrink-0">
              <span className="label-flex"></span>
              <SingleForm
                className="ml-1"
                id="canCustomizeReception"
                name="canCustomizeReception"
                type="checkbox"
                label="受付カスタマイズ"
                value={formData.canCustomizeReception}
                onChange={handleOnChange('canCustomizeReception')}
                isCheckType
                isDisabled={!hasAdminCompanyPermission}
              />
            </div>
            {!isCreatePage && (
              <div className="d-flex mt-5">
                <div className="label-flex" />
                <div className="input-flex">
                  <span className="color-gray-med1 mr-2">
                    {`最終更新時間 : ${moment(updateInfo?.updatedAt).format('YYYY-MM-DD')} ${moment(
                      updateInfo?.updatedAt
                    ).format('HH:mm:ss')}`}
                  </span>
                  <span className="color-gray-med1">{`最終更新者 : ${
                    updateInfo?.lastUpdater || ''
                  }様`}</span>
                </div>
              </div>
            )}
          </div>
        </CommonWrapper>
        <ButtonGroup>
          <div className="main-wrapper">
            <Button size="sm" width="115px" color="lightBlue" onClick={handleCancel}>
              キャンセル
            </Button>
            <div className="ml-auto d-flex">
              {/* Do it in the future */}
              {/* {!isCreatePage && (
                <Button size="sm" color="lightRed" width="120px" onClick={() => {}}>
                  削除
                </Button>
              )} */}
              <Button
                className="ml-3"
                size="sm"
                width="120px"
                color="blue"
                needWait
                disabled={loading}
                onClick={handleSubmit}
              >
                保存する
              </Button>
            </div>
          </div>
        </ButtonGroup>
      </Form>
    </S.FormCreateGeneralWrapper>
  )
}

export default memo(GeneralReceptionCreatePage)
