import React from 'react'

import moment from 'moment'
import { QuickNotificationWrapper } from '../style'
import Icon from 'components/Icon'

const QuickNotification = ({ isOpen, onRequestClose, data }) => (
  <QuickNotificationWrapper isOpen={isOpen}>
    <div role="presentation" onClick={onRequestClose}>
      <Icon src="/assets/svgs/close-icon-white.svg" />
      <div className="item-message">{data.message}</div>
      <div className="item-datetime">{moment(data.created_at).format('LLL')}</div>
    </div>
  </QuickNotificationWrapper>
)

export default QuickNotification
