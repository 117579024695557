import React, { memo, useCallback } from 'react'
import freezeR from 'utils/freezeR'
import { Button } from 'reactstrap'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'
import { camelize } from 'humps'

import { openError } from 'containers/App/reducers'
import Header from '../components/Header'
import ImportExportInputRow from '../components/ImportExportInputRow'
import ImportExportSelectRow from '../components/ImportExportSelectRow'
import PrevStartButton from '../components/PrevStartButton'
import PageNumber from '../components/PageNumber'
import { updateBooking, resetAllData, startLoading, endLoading } from '../reducer'
import * as S from '../style'
import { inputType, GeneralBookingType, GeneralMasterDataType, PackageType } from '../type'
import { INTEGRATED_RECEPTION_URL_BASE } from '../constants'
import { useDispatch } from 'reduxStoreHelper'
import { TEN_DIGITS } from 'constants/misc'
import { postGeneralBookingCheckin, updateGeneralBookingCheckinWithBookingNo } from '../api'
import { masterDataSelectors, driverConfirmationsSelectors } from '../selectors'
import { trimData } from 'utils/commonFunctions'
import { isSelectType } from 'services/Booking'
import { ParameterItemType, ReceptionOptionType } from '../type'
import { ADDITIONAL_COLUMNS_RANGE } from 'constants/ReceptionCustom'
import { ImportFlagCheck } from '../constants'
import { ConfirmationType } from 'containers/IntegratedReceptionCustom/type'

const IMPORT_EXPORT_TYPES: inputType[] = freezeR([
  {
    id: 'import',
    label: '荷降ろし',
  },
  {
    id: 'export',
    label: '荷積み',
  },
])

const IMPORT = 'import'
const EXPORT = 'export'

const REGEX_IMPORT = /^import/

type Props = {
  booking: GeneralBookingType
  isFromBookingNo: boolean
  canGeneralBooking?: boolean
  driverConfirmations?: ConfirmationType[]
  masterData: GeneralMasterDataType
}

const EntryLoadPage: React.FC<Props> = ({
  booking,
  isFromBookingNo,
  canGeneralBooking,
  driverConfirmations,
  masterData,
}) => {
  const history = useHistory()
  const { generalReceptionNumber }: { generalReceptionNumber: string } = useParams()
  const dispatch = useDispatch()
  const packageType: any = _.get(masterData, 'packageTypes', [])
  const tenantParameters = _.get(masterData, 'tenantParameters')
  const importParams = _.get(masterData, 'tenantParameters.import')

  const importTenantParameters = _.chain(importParams)
    .filter((item) => item.displayFlag)
    .sortBy((item) => item.displayOrder)
    .value()

  const isImportExportChecked = !!booking.importFlag || !!booking.exportFlag

  const filteredConfirmations = driverConfirmations?.filter(
    (confirmation: ConfirmationType) => confirmation?.displayFlag
  )

  const handleClickButtonExit = useCallback(() => {
    dispatch(resetAllData())
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
  }, [dispatch, generalReceptionNumber, history])

  const convertToOptions = (items = [], isAdditionalColumn = false, value: any) => {
    const propName = isAdditionalColumn ? 'parameterName' : 'parameterId'
    const options = items
      //@ts-ignore
      .filter(
        (item: ReceptionOptionType) => (value && item[propName] === value) || item.displayFlag
      )
      .map((item: ReceptionOptionType) => ({
        //@ts-ignore
        value: item[propName],
        label: item.parameterName,
      }))

    return options
  }

  const convertImportExportColumns = (booking: { [key: string]: any }) => {
    const additionalColumns: { [key: string]: any } = ADDITIONAL_COLUMNS_RANGE.reduce(
      (ac, id) => ({
        ...ac,
        [`additionalColumn${id}`]: _.get(masterData, `additionalColumn${id}`, []),
      }),
      {}
    )

    return ADDITIONAL_COLUMNS_RANGE.reduce(
      (ac, id) => ({
        ...ac,
        [`importAdditionalColumn${id}`]: convertToOptions(
          additionalColumns[`additionalColumn${id}`],
          true,
          booking[`importAdditionalColumn${id}`]
        ),
        [`exportAdditionalColumn${id}`]: convertToOptions(
          additionalColumns[`additionalColumn${id}`],
          true,
          booking[`exportAdditionalColumn${id}`]
        ),
      }),
      {}
    )
  }

  // check parameter item require and not have value
  const validateRequiredItems = (parameters: ParameterItemType[], booking: GeneralBookingType) =>
    !parameters.some(
      //@ts-ignore
      ({ requiredFlag, columnName } = {}) =>
        //@ts-ignore
        requiredFlag && !booking[camelize(columnName)]?.toString()?.trim()
    )

  const canClickNextScreen = () => {
    const isImportExportEnable = tenantParameters?.base?.some(
      (item: { columnName: string; requiredFlag: any }) =>
        item.columnName === 'import_export_enable' && item.requiredFlag
    )

    if (isImportExportEnable && !booking.importFlag && !booking.exportFlag) {
      return false
    }
    const isImportValid =
      booking.importFlag === 1 ? validateRequiredItems(tenantParameters.import, booking) : true
    const isExportValid =
      booking.exportFlag === 1 ? validateRequiredItems(tenantParameters.export, booking) : true
    return isImportValid && isExportValid
  }

  const handleUpdateBooking = (
    value: any,
    importOrExport: string,
    columnName: string,
    maxLength?: number
  ) => {
    let newValue = value

    const name = camelize(
      importOrExport === IMPORT ? columnName : columnName.replace(REGEX_IMPORT, EXPORT)
    )

    if (['importGoodsAmount', 'exportGoodsAmount'].includes(name)) {
      let num = value.replace(/[^0-9]/g, '')

      if (num > TEN_DIGITS) return

      newValue = num ? Number(num) : ''
    } else {
      if (maxLength && newValue.length > maxLength) {
        newValue = newValue.substr(0, maxLength)
      }
    }
    dispatch(
      updateBooking({
        [name]: newValue,
      })
    )
  }

  const getAdditionalColumnsMaxLength = () =>
    ADDITIONAL_COLUMNS_RANGE.reduce(
      (ac, id) => ({
        ...ac,
        [`import_additional_column${id}`]: 200,
      }),
      {}
    )

  const getMaxLengthOfInput: { [key: string]: number } = {
    import_goods_owner: 255,
    import_goods_name: 255,
    import_goods_amount: 10,
    import_main_goods: 50,
    import_delivery_slip_number: 50,
    import_contact_notes: 255,
    ...getAdditionalColumnsMaxLength(),
  }

  const handleSubmit = async () => {
    const bookingFormat = trimData(booking)

    dispatch(startLoading())
    if (isFromBookingNo) {
      try {
        const resp = await updateGeneralBookingCheckinWithBookingNo(bookingFormat)
        history.push({
          pathname: `${INTEGRATED_RECEPTION_URL_BASE}/entry-finish/${generalReceptionNumber}`,
          state: {
            portalFlag: resp?.data?.portalFlag,
            lineFlag: resp?.data?.lineFlag,
          },
        })
      } catch (error) {
        //@ts-ignore
        dispatch(openError(error.message))
      } finally {
        dispatch(endLoading())
      }
    } else {
      try {
        const resp = await postGeneralBookingCheckin(bookingFormat)
        history.push({
          pathname: `${INTEGRATED_RECEPTION_URL_BASE}/entry-finish/${generalReceptionNumber}`,
          state: {
            portalFlag: resp?.data?.portalFlag,
            lineFlag: resp?.data?.lineFlag,
          },
        })
      } catch (error) {
        //@ts-ignore
        dispatch(openError(error.message))
      } finally {
        dispatch(endLoading())
      }
    }
  }

  const renderImportExportInput = (importExportType: inputType) => {
    const optionMap = {
      importPackageType: convertToOptions(packageType, false, booking.importPackageType),
      exportPackageType: convertToOptions(packageType, false, booking.exportPackageType),
      ...convertImportExportColumns(booking),
    }
    return importTenantParameters.map((parameter: ParameterItemType) => {
      const columnName = camelize(
        importExportType.id === IMPORT
          ? parameter.columnName
          : parameter.columnName.replace(REGEX_IMPORT, EXPORT)
      )

      if (isSelectType(parameter)) {
        return (
          <ImportExportSelectRow
            label={parameter.displayName}
            value={_.get(booking, `${columnName}`)}
            optionMap={optionMap}
            columnName={columnName}
            requiredFlag={parameter.requiredFlag}
            onChange={(value: string | number) =>
              handleUpdateBooking(value, importExportType.id, columnName)
            }
          />
        )
      }
      return (
        <ImportExportInputRow
          label={parameter.displayName}
          value={_.get(booking, `${columnName}`)}
          requiredFlag={parameter.requiredFlag}
          onChange={(value: string | number) =>
            handleUpdateBooking(
              value,
              importExportType.id,
              columnName,
              getMaxLengthOfInput[parameter.columnName]
            )
          }
        />
      )
    })
  }

  return (
    <S.Wrapper>
      <Header />
      <S.EntryLoadPage className="pt-3 px-3">
        <div className="bg-white px-3 pb-3" style={{ borderRadius: 12 }}>
          <div className="text-center mb-3">
            <div className="text-size32 font-weight-bold">入庫/出庫情報を入力</div>
          </div>
          <S.EntryLoadPageContent isImportExportChecked={isImportExportChecked}>
            {IMPORT_EXPORT_TYPES.map((item: inputType) => (
              <S.EntryLoadPageArea hasBorderRight={item.id === IMPORT} key={item.id}>
                <S.EntryLoadPageAreaContent hasBorderRight={item.id === IMPORT} className="p-4">
                  <S.CheckboxCustom
                    isGray={false}
                    label={item.label}
                    className="mb-3"
                    checked={(item.id === IMPORT ? booking?.importFlag : booking?.exportFlag) === 1}
                    onChange={(e: any) => {
                      handleUpdateBooking(
                        e?.target?.checked ? ImportFlagCheck.CHECK : ImportFlagCheck.UNCHECK,
                        IMPORT,
                        `${item.id}Flag`
                      )
                    }}
                  />
                  <S.ImportExportContent
                    isShow={(item.id === IMPORT ? booking?.importFlag : booking?.exportFlag) === 1}
                  >
                    {renderImportExportInput(item)}
                  </S.ImportExportContent>
                </S.EntryLoadPageAreaContent>
              </S.EntryLoadPageArea>
            ))}

            <div
              className="d-flex justify-content-between p-3"
              style={{
                position: 'fixed',
                backgroundColor: '#ededed',
                left: 0,
                right: 0,
                bottom: 0,
              }}
            >
              <div style={{ width: '33%' }}>
                <PrevStartButton onClick={handleClickButtonExit} />
              </div>
              <div className="text-center" style={{ width: '34%' }}>
                <PageNumber
                  page="entry-load"
                  existsDriverConfirmations={Boolean(filteredConfirmations?.length)}
                  isFreePlan={!canGeneralBooking}
                />
              </div>
              <div className="text-right" style={{ width: '33%' }}>
                <Button
                  outline
                  color="primary"
                  className="mr-3 rounded-pill bg-white px-4 text-primary"
                  onClick={() =>
                    history.push(
                      `${INTEGRATED_RECEPTION_URL_BASE}/entry-driver-info/${generalReceptionNumber}`
                    )
                  }
                >
                  もどる
                </Button>

                {Boolean(filteredConfirmations?.length) &&
                  (canClickNextScreen() ? (
                    <Button
                      color="primary"
                      className="rounded-pill px-md-4 px-sm-1 px-0"
                      onClick={() =>
                        history.push(
                          `${INTEGRATED_RECEPTION_URL_BASE}/entry-confirmation/${generalReceptionNumber}`
                        )
                      }
                    >
                      次へ
                    </Button>
                  ) : (
                    <Button color="primary" className="rounded-pill px-md-4 px-sm-1 px-0" disabled>
                      次へ
                    </Button>
                  ))}

                {!filteredConfirmations?.length && (
                  <Button
                    color="primary"
                    className="rounded-pill px-md-4 px-sm-1 px-0"
                    disabled={!canClickNextScreen()}
                    onClick={handleSubmit}
                  >
                    登録完了
                  </Button>
                )}
              </div>
            </div>
          </S.EntryLoadPageContent>
        </div>
      </S.EntryLoadPage>
    </S.Wrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  driverConfirmations: driverConfirmationsSelectors(),
  masterData: masterDataSelectors(),
})

const withConnect = connect(mapStateToProps, null)

export default compose(withConnect)(memo(EntryLoadPage))
