import { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useDispatch } from 'reduxStoreHelper'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { getGeneralReceptionManager, postManageRoleGeneral } from '../api'
import { FIRST_PAGE, PER_PAGE, ROLE_TYPE } from '../constants'
import { UserGeneralReceptionStateType, UserGeneralReceptionType } from '../type'

const initialManagerGeneralState: UserGeneralReceptionStateType = {
  totalPages: 0,
  totalCount: 0,
  page: FIRST_PAGE,
  userGeneralReception: [],
}

const useAddAdmin = () => {
  const [managerGeneral, setManagerGeneral] = useState<UserGeneralReceptionStateType>(
    initialManagerGeneralState
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const params: { generalReceptionId: string } = useParams()
  const dispatch = useDispatch()

  const handleGetManageRoleGeneral = useCallback(
    async (searchingValue: string = keyword, page = managerGeneral.page) => {
      setLoading(true)
      const res = await getGeneralReceptionManager(
        +params.generalReceptionId,
        searchingValue,
        page,
        PER_PAGE
      )
      //@ts-ignore
      setManagerGeneral({
        ...res.data,
        page,
      })

      setLoading(false)
    },
    [keyword, managerGeneral.page, params.generalReceptionId]
  )

  const handleUpdateManageRoleGeneral = useCallback(
    async (manageGeneral: UserGeneralReceptionType) => {
      setLoading(true)

      const { userId, admin } = manageGeneral
      await postManageRoleGeneral({
        generalReceptionId: +params.generalReceptionId,
        userId,
        generalRole: ROLE_TYPE.ADMIN,
        flag: !admin,
      })
      dispatch(
        showFlashMessage({
          message: admin
            ? '総合受付から管理者削除が成功しました。'
            : '総合受付に管理者追加が成功しました。',
        })
      )

      handleGetManageRoleGeneral()
      setLoading(true)
    },
    [dispatch, handleGetManageRoleGeneral, params.generalReceptionId]
  )

  useEffect(() => {
    handleGetManageRoleGeneral()
  }, [handleGetManageRoleGeneral])

  return {
    loading,
    managerGeneral,
    keyword,
    setManagerGeneral,
    setKeyword,
    handleGetManageRoleGeneral,
    handleUpdateManageRoleGeneral,
  }
}

export default useAddAdmin
