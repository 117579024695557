import React, { memo, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { useDispatch } from 'reduxStoreHelper'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import LoadingIndicator from 'components/LoadingIndicator'
import GeneralReceptionInfo from '../components/GeneralReceptionInfo'
import TableData from '../components/TableData'
import {
  TableHeaderTenant,
  TableHeaderManagerGeneralReception,
  TableHeaderControllerGeneralReception,
  NOTIFICATION_METHOD,
  ROLE_TYPE,
} from '../constants'
import {
  GeneralReceptionType,
  GeneralReceptionTenantsType,
  UserGeneralReceptionType,
  UserGeneralReceptionStateType,
  ConfirmModalProps,
} from '../type'
import {
  fetchGeneralReceptionTenants,
  fetchGeneralReceptionAdmin,
  fetchGeneralReceptionController,
} from '../reducer'
import {
  selectedGeneralTenantsReceptionSelectors,
  selectedGeneralReceptionAdminSelectors,
  selectedGeneralReceptionControllerSelectors,
} from '../selectors'
import { FIRST_PAGE, PER_PAGE_OF_MASTER } from '../constants'
import { Wrapper } from '../style'
import { postManageRoleGeneral } from '../api'

type Props = {
  hasAdminCompanyPermission: boolean
  hasManagerGeneralPermission: boolean
  loading: boolean
  selectedGeneralReception: GeneralReceptionType
  selectedGeneralTenantsReception: GeneralReceptionTenantsType
  selectedGeneralReceptionAdmin: {
    totalPages: number
    totalCount: number
    page: number
    userGeneralReception: UserGeneralReceptionType[]
  }
  selectedGeneralReceptionController: UserGeneralReceptionStateType
  hasGeneralReception: boolean,
  fetchGeneralReceptionTenants: (generalReceptionId: number, page: number, perPage: number) => void
  fetchGeneralReceptionAdmin: (generalReceptionId: number, page: number, perPage: number) => void
  fetchGeneralReceptionController: (
    generalReceptionId: number,
    page: number,
    perPage: number
  ) => void
  showConfirmModal: (confirmModalProps: ConfirmModalProps) => void
}

const IntegratedSettingPage: React.FC<Props> = ({
  hasAdminCompanyPermission,
  hasManagerGeneralPermission,
  loading,
  selectedGeneralReception,
  selectedGeneralTenantsReception,
  selectedGeneralReceptionAdmin,
  selectedGeneralReceptionController,
  hasGeneralReception,
  fetchGeneralReceptionTenants,
  fetchGeneralReceptionAdmin,
  fetchGeneralReceptionController,
  showConfirmModal,
}) => {
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedGeneralReception?.id) {
      fetchGeneralReceptionTenants(selectedGeneralReception.id, FIRST_PAGE, PER_PAGE_OF_MASTER)
      fetchGeneralReceptionAdmin(selectedGeneralReception.id, FIRST_PAGE, PER_PAGE_OF_MASTER)
      fetchGeneralReceptionController(selectedGeneralReception.id, FIRST_PAGE, PER_PAGE_OF_MASTER)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGeneralReception?.id])

  const generalReceptionAdmins = useMemo<
    Array<UserGeneralReceptionType & { id: string | number; line: string }>
  >(() => {
    const { userGeneralReception } = selectedGeneralReceptionAdmin

    if (!_.isArray(userGeneralReception)) return []

    return userGeneralReception.map((user) => ({
      id: user.userId,
      line: user.notificationMethod === NOTIFICATION_METHOD.LINE ? 'なし' : '',
      ...user,
    }))
  }, [selectedGeneralReceptionAdmin])

  const generalReceptionControllers = useMemo<
    Array<UserGeneralReceptionType & { id: string | number }>
  >(() => {
    const { userGeneralReception } = selectedGeneralReceptionController

    if (!_.isArray(userGeneralReception)) return []

    return userGeneralReception.map((user) => ({
      id: user.userId,
      ...user,
    }))
  }, [selectedGeneralReceptionController])

  const handleRemoveController = useCallback(
    async (userId: Number) => {
      showConfirmModal({
        title: '削除してよろしいですか？',
        handleConfirm: async (closeModal: () => void) => {
          try {
            await postManageRoleGeneral({
              generalReceptionId: selectedGeneralReception.id,
              userId,
              generalRole: ROLE_TYPE.CONTROLLER,
              flag: false,
            })
            fetchGeneralReceptionController(
              selectedGeneralReception.id,
              FIRST_PAGE,
              PER_PAGE_OF_MASTER
            )
            dispatch(
              showFlashMessage({
                message: '総合受付から配車担当者削除が成功しました。',
              })
            )
          } catch (err) {
            dispatch(
              showFlashMessage({
                isError: true,
                message: '総合受付から配車担当者削除が失敗しました。',
              })
            )
          }

          closeModal()
        },
        handleCancel: (closeModal: () => void) => {
          closeModal()
        },
      })
    },
    [dispatch, fetchGeneralReceptionController, showConfirmModal, selectedGeneralReception.id]
  )

  const isDisabledController = useMemo(() => {
    return (
      !selectedGeneralReception.features?.canGeneralBooking ||
      !(hasAdminCompanyPermission || hasManagerGeneralPermission)
    )
  }, [hasAdminCompanyPermission, hasManagerGeneralPermission, selectedGeneralReception.features])

  if (loading)
    return (
      <div className="w-100 h-100 flex-center">
        <LoadingIndicator isRelative />
      </div>
    )

  return (
    <Wrapper>
      <GeneralReceptionInfo
        numberTenant={selectedGeneralTenantsReception?.totalItem}
        permissions={hasAdminCompanyPermission}
        data={selectedGeneralReception}
        hasGeneralReception={hasGeneralReception}
        onClick={() => history.push(`/multi-setting/${selectedGeneralReception?.id}/edit`)}
      />
      <TableData
        isDisabledHeaderButton={!hasGeneralReception || !hasAdminCompanyPermission}
        data={selectedGeneralTenantsReception?.generalReceptionTenants}
        tableHeader={TableHeaderTenant}
        title="テナント"
        labelHeaderButton="テナント追加"
        currentPage={selectedGeneralTenantsReception?.page}
        totalPage={selectedGeneralTenantsReception?.totalPage}
        handleChangePage={(selected) => {
          fetchGeneralReceptionTenants(selectedGeneralReception.id, selected, PER_PAGE_OF_MASTER)
        }}
        handleHeaderButton={() => {
          history.push(`/multi-setting/${selectedGeneralReception?.id}/tenant/add`)
        }}
      />
      <TableData
        isDisabledHeaderButton={!hasGeneralReception || !hasAdminCompanyPermission}
        data={generalReceptionAdmins}
        tableHeader={TableHeaderManagerGeneralReception}
        title="管理者"
        labelHeaderButton="追加"
        currentPage={selectedGeneralReceptionAdmin?.page}
        totalPage={selectedGeneralReceptionAdmin?.totalPages}
        handleChangePage={(page) => {
          fetchGeneralReceptionAdmin(selectedGeneralReception.id, page, PER_PAGE_OF_MASTER)
        }}
        handleHeaderButton={() => {
          history.push(`/multi-setting/${selectedGeneralReception.id}/add-admin`)
        }}
      />
      <TableData
        isDisabledHeaderButton={!hasGeneralReception || isDisabledController}
        isDisabledTableItemButton={isDisabledController}
        data={generalReceptionControllers}
        tableHeader={TableHeaderControllerGeneralReception}
        title="予約者（限定）"
        labelHeaderButton="追加"
        labelTableItemButton="削除"
        currentPage={selectedGeneralReceptionController?.page}
        totalPage={selectedGeneralReceptionController?.totalPages}
        handleChangePage={(page) => {
          fetchGeneralReceptionController(selectedGeneralReception.id, page, PER_PAGE_OF_MASTER)
        }}
        handleHeaderButton={() => {
          history.push(`/multi-setting/${selectedGeneralReception.id}/driver-controller`)
        }}
        handleTableItemButton={handleRemoveController}
      />
    </Wrapper>
  )
}

const mapStateToProps = createStructuredSelector({
  selectedGeneralTenantsReception: selectedGeneralTenantsReceptionSelectors(),
  selectedGeneralReceptionAdmin: selectedGeneralReceptionAdminSelectors(),
  selectedGeneralReceptionController: selectedGeneralReceptionControllerSelectors(),
})

const mapDispatchToProps = {
  fetchGeneralReceptionTenants,
  fetchGeneralReceptionAdmin,
  fetchGeneralReceptionController,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(memo(IntegratedSettingPage))
