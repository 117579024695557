import styled from 'styled-components'
import SlidingPane from 'react-sliding-pane'
import theme from 'vars/theme'

import Button from 'components/Button'

export const SlidingPaneWrapper = styled(SlidingPane)`
  & .slide-pane__header {
    display: none;
  }

  & .slide-pane__content {
    padding: 0;

    @supports (-webkit-touch-callout: none) {
      position: relative;
    }
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.darkBlueMedium1};
  height: ${theme.headerHeight};
  padding: 10px 24px;
  padding-bottom: 0;
  font-size: 20px;
  font-weight: 500;

  img {
    cursor: pointer;
  }
`

export const FixedButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: ${theme.graySoft1};
  border-top: 1px solid ${theme.graySoft2};
  padding: 10px 18px;
  height: ${theme.fixedButtonHeight};

  @supports (-webkit-touch-callout: none) {
    position: absolute;
  }
`

export const SubmitButton = styled(Button)`
  font-size: 16px !important;
  height: 42px !important;
`

export const WrapperModal = styled.div`
  form {
    height: ${(props) =>
      props.type === 'create'
        ? 'calc((var(--vh, 1vh) * 100) - 114px)'
        : 'calc((var(--vh, 1vh) * 100) - 144px)'};
  }

  .btn-delete {
    width: 100px;
    margin-left: 20px;
  }
`
