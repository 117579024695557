import React, { memo, FC } from 'react'
import styled from 'styled-components'

import theme from 'vars/theme'

const Wrapper = styled.div`
  min-width: 300px;
`

const Button = styled.button`
  display: inline-block;
  flex: 1;
  padding: 0;
  margin-bottom: 10px;
  height: 80px;
  min-width: 100px;
  border: 1px solid ${theme.grayMedium3};
  color: ${theme.blueMedium5};
  font-weight: 700;
  background-color: ${theme.whitePrimary};
  font-size: 48px;
  border-radius: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
`

const EnterButton = styled(Button)`
  flex: 2;
  color: ${theme.whitePrimary};
  background-color: ${theme.blueMedium5};
  padding-left: 10px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
`

const Row = styled.div`
  display: flex;
  column-gap: 10px;
`

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  disabledEnterButton?: boolean
  onClickNumber: (value: string) => void
  onClickEnter: () => void
}

const NumericKeypad: FC<Props> = ({
  disabledEnterButton,
  onClickEnter,
  onClickNumber,
  ...restProps
}) => {
  return (
    <Wrapper {...restProps}>
      <Row>
        <Button onClick={() => onClickNumber('1')}>1</Button>
        <Button onClick={() => onClickNumber('2')}>2</Button>
        <Button onClick={() => onClickNumber('3')}>3</Button>
      </Row>
      <Row>
        <Button onClick={() => onClickNumber('4')}>4</Button>
        <Button onClick={() => onClickNumber('5')}>5</Button>
        <Button onClick={() => onClickNumber('6')}>6</Button>
      </Row>
      <Row>
        <Button onClick={() => onClickNumber('7')}>7</Button>
        <Button onClick={() => onClickNumber('8')}>8</Button>
        <Button onClick={() => onClickNumber('9')}>9</Button>
      </Row>
      <Row>
        <Button onClick={() => onClickNumber('0')}>0</Button>
        <EnterButton disabled={disabledEnterButton} onClick={onClickEnter}>
          決定
        </EnterButton>
      </Row>
    </Wrapper>
  )
}

export default memo(NumericKeypad)
