import React from 'react'
import styled from 'styled-components'

import errorMessage from 'utils/errorMessages'
import { ErrorWrapper } from 'components/Layout/commonLayoutStyles'

import Icon from 'components/Icon'
import { compareValue } from 'utils/commonFunctions'
import { imageToBase64 } from 'utils/helper'
import theme from 'vars/theme'
import trash from 'assets/svgs/trash.svg'

const UploadBtn = styled.div`
  width: 125px;
  height: 90px;
  background-color: ${theme.blueSoft2};
  border: 2px dashed ${(props) => (props.disabled ? theme.grayMedium1 : theme.blueMedium1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 2.25rem 0 0;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
`

const ImgPreview = styled.div`
  width: 125px;
  position: relative;
  margin: 0 2.25rem 0 0;

  &:hover {
    .remove {
      display: ${(props) => (props.onRemoveDisabled ? 'none' : 'block')};
    }
  }

  img {
    width: 125px;
    height: 90px;
    object-fit: contain;
    border-radius: 4px;
  }
  .remove {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    .btn {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
`

const acceptTypes = ['image/gif', 'image/jpeg', 'image/png']
const MAX_IMAGES = 3

export class InputImage extends React.PureComponent {
  state = {
    images: this.props.value || [],
    error: '',
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props
    if (value && !compareValue(value, nextProps.value)) {
      this.setState({
        images: nextProps.value,
        error: '',
      })
    }
  }

  onClickAddImage = (e) => {
    const { images } = this.state
    const { isDisabled } = this.props
    e.preventDefault()
    const remainImages = images.filter((item) => !item.destroy)
    if (remainImages.length >= MAX_IMAGES || isDisabled) return null
    return this.image.click()
  }

  onImageChange = (e) => {
    const { images } = this.state
    const { onChange } = this.props
    const imageFiles = e.target.files
    const [firstFile] = e.target.files
    e.preventDefault()
    this.setState({
      ...this.state,
      error: '',
    })
    if (!imageFiles) {
      return
    } else if (!acceptTypes.includes(firstFile.type)) {
      this.setState({
        ...this.state,
        error: errorMessage.Warehouse.imageTypeInvalid(),
      })
      return
    } else if (firstFile.size > 10485760) {
      this.setState({
        ...this.state,
        error: errorMessage.HomePage.fileTooBig(),
      })
      return
    }

    const promise = Array.from([firstFile]).map((file) => imageToBase64(file))
    Promise.all(promise).then((data) => {
      const newImages = images.concat(
        data.map((img) => ({
          id: null,
          data: img,
          url: null,
          destroy: false,
        }))
      )
      this.setState(
        {
          images: newImages,
        },
        () =>
          onChange({
            data: newImages,
          })
      )
    })
  }

  removeImage = (index) => {
    const { images } = this.state
    const { onChange } = this.props
    const image = images[index]
    let newImages
    if (!image.id) {
      newImages = images.slice()
      newImages.splice(index, 1)
    } else {
      newImages = images.map((img, i) => {
        if (i !== index) {
          return img
        }

        return {
          ...img,
          destroy: true,
        }
      })
    }
    this.setState(
      {
        images: newImages,
      },
      () =>
        onChange({
          data: newImages,
        })
    )
  }

  renderImgPreview = () => {
    const { images } = this.state
    const { isDisabled } = this.props

    return images.map(
      (image, index) =>
        !image.destroy && (
          <ImgPreview key={index} onRemoveDisabled={isDisabled}>
            <img src={image.url || image.data} alt={`img-preview ${index + 1}`} />
            <div className="remove">
              <div
                role="presentation"
                onClick={() => this.removeImage(index)}
                className="btn flex-center"
              >
                <img src={trash} alt="trash-icon" />
              </div>
            </div>
          </ImgPreview>
        )
    )
  }

  render() {
    const { images } = this.state
    const { isDisabled } = this.props
    const disabled = images.filter((item) => !item.destroy).length >= MAX_IMAGES || isDisabled
    return (
      <div className="w-100 d-flex flex-column">
        <div className="w-100 d-flex flex-wrap ml-1">
          <UploadBtn onClick={this.onClickAddImage} disabled={disabled}>
            <Icon
              src={
                disabled
                  ? '/assets/svgs/image-upload-text-gray.svg'
                  : '/assets/svgs/image-upload-text.svg'
              }
              alt="add-btn"
            />
          </UploadBtn>
          {this.renderImgPreview()}
          <input
            ref={(el) => {
              this.image = el
            }}
            onChange={this.onImageChange}
            type="file"
            className="d-none"
            accept="image/*"
          />
        </div>
        {this.state.error && <ErrorWrapper className="my-1">{this.state.error}</ErrorWrapper>}
      </div>
    )
  }
}

InputImage.defaultProps = {
  onChange: () => {},
}

export default InputImage
