import React, { useEffect, memo } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import Button from 'reactstrap/lib/Button'
import { Prompt } from 'react-router-dom'

import * as S from './styles'
import Tabs from 'containers/IntegratedReceptionCustom/components/Tabs'
import ReceptionItemRow from 'containers/IntegratedReceptionCustom/components/ReceptionItemRow'
import { typeRow } from 'containers/IntegratedReceptionCustom/constants'
import { GeneralReceptionType, ParameterItemType } from 'containers/IntegratedReceptionCustom/type'
import { openError, openToast } from 'containers/App/reducers'
import {
  fetchReceptionParameters,
  selectParameter,
  updateParameters,
  startLoading,
  endLoading,
  fetchGeneralReceptions,
} from 'containers/IntegratedReceptionCustom/reducer'
import {
  parametersSelectors,
  generalReceptionSelectedSelectors,
  initialParametersSelectors,
} from 'containers/IntegratedReceptionCustom/selectors'
import FinishToast from 'containers/IntegratedReceptionCustom/components/FinishToast'
import { patchReceptionParameter } from 'containers/IntegratedReceptionCustom/api'

const IMPORT_PARAMETER_FIRST_INDEX = 5
const BASE_PARAMETER = ['company_name', 'vehicle_number', 'driver_name', 'vehicle_category']
const ONLY_CHECKBOX_PARAMETERS = ['import_export_enable']

export interface IItemsPageProps {
  parameters: ParameterItemType[]
  initialParameters: ParameterItemType[]
  generalSelected: GeneralReceptionType
  startLoading: () => void
  endLoading: () => void
  openError: (message: string, useNetworkError: boolean) => void
  selectParameter: (params: ParameterItemType) => void
  openToast: (params: any) => void
  fetchReceptionParameters: () => void
  updateParameters: (params: ParameterItemType[]) => void
  fetchGeneralReceptions: () => void
}

function ItemsPage({
  parameters,
  initialParameters,
  generalSelected,
  openError,
  openToast,
  selectParameter,
  updateParameters,
  fetchReceptionParameters,
  startLoading,
  endLoading,
  fetchGeneralReceptions,
}: IItemsPageProps) {
  const history = useHistory()

  useEffect(() => {
    if (generalSelected?.id) {
      fetchReceptionParameters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSelected?.id])

  const getTypeOfRowItem = (columnName: string) => {
    if (BASE_PARAMETER.includes(columnName)) return typeRow.BASE
    if (ONLY_CHECKBOX_PARAMETERS.includes(columnName)) return typeRow.ONLY_CHECK_BOX
    return typeRow.IMPORT
  }

  const onClickUp = (item: ParameterItemType) => {
    const newParameters = [...parameters]
    const foundIndex = newParameters?.findIndex((parameter) => parameter.id === item.id)
    const prevItem = newParameters[foundIndex - 1]

    newParameters[foundIndex - 1] = {
      ...item,
      displayOrder: prevItem.displayOrder,
    }
    newParameters[foundIndex] = {
      ...prevItem,
      displayOrder: item.displayOrder,
    }
    updateParameters(newParameters)
  }

  const onClickDown = (item: ParameterItemType) => {
    const newParameters = [...parameters]

    const foundIndex = newParameters.findIndex((parameter) => parameter.id === item.id)
    const nextItem = newParameters[foundIndex + 1]

    newParameters[foundIndex + 1] = {
      ...item,
      displayOrder: nextItem.displayOrder,
    }
    newParameters[foundIndex] = {
      ...nextItem,
      displayOrder: item.displayOrder,
    }

    updateParameters(newParameters)
  }

  const onClickEdit = (url: string, item: ParameterItemType) => {
    selectParameter(item)
    history.push(url)
  }

  const onChangeRequired = (item: ParameterItemType) => {
    const newParameters = [...parameters]

    const foundIndex = newParameters.findIndex((parameter) => parameter.id === item.id)
    newParameters[foundIndex] = {
      ...item,
      requiredFlag: !item.requiredFlag,
    }

    updateParameters(newParameters)
  }

  const onChangeVisibility = (item: ParameterItemType) => {
    const newParameters = [...parameters]

    if (item.cardViewFlag && item.displayFlag) {
      openError(
        '本項目は「カード表示内容」にて選択されていたため、「カード表示内容」から他の項目を選択してください。',
        false
      )
    }

    const foundIndex = newParameters.findIndex((parameter) => parameter.id === item.id)
    const displayFlag = !item.displayFlag
    newParameters[foundIndex] = {
      ...item,
      displayFlag,
      requiredFlag: displayFlag ? item.requiredFlag : false,
    }

    updateParameters(newParameters)
  }

  const onClickRegister = async () => {
    const changedParameters = _.differenceWith(parameters, initialParameters, _.isEqual)
    const isNotDisplayName = changedParameters.some(
      (parameter) => !parameter.displayName && parameter.displayFlag
    )
    if (isNotDisplayName) {
      return openError('名称を未入力で表示にはできません', false)
    }

    const data = {
      generalReceptionId: generalSelected?.id,
      parameters: changedParameters,
    }

    startLoading()

    try {
      await patchReceptionParameter(data)
    } catch (error) {
      openError(error?.message, false)
      return
    } finally {
      endLoading()
    }

    fetchReceptionParameters()
    fetchGeneralReceptions()
    openToast(FinishToast)
  }

  return (
    <div>
      <S.Wrapper>
        <Tabs currentTabId="items" />
        <S.TextTile className="mb-3 text-center">
          ドライバー受付時の入力項目として表示/非表示を選択し、表示の場合は必須/任意をお選びください
        </S.TextTile>
        <div className="m-3">
          <div className="mb-3" style={{ overflowY: 'auto' }}>
            <S.Table>
              <colgroup>
                <col />
                <S.ColCustom />
                <S.ColCustom />
                <S.ColCustom />
                <S.ColCustom />
              </colgroup>
              <thead className="border-bottom">
                <tr>
                  <S.Th className="pl-3">項目名</S.Th>
                  <S.Th />
                  <S.Th className="text-center">表示順</S.Th>
                  <S.Th className="text-center">必須項目</S.Th>
                  <S.Th className="text-center">表示／非表示</S.Th>
                </tr>
              </thead>
              <tbody className="border-0">
                {parameters.map((item, index) => (
                  <ReceptionItemRow
                    key={item?.id}
                    item={item}
                    type={getTypeOfRowItem(item.columnName)}
                    onClickUp={onClickUp}
                    onClickDown={onClickDown}
                    onChangeRequired={onChangeRequired}
                    onChangeVisibility={onChangeVisibility}
                    onClickEdit={onClickEdit}
                    hideUpButton={index === IMPORT_PARAMETER_FIRST_INDEX}
                    hideDownButton={index === parameters.length - 1}
                    canUpdate={true}
                  />
                ))}
              </tbody>
            </S.Table>
          </div>
        </div>
      </S.Wrapper>
      <div className="text-right">
        <Button
          color="primary"
          className="rounded-pill px-4"
          disabled={_.isEqual(initialParameters, parameters)}
          onClick={onClickRegister}
        >
          登録
        </Button>
      </div>
      <Prompt
        when={!_.isEqual(initialParameters, parameters)}
        message={
          '変更内容が保存されていません。\n「OK」を押すと変更内容が破棄されます。よろしいですか？'
        }
      />
    </div>
  )
}

const mapStateToProps = () =>
  createStructuredSelector({
    parameters: parametersSelectors(),
    initialParameters: initialParametersSelectors(),
    generalSelected: generalReceptionSelectedSelectors(),
  })

const mapDispatchToProps = {
  openError,
  selectParameter,
  fetchReceptionParameters,
  updateParameters,
  openToast,
  startLoading,
  endLoading,
  fetchGeneralReceptions,
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(ItemsPage))
