import React, { FC, useRef } from 'react'

import { formatConfirmation } from 'utils/formatters'
import Icon from 'components/Icon'
import Checkbox from 'components/Checkbox'
import { ATTACHMENT_CONFIRMATION_MAX_SIZE, ErrorMessages } from 'constants/Attachment'
import { checkValidFileName, ACCEPTED_FILE_IMAGE_CONFIRMING_DRIVER } from 'utils/helper'
import { ConfirmationType, ConfirmationImageType } from '../../type'
import { CONFIRMATION_CONTENT_LENGTH } from 'constants/ReceptionCustom'
import * as S from './styles'

type RowProps = {
  item: ConfirmationType
  validationResult: any
  openError: (params: any) => void
  onUpdateImage: (item: ConfirmationType, image: ConfirmationImageType) => void
  onChangeConfirmation: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: ConfirmationType
  ) => void
  onChangeRequired: (item: ConfirmationType) => void
  onChangeDisplay: (item: ConfirmationType) => void
  onChangeImportant: (item: ConfirmationType) => void
  onRemoveImage: (item: ConfirmationType) => void
  onChangeAboveTextFlag: (item: ConfirmationType) => void
}

const RowConfirmation: FC<RowProps> = ({
  item,
  onChangeConfirmation,
  onChangeRequired,
  onChangeDisplay,
  onChangeImportant,
  onRemoveImage,
  validationResult,
  onUpdateImage,
  onChangeAboveTextFlag,
  openError,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onInputClick = () => {
    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const onChangeFile = (event: any) => {
    const file: any = Array.from(event?.target?.files)[0]
    const isOver10mb = file?.size >= ATTACHMENT_CONFIRMATION_MAX_SIZE
    let errorMessage = ''

    if (isOver10mb) {
      errorMessage = ErrorMessages.CONFIRMATION_OVER_MAX_SIZE.replace('${fileName}', file?.name)
    } else if (file.name.includes(',')) {
      errorMessage = ErrorMessages.INVALID_FILE_NAME
    } else if (!checkValidFileName(file.name)) {
      errorMessage = ErrorMessages.INVALID_FILE_TYPE.replace('${fileName}', file?.name)
    }

    if (errorMessage) {
      alert(errorMessage)
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      onUpdateImage(item, {
        generalDriverConfirmationId: item?.id,
        content: reader?.result,
        name: file?.name,
        aboveTextFlag: false,
        size: file?.size,
      })
    }
    reader.onerror = (error) => {
      openError(error)
    }
  }

  return (
    <div className="mb-4">
      <textarea
        value={formatConfirmation(item?.confirmation) || ''}
        className="w-100"
        style={{ height: 126 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          onChangeConfirmation(e, item)
        }
        placeholder="未設定"
        maxLength={CONFIRMATION_CONTENT_LENGTH}
      />
      <S.WrapperUploadFile>
        <S.Box>
          <S.InputFile
            id={`upload-file-${item?.id}`}
            type="file"
            accept={ACCEPTED_FILE_IMAGE_CONFIRMING_DRIVER.join(',')}
            onChange={onChangeFile}
            onClick={onInputClick}
            ref={inputRef}
          />
          <S.UploadImageButton htmlFor={`upload-file-${item.id}`}>画像を追加</S.UploadImageButton>
          <S.ListFile>
            {(item?.images || []).map((image) => (
              <S.FileWrapper key={`${image?.id || ''}-${image?.name}`}>
                <S.FileBox>
                  <S.WrapperIconAndNameFile>
                    <S.ImageIcon />
                    {Boolean(image?.id) ? (
                      <a
                        href={`/general-confirmation/file-preview/${image?.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={image?.name}
                      >
                        {image?.name}
                      </a>
                    ) : (
                      <span title={image?.name}>{image?.name}</span>
                    )}
                  </S.WrapperIconAndNameFile>
                  <S.RemoveIconWrapper>
                    <Icon
                      src="/assets/svgs/trash.svg"
                      alt="削除ボタン"
                      type="button"
                      width={16}
                      height={16}
                      onClick={() => onRemoveImage(item)}
                    />
                  </S.RemoveIconWrapper>
                </S.FileBox>
                <Checkbox
                  className="ml-2"
                  checked={image?.aboveTextFlag}
                  onChange={() => onChangeAboveTextFlag(item)}
                  label="画像を文書の上に移動"
                />
              </S.FileWrapper>
            ))}
          </S.ListFile>
        </S.Box>
        <div>
          {item?.confirmation?.length || 0}/{`${CONFIRMATION_CONTENT_LENGTH}`}
        </div>
      </S.WrapperUploadFile>
      <S.WrapperCheckbox>
        <div className="d-flex justify-content-start">
          <div className="mr-3 d-flex">
            <Checkbox
              checked={item?.displayFlag}
              onChange={() => onChangeDisplay(item)}
              label="事項表示"
              className="mr-3"
            />
            <Checkbox
              className="mr-3"
              checked={item?.checkFlag}
              onChange={() => onChangeRequired(item)}
              label="既読必須"
            />
            <Checkbox
              className=""
              checked={!!item?.importantFlag}
              onChange={() => onChangeImportant(item)}
              label="強調"
            />
          </div>
        </div>
        {!validationResult?.result && (
          <div className="text-danger">{validationResult?.message}</div>
        )}
      </S.WrapperCheckbox>
    </div>
  )
}

export default RowConfirmation
