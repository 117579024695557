import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import { camelizeKeys } from 'humps'
import _ from 'lodash'
import styled from 'styled-components'

import { fetchAnything, startLoading, stopLoading, openError } from 'containers/App/reducers'
import { VEHICLE_NUMBER_LENGTH } from 'constants/Booking'
import CreatableSelect2 from 'components/SelectOptions/CreatableSelect2'

import Header from '../components/Header'
import { updateBooking, fetchSuccess, updateIsAcceptPolicy } from '../reducers'
import { requestGet, requestPatch } from '../api'
import NumericKeypad from '../components/NumericKeypad'
import { Input, EMPTY_OPTION } from '../components/utils'
import PrevStartButton from '../components/PrevStartButton'
import PrevButton from 'components/PrevButton'
import PageNumber from '../components/PageNumber'
import theme from 'vars/theme'
import SelectBase from 'components/SelectOptions/SelectBase'
import ConfirmDriverNameModal from 'components/ConfirmDriverNameModal'
import PolicyModal from 'components/PolicyModal'

const VehicleNumberInput = styled.input`
  width: 200px;
  text-align: center;
  font-size: 40px;
  color: #333;
  border-radius: 12px;
`
const RequiredText = styled.span`
  color: ${theme.redPrimary2};
  font-size: 18px;
  margin-right: 16px;
`

const CreatableSelectWrapper = styled.div`
  width: 260px;
  .mono-creatable__control {
    margin-left: 0;
  }
`
const DRIVER_NAME_ID = 'inputDriverName'

class EntryDriverInfoPage extends Component {
  state = {
    isOpenNumericKeypad: false,
    isOpenModalConfirm: false,
    isOpenPolicyModal: false,
    hasDriverName: false,
    policyData: {},
  }

  showConfirmModal = () => this.setState({ isOpenModalConfirm: true })

  closeConfirmModal = () => this.setState({ isOpenModalConfirm: false })

  showPolicyModal = () => this.setState({ isOpenPolicyModal: true })

  closePolicyModal = () => this.setState({ isOpenPolicyModal: false })

  async componentDidMount() {
    const {
      match,
      driver: { driverPhone },
      booking,
      returnEntryPage,
      history,
      vehicleCategories,
      isAcceptPolicyDriverName,
    } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
      return
    }

    this.props.fetchAnything({
      url: '/tablets/list_companies',
      storePath: 'companies',
      data: { tenantId, phoneNumber: driverPhone },
    })

    this.props.startLoading()

    let resp1
    let resp2

    try {
      resp1 = await requestGet('/tablets/get_driver', {
        driverPhone,
      })
      resp2 = await requestGet('/tablets/recent_booking', {
        warehouseId,
        tenantId,
        driverPhone,
      })
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const driver = resp1.data ? camelizeKeys(resp1.data.attributes) : {}
    const recentBooking = camelizeKeys(resp2.data)
    this.props.fetchSuccess({ storePath: 'recentBooking', data: recentBooking })
    if (driver.id && driver.name) {
      this.setState({ hasDriverName: true })
    }
    let vehicleCategoryId = booking.vehicleCategory || recentBooking.vehicleCategory || ''
    const filteredVehicleCategories = booking.bookingNo
      ? vehicleCategories
      : vehicleCategories.filter((vc) => vc.displayFlag)
    // 受付カスタマイズで非表示にされている選択肢の場合は空にする
    if (!filteredVehicleCategories.find((vc) => vc.parameterId == vehicleCategoryId)) {
      vehicleCategoryId = ''
    }
    this.props.updateBooking({
      driverCompanyId:
        Number(booking.driverCompanyId || recentBooking.companyId || driver.companyId) || '',
      driverCompanyName:
        booking.driverCompanyName ||
        recentBooking.driverCompanyName ||
        driver.driverCompanyName ||
        '',
      vehicleNumber: booking.vehicleNumber || recentBooking.vehicleNumber || '',
      driverId: Number(driver.id) || '',
      driverName: this.getDriverNameInitPage(isAcceptPolicyDriverName, driver, booking) || '',
      vehicleCategory: vehicleCategoryId,
    })
  }

  getDriverNameInitPage = (isAcceptPolicyDriverName, driver, booking) => {
    // isAcceptPolicyDriverName = true: when user has driver name and after they changed value driver name
    // driver?.name = false: when user first checkin, it is empty and after that have value if they change info
    if ((!driver?.name || isAcceptPolicyDriverName) && booking?.driverName) {
      return booking?.driverName
    }
    return driver?.name
  }

  onChangeText = (e, key) => {
    const { value } = e.target

    let newValue = value

    if (key === 'vehicleNumber') {
      newValue = newValue.replace(/[^0-9]/g, '')
    }

    this.props.updateBooking({ [key]: newValue })
  }

  onClickVehicleNumber = () => {
    const { isOpenNumericKeypad } = this.state
    this.setState({ isOpenNumericKeypad: !isOpenNumericKeypad })
  }

  onClickEnterVehicleNumber = () => {
    const {
      booking: { vehicleNumber },
    } = this.props
    this.props.updateBooking({ vehicleNumber: vehicleNumber.padStart(4, '0') })
    this.setState({ isOpenNumericKeypad: false })
  }

  onChangeSelectVehicleCategory = (e) => {
    const { value } = e.target
    this.props.updateBooking({ vehicleCategory: value })
  }

  handleChangeDriverCompany = (option) => {
    const { value, label } = option || { value: '', label: '' }

    // 入力の場合
    if (value === label || !value) {
      this.props.updateBooking({ driverCompanyId: '', driverCompanyName: value })
      return
    }

    // 選択の場合
    this.props.updateBooking({ driverCompanyId: value, driverCompanyName: label })
  }

  handleBlurDriverCompany = (e) => {
    const { value } = e.target

    if (!value) {
      // 選択の場合
      return
    }

    // 入力の場合
    this.props.updateBooking({ driverCompanyName: value })
  }

  onClickNumber = (value) => {
    const {
      booking: { vehicleNumber },
    } = this.props

    if (vehicleNumber.length === VEHICLE_NUMBER_LENGTH) return

    this.props.updateBooking({ vehicleNumber: `${vehicleNumber}${value}` })
  }

  onClickBackSpace = () => {
    const {
      booking: { vehicleNumber },
    } = this.props

    if (!vehicleNumber) return

    this.props.updateBooking({
      vehicleNumber: vehicleNumber.substring(0, vehicleNumber.length - 1),
    })
  }

  onFocusInput = (e) => {
    const { isAcceptPolicyDriverName } = this.props
    const { hasDriverName } = this.state
    if (hasDriverName && !isAcceptPolicyDriverName) {
      this.showConfirmModal()
      e.target.blur()
    }
  }

  onHandleConfirm = async () => {
    const { startLoading, openError, stopLoading } = this.props
    try {
      startLoading()
      const resp = await requestGet('/policies/newest', {})
      this.setState({ policyData: camelizeKeys(resp?.data?.display_policy_version) })
      this.showPolicyModal()
    } catch (error) {
      openError(error.message)
    } finally {
      stopLoading()
      this.closeConfirmModal()
    }
  }

  onHandleCancel = () => {
    this.closeConfirmModal()
  }

  onHandleAcceptPolicyModal = async () => {
    const { updateIsAcceptPolicy, updateBooking, driver, stopLoading, startLoading } = this.props
    const { policyData } = this.state
    try {
      startLoading()
      await requestPatch('/tablets/update_policy_checked', {
        phoneNumber: driver?.driverPhone,
        policyId: policyData?.id,
      })
      updateIsAcceptPolicy(true)
      updateBooking({ driverName: '' })
      const driverNameInput = document.getElementById(DRIVER_NAME_ID)
      if (driverNameInput) driverNameInput.focus()
    } catch (error) {
      openError(error.message)
    } finally {
      stopLoading()
      this.closePolicyModal()
    }
  }

  onHandleCancelPolicyModal = () => {
    this.closePolicyModal()
  }

  render() {
    const {
      match,
      vehicleCategories,
      booking,
      companies,
      driver,
      driverConfirmations,
      history,
      isAcceptPolicyDriverName,
    } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { isOpenNumericKeypad, isOpenModalConfirm, isOpenPolicyModal, policyData } = this.state

    const vehicleCategoryOptions = vehicleCategories
      .filter(
        (option) =>
          (booking.vehicleCategory && booking.vehicleCategory == option.parameterId) ||
          option.displayFlag
      )
      .map((vehicleCategory) => ({
        value: vehicleCategory.parameterId,
        label: vehicleCategory.parameterName,
      }))
    const companyOptions = companies.map((company) => ({
      value: company.id,
      label: company.attributes.companyName,
    }))

    const isFreePlan = !driver.tenant.canBerthBook
    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header driver={driver} />

        <div className="p-3">
          <div className="bg-white mb-3 p-3" style={{ borderRadius: 12 }}>
            <div className="text-center mb-3">
              <div className="text-size32 font-weight-bold">ドライバー情報を入力</div>
              <div>以下の情報を入力してください</div>
            </div>

            <div className="d-flex mx-auto mb-3" style={{ width: 550 }}>
              <CreatableSelectWrapper className="mr-3">
                <div className="d-flex align-items-center">
                  <RequiredText>必須</RequiredText>
                  運送会社名
                </div>
                <CreatableSelect2
                  isClearable
                  isInputDisplay
                  options={companyOptions}
                  value={{ value: booking.driverCompanyId, label: booking.driverCompanyName }}
                  onChange={this.handleChangeDriverCompany}
                  onBlur={this.handleBlurDriverCompany}
                  height="52px"
                  fontSize="24px"
                />
              </CreatableSelectWrapper>
              <div style={{ width: 260 }}>
                <div className="d-flex align-items-center">
                  <RequiredText>必須</RequiredText>
                  車番
                </div>
                <Input
                  type="text"
                  value={
                    booking.vehicleNumber
                      ? `${booking.vehicleNumber.substr(0, 2)}-${booking.vehicleNumber.substr(
                          2,
                          2
                        )}`
                      : ''
                  }
                  maxLength={VEHICLE_NUMBER_LENGTH}
                  onClick={this.onClickVehicleNumber}
                  onChange={_.noop}
                  className="w-100 text-size24"
                  style={{ height: 52 }}
                />
              </div>
            </div>

            <div className="d-flex mx-auto mb-3" style={{ width: 550 }}>
              <div style={{ width: 260 }} className="mr-3">
                <div className="d-flex align-items-center">
                  <RequiredText>必須</RequiredText>
                  氏名フルネーム
                </div>
                <Input
                  id={DRIVER_NAME_ID}
                  type="text"
                  value={booking.driverName}
                  placeholder={'例：山田　太郎'}
                  maxLength={50}
                  className="w-100 text-size24"
                  style={{ height: 52 }}
                  onChange={(e) => {
                    this.onChangeText(e, 'driverName')
                  }}
                  onFocus={this.onFocusInput}
                />
              </div>
              <div style={{ width: 260 }}>
                <div className="d-flex align-items-center">
                  <RequiredText>必須</RequiredText>
                  車両形態
                </div>
                <SelectBase
                  className="w-100 bg-white px-2"
                  style={{
                    height: 52,
                    borderRadius: 4,
                    fontSize: 24,
                    border: '1px solid #CCC',
                    color: '#000',
                  }}
                  value={booking.vehicleCategory}
                  onChange={this.onChangeSelectVehicleCategory}
                >
                  {[EMPTY_OPTION].concat(vehicleCategoryOptions).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </SelectBase>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div style={{ width: '33%' }}>
              <PrevStartButton to={`/receipt-booking/${warehouseId}/${tenantId}`} />
            </div>
            <div className="text-center" style={{ width: '34%' }}>
              <PageNumber
                page="entry-driver-info"
                existsDriverConfirmations={Boolean(driverConfirmations.length)}
                isFreePlan={isFreePlan}
              />
            </div>
            <div className="text-right" style={{ width: '33%' }}>
              {!isFreePlan && (
                <Button
                  outline
                  color="primary"
                  className="mr-3 rounded-pill bg-white px-4 text-primary"
                  onClick={() =>
                    history.push(`/receipt-booking/entry-bookings/${warehouseId}/${tenantId}`)
                  }
                >
                  もどる
                </Button>
              )}
              <Button
                color="primary"
                className="rounded-pill px-4"
                disabled={
                  !booking.driverCompanyName?.trim() ||
                  !booking.driverName?.trim() ||
                  !booking.vehicleNumber ||
                  !booking.vehicleCategory
                }
                onClick={() =>
                  history.push(`/receipt-booking/entry-load/${warehouseId}/${tenantId}`)
                }
              >
                次へ
              </Button>
            </div>
          </div>
        </div>

        <Modal isOpen={isOpenNumericKeypad} size="lg" style={{ maxWidth: 664 + 32 }}>
          <ModalBody
            style={{
              overflow: 'auto',
            }}
          >
            <div className="p-3 d-flex justify-content-between align-items-center mb-1">
              <div style={{ width: '33%' }}>
                <PrevButton onClick={this.onClickVehicleNumber} />
              </div>
              <div className="text-center text-size24" style={{ width: '34%' }}>
                <span>車番を入力</span>
              </div>
              <div style={{ width: '33%' }} />
            </div>
            <div className="text-center mb-3">
              <VehicleNumberInput
                type="text"
                value={booking.vehicleNumber}
                maxLength={VEHICLE_NUMBER_LENGTH}
                onChange={(e) => {
                  this.onChangeText(e, 'vehicleNumber')
                }}
              />
            </div>
            <NumericKeypad
              value={booking.vehicleNumber}
              canClickEnter={booking.vehicleNumber && booking.vehicleNumber.length > 0}
              onClick={this.onClickNumber}
              onClickBackSpace={this.onClickBackSpace}
              onClickEnter={this.onClickEnterVehicleNumber}
            />
          </ModalBody>
        </Modal>

        <ConfirmDriverNameModal
          isOpen={isOpenModalConfirm}
          title="ドライバー氏名を変更しますか？<br/>※ 利用規約に同意が必要です。"
          textClose="いいえ"
          textOk="はい"
          handleCancel={this.onHandleCancel}
          handleConfirm={this.onHandleConfirm}
        />

        <PolicyModal
          isOpen={isOpenPolicyModal}
          policyData={policyData}
          onAccept={this.onHandleAcceptPolicyModal}
          onCancel={this.onHandleCancelPolicyModal}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
    booking: reception.booking,
    companies: reception.companies,
    recentBooking: reception.recentBooking,
    vehicleCategories: reception.master.vehicleCategories,
    driverConfirmations: reception.driverConfirmations,
    returnEntryPage: reception.returnEntryPage,
    isAcceptPolicyDriverName: reception.isAcceptPolicyDriverName,
  }
}

const mapDispatchToProps = {
  fetchAnything,
  startLoading,
  stopLoading,
  openError,
  updateBooking,
  fetchSuccess,
  updateIsAcceptPolicy,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryDriverInfoPage)
