import { takeLatest, put, call } from 'redux-saga/effects'
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps'
import _ from 'lodash'
import moment from 'moment'

import { requestGet } from 'containers/ReceptionCustom/api'
import {
  SettingTargets,
  defaultBerthColumnsPerPage,
  defaultReceptionNotificationSound,
} from 'constants/Settings'

const defaultValues = Object.freeze({
  [SettingTargets.BERTH_COLUMNS_PER_PAGE]: defaultBerthColumnsPerPage,
  [SettingTargets.RECEPTION_NOTIFICATION_SOUND]: defaultReceptionNotificationSound,
})

function* fetchWarehouses({ payload }: any) {
  const { tenantId } = payload

  yield put({ type: 'base/START_LOADING' })

  let resp

  try {
    resp = yield call(requestGet, '/warehouse/list_tenants_for_warehouse')
  } catch (error) {
    yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
    return
  }

  const camelizedWarehouses = camelizeKeys(resp.data)

  yield put({
    type: 'settings/FETCH_SUCCESS',
    payload: { storePath: 'warehouses', data: camelizedWarehouses },
  })

  const warehouse: any = camelizedWarehouses.find((warehouse: any) => {
    return warehouse.tenants.find((tenant: any) => {
      return tenant.id === tenantId
    })
  })

  yield put({ type: 'settings/CHANGE_WAREHOUSE', payload: warehouse.id })

  const tenants = camelizeKeys(warehouse.tenants)
  yield put({ type: 'settings/FETCH_SUCCESS', payload: { storePath: 'tenants', data: tenants } })

  yield put({ type: 'settings/CHANGE_TENANT', payload: tenantId })

  yield put({ type: 'base/STOP_LOADING' })
}

function* fetchSettings({ payload }: any) {
  const { target, tenantId = 0, userId = 0 } = payload

  let resp: any

  yield put({ type: 'base/START_LOADING' })

  try {
    resp = yield call(
      requestGet,
      '/berths/bookings/tenant_settings',
      decamelizeKeys({ target: decamelize(target), tenantId, userId })
    )
  } catch (error) {
    yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
    return
  } finally {
    yield put({ type: 'base/STOP_LOADING' })
  }
  const displaySettingValue = _.get(resp, 'data.display_settings.value', null)
  const data = displaySettingValue ? JSON.parse(displaySettingValue) : defaultValues[target]
  yield put({ type: 'settings/SAVE_SETTINGS', payload: { target, data } })
}

export default function* () {
  yield takeLatest('settings/FETCH_WAREHOUSES', fetchWarehouses)
  yield takeLatest('FETCH_SETTINGS', fetchSettings)
  yield takeLatest('settings/FETCH_SETTINGS', fetchSettings)
}
