import { useEffect, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import { useDispatch } from 'reduxStoreHelper'
import { testMailValidate } from 'utils/helper'
import { showFlashMessage } from 'containers/AppPrivate/actions'
import { validateYup } from 'utils/validators'
import { convertApiError, convertPhoneNumber, compareValue } from 'utils/commonFunctions'
import { REGEX } from 'constants/misc'

import {
  getUserGeneralReception,
  postManageRoleGeneral,
  getListCompanyWithPaging,
  findUserInfoGeneral,
} from '../api'
import { FIRST_PAGE, PER_PAGE, ROLE_TYPE, DEFAULT_OPTION } from '../constants'
import {
  ConfirmModalProps,
  UserGeneralReceptionStateType,
  UserGeneralReceptionType,
  CompanyType,
  CompanyOptionType,
  UserOptionType,
  UserByEmailType,
} from '../type'
import { addControllerSchema } from '../validation'

const initialManagerGeneralState: UserGeneralReceptionStateType = {
  totalPages: 0,
  totalCount: 0,
  page: FIRST_PAGE,
  userGeneralReception: [],
}

const initialFormData = {
  companyName: '',
  companyId: '',
  id: '',
  name: '',
  email: '',
  telNumber: '',
  setSelectedCompany: {},
}

export type FormDataType = {
  companyName: string
  companyId: string | number
  id: string | number
  name: string
  email: string
  telNumber: string
  setSelectedCompany: Partial<CompanyOptionType>
}

type CompanyDataType = {
  page: number
  totalPages: number
  keyword: string | number
}

const initialCompanyData: CompanyDataType = {
  page: FIRST_PAGE,
  totalPages: 0,
  keyword: '',
}

type ModalConfirmController = {
  isOpen: boolean
  message: {
    companyName?: String
    name?: string
  }
}

const useAddController = (showConfirmModal: (confirmModalProps: ConfirmModalProps) => void) => {
  const initialConfirmControllerModal: ModalConfirmController = {
    isOpen: false,
    message: {
      companyName: '',
      name: '',
    },
  }
  const [loading, setLoading] = useState<boolean>(false)
  const [controllersInfo, setControllerInfo] = useState<UserGeneralReceptionStateType>(
    initialManagerGeneralState
  )
  const [formData, setFormData] = useState<FormDataType>(initialFormData)
  const [error, setError] = useState<any>({})
  const [userByEmail, setUserByEmail] = useState<Partial<UserByEmailType>>({})
  const [keyword, setKeyword] = useState<string>('')

  // State of companies
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false)
  const [companies, setCompanies] = useState<CompanyType[]>([])
  const [companyData, setCompanyData] = useState<CompanyDataType>({ ...initialCompanyData })
  const [isFocusSelectCompany, setIsFocusSelectCompany] = useState<boolean>(false)

  const [infoConfirmControllerModal, setInfoConfirmControllerModal] =
    useState<ModalConfirmController>(initialConfirmControllerModal)

  const params: { generalReceptionId: string } = useParams()
  const dispatch = useDispatch()

  const companyOptions = useMemo<Partial<CompanyOptionType>[]>(() => {
    let listCompany: Partial<CompanyType>[] = [...companies]

    if (!_.isEmpty(userByEmail)) {
      const foundedCompany = companies.find((company) =>
        compareValue(company.id, userByEmail.companyId)
      )
      if (!foundedCompany) {
        listCompany.unshift({
          id: userByEmail.companyId,
          companyName: userByEmail.companyName,
          users: [
            {
              id: userByEmail.userId,
              name: userByEmail.name,
              email: userByEmail.email,
              telNumber: userByEmail.telNumber,
            },
          ],
        })
      }
    }

    const options = _.uniqBy(listCompany, 'id').map((company) => ({
      value: company.id,
      label: company.companyName,
      ...company,
    }))

    return options
  }, [companies, userByEmail])

  const userOptions = useMemo<Partial<UserOptionType>[]>(() => {
    if (!formData.setSelectedCompany.users) return [DEFAULT_OPTION]
    let listUser = [...formData.setSelectedCompany.users]

    if (!_.isEmpty(userByEmail)) {
      const foundedUser = companies.find((user) => compareValue(user.id, userByEmail.userId))

      if (!foundedUser) {
        listUser.push({
          id: userByEmail.userId,
          name: userByEmail.name,
          email: userByEmail.email,
          telNumber: userByEmail.telNumber,
        })
      }
    }

    const options = _.uniqBy(listUser, 'id').map((user) => ({
      value: user.id,
      label: user.name,
      ...user,
    }))

    return [DEFAULT_OPTION, ...options]
  }, [companies, formData.setSelectedCompany.users, userByEmail])

  const handleOnChange = useCallback(
    (name: string) => (value: any) => {
      switch (name) {
        case 'company':
          let company = value

          if (!value.id) {
            const foundedCompany = companyOptions.find((item) =>
              compareValue(item.companyName, value.value)
            )
            if (foundedCompany) {
              company = foundedCompany
            }
          }

          if (value.value !== formData.companyId && !_.isEmpty(userByEmail)) setUserByEmail({})

          setFormData({
            companyId: company.value || '',
            companyName: company.label || '',
            setSelectedCompany: company,
            id: '',
            name: '',
            email: '',
            telNumber: '',
          })
          break
        case 'user':
          let user = value
          if (!value.id) {
            const foundedUser = userOptions.find((item) => compareValue(item.name, value.value))
            if (foundedUser) {
              user = foundedUser
            }
          }
          setFormData((preData) => ({
            ...preData,
            id: user.value || '',
            name: user.label || '',
            email: user.email || '',
            telNumber: user.telNumber || '',
          }))
          break
        case 'telNumber':
          if (REGEX.TEL_NUMBER_ON_CHANGE.test(value)) {
            setFormData((prev) => ({ ...prev, telNumber: convertPhoneNumber(value) }))
          }
          break
        default:
          setFormData((prev) => ({ ...prev, [name]: value }))
          break
      }
    },
    [companyOptions, formData.companyId, userByEmail, userOptions]
  )

  const handleFindUserByEmail = useCallback(async () => {
    if (!testMailValidate(formData.email))
      return {
        isEmailExists: false,
      }

    try {
      setLoading(true)
      const res = await findUserInfoGeneral(formData.email, ROLE_TYPE.CONTROLLER)
      const { companyName, name } = res.data

      // Show modal confirm and set data of controller
      setUserByEmail(res.data)
      setInfoConfirmControllerModal({
        isOpen: true,
        message: {
          companyName,
          name,
        },
      })
      return {
        isEmailExists: true,
      }
    } catch (error) {
      return {
        isEmailExists: false,
      }
    } finally {
      setLoading(false)
    }
  }, [formData.email])

  const handleGetControllers = useCallback(
    async (page, searchingValue = keyword) => {
      setLoading(true)
      const res = await getUserGeneralReception(
        +params.generalReceptionId,
        ROLE_TYPE.CONTROLLER,
        page,
        PER_PAGE,
        searchingValue.trim()
      )
      //@ts-ignore
      setControllerInfo({
        ...res.data,
        page,
      })

      setLoading(false)
    },
    [keyword, params.generalReceptionId]
  )

  const handleGetListCompany = useCallback(
    async (keyword: string | number = '', keepCompany: CompanyType | null = null) => {
      setIsLoadingCompany(true)

      const res = await getListCompanyWithPaging(params.generalReceptionId, FIRST_PAGE, keyword)
      const totalPages = _.get(res, 'data.totalPages', 0)
      const resCompanies = _.get(res, 'data.companies', [])

      if (keepCompany) {
        resCompanies.unshift(keepCompany)
      }

      setCompanyData({
        totalPages,
        page: FIRST_PAGE,
        keyword,
      })
      setCompanies(resCompanies)
      setIsLoadingCompany(false)
    },
    [params.generalReceptionId]
  )

  const handleUpdateListCompany = useCallback(
    async (keyword: string | number = '', page: number = FIRST_PAGE) => {
      setIsLoadingCompany(true)

      const res = await getListCompanyWithPaging(params.generalReceptionId, page, keyword)
      const totalPages = _.get(res, 'data.totalPages', 0)
      const resCompanies = _.get(res, 'data.companies', [])

      setCompanyData({
        totalPages,
        page,
        keyword,
      })
      setCompanies([...companies, ...resCompanies])
      setIsLoadingCompany(false)
    },
    [companies, params.generalReceptionId]
  )

  const handleRemoveController = useCallback(
    async (manageGeneral: UserGeneralReceptionType) => {
      showConfirmModal({
        title: '削除してよろしいですか？',
        handleConfirm: async (closeModal: () => void) => {
          try {
            setLoading(true)

            const { userId } = manageGeneral

            await postManageRoleGeneral({
              generalReceptionId: +params.generalReceptionId,
              userId,
              generalRole: ROLE_TYPE.CONTROLLER,
              flag: false,
            })
            dispatch(
              showFlashMessage({
                message: '総合受付から配車担当者削除が成功しました。',
              })
            )
            handleGetControllers(controllersInfo.page)
          } catch (err) {
            setLoading(false)
            dispatch(
              showFlashMessage({
                isError: true,
                message: '総合受付から配車担当者削除が失敗しました。',
              })
            )
          }

          closeModal()
        },
        handleCancel: (closeModal: () => void) => {
          closeModal()
        },
      })
    },
    [
      controllersInfo.page,
      dispatch,
      handleGetControllers,
      params.generalReceptionId,
      showConfirmModal,
    ]
  )

  const handleSubmit = useCallback(async () => {
    try {
      const { id } = formData
      const isUerExists = userOptions.find((user) => compareValue(user.id, id))

      if (!isUerExists) {
        const res = await handleFindUserByEmail()
        if (res.isEmailExists) return
      }

      const { yupData, errors, isError } = validateYup(addControllerSchema, formData)
      if (isError) {
        setError(errors)
        return
      }
      setLoading(true)
      //@ts-ignore
      await postManageRoleGeneral({
        generalReceptionId: +params.generalReceptionId,
        generalRole: ROLE_TYPE.CONTROLLER,
        flag: true,
        userId: _.isNumber(formData.id) ? formData.id : null,
        companyId: _.isNumber(formData.companyId) ? formData.companyId : null,
        ...yupData,
      })
      handleGetListCompany()
      handleGetControllers(controllersInfo.page)
      setError({})
      setUserByEmail({})
      setFormData(initialFormData)
      dispatch(
        showFlashMessage({
          message: '総合受付に配車担当者が成功しました。',
        })
      )
    } catch (e) {
      const errors = _.get(e, 'data.errors', [])
      const isFormErrors = _.some(errors, 'field')

      if (isFormErrors) {
        setError(convertApiError(errors))
      } else {
        dispatch(
          showFlashMessage({
            isError: true,
            message: errors.map((err: any) => err.message).join('.'),
          })
        )
      }

      setLoading(false)
    }
  }, [
    controllersInfo.page,
    dispatch,
    formData,
    handleFindUserByEmail,
    handleGetControllers,
    handleGetListCompany,
    params.generalReceptionId,
    userOptions,
  ])

  const handleChangePage = useCallback(
    (page: number, keywordDraff: string, setKeywordDraff: (value: string) => void) => {
      handleGetControllers(page)
      if (keyword !== keywordDraff) {
        setKeywordDraff(keyword)
      }
    },
    [handleGetControllers, keyword]
  )

  const handleSearchDriver = useCallback(
    (keywordDraff: string) => {
      setKeyword(keywordDraff)
      handleGetControllers(FIRST_PAGE, keywordDraff)
    },
    [handleGetControllers]
  )

  useEffect(() => {
    if (params.generalReceptionId) {
      handleGetControllers(FIRST_PAGE)
      handleGetListCompany()
    }
  }, [handleGetControllers, handleGetListCompany, params.generalReceptionId])

  const handleScrollMenuSelectToBottomCompany = useCallback(
    (_: any, inputValue: string) => {
      const { page, totalPages } = companyData

      if (page >= totalPages || isLoadingCompany) return

      handleUpdateListCompany(inputValue, page + 1)
    },
    [companyData, isLoadingCompany, handleUpdateListCompany]
  )

  const handleInputChangeCompany = _.debounce((value) => {
    if (isFocusSelectCompany) {
      handleGetListCompany(value)
    }
  }, 400)

  const handleOnFocusSelectCompany = useCallback(() => {
    setIsFocusSelectCompany(true)
  }, [])

  const handleOnBlurSelectCompany = useCallback(() => {
    const { keyword } = companyData

    setIsFocusSelectCompany(false)

    if (keyword) {
      const { setSelectedCompany } = formData
      const keepCompany = companies.find((company) => company.id === setSelectedCompany.id)

      handleGetListCompany('', keepCompany)
    }
  }, [companies, companyData, formData, handleGetListCompany])

  const handleConfirmController = async () => {
    const { companyId, companyName, email, name, telNumber, userId } = userByEmail
    const foundedCompany = companies.find((company) => compareValue(company.id, companyId))

    //@ts-ignore
    setFormData((preData) => ({
      ...preData,
      companyId,
      companyName,
      email: email || '',
      name: name || '',
      telNumber: telNumber || '',
      id: userId,
      ...(!!foundedCompany
        ? { setSelectedCompany: foundedCompany }
        : {
            setSelectedCompany: {
              id: companyId,
              companyName: companyName,
              users: [],
            },
          }),
    }))
    setInfoConfirmControllerModal(initialConfirmControllerModal)
  }

  const handleCanceController = () => {
    const { email, name, telNumber, userId } = userByEmail
    setInfoConfirmControllerModal(initialConfirmControllerModal)

    //@ts-ignore
    setFormData((preData) => ({
      ...preData,
      name: name,
      telNumber: telNumber || '',
      id: userId,
      setSelectedCompany: {
        users: [
          {
            id: userId,
            name: name,
            email: email,
            telNumber: telNumber,
          },
        ],
      },
    }))
  }

  return {
    loading,
    formData,
    controllersInfo,
    companyOptions,
    userOptions,
    error,
    handleOnChange,
    handleFindUserByEmail,
    setControllerInfo,
    handleGetControllers,
    handleRemoveController,
    handleSubmit,
    keyword,
    setKeyword,
    handleChangePage,
    handleSearchDriver,
    isLoadingCompany,
    handleScrollMenuSelectToBottomCompany,
    handleInputChangeCompany,
    handleOnFocusSelectCompany,
    handleOnBlurSelectCompany,
    infoConfirmControllerModal,
    handleConfirmController,
    handleCanceController,
  }
}

export default useAddController
