import axios from 'axios'
import get from 'lodash/get'
import Qs from 'qs'

import loginStorage from 'utils/loginStorage'
import { statusCode, DEFAULT_CONFIG } from './constants'
import { compareValue, getTenantId } from 'utils/commonFunctions'
import { getStorageKeyByPathname, extractUidByPathname } from 'constants/Reception'
import Storage from 'containers/Reception/Storage'
import { store } from 'reduxStoreHelper'
import { openModalMaintain } from 'containers/App/reducers'
import { getGeneralReceptionFromLocal } from 'containers/ReceiptBooking/containers/exit/getGeneralReceptionFromLocal'

export function getCheckinAuthHeader(loginByGeneralReceptionNumber = false) {
  const localStorageKey = getStorageKeyByPathname()
  const dataSessionList = loginStorage.getCheckinAuthData(localStorageKey) || []
  const currentTenantId = getTenantId() || Storage.read('tenantId')
  const currentSession = dataSessionList.find((s) =>
    loginByGeneralReceptionNumber
      ? compareValue(s.generalReceptionNumber, currentTenantId)
      : compareValue(s.uid, currentTenantId)
  )
  return {
    uid: get(currentSession, 'uid', ''),
    client: get(currentSession, 'client', ''),
    'access-token': get(currentSession, 'accessToken', ''),
  }
}

export default class RestCheckinClient {
  constructor(config = {}) {
    this.config = Object.assign({}, DEFAULT_CONFIG, config)
  }

  get(url, params, config = {}) {
    return this.executeRequest(url, {
      method: 'get',
      ...config,
      params,
      paramsSerializer: (preParams) => Qs.stringify(preParams, { arrayFormat: 'brackets' }),
    })
  }

  patch({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'PATCH' })
  }

  put({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'PUT' })
  }

  post({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'POST' })
  }

  delete({ url, params, data, config }) {
    return this.executeRequest(url, { ...config, params, data, method: 'DELETE' })
  }

  executeRequest(url, config) {
    const finalConfig = Object.assign({}, this.config, { url, ...config })
    const localStorageKey = getStorageKeyByPathname()
    return axios
      .request(finalConfig)
      .then((res) => {
        const accessToken = get(res, 'headers.access-token', '')
        const client = get(res, 'headers.client', '')
        const uid = get(res, 'headers.uid', '')
        const generalReceptionNumber = get(config, 'data.general_reception_number', '')

        if (accessToken && client && uid) {
          loginStorage.setCheckinAuthData(localStorageKey, {
            accessToken,
            uid,
            client,
            generalReceptionNumber,
          })
        }
        return Promise.resolve(res.data)
      })
      .catch((errorResponse) => {
        const status = get(errorResponse, 'response.status')
        if (status === statusCode.SERVER_MAINTAIN_ERROR) {
          store.dispatch(openModalMaintain())
          return null
        }

        const { pathname, origin } = window.location || {}
        // If staus code is 401 or 403 of the general checkin, redirect to the checkin page and clear localStorage
        // Check general checkin (both new and old url)
        const isGeneralCheckin = pathname?.includes('general-reception')
        if (
          (status === statusCode.UNAUTHORIZED || status === statusCode.FORBIDDEN) &&
          isGeneralCheckin
        ) {
          const isGeneralCheckinByGeneralNumber = pathname?.includes('general-reception-desk')
          if (isGeneralCheckinByGeneralNumber) {
            const generalReceptionNumber = extractUidByPathname()
            loginStorage.clearCheckinData(
              localStorageKey,
              getGeneralReceptionFromLocal(generalReceptionNumber)?.uid
            )
            window.location.href = `${origin}/general-reception-desk/login/${generalReceptionNumber}`
          } else {
            const generalReceptionId = extractUidByPathname()
            loginStorage.clearCheckinData(localStorageKey, generalReceptionId)
            window.location.href = `${origin}/general-reception/${generalReceptionId}`
          }
        } else if (status === statusCode.UNAUTHORIZED) {
          loginStorage.clearCheckinData(localStorageKey, getTenantId())
        } else if (
          status === statusCode.INTERNAL_SERVER_ERROR &&
          url.indexOf('tablets/base_data') === 0
        ) {
          // eslint-disable-next-line no-restricted-globals
          location.href = '/receipt_logout.html'
        }
        const error = get(errorResponse, 'response.data')
        return Promise.reject({ ...error, status })
      })
  }
}
