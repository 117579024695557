import styled, { css } from 'styled-components'

import theme from 'vars/theme'
import { ErrorWrapper } from 'components/Layout/commonLayoutStyles'
import { HEADER_HEIGHT } from '../constants'

const HEIGHT_WRAPPER = window.innerHeight - HEADER_HEIGHT

export const WrapperUploadFile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 4px 4px 4px;
`

export const UploadImageButton = styled.label`
  cursor: pointer;
  margin: 0;
  padding: 5px 10px;
  color: ${theme.blueMedium5};
  border: 1px solid ${theme.blueMedium5};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`

export const InputFile = styled.input`
  display: none;

  &:disabled {
    & + ${UploadImageButton} {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
`

export const WrapperCheckbox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 4px;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
`

export const ListFile = styled.div`
  margin-left: 30px;
`

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const FileBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  font-size: 14px;
`

export const WrapperIconAndNameFile = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RemoveIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const ImageIcon = styled.i.attrs(() => ({
  className: 'far fa-file-image',
}))`
  margin-right: 5px;
`

export const Label = styled.label`
  margin-bottom: 0;
`

export const TimeSettingWrapper = styled.div`
  height: calc((var(--vh, 1vh) * 100) - ${HEADER_HEIGHT}px);
  padding: 16px;
  overflow-y: auto;
`

export const SettingContent = styled.div`
  width: 852px;
  margin: 0 auto;
`

export const Content = styled.div`
  background-color: ${theme.whitePrimary};
  border: 1px solid ${theme.grayPrimary2};
  padding: 16px 16px 24px;
  margin-bottom: 16px;
  overflow-y: auto;
`

export const ErrorCustom = styled(ErrorWrapper)`
  width: 100%;
  margin-top: 14px;
  text-align: center;
`

export const InputForm = styled.input`
  width: 70px;
  height: 32px;
  border-radius: 4px;
  margin-right: 8px;
  padding: 4px;
  text-align: right;

  &[type='text']:disabled {
    background: ${theme.graySoft17};
  }

  &:focus::placeholder {
    color: transparent;
  }
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CheckBox = styled.input`
  width: 22px;
  height: 22px;
  margin-right: 8px;
`

export const TimeSettingContent = styled.div`
  margin: 0 130px;
  padding-top: 8px;

  ${(props) =>
    props.isFlexCenter &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `};
`

export const MinusIcon = styled.span`
  width: 30px;
  border: 2px solid ${theme.blackSoft};
`

export const RedText = styled.div`
  color: ${theme.redPrimary1}; ;
`

export const CheckBoxTruckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`
