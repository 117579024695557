import React from 'react'
import { fromJS } from 'immutable'
import { handleActions, combineActions, createActions, createAction } from 'redux-actions'
import _ from 'lodash'

import * as types from './constants'
import ErrorMessages from 'constants/ErrorMessages'
import { EnvironmentNames } from 'constants/App'

const emptyModalOptions = Object.freeze({
  isOpen: false,
  content: undefined, // string, component, function
  buttons: [],
})

const emptyToastOptions = Object.freeze({
  isOpen: false,
  content: undefined, // string, component, function
})

const initialState = fromJS({
  userData: {},
  receptionData: {},
  userBookingPermission: {},
  loading: false,
  loadingLogout: false,
  notices: [],

  // アプリ全体で使う共通機能
  base: {
    loading: false,
    modalOptions: emptyModalOptions,
    toastOptions: emptyToastOptions,
    windowInnerHeight: window.innerHeight,
  },

  // maintain:
  isOpenModalMaintain: false,

  customBerthLabel: '',

  bookingRemindStatus: [],

  // 設定
  receptionNotificationSound: {},

  debugLogs: [],
})

export const startLoading = createAction('base/START_LOADING')
export const stopLoading = createAction('base/STOP_LOADING')

export const {
  // startLoading,
  // stopLoading,
  openModal,
  closeModal,
  openToast,
  closeToast,
  openError,
  fetchAnything,
  updateWindowSize,
  addDebugLog,
  updateReceptionNotificationSound,
  openModalMaintain,
  fetchCustomBerthLabel,
  fetchCustomBerthLabelSucess,
  fetchBookingRemindStatusSuccess,
} = createActions({
  // START_LOADING: _.noop,
  // STOP_LOADING: _.noop,
  OPEN_MODAL: (data) => data,
  CLOSE_MODAL: _.noop,
  OPEN_TOAST: (data) => data,
  CLOSE_TOAST: _.noop,
  OPEN_ERROR: (message, useNetworkError = true) => ({ message, useNetworkError }),
  FETCH_ANYTHING: (data) => data,
  UPDATE_WINDOW_SIZE: (height) => height,
  ADD_DEBUG_LOG: (data) => data,
  UPDATE_RECEPTION_NOTIFICATION_SOUND: (data) => data,
  OPEN_MODAL_MAINTAIN: () => _.noop(),
  [types.FETCH_CUSTOM_BERTH_LABEL]: (data) => data,
  [types.FETCH_CUSTOM_BERTH_LABEL_SUCEESS]: (data) => data,
  [types.FETCH_BOOKING_REMIND_STATUS_SUCCESS]: (data) => data,
})

const rootReducer = handleActions(
  {
    [combineActions(types.GET_USER_DATA, types.GET_AUTHEN_DATA)]: (state) =>
      state.set('loading', true),
    [types.SIGN_OUT_USER]: (state) => state.set('loadingLogout', true),
    [combineActions(types.SIGN_OUT_USER_SUCCESS, types.SIGN_OUT_USER_FAILURE)]: (state) =>
      state.set('loadingLogout', false),
    [types.GET_USER_DATA_SUCCESS]: (state, { payload }) =>
      state
        .set('userData', fromJS(payload.userData))
        .set('receptionData', fromJS(payload.receptionData))
        .set('userBookingPermission', fromJS(payload.userBookingPermission))
        .set('loading', false),
    [types.GET_USER_DATA_FAILURE]: (state) => state.set('loading', false),

    [types.FETCH_CUSTOM_BERTH_LABEL_SUCEESS]: (state, { payload }) => {
      return state.set('customBerthLabel', fromJS(payload))
    },

    [types.FETCH_BOOKING_REMIND_STATUS_SUCCESS]: (state, { payload }) => {
      return state.set('bookingRemindStatus', payload)
    },

    // アプリ全体で使う共通機能

    // loading
    'base/START_LOADING': (state) => {
      const base = state.get('base')
      return state.set('base', base.set('loading', true))
    },
    'base/STOP_LOADING': (state) => {
      const base = state.get('base')
      return state.set('base', base.set('loading', false))
    },

    // modal
    OPEN_MODAL: (state, { payload }) => {
      const base = state.get('base')
      const modalOptions = payload
      modalOptions.isOpen = true
      return state.set('base', base.set('modalOptions', fromJS(modalOptions)))
    },
    // error modal
    OPEN_ERROR: (state, { payload }) => {
      const base = state.get('base')
      const { message, useNetworkError } = payload
      console.error(message)
      const content = useNetworkError
        ? ErrorMessages[message] || '通信エラーが発生しました'
        : message
      const modalOptions = {
        isOpen: true,
        content:
          [EnvironmentNames.STAGING, EnvironmentNames.PRODUCTION].includes(
            process.env.REACT_APP_ENV
          ) || !useNetworkError ? (
            content
          ) : (
            <div>
              <div>{content}</div>
              <div />
              <div>開発中のみ表示するメッセージ</div>
              <div>{message}</div>
            </div>
          ),
        buttons: [
          {
            label: 'とじる',
            color: 'primary',
          },
        ],
      }
      return state.set('base', base.set('modalOptions', fromJS(modalOptions)).set('loading', false))
    },
    CLOSE_MODAL: (state) => {
      const base = state.get('base')
      return state.set('base', base.set('modalOptions', fromJS(emptyModalOptions)))
    },

    // toast
    OPEN_TOAST: (state, { payload }) => {
      const base = state.get('base')
      const content = payload
      const toastOptions = {
        isOpen: true,
        content,
      }
      return state.set('base', base.set('toastOptions', fromJS(toastOptions)))
    },
    CLOSE_TOAST: (state) => {
      const base = state.get('base')
      return state.set('base', base.set('toastOptions', fromJS(emptyToastOptions)))
    },
    UPDATE_WINDOW_SIZE: (state, { payload }) => {
      const base = state.get('base')
      const height = payload
      return state.set('base', base.set('windowInnerHeight', height))
    },

    [types.UPDATE_NOTICES]: (state, { payload: { notices } }) =>
      state.set('notices', fromJS(notices)),

    ADD_DEBUG_LOG: (state, { payload }) => {
      if (process.env.REACT_APP_ENV === EnvironmentNames.PRODUCTION) return state

      const debugLogs = state.get('debugLogs').toJSON()
      debugLogs.push(payload)
      return state.set('debugLogs', fromJS(debugLogs))
    },

    FETCH_SUCCESS: (state, { payload: { storePath = '', data } }) => {
      if (!storePath.startsWith('base/')) {
        return state
      }

      const pathNodes = storePath.split('/')
      const target = _.last(pathNodes)

      return state.set(target, fromJS(data))
    },

    UPDATE_RECEPTION_NOTIFICATION_SOUND: (state, { payload }) => {
      return state.set('receptionNotificationSound', fromJS(payload))
    },
    OPEN_MODAL_MAINTAIN: (state) => {
      if (state.get('isOpenModalMaintain')) {
        return state
      }
      return state.set('isOpenModalMaintain', true)
    },
  },
  initialState
)

export default rootReducer
