import { fromJS } from 'immutable'
import { handleActions, createActions } from 'redux-actions'
import _ from 'lodash'

import { context } from './constants'

const EMPTY_BOOKING = Object.freeze({
  bookingId: '',
  bookingNo: '',
  destination: '',
  driverName: '',
  driverId: '',
  driverPhone: '',
  driverCompanyId: '',
  driverCompanyName: '',
  vehicleNumber: '',
  vehicleCategory: '',
  importGoodsOwner: '',
  exportGoodsOwner: '',
  importGoodsName: '',
  exportGoodsName: '',
  importDeliverySlipNumber: '',
  exportDeliverySlipNumber: '',
  importPackageType: '',
  exportPackageType: '',
  importMainGoods: '',
  exportMainGoods: '',
  importContactNotes: '',
  exportContactNotes: '',
  importGoodsAmount: '',
  exportGoodsAmount: '',
  exportFlag: 0,
  importFlag: 0,
  status: '',
  receptionNo: '',
})

const initialState = fromJS({
  isReturnEntryPage: true,
  isPhoneChecked: false,
  isCreatedBookingData: false,
  loading: false,
  error: null,
  driver: {},
  phoneNumberEntered: '',
  policyData: {},
  booking: { ...EMPTY_BOOKING },
  bookingFromBookingNo: {},
  isFromBookingNo: false,
  selectedBooking: {},
  isFromListCheckin: false,
  masterData: {},
  recentBooking: {},
  listCompanies: [],
  generalReceptionDetail: {},
  isAcceptPolicyChangeDriverName: false,
})

export const {
  startLoading,
  endLoading,
  fetchFailed,
  fetchSuccess,
  resetAllData,
  fetchDriverInfo,
  fetchDriverInfoSuccess,
  resetDriverInfo,
  updateBooking,
  replaceBooking,
  updateCommonData,
  resetCommonData,
  clearBooking,
  setIsFromBookingNo,
  setSelectedBooking,
  resetSelectedBooking,
  setGeneralReceptionDetail,
  setIsAcceptPolicyChangeDriverName,
} = createActions(
  {
    START_LOADING: _.noop,
    END_LOADING: _.noop,
    FETCH_FAILED: (error) => error,
    FETCH_SUCCESS: (data) => data,
    RESET_ALL_DATA: _.noop,
    FETCH_DRIVER_INFO: (generalReceptionId, phoneNumberEntered) => ({
      generalReceptionId,
      phoneNumberEntered,
    }),
    RESET_DRIVER_INFO: _.noop,
    FETCH_DRIVER_INFO_SUCCESS: (data) => data,
    UPDATE_BOOKING: (data) => data,
    REPLACE_BOOKING: (data) => data,
    UPDATE_COMMON_DATA: (data) => data,
    RESET_COMMON_DATA: _.noop,
    CLEAR_BOOKING: (data) => data,
    SET_IS_FROM_BOOKING_NO: (status) => status,
    SET_SELECTED_BOOKING: (data) => data,
    RESET_SELECTED_BOOKING: _.noop,
    SET_GENERAL_RECEPTION_DETAIL: (data) => data,
    SET_IS_ACCEPT_POLICY_CHANGE_DRIVER_NAME: (data) => data,
  },
  { prefix: context }
)

export const reducer = handleActions(
  {
    START_LOADING: (state) => state.set('loading', true),
    END_LOADING: (state) => state.set('loading', false),
    FETCH_FAILURE: (state, { payload }) => state.set('loading', false).set('error', payload),
    FETCH_SUCCESS: (state, { payload: { key, data } }) =>
      state.set('loading', false).set(key, data),
    FETCH_DRIVER_INFO_SUCCESS: (
      state,
      { payload: { phoneNumberEntered, driver, policyData, isPhoneChecked } }
    ) =>
      state
        .set('phoneNumberEntered', phoneNumberEntered)
        .set('driver', driver)
        .set('policyData', policyData)
        .set('isPhoneChecked', isPhoneChecked)
        .set('loading', false),
    RESET_DRIVER_INFO: (state) =>
      state
        .set('phoneNumberEntered', '')
        .set('driver', {})
        .set('policyData', {})
        .set('isPhoneChecked', false),
    UPDATE_BOOKING: (state, { payload }) => {
      const booking = { ...state.toJS().booking, ...payload }
      return state.set('booking', booking)
    },
    REPLACE_BOOKING: (state, { payload }) => {
      const booking = payload
      return state.set('booking', booking)
    },
    UPDATE_COMMON_DATA: (state, { payload }) => {
      const { masterData, listCompanies, recentBooking } = payload
      return state
        .set('masterData', masterData)
        .set('listCompanies', listCompanies)
        .set('recentBooking', recentBooking)
        .set('isCreatedBookingData', true)
    },
    CLEAR_BOOKING: (state, { payload }) => state.set('booking', EMPTY_BOOKING),
    RESET_COMMON_DATA: (state) =>
      state
        .set('masterData', {})
        .set('listCompanies', [])
        .set('recentBooking', {})
        .set('isCreatedBookingData', false),
    SET_IS_FROM_BOOKING_NO: (state, { payload }) => state.set('isFromBookingNo', payload),
    SET_SELECTED_BOOKING: (state, { payload }) =>
      state.set('selectedBooking', payload).set('isFromListCheckin', true),
    RESET_SELECTED_BOOKING: (state) =>
      state.set('selectedBooking', {}).set('isFromListCheckin', false),
    RESET_ALL_DATA: (state) => state.merge(initialState.toJS()),
    SET_GENERAL_RECEPTION_DETAIL: (state, { payload }) =>
      state.set('generalReceptionDetail', payload),
    SET_IS_ACCEPT_POLICY_CHANGE_DRIVER_NAME: (state, { payload }) =>
      state.set('isAcceptPolicyChangeDriverName', payload),
  },
  initialState,
  { prefix: context }
)
