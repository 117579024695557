import React, { useEffect, memo } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'

import injectReducer from 'utils/injectReducer'
import { reducer, fetchGeneralReceptions, resetData } from './reducer'
import saga from './saga'
import DefaultLayout from './components/DefaultLayout'
import { context, routes } from './constants'
import CardItemsPage from './pages/CardItemsPage'
import ConfirmationPage from './pages/ConfirmationPage'
import ConfirmationEditPage from './pages/ConfirmationEditPage'
import ItemsPage from './pages/ItemsPage'
import OptionPage from './pages/OptionPage'
import injectSaga from 'utils/injectSaga'
import { loadingSelectors, generalReceptionSelectedSelectors } from './selectors'
import LoadingIndicator from 'components/LoadingIndicator'

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
`
interface IProps {
  loading: boolean
  resetData: () => void
  fetchGeneralReceptions: () => void
}
const routerTab = [routes.cardItemsPage, routes.confirmationPage, routes.itemsPage]

const IntegratedReceptionCustomRouter = ({
  fetchGeneralReceptions,
  loading,
  resetData,
}: IProps) => {
  const location = useLocation()

  useEffect(() => {
    if (routerTab.includes(location?.pathname)) {
      fetchGeneralReceptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname])

  useEffect(() => {
    // Clear when exit the page
    return () => {
      resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Switch>
        <DefaultLayout path={routes.itemsPage}>
          <ItemsPage />
        </DefaultLayout>
        <DefaultLayout path={routes.confirmationPage}>
          <ConfirmationPage />
        </DefaultLayout>
        <DefaultLayout path={routes.cardItemsPage}>
          <CardItemsPage />
        </DefaultLayout>
        <DefaultLayout path={routes.optionsPage} isEditPage>
          <OptionPage />
        </DefaultLayout>
        <DefaultLayout path={routes.confirmationEditPage} isEditPage>
          <ConfirmationEditPage />
        </DefaultLayout>
      </Switch>
      {loading && (
        <>
          <Backdrop />
          <LoadingIndicator />
        </>
      )}
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  loading: loadingSelectors(),
  generalReceptionSelected: generalReceptionSelectedSelectors(),
})

const mapDispatchToProps = {
  fetchGeneralReceptions,
  resetData,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: context, reducer })
const withSaga = injectSaga({ key: context, saga, mode: null })

export default compose(memo, withReducer, withSaga, withConnect)(IntegratedReceptionCustomRouter)
