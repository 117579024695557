import {
  testMailValidate,
  testCellPhoneValidate,
  testCellPhoneStartWithValidate,
} from 'utils/helper'
import errorMessage from 'utils/errorMessages'
import { GeneralBooking, BookingError } from './type'

export const validateBooking = (booking: GeneralBooking): BookingError => {
  const { HomePage } = errorMessage
  const {
    vehicleControllerId,
    vehicleControllerEmail,
    driverId,
    driverName,
    driverEmail,
    driverPhone,
    planStartDate,
    planEndDate,
  } = booking
  const error: BookingError = {}
  if(driverPhone && !driverName) error.driverName = errorMessage.HomePage.driverNameBlank()
  if (vehicleControllerId && !vehicleControllerEmail)
    error.vehicleControllerEmail = HomePage.controllerEmailBlank()

  if (vehicleControllerEmail && !testMailValidate(vehicleControllerEmail))
    error.vehicleControllerEmail = HomePage.emailInvalid()

  if (driverEmail && !testMailValidate(driverEmail)) error.driverEmail = HomePage.emailInvalid()

  if (driverId && !driverPhone) error.driverPhone = HomePage.phoneBlank()

  if (driverPhone && !testCellPhoneStartWithValidate(driverPhone))
    error.driverPhone = HomePage.cellPhoneStartWithInvalid()

  if (driverPhone && !testCellPhoneValidate(driverPhone))
    error.driverPhone = HomePage.cellPhoneInvalid()

  if (!planStartDate) error.planStartDate = errorMessage.HomePage.planStartDateRequired()

  if (!planEndDate) error.planEndDate = errorMessage.HomePage.planEndDateRequired()

  return error
}
