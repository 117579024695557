import { matchPath } from 'react-router'

import freezeR from 'utils/freezeR'

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount'
export const DAEMON = '@@saga-injector/daemon'
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount'
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const BASE_URL = process.env.REACT_APP_API_URL
export const BASE_URL_V2_1 = process.env.REACT_APP_API_URL_V2_1
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY
export const TERM_OF_USE_1 = process.env.REACT_APP_TERM_OF_USE_1
export const TERM_OF_USE_2 = process.env.REACT_APP_TERM_OF_USE_2
export const SHOW_CSV_UPLOAD = process.env.REACT_APP_SHOW_CSV_UPLOAD

export const NOTICE_REVISION_TXT_URL = `${process.env.REACT_APP_NOTICE_URL}notice-revision.txt`
export const NOTICES_JSON_URL = `${process.env.REACT_APP_NOTICE_URL}notices.json`

export const ROUTES = {
  home: () => '/',
  warehouse: {
    index: () => '/warehouse',
    create: () => '/warehouse/create',
    edit: (id, tenantId) =>
      tenantId ? `/warehouse/${id}/${tenantId}/edit` : `/warehouse/${id}/edit`,
    createBerth: (id, tenantId) => `/warehouse/${id}/${tenantId}/berth/create`,
    editBerth: (id, tenantId, berthId) => `/warehouse/${id}/${tenantId}/berth/${berthId}/edit`,
    editManager: (id) => `/warehouse/${id}/manager-management`,
    addWarehouseManager: (id, tenantId, role) => `/warehouse/${id}/tenant/${tenantId}/${role}`,
    editMessageCustom: (id, tenantId, messageId) =>
      `/warehouse/${id}/${tenantId}/customize-message/${messageId}/edit`,
    editMessageDefault: (id, tenantId, messageId) =>
      `/warehouse/${id}/${tenantId}/fixed-message/${messageId}/edit`,
    addGroupBerth: (id, tenantId) => `/warehouse/${id}/${tenantId}/group-berth/create`,
    editGroupBerth: (id, tenantId, berthId) =>
      `/warehouse/${id}/${tenantId}/group-berth/${berthId}/edit`,
    editCheckInTime: (id, tenantId) => `/warehouse/${id}/${tenantId}/checkin-time/edit`,
  },

  receiptBooking: {
    index: (params) => `/xreceipt-booking/${params.warehouseId}/${params.tenantId}`,
    entry: (params) => `/xreceipt-booking/entry/${params.warehouseId}/${params.tenantId}`,
    exit: (params) => `/xreceipt-booking/exit/${params.warehouseId}/${params.tenantId}`,
  },
}

export const messages = {
  SettingMain: {
    timeWidthUpdated: () => '時間幅の更新は成功しました。',
    passwordUpdated: () => 'パスワードの更新は成功しました。',
  },
  Warehouse: {
    warehouseCreated: () => '倉庫は正常に作成されました。',
    warehouseUpdated: () => '倉庫は正常に更新されました。',
    warehouseDeleted: () => '倉庫は正常に削除されました。',
    berthCreated: () => 'バースは正常に作成されました。',
    berthUpdated: () => 'バースは正常に更新されました。',
    berthDeleted: () => 'バースは正常に削除されました。',
  },
}

export const ROLES = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'admin',
  FULL_OPEN_ADMIN: 'fullopen_super',
  FULL_OPEN_NORMAL: 'fullopen_normal',
  DRIVER_CONTROLLER: 'driver_controller',
  DRIVER: 'driver',
}

export const PERMISSIONS = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'destroy',
  MANAGE: 'manage',
}

export const ACTIONS = {
  BERTH: 'Berth',
  WAREHOUSE: 'Warehouse',
  COMPANY: 'Company',
  TRUCK: 'Vehicle',
  USER: 'User',
  ACCOUNTING: 'Accounting',
  DEVICE: 'Device',
  BOOKING: 'booking',
}

export const matchParamLogout = (url = window.location.pathname) =>
  matchPath(url, {
    path: '/xreceipt-booking/:warehouseId/:tenantId/logout',
    exact: true,
    strict: false,
  })

export const matchParam = (url = window.location.pathname) =>
  matchPath(url, {
    path: '/xreceipt-booking/:warehouseId/:tenantId',
    exact: true,
    strict: false,
  })

export const matchParamNew = (url = window.location.pathname) =>
  matchPath(url, {
    path: '/receipt-booking/:warehouseId/:tenantId',
    exact: true,
    strict: false,
  })

export const matchEntryParam = (url = window.location.pathname) =>
  matchPath(url, {
    path: '/xreceipt-booking/entry/:warehouseId/:tenantId',
    exact: true,
    strict: false,
  })

export const matchExitParam = (url = window.location.pathname) =>
  matchPath(url, {
    path: '/xreceipt-booking/exit/:warehouseId/:tenantId',
    exact: true,
    strict: false,
  })

export const matchExitParamNew = (url = window.location.pathname) =>
  matchPath(url, {
    path: '/receipt-booking/exit/:warehouseId/:tenantId',
    exact: true,
    strict: false,
  })

/** @deprecated */
export const statusValues = {
  All: 0,
  Unassigned: 1,
  Recept: 2,
  Booking: 3,
  Confirmed: 4,
  Delay_Expected: 5,
  Delay: 6,
  Arrival: 7,
  Calling: 8,
  Working: 9,
  Finish: 10,
}

/** @deprecated */
export const statusLabel = {
  All: 'すべて',
  Unassigned: '未割当',
  Recept: '受付済',
  Booking: '調整中',
  Force_Confirmed: '確定',
  Confirmed: '予約済',
  Delay_Expected: '遅延見込',
  Delay: '遅延中',
  Arrival: '受付済',
  Calling: '呼出中',
  Start_Calling: '呼出',
  Cancel_Calling: '呼出取消',
  Working: '作業中',
  Start_Working: '作業',
  Finish: '完了',
}

export const BookingStatuses = freezeR({
  All: { id: 0, label: 'すべて' },
  Unassigned: { id: 1, label: '未割当' },
  Recept: { id: 2, label: '受付済' },
  Booking: { id: 3, label: '調整中' },
  // Force_Confirmed: { label: '確定' },
  Confirmed: { id: 4, label: '予約済' },
  Delay_Expected: { id: 5, label: '遅延見込' },
  Delay: { id: 6, label: '遅延中' },
  Arrival: { id: 7, label: '受付済' },
  Calling: { id: 8, label: '呼出中' },
  // Start_Calling: { label: '呼出' },
  // Cancel_Calling: { label: '呼出取消' },
  Working: { id: 9, label: '作業中' },
  // Start_Working: { label: '作業' },
  Finish: { id: 10, label: '完了' },

  // フロント側でのみ使っているID
  WaitingOne: { id: 101, label: '受付済' },
  WaitingTwo: { id: 102, label: '受付済' },
  Deleted: { id: 103, label: '削除済' },
  PreAssign: { id: 11 },
})

export const GENERAL_BOOKING_WAITING_STATUS_OFFSET = 100

// TODO app/containers/IntegratedManagementPage/constants.js に移す
export const GeneralBookingWaitingStatuses = freezeR({
  WAITING_ZERO: {
    id: 0,
    label: '受付済',
  },
  WAITING_ONE: {
    id: 1,
    label: '受付済',
  },
  WAITING_TWO: {
    id: 2,
    label: '受付済',
  },
})

export const DisplayModes = Object.freeze({
  // 1時間表示
  INTERVAL_1_HOUR: 6,
  // 4時間表示
  INTERVAL_4_HOURS: 1,
})

export const BOOKING_SOURCE = {
  PORTAL: 4, // portal app
}

export const VEHICLE_NUMBER_LENGTH = 4

export const REGEX = {
  EMAIL:
    /^(?:(?:[!#$%&'*+/=?^_`{|}~-]*[a-z0-9][!#$%&'*+/=?^_`{|}~-]*)+(?:\.(?:[!#$%&'*+/=?^_`{|}~-]*[a-z0-9][!#$%&'*+/=?^_`{|}~-]*)+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9])\])$/,
  CELL_PHONE: /^\d{11}$/,
  TEL_NUMBER_ON_CHANGE: /^\d{0,11}$/,
  TEL_NUMBER: /^\d{10,11}$/,
  CONTACT_PHONE: /^(0)\d{9,10}$/,
  VIEW_ORDER: /^\d{0,2}$/,
  CELL_PHONE_START_WITH: /^(0|84)/,
  PASSWORD: /.*([a-zA-Z].*[0-9]|[0-9].*[a-zA-Z]).*/,
  FULL_WIDTH: /^([０-９Ａ-Ｚａ-ｚ！-～ぁ-んァ-ン一-龥]|ー)*$/,
}

export const TEN_DIGITS = 2147483647

export const checkIsPreAssign = (booking) => {
  return booking.general_booking_id && booking.status === statusValues.Unassigned
}

export const optionEnCodingsDownloadCsv = freezeR([
  { label: 'Shift-JIS', value: 'Shift-JIS' },
  { label: 'UTF-8', value: 'UTF-8' },
  { label: 'Windows-31J', value: 'Windows-31J' },
])

export const DownloadTypes = freezeR({
  PLAN: {
    label: 'plan',
    value: 1,
  },
  ACTUAL: {
    label: 'actual',
    value: 2,
  },
})
export const LIMIT_DAYS_DOWNLOAD_CSV = 90
export const FREE_PLAN_PERIOD_MONTH = 1
export const STANDARD_PLAN_PERIOD_YEAR = 2

export const canCheckin = (status) =>
  [
    statusValues.Unassigned,
    statusValues.Booking,
    statusValues.Confirmed,
    statusValues.Delay_Expected,
    statusValues.Delay,
  ].includes(status)

export const TIME_AUTO_REDIRECT_CHECKIN = 10 * 1000

export const TypeMessageDefault = [
  { id: 0, name: 'CHECKIN_FINISH' },
  { id: 1, name: 'CHECKIN_CALLING' },
  { id: 2, name: 'CHECKIN_CONFIRMED' },
  { id: 3, name: 'CHECKIN_ASSIGNED' },
  { id: 4, name: 'CHECKIN_UNASSIGN' },
]

export const TypeMessageCustom = [
  { id: 5, name: 'CUSTOM_1' },
  { id: 6, name: 'CUSTOM_2' },
  { id: 7, name: 'CUSTOM_3' },
  { id: 8, name: 'CUSTOM_4' },
]

export const TypeMessageCustomIds = TypeMessageCustom.map((type) => type?.id)

export const FIRST_PAGE = 1
export const DEFAULT_TOTAL_PAGE = 1

export const MAX_ITEM_SEARCH_HISTORY = 2

export const filterPredicateHistoriesStore = (store, keyword) => {
  return store?.filter((item) => !keyword?.startsWith(item?.keyword))
}

export const TIME_SETTING_WAITING_MODE = {
  MIX_MAX: 1,
  AVERAGE: 2,
}

export const customBerthlabel = {
  LABEL_DEFAUT: 'バース',
  KEY_LOCAL: 'customBerthLabel',
}

export const TIME_DONT_ALLOW_CLOSE_BOOKING_IMMEDIATE = 2000

export const BOOKING_SOURCES = freezeR({
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
  Five: 5,
  Six: 6,
  Seven: 7,
  Eight: 8,
  Nine: 9,
  Ten: 10,
})
