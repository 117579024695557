import { useEffect, useState } from 'react'

const useWindowFocus = () => {
  const [isWindowFocus, setIsWindowFocus] = useState(true)

  useEffect(() => {
    const onWindowFocus = () => {
      setIsWindowFocus(true)
    }

    const onWindowBlur = () => {
      setIsWindowFocus(false)
    }

    window.addEventListener('focus', onWindowFocus)
    window.addEventListener('blur', onWindowBlur)

    return () => {
      window.removeEventListener('focus', onWindowFocus)
      window.removeEventListener('blur', onWindowBlur)
    }
  }, [])

  return isWindowFocus
}

export default useWindowFocus
