export const context = 'containers/IntegratedSettingPage'

export const Routes = {
  master: '/multi-setting',
  createGeneralReception: '/multi-setting/create',
  editGeneralReception: '/multi-setting/:generalReceptionId/edit',
  addTenant: '/multi-setting/:generalReceptionId/tenant/add',
  addAdmin: '/multi-setting/:generalReceptionId/add-admin',
  addController: '/multi-setting/:generalReceptionId/driver-controller',
}

export const TableHeaderTenant = [
  {
    key: 'viewOrder',
    title: '表示順',
    isHightLightItem: true,
  },
  {
    key: 'generalTenantName',
    title: '表示名',
    isHightLightItem: true,
  },
  {
    key: 'warehouseName',
    title: '倉庫名',
    isHightLightItem: true,
  },
  {
    key: 'tenantName',
    title: 'テナント名',
    isHightLightItem: true,
  },
]

export const TableHeaderManagerGeneralReception = [
  {
    key: 'userName',
    title: '管理者名',
    isHightLightItem: true,
  },
  {
    key: 'telNumber',
    title: '電話',
  },
  {
    key: 'userEmail',
    title: 'メール',
    isHightLightItem: true,
  },
]

export const TableHeaderControllerGeneralReception = [
  {
    key: 'companyName',
    title: '運送会社名',
  },
  {
    key: 'userName',
    title: '名前',
    isHightLightItem: true,
  },
  {
    key: 'telNumber',
    title: '電話',
  },
  {
    key: 'userEmail',
    title: 'メール',
    isHightLightItem: true,
  },
]

export const TableHeaderOfAddControllerPage = [
  {
    key: 'companyName',
    title: '運送会社名',
  },
  {
    key: 'userName',
    title: '名前',
  },
  {
    key: 'telNumber',
    title: '電話',
  },
  {
    key: 'userEmail',
    title: 'メール',
    isHightLightItem: true,
  },
]

export const TableHeaderManagerDetailTenant = [
  {
    key: 'screenDisplay',
    title: '表示順',
  },
  {
    key: 'name',
    title: 'バース名',
  },
  {
    key: 'camera',
    title: 'カメラ',
  },
  {
    key: 'autoCall',
    title: '自動呼出',
  },
  {
    key: 'note',
    title: 'メモ',
  },
]

export const FIRST_PAGE = 1
export const PER_PAGE = 10
export const PER_PAGE_OF_MASTER = 5

export const MIN_LONGITUDE = 0
export const MIN_LATITUDE = 0
export const MAX_LONGITUDE = 180
export const MAX_LATITUDE = 90

export const ROLE_TYPE = {
  ADMIN: 'admin',
  CONTROLLER: 'controller',
  VIEWER: 'viewer',
} as const

export const NOTIFICATION_METHOD = {
  SMS: 0,
  LINE: 1,
  PORTAL: 2,
} as const

export const DEFAULT_OPTION = {
  value: '',
  label: '選択or入力',
}
