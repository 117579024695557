import styled from 'styled-components'
import theme from 'vars/theme'

import { downArrowStyle } from 'vars/style-utils'

export const HeaderWrapper = styled.div`
  height: ${theme.headerHeight};
  width: 100%;
  display: flex;
  color: white;

  .brand {
    flex: 0 0 ${theme.sidebarWidth};
    background-color: ${theme.bluePrimary1};
    font-size: 16px;
    padding: 0 22px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 40px;
      width: 1px;
      background: ${theme.whitePrimary};
      right: -1px;
      margin-top: -20px;
      top: 50%;
    }
  }

  .main {
    flex: 1;
    width: calc(100% - 470px);
    padding: 0 22px;
    background-color: ${theme.bluePrimary1};
    justify-content: space-between;

    .divider {
      border-color: white;
      border-style: solid;
      border-width: 0 1px 0 0;
      width: 20px;
      height: 32px;
    }
  }

  .dropdown-toggle:after {
    ${downArrowStyle()};
  }
`
