export const context = 'containers/App'

export const GET_AUTHEN_DATA = `${context}/UPDATE_AUTHEN_DATA`

// get users
export const GET_USER_DATA = `${context}/GET_USER_DATA`
export const GET_USER_DATA_SUCCESS = `${context}/GET_USER_DATA_SUCCESS`
export const GET_USER_DATA_FAILURE = `${context}/GET_USER_DATA_FAILURE`

// Sign out user
export const SIGN_OUT_USER = `${context}/SIGN_OUT_USER`
export const SIGN_OUT_USER_SUCCESS = `${context}/SIGN_OUT_USER_SUCCESS`
export const SIGN_OUT_USER_FAILURE = `${context}/SIGN_OUT_USER_FAILURE`

export const UPDATE_NOTICES = `${context}/UPDATE_NOTICES`

export const FETCH_CUSTOM_BERTH_LABEL = 'FETCH_CUSTOM_BERTH_LABEL'
export const FETCH_CUSTOM_BERTH_LABEL_SUCEESS = 'FETCH_CUSTOM_BERTH_LABEL_SUCESS'

export const FETCH_BOOKING_REMIND_STATUS_SUCCESS = 'FETCH_BOOKING_REMIND_STATUS_SUCCESS '
