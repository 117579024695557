import styled from 'styled-components'

import { DRIVER_WIDTH } from './constants'
import theme from 'vars/theme'

export const PaginationContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PaginationTextResult = styled.span`
  font-size: 14px;
  margin-right: 16px;

  span {
    font-size: 16px;
    font-weight: bold;
    margin-left: 6px;

    &.total-item {
      font-size: 22px;
    }
  }
`
export const SearchFilterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(isEmptyBooking) => isEmptyBooking && `padding: 0 0 8px 20px;`}
`

export const SearchFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const GeneralBookingDetailWrapper = styled.div`
  flex: 1;
  width: calc((100% - ${DRIVER_WIDTH}px) / 2);
`

export const BookingDetailWrapper = styled.div`
  position: absolute;
  left: 20px;
  top: ${({ top }) => top && `${top}px`};
  width: ${({ width }) => width && `${width}px`};
  background-color: ${theme.whitePrimary};
  border-radius: 12px;
  box-shadow: 0px 4px 16px rgba(17, 32, 64, 0.25);
`

export const BookingDetailTop = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${theme.graySoft2};
  border-left: 2px solid ${theme.graySoft2};
`

export const BookingDriverName = styled.div`
  width: 40%;
`

export const BookingVehicleNumber = styled.div`
  width: 20%;
`

export const BookingVehicleCategory = styled.div`
  min-width: 20%;
  flex: 1;
`

export const TextVehicleCategory = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

export const DriverInformationWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-clamp: 1;
  box-orient: vertical;
  max-width: 90%;
`

export const WrapperDetailClose = styled.div`
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin: 12px;
`

export const BookingDriverContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 15px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  border-left: 2px solid ${theme.graySoft2};

  @media screen and (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const Table = styled.table`
  width: 100%;

  td {
    width: 50%;
  }

  .display-name {
    white-space: nowrap;
  }

  ${({ maximumWidthColumn }) =>
    maximumWidthColumn?.left &&
    `
      .display-name.left {
        width: ${maximumWidthColumn?.left}px;
    }
 `}

  ${({ maximumWidthColumn }) =>
    maximumWidthColumn?.right &&
    `
      .display-name.right {
        width: ${maximumWidthColumn?.right}px;
    }
 `}

  .colon-character {
    padding-left: 6px;
  }

  .display-column-name {
    flex: 1;
    padding-left: 6px;
    padding-right: 6px;
  }
`

export const WrapperIconImportExport = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 11px;
  margin-top: 3px;

  .import,
  .export {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    border: 1px solid rgba(37, 39, 44, 0.6);
  }

  .import {
    border-right: none;
    border-bottom-left-radius: 4px;
  }

  .export {
    border-top-right-radius: 4px;
  }

  .active {
    opacity: 1;
    color: ${theme.whitePrimary};
    background: ${theme.darkBlueMedium4};
    border: none;
  }
`

export const EllipsisWrapper = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const TextLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-clamp: 1;
  box-orient: vertical;
  min-width: 25px;
`

export const VehicleSummaryIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 36px;
  margin-bottom: 2px;
`

export const VehicleSummaryLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const VehicleSummaryRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 46px);
  margin-right: 20px;
  font-size: 14px;
  font-weight: bold;
`

export const VehicleSummaryInfo = styled.div`
  display: flex;
  align-items: center;
  width: 260px;
  line-height: 1;
  margin-bottom: 2px;
  min-height: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ItemImportExportContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
