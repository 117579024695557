import React from 'react'
import { Label, Input } from 'reactstrap'

import { FormWrapper, ErrorText } from './style'
import DayPickerForm from './DayPickerForm'
import InputImage from 'components/InputImage'
import TimePicker from './TimePicker'
import InputArray from './InputArray'

class SingleForm extends React.Component {
  handleChange = (evt) => {
    if (!this.props.isDisabled) {
      const { onChange } = this.props
      const { type, value, checked, name } = evt.target
      if (type === 'checkbox') {
        onChange(checked)
      } else {
        onChange(value, name)
      }
    }
  }

  render() {
    const {
      value,
      defaultValue,
      id,
      name,
      type,
      label,
      placeholder,
      className,
      isCheckType,
      isDisabled,
      isDayPicker,
      isInputImage,
      isInputArray,
      isTimePicker,
      isHightLight,
      width,
      step,
      children,
      suffix,
      required,
      onChange,
      errorMessage,
      innerRef,
      ...props
    } = this.props

    return (
      <React.Fragment>
        <FormWrapper
          className={`d-flex align-items-end mb-2 flex-shrink-0 ${className}`}
          check={isCheckType}
          isHightLight={isHightLight}
          disabled={isDisabled}
          width={width}
          isError={!!errorMessage}
        >
          {label && (
            <Label
              className={`label-flex d-flex align-items-center ${
                isDisabled ? 'checkbox-disabled' : ''
              } ${isCheckType ? 'flex-auto' : 'mb-2'}`}
              for={id}
              check={isCheckType}
            >
              {isCheckType && (
                <Input
                  className="custom-checktype"
                  type="checkbox"
                  id={id}
                  onChange={this.handleChange}
                  defaultChecked={defaultValue}
                  checked={value}
                  name={name}
                  disabled={isDisabled}
                />
              )}
              <span className="checkmark" />
              {label} {required && <span className="color-red-med1 font-weight-bold">*</span>}
            </Label>
          )}
          {!isCheckType && (
            <div className="input-flex flex-column">
              <div className="d-flex w-100">
                {isDayPicker && (
                  <DayPickerForm onChange={onChange} value={value} isDisabled={isDisabled} />
                )}
                {isInputImage && (
                  <InputImage onChange={onChange} value={value} isDisabled={isDisabled} />
                )}
                {isTimePicker && (
                  <TimePicker
                    value={value}
                    onChange={onChange}
                    isDisabled={isDisabled}
                    isError={!!errorMessage}
                    {...props}
                  />
                )}
                {isInputArray && (
                  <InputArray
                    type={type}
                    name={name}
                    value={value}
                    onChange={this.props.onChange}
                    {...props}
                  />
                )}
                {!isDayPicker && !isInputImage && !isInputArray && !isTimePicker && (
                  <Input
                    className="ml-1"
                    type={type}
                    onChange={this.handleChange}
                    id={id}
                    step={step}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    min="0"
                    maxLength="200"
                    ref={innerRef}
                    {...props}
                  >
                    {children}
                  </Input>
                )}
                {suffix && <div className="w-100 font-md d-flex">{suffix}</div>}
              </div>
              {errorMessage && <ErrorText className="single-form__error">{errorMessage}</ErrorText>}
            </div>
          )}
        </FormWrapper>
      </React.Fragment>
    )
  }
}

SingleForm.displayName = 'Input'

SingleForm.defaultProps = {
  placeholder: null,
  type: 'text',
  isCheckType: false,
  isDayPicker: false,
  isDisabled: false,
  children: null,
  name: '',
}

export default SingleForm
