import DayPickerInput from 'containers/HomePage/components/DayPickerInput'
import React, { FC, useCallback, useEffect } from 'react'

import HeaderLayout from 'components/Layout/Header'
import * as S from '../style'
import { ParameterFilterDateType } from '../type'
import { compareValue, findWarehouse } from 'utils/commonFunctions'
import { useHistory } from 'react-router-dom'

type Props = {
  selectedDay: ParameterFilterDateType
  warehouseId: number
  tenantId: number
  warehouseList: any[]
  selectParameterFilterDate: (params: ParameterFilterDateType) => void
  changeWarehouseId: (id: number) => void
  changeTenantId: (id: number) => void
  resetAllTenantData: () => void
}

const Header: FC<Props> = ({
  selectedDay,
  warehouseList,
  warehouseId,
  tenantId,

  selectParameterFilterDate,
  changeWarehouseId,
  changeTenantId,
  resetAllTenantData,
}) => {
  const getWarehouseOption = useCallback(() => {
    return warehouseList.map((item) => ({
      value: item.id,
      label: item.name,
    }))
  }, [warehouseList])

  const getTenantOptions = useCallback(() => {
    const warehouseItem = findWarehouse(warehouseList, warehouseId)
    if (!warehouseItem) return []
    return warehouseItem.tenants.map((it: any) => ({
      value: it.id,
      label: it.name,
    }))
  }, [warehouseId, warehouseList])
  const history = useHistory()

  useEffect(() => {
    const warehouseOptions = getWarehouseOption()
    const tenantOptions = getTenantOptions()

    if (warehouseOptions.length) {
      const option = warehouseOptions.find((it: any) => compareValue(warehouseId, it.value))
      if (!option) changeWarehouseId(warehouseOptions[0].value)
    }
    if (tenantOptions.length) {
      const option = tenantOptions.find((it: any) => compareValue(tenantId, it.value))
      if (!option) {
        const tenantIdCurrent = tenantOptions[0]?.value
        changeTenantId(tenantIdCurrent)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, warehouseId])

  const handelSelectedDay = useCallback(
    (startDate: Date, endDate: Date) => {
      selectParameterFilterDate({
        startDate,
        endDate,
      })
    },
    [selectParameterFilterDate]
  )

  return (
    <HeaderLayout>
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex">
          <DayPickerInput
            name="startDate "
            value={selectedDay.startDate}
            onDayChange={(newValue: any) => {
              if (newValue > selectedDay.endDate) {
                handelSelectedDay(newValue, newValue)
              } else {
                handelSelectedDay(newValue, selectedDay.endDate)
              }
            }}
          />
          <S.CharacterDatePicker>~</S.CharacterDatePicker>
          <DayPickerInput
            name="endDate "
            value={selectedDay.endDate}
            onDayChange={(newValue: any) => {
              if (newValue < selectedDay.startDate) {
                handelSelectedDay(newValue, newValue)
              } else {
                handelSelectedDay(selectedDay.startDate, newValue)
              }
            }}
          />
          <S.SwitchButton outline color="primary" onClick={() => history.push('/')}>
            カレンダー画面へ
          </S.SwitchButton>
        </div>
        <div className="d-flex">
          <S.CustomSelectOption
            color="#132D49"
            className="ml-3"
            name="header-select-options"
            options={getWarehouseOption()}
            width="180px"
            skipAutoUpdate
            selectValue={warehouseId}
            handleUpdateData={(newId: number) => {
              if (newId) {
                changeWarehouseId(newId)
              }
            }}
          />
          <S.CustomSelectOption
            color="#132D49"
            className="ml-3"
            name="header-select-options"
            options={getTenantOptions()}
            width="180px"
            skipAutoUpdate
            selectValue={tenantId}
            handleUpdateData={(newId: number) => {
              if (newId) {
                changeTenantId(newId)
              }
            }}
          />
        </div>
      </div>
    </HeaderLayout>
  )
}

export default Header
