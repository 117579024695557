import { createActions, handleActions } from 'redux-actions'
import loginStorage from 'utils/loginStorage'

const initialState = {
  // 倉庫、テナントツリー
  warehouses: [],
  tenants: [],
  warehouseId: 0,
  tenantId: 0,

  // 受付入力項目
  baseParameters: [],
  parameters: [],

  // 比較用のデータ
  initialParameters: [],

  // 選択された受付入力項目
  selectedParameter: {
    id: 0,
    displayName: '',
    type: 0,
  },
  // 選択肢
  options: [],

  // 伝達事項
  confirmations: [],
  waitingSetting: {},
  initialWaitingSetting: {},
}

export const {
  selectParameter,
  changeWarehouse,
  changeTenant,
  updateParameters,
  updateParameter,
  updateOptions,
  fetchReceptionParameters,
  updateConfirmations,
  clearConfirmations,
  updateWaitingSetting,
  updateInitialWaitingSetting,
} = createActions({
  SELECT_PARAMETER: (data) => data,
  CHANGE_WAREHOUSE: (data) => data,
  CHANGE_TENANT: (data) => data,
  UPDATE_PARAMETERS: (data) => data,
  UPDATE_PARAMETER: (data) => data,
  UPDATE_OPTIONS: (data) => data,
  FETCH_RECEPTION_PARAMETERS: (data) => data,
  UPDATE_CONFIRMATIONS: (data) => data,
  CLEAR_CONFIRMATIONS: (data) => data,
  UPDATE_WAITING_SETTING: (data) => data,
  UPDATE_INITIAL_WAITING_SETTING: (data) => data,
})

export default handleActions(
  {
    FETCH_SUCCESS: (state, { payload: { storePath, data } }) => ({ ...state, [storePath]: data }),
    SELECT_PARAMETER: (state, { payload }) => {
      const selectedParameter = payload
      return { ...state, selectedParameter }
    },
    CHANGE_WAREHOUSE: (state, { payload }) => {
      const { tenantId } = loginStorage.getWarehouseSession()
      const warehouseId = payload
      loginStorage.setWarehouseSession({ warehouseId, tenantId })
      return { ...state, warehouseId }
    },
    CHANGE_TENANT: (state, { payload }) => {
      const { warehouseId } = loginStorage.getWarehouseSession()
      const tenantId = payload
      loginStorage.setWarehouseSession({ warehouseId, tenantId })
      return { ...state, tenantId }
    },
    UPDATE_PARAMETERS: (state, { payload }) => {
      const parameters = [...payload]
      return { ...state, parameters }
    },
    UPDATE_PARAMETER: (state, { payload }) => {
      const parameter = payload
      return { ...state, selectedParameter: { ...state.selectedParameter, ...parameter } }
    },
    UPDATE_OPTIONS: (state, { payload }) => {
      const options = [...payload]
      return { ...state, options }
    },
    UPDATE_CONFIRMATIONS: (state, { payload }) => {
      const confirmations = [...payload]
      return { ...state, confirmations }
    },
    CLEAR_CONFIRMATIONS: (state) => {
      return { ...state, confirmations: [] }
    },
    UPDATE_WAITING_SETTING: (state, { payload }) => {
      const waitingSetting = { ...state.waitingSetting, ...payload }
      return { ...state, waitingSetting }
    },
    UPDATE_INITIAL_WAITING_SETTING: (state, { payload }) => {
      const initialWaitingSetting = { ...state.initialWaitingSetting, ...payload }
      return { ...state, initialWaitingSetting }
    },
  },
  initialState
)
