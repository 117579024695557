import styled from 'styled-components'
import theme from 'vars/theme'

const CommonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 2px;
  height: calc((var(--vh, 1vh) * 100) - ${theme.headerHeight} - 100px);

  .main-wrapper {
    width: 70%;
    max-width: 1200px;
    min-width: 800px;
  }

  .label-flex {
    flex: 0 0 160px;
  }

  .custom-form-wrapper {
    > div {
      flex: 1 1 150px;

      .input-flex {
        flex: 1 1 120px;
      }
    }
  }
  .opacity-0 {
    opacity: 0;
    visibility: hidden;
  }
`

const ButtonGroup = styled.div`
  width: 100%;
  background: ${theme.graySoft1};
  box-shadow: 0px -1px 0px ${theme.graySoft2};
  padding-top: 12px;
  padding-bottom: 12px;
  min-width: 100%;
  display: flex;
  justify-content: center;

  .main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 920px;
    min-width: 820px;
  }
`

// Wrapper used in BerthMasterPage and HomePage
const MainWrapper = styled.div`
  flex: 1 1 ${theme.minWidth};
  min-width: 100%;
  height: 100%;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .aside {
    flex: 0 0 ${theme.sidebarWidth};
    max-width: ${theme.sidebarWidth};
    height: calc((var(--vh, 1vh) * 100) - ${theme.headerHeight});
    position: relative;

    &--booking-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 34px;
      background-color: #fafafa;
      font-size: 13px;
      color: ${theme.darkBlueMedium1};
    }
  }

  & > div {
    flex-grow: 1;
    max-width: calc(100vw - ${theme.sidebarWidth});
  }

  .main {
    flex: 1 0 calc(${theme.minWidth} - ${theme.sidebarWidth});
    min-width: calc(${theme.minWidth} - ${theme.sidebarWidth});
    height: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .main-min-w {
    min-width: calc(${theme.minWidth} - ${theme.sidebarWidth});
  }
`

const ErrorWrapper = styled.div`
  animation: bounce 0.5s;
  transform-origin: center bottom;
  color: ${theme.redMedium1};
  text-align: center;
  margin: 20px 0;

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(-20px, 0, 0);
    }

    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(15px, 0, 0);
    }

    90% {
      transform: translate3d(8px, 0, 0);
    }
  }
`

export { CommonWrapper, ButtonGroup, MainWrapper, ErrorWrapper }
