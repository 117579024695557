import { takeLatest, select, put, call } from 'redux-saga/effects'
import { camelizeKeys, camelize, decamelizeKeys } from 'humps'

import { requestGet } from './api'
import { getParameterValue } from 'services/Booking'

function* fetchImportExportParams({ payload }) {
  const tenantId = Number(payload)
  const { booking, master } = yield select((state) => state.get('reception'))

  let resp

  yield put({ type: 'base/START_LOADING' })

  try {
    resp = yield call(requestGet, '/tablets/import_export_params', {
      tenantId,
      driverId: booking.driverId,
    })
  } catch (error) {
    yield put({ type: 'OPEN_ERROR', payload: { message: error.message } })
    return
  } finally {
    yield put({ type: 'base/STOP_LOADING' })
  }

  const importExportParams = camelizeKeys(resp.data)

  const newBooking = importExportParams.import.concat(importExportParams.export).reduce(
    (ac, item) => ({
      ...ac,
      [camelize(item.columnName)]: getParameterValue(
        decamelizeKeys(item),
        { [item.columnName]: item.value },
        decamelizeKeys(master),
        true,
        Boolean(booking.bookingNo)
      ),
    }),
    {}
  )

  yield put({ type: 'UPDATE_BOOKING', payload: newBooking })
}

export default function* () {
  yield takeLatest('FETCH_IMPORT_EXPORT_PARAMS', fetchImportExportParams)
}
