import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { compose } from 'redux'

import MultiTenantsPage from './pages/MultiTenantsPage'
import EntryPage from './pages/EntryPage'
import EntryTelNumberPage from './pages/EntryTelNumberPage'
import EntryPasscodePage from './pages/EntryPasscodePage'
import TermsOfServicePage from './pages/TermsOfServicePage'
import EntryDriverInfoPage from './pages/EntryDriverInfoPage'
import EntryBookingsPage from './pages/EntryBookingsPage'
import EntryBookingNumberPage from './pages/EntryBookingNumberPage'
import EntryLoadPage from './pages/EntryLoadPage'
import EntryConfirmationPage from './pages/EntryConfirmationPage'
import EntryFinishPage from './pages/EntryFinishPage'
import LoginPage from './pages/LoginPage'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import reducers from './reducers'
import { CHECKIN_USER_DATA } from 'utils/loginStorage'
import { decryptFunc } from 'utils/commonFunctions'
import Storage from './Storage'
import saga from './saga'

const isSessionLogin = () => {
  const { pathname } = global.location
  const nodes = pathname.split('/')
  const tenantId = nodes[nodes.length - 1]

  if (!pathname.startsWith('/mtenants') && !Number.isNaN(Number(tenantId))) {
    Storage.write('tenantId', tenantId)
  }

  const dataSessionList = decryptFunc(CHECKIN_USER_DATA) || []
  return dataSessionList.some((session) => session.uid === Storage.read('tenantId'))
}

const ReceptionRouting = () => {
  return isSessionLogin() ? (
    <Switch>
      <Route path="/mtenants/:multiTenantId" component={MultiTenantsPage} />

      <Route exact path="/receipt-booking/:warehouseId/:tenantId" component={EntryPage} />
      <Route
        path="/receipt-booking/terms-of-service/:warehouseId/:tenantId"
        component={TermsOfServicePage}
      />
      <Route
        path="/receipt-booking/entry-tel-number/:warehouseId/:tenantId"
        component={EntryTelNumberPage}
      />
      <Route
        path="/receipt-booking/entry-passcode/:warehouseId/:tenantId"
        component={EntryPasscodePage}
      />
      <Route
        path="/receipt-booking/entry-driver-info/:warehouseId/:tenantId"
        component={EntryDriverInfoPage}
      />
      <Route
        path="/receipt-booking/entry-bookings/:warehouseId/:tenantId"
        component={EntryBookingsPage}
      />
      <Route
        path="/receipt-booking/entry-booking-number/:warehouseId/:tenantId"
        component={EntryBookingNumberPage}
      />
      <Route path="/receipt-booking/entry-load/:warehouseId/:tenantId" component={EntryLoadPage} />
      <Route
        path="/receipt-booking/entry-confirmation/:warehouseId/:tenantId"
        component={EntryConfirmationPage}
      />
      <Route
        path="/receipt-booking/entry-finish/:warehouseId/:tenantId"
        component={EntryFinishPage}
      />
    </Switch>
  ) : (
    <Switch>
      <Route path="/mtenants/:multiTenantId" component={MultiTenantsPage} />
      <Route path="/receipt-booking/login" component={LoginPage} />
      <Redirect
        to={{ pathname: '/receipt-booking/login', state: { referrer: document.location.pathname } }}
      />
    </Switch>
  )
}

const withReducer = injectReducer({ key: 'reception', reducer: reducers })
const withSaga = injectSaga({ key: 'reception', saga })

export default compose(withReducer, withSaga)(ReceptionRouting)
