import RestClient, { getAuthHeader } from './RestClient'

const BERTH_SIGN_IN_PATH = 'berths/auth/sign_in'

export default class AuthService {
  constructor(restService = new RestClient()) {
    this.restService = restService
  }

  login(params) {
    return this.restService.post({
      url: BERTH_SIGN_IN_PATH,
      data: params,
    })
  }

  logout() {
    return this.restService.delete({
      url: 'berths/auth/sign_out',
      params: null,
      data: null,
      config: {
        headers: getAuthHeader(),
      },
    })
  }

  registerPassword(data) {
    return this.restService.post({
      url: 'berths/auth/password',
      data,
    })
  }

  checkToken(params) {
    return this.restService.get('berths/auth/password/edit', params)
  }

  settingPassword(params) {
    return this.restService.put({
      url: 'berths/auth/password',
      data: params.data || null,
      config: {
        headers: params.headers || {},
      },
    })
  }

  updateUserPolicy = (params) => {
    return this.restService.put({
      url: `user/policy/${params.id}`,
      config: {
        headers: {
          'access-token': params.accessToken
        },
      }
    })
  }
}
