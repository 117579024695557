import React, { memo, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'

import { useDispatch } from 'reduxStoreHelper'
import { fetchDriverInfo, fetchSuccess } from '../reducer'
import {
  MAX_LENGTH_LEFT_INPUT,
  MAX_LENGTH_CENTER_INPUT,
  MAX_LENGTH_RIGHT_INPUT,
  INTEGRATED_RECEPTION_URL_BASE,
} from '../constants'
import Header from '../components/Header'
import InputNumberModal from '../components/InputNumberModal'
import { getGeneralConfirmations } from '../api'
import { startLoading, stopLoading, openError } from 'containers/App/reducers'
import * as S from '../style'

type Props = {
  generalReceptionId?: string
}

const EntryTelNumberPage: React.FC<Props> = ({ generalReceptionId }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { generalReceptionNumber }: any = useParams()

  useEffect(() => {
    ;(async () => {
      dispatch(startLoading())
      try {
        const data = await getGeneralConfirmations(generalReceptionId!)
        const driverConfirmationDetail = _.get(data, 'data', [])

        dispatch(
          fetchSuccess({
            key: 'driverConfirmations',
            data: driverConfirmationDetail,
          })
        )
      } catch (err) {
        dispatch(openError(err.message, false))
      } finally {
        dispatch(stopLoading())
      }
    })()
  }, [dispatch, generalReceptionId])

  const onClickEnter = useCallback(
    (phoneNumber: string) => {
      dispatch(fetchDriverInfo(generalReceptionId, phoneNumber))
      history.push(`${INTEGRATED_RECEPTION_URL_BASE}/entry-passcode/${generalReceptionNumber}`)
    },
    [dispatch, generalReceptionId, generalReceptionNumber, history]
  )

  const onClickClose = useCallback(() => {
    history.push(`${INTEGRATED_RECEPTION_URL_BASE}/${generalReceptionNumber}`)
  }, [generalReceptionNumber, history])

  return (
    <S.Wrapper>
      <Header />
      <InputNumberModal
        isOpen
        title="電話番号を入力"
        maxLengthLeft={MAX_LENGTH_LEFT_INPUT}
        maxLengthCenter={MAX_LENGTH_CENTER_INPUT}
        maxLengthRight={MAX_LENGTH_RIGHT_INPUT}
        onClickEnter={onClickEnter}
        onClickClose={onClickClose}
        isShowPhoneNumberPrefix
      />
    </S.Wrapper>
  )
}

export default memo(EntryTelNumberPage)
