import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { TIME_AUTO_REDIRECT_CHECKIN } from 'constants/misc'
import Header from '../components/Header'
import PrevStartButton from '../components/PrevStartButton'
import SessionStorage from '../SessionStorage'

import SMSIcon from 'assets/svgs/sms.svg'
import lineApp from 'assets/pngs/line-app.png'
import Truckbook from 'assets/svgs/monoful-logo-background-white.svg'
import theme from 'vars/theme'

export const Wrapper = styled.div`
  background-color: ${theme.graySoft6};
  height: calc(var(--vh, 1vh) * 100);
  font-size: 18px;
  overflow: auto;
`

export const IconWrapper = styled.div`
  ${(props) =>
    props.sms
      ? ` width: 120px;
          height: 120px;
          margin: 24px 0 0;
        `
      : `
          width: 180px;
          height: 180px;
          margin: 24px 0;
  `}
`

const LogoImage = styled.img`
  width: 100%;
  height: 100%;
`

const NotificationContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Text = styled.p`
  font-size: 28px;

  ${(props) =>
    props.sms &&
    `
      line-height: 1.2;
      font-size: 62px;
      font-weight: bold
  `}
`

export const TextNoteMessage = styled.p`
  font-size: 28px;
  padding: 12px;
  margin-top: 4px;
  background: ${theme.redPrimary1};
  color: ${theme.whitePrimary};
  font-weight: 500;
`

const PortalIconWrapper = styled.div`
  width: 180px;
  height: 180px;
  margin: 24px 0;
  padding: 30px;
  border-radius: 32px;
  box-shadow: 0px 0px 9px 4px ${theme.graySoft17};
`
const DefaultTextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 250px;
`

class EntryFinishPage extends Component {
  timerId = 0

  componentDidMount() {
    const { match, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match

    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
      return
    }

    this.timerId = setTimeout(() => {
      const multiTenantId = SessionStorage.read('multiTenantId')
      const url = multiTenantId
        ? `/mtenants/${multiTenantId}`
        : `/receipt-booking/${warehouseId}/${tenantId}`
      this.props.history.push(url)
    }, TIME_AUTO_REDIRECT_CHECKIN)
  }

  componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = 0
    }
  }

  renderNotification = () => {
    const { driver, location } = this.props
    const driverCanSMS = driver?.tenant?.canSms
    const { lineFlag, portalFlag, isCheckedPhone } = location?.state?.methodNotification
    const smsFlag = driverCanSMS && !lineFlag && !portalFlag

    if (smsFlag) {
      return (
        <>
          <Text>
            入場準備が出来次第、<b>SMS</b>でご案内します。
          </Text>
          {!isCheckedPhone && (
            <TextNoteMessage>次回受付も、同じパスコードを使用します</TextNoteMessage>
          )}
          <IconWrapper sms>
            <LogoImage src={SMSIcon} alt="" />
          </IconWrapper>
          <Text sms>SMS</Text>
        </>
      )
    }

    if (lineFlag) {
      return (
        <>
          <Text>
            入場準備が出来次第、<b>LINE</b>でご案内します。
          </Text>
          {!isCheckedPhone && (
            <TextNoteMessage>次回受付も、同じパスコードを使用します</TextNoteMessage>
          )}
          <IconWrapper>
            <LogoImage src={lineApp} alt="" />
          </IconWrapper>
        </>
      )
    }

    if (portalFlag) {
      return (
        <>
          <Text>入場準備が出来次第、モノフルアプリでご案内します。</Text>
          {!isCheckedPhone && (
            <TextNoteMessage>次回受付も、同じパスコードを使用します</TextNoteMessage>
          )}
          <PortalIconWrapper>
            <LogoImage src={Truckbook} alt="" />
          </PortalIconWrapper>
          <h2 className="text-size32 mv12">モノフル</h2>
        </>
      )
    }
  }

  render() {
    const { driver, match, location } = this.props
    const driverCanSMS = driver?.tenant?.canSms
    const canUnmannedCheckin = location?.state?.canUnmannedCheckin
    const { isCheckedPhone } = location?.state?.methodNotification
    const {
      params: { warehouseId, tenantId },
    } = match
    const multiTenantId = SessionStorage.read('multiTenantId')

    return (
      <Wrapper>
        <Header driver={driver} />
        <div style={{ margin: 32 }}>
          <div className="bg-white p-5 mb-3" style={{ minHeight: 350, borderRadius: 12 }}>
            <div className="text-center">
              <div className="mb-2 font-weight-bold" style={{ fontSize: 48 }}>
                入場受付完了
              </div>
              {!driverCanSMS ? (
                <DefaultTextContent>
                  <div style={{ fontSize: 28 }}>
                    <Text>
                      {canUnmannedCheckin
                        ? '作業完了後、退場受付をお願いいたします。'
                        : '入場準備が出来次第、別途ご案内します。'}
                    </Text>

                    {!isCheckedPhone && (
                      <TextNoteMessage>次回受付も、同じパスコードを使用します</TextNoteMessage>
                    )}
                  </div>
                </DefaultTextContent>
              ) : (
                <NotificationContent>{this.renderNotification()}</NotificationContent>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div style={{ width: '33%' }}>
              <PrevStartButton
                to={
                  multiTenantId
                    ? `/mtenants/${multiTenantId}`
                    : `/receipt-booking/${warehouseId}/${tenantId}`
                }
              />
            </div>
            <div style={{ width: '34%' }} />
            <div className="text-right" style={{ width: '33%' }} />
          </div>
        </div>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
    returnEntryPage: reception.returnEntryPage,
  }
}

export default connect(mapStateToProps, null)(EntryFinishPage)
