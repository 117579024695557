const errorMessages = {
  Receipt: {
    blank: () => '携帯電話番号を入力してください。',
    invalidNumber: () => '電話番号はハイフンを入れず、10〜11桁の数字のみで入力してください。',
    invalidRange: () => '電話番号はハイフンを入れず、10〜11桁の数字のみで入力してください。',
    invalidFormatPhone: () => '電話番号はハイフンを入れず、10〜11桁の数字のみで入力してください。',
    cannotExit: () => 'この予約を終了できません。',
    bookingNotExist: () => '予約はありません。',
    invalidField: (field) => `${field}は違いました。`,
    requirePhone: () => '携帯電話番号を入力してください。',
    pleaseEnterVehicleNumber: () => '車番を入力してください',
    destinationBlank: () => '行き先 を入力してください。',
    bookingNumberBlank: () => '該当する受付番号が見つかりませんでした。',
    invalidVehicleNumber: () => '車番は違いました。車番は4桁の数字で入力してください。',
    enterDriver: () => 'ドライバー氏名を入力してください。',
    bookingNumberInvalidFormat: () =>
      '受付番号は違いました。受付番号は英数字8桁で入力してください。',
    bookingNumberNoData: () => '該当する受付番号が見つかりませんでした。',
    exitError: () => '受付番号は違いました。',
    bookingNoNotFound: () => '予約が見つかりませんでした。',
    driverPhoneAndBookingNoNotMatch: () => '電話番号に該当する予約番号が見つかりません。',
    bookingNoInvalid: () => '予約番号が見つかりません。',
    pleaseEnterDriverPhone: () => '電話番号を入力してください。',
    pleaseEnterBookingNo: () => '予約番号を入力してください。',
    pleaseEnterPassword: () => 'パスワードを入力してください。',
    passwordIncorrect: () => 'パスワードが正しくありません。',
    passwordSystemError: () => 'システムエラーが発生しました。もう一度ログインしてください。',
    verifyCodeError: () => '番号が違います。再度ご確認ください。',
    bookingNoReceived: () => 'この予約番号は既に受付完了しました。',
    phoneNumberInCorrect: () => '予約のドライバー電話番号が正しく有りません。',
    berthFreeTimeError: () =>
      'エラーが発生しましたため、データを保存できませんでした。再度お試しください。',
    bookingNotFound: () => '予約が見つかりませんでした',
  },
  PhoneBooking: {
    incorrectTenant: () => 'テナント番号は正しくありません。',
    emptyTenantNumber: () => 'テナント番号を入力してください。',
    duplicateTenant: () => '入力された倉庫IDの拠点はすでに<br />下記リストに登録済みです',
    phoneCheckedMessage: () => 'パスコードを入力してください',
    phoneUnCheckedMessage: () => 'パスコードを忘れた方はこちら',
  },
  HomePage: {
    validateBooking: (startMoment, endMoment) =>
      `このバースには ${startMoment} から ${endMoment} まで既に予約があります。`,
    validateBookingDriverController: () => '指定予約枠内で入力してください',
    fileTooBig: () => '添付ファイルは10MB以下にしてください。',
    disableBookingPeriod: () => '現在このバースは使用できません。',
    repeatDayBooking: () => '日次繰り返し予約は一日中にしなくてはいけない。',
    repeatWeekBooking: () => '繰り返し予約は7日間以下にしてください。',
    repeatMonthBooking: () => '繰返し予約は29日間以下にしてください。',
    berthBlank: () => 'バースを入力してください。',
    forceFinishError: () =>
      '該当バースには、[予約調整中｜予約確定｜遅延｜遅延見込]のスケジュールがあるため、呼び出せません。現在あるスケジュールを調整してください。',
    startTimeBlank: () => '開始時間を入力してください。',
    endTimeBlank: () => '終了時間を入力してください。',
    companyBlank: () => '会社名を入力してください。',
    phoneInvalid: () => '電話番号は違いました。電話番号は10～11桁の数字で入力してください。',
    startTimeBigger: () => '開始時間は終了時間の前に設定してください。',
    startDateBigger: () => '開始日は終了日の前に設定してください。',
    managerBlank: () => '配車担当を入力してください。',
    controllerExit: (data) =>
      `ご入力頂いたご担当者様のメールアドレス「${data.vehicle_controller_email}」は既に登録済みです。<br />以下の内容での予約情報を更新しますか？<br />会社名：${data.company_name}<br />配車担当者名：${data.vehicle_controller_name}`,
    driverExit: (data) =>
      `ご入力頂いたご担当者様の電話番号「${data.driver_phone}」は既に登録済みです。<br />以下の内容での予約情報を更新しますか<br />ドライバー名：${data.driver_name}`,
    driverNameNull: (data) =>
      `ご入力頂いたご担当者様の電話番号「${data.driver_phone}」は既に登録済みですが、ドライバー名はまだ登録されていません。<br />ドライバー名を入力してください。</p>`,
    controllerBlank: () => '配車担当を入力してください',
    controllerEmailBlank: () => 'メールアドレスを入力してください。',
    emailInvalid: () => 'メールアドレスは違いました。',
    emailExist: () => 'メールアドレスはすでに存在しています。',
    deadLineBlank: () => '終了日を入力してください。',
    dayOfWeekBlank: () => '曜日が選択されていないため、予約を作成できません。',
    delegationOwnCompany: () => 'あなたの会社に委任することはできません',
    repeatInPast: () =>
      '過去に対して繰り返し予約を作成することはできません。開始日を修正してください。',
    cellPhoneInvalid: () =>
      '携帯電話番号は違いました。携帯電話番号は11桁の数字で入力してください。',
    cellPhoneStartWithInvalid: () =>
      '不正な携帯電話番号です。0から始まる携帯電話番号を入力してください。',
    phoneBlank: () => '電話番号を入力してください。',
    planStartDateRequired: () => '開始日を入力してください。',
    planEndDateRequired: () => '終了日を入力してください。',
    driverNameBlank: () => 'ドライバー名を入力してください。',
  },
  ConfirmBooking: {
    invalidPhone: (value) => `${value}は違いました。${value}は10～13桁の数字で入力してください。`,
    invalidEmail: (value) => `${value}は違いました。`,
    invalidCarNumber: (value) => `${value}は違いました。`,
    numberValidate: () => '数字で入力ください。',
  },
  Warehouse: {
    urlInvalid: () => 'このURLは正しくありません',
    postalCodeInvalid: () => '該当する郵便番号が見つかりませんでした。',
    endDateInvalid: () => '開始日より後にしてください。',
    endTimeInvalid: () => '開始時間後にしてください。 ',
    dateBlank: () => '両方の日付を記入してください。 ',
    imageTypeInvalid: () => '写真はpdf、png、jpeg、gif形式ではなくてはいけない。',
    nameAlreadyExist: () => 'テナントはすでに存在しています。',
    controllerExist: (data) =>
      `ご入力頂いたご担当者様のメールアドレス「${data.email}」は既に登録済みです。<br />以下の内容でのユーザーをテナントに追加しますか？<br />会社名：${data.company_name}<br />予約者（限定）：${data.name}`,
    fullopenExist: (data) =>
      `ご入力頂いたご担当者様のメールアドレス「${data.email}」は既に登録済みです。<br />以下の内容でのユーザーをテナントに追加しますか？<br />会社名：${data.company_name}<br />予約者（フル）：${data.name}`,
  },
  GeneralReception: {
    nameBlank: () => '総合受付名を入力してください。',
    passwordBlank: () => 'チェクインパスワードを入力してください。',
    passwordShort: () => 'チェクインパスワードは6桁から入力してください。',
    parentTenantIdBlank: () => '親テナントを選択してください。',
    checkinEnableDistanceInvalid: () => 'スマホ受付範囲は0以上から入力してください。',
    latitudeInvalid: () => 'スマホ受付経度は正しくありません。',
    longitudeInvalid: () => 'スマホ受付緯度は正しくありません。',
    generalReceptionInvalid: () => '総合受付の権限を持っていません。',
    contactPhoneInvalid: () => '電話番号は0から始まり、10桁or11桁で入力してください',
    controllerNameBlank: () => '名前を入力してください。',
    controllerPhoneInvalid: () => '電話番号は0から始まり、10桁or11桁で入力してください。',
  },
  Unauthorized: () => 'アクセス権限がありません。',
  SettingTimeWaitingBooking: {
    inputMinGetterThanMax: () => `最小待ち時間が最大待ち時間を上回っています。`,
    inputNotMultipleOf5To360: () => `待ち時間は5分以上、360分以内、かつ5分間隔で入力してください。`,
  },
}

export default errorMessages
