import { Howl } from 'howler'

import { ReceptionNotificationSoundTones } from 'constants/Settings'

const DEFAULT_VOLUME = 0.5

const srcs: any = {
  Bell: require('../assets/sounds/Bell.mp3'),
  Harmonics: require('../assets/sounds/Harmonics.mp3'),
  Ring: require('../assets/sounds/Ring.mp3'),
}

const players: any = ReceptionNotificationSoundTones.reduce((ac, tone) => {
  return { ...ac, [tone.id]: new Howl({ src: srcs[tone.id], volume: DEFAULT_VOLUME }) }
}, {})

export const playSound = (id: string, volume: number) => {
  players[id].volume(volume)
  players[id].play()
}
