import styled, { css } from 'styled-components'
import { Button } from 'reactstrap'

import theme from 'vars/theme'
import { ReactComponent as CloseIconSVG } from 'assets/svgs/close-icon-gray.svg'
import { ReactComponent as SearchIconSVG } from 'assets/svgs/search.svg'

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 3px;
  flex: 1;
`

export const SearchIcon = styled(SearchIconSVG)`
  cursor: pointer;
  position: absolute;
  left: 6px;
  width: 24px;
  height: 24px;
  border: none;
`

export const CloseIcon = styled(CloseIconSVG)`
  cursor: pointer;
  position: absolute;
  right: 8px;
  width: 14px;
  height: 14px;

  path {
    fill: ${theme.blackDark};
  }
`

export const Input = styled.input`
  border: 1px solid ${theme.graySoft2};
  border-radius: 3px;
  font-size: 13px;
  height: 34px;
  padding: 0px 25px 0px 35px;
  width: 100%;

  &::placeholder {
    font-size: 14px;
    color: ${theme.darkBlueMedium2};
    font-weight: normal;
    opacity: 0.5;
  }

  &:focus,
  &:active {
    border: 1px solid ${theme.bluePrimary};
    outline: none;
    box-shadow: 0 0 0 1px ${theme.bluePrimary};
  }
`

export const Content = styled.div`
  width: 100%;
  transition: width 0.1s ease-in;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => `${width}px`};
  height: 34px;

  ${({ isActive, activeWidth, isSmallPlaceholderActive }) =>
    isActive &&
    css`
      ${Content} {
        position: absolute;
        top: 0;
        left: 0;
        width: ${`${activeWidth}px`};
        padding: 10px;
        background-color: ${theme.whitePrimary};
        z-index: 10;
        border: 1px solid ${theme.blackDark};
        border-radius: 5px;
        box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.1);

        .btn-search,
        .input-search {
          font-size: 22px;
          height: 42px;
        }

        .btn-search {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .input-search::placeholder {
          font-size: ${isSmallPlaceholderActive ? '20px' : '22px'};
        }

        .icon-search {
          width: 30px;
          height: 30px;
          left: 5px;
        }
      }
    `}
`

export const SearchButton = styled(Button)`
  color: ${theme.whitePrimary};
  background: ${theme.blueMedium7};
  border-radius: 8px;
  font-size: 13px;
  margin-left: 10px;

  &:hover {
    background: ${theme.blueMedium7};
    color: ${theme.whitePrimary};
  }
`

export const HistoryTitle = styled.p`
  margin: 10px 0;
  font-size: 16px;
`

export const ListHistory = styled.ul`
  list-style: none;
  padding: 0;
`

export const HistoryItem = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${theme.grayDark};
  font-size: 18px;

  &:hover {
    background-color: ${theme.blueSoft8};
  }

  ${SearchIcon} {
    position: unset;
    transform: translateY(1px);
    margin-right: 10px;
  }
`
