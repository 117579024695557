import React from 'react'

import { connect } from 'react-redux'
import _ from 'lodash'

import { updatedBooking, receiveMultiBooking } from '../actions'
import {
  createUnassignBookingSuccess,
  updateBookingSuccess,
  updateBookingUnassignedSuccess,
  deleteBookingItem,
  deleteBookingRepeat,
  fetchBookingDataAfterUploadCSV,
  updateTenantSetting,
  deleteBookingItems,
  deleteYesterdayBookingRepeat,
  deleteYesterdayBooking,
  updateYesterdayBookingSuccess,
  deleteYesterdayBookingItems,
  fetchYesterdayBookingList,
} from 'containers/HomePage/actions'

import { ActionCableConsumer } from 'react-actioncable-provider'
import QuickNotification from './components/QuickNotification'
import { NOTI_TYPE } from './constants'
import { statusValues } from 'constants/misc'
import { playSound } from 'utils/SoundPlayer'
import { compareValue } from 'utils/commonFunctions'

class NotificationContainers extends React.PureComponent {
  state = {
    isOpen: false,
    notificationData: null,
  }

  onRequestClose = () => {
    this.setState({
      isOpen: false,
      notificationData: null,
    })
  }

  handleOnReceived = ({ notification }) => {
    clearTimeout(this.state.timing)
    this.setState(
      {
        isOpen: true,
        notificationData: notification,
        timing: setTimeout(() => {
          this.setState({
            isOpen: false,
            notificationData: null,
          })
        }, 5000),
      },
      () => {}
    )
  }

  handleOnBookingReceived = ({ type, data }) => {
    this.props.updatedBooking()

    switch (type) {
      case NOTI_TYPE.UPLOAD_CSV_BERTH:
      case NOTI_TYPE.PUBLIC_API_BOOKING:
        const { tenant_id: tenantCsvId, warehouse_id: warehouseCsvId } = data
        const { warehouseId, tenantId } = this.props
        const { pathname } = window.location

        if (pathname === '/' && warehouseId === warehouseCsvId && tenantId === tenantCsvId) {
          this.props.fetchBookingDataAfterUploadCSV()
          // call new yesterday
          this.props.fetchYesterdayBookingList()
        }
        break
      case NOTI_TYPE.CREATE_RECEPT:
      case NOTI_TYPE.CREATE_UNASSIGN:
        this.props.createReceptBooking(data)
        break
      case NOTI_TYPE.UPDATE_UNASSIGN:
        this.props.updateBookingUnassignedSuccess(data)
        break
      case NOTI_TYPE.DELETE_BOOKING:
        if (data.repeat_id) {
          this.props.deleteBookingRepeat(data)
          this.props.deleteYesterdayBookingRepeat(data)
        } else {
          this.props.deleteBookingItem(data)
          this.props.deleteYesterdayBooking(data)
        }
        break
      case NOTI_TYPE.DELETE_BOOKINGS:
        const { booking_ids: bookingIds, tenant_id: tenantIdDeleteBooking } = data || {}
        const { tenantId: tenantIdCurrent, warehouseId: warehouseIdCurrent } = this.props
        const { pathname: pathnameCurrent } = window.location

        if (pathnameCurrent === '/' && compareValue(tenantIdCurrent, tenantIdDeleteBooking)) {
          this.props.deleteBookingItems({
            bookingIds,
            tenant_id: tenantIdCurrent,
            warehouse_id: warehouseIdCurrent,
          })
          this.props.deleteYesterdayBookingItems({
            bookingIds,
            tenant_id: tenantIdCurrent,
            warehouse_id: warehouseIdCurrent,
          })
        }
        break
      default:
        if ([statusValues.Unassigned, statusValues.Recept].includes(data.status)) {
          this.props.updateBookingUnassignedSuccess(data)
        } else {
          this.props.updateBookingSuccess(data)
          this.props.updateYesterdayBookingSuccess(data)
        }
        break
    }
  }

  handleOnMultiBookingReceived = ({ type, data, notification }) => {
    const { pathname } = window.location

    if (notification) {
      this.handleOnReceived({ notification })
      return
    }

    if (type === NOTI_TYPE.UPLOAD_CSV_GENERAL && pathname === '/') {
      this.props.fetchBookingDataAfterUploadCSV()

      return
    }

    // 変更があった multi_booking レコードを受け取って置き換える
    this.props.receiveMultiBooking({ type, data })
  }

  handleOnReceptionReceived = () => {
    const { receptionNotificationSound } = this.props
    playSound(receptionNotificationSound.toneId, receptionNotificationSound.volume)
  }

  handleUpdateSettingTenant = (payload) => {
    const { pathname } = window.location

    if (pathname === '/') {
      this.props.updateTenantSetting(payload)
      return
    }
  }

  renderNotification = () => {
    const { isOpen, notificationData } = this.state
    return (
      notificationData && (
        <QuickNotification
          isOpen={isOpen}
          data={notificationData}
          onRequestClose={this.onRequestClose}
        />
      )
    )
  }

  render() {
    const {
      userData: { id },
    } = this.props
    return (
      <React.Fragment>
        <ActionCableConsumer
          channel={{ channel: 'NotificationsChannel', user: id }}
          onReceived={this.handleOnReceived}
        >
          {this.renderNotification()}
        </ActionCableConsumer>
        <ActionCableConsumer
          channel={{ channel: 'TenantSettingChannel', user: id }}
          onReceived={this.handleUpdateSettingTenant}
        />
        <ActionCableConsumer
          channel={{ channel: 'BookingsChannel', user: id }}
          onReceived={this.handleOnBookingReceived}
        />
        <ActionCableConsumer
          channel={{ channel: 'GeneralBookingChannel', user: id }}
          onReceived={this.handleOnMultiBookingReceived}
        >
          {this.renderNotification()}
        </ActionCableConsumer>

        <ActionCableConsumer
          channel={{ channel: 'ReceptionChannel', user: id }}
          onReceived={this.handleOnReceptionReceived}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const receptionNotificationSound = state.get('containers/App').get('receptionNotificationSound')
  const homePageState = state.get('containers/home-page')
  const bookings = homePageState ? homePageState.get('bookingList').toJSON() : []
  const neutralBookings = homePageState ? homePageState.get('neutralBookingList').toJSON() : []
  const tenantId = homePageState ? homePageState.get('tenantId') : 0
  const warehouseId = homePageState ? homePageState.get('warehouseId') : 0

  return {
    receptionNotificationSound: _.isString(receptionNotificationSound)
      ? JSON.parse(receptionNotificationSound)
      : receptionNotificationSound.toJSON(),
    bookings,
    neutralBookings,
    tenantId,
    warehouseId,
  }
}

const matchDispatchToProp = (dispatch) => ({
  createReceptBooking: (data) => dispatch(createUnassignBookingSuccess(data)),
  updateBookingSuccess: (data) => dispatch(updateBookingSuccess(data)),
  updateBookingUnassignedSuccess: (data) => dispatch(updateBookingUnassignedSuccess(data)),
  deleteBookingItem: (data) => dispatch(deleteBookingItem(data)),
  deleteBookingItems: (data) => dispatch(deleteBookingItems(data)),
  deleteBookingRepeat: (data) => dispatch(deleteBookingRepeat(data)),
  updatedBooking: () => dispatch(updatedBooking()),
  receiveMultiBooking: (data) => dispatch(receiveMultiBooking(data)),
  fetchBookingDataAfterUploadCSV: () => dispatch(fetchBookingDataAfterUploadCSV()),
  updateTenantSetting: (data) => dispatch(updateTenantSetting(data)),
  deleteYesterdayBookingRepeat: (data) => dispatch(deleteYesterdayBookingRepeat(data)),
  deleteYesterdayBooking: (data) => dispatch(deleteYesterdayBooking(data)),
  updateYesterdayBookingSuccess: (data) => dispatch(updateYesterdayBookingSuccess(data)),
  fetchYesterdayBookingList: () => dispatch(fetchYesterdayBookingList()),
  deleteYesterdayBookingItems: (data) => dispatch(deleteYesterdayBookingItems(data)),
})

const withConnect = connect(mapStateToProps, matchDispatchToProp)

export default withConnect(NotificationContainers)
