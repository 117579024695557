import CryptoJS from 'crypto-js'
import uniqBy from 'lodash/uniqBy'
import _get from 'lodash/get'
import findIndex from 'lodash/findIndex'

import { compareValue } from './commonFunctions'
import { customBerthlabel } from 'constants/misc'

export const USER_DATA = '_mnf_f_c'
export const CHECKIN_USER_DATA = 'checkin_mnf_f_c' // checkin normal
export const GENERAL_CHECKIN = 'checkin_g_mnf' // checkin general
export const GENERAL_CHECKIN_BY_TENANT_ID = 'checkin_g_t_mnf' // checkin general by tenant
const WAREHOUSE_SESSION = 'w.h.s.sss'
const PHONE_BOOKING_SESSION = 'm.b.b.k'
export const HISTORY_SEARCH_NORMAL_BOOKING = 'historySearchNormalBooking'
export const HISTORY_SEARCH_GENERAL_BOOKING = 'historySearchGeneralBooking'
export const COPY_BOOKING_LOCAL_KEY = 'copyBooking'

export default {
  encryptData(key) {
    const data1 = CryptoJS.AES.decrypt(key, `1${USER_DATA}`)
    const data2 = data1.toString(CryptoJS.enc.Utf8)
    if (!data2) {
      if (!this.getAuthData()) window.location.replace('/login')
      else window.history.replaceState({}, '', '/')
    }
    return JSON.parse(data2)
  },

  setAuthData({ accessToken, uid, client, id }) {
    const resData = CryptoJS.AES.encrypt(
      JSON.stringify({ accessToken, uid, client }),
      `1${USER_DATA}`
    )
    localStorage.setItem(USER_DATA, resData)
    if (id) localStorage.setItem('userId', id)
  },

  getAuthData() {
    const storedData = localStorage.getItem(USER_DATA)
    try {
      const data1 = CryptoJS.AES.decrypt(storedData, `1${USER_DATA}`)
      return JSON.parse(data1.toString(CryptoJS.enc.Utf8))
    } catch (err) {
      return null
    }
  },

  setWarehouseSession({ tenantId, warehouseId }) {
    const obj = JSON.stringify({ tenantId, warehouseId })
    localStorage.setItem(WAREHOUSE_SESSION, obj)
  },

  getWarehouseSession() {
    const data = JSON.parse(localStorage.getItem(WAREHOUSE_SESSION))
    const warehouseId = _get(data, 'warehouseId') || null
    const tenantId = _get(data, 'tenantId') || null
    return { tenantId, warehouseId }
  },

  // Checkin Authentication
  setCheckinAuthData(localStorageKey, { accessToken, uid, client, generalReceptionNumber }) {
    const data = this.getCheckinAuthData(localStorageKey) || []
    data.unshift({ accessToken, uid, client, generalReceptionNumber })
    const nonDuplicate = uniqBy(data, 'uid')
    const resData = CryptoJS.AES.encrypt(JSON.stringify(nonDuplicate), `1${localStorageKey}`)
    localStorage.setItem(localStorageKey, resData)
  },

  updateCheckinAuthDataByUid(localStorageKey, uid, extraData = {}) {
    const data = this.getCheckinAuthData(localStorageKey) || []
    const index = data.findIndex((dataLogin) => compareValue(dataLogin.uid, uid))

    if (index !== -1) {
      data[index] = {
        ...data[index],
        ...extraData,
      }

      const resData = CryptoJS.AES.encrypt(JSON.stringify(data), `1${localStorageKey}`)
      localStorage.setItem(localStorageKey, resData)
    }
  },

  clearCheckinData(localStorageKey, tenantId) {
    const data = this.getCheckinAuthData(localStorageKey) || []
    const sessionIndex = findIndex(data, { uid: tenantId })
    if (sessionIndex !== -1) {
      data.splice(sessionIndex, 1)
    }

    const resData = CryptoJS.AES.encrypt(JSON.stringify(data), `1${localStorageKey}`)
    localStorage.setItem(localStorageKey, resData)
  },

  getCheckinAuthData(localStorageKey) {
    const storedData = localStorage.getItem(localStorageKey)
    try {
      const data1 = CryptoJS.AES.decrypt(storedData, `1${localStorageKey}`)
      return JSON.parse(data1.toString(CryptoJS.enc.Utf8))
    } catch (err) {
      return null
    }
  },

  setPhoneBookingAuthData({ accessToken, uid }) {
    const resData = JSON.stringify({ accessToken, uid })
    localStorage.setItem(PHONE_BOOKING_SESSION, resData)
  },

  getPhoneBookingAuthData() {
    const storedData = localStorage.getItem(PHONE_BOOKING_SESSION)
    return JSON.parse(storedData)
  },

  setCustomBerthLabel(customBerthLabel) {
    localStorage.setItem(customBerthlabel.KEY_LOCAL, customBerthLabel)
  },

  getCustomBerthLabel() {
    localStorage.getItem(customBerthlabel.KEY_LOCAL)
  },

  clearPhoneBookingAuth() {
    localStorage.removeItem(PHONE_BOOKING_SESSION)
  },

  clearAuthData() {
    localStorage.removeItem(USER_DATA)
    localStorage.removeItem(WAREHOUSE_SESSION)
    localStorage.removeItem(customBerthlabel.KEY_LOCAL)
    this.clearBookingCopy()
  },

  clearOtherData() {
    localStorage.removeItem('userId')
    localStorage.removeItem(WAREHOUSE_SESSION)
  },

  getBookingCopy() {
    try {
      const bookingCopy = JSON.parse(localStorage.getItem(COPY_BOOKING_LOCAL_KEY))
      return bookingCopy
    } catch (e) {}

    return {}
  },

  setBookingCopy(bookingCopy) {
    try {
      localStorage.setItem(COPY_BOOKING_LOCAL_KEY, JSON.stringify(bookingCopy))
    } catch (e) {}
  },

  clearBookingCopy() {
    localStorage.removeItem(COPY_BOOKING_LOCAL_KEY)
  },
}
