import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { camelizeKeys } from 'humps'
import _ from 'lodash'

import {
  startLoading,
  stopLoading,
  openModal,
  fetchAnything,
  openError,
} from 'containers/App/reducers'
import { formatPhoneNumber, formatPhoneNumberToObject } from 'utils/formatters'
import theme from 'vars/theme'

import NumericKeypad from 'components/NumericKeypad'
import BackSpace from 'components/BackSpace'
import Header from '../components/Header'
import { Backdrop, ModalFrame } from '../components/utils'
import { requestPost } from '../api'
import { updateDriver } from '../reducers'
import PrevButton from 'components/PrevButton'
import Icon from 'components/Icon'
import { filterNumber } from '../constants'

const BackSpaceCustom = styled(BackSpace)`
  margin-left: auto;
  margin-top: 9px;
  margin-bottom: 30px;
`

const WrapperInput = styled.div`
  display: flex;
  justify-content: space-between;
`

const Input = styled.input`
  flex: 1;
  max-width: 111px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 40px;
  color: #333;
  border-radius: 12px;
`

const Text = styled.p`
  margin: 0;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
`

const Title = styled(Text)`
  font-weight: 700;
  span {
    text-decoration: underline;
  }
`

const Notification = styled(Title)`
  margin-top: 15px;
`

const PhoneNumberLabel = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 36px;
  font-weight: 600;
  margin: 18px 0px;
`

const SendCodeButton = styled.button`
  padding: 35px 23px;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  line-height: 1.2;
  color: ${theme.blueMedium5};
  background-color: ${theme.whitePrimary};
  border: 1px solid ${theme.blueMedium5};
  border-radius: 30px;
  font-size: 24px;
`

const Box = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const LeftBox = styled.div`
  margin-top: -80px;
  padding-right: 35px;
  flex: 45%;
`

const RightBox = styled.div`
  flex: 55%;
`

const PassCodeInitial = {
  num0: '',
  num1: '',
  num2: '',
  num3: '',
}

class EntryPasscodePage extends Component {
  state = {
    ...PassCodeInitial,
    isValid: true,
    isPhoneChecked: false,
  }

  async componentDidMount() {
    const { match, driver, returnEntryPage, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    if (returnEntryPage) {
      history.push(`/receipt-booking/${warehouseId}/${tenantId}`)
      return
    }
    this.props.startLoading()
    let resp
    try {
      // TODO 失敗するケースがある？
      resp = await requestPost('/tablets/send_code', {
        phoneNumber: driver.driverPhone,
        tenantId,
      })
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }
    const data = camelizeKeys(resp.data)
    const { displayPolicyVersion, phoneChecked } = data
    this.props.updateDriver({ policyData: displayPolicyVersion || {}, phoneChecked })
    this.setState({ isPhoneChecked: phoneChecked })
  }

  onClick = (value) => {
    const { num0, num1, num2, num3 } = this.state

    if (`${num0}${num1}${num2}${num3}`.length === 4) return

    const nums = [num0, num1, num2, num3]
    for (let i = 0; i < 4; i += 1) {
      const num = nums[i]
      if (!num) {
        this.setState({ [`num${i}`]: value, isValid: true })
        return
      }
    }
  }

  resetPassCodeAndError = () => {
    this.setState({
      ...PassCodeInitial,
      isValid: true,
    })
  }

  onClickBackSpace = () => {
    const { num0, num1, num2, num3 } = this.state
    const nums = [num0, num1, num2, num3]

    for (let i = 4; i >= 0; i -= 1) {
      const num = nums[i]
      if (num) {
        this.setState({ [`num${i}`]: '', isValid: true })
        return
      }
    }
  }

  onChange = (e, index) => {
    const { value } = e.target
    this.setState({ [`num${index}`]: filterNumber(value), isValid: true })
  }

  onClickEnter = async () => {
    const { history, match, driver } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { num0, num1, num2, num3 } = this.state

    this.props.startLoading()

    try {
      await requestPost('/tablets/verify', {
        code: `${num0}${num1}${num2}${num3}`,
        phoneNumber: driver.driverPhone,
        tenantId,
      })
    } catch (error) {
      this.setState({ isValid: false })
      return
    } finally {
      this.props.stopLoading()
    }

    if (!_.isEmpty(driver.policyData)) {
      history.push(`/receipt-booking/terms-of-service/${warehouseId}/${tenantId}`)
      return
    }

    const url = driver.tenant.canBerthBook
      ? `/receipt-booking/entry-bookings/${warehouseId}/${tenantId}`
      : `/receipt-booking/entry-driver-info/${warehouseId}/${tenantId}`

    history.push(url)
  }

  onClickResend = async () => {
    const { match, driver } = this.props
    const {
      params: { tenantId },
    } = match

    this.props.startLoading()

    let resp
    try {
      resp = await requestPost('/tablets/resend_code', {
        phoneNumber: driver.driverPhone,
        tenantId,
      })
    } catch (error) {
      this.props.openError(error.message)
      return
    } finally {
      this.props.stopLoading()
    }

    const data = camelizeKeys(resp.data)
    const { displayPolicyVersion, phoneChecked } = data
    this.props.updateDriver({ policyData: displayPolicyVersion || {} })
    this.setState({ isPhoneChecked: phoneChecked })

    this.props.openModal({
      content: (
        <div>
          <Title>
            SMSで
            <span style={{ textDecoration: 'underline' }}>
              {formatPhoneNumber(driver.driverPhone)}
            </span>
            にパスコードを送信しました。
          </Title>
          <Notification
            dangerouslySetInnerHTML={{
              __html:
                'パスコードが届かない場合は、<br/>入力した電話番号に誤りがないか、再度ご確認ください。',
            }}
          />
        </div>
      ),
      buttons: [
        {
          label: 'とじる',
          color: 'primary',
          onClick: this.resetPassCodeAndError,
        },
      ],
    })
  }

  render() {
    const { match, driver, history } = this.props
    const {
      params: { warehouseId, tenantId },
    } = match
    const { num0, num1, num2, num3, isValid, isPhoneChecked } = this.state
    const { left, center, right } = formatPhoneNumberToObject(driver.driverPhone)

    return (
      <div style={{ backgroundColor: '#EDEDED', height: '100vh', fontSize: 18 }}>
        <Header driver={driver} />

        <ModalFrame className="mx-auto p-3" style={{ width: 931 }}>
          <div className="d-flex justify-content-between align-items-center">
            <div style={{ width: '30%' }}>
              <PrevButton
                onClick={() =>
                  history.push(`/receipt-booking/entry-tel-number/${warehouseId}/${tenantId}`)
                }
              />
            </div>
            <div className="text-center text-size24" style={{ width: '40%' }}>
              {isPhoneChecked ? (
                <Icon
                  src="/assets/svgs/registered-mark.svg"
                  width={44}
                  height={44}
                  className="mr-2"
                />
              ) : (
                <Icon
                  src="/assets/svgs/beginner-mark.svg"
                  width={44}
                  height={44}
                  className="mr-2"
                />
              )}
              <span>パスコードを入力</span>
            </div>
            <div style={{ width: '30%' }} />
          </div>

          <div className="text-center text-danger">
            {isValid ? <span>&nbsp;</span> : 'パスコードが違います。再度ご確認ください。'}
          </div>
          <Box>
            <LeftBox>
              <Text
                dangerouslySetInnerHTML={{
                  __html: driver.phoneChecked
                    ? `本人確認のため、<br/>ご登録の電話番号にお送りしている<br/>パスコードを入力してください。`
                    : '本人確認のため、<br/>ご登録の電話番号にお送りした<br/>４桁のパスコードを入力してください。',
                }}
              ></Text>
              <PhoneNumberLabel>
                <p>{left}</p>
                <p>-</p>
                <p>{center}</p>
                <p>-</p>
                <p>{right}</p>
              </PhoneNumberLabel>
              <Text>パスコードがわからない方</Text>
              <SendCodeButton onClick={this.onClickResend}>パスコードを再発行する</SendCodeButton>
              <Text>※パスコードは次回以降も</Text>
              <Text>同じ数字を使用します。</Text>
            </LeftBox>
            <RightBox>
              <WrapperInput>
                <Input
                  type="text"
                  value={num0}
                  maxLength={1}
                  onChange={(e) => this.onChange(e, '0')}
                  style={{ border: `2px solid ${isValid ? '#333333' : '#DB2664'}` }}
                />
                <Input
                  type="text"
                  value={num1}
                  maxLength={1}
                  onChange={(e) => this.onChange(e, '1')}
                  style={{ border: `2px solid ${isValid ? '#333333' : '#DB2664'}` }}
                />
                <Input
                  type="text"
                  value={num2}
                  maxLength={1}
                  onChange={(e) => this.onChange(e, '2')}
                  style={{ border: `2px solid ${isValid ? '#333333' : '#DB2664'}` }}
                />
                <Input
                  type="text"
                  value={num3}
                  maxLength={1}
                  onChange={(e) => this.onChange(e, '3')}
                  style={{ border: `2px solid ${isValid ? '#333333' : '#DB2664'}` }}
                />
              </WrapperInput>
              <BackSpaceCustom onClick={this.onClickBackSpace}>一文字消す</BackSpaceCustom>
              <NumericKeypad
                onClickNumber={this.onClick}
                onClickEnter={this.onClickEnter}
                disabledEnterButton={`${num0}${num1}${num2}${num3}`.length !== 4}
              />
            </RightBox>
          </Box>
        </ModalFrame>

        <Backdrop />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const reception = state.get('reception')
  return {
    driver: reception.driver,
    returnEntryPage: reception.returnEntryPage,
  }
}

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  fetchAnything,
  openModal,
  updateDriver,
  openError,
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryPasscodePage)
